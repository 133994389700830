/* eslint-disable react/prop-types */
import React from "react";

export const UncheckedIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "12"}
      height={height || "12"}
      viewBox="0 0 12 12"
    >
      <g
        id="Rectangle_4954"
        data-name="Rectangle 4954"
        fill="none"
        stroke="#ccc"
        strokeWidth="1"
      >
        <rect width="12" height="12" rx="4" stroke="none" />
        <rect x="0.5" y="0.5" width="11" height="11" rx="3.5" fill="none" />
      </g>
    </svg>
  );
};
