import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tail: {
    width: "19px",
    height: "11.36px",
    left: "32px",
    top: "-10px",
    zIndex: 10,
    backgroundColor: "#ffffff",
    clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
    position: "absolute",
  },

  tailBackground: {
    width: "21px",
    height: "12.36px",
    left: "31px",
    top: "-12px",
    zIndex: 10,
    backgroundColor: "#D5D9D9",
    clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
    position: "absolute",
  },

  menu: {
    overflow: "visible",
    // width: "200px",
    "& .PrivatePickersFadeTransitionGroup-root:not(:last-of-type)": {
      paddingLeft: "10px",
    },
    // transform: "translateY(10px)!important",
    "& .MuiPickersDay-root": { height: "20px", width: "22px" },
    "& .MuiCalendarPicker-root": {
      "& .MuiTypography-subtitle1": {
        whiteSpace: "nowrap",
        fontSize: "12px",
      },

      "& .MuiTypography-caption": {
        width: "22px",
        height: "23px",
        textTransform: "lowercase",
        display: "inline-block",
        "&:first-letter": {
          textTransform: "uppercase",
        },
      },
      "& .PrivatePickersSlideTransition-root": {
        minWidth: "200px",
        width: "200px",
        minHeight: "150px",
        height: "150px",
      },
      "& > div": {
        width: "200px",
        padding: "0px",

        "& > div > div": {
          borderRight: "none",
          "& > div": { fontSize: "12px" },
          "& > div:nth-of-type(2)": {
            borderTop: "0.5px solid #D4D4D4",
          },
          "&:not(:last-of-type)": {
            "& > div:nth-of-type(2)": {
              borderRight: "0.5px solid #D4D4D4",
            },
            "& .PrivatePickersSlideTransition-root": {
              borderRight: "0.5px solid #D4D4D4",
            },
          },
        },
      },
    },
  },
}));

export default useStyles;
