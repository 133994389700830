import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  pageWrapper: {
    paddingTop: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  profileWrapper: {
    width: "1126px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    border: "1px solid #d5d9d9",
    height: "calc(100vh - 200px)",
  },

  profileHeader: {
    padding: "20px 26px 0 35px",
  },

  editButton: {
    height: "28px",
    borderColor: "#CCCCCC",
  },

  title: {
    color: "#707070",
    fontSize: "35px",
  },

  profileInnerPage: {
    padding: "20px 26px 15px 35px",
    maxHeight: "calc(100% - 165px)",
    overflow: "auto",
  },

  buttonsBlock: {
    display: "flex",
    gap: "12px",
  },

  editInput: {
    height: "43px",
    fontSize: "15px",
    borderRadius: "9px",
  },
}));

export default useStyles;
