import { useSelector } from "react-redux";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Loader } from "components";
import { DesktopViewOrder, MobileViewOrder } from "./components";

import { useOrderReturn } from "./useOrderReturn";
import { useAcceptPayment } from "./useAcceptPayment";
import { useMediaDevice } from "hooks/useMediaDevice";
import { OrderContext, useViewOrder } from "./ViewOrderPage.hooks";

export const ViewOrderPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isMobile } = useMediaDevice();
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { orderData, updateOrderData, handleUpdateNote, handleGetOrder } =
    useViewOrder();

  const { type } = state || {};

  const [isOpenEditContactDialog, setOpenEditContactDialog] = useState(false);
  const [editCustomerType, setEditCustomerType] = useState("");
  const [editCustomerData, setEditCustomerData] = useState(null);

  const {
    state: returnOrderState,
    handleOpenDialog: handleOpenReturnDialogDialog,
    handleCloseDialog: handleCloseReturnDialogDialog,
  } = useOrderReturn();

  const handleClickContinueShopping = () => navigate("/");

  const handleClickAddressEdit = useCallback((type, data) => {
    setOpenEditContactDialog(true);
    setEditCustomerType(type);
    setEditCustomerData(data);
  }, []);

  const handleChangeCustomer = (data, type, err, setError) => {
    if (!data) return;
    if (err?.formatted_address?.type === "duplicate")
      return setError("data.formatted_address", {
        ...err.formatted_address,
      });

    let fieldName = "";
    switch (type) {
      case "customer contact": {
        fieldName = "contactId";
        break;
      }
      case "billing address": {
        fieldName = "billingAddress";
        break;
      }
      case "shipping address": {
        fieldName = "shippingAddress";
        break;
      }
      default:
        return "";
    }

    const key = Object.keys(data)[0];

    // eslint-disable-next-line no-console
    console.log("", { [fieldName]: data[key] });
  };

  const acceptPaymentData = useAcceptPayment({
    handleGetOrder,
    order: orderData?.order,
  });

  return (
    <OrderContext.Provider
      value={{
        orderData,
        updateOrderData,
        type,
        handleUpdateNote,
        acceptPaymentData,
        returnOrderState,
        currentUser,
        isOpenEditContactDialog,
        editCustomerData,
        editCustomerType,
        setOpenEditContactDialog,
        handleChangeCustomer,
        handleCloseReturnDialogDialog,
        handleClickAddressEdit,
        handleOpenReturnDialogDialog,
        handleClickContinueShopping,
      }}
    >
      {orderData.loading ? (
        <Loader isLoading={orderData.loading} />
      ) : (
        <>{isMobile ? <MobileViewOrder /> : <DesktopViewOrder />}</>
      )}
    </OrderContext.Provider>
  );
};
