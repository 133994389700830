/* eslint-disable react/prop-types */
import React from "react";

export const InformationIconGreen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.049"
      height="16.049"
      viewBox="0 0 16.049 16.049"
    >
      <path
        id="Icon_ionic-ios-information-circle"
        data-name="Icon ionic-ios-information-circle"
        d="M11.4,3.375A8.024,8.024,0,1,0,19.424,11.4,8.023,8.023,0,0,0,11.4,3.375ZM12.132,15.1H10.659V9.544h1.474ZM11.4,8.938a.77.77,0,1,1,.8-.772A.773.773,0,0,1,11.4,8.938Z"
        transform="translate(-3.375 -3.375)"
        fill="#26874b"
      />
    </svg>
  );
};

export const InformationIconStandart = ({
  stroke = "#47A06D",
  size = "9.853",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 9.853 9.853"
    >
      <g
        id="Icon_feather-info"
        data-name="Icon feather-info"
        transform="translate(0.4 0.4)"
      >
        <path
          id="Path_2549"
          data-name="Path 2549"
          d="M12.053,7.526A4.526,4.526,0,1,1,7.526,3,4.526,4.526,0,0,1,12.053,7.526Z"
          transform="translate(-3 -3)"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.8"
        />
        <path
          id="Path_2550"
          data-name="Path 2550"
          d="M18,19.811V18"
          transform="translate(-13.474 -13.474)"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.8"
        />
        <path
          id="Path_2551"
          data-name="Path 2551"
          d="M18,12h0"
          transform="translate(-13.474 -9.284)"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.8"
        />
      </g>
    </svg>
  );
};

export const InformationIcon = ({
  stroke = "#47A06D",
  size = "9.853",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 9.853 9.853"
      {...props}
    >
      <g
        id="Icon_feather-info"
        data-name="Icon feather-info"
        transform="translate(0.4 0.4)"
      >
        <path
          id="Path_2549"
          data-name="Path 2549"
          d="M12.053,7.526A4.526,4.526,0,1,1,7.526,3,4.526,4.526,0,0,1,12.053,7.526Z"
          transform="translate(-3 -3)"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.8"
        />
        <path
          id="Path_2550"
          data-name="Path 2550"
          d="M18,19.811V18"
          transform="translate(-13.474 -13.474)"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.8"
        />
        <path
          id="Path_2551"
          data-name="Path 2551"
          d="M18,12h0"
          transform="translate(-13.474 -9.284)"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.8"
        />
      </g>
    </svg>
  );
};
