import {} from "react";
import { string } from "prop-types";
import { AccordionSummary, Grid, Typography } from "@mui/material";
import { CardIconComponent } from "components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const WalletSummaryBlock = ({
  typeCard,
  nameCardAndPeriod,
  name,
  expires,
}) => {
  return (
    <AccordionSummary
      sx={{ height: "72px", pl: 0 }}
      expandIcon={<ExpandMoreIcon sx={{ fontSize: 30 }} />}
      aria-controls="panel-content"
      id="panel-header"
    >
      <Grid sx={{ alignItems: "center" }} container>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={0.8}
          item
        >
          <CardIconComponent type={typeCard} />
        </Grid>
        <Grid xs={3.92} item>
          <Typography fontSize={24} color="#707070">
            {nameCardAndPeriod}
          </Typography>
        </Grid>
        <Grid xs={3.3} item>
          <Typography fontSize={24} color="#707070">
            {name}
          </Typography>
        </Grid>
        <Grid xs={3.98} item>
          <Typography fontSize={24} color="#707070">
            {expires}
          </Typography>
        </Grid>
      </Grid>
    </AccordionSummary>
  );
};

WalletSummaryBlock.propTypes = {
  typeCard: string,
  nameCardAndPeriod: string,
  name: string,
  expires: string,
};
WalletSummaryBlock.defaultProps = {
  typeCard: "",
  nameCardAndPeriod: "",
  name: "",
  expires: "",
};
