import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { string, any, bool } from "prop-types";
import { StyledTooltip, StyledLabel } from "components";

const TableItem = ({
  title,
  value,
  isDefault,
  isEdit,
  EditField,
  autoHeight,
}) => {
  return (
    <Box
      sx={{
        "&:not(:last-of-type)": {
          borderBottom: !isEdit && "1px solid #d5d9d9",
        },
        "& .MuiTypography-root": {
          fontSize: "15px",
        },
      }}
      display="flex"
      justifyContent="space-between"
      ml="20px"
      mr="10px"
      py="21px"
      height={autoHeight ? "auto" : "62px"}
      alignItems={autoHeight ? "flex-start" : "center"}
    >
      <Box display="flex" gap="10px" alignItems="center" height="40px">
        <Typography color="#979797" fontWeight={600}>
          {title}
        </Typography>
        {isDefault && <StyledLabel />}
      </Box>
      {isEdit && !!EditField ? (
        <Box width="65%">{EditField}</Box>
      ) : title === "Website" ? (
        <StyledTooltip
          placement="top"
          arrow
          title={value?.url || ""}
          PopperProps={{
            modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
          }}
          disableHoverListener={!value?.queryString}
        >
          <Typography>
            {value?.domainName || "-"}
            {!!value?.queryString && <Box component="span">...</Box>}
          </Typography>
        </StyledTooltip>
      ) : (
        <Typography color="#000000" whiteSpace="pre-wrap" textAlign="right">
          {value}
        </Typography>
      )}
    </Box>
  );
};

TableItem.propTypes = {
  title: string,
  value: any,
  isDefault: bool,
  isEdit: bool,
  EditField: any,
  autoHeight: bool,
};

TableItem.defaultProps = {
  title: "Title",
  value: "value",
  isDefault: false,
  isEdit: false,
  EditField: null,
  autoHeight: false,
};

export default TableItem;
