// import { useNavigate } from "react-router-dom";
import PropTypes, { string, object } from "prop-types";

import { Typography, Box } from "@mui/material";

// import { StyledButton } from "components";
// import { ArrowLeftIcon } from "components/Icons";

export const ProductDetailsSwitcherTab = ({ breadCrumbs }) => {
  // const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        // borderBottom: "0.5px solid #D4D4D4",
        height: "44px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 3,
          px: "42px",
          width: "100%",
        }}
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          color="#000"
          display="flex"
          alignItems="center"
          gap={1}
          noWrap
          component="span"
        >
          {breadCrumbs}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
          mr: "20px",
        }}
      >
        {/* <StyledButton
          sx={{
            "& .MuiTypography-root": {
              fontSize: 16,
              color: "#6A6A6A",
            },
          }}
          label="Back"
          onClick={() => navigate("/")}
          startIcon={<ArrowLeftIcon color="#6A6A6A" />}
        /> */}
      </Box>
    </Box>
  );
};

ProductDetailsSwitcherTab.propTypes = {
  breadCrumbs: PropTypes.oneOfType([string, object]),
};
ProductDetailsSwitcherTab.defaultProps = {
  breadCrumbs: "no title",
};

export default ProductDetailsSwitcherTab;
