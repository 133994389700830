import { makeStyles } from "@mui/styles";

export const cl = {
  wrapper: {
    position: "fixed",
    top: 0,
    width: "100%",
    backgroundColor: "#ffff",
    zIndex: 100,
    p: "10px 16px",
    display: "flex",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.05)",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleText: {
    color: "#2F3040",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "24.3px",
  },
  backArrowBlock: {
    width: "87px",
    justifyContent: "center",

    "& svg": {
      width: "24px",
      height: "24px",
      transform: "rotate(90deg)",
    },
  },
};

export const useStyles = makeStyles(() => ({
  addItemsBtn: {
    color: "#409A65",
    fontSize: 14,
    fontWeight: "600",
    width: "87px",
    padding: 0,
    whiteSpace: "nowrap",

    "& svg": {
      width: "18px",
      height: "18px",
    },
  },
}));
