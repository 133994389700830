import { Box } from "@mui/material";
import { DiscountItem } from "./components";
import Slider from "react-slick";
import { GalleryNextIcon } from "components/Icons";
import { array, bool, func, shape, string } from "prop-types";
import { separateNumWithComma } from "helpers/helpers";

export const DiscountBlock = ({
  discountState,
  discountActiveId,
  setDiscountActiveId,
  productsList,
}) => {
  const { discounts } = discountState || {};

  const settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
  };

  const filterQty = () => {
    return productsList?.reduce((acc, cur) => {
      return acc + cur?.value;
    }, 0);
  };

  const filterMpa = () => {
    return productsList?.reduce((acc, cur) => {
      return acc + cur?.value * cur?.retailPrice;
    }, 0);
  };

  const setDiscountDisable = (discount) => {
    if (discount?.type === "QUANTITY") {
      return filterQty() < discount?.minQuantity;
    }

    if (discount?.minPurchaseAmount) {
      return filterMpa() < discount?.minPurchaseAmount;
    }

    if (discount?.type === "PERCENTAGE") {
      if (discount?.isLimited)
        return discount?.limit === discount?._count?.duplicates;

      if (!discount?.isLimited && discount?.minQuantity)
        return filterQty() < discount?.minQuantity;
    }

    return false;
  };

  const setTooltipText = (discount) => {
    if (discount?.type === "QUANTITY") {
      return `Minimum quantity of items - ${discount?.minQuantity}`;
    }

    if (discount?.minPurchaseAmount) {
      return `Minimum purchase amount - $${discount?.minPurchaseAmount}`;
    }

    if (discount?.type === "PERCENTAGE") {
      if (discount?.isLimited)
        return `Used ${discount?.limit} of ${discount?._count?.duplicates}`;

      if (!discount?.isLimited && discount?.minQuantity)
        return `Minimum quantity of items - ${discount?.minQuantity}`;
    }

    return "";
  };

  return (
    <Box p="0 114px 0 80px" mt={1} position="relative">
      <Box
        sx={{
          overflow: "hidden",
          "& .slick-slide": {
            marginRight: "26px",
          },
          "& .slick-list": {
            marginRight: "-26px",
          },
          "& .slick-track": {
            display: "flex",
            alignItems: "center",
            ml: "unset",
            mr: "unset",
          },
          "& .slick-arrow.slick-prev": {
            width: "36px",
            height: "36px",
            position: "absolute",
            top: "50%",
            left: 30,
            transform: "translate(0, -50%) rotate(180deg)",
            cursor: "pointer",
          },
          "& .slick-arrow.slick-next": {
            width: "36px",
            height: "36px",
            position: "absolute",
            top: "50%",
            right: 66,
            transform: "translate(0, -50%)",
            cursor: "pointer",
          },
          "& .slick-arrow.slick-prev.slick-disabled svg": {
            cursor: "auto",
            opacity: 0.3,
          },
          "& .slick-arrow.slick-next.slick-disabled svg": {
            cursor: "auto",
            opacity: 0.3,
          },
        }}
      >
        <Slider
          {...settings}
          nextArrow={
            <Box top="47%" right="26px" zIndex="10">
              <GalleryNextIcon />
            </Box>
          }
          prevArrow={
            <Box top="47%" left="26px" zIndex="10">
              <GalleryNextIcon />
            </Box>
          }
        >
          {discounts.map((discount) => {
            return (
              <DiscountItem
                key={discount?.id}
                id={discount?.id}
                discountName={discount?.name}
                discountType={discount?.type}
                percentDiscount={discount?.percentDiscount}
                absoluteDiscount={separateNumWithComma(
                  discount?.absoluteDiscount
                )}
                disabled={setDiscountDisable(discount)}
                tooltipText={setTooltipText(discount)}
                minQuantity={discount?.minQuantity}
                {...{ discountActiveId, setDiscountActiveId }}
              />
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};
DiscountBlock.propTypes = {
  discountState: shape({
    loading: bool,
    discounts: array,
  }),
  discountActiveId: string,
  setDiscountActiveId: func,
  productsList: array,
};
DiscountBlock.defaultProps = {
  discountState: {
    loading: false,
    discounts: [],
  },
  discountActiveId: "",
  setDiscountActiveId: () => {},
  productsList: [],
};
