import {} from "react";
import {} from "prop-types";
import { Box, TextField, Typography } from "@mui/material";

export const NotesBlock = () => {
  return (
    <Box mt={2}>
      <Typography fontSize={12} fontWeight={400} color="#000" mb={1}>
        Notes
      </Typography>
      <TextField
        placeholder=""
        multiline
        rows={3}
        fullWidth
        InputProps={{
          sx: {
            fontSize: 13,
            fontWeight: 300,
            paddingTop: "10px",
            paddingBottom: "11px",
          },
        }}
        // error={!!error}
      />
    </Box>
  );
};

NotesBlock.propTypes = {};
NotesBlock.defaultProps = {};
