import { useMemo } from "react";
import { object, string } from "prop-types";
import { Box, Grid, Paper, Typography } from "@mui/material";

import { StyledProductIco, Statuses } from "../../components";
import { separateNumWithComma } from "../../helpers/helpers";

export const SearchItem = ({ item, ...props }) => {
  const currentStatus = useMemo(() => {
    if (
      item?.orderDirect?.length &&
      ["REJECTED", "PENDING"].includes(item?.orderDirect?.[0]?.status)
    ) {
      return item?.orderDirect?.[0]?.status;
    }
  }, [item?.orderDirect]);

  return (
    <Paper
      sx={{
        alignItems: "center",
        borderRadius: "0 0 4px 4px",
        "&:not(:last-child)": {
          borderBottom: "1px solid #D5D9D9",
          borderRadius: 0,
        },
        "&:first-of-type": {
          borderRadius: "4px 4px 0 0",
        },
      }}
      elevation={0}
      square
      component={Grid}
      container
      {...props}
    >
      <Grid sx={{ display: "flex" }} xs="auto" item>
        <Box
          sx={{
            border: "1px solid #D5D9D9",
            borderRadius: "4px",
            backgroundColor: "#F3F3F3",
            width: "34px",
            minWidth: "34px",
            maxWidth: "34px",
            height: "34px",
          }}
        >
          <StyledProductIco
            src={item?.profilePhoto?.fileName}
            styles={{
              objectFit: "contain",
              maxWidth: "34px",
              width: "100%",
              maxHeight: "34px",
              height: "100%",
              borderRadius: "4px",
            }}
            iconWrapper={{ display: "flex", m: 0.8 }}
          />
        </Box>
      </Grid>
      <Grid
        sx={{ display: "flex", flexDirection: "column" }}
        xs
        item
        zeroMinWidth
      >
        <Typography sx={{ fontSize: 12, pl: 1 }} noWrap>
          {item?.name}
        </Typography>
        <Typography sx={{ fontSize: 12, pl: 1 }} noWrap>
          {item?.minimumOrderValueAmount === 0
            ? "No Minimum"
            : `Minimum $${separateNumWithComma(item?.minimumOrderValueAmount)}`}
        </Typography>
      </Grid>
      <Grid sx={{ display: "flex" }} xs={4} item>
        <Typography sx={{ fontSize: 12 }} noWrap>
          {item?.companyIndustry}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        xs={2.5}
        item
      >
        <Typography sx={{ fontSize: 12 }} noWrap>
          {currentStatus ? (
            <Statuses text={currentStatus} />
          ) : (
            `${item?._count?.products} products`
          )}
        </Typography>
      </Grid>
    </Paper>
  );
};

SearchItem.propTypes = { item: object, address: string };
