import {} from "react";
import { any, func, string } from "prop-types";
import { Accordion } from "@mui/material";
import { WalletDetailsBlock, WalletSummaryBlock } from "./components";

export const WalletItem = ({
  cardState,
  handleConfirmDeleteCard,
  customerId,
  handleSave,
  handleSetDefault,
}) => {
  return (
    <Accordion
      sx={{
        border: "0.5px solid #D5D9D9",
        m: "12px 0",
        borderRadius: "4px",
        "&:before": {
          display: "none",
        },
        "& .Mui-expanded": {
          backgroundColor: "#FBFBFB",
          borderRadius: "4px 4px 0 0",
        },
      }}
      elevation={0}
      disableGutters
    >
      <WalletSummaryBlock
        typeCard={cardState?.brand}
        nameCardAndPeriod={`Visa ending in ${cardState.last4}`}
        name={cardState?.billingAddress?.name}
        expires={`${cardState?.expMonth} / ${cardState?.expYear}`}
      />
      <WalletDetailsBlock
        customerId={customerId}
        billingAddress={cardState?.billingAddress}
        cardState={cardState}
        handleConfirmDeleteCard={handleConfirmDeleteCard}
        handleSave={handleSave}
        handleSetDefault={handleSetDefault}
      />
    </Accordion>
  );
};

WalletItem.propTypes = {
  cardState: any,
  handleConfirmDeleteCard: func,
  customerId: string,
  handleSave: func,
  handleSetDefault: func,
};
WalletItem.defaultProps = {};
