import { createContext } from "react";

import { Loader } from "components";
import { DesktopPayments, MobilePayments } from "./components";

import { usePayments } from "./usePayments";
import { useMediaDevice } from "hooks/useMediaDevice";

export const PaymentsContext = createContext();

export const PaymentsPage = () => {
  const paymentsState = usePayments();

  const { isMobile } = useMediaDevice();

  return (
    <PaymentsContext.Provider value={{ ...paymentsState }}>
      <Loader isLoading={paymentsState.isLoading} />
      {isMobile ? <MobilePayments /> : <DesktopPayments />}
    </PaymentsContext.Provider>
  );
};
