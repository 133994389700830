export const MobileLockIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.75 8.75V5.625a3.75 3.75 0 1 0-7.5 0V8.75m-.625 9.375h8.75c1.036 0 1.875-.84 1.875-1.875v-5.625c0-1.036-.84-1.875-1.875-1.875h-8.75c-1.036 0-1.875.84-1.875 1.875v5.625c0 1.035.84 1.875 1.875 1.875"
      stroke="#0F172A"
      strokeOpacity={0.4}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
