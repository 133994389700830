import { error } from "helpers/notifications";
import {
  addDraftService,
  deleteDraftService,
  getDraftsListService,
  updateDraftService,
} from "services/drafts";

export const GET_DRAFTS_LIST = "GET_DRAFTS_LIST";
export const SET_DRAFTS_LOADING = "SET_DRAFTS_LOADING";
export const UPDATE_DRAFTS_LIST = "UPDATE_DRAFTS_LIST";
export const DELETE_DRAFT = "DELETE_DRAFT";
export const SET_USER_CART_STEP = "SET_USER_CART_STEP";
export const SELECTED_DISTRIBUTOR = "SELECTED_DISTRIBUTOR";

export const getDraftsList = (payload) => ({
  type: GET_DRAFTS_LIST,
  payload,
});

const setUserCartStep = (payload) => ({
  type: SET_USER_CART_STEP,
  payload,
});

const deleteDraft = (payload) => ({
  type: DELETE_DRAFT,
  payload,
});

const updateDraftsList = (payload) => ({
  type: UPDATE_DRAFTS_LIST,
  payload,
});

export const setDraftsLoading = (payload) => ({
  type: SET_DRAFTS_LOADING,
  payload,
});

export const selectedDistributorAction = (payload) => ({
  type: SELECTED_DISTRIBUTOR,
  payload,
});

export const getDraftsAction = () => {
  return (dispatch) => {
    dispatch(setDraftsLoading(true));
    getDraftsListService()
      .then((res) => {
        dispatch(setDraftsLoading(false));
        dispatch(getDraftsList(res));
      })
      .catch((err) => {
        dispatch(setDraftsLoading(false));
        error(err?.response?.data?.message);
      });
  };
};

export const updateDraftsAction = (data, distributor_id, draftId) => {
  return (dispatch) => {
    dispatch(setDraftsLoading(true));
    updateDraftService({ data }, { distributor_id }, draftId)
      .then((res) => {
        dispatch(setDraftsLoading(false));
        const shouldForceReplace = draftId !== res.id;

        dispatch(
          updateDraftsList({
            draftData: res,
            oldDraftId: shouldForceReplace ? draftId : null,
          })
        );
      })
      .catch((err) => {
        dispatch(setDraftsLoading(false));
        error(err?.response?.data?.message);
      });
  };
};

export const addDraftAction = (data, distributor_id) => {
  return (dispatch) => {
    dispatch(setDraftsLoading(true));
    addDraftService({ data }, { distributor_id })
      .then(() => {
        dispatch(setDraftsLoading(false));
        dispatch(getDraftsAction());
      })
      .catch((err) => {
        dispatch(setDraftsLoading(false));
        error(err?.response?.data?.message);
      });
  };
};

export const deleteDraftAction = (id, distributor_id) => {
  return (dispatch) => {
    dispatch(setDraftsLoading(true));
    deleteDraftService(id, distributor_id)
      .then(() => {
        dispatch(deleteDraft(id));
        dispatch(setDraftsLoading(false));
      })
      .catch((err) => {
        dispatch(setDraftsLoading(false));
        error(err?.response?.data?.message);
      });
  };
};

export const userCartStepAction = (step) => {
  return (dispatch) => {
    dispatch(setUserCartStep(step));
  };
};
