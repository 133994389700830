import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box } from "@mui/material";

import { useDebounce } from "simply-depo-ui-components";
import { distributorsListSelector } from "../../redux/selectors/distributors";
import { paginationSelector } from "../../redux/selectors/settings";
import { defaultValues } from "./SupplierProductsPage.constants";
import { validationSchema } from "./SupplierProductsPage.validations";
import { getDistributorProductsService } from "../../services/distributors";
import {
  resetPaginationAction,
  setPaginationAction,
} from "../../redux/actions/settings";
import { BrandHeader } from "./components/BrandHeader";
import { photoUrl } from "../../helpers/helpers";
import { ViewSettings } from "./components";
import { Loader, ProductCard, SimpleTab } from "../../components";
import ContactDialog from "pages/VendorsPage/components/DesktopVendors/components/ContactDialog";

const selector = createSelector(
  distributorsListSelector,
  paginationSelector,
  (distributorsList, pagination) => ({
    distributorsList,
    pagination,
  })
);

export const SupplierProductsPage = () => {
  const { pathname } = useLocation();
  const distributorId = pathname.split("/")[2];

  const dispatch = useDispatch();

  const { distributorsList, pagination } = useSelector(selector);

  const [isContactVendorDialog, setContactVendorDialog] = useState(false);
  const [productsSearch, setProductsSearch] = useState("");
  const searchInputDebounced = useDebounce(productsSearch, 500);

  const topSectionOfList = useRef();
  const handleScrollToTop = (ref) =>
    ref.current.scrollIntoView({ behavior: "smooth" });

  const currentDist = useMemo(() => {
    return distributorsList.find(({ id }) => id === distributorId);
  }, [distributorId, distributorsList]);

  const replacetext = (text, from, to) => {
    return text.replace(from, to);
  };

  const preparedWebsiteText = (type) => {
    if (typeof type !== "string") return type;

    const typeToLowercase = type.toLowerCase();
    let text = replacetext(typeToLowercase, "https://", "");
    text = replacetext(text, /\/$/, "");
    return text;
  };

  const [isLoading, setLoading] = useState(false);
  const [vendorProductsList, setVendorProductsList] = useState([]);

  const { control, setValue } = useForm({
    mode: "onChange",
    defaultValues: { ...defaultValues },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  useEffect(() => {
    // preparing the categories list for the ViewSettings component
    const categories = currentDist?.productCategories;
    if (!categories?.length) return;
    const preparedCategories = categories.map((cat) => ({
      ...cat,
      checked: false,
    }));
    const data = {
      id: "Category",
      name: "Category",
      type: "checkbox",
      items: preparedCategories,
    };
    setValue("items", [...formField.items, data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDist?.productCategories, setValue]);

  const fetchDistributorsProductsData = useCallback(
    (searchParams) => {
      setLoading(true);
      const params = {
        page: pagination.currentPage,
        limit: pagination.resultsPerPage,
        ...searchParams,
      };
      getDistributorProductsService(distributorId, params)
        .then((res) => {
          setVendorProductsList(res?.rows);
          dispatch(
            setPaginationAction({
              allPages: Math.ceil(res?.count / pagination.resultsPerPage),
            })
          );
          handleScrollToTop(topSectionOfList);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err?.message);
        })
        .finally(() => setLoading(false));
    },
    [dispatch, distributorId, pagination.currentPage, pagination.resultsPerPage]
  );

  useEffect(() => {
    if (pagination.currentPage === 1) {
      fetchDistributorsProductsData({ search: searchInputDebounced });
    } else {
      dispatch(setPaginationAction({ currentPage: 1 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchInputDebounced]);

  useEffect(() => {
    if (!distributorId) return;
    fetchDistributorsProductsData({ search: searchInputDebounced });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, distributorId, fetchDistributorsProductsData]);

  useEffect(() => {
    return () => dispatch(resetPaginationAction());
  }, [dispatch]);

  // useEffect(() => {
  //   const logo = currentDist?.profilePhoto?.fileName;
  //   if (!logo) return;

  //   dispatch(getDistributorLogoAction(photoUrl(logo)));

  //   return () => dispatch(getDistributorLogoAction(null));
  // }, [currentDist, dispatch]);

  const handleCloseContactVendorDialog = () => setContactVendorDialog(false);
  const handleClickViewProfile = () => {
    setContactVendorDialog(true);
  };

  return (
    <Box>
      <ContactDialog
        open={isContactVendorDialog}
        supplier={currentDist}
        onClose={handleCloseContactVendorDialog}
      />
      <SimpleTab
        startText="Suppliers"
        endText={currentDist?.name}
        navigatePath="/suppliers"
      />
      <BrandHeader
        logo={photoUrl(currentDist?.profilePhoto?.fileName)}
        name={currentDist?.name}
        minimumOrderValue={currentDist?.minimumOrderValueAmount}
        address={`${currentDist?.shippingAddress?.city}, ${currentDist?.shippingAddress?.state}`}
        web={preparedWebsiteText(currentDist?.website)}
        productsCount={currentDist?._count?.products}
        aboutCompany={currentDist?.description}
        orderStatus={"-"}
        terms={"-"}
        onSearch={setProductsSearch}
        onClickViewProfile={handleClickViewProfile}
      />
      <Box display="flex" sx={{ width: "100%" }}>
        <Box
          sx={{
            pt: "20px",
            pr: 3,
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          {formField?.items?.length ? (
            formField?.items?.map((item, index) => (
              <ViewSettings
                key={item?.id}
                index={index}
                item={item}
                control={control}
              />
            ))
          ) : (
            <Box
              sx={{
                border: "1px solid #D5D9D9",
                borderRadius: "4px",
                width: "246px",
                textAlign: "center",
              }}
            >
              -
            </Box>
          )}
        </Box>
        <Box
          sx={{
            mt: "20px",
            maxHeight: "calc(100vh - 306px)",
            overflow: "overlay",
            width: "100%",
          }}
        >
          <Loader isLoading={isLoading} />
          <Box
            ref={topSectionOfList}
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(238px, 1fr))",
              gap: "15px",
            }}
          >
            {vendorProductsList?.map(
              ({
                id,
                name,
                manufacturer,
                wholesalePrice,
                childProducts,
                retailPrice,
                isMultiple,
                colorVariants,
                sizeVariants,
                photos,
              }) => {
                const variations = () => {
                  if (!isMultiple) return "";
                  const varText = [];
                  const colorsCount = colorVariants?.length;
                  const sizeCount = sizeVariants?.length;
                  if (colorsCount) varText.push(`${colorsCount} colors`);
                  if (sizeCount) varText.push(`${sizeCount} sizes`);
                  return varText.join(", ");
                };
                const getPrice = (price, key) => {
                  // if the product has variations shoud show the cheapest data of children
                  if (!isMultiple) return price ?? 0;
                  return Math.min(
                    ...childProducts.map((price) => price?.[key])
                  );
                };
                return (
                  <ProductCard
                    key={id}
                    bestSeller={false}
                    name={name}
                    manufacturer={manufacturer?.name}
                    wholesalePrice={getPrice(wholesalePrice, "wholesalePrice")}
                    retailPrice={getPrice(retailPrice, "retailPrice")}
                    img={photoUrl(photos?.[0]?.fileName)}
                    variations={variations()}
                  />
                );
              }
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
