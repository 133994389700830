import React from "react";

export const EmptyCartIcon = () => {
  return (
    <svg
      width="101"
      height="101"
      viewBox="0 0 101 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50.59" cy="47.163" r="31.653" fill="#ECF6EF" />
      <circle cx="20.921" cy="25.791" r="1.984" fill="#ECF6EF" />
      <circle cx="61.592" cy="11.813" r="3.697" fill="#ECF6EF" />
      <circle cx="23.897" cy="72.413" r="3.517" fill="#ECF6EF" />
      <circle cx="84.497" cy="64.117" r="3.517" fill="#ECF6EF" />
      <circle cx="77.644" cy="68.085" r="1.713" fill="#ECF6EF" />
      <path
        d="M52.398 17.05c-.104-.022-.104-.171 0-.193l1.581-.337a.1.1 0 0 0 .077-.075l.34-1.57c.022-.104.17-.104.193 0l.34 1.57a.1.1 0 0 0 .077.075l1.58.337c.105.022.105.171 0 .193l-1.58.337a.1.1 0 0 0-.076.075l-.34 1.57c-.023.104-.172.104-.194 0l-.34-1.57a.1.1 0 0 0-.077-.075zm-36.126 32.3c.04-.191.313-.191.353 0l.603 2.848a.18.18 0 0 0 .138.139l2.82.614c.188.041.188.311 0 .352l-2.82.614a.18.18 0 0 0-.138.14l-.603 2.848c-.04.19-.312.19-.353 0l-.603-2.849a.18.18 0 0 0-.138-.139l-2.818-.614c-.19-.04-.19-.31 0-.352l2.818-.614a.18.18 0 0 0 .138-.139zm62.606-22.725c.04-.191.313-.191.353 0l.352 1.661a.18.18 0 0 0 .138.14l1.65.358c.188.042.188.312 0 .353l-1.65.36a.18.18 0 0 0-.138.138l-.352 1.662c-.04.19-.312.19-.353 0l-.351-1.662a.18.18 0 0 0-.139-.139l-1.649-.36c-.189-.04-.189-.31 0-.352l1.65-.359a.18.18 0 0 0 .138-.139z"
        fill="#8ECEA3"
      />
      <path
        d="m86.15 53.265.551 2.61 2.573.56-2.573.56-.552 2.61-.552-2.61-2.572-.56 2.572-.56z"
        fill="#fff"
      />
      <path
        d="M68.536 32.825 25.88 49.147l9.108 25.34 5.501 2.435 6.854 1.443 6.944-.27 4.058-.722 3.607-1.082 5.952-3.066 5.05-3.968.39-.504a62.6 62.6 0 0 0 5.832-9.055z"
        fill="url(#a)"
      />
      <path
        d="m29.128 41.933-4.51-2.525 39.138-14.79-2.074 5.141 1.533 4.78-32.374 12.354z"
        fill="#BDE4CB"
      />
      <path
        d="M34.809 74.397a37.6 37.6 0 0 0 12.62 3.85l.11.013h3.137c2.35 0 4.694-.25 6.992-.747l.778-.168a27.4 27.4 0 0 0 11.906-5.86l.485-.411a30.7 30.7 0 0 0 7.542-9.539l.978-1.927M25.535 48.771l9.455 25.8M68.911 32.81l10.372 27.1M23.912 39.483l40.399-15.18M25.326 49.236l43.017-16.59L62 29.703a.36.36 0 0 1-.173-.483l2.208-4.621m-39.76 14.759 4.547 2.438a.36.36 0 0 1 .15.481l-3.437 6.765m-1.442-10.01 2.93 7.324m2.015-4.152 1.803 5.05m31.022-17.675 1.803 5.23m.402-10.127 2.589 7.136"
        stroke="#8ECEA3"
        strokeWidth=".721"
      />
      <circle cx="42.474" cy="46.803" r="1.894" fill="#BDE4CB" />
      <circle cx="39.769" cy="37.063" r="1.353" fill="#ECF6EF" />
      <circle cx="54.378" cy="42.294" r="1.894" fill="#BDE4CB" />
      <circle cx="48.065" cy="33.997" r="1.353" fill="#ECF6EF" />
      <defs>
        <linearGradient
          id="a"
          x1="52.529"
          y1="32.825"
          x2="52.529"
          y2="78.365"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECF6EF" />
          <stop offset="1" stopColor="#C8EAD4" />
        </linearGradient>
      </defs>
    </svg>
  );
};
