/* eslint-disable react/prop-types */
import React from "react";

export const TriangleRightIcon = ({
  width = 3.92,
  height = 7.85,
  color = "#5F6267",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 3.92 7.85"
    >
      <path
        id="Icon_ionic-md-arrow-dropright"
        data-name="Icon ionic-md-arrow-dropright"
        d="M13.5,9l3.92,3.925L13.5,16.85Z"
        transform="translate(-13.5 -9)"
        fill={color}
      />
    </svg>
  );
};
