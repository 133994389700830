import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { separateNumWithComma } from "./helpers";

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  const clearHook = useCallback(() => {
    ref.current = null;
  }, []);
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return { item: ref.current, clearHook };
};

export const useShowProductsWithoutLogIn = () => {
  const { distributor_name } = useParams();
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const isShowProductsWithoutLogIn = !currentUser && !!distributor_name;
  const previewLink = `/preview/${distributor_name}`;
  return { isShowProductsWithoutLogIn, distributor_name, previewLink };
};

const preparePrice = (prices) => {
  if (Array.isArray(prices)) {
    const sortedPrices = prices.sort((a, b) => a - b);
    const getPricesValues =
      sortedPrices.length === 1
        ? sortedPrices
        : [sortedPrices[0], sortedPrices[sortedPrices?.length - 1]];

    return getPricesValues?.length === 1
      ? `$${separateNumWithComma(getPricesValues[0])}`
      : `$${separateNumWithComma(getPricesValues[0])} - $${separateNumWithComma(
          getPricesValues[1]
        )}`;
  }

  if (prices === null || (Number.isNaN(prices) && !Number(prices))) return "-";
  return `$${separateNumWithComma(prices)}`;
};

export const useRangePrices = ({ prices }) => {
  return preparePrice(prices);
};
