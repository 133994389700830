export const cl = {
  contentWrapper: {
    gap: "24px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
      width: "0px",
    },
  },

  moqWrapper: {
    width: "100%",
    zIndex: 100,
    backgroundColor: "#F8F8F8",
    p: "8px 16px",
    top: "43.5px",
    left: 0,
  },
};
