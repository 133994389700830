import { useContext, useMemo } from "react";
import { bool, func, shape, string, arr } from "prop-types";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { AddressItem } from "./AddressItem";
import { cl } from "../../../../ViewOrderPage.styles";
import {
  CheckmarkIcon,
  EmailIcon,
  InformationIcon,
  PhoneIcon,
} from "components/Icons";
import { OrderContext } from "pages/ViewOrderPage/ViewOrderPage.hooks";
import useStyles from "./styles";
import { StyledTooltip } from "components";
import {
  getFormattedDate,
  getPaymentDue,
  setPhoneNumberMask,
} from "helpers/helpers";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { currentUserSelector } from "redux/selectors/auth";
import moment from "moment";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const AddressesBlock = ({
  type,
  onClickContinueShopping,
  acceptPayment,
  orderDirect,
}) => {
  const { orderData } = useContext(OrderContext);
  const classes = useStyles();
  const { order } = useMemo(() => orderData, [orderData]);
  const { currentUser } = useSelector(selector);
  const { timeZone } = currentUser || {};

  const matchOrderDirect = useMemo(
    () =>
      orderDirect.find(
        ({ distributor }) => distributor?.id === order?.distributor?.id
      ),
    [order?.distributor?.id, orderDirect]
  );

  const hidePayWithCredit = useMemo(() => {
    return !(
      !!matchOrderDirect &&
      !!matchOrderDirect?.distributor?.allowStoreCreditCardPayments
    );
  }, [matchOrderDirect]);

  const {
    AddressesBlock: { btn, btnText },
  } = cl;

  const shippingAddressSecondLine = useMemo(
    () =>
      `${order?.customer?.shippingAddress?.appartement || ""} ${
        order?.customer?.shippingAddress?.street
      }`,
    [
      order?.customer?.shippingAddress?.appartement,
      order?.customer?.shippingAddress?.street,
    ]
  );
  const shippingAddressBottomLine = useMemo(
    () =>
      `${order?.customer?.shippingAddress?.city}, ${order?.customer?.shippingAddress?.state} ${order?.customer?.shippingAddress?.zip}`,
    [
      order?.customer?.shippingAddress?.city,
      order?.customer?.shippingAddress?.state,
      order?.customer?.shippingAddress?.zip,
    ]
  );
  const billingAddressSecondLine = useMemo(
    () =>
      `${order?.customer?.billingAddress?.appartement || ""} ${
        order?.customer?.billingAddress?.street
      }`,
    [
      order?.customer?.billingAddress?.appartement,
      order?.customer?.billingAddress?.street,
    ]
  );
  const billingAddressBottomLine = useMemo(
    () =>
      `${order?.customer?.billingAddress?.city}, ${order?.customer?.billingAddress?.state} ${order?.customer?.billingAddress?.zip}`,
    [
      order?.customer?.billingAddress?.city,
      order?.customer?.billingAddress?.state,
      order?.customer?.billingAddress?.zip,
    ]
  );

  const contactAddressSecondLine = useMemo(
    () => order?.contactDuplicate?.name,
    [order?.contactDuplicate?.name]
  );

  const contactEmail = useMemo(
    () => order.contactDuplicate?.email,
    [order.contactDuplicate?.email]
  );

  const contactPhone = useMemo(
    () => order.contactDuplicate?.phone,
    [order.contactDuplicate?.phone]
  );

  const paymentTerm = useMemo(
    () => order.paymentTermsDuplicate,
    [order.paymentTermsDuplicate]
  );

  const contactAddressBottomLine = useMemo(() => {
    // `${order?.customer?.contacts?.[0]?.email} | ${order?.customer?.contacts?.[0]?.phone}`,

    return (
      <Box display="flex" gap={1}>
        <StyledTooltip
          arrow
          placement="top"
          title={contactEmail || "No email available"}
        >
          <Box className={classes.contactButtonWrap}>
            <IconButton
              onClick={() => (window.location.href = `mailto:${contactEmail}`)}
              className={classes.contactButton}
              disabled={!contactEmail}
            >
              <EmailIcon />
            </IconButton>
          </Box>
        </StyledTooltip>
        <StyledTooltip
          arrow
          placement="top"
          title={
            contactPhone
              ? setPhoneNumberMask(contactPhone)
              : "No phone available"
          }
        >
          <Box className={classes.contactButtonWrap}>
            <IconButton
              className={classes.contactButton}
              disabled={!contactPhone}
            >
              <PhoneIcon />
            </IconButton>
          </Box>
        </StyledTooltip>
      </Box>
    );
  }, [
    classes.contactButton,
    classes.contactButtonWrap,
    contactEmail,
    contactPhone,
  ]);

  const dueData = useMemo(() => {
    return order?.orderStatus === "CANCELED" ||
      order?.paymentStatus === "PAID" ||
      !order?.paymentTermsDuplicate
      ? false
      : getPaymentDue(
          order?.createdAt,
          order?.paymentTermsDuplicate?.daysInvoices
        );
  }, [
    order?.createdAt,
    order?.orderStatus,
    order?.paymentStatus,
    order?.paymentTermsDuplicate,
  ]);

  const getPatStatus = (status, order) => {
    const { orderPayments } = order || {};

    const lastPartiallyPayment = orderPayments?.length
      ? orderPayments[orderPayments?.length - 1]
      : null;

    if (dueData?.overdue)
      return (
        <Box mt={1} display="flex" alignItems="center" gap={0.5}>
          <InformationIcon size="15.33" stroke="#FF6969" />
          <Typography fontSize={12} color="#FF6969">
            {dueData?.text}
          </Typography>
        </Box>
      );

    return (
      <Box mt={1}>
        {status === "PARTIALLY_PAID" && (
          <Typography fontSize={15} fontWeight={300} color="#A8A8A8">
            Partially Paid on{" "}
            {moment(
              getFormattedDate(lastPartiallyPayment?.date, timeZone)
            ).format("MMM D, YY")}
          </Typography>
        )}
        {status === "PAID" && (
          <Typography fontSize={15} fontWeight={300} color="#A8A8A8">
            Paid on{" "}
            {moment(
              getFormattedDate(lastPartiallyPayment?.date, timeZone)
            ).format("MMM D, YY")}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex">
        <AddressItem
          title="Shipping address"
          secondLine={shippingAddressSecondLine}
          bottomLine={shippingAddressBottomLine}
          // onClickEdit={handleShippingEdit}
        />
        <AddressItem
          title="Billing address"
          secondLine={billingAddressSecondLine}
          bottomLine={billingAddressBottomLine}
        />

        <AddressItem
          title="Payment term"
          secondLine={paymentTerm?.name}
          btnLine={
            !!acceptPayment?.showBtn &&
            type && (
              <Box>
                {acceptPayment?.paymentStatus === "PAID" ? (
                  <Box
                    sx={{
                      border: "0.5px solid #70707050",
                      borderRadius: "4px",
                      width: "clamp(135px, 10vw, 166px)",
                      height: "38px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box>
                      <CheckmarkIcon
                        width="14.37"
                        height="10.28"
                        stroke="#52B985"
                      />
                      <CheckmarkIcon
                        width="14.37"
                        height="10.28"
                        stroke="#52B985"
                        style={{ marginLeft: "-10px" }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "clamp(13px, 0.8vw, 15px)",
                        fontWeight: 500,
                        color: "#53B986",
                      }}
                    >
                      Paid
                    </Typography>
                  </Box>
                ) : hidePayWithCredit ? null : (
                  <Button
                    sx={btn}
                    disabled={order?.orderStatus === "CANCELED"}
                    variant="contained"
                    onClick={acceptPayment.openDialog}
                  >
                    <Typography sx={btnText}>Pay Invoice</Typography>
                  </Button>
                )}
                {getPatStatus(acceptPayment?.paymentStatus, order)}
              </Box>
            )
          }
        />

        <AddressItem
          title="Customer contact"
          secondLine={contactAddressSecondLine}
          bottomLine={contactAddressBottomLine}
        />
      </Box>

      {/* Temporarily hidden SD-4442 */}
      {/* {type === "orders_page" && (
        <Button
          sx={{ width: "166px", height: "38px" }}
          variant="outlined"
          color="grey"
          onClick={onClickReturnOrder}
        >
          <Typography sx={btnText}>Start A Return</Typography>
        </Button>
      )} */}
      <Box display="flex" gap={1}>
        {/* {!!acceptPayment?.showBtn && type && (
          <StyledTooltip
            title="Order is paid"
            placement="top"
            isTooltip
            arrow
            disableHoverListener={acceptPayment?.paymentStatus !== "PAID"}
          >
            <Box>
              <Button
                disabled={acceptPayment?.paymentStatus === "PAID"}
                sx={btn}
                variant="outlined"
                color="grey"
                onClick={acceptPayment.openDialog}
              >
                <Typography sx={btnText}>Accept payment</Typography>
              </Button>
            </Box>
          </StyledTooltip>
        )} */}
        {!type && (
          <Button
            sx={btn}
            variant="outlined"
            color="grey"
            onClick={onClickContinueShopping}
          >
            <Typography sx={btnText}>Continue Shopping</Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

AddressesBlock.propTypes = {
  onClickEdit: func,
  type: string,
  onClickReturnOrder: func,
  onClickContinueShopping: func,
  acceptPayment: shape({
    showBtn: bool,
    openDialog: func,
  }),
  orderDirect: arr,
};
AddressesBlock.defaultProps = {
  onClickEdit: () => {},
  type: "",
  onClickReturnOrder: () => {},
  onClickContinueShopping: () => {},
  acceptPayment: {
    showBtn: false,
    openDialog: () => {},
  },
  orderDirect: [],
};
