import { Loader, StyledTooltip } from "components";
import {
  DescriptionBlock,
  LastPurchasedBlock,
  PhotosBlock,
  ProductDetailsSwitcherTab,
  ProductsBlock,
  SubtitleBlock,
  TitleBlock,
} from "./components";
import { Box, Button, Grid } from "@mui/material";
import { useContext } from "react";
import { ProductContext } from "../../ProductDetailsPage";

export const DesktopViewProduct = () => {
  const {
    loading,
    breadCrumbs,
    productPhotos,
    product,
    handleViewProfile,
    lastPurchasedDate,
    orderId,
    productsList,
    control,
    currentDraft,
    setShowMSRP,
    selectedProducts,
    handleAddToCart,
  } = useContext(ProductContext);

  return loading ? (
    <Loader isLoading={loading} />
  ) : (
    <>
      <ProductDetailsSwitcherTab {...{ breadCrumbs }} />

      <Grid container mt={5}>
        <PhotosBlock {...{ productPhotos }} />
        <Grid
          xs={6.82}
          item
          paddingTop="0"
          paddingRight="6%"
          paddingBottom="0"
          paddingLeft="4%"
        >
          <TitleBlock
            productName={product?.name}
            setContactDialogOpen={handleViewProfile}
            minimumOrderValueAmount={product?.minimumOrderValueAmount}
          />

          <SubtitleBlock
            manufacturerName={product?.manufacturer?.name}
            distributorName={product?.distributorName}
            minimumOrderValueAmount={product?.minimumOrderValueAmount}
          />

          {lastPurchasedDate ? (
            <LastPurchasedBlock
              createdAt={lastPurchasedDate}
              orderId={orderId}
            />
          ) : null}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {!!productsList?.length && (
              <ProductsBlock
                productId={product?.id}
                productMinOrderQTY={product?.minOrderQTY}
                productValue={product?.value}
                productSellingOutOfStock={product?.sellingOutOfStock}
                {...{ productsList, control }}
                currentDraftProducts={currentDraft?.data?.productsData}
                showMSRP={setShowMSRP}
              />
            )}
          </Box>

          {/* {discountState.discounts?.length ? (
              <>
                <DiscountHeader />
                <DiscountBlock
                  {...{
                    discountState,
                    discountActiveId,
                    setDiscountActiveId,
                    productsList,
                  }}
                />
              </>
            ) : null} */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledTooltip
              arrow
              placement="top"
              title="Select product quantity"
              disableHoverListener={!!selectedProducts.length}
            >
              <Box width="100%">
                <Button
                  sx={{
                    height: "56px",
                    fontSize: "24px",
                    fontWeight: 500,
                    boxShadow: "none",
                    color: "#FFFFFF",
                  }}
                  fullWidth
                  color="primary"
                  onClick={handleAddToCart}
                  variant="contained"
                  disabled={!selectedProducts.length}
                >
                  Add to Cart
                </Button>
              </Box>
            </StyledTooltip>
          </Box>

          {!!product?.description && (
            <DescriptionBlock productDescription={product?.description} />
          )}

          {/* <Box
              ref={boxRef}
              display="flex"
              sx={{
                width: "280px",
                height: "70px",
                position: "absolute",
                top: "40%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Popper
                open={!!boxRef.current && openToolTip}
                anchorEl={boxRef.current}
                placement="bottom"
                id={openToolTip ? "transition-popper" : undefined}
                transition
                sx={{ width: "280px", height: "70px" }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={200}>
                    <Box
                      sx={{
                        backgroundColor: "#363531",
                        p: "2px 20px 4px 20px",
                        borderRadius: "4px",
                        display: "flex",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <CheckIcon />
                      <Typography
                        sx={{
                          fontSize: "28px",
                          fontWeight: 500,
                          color: "#FFF",
                          ml: 1,
                        }}
                      >
                        Added to Cart
                      </Typography>
                    </Box>
                  </Fade>
                )}
              </Popper>
            </Box> */}
        </Grid>
      </Grid>
    </>
  );
};
