import { useContext } from "react";

import { OrderContext } from "pages/ViewOrderPage/ViewOrderPage.hooks";

import { cl } from "./styles";

import { Stack, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export const ContactBlockItem = () => {
  const { orderData } = useContext(OrderContext);

  const { contactDuplicate } = orderData?.order || {};
  const { name, role, email } = contactDuplicate || {};

  return (
    <>
      {contactDuplicate && (
        <Stack direction="row" sx={cl.contactWrapper}>
          <AccountCircleIcon />
          <Stack width="calc(100% - 28px)">
            <Typography noWrap sx={cl.contactName}>
              {name} • {role}
            </Typography>
            <Typography
              noWrap
              sx={{
                ...cl.contactName,
                color: "rgba(0, 0, 0, 0.40)",
                fontWeight: 400,
              }}
            >
              {email}
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
};
