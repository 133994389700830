/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    amount: Yup.number()
      .required()
      .test("amountValidation", (value) => {
        const AMOUNT_REGEXP = /\d/;
        return AMOUNT_REGEXP.test(value) && value >= 0;
      }),
    date: Yup.string().required(),
    check: Yup.string().when("paymentType", {
      is: "CHECK",
      then: Yup.string().required("This field is required"),
    }),
    // nameOnCard: Yup.string().when("paymentType", {
    //   is: "CREDIT_CARD",
    //   then: Yup.string().required("This field is required"),
    // }),
    // cardNumber: Yup.string().when("paymentType", {
    //   is: "CREDIT_CARD",
    //   then: Yup.string().required("This field is required"),
    // }),
    // expirationDate: Yup.string().when("paymentType", {
    //   is: "CREDIT_CARD",
    //   then: Yup.string().required("This field is required"),
    // }),
    // securiryCode: Yup.string().when("paymentType", {
    //   is: "CREDIT_CARD",
    //   then: Yup.string()
    //     .required("This field is required")
    //     .test("securiryCodeValidation", (value) => {
    //       return value.length <= 6;
    //     }),
    // }),
    // zipCode: Yup.string().when("paymentType", {
    //   is: "CREDIT_CARD",
    //   then: Yup.string()
    //     .required("This field is required")
    //     .test("securiryCodeValidation", (value) => {
    //       return value.length <= 10;
    //     }),
    // }),
  });
