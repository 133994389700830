import { Box } from "@mui/material";
import { LogoBlock } from "pages/ProfilePage/components";

export const MobileLogo = () => {
  return (
    <Box pt={1}>
      <LogoBlock isMobile={true} />
    </Box>
  );
};
