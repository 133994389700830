import { MobileAccordion, MobileLogo } from "./components";

export const SummaryTab = () => {
  return (
    <>
      <MobileLogo />
      <MobileAccordion />
    </>
  );
};
