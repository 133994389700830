import { number, object, string } from "prop-types";

import { Grid, Typography } from "@mui/material";

export const VendorsHeaderItem = ({ name, size, style }) => {
  return (
    <Grid
      sx={{ ...style }}
      xs={size}
      item
      variant="headersText"
      component={Typography}
    >
      {name}
    </Grid>
  );
};
VendorsHeaderItem.propTypes = {
  name: string,
  size: number,
  style: object,
};
VendorsHeaderItem.defaultProps = {
  name: "",
  size: 2,
  style: {},
};
