import { number, object } from "prop-types";
import moment from "moment";
import { cl } from "./styles";

import { Stack } from "@mui/material";
import { PaymentDetailsBlock } from "./PaymentDetailsBlock";
import { normalizeSnakeCaseString } from "helpers/helpers";
import { shippingLinks } from "utils/constants";

export const FulFillmentItem = ({ delivery, totalOrderQuantity }) => {
  const {
    totalQuantity,
    delivererDuplicate,
    deliveryMethod,
    shippingCarrierDuplicate,
    date,
    trackingId,
    shippingCarrier,
  } = delivery;

  const getLink = (id, shippingName) => {
    const url = shippingLinks[shippingName];
    return url ? window.open(`${url}${id}`, "_blank") : "";
  };

  const DELIVERY_DATA = [
    {
      title: "VIA",
      value: `${normalizeSnakeCaseString(deliveryMethod)}`,
    },
    {
      title: "Fulfilled by",
      value: `${
        delivererDuplicate?.name || shippingCarrierDuplicate.name || "-"
      }`,
    },
    {
      title: "Date",
      value: `${moment(date).format("MM/DD/YYYY")}`,
    },
    {
      title: "Quantity",
      value: `${totalQuantity} of ${totalOrderQuantity}`,
    },
    ...(trackingId
      ? [
          {
            title: "Tracking ID",
            valueSx: {
              textDecoration: "underline",
              color: "rgba(64, 154, 101, 0.80)",
            },
            onClick: () =>
              getLink(
                trackingId,
                shippingCarrier?.name || shippingCarrierDuplicate?.name
              ),
            value: trackingId,
          },
        ]
      : []),
  ];

  return (
    <Stack
      direction="column"
      sx={{ ...cl.cardWrapper, minHeight: "100%" }}
      mx="10px"
    >
      <Stack width="100%" direction="row" flexWrap="wrap" gap="20px">
        {DELIVERY_DATA.map(({ title, value, onClick, valueSx }) => (
          <PaymentDetailsBlock
            key={title}
            sx={{ width: "calc(50% - 10px)" }}
            {...{ valueSx, onClick, value, title }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

FulFillmentItem.propTypes = {
  delivery: object,
  index: number,
  totalOrderQuantity: number,
};
