import { Box, IconButton } from "@mui/material";
import { ProfileContext } from "pages/ProfilePage/NewProfile";
import { bool, object, func } from "prop-types";
import { useContext } from "react";
import { useWatch } from "react-hook-form";
import { PlusIcon } from "../../../../../../components/Icons";
import TableHeader from "../TableHeader";
import LocationsItem from "./components/LocationsItem";

export const LocationsTab = ({ isEdit, control, setValue }) => {
  const formField = useWatch({ control });

  const {
    handleAddLocations,
    handleDeleteLocation,
    fields,
    handleChangeDefault,
  } = useContext(ProfileContext);

  return (
    <Box>
      <TableHeader
        title="Shipping Locations"
        addElement={
          <IconButton onClick={handleAddLocations}>
            <PlusIcon stroke="#eeeeee" fill="#47A06D" width="20" height="20" />
          </IconButton>
        }
        isEdit={isEdit}
      />

      {fields.map((location, i) => (
        <LocationsItem
          key={location.id}
          title={formField.locations[i]?.name || "Store Location"}
          isEdit={isEdit}
          address={formField.locations[i]}
          control={control}
          fieldName={`locations.${i}`}
          setValue={setValue}
          handleDeleteLocation={() => handleDeleteLocation(i)}
          handleChangeDefault={handleChangeDefault}
          index={i}
        />
      ))}
    </Box>
  );
};

LocationsTab.propTypes = {
  isEdit: bool,
  control: object,
  setValue: func,
};

export default LocationsTab;
