export const TABS = ["Wallet", "Transactions"];

export const MOBILE_TABS = [
  {
    name: "Wallet",
    value: "Wallet",
    mobile: {
      show: true,
      disabled: false,
    },
  },
  {
    name: "Transactions",
    value: "Transactions",
    mobile: {
      show: true,
      disabled: false,
    },
  },
];

export const defaultValues = {
  fid: "",
  name: "",
  number: "",
  exp: "",
  cvc: "",
  defaultMethod: false,
  billingAddress: {
    formatted_address: "",
    lat: null,
    lng: null,
    zip: "",
    street: "",
    state: "",
    city: "",
    appartement: "",
  },
};
