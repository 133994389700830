import { useContext, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { photoUrl } from "helpers/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs, Navigation } from "swiper/modules";
import { ProductContext } from "pages/ProductsPage/components/ProductDetailsPage/ProductDetailsPage";
import { ImagePlaceholder } from "components";
import { TitleBlock } from "../TitleBlock/TitleBlock";

export const CarouselBlock = () => {
  const { productsList, setCurrentProductIndex, initialSlide } =
    useContext(ProductContext);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [swiperState, setSwiperState] = useState(null);

  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current?.swiper) {
      setSwiperState(swiperRef.current.swiper);
    }
  }, []);

  useEffect(() => {
    if (initialSlide > 0 && swiperState) {
      swiperState.slideTo(initialSlide, 500);
    }
  }, [initialSlide, swiperState]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "relative",
          "& .mySwiper .swiper-slide": {
            height: "60px",
            maxHeight: "60px",
          },
        }}
      >
        <Swiper
          ref={swiperRef}
          spaceBetween={10}
          thumbs={{
            swiper: thumbsSwiper,
            autoScrollOffset: 0,
            multipleActiveThumbs: false,
          }}
          setWrapperSize={true}
          modules={[Thumbs]}
          className="mySwiper2"
          onSlideChange={(swiper) => setCurrentProductIndex(swiper.activeIndex)}
        >
          {productsList.map((product) => (
            <SwiperSlide key={product?.id || `product-${product?.index}`}>
              {product?.photos?.[0]?.fileName ? (
                <Box
                  sx={{
                    borderRadius: "12px",
                    width: "100%",
                    aspectRatio: "1 / 1",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  component="img"
                  src={photoUrl(product.photos[0].fileName)}
                  alt={product.photos[0].fileName}
                />
              ) : (
                <ImagePlaceholder
                  width="100%"
                  height="100%"
                  wrapperProps={{ width: "100%", mb: "32px" }}
                  style={{
                    objectFit: "cover",
                    borderWidth: "0",
                    borderRadius: "12px",
                  }}
                  rx={0}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>

        <Box mt="12px" mb="0px">
          <TitleBlock />
        </Box>

        <Swiper
          spaceBetween={5}
          slidesPerView={4}
          slideToClickedSlide={true}
          modules={[Navigation]}
          navigation={{
            nextEl: ".swiper-button-next-unique",
            prevEl: ".swiper-button-prev-unique",
          }}
          className="mySwiper swiper-button-unique"
          onSwiper={setThumbsSwiper}
        >
          {productsList.map((product, i) => (
            <SwiperSlide key={product?.id || `product-thumb-${product?.index}`}>
              <Box
                width="100%"
                height="100%"
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                borderRadius="8px"
                sx={{ overflow: "hidden", px: 1 }}
                onClick={() => {
                  if (
                    i ===
                    thumbsSwiper?.visibleSlidesIndexes[
                      thumbsSwiper?.visibleSlidesIndexes?.length - 1
                    ]
                  ) {
                    thumbsSwiper.slideNext();
                  }
                  if (i === thumbsSwiper?.visibleSlidesIndexes[0]) {
                    thumbsSwiper.slidePrev();
                  }
                }}
              >
                {!!product?.sku && (
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    color="#2F3040"
                    noWrap
                  >
                    {product?.sku}&nbsp;
                  </Typography>
                )}
                {!!product?.color && (
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    color="#2F3040"
                    noWrap
                  >
                    {product?.color}
                  </Typography>
                )}
                {!!product?.size && (
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    color="#2F3040"
                    noWrap
                  >
                    {product?.size}
                  </Typography>
                )}
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};
