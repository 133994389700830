import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { bool, func, object, array, string } from "prop-types";
import { useEffect } from "react";
import { useCallback } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { LargeCheckbox } from "../../Checkboxes/Checkboxes";
import { CrossIcon } from "../../Icons";
import { StyledSelect } from "../../Selects/Selects";
import { StyledButton } from "../../StyledButton/StyledButton";
import { StyledTextField } from "../../TextFields/TextFields";
import { validationSchema } from "./ContactPopup.validations";
import { TYPES } from "./contactTypes";
import useStyles from "./styles";

const ContactEditPopup = ({
  type,
  isOpen,
  editContact,
  handleClose,
  handleAddContact,
  contacts,
}) => {
  const classes = useStyles();

  const { control, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      fid: editContact?.fid || Date.now(),
      id: editContact?.id || "",
      email: editContact?.email || "",
      name: editContact?.name || "",
      phone: editContact?.phone || "",
      role: editContact?.role
        ? ["owner", "manager", "bookkeeper"].includes(editContact?.role)
          ? editContact?.role
          : "custom"
        : "",
      defaultContact: !!editContact?.defaultContact,
      custom: editContact?.role
        ? ["owner", "manager", "bookkeeper"].includes(editContact?.role)
          ? ""
          : editContact?.role
        : "",
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({
    control,
  });

  const isCustom = (role) => {
    return !["owner", "manager", "bookkeeper", ""].includes(role);
  };

  const onSubmit = useCallback(
    (data) => {
      const { name, phone, email, defaultContact, custom, role, fid, id } =
        data;
      if (data.role === "custom") {
        handleAddContact({
          name,
          phone,
          email,
          defaultContact,
          role: custom,
          fid,
          id,
        });
      } else {
        handleAddContact({
          name,
          phone,
          email,
          defaultContact,
          role,
          fid,
          id,
        });
      }
      handleClose();
    },
    [handleAddContact, handleClose]
  );

  useEffect(() => {
    reset({
      fid: editContact?.fid || Date.now(),
      id: editContact?.id || "",
      email: editContact?.email || "",
      name: editContact?.name || "",
      phone: editContact?.phone || "",
      role: editContact?.role
        ? ["owner", "manager", "bookkeeper"].includes(editContact?.role)
          ? editContact?.role
          : "custom"
        : "",
      defaultContact: contacts.length ? !!editContact?.defaultContact : true,
      custom: editContact?.role
        ? ["owner", "manager", "bookkeeper"].includes(editContact?.role)
          ? ""
          : editContact?.role
        : "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, isOpen, editContact?.fid, editContact?.id]);

  return (
    <Dialog open={isOpen}>
      <Box sx={{ borderBottom: "0.5px solid #D5D9D9" }}>
        <IconButton
          sx={{ position: "absolute", top: 14, right: 14, p: 0 }}
          onClick={handleClose}
        >
          <CrossIcon />
        </IconButton>
        <Typography
          p="14px 0 12px 26px"
          fontWeight="400"
          fontSize="17px"
          color="#3F3F3F"
        >
          {editContact ? `Edit ${type} contact` : `New ${type} contact`}
        </Typography>
      </Box>
      <DialogContent sx={{ p: "10px 42px 24px 47px", minWidth: "413px" }}>
        <Grid display="flex" sx={{ flexDirection: "column" }} container>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                formSx={{ mt: "15px" }}
                fullWidth
                size="small"
                InputProps={{
                  className: classes.textInput,
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                label="Full name"
                error={error ? error.message : ""}
                {...field}
              />
            )}
            name="name"
            control={control}
          />

          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                formSx={{ mt: "26px" }}
                fullWidth
                size="small"
                InputProps={{
                  className: classes.textInput,
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                label="Phone number"
                error={error ? error.message : ""}
                {...field}
              />
            )}
            name="phone"
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                formSx={{ mt: "26px" }}
                fullWidth
                size="small"
                InputProps={{
                  className: classes.textInput,
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                label="Email address"
                error={error ? error.message : ""}
                {...field}
              />
            )}
            name="email"
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledSelect
                formSx={{
                  mt: "26px",
                  mb: "9px",
                  "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
                    paddingLeft: 0,
                  },
                }}
                label="Contact type"
                leftSidedIcon
                error={error ? error.message : ""}
                {...field}
              >
                {TYPES.map((type) => {
                  const key = Object.keys(type);
                  return (
                    <MenuItem key={key} value={key[0]}>
                      {type[key]}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            )}
            name="role"
            control={control}
          />

          {isCustom(formField?.role) && (
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  formSx={{ mt: "15px" }}
                  fullWidth
                  size="small"
                  InputProps={{
                    className: classes.textInput,
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  label="Custom"
                  error={error ? error.message : ""}
                  {...field}
                />
              )}
              name="custom"
              control={control}
            />
          )}

          <Controller
            render={({ field }) => (
              <LargeCheckbox
                disabled={!contacts.length}
                checked={!!field.value}
                formSx={{ ml: "auto", mr: 0, mb: "13px" }}
                label={
                  <Typography color="#9C9C94" fontSize="11px" fontWeight="400">
                    default contact
                  </Typography>
                }
                size={16}
                {...field}
              />
            )}
            name="defaultContact"
            control={control}
          />

          <StyledButton
            sx={{ height: 34, mb: "13px", boxShadow: "none" }}
            onClick={handleSubmit(onSubmit)}
            label={
              <Typography fontSize="15px" fontWeight="normal" color="#ffffff">
                {editContact ? "Save contact" : "Add Contact"}
              </Typography>
            }
            variant="contained"
          />
          <StyledButton
            sx={{ height: 34, borderColor: "#D5D9D9" }}
            onClick={handleClose}
            label={
              <Typography
                fontSize="15px"
                sx={{
                  color: "#5f6267",
                }}
                fontWeight="normal"
              >
                Cancel
              </Typography>
            }
            variant="outlined"
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ContactEditPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  handleAddContact: func,
  editContact: object,
  contacts: array,
  type: string,
};

ContactEditPopup.defaultProps = {
  isOpen: false,
  contacts: [],
  type: "customer",
};

export default ContactEditPopup;
