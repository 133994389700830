import { useCallback, useContext } from "react";
import PropTypes, { func, string, object } from "prop-types";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
} from "@mui/material";
import { StyledButton } from "components";
import { CrossIcon } from "components/Icons";

import { setCardName } from "helpers/helpers";
import { ConfirmContext } from "pages/MasterPage/MasterPage";

export const UniversalConfirmDialog = () => {
  const { confirmState, setConfirmState } = useContext(ConfirmContext);

  const handleConfirmClose = useCallback(() => {
    setConfirmState((prev) => ({
      ...prev,
      isOpen: false,
    }));
  }, [setConfirmState]);

  return (
    <Dialog open={!!confirmState.isOpen} scroll="body">
      <DialogTitle
        sx={{
          fontSize: "20px",
          color: "#080808",
          fontWeight: 400,
          p: "12px 30px 10px 30px",
          position: "relative",
        }}
      >
        <Box display="flex" alignItems="center">
          {confirmState.title}
        </Box>
        <IconButton
          sx={{ position: "absolute", right: 4, top: 5 }}
          onClick={handleConfirmClose}
        >
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          p: "16px 25px 20px 30px",
          maxWidth: "484px",
          minWidth: "420px",
          borderBottom: "0.5px solid #D5D9D9",
        }}
      >
        <Box
          style={{
            color: "#707070",
            fontWeight: "400",
            fontSize: "15px",
          }}
        >
          Are you sure you want to delete{" "}
          <Typography
            style={{
              fontWeight: "500",
              color: "#000000",
              display: "contents",
            }}
          >
            {setCardName(confirmState?.cardStateItem?.brand)} ending in{" "}
            {confirmState?.cardStateItem?.number?.slice(-4) ||
              confirmState?.cardStateItem?.last4}
            ?
          </Typography>{" "}
          Removing this payment method will not cancel any of your open orders
          that use this method.
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "14px 25px 15px 30px",
        }}
      >
        <Box>
          <StyledButton
            sx={{
              width: "98px",
              color: "#6A6A6A",
              borderColor: "#D4D4D4",
              height: "28px",
              ml: "auto",
            }}
            onClick={() =>
              setConfirmState((prev) => ({
                ...prev,
                isOpen: false,
              }))
            }
            fontSize="13px"
            label="Cancel"
            variant="outlined"
          />
          <StyledButton
            sx={{
              width: "98px",
              color: "#FFFFFF",
              height: "28px",
              boxShadow: "none",
              marginLeft: "8px",
              background: "#EB4233",
              "&:hover": {
                background: "#EB4233",
              },
            }}
            fontSize="13px"
            onClick={() =>
              confirmState.onConfirm(confirmState?.cardStateItem?.id)
            }
            variant="contained"
            label="Confirm"
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

UniversalConfirmDialog.propTypes = {
  text: PropTypes.oneOfType([string, object]),
  onCancel: func,
  onAccept: func,
};

UniversalConfirmDialog.defaultProps = {
  // isOpen: false,
  text: "Are you sure?",
};
