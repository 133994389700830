import { useContext } from "react";
import { Box } from "@mui/material";
import { ProductContext } from "../../ProductDetailsPage";
import { Loader } from "components";
import {
  ActionsBlock,
  CarouselBlock,
  FooterBlock,
  PriceBlock,
} from "./components";

export const MobileViewProduct = () => {
  const { loading, product, productsList } = useContext(ProductContext);

  return (
    <Box
      sx={{
        height: "100svh",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
          width: "0px !important",
        },
      }}
    >
      {loading && !!productsList?.length ? (
        <Loader isLoading={loading} />
      ) : (
        <Box p="60px 16px 44px 16px">
          <CarouselBlock />

          <Box mt="2px" mb="12px">
            <PriceBlock />
          </Box>

          <Box mb="16px">
            <ActionsBlock />
          </Box>

          {product?.description && (
            <FooterBlock title="Product details" defaultExpanded>
              {product.description}
            </FooterBlock>
          )}
        </Box>
      )}
    </Box>
  );
};
