import { useEffect, useMemo, useRef, useState } from "react";
import { IconButton, Typography } from "@mui/material";
import PropTypes, { string, func, object, bool, number } from "prop-types";
import {
  uploadFile,
  uploadGenerateService,
  uploadPhotoService,
} from "../../services/files";
import { uploadProgress } from "../../helpers/helpers";
import { CrossIcon } from "../Icons";
import { CropperComponent } from "./CropperComponent";
import { error } from "helpers/notifications";
import { useSelector } from "react-redux";
import { firestore } from "../../firebase/notifications/config";
import { doc, onSnapshot } from "firebase/firestore";

export const UploadFile = ({
  label,
  onChange,
  accept,
  Wrapper,
  wrapperProps,
  path,
  isEmpty,
  icon,
  handleUploadFiles,
  fontSize,
  withCropper,
  settingsCropper,
  dialogSize,
  progressUpload,
  defaultAspect,
  setIsOpen,
  uploadData,
  preparingPhoto,
  setPreparingPhoto,
  ...props
}) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const [dialogState, setDialogState] = useState({
    open: false,
    file: null,
  });

  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const inputRef = useRef(null);

  const handleUploadPhoto = async (file) => {
    setIsUploading(true);
    progressUpload(true);

    if (uploadData?.type) {
      let preparedData = {
        appCustomerId: currentUser?.id,
        gallery: [
          {
            name: file.name,
            type: uploadData?.type,
          },
        ],
      };

      try {
        const uploadGenerate = await uploadGenerateService(preparedData);

        if (!uploadGenerate?.groupContentId)
          throw new Error("Something went wrong!");

        const groupContentId = uploadGenerate?.groupContentId;
        const uuid = uploadGenerate?.signedUrlItems?.length
          ? uploadGenerate?.signedUrlItems?.[0]?.uuid
          : null;
        const link = uploadGenerate?.signedUrlItems?.length
          ? uploadGenerate?.signedUrlItems?.[0]?.signedUrl
          : null;

        await uploadPhotoService({
          file,
          url: link,
          headers: {
            uuid,
            "Content-Type": file.type,
          },
          config: {
            onUploadProgress: (progressEvent) =>
              uploadProgress(progressEvent, setProgress),
          },
        });

        setPreparingPhoto(true);

        await onSnapshot(
          doc(firestore, "image-uploads", groupContentId),
          (doc) => {
            const res = doc?.data();

            if (res?.finalized) {
              const objectUrl = URL.createObjectURL(file);
              onChange({ fileName: objectUrl, uuid });
              if (groupContentId && uploadData?.setGroupContentId) {
                uploadData.setGroupContentId(groupContentId);
              }
              setPreparingPhoto(false);
            }
          }
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setProgress(0);
        setIsUploading(false);
        progressUpload(false);
      }
      return;
    }

    uploadFile(file, path, {
      onUploadProgress: (progressEvent) =>
        uploadProgress(progressEvent, setProgress),
    }).then((res) => {
      setProgress(0);
      setIsUploading(false);
      onChange(res);
      progressUpload(false);
    });
  };

  useEffect(() => {
    if (setIsOpen) setIsOpen(dialogState?.open);
  }, [dialogState?.open, setIsOpen]);

  const handleUpload = (e) => {
    const { files } = e.currentTarget;
    if (!files[0]) return;
    if (withCropper)
      setDialogState((prev) => ({ ...prev, open: true, file: files[0] }));

    if (!withCropper) handleUploadPhoto(files[0]);
  };

  const handleSaveImg = (imgData) => {
    imgData.then((res) => handleUploadPhoto(res));
  };

  const loadingProp = useMemo(
    () => (path === "product-photos" ? { loading: isUploading } : {}),
    [isUploading, path]
  );

  return (
    <>
      <CropperComponent
        open={dialogState.open}
        onClose={() => {
          setDialogState((prop) => ({ ...prop, open: false, file: null }));
          if (inputRef?.current?.value) inputRef.current.value = null;
        }}
        file={dialogState.file}
        handleSaveImg={handleSaveImg}
        settings={settingsCropper}
        size={dialogSize || ""}
        defaultAspect={defaultAspect}
      />
      <Wrapper
        onClick={() => {
          inputRef.current.click();
        }}
        {...wrapperProps}
        {...loadingProp}
      >
        {preparingPhoto ? (
          <Typography fontSize={fontSize}>Preparing</Typography>
        ) : (
          <Typography fontSize={fontSize}>
            {isUploading ? `Uploading: ${progress}%` : label}
          </Typography>
        )}
        {icon}
        <input
          type="file"
          hidden
          onChange={handleUploadFiles || handleUpload}
          accept={accept}
          ref={inputRef}
          {...props}
        />
        {!isEmpty && (
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              p: "4px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              onChange(null);
            }}
          >
            <CrossIcon />
          </IconButton>
        )}
      </Wrapper>
    </>
  );
};

UploadFile.propTypes = {
  label: string,
  accept: string,
  path: string,
  onChange: func,
  Wrapper: PropTypes.oneOfType([object, func]),
  wrapperProps: object,
  isEmpty: bool,
  icon: object,
  handleUploadFiles: func,
  fontSize: string,
  withCropper: bool,
  settingsCropper: object,
  dialogSize: string,
  progressUpload: func,
  defaultAspect: number,
  setIsOpen: func,
  uploadData: object,
  preparingPhoto: bool,
  setPreparingPhoto: func,
};

UploadFile.defaultProps = {
  label: "Upload file",
  accept: "*",
  path: "users/representatives/profile-photo",
  isEmpty: true,
  fontSize: "14px",
  withCropper: false,
  progressUpload: () => {},
  defaultAspect: 1,
  uploadData: {},
  preparingPhoto: false,
  setPreparingPhoto: () => {},
};

export default UploadFile;
