import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().trim().required("This field is required"),
    federalTaxId: Yup.string()
      .transform((v, o) => (o === "" ? null : v))
      .nullable()
      .typeError("Must be a number.")
      .test(
        "fedValidation",
        "Please type a valid Tax Identification String!",
        (value) => {
          if (!value) return true;
          const FED_REGEXP = /^[\d-]+[\d]{7}$/;
          return FED_REGEXP.test(value);
        }
      ),
    email: Yup.string().trim().required("This field is required"),
    phone: Yup.string()
      .trim()
      .test("phoneValidation", "Not valid phone number!", (value) => {
        if (!value) return true;
        const PHONE_REGEXP =
          // eslint-disable-next-line no-useless-escape
          /^[\+(|[0-9][0-9 \-\\.)]{9,14}$/im;
        return PHONE_REGEXP.test(value);
      })
      .required(),
    billingAddress: Yup.object().shape({
      formatted_address: Yup.string().required("This field is required"),
      street: Yup.string().required("This field is required"),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      zip: Yup.string().required("This field is required"),
    }),
    contacts: Yup.array().of(
      Yup.object().shape({
        phone: Yup.string()
          .trim()
          .required("This field is required")
          .test("phoneValidation", "Not valid phone number!", (value) => {
            if (!value) return true;
            const PHONE_REGEXP =
              // eslint-disable-next-line no-useless-escape
              /^[\+(|[0-9][0-9 \-\\.)]{9,14}$/im;
            return PHONE_REGEXP.test(value);
          }),
        name: Yup.string().trim().required("This field is required"),
        role: Yup.string().trim().required("This field is required"),
        email: Yup.string()
          .trim()
          .required("This field is required.")
          .test("emailValidation", "Email not valid!", (value) => {
            if (!value) return true;
            const EMAIL_REGEXP =
              /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))\.)+(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))$/i; // eslint-disable-line
            return EMAIL_REGEXP.test(value);
          }),
      })
    ),
    locations: Yup.array().of(
      Yup.object().shape({
        formatted_address: Yup.string().required("This field is required"),
        street: Yup.string().required("This field is required"),
        city: Yup.string().required("This field is required"),
        state: Yup.string().required("This field is required").nullable(),
        zip: Yup.string().required("This field is required"),
        name: Yup.string().nullable().required("This field is required"),
      })
    ),
    website: Yup.string()
      .nullable()
      .test("websiteValidation", "Enter valid link", (value) => {
        const URL_REGEXP =
          /^(http(s)?:\/\/|www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/;
        if (!value) return true;
        return URL_REGEXP.test(value);
      })
      .notRequired(),
  });

export const businessDetailsValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().trim().required("This field is required"),
    federalTaxId: Yup.string()
      .transform((v, o) => (o === "" ? null : v))
      .nullable()
      .typeError("Must be a number.")
      .test(
        "fedValidation",
        "Please type a valid Tax Identification String!",
        (value) => {
          if (!value) return true;
          const FED_REGEXP = /^[\d-]+[\d]{7}$/;
          return FED_REGEXP.test(value);
        }
      ),
    billingAddress: Yup.object().shape({
      formatted_address: Yup.string().required("This field is required"),
      street: Yup.string().required("This field is required"),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      zip: Yup.string().required("This field is required"),
    }),
  });

export const businessContactsValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string().trim().required("This field is required"),
    phone: Yup.string()
      .trim()
      .test("phoneValidation", "Not valid phone number!", (value) => {
        if (!value) return true;

        const MOBILE_PHONE_REGEXP =
          // eslint-disable-next-line no-useless-escape
          /^[\+][\d]{1}[\s]?[\(]?[\d]{3}[\)]?[\s]?[\d]{3}[\s]?[\d]{4}$/im;

        return MOBILE_PHONE_REGEXP.test(value);
      })
      .required(),

    website: Yup.string()
      .nullable()
      .test("websiteValidation", "Enter valid link", (value) => {
        const URL_REGEXP =
          /^(http(s)?:\/\/|www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/;
        if (!value) return true;
        return URL_REGEXP.test(value);
      })
      .notRequired(),
  });

export const locationsValidationSchema = () =>
  Yup.object().shape({
    locations: Yup.array().of(
      Yup.object().shape({
        formatted_address: Yup.string().required("This field is required"),
        street: Yup.string().required("This field is required"),
        city: Yup.string().required("This field is required"),
        state: Yup.string().required("This field is required").nullable(),
        zip: Yup.string().required("This field is required"),
        name: Yup.string().nullable().required("This field is required"),
      })
    ),
  });
