import React from "react";

// eslint-disable-next-line react/prop-types
export const LocationIcon = ({ fill, ...props }) => {
  return (
    <svg
      id="Icon_metro-location"
      data-name="Icon metro-location"
      xmlns="http://www.w3.org/2000/svg"
      width="6.666"
      height="10.665"
      viewBox="0 0 6.666 10.665"
      {...props}
    >
      <path
        id="Icon_metro-location-2"
        data-name="Icon metro-location"
        d="M11.688,1.928A3.333,3.333,0,0,0,8.355,5.261c0,3.333,3.333,7.332,3.333,7.332s3.333-4,3.333-7.332a3.333,3.333,0,0,0-3.333-3.333Zm0,5.374a2.041,2.041,0,1,1,2.041-2.041A2.041,2.041,0,0,1,11.688,7.3ZM10.4,5.261a1.291,1.291,0,1,1,1.291,1.291A1.291,1.291,0,0,1,10.4,5.261Z"
        transform="translate(-8.355 -1.928)"
        fill={fill || "#b5b5ac"}
      />
    </svg>
  );
};
