import { func, string } from "prop-types";

import { cl } from "../styles";

import { TextField } from "@mui/material";

export const TextBlock = ({ noteText, setNoteText }) => {
  return (
    <>
      <TextField
        placeholder="Add order note or delivery instructions"
        multiline
        rows={14}
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
        fullWidth
        name="note"
        InputProps={{ sx: cl.textInput }}
      />
    </>
  );
};

TextBlock.propTypes = {
  noteText: string,
  setNoteText: func,
};
