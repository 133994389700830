import { GET_PRODUCTS_LIST, SET_PRODUCTS_LOADING } from "../actions/products";

const initialState = {
  products: [],
  loading: false,
};

const productsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PRODUCTS_LIST:
      return { ...state, products: [...payload] };

    case SET_PRODUCTS_LOADING:
      return { ...state, loading: payload };

    default:
      return state;
  }
};

export default productsReducer;
