export const cl = {
  select: {
    mr: 2,
    width: 74,
    height: 42,
    "& .MuiInputBase-input": {
      fontSize: 13,
      color: "#0D0D0D",
      p: "0 0 0 10px !important",
      textAlign: "center",
    },
    "& .MuiSvgIcon-root": {
      left: 10,
      color: "#000000",
    },
    "& fieldset": {
      border: "1px solid #D4D4D4",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #000 !important",
    },
  },
  paginationWrapper: {
    position: "absolute",
    borderLeft: "1px solid #D4D4D4",
    borderRight: "1px solid #D4D4D4",
    height: "18px",
    top: "50%",
    left: 31,
    right: 31,
    transform: "translate(0%, -50%)",
  },
  pagination: {
    "& .MuiButtonBase-root": {
      width: "30px",
      height: "42px",
      mt: "-1px",
      mx: 0,
    },
    "& .Mui-selected": {
      width: "40px",
      border: "1px solid #000",
      borderRadius: 0,
      backgroundColor: "#fff !important",
    },
  },
};
