import { error, success } from "helpers/notifications";
import { useEffect, useState } from "react";
import { acceptPaymentService } from "services/orders";

const initState = {
  showBtn: false,
  open: false,
  amount: "",
  paymentStatus: "",
  loading: false,
};

export const useAcceptPayment = ({ handleGetOrder, order }) => {
  const [acceptPaymentData, setAcceptPaymentData] = useState(initState);

  const openDialog = () => {
    setAcceptPaymentData((prev) => ({ ...prev, open: true }));
  };
  const closeDialog = () =>
    setAcceptPaymentData({
      ...initState,
      showBtn: true,
      paymentStatus: order?.paymentStatus,
      amount: order?.balance.toFixed(2),
      order,
    });

  const setAmount = (num) => {
    setAcceptPaymentData((prev) => ({
      ...prev,
      amount: num,
    }));
  };

  const handleAcceptPayment = async (selectedCard) => {
    const { distributor_id, orderId, id } = selectedCard;
    setAcceptPaymentData((prev) => ({ ...prev, loading: true }));
    try {
      await acceptPaymentService(distributor_id, {
        orderId,
        amount: +acceptPaymentData?.amount,
        paymentType: "CREDIT_CARD",
        cardId: id,
        sendNotification: false,
      });
      success("Payment successful");
      handleGetOrder();
      setAcceptPaymentData((prev) => ({ ...prev, loading: false }));
    } catch (err) {
      if (err?.response?.data?.message) error(err.response.data.message);
      setAcceptPaymentData((prev) => ({ ...prev, loading: false }));
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message || err.message);
      error(err?.response?.data?.error);
    }
  };

  useEffect(() => {
    setAcceptPaymentData((prev) => ({
      ...prev,
      showBtn: true,
      paymentStatus: order?.paymentStatus,
      amount: order?.balance.toFixed(2),
    }));
  }, [order?.balance, order?.paymentStatus]);

  return {
    ...acceptPaymentData,
    openDialog,
    closeDialog,
    setAmount,
    handleAcceptPayment,
  };
};
