import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DRAWERS } from "constants/drawer";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { success } from "helpers/notifications";

export const useNoteDrawer = ({ setValue, fieldValue }) => {
  const isDrawerOpened = useSelector(({ uiState }) => uiState.noteDrawer);

  const dispatch = useDispatch();
  const [noteText, setNoteText] = useState("");

  useEffect(() => {
    if (isDrawerOpened) setNoteText(fieldValue || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpened]);

  const onClose = () =>
    dispatch(
      showCurrentDrawerAction({ type: DRAWERS.NOTE_DRAWER, show: false })
    );

  const onOpen = () =>
    dispatch(
      showCurrentDrawerAction({ type: DRAWERS.NOTE_DRAWER, show: true })
    );

  const handleClearAction = () => setNoteText("");

  const handleSaveNote = () => {
    setValue("note.text", noteText, {
      shouldDirty: true,
    });
    success("Note updated", {
      position: "top-center",
      style: { top: "30px" },
    });
    onClose();
  };

  return {
    onClose,
    handleClearAction,
    handleSaveNote,
    noteText,
    setNoteText,
    onOpen,
    isDrawerOpened,
  };
};
