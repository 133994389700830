export const cl = {
  headerBlock: {
    p: "12px 0px",
    gap: "8px",
    borderBottom: "1px #F3F3F3 solid",
    width: "100%",
  },
  headerBlockText: {
    color: "rgba(0, 0, 0, 0.40)",
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "19.5px",
    wordWrap: "break-word",
  },
};
