import axios from "axios";

export const getDistributorsService = async ({ params }) => {
  return axios({
    method: "get",
    url: "/users/app-customers/distributors",
    params,
  }).then((res) => res.data);
};

export const getConnectedDistributorsService = async ({ params }) => {
  return axios({
    method: "get",
    url: "/users/app-customers/connected-distributors",
    params,
  }).then((res) => res.data);
};

export const getDistributorProductsService = async (distributorId, params) => {
  return axios({
    method: "get",
    url: `/products/?distributor_id=${distributorId}`,
    params,
  }).then((res) => res.data);
};

export const contactVendorService = async (distributorId, formData, files) => {
  files.forEach((file) => formData.append("file[]", file));
  const { data } = await axios.post(
    `/users/app-customers/send-email?distributor_id=${distributorId}`,
    formData
  );
  return data;
};

export const wholesaleAccessService = async (data, token) => {
  const { newDistributor, ...preparedData } = data; // temporarily fix
  const config = {
    method: "POST",
    url: "/users/app-customers/wholesale-access",
    data: preparedData,
  };
  if (token) config.headers = { Authorization: `Bearer ${token}` };
  return axios(config).then((res) => res.data);
};

export const getSuppliersService = async ({ params }) => {
  return axios({
    method: "get",
    url: "/users/app-customers/suppliers",
    params,
  }).then((res) => res.data);
};

export const getPaymentTermsByStoreNameService = ({
  storeName,
  token,
  params,
}) => {
  return axios({
    method: "get",
    url: `/payment-terms/${storeName}`,
    headers: { Authorization: `Bearer ${token}` },
    params,
  }).then((res) => res.data);
};

export const checkRegistrationEmailService = (email) => {
  return axios({
    method: "POST",
    url: `users/check-email/${email}`,
  }).then((res) => res.data);
};

export const getDistributorByIdService = async (distributor_id) => {
  return axios
    .get("/users/app-customers/distributor-info", {
      params: { distributor_id },
    })
    .then((res) => res.data);
};
