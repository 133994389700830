import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: "12px",
    height: "32px",
  },

  inputLabel: {
    fontSize: "12px",
    color: "#B5B5AC",
  },

  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 19px 14px 20px",
    alignItems: "center",
  },

  title: {
    fontSize: "17px",
    color: "#000",
  },

  dialogContent: {
    width: "359px",
    padding: "10px 25px 10px 20px !important",
  },

  contentTitle: {
    fontSize: "16px",
    color: "#363531",
    fontWeight: "600",
  },

  textFieldTitle: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#000000",
    marginBottom: "7px",
  },

  input: {
    "& .MuiInputBase-root": {
      height: "40px",
    },

    "& .MuiFormLabel-root": {
      top: 3,
    },
  },

  defaultMethodText: {
    fontSize: "11px",
    color: "#5F6267",
  },

  contentDivider: {
    // display
    marginLeft: "-26px",
    marginRight: "-63px",
  },

  billingDate: {
    fontSize: "13px",
    fontWeight: 400,
    color: "#363531",
    lineHeight: "1",
  },

  dialogActions: {
    padding: "20px",
  },

  addressTitle: {
    fontSize: "13px",
    color: "#363531",
  },

  toggleButton: {
    color: "#6A6A6A",
    height: "30px",
    width: "20px",
    marginLeft: "8.6px",
    "&.Mui-selected": {
      backgroundColor: "rgba(71, 160, 109, 0.1)!important",
      borderColor: "#47A06D",
      color: "#6A6A6A",
    },
  },

  dialogText: {
    fontSize: "13px",
    fontWeight: 300,
    color: "#363531",
  },

  methodBlock: {
    display: "inline-flex",
    gap: "7px",
    alignItems: "center",
    marginBottom: "21px",
  },

  blockTitle: {
    fontSize: "12px",
    color: "#363531",
    marginBottom: "9px",
  },
}));

export default useStyles;
