import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  drawerWrapper: {
    background: "white",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "inline-flex",
    overflow: "hidden",
    width: "100vw",
    padding: "20px 16px 61px",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    gap: "20px",
  },

  headerWrapper: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    display: "inline-flex",
    gap: "20px",
  },

  headerDividerWrapper: {
    paddingBottom: "20px",
    borderBottom: "1px rgba(0, 0, 0, 0.05) solid",
  },

  actionBtn: {
    fontSize: "14px",
    fontWeight: 600,
    border: "none !important",
    padding: 0,
    minWidth: "50px",

    "&:hover": {
      border: "none",
    },
  },

  title: {
    color: "black",
    fontSize: "18px",
    fontWeight: 500,
    wordWrap: "break-word",
  },
}));

export const cl = {
  textInput: {
    height: "36px",
    fontSize: "14px",
    borderRadius: "8px",
    color: "black",
    backgroundColor: "rgba(213.42, 213.42, 213.42, 0.16)",
    "& > fieldset": {
      borderColor: "rgb(213,217,217)!important",
      borderWidth: "0px !important",
      height: "36px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px",
    },
  },

  outlinedTextInput: {
    fontSize: "14px",
    borderRadius: "8px",
    color: "black",

    "& > fieldset": {
      borderColor: "rgba(0, 0, 0, 0.05)!important",
    },

    "& .MuiOutlinedInput-input": {
      padding: "8px",
    },
  },

  select: {
    "& span": {
      color: "rgba(86, 86, 86, 0.50)",
      fontSize: "14px",
      lineHeight: "20px",
    },

    "& svg": {
      right: 0,
      fill: "#2F3040",
      width: "27px",
      mr: "3px",
      position: "absolute",
      transform: "scale(1)",
      top: "calc(50% - 11px)",
      marginTop: "3px",
    },
    fontWeight: 500,
    color: "black",
    backgroundColor: "rgba(213.42, 213.42, 213.42, 0.16)",
    borderRadius: "8px",
    "& > .MuiSelect-select": {
      fontSize: "14px",
      lineHeight: "20px",
      color: "black",
      padding: "0px 32px 0px 10px !important",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
      height: "36px",
      top: "0px",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  label: { fontSize: "12px", lineHeight: "16px", color: "rgba(0, 0, 0, 0.60)" },
  errorLabel: { color: "rgba(228, 30, 58, 0.60)" },
  errorTextInput: {
    "& > fieldset": {
      borderColor: "rgba(228, 30, 58, 0.60) !important",
      borderWidth: "1px !important",
    },
  },
  errorSelect: {
    border: "1px solid rgba(228, 30, 58, 0.60) !important",
  },
};
