/* eslint-disable react/prop-types */
import React from "react";

export const PenIcon = ({ ...props }) => {
  return (
    <svg
      id="pen-solid"
      xmlns="http://www.w3.org/2000/svg"
      width="20.022"
      height="20.022"
      viewBox="0 0 20.022 20.022"
      {...props}
    >
      <path
        id="pen-solid-2"
        data-name="pen-solid"
        d="M11.359,3.653,16.365,8.66,5.494,19.531l-4.463.493A.939.939,0,0,1,0,18.987l.5-4.467L11.359,3.653Zm8.1-.745L17.111.557a1.878,1.878,0,0,0-2.656,0L12.244,2.769,17.25,7.775l2.211-2.211a1.878,1.878,0,0,0,0-2.656Z"
        transform="translate(0.011 -0.007)"
        fill="#707070"
      />
    </svg>
  );
};
