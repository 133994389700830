import { Box, InputAdornment, MenuItem, Stack, TextField } from "@mui/material";
import { object, func, bool, number, array } from "prop-types";
import { cl } from "../styles";
import { StyledPopper, StyledSelect } from "components";
import { useQtySelect } from "./useQtySelect";
import { useEffect, useMemo, useRef } from "react";
import { StyledArrowIcon } from "components/Icons";
import NumberFormat from "react-number-format";
import { useIsVisible } from "hooks/useInVisible";

export const ProductQtySelect = ({
  product,
  currentDraftProducts = [],
  productQty,
  setProductQty,
  productSellingOutOfStock,
  isAdded,
  showMOQTooltip,
  showStockTooltip,
  minOrderQTY,
  isLowerThanMOQ,
  stylesProps = {},
  setIsOutOfStock,
}) => {
  const { sellingOutOfStock } = product || {};

  const {
    quantityList,
    isNonInventory,
    inventory,
    alreadyAddedDraft,
    onSelectClick,
    onChange,
    showInput,
    isSelectOpen,
    handleCloseMenu,
    boxRef,
    lastQtyItem,
    tooltipText,
    inputAutoFocus,
  } = useQtySelect({
    product,
    currentDraftProducts,
    setProductQty,
    productSellingOutOfStock,
    minOrderQTY,
    isAdded,
    showStockTooltip,
  });

  const popoverRef = useRef(null);

  const isVisible = useIsVisible(popoverRef);

  const isOutOfStock = useMemo(
    () =>
      isNonInventory
        ? false
        : sellingOutOfStock
        ? false
        : inventory - alreadyAddedDraft < 1,
    [alreadyAddedDraft, inventory, isNonInventory, sellingOutOfStock]
  );

  useEffect(() => {
    !!setIsOutOfStock && setIsOutOfStock(isLowerThanMOQ || isOutOfStock);
  }, [isLowerThanMOQ, isOutOfStock, setIsOutOfStock]);

  return (
    <>
      <StyledPopper
        open={
          (!!showMOQTooltip || showStockTooltip) && isVisible && boxRef?.current
        }
        anchorEl={boxRef?.current || null}
        text={tooltipText}
        modifiers={[
          {
            name: "offset",
            options: { offset: [40, 6] },
          },
        ]}
        arrowSx={{ ml: "40px" }}
        contentSx={{ ml: "-80px" }}
        placement="top"
        aria-hidden="true"
        sx={{
          pointerEvents: "none",
          zIndex: 100,
        }}
      />
      <Box ref={popoverRef} mb="-8px" />
      {isLowerThanMOQ || isOutOfStock ? (
        <Stack
          sx={{
            ...cl.outOfStockText,
            ...stylesProps,
          }}
          alignItems="center"
        >
          Out of stock
        </Stack>
      ) : (
        <Stack ref={boxRef} width="100%">
          <Box width="100%">
            {showInput ? (
              <NumberFormat
                customInput={TextField}
                fullWidth
                disabled={isOutOfStock}
                autoFocus={inputAutoFocus}
                placeholder="Qty"
                value={productQty}
                sx={{
                  ...cl.selectInput,
                  "& .MuiOutlinedInput-input": {
                    ...cl.selectInput["& .MuiOutlinedInput-input"],
                    height:
                      stylesProps?.height ||
                      cl.selectInput["& .MuiOutlinedInput-input"].height,
                    fontSize:
                      stylesProps?.fontSize ||
                      cl.selectInput["& .MuiOutlinedInput-input"].fontSize,
                  },
                }}
                InputProps={{
                  classes: {
                    icon: cl.icon,
                  },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ mr: "4px" }}
                      onClick={onSelectClick}
                    >
                      <StyledArrowIcon sx={cl.icon} />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setProductQty(+e.target.value)}
              />
            ) : (
              <StyledSelect
                labelProps={{ shrink: false }}
                disabled={isOutOfStock}
                notched={false}
                fullWidth
                fontSize={stylesProps?.fontSize || "10px"}
                displayEmpty
                IconComponent={StyledArrowIcon}
                value={productQty || 1}
                height={stylesProps?.height || "22px"}
                formSx={{ minWidth: "100%" }}
                paperSx={cl.paperStyles}
                sx={cl.select}
                renderValue={(value) => value}
                open={isSelectOpen}
                onOpen={onSelectClick}
                onChange={onChange}
                dataLength={sellingOutOfStock ? quantityList?.length : null}
              >
                {minOrderQTY && (
                  <MenuItem
                    disabled
                    value={minOrderQTY}
                    onClick={handleCloseMenu}
                  >
                    Minimum Quantity {minOrderQTY}
                  </MenuItem>
                )}
                {quantityList.map((num) => (
                  <MenuItem key={num} value={num} onClick={handleCloseMenu}>
                    {num}
                  </MenuItem>
                ))}
                <MenuItem
                  key={lastQtyItem}
                  value={lastQtyItem}
                  onClick={handleCloseMenu}
                >
                  {lastQtyItem}+
                </MenuItem>
              </StyledSelect>
            )}
          </Box>
        </Stack>
      )}
    </>
  );
};

ProductQtySelect.propTypes = {
  product: object,
  parentProduct: object,
  suppliersCount: number,
  handleOpenProductProfile: func,
  filteredBySupplier: bool,
  productSellingOutOfStock: bool,
  supplierApproved: bool,
  showRetailPrice: bool,
  isChild: bool,
  isAdded: bool,
  showMOQTooltip: bool,
  showStockTooltip: bool,
  currentDraftProducts: array,
  productQty: number,
  minOrderQTY: number,
  setProductQty: func,
  isLowerThanMOQ: bool,
  stylesProps: object,
  setIsOutOfStock: func,
};
