import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import PropTypes, {
  string,
  array,
  object,
  bool,
  func,
  number,
  any,
} from "prop-types";
import { forwardRef, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";
import { FILTER_DATES } from "../../utils/constants";
import { StyledTextField } from "../TextFields/TextFields";
import { ClosePinIcon, StyledArrowIcon } from "../Icons";
import { StyledDateRangePicker } from "../../components";

const styles = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgb(213,217,217)",
    },
  },
  "& .MuiInputLabel-root.Mui-error": {
    color: { xs: "rgba(228, 30, 58, 0.60) !important", sm: "#FF3737" },
  },
  "& .MuiOutlinedInput-root:not(.Mui-disabled):hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#47A06D",
    },
  },
};

export const SquareSelect = forwardRef(
  (
    {
      label,
      children,
      fullWidth,
      formSx,
      leftSidedIcon,
      radiused,
      radiusedRight,
      unfocused,
      handleFetch,
      dataLength,
      dataCount,
      height,
      labelPaddingTop,
      ...props
    },
    ref
  ) => {
    const classes = useStyles({
      leftSidedIcon,
      radiused,
      radiusedRight,
      unfocused,
      height,
      labelPaddingTop,
    });

    return (
      <FormControl
        size="small"
        fullWidth={fullWidth}
        className={classes.squareSelectForm}
        sx={formSx}
      >
        <InputLabel
          id={`${label}-select`}
          className={classes.squareSelectLabel}
        >
          {label}
        </InputLabel>
        <Select
          labelId={`${label}-select`}
          variant="outlined"
          inputProps={{ classes: { icon: classes.squareSelectIcon } }}
          IconComponent={ExpandMore}
          className={classes.squareSelect}
          ref={ref}
          MenuProps={{
            PaperProps: {
              sx: {
                maxWidth: "200px",
                overflowX: "hidden!important",
                maxHeight: "360px",
                overflow: "auto",
                pb: 0,
              },
              onScroll: (e) => {
                if (
                  e.target.scrollTop + 365 < e.target.scrollHeight ||
                  dataLength >= dataCount
                )
                  return;
                handleFetch();
              },
            },
          }}
          {...props}
        >
          {children}
          {dataLength < dataCount && (
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              height="30px"
            >
              <CircularProgress sx={{ mt: "2px" }} size="20px" />
            </Box>
          )}
        </Select>
      </FormControl>
    );
  }
);

SquareSelect.displayName = "SquareSelect";

SquareSelect.propTypes = {
  label: string,
  children: PropTypes.oneOfType([array, object]),
  labelSx: object,
  fullWidth: bool,
  formSx: object,
  leftSidedIcon: bool,
  radiused: bool,
  radiusedRight: bool,
  unfocused: bool,
  handleFetch: func,
  dataLength: number,
  dataCount: number,
  height: string,
  labelPaddingTop: number,
};

SquareSelect.defaultProps = {
  label: "",
  fullWidth: false,
  children: null,
  leftSidedIcon: false,
  radiused: false,
  radiusedRight: false,
  unfocused: false,
  dataLength: 0,
  handleFetch: () => {},
  dataCount: 0,
};

export const StyledSelect = forwardRef(
  (
    {
      leftSidedIcon,
      fill,
      error,
      label,
      children,
      labelSx,
      fullWidth,
      formSx,
      fontSize,
      color,
      height,
      dataLength,
      dataCount,
      handleFetch,
      labelProps,
      noErrorMessage,
      border,
      formProps,
      paperSx = {},
      ...props
    },
    ref
  ) => {
    const classes = useStyles({
      leftSidedIcon,
      fill,
      fontSize,
      color,
      height,
      labelSx,
      border,
    });

    return (
      <FormControl
        size="small"
        fullWidth={fullWidth}
        sx={{ ...formSx, ...styles }}
        className={classes.inputForm}
        error={!!error}
        {...formProps}
      >
        <InputLabel
          id={`${label}-select`}
          sx={labelSx}
          className={classes.label}
          {...labelProps}
        >
          {label}
        </InputLabel>
        <Select
          labelId={`${label}-select`}
          label={label}
          variant="outlined"
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
          IconComponent={ExpandMore}
          className={classes.select}
          ref={ref}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: "360px",
                overflow: "auto",
                overflowX: "hidden",
                pb: 0,
                "& ul": {
                  width: "100% !important",
                  paddingRight: "0px !important",
                },
                ...paperSx,
              },
              onScroll: (e) => {
                if (
                  e.target.scrollTop + 365 < e.target.scrollHeight ||
                  dataLength >= dataCount
                )
                  return;
                handleFetch();
              },
            },
          }}
          {...props}
        >
          {children}
          {dataLength < dataCount && (
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              height="30px"
            >
              <CircularProgress sx={{ mt: "2px" }} size="20px" />
            </Box>
          )}
        </Select>
        {!!error && !noErrorMessage && typeof error === "string" && (
          <FormHelperText
            sx={{ position: "absolute", bottom: -20, left: 0, margin: 0 }}
          >
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

StyledSelect.propTypes = {
  label: string,
  fill: string,
  children: PropTypes.oneOfType([array, object]),
  labelSx: object,
  fullWidth: bool,
  formSx: object,
  error: PropTypes.oneOfType([string, bool]),
  leftSidedIcon: bool,
  fontSize: string,
  color: string,
  height: string,
  dataLength: number,
  dataCount: number,
  handleFetch: func,
  labelProps: object,
  noErrorMessage: bool,
  border: string,
  formProps: object,
  paperSx: object,
};

StyledSelect.defaultProps = {
  label: "",
  fill: "",
  fullWidth: false,
  children: null,
  error: null,
  leftSidedIcon: false,
  fontSize: "12px",
  height: "32px",
  color: "#1C1C19",
  dataLength: 0,
  dataCount: 0,
  handleFetch: () => {},
  noErrorMessage: false,
};

StyledSelect.displayName = "StyledSelect";

export const SearchSelect = forwardRef(
  (
    {
      leftSidedIcon,
      error,
      label,
      children,
      labelSx,
      fullWidth,
      formSx,
      handleDelete,
      multiselect,
      chipSx,
      ...props
    },
    ref
  ) => {
    const classes = useStyles({ leftSidedIcon });
    return (
      <FormControl
        size="small"
        fullWidth={fullWidth}
        sx={{ ...formSx, ...styles }}
        className={classes.inputForm}
        error={!!error}
      >
        {!props.value?.length && (
          <InputLabel
            id={`${label}-select`}
            sx={labelSx}
            disableAnimation
            shrink={false}
            className={classes.label}
          >
            {label}
          </InputLabel>
        )}
        <Select
          labelId={`${label}-select`}
          label={label}
          variant="outlined"
          notched={false}
          inputProps={{ classes: { icon: classes.icon } }}
          IconComponent={ExpandMore}
          className={classes.select}
          multiple={multiselect}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {multiselect ? (
                selected.map((value) => (
                  <Chip
                    key={value.id}
                    sx={{
                      borderRadius: "4px",
                      backgroundColor: "#F8F8FA",
                      ...chipSx,
                    }}
                    size="small"
                    label={
                      <Typography
                        sx={{ fontSize: "12px" }}
                        color="groundLighter.main"
                      >
                        {value.name}
                      </Typography>
                    }
                    deleteIcon={<CloseIcon />}
                    onMouseDown={(e) => e.stopPropagation()}
                    onDelete={() => handleDelete(value.id)}
                  />
                ))
              ) : (
                <Chip
                  sx={{
                    borderRadius: "4px",
                    backgroundColor: "#F8F8FA",
                  }}
                  size="small"
                  label={
                    <Typography
                      sx={{ fontSize: "12px" }}
                      color="groundLighter.main"
                    >
                      {selected}
                    </Typography>
                  }
                  deleteIcon={<CloseIcon />}
                  onMouseDown={(e) => e.stopPropagation()}
                  onDelete={handleDelete}
                />
              )}
            </Box>
          )}
          ref={ref}
          {...props}
        >
          {children}
        </Select>
        {!!error && (
          <FormHelperText
            sx={{ position: "absolute", bottom: -20, left: 0, margin: 0 }}
          >
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);
SearchSelect.displayName = "SearchSelect";
SearchSelect.propTypes = {
  label: string,
  children: array,
  labelSx: object,
  fullWidth: bool,
  formSx: object,
  error: string,
  leftSidedIcon: bool,
  handleDelete: func,
  multiselect: bool,
  chipSx: object,
  value: any,
};
SearchSelect.defaultTypes = {
  label: "",
  fullWidth: false,
  children: null,
  error: null,
  leftSidedIcon: false,
  multiselect: false,
};

export const SelectDatePicker = forwardRef(
  (
    {
      handleDateInput,
      handleClearValue,
      height,
      formSx,
      fullWidth,
      datesList,
      labelPaddingTop,
      textfieldInputSx,
      ...props
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const dateRef = useRef(null);

    return (
      <>
        <StyledDateRangePicker
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          handleDateInput={handleDateInput}
          date={props.value}
        />
        {typeof props.value === "string" ? (
          <SquareSelect
            ref={dateRef}
            height={height}
            fullWidth={fullWidth}
            formSx={formSx}
            labelPaddingTop={labelPaddingTop}
            {...props}
          >
            {(datesList?.length ? datesList : FILTER_DATES).map((el) => (
              <MenuItem key={el?.name} value={el?.name}>
                {el?.name}
              </MenuItem>
            ))}
            <MenuItem onClick={() => setAnchorEl(dateRef.current)}>
              Custom
            </MenuItem>
          </SquareSelect>
        ) : (
          <StyledTextField
            InputProps={{
              sx: {
                height: height || "39px",
                color: "#707070",
                fontSize: "11px",
                fontWeight: 400,
                backgroundColor: "#fff",
                pr: "10px",
                ...textfieldInputSx,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClearValue();
                    }}
                  >
                    <ClosePinIcon />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start" sx={{ height: "100%" }}>
                  <Typography
                    whiteSpace="nowrap"
                    fontSize="11px"
                    color="#707070"
                    mr="11px"
                  >
                    Custom date
                  </Typography>
                  <Divider orientation="vertical" />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { backgroundColor: "#fff" },
            }}
            onClick={(e) => setAnchorEl(e.target)}
            ref={ref}
            {...props}
            value={
              props.value[1]
                ? ` ${props.value[0]?.format(
                    "MM/DD/YYYY"
                  )} - ${props.value[1]?.format("MM/DD/YYYY")}`
                : props.value[0]?.format("MM/DD/YYYY")
            }
            label=""
            radiused="false"
          />
        )}
      </>
    );
  }
);

SelectDatePicker.displayName = "SelectDatePicker";

SelectDatePicker.propTypes = {
  value: PropTypes.oneOfType([array, string]),
  fullWidth: bool,
  height: string,
  formSx: object,
  datesList: array,
  handleDateInput: func,
  handleClearValue: func,
  labelPaddingTop: number,
  textfieldInputSx: object,
};

export const MobileStyledSelect = forwardRef(
  ({ sx, height, fullWidth, formSx, children, ...props }, ref) => {
    const cl = {
      paperStyles: {
        maxHeight: "260px",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.10)",
        "& li": {
          padding: "5px 11px",
          minHeight: "16px",
          color: "black",
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "16.20px",
          wordWrap: "break-word",
        },
        borderRadius: "8px",
        marginTop: "6px",
        "& ul": {
          padding: "5px 0",
        },
        "&::-webkit-scrollbar": {
          width: "6px",
          borderRadius: "7px",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "7px",
          backgroundColor: "rgba(0, 0, 0, 0.35)",
        },
      },
      select: {
        "& span": {
          color: "rgba(86, 86, 86, 0.50)",
          fontSize: "14px",
          lineHeight: "20px",
        },

        "& svg": {
          right: 0,
          fill: "#2F3040",
          width: "27px",
          mr: "3px",
          position: "absolute",
          transform: "scale(1)",
          top: "calc(50% - 11px)",
          marginTop: "3px",
        },
        fontWeight: 500,
        color: "black",
        backgroundColor: "rgba(213.42, 213.42, 213.42, 0.16)",
        borderRadius: "8px",
        "& > .MuiSelect-select": {
          fontSize: "14px",
          lineHeight: "20px",
          color: "black",
          padding: "0px 32px 0px 10px !important",
        },

        "& .MuiOutlinedInput-notchedOutline": {
          border: "none !important",
          height: "36px",
          top: "0px",
        },

        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none !important",
        },
      },
    };

    return (
      <StyledSelect
        IconComponent={StyledArrowIcon}
        sx={{ ...cl.select, ...sx }}
        displayEmpty
        height={height || "36px"}
        fullWidth={fullWidth}
        formSx={{ ...formSx }}
        MenuProps={{
          PaperProps: {
            sx: { ...cl.paperStyles, width: "100%" },
          },
        }}
        ref={ref}
        {...props}
      >
        {children}
      </StyledSelect>
    );
  }
);
MobileStyledSelect.propTypes = {
  sx: object,
  height: string,
  fullWidth: bool,
  formSx: object,
  children: PropTypes.oneOfType([array, object]),
};

MobileStyledSelect.defaultProps = {
  sx: {},
  height: "36px",
  fullWidth: false,
  formSx: {},
  children: null,
};

MobileStyledSelect.displayName = "MobileStyledSelect";
