import { error } from "helpers/notifications";
import {
  contactVendorService,
  getDistributorsService,
} from "../../services/distributors";
import { setAuthLoading } from "./auth";

export const START_LOADING_DISTRIBUTORS = "START_LOADING_DISTRIBUTORS";
export const END_LOADING_DISTRIBUTORS = "END_LOADING_DISTRIBUTORS";
export const GET_DISTRIBUTORS_LIST = "GET_DISTRIBUTORS_LIST";
export const GET_DISTRIBUTORS_COUNT = "GET_DISTRIBUTORS_COUNT";
export const GET_VENDOR_PRODUCTS_LIST = "GET_VENDOR_PRODUCTS_LIST";
export const GET_VENDOR_PRODUCTS_COUNT = "GET_VENDOR_PRODUCTS_COUNT";
export const GET_DISTRIBUTOR_LOGO = "GET_DISTRIBUTORS_LOGO";
export const GET_DISTRIBUTOR_PARAMS = "GET_DISTRIBUTOR_PARAMS";
export const SET_STORE_DATA = "SET_STORE_DATA";
export const SET_SELECTED_SUPPLIER = "SET_SELECTED_SUPPLIER";

export const startLoadingDistributors = () => ({
  type: START_LOADING_DISTRIBUTORS,
});

export const setSelectedSupplier = (payload) => ({
  type: SET_SELECTED_SUPPLIER,
  payload,
});

export const endLoadingDistributors = () => ({
  type: END_LOADING_DISTRIBUTORS,
});

const getDistributorsList = (payload) => ({
  type: GET_DISTRIBUTORS_LIST,
  payload,
});

const getDistributorsCount = (payload) => ({
  type: GET_DISTRIBUTORS_COUNT,
  payload,
});

const getDistributorLogo = (payload) => ({
  type: GET_DISTRIBUTOR_LOGO,
  payload,
});

export const getDistributorParams = (payload) => ({
  type: GET_DISTRIBUTOR_PARAMS,
  payload,
});

export const setStoreData = (payload) => ({
  type: SET_STORE_DATA,
  payload,
});

export const getDistributorsAction = (params) => {
  return (dispatch, getState) => {
    const {
      distributors: { distributorGetParams },
    } = getState();

    getDistributorsService({
      params: { ...params.params, ...distributorGetParams },
    })
      .then((res) => {
        dispatch(getDistributorsList(res?.rows));
        dispatch(getDistributorsCount(res?.count));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err?.message);
      });
  };
};

export const setSelectedSupplierAction = (supplier) => {
  return (dispatch) => dispatch(setSelectedSupplier(supplier));
};

export const getDistributorLogoAction = (url) => {
  return (dispatch) => {
    dispatch(getDistributorLogo(url));
  };
};

export const contactVendorAction = ({
  distributorId,
  data,
  files,
  onSuccess,
}) => {
  return (dispatch) => {
    dispatch(setAuthLoading(true));
    contactVendorService(distributorId, data, files)
      .then(() => {
        onSuccess();
        dispatch(setAuthLoading(false));
        // success("Message sent successfully!");
      })
      .catch((err) => {
        dispatch(setAuthLoading(false));
        error(err?.response?.data?.message);
      });
  };
};
