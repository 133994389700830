import { createContext, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { DesktopViewProduct, MobileViewProduct } from "./components";
import { useProduct } from "./useProduct";
import { useLastPurchased } from "./useLastPurchased";
import { useBreadCrumbs } from "./useBreadCrumbs";
import { useDrawer } from "./useDrawer";
import { useMediaDevice } from "hooks/useMediaDevice";
import { useProductDetailsPage } from "./useProductDetailsPage";
import { DrawerComponent } from "components";
import ContactDialog from "pages/VendorsPage/components/DesktopVendors/components/ContactDialog";

export const ProductContext = createContext();

const ProductDetailsPage = () => {
  const { state } = useLocation();
  const { productId } = useParams();
  const { isMobile } = useMediaDevice();

  const distributorInfo = useMemo(
    () => ({
      distributorId: state?.product?.distributor?.id,
      distributorName: state?.product?.distributor?.name,
      minimumOrderValueAmount:
        state?.product?.distributor?.minimumOrderValueAmount,
    }),
    [
      state?.product?.distributor?.id,
      state?.product?.distributor?.minimumOrderValueAmount,
      state?.product?.distributor?.name,
    ]
  );

  const [showSelectProducts, setShowSelectProducts] = useState();

  const { loading, product } = useProduct({ productId, distributorInfo });

  const { lastPurchasedDate, orderId } = useLastPurchased({ product });

  const drawerState = useDrawer({
    distributorId: distributorInfo?.distributorId,
    showSelectProducts: showSelectProducts,
  });

  const breadCrumbs = useBreadCrumbs({
    supplier: {
      id: product?.distributorId,
      name: product?.distributorName,
    },
    category: product?.productCategory,
    productName: product?.name,
  });

  const productDetailsState = useProductDetailsPage({
    isMobile,
    state,
    loading,
    product,
    drawerState,
    setShowSelectProducts,
    distributorInfo,
  });

  const { profileState, setProfileState } = productDetailsState || {};

  return (
    <ProductContext.Provider
      value={{
        ...productDetailsState,
        lastPurchasedDate,
        orderId,
        breadCrumbs,
        loading,
        product,
        distributorInfo,
        state,
      }}
    >
      <DrawerComponent
        open={drawerState?.open}
        onClose={drawerState?.handleClose}
        state={drawerState}
        parentProduct={product}
      />
      <ContactDialog
        open={profileState.open}
        supplier={profileState.distributor}
        onClose={() => setProfileState((prev) => ({ ...prev, open: false }))}
      />

      {isMobile ? <MobileViewProduct /> : <DesktopViewProduct />}
    </ProductContext.Provider>
  );
};

export default ProductDetailsPage;
