import { bool, func, object, string, array } from "prop-types";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Loader, StyledTextField } from "components";
import { CrossIcon } from "components/Icons";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { cardMask, defaultValues } from "./AddAddressDialog.constants";
import { validationSchema } from "./AddAddressDialog.validations";
import useStyles from "./styles";

export const AddAddressDialog = ({ isOpen, handleClose, billingAddress }) => {
  const classes = useStyles();

  const [state, setState] = useState({
    toggleUnit: false,
    edit: false,
    loading: false,
    cardValue: "",
    cardValueType: "",
  });

  const {
    control,
    handleSubmit,
    reset,
    // setError,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      ...defaultValues,
      fid: Date.now(),
      billingAddress: billingAddress?.formatted_address
        ? billingAddress
        : defaultValues.billingAddress,
      // defaultMethod: setDefaultCreditCard,
      defaultMethod: false, //? temp
    },
    resolver: yupResolver(
      validationSchema({
        editingAddress: state.edit,
        isEdit: false,
        cardValueType: state.cardValueType,
      })
    ),
  });

  // const formField = useWatch({ control });

  // const currentUserHasBillingAddress = useMemo(() => {
  //   if (isRegistration) return !!formField?.billingAddress?.formatted_address;
  //   return !!currentUser?.billingAddress?.formatted_address;
  // }, [
  //   currentUser?.billingAddress?.formatted_address,
  //   formField?.billingAddress?.formatted_address,
  //   isRegistration,
  // ]);

  useEffect(() => {
    reset({
      ...defaultValues,
      fid: Date.now(),
      billingAddress: billingAddress?.formatted_address
        ? billingAddress
        : defaultValues.billingAddress,
      // defaultMethod: setDefaultCreditCard,
      defaultMethod: false, //? temp
    });
  }, [billingAddress, isOpen, reset]);

  // const getErrorMessage = (err) => {
  //   if (err === "Your card number is incorrect.") {
  //     setError("number", { message: err });
  //     return;
  //   }
  //   if (err === "Your card's security code is incorrect.") {
  //     setError("cvc", { message: err });
  //     return;
  //   }
  //   if (err === "Your card has expired.") {
  //     setError("exp", { message: err });
  //     return;
  //   }
  //   if (err === "Your card has insufficient funds.") {
  //     setError("number", { message: err });
  //     return;
  //   }
  //   error("Something went wrong!");
  //   handleClose();
  // };

  const onSubmit = (data) => {
    // eslint-disable-next-line no-console
    console.log("🙏 data -> ", data);
    // if (!currentUserHasBillingAddress) {
    //   setError("billingAddress", {
    //     message: "Please add a default Billing Address before saving.",
    //   });
    //   return;
    // }
    // // SD-3654
    // // if (!currentUserHasContacts && !skipContactValidate) {
    // //   setError("contacts", {
    // //     message: "Please add a primary Contact before saving.",
    // //   });
    // //   return;
    // // }

    // const { exp, fid, ...saveData } = data;
    // const expSplit = data.exp.split(" / ");
    // const dataCard = {
    //   ...saveData,
    //   expMonth: expSplit[0],
    //   expYear: "20" + expSplit[1],
    //   billingAddress: state.edit ? data.billingAddress : billingAddress,
    //   number: data.number.split(" ").join(""),
    // };

    // if (isRegistration) {
    //   setState((prop) => ({ ...prop, loading: true }));

    //   if (replacedCardId) {
    //     replaceDistributorPaymentCardService(replacedCardId, dataCard, headers)
    //       .then((res) => handleSave(res))
    //       .catch((err) => {
    //         console.error(err);
    //         getErrorMessage(err?.response?.data?.error);
    //       })
    //       .finally(() => {
    //         setState((prop) => ({ ...prop, loading: false }));
    //       });
    //     return;
    //   }

    //   addDistributorPaymentCardService(dataCard, headers)
    //     .then((res) => handleSave(res))
    //     .catch((err) => {
    //       console.error(err);
    //       getErrorMessage(err?.response?.data?.error);
    //     })
    //     .finally(() => {
    //       setState((prop) => ({ ...prop, loading: false }));
    //     });
    //   return;
    // }

    // if (customerId) {
    //   setState((prop) => ({ ...prop, loading: true }));
    //   addCustomerCardService(customerId, dataCard)
    //     .then((res) => {
    //       if (orderId) {
    //         dispatch(getOrderByIdAction(orderId));
    //       }
    //       handleSave(res);
    //       success("The card was added!");
    //       handleClose();
    //     })
    //     .catch((err) => {
    //       getErrorMessage(err?.response?.data?.error);
    //       console.error(err);
    //     })
    //     .finally(() => {
    //       setState((prop) => ({ ...prop, loading: false }));
    //     });
    // }
    // if (distributorId) {
    //   setState((prop) => ({ ...prop, loading: true }));
    //   if (replacedCardId) {
    //     replaceDistributorPaymentCardService(replacedCardId, dataCard)
    //       .then((res) => {
    //         success("The card was replaced!");
    //         handleSave(res.rows);
    //         dispatch(getDistributorPaymentCards(res.rows));
    //         handleClose();
    //       })
    //       .catch((err) => {
    //         getErrorMessage(err?.response?.data?.error);
    //         console.error(err);
    //       })
    //       .finally(() => {
    //         setState((prop) => ({ ...prop, loading: false }));
    //       });
    //   }
    //   if (!replacedCardId) {
    //     addDistributorPaymentCardService(dataCard)
    //       .then(() => {
    //         if (orderId) {
    //           dispatch(getOrderByIdAction(orderId));
    //         }
    //         success("The card was added!");
    //         handleClose();
    //       })
    //       .then(() => {
    //         dispatch(getDistributorPaymentCardsAction());
    //       })
    //       .catch((err) => {
    //         getErrorMessage(err?.response?.data?.error);
    //         console.error(err);
    //       })
    //       .finally(() => {
    //         setState((prop) => ({ ...prop, loading: false }));
    //       });
    //   }
    // }
  };

  useEffect(() => {
    setState((prop) => ({
      ...prop,
      cardValueType:
        cardMask.find((m) => m.regex.test(+state.cardValue))?.cardtype ||
        "Default",
    }));
  }, [state.cardValue]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setState((prop) => ({ ...prop, edit: false }));
        handleClose();
      }}
      PaperProps={{ sx: { maxHeight: "100vh" } }}
    >
      <Loader isLoading={state.loading} />
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.title}>Add new address</Typography>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: 0,
        }}
        className={classes.dialogContent}
      >
        <Box
          component="form"
          id="customer-card-details-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box mt="24px">
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  placeholder="Street Address"
                  error={error?.message || ""}
                  noErrorMessage
                  {...field}
                />
              )}
              name="name"
              control={control}
            />
          </Box>
          <Box mt="14px">
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  placeholder="Suite, Unit, Floor etc"
                  error={error?.message || ""}
                  noErrorMessage
                  {...field}
                />
              )}
              name="d"
              control={control}
            />
          </Box>
          <Box my="10px" display="flex" gap="13px">
            <Box width="150px">
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    placeholder="City"
                    error={error?.message || ""}
                    noErrorMessage
                    {...field}
                  />
                )}
                name="city"
                control={control}
              />
            </Box>
            <Box width="67px">
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    placeholder="State"
                    error={error?.message || ""}
                    noErrorMessage
                    {...field}
                  />
                )}
                name="state"
                control={control}
              />
            </Box>
            <Box width="90px">
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    placeholder="ZIP code"
                    error={error?.message || ""}
                    noErrorMessage
                    {...field}
                  />
                )}
                name="zip"
                control={control}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          color="grey"
          sx={{
            height: "27px",
            fontSize: 10,
          }}
          onClick={() => {
            setState((prop) => ({ ...prop, edit: false }));
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          form="customer-card-details-form"
          sx={{ height: "27px", color: "#FFF", fontSize: 10 }}
        >
          Add Address
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddAddressDialog.propTypes = {
  isOpen: bool,
  handleClose: func,
  customerId: string,
  distributorId: string,
  orderId: string,
  billingAddress: object,
  handleSave: func,
  replacedCardId: string,
  setCardDefault: bool,
  skipContactValidate: bool,
  customerCreditCards: array,
  headers: object,
  isRegistration: bool,
};

export default AddAddressDialog;
