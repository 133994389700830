import { bool, func, number, oneOf, string } from "prop-types";
import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { StyledTooltip } from "components";

export const DiscountItem = ({
  id,
  discountName,
  discountType,
  percentDiscount,
  absoluteDiscount,
  minQuantity,
  disabled,
  tooltipText,
  discountActiveId,
  setDiscountActiveId,
}) => {
  const setDiscount = useMemo(() => {
    if (discountType === "PERCENTAGE") return `% ${percentDiscount} off`;
    if (discountType === "ABSOLUTE") return `$ ${absoluteDiscount}`;
    if (discountType === "QUANTITY") return `QTY ${minQuantity}`;
  }, [absoluteDiscount, discountType, minQuantity, percentDiscount]);

  if (disabled && discountActiveId === id) setDiscountActiveId("");

  return (
    <StyledTooltip
      disableHoverListener={!disabled}
      placement="top"
      arrow
      title={tooltipText}
    >
      <Box
        sx={{
          minWidth: "124px",
          maxWidth: "360px",
          borderRadius: "8px",
          textAlign: "center",
          cursor: disabled ? "auto" : "pointer",
          border:
            discountActiveId === id
              ? "0.5px solid rgba(38, 135, 75, 0.4)"
              : "0.5px solid #D5D9D9",
          backgroundColor:
            discountActiveId === id ? "rgba(38, 135, 75, 0.1)" : "#FFFFFF",
          p: 1,
          opacity: disabled ? 0.5 : 1,
        }}
        onClick={() => {
          if (disabled) return;
          setDiscountActiveId((prev) => {
            return prev === id ? "" : id;
          });
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            color: "#000000",
          }}
          noWrap
        >
          {discountName}
        </Typography>

        <Typography
          sx={{ fontSize: "20px", fontWeight: 400, color: "#000000" }}
        >
          {setDiscount}
        </Typography>
      </Box>
    </StyledTooltip>
  );
};

DiscountItem.propTypes = {
  id: string,
  discountName: string,
  discountType: oneOf(["PERCENTAGE", "ABSOLUTE", "QUANTITY"]),
  percentDiscount: number,
  absoluteDiscount: string,
  minQuantity: number,
  disabled: bool,
  tooltipText: string,
  discountActiveId: string,
  setDiscountActiveId: func,
};
DiscountItem.defaultProps = {
  id: "",
  discountName: "",
  discountType: "",
  percentDiscount: 0,
  absoluteDiscount: "",
  minQuantity: 0,
  disabled: false,
  tooltipText: "",
  discountActiveId: "",
  setDiscountActiveId: () => {},
};
