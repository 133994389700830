import {} from "react";
import { number } from "prop-types";
import { InfoItem } from "./components";
import { Box } from "@mui/material";

export const InfoBlock = ({ paidSum, fulfilledItems, fulfilledCount }) => {
  return (
    <Box display="flex" flexDirection="column" gap={1} mt={1}>
      <InfoItem title="Paid" value={`$${paidSum}`} />
      <InfoItem
        title="Fulfilled"
        value={`${fulfilledItems} of ${fulfilledCount} items`}
      />
    </Box>
  );
};

InfoBlock.propTypes = {
  paidSum: number,
  fulfilledItems: number,
  fulfilledCount: number,
};
InfoBlock.defaultProps = {
  paidSum: 0,
  fulfilledItems: 0,
  fulfilledCount: 0,
};
