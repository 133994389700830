import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "../../firebase/notifications/config";
import { error, success } from "helpers/notifications";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { wholesaleAccessService } from "services/distributors";

export const useRequestOrderDirect = () => {
  const [showRequestDialog, setShowRequestDialog] = useState(false);
  const [paymentTermsId, setPaymentTermsId] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChangePayterm = (newId) => {
    setPaymentTermsId(newId);
  };

  const currentStoreData = useSelector(
    ({ distributors }) => distributors.currentStoreData
  );

  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { orderDirect, id: currentUserId } = currentUser || {};

  useEffect(() => {
    if (!currentStoreData) return;
    const currentStoreHasOrderDirect = orderDirect?.some(
      (item) => item.distributor.id === currentStoreData.id
    );

    if (!currentStoreHasOrderDirect) setShowRequestDialog(true);
  }, [currentStoreData, orderDirect]);

  const handleCloseOrderDirectAccess = () => {
    setShowRequestDialog(false);
  };

  const handleRequestWholesale = async () => {
    const distributorId = currentStoreData.id;

    const uploadData = { customerId: currentUserId, read: false };

    const preparedData = { distributorId };

    if (paymentTermsId) preparedData.paymentTermsId = paymentTermsId;

    try {
      setLoading(true);
      const res = await wholesaleAccessService(preparedData);

      if (res) {
        const fetchCollection = await getDoc(
          doc(firestore, "distributors-direct-statuses", distributorId)
        );
        let dataCollection;
        if (fetchCollection.exists()) {
          dataCollection = fetchCollection.data()?.customers;
        } else {
          dataCollection = [];
        }

        await setDoc(
          doc(firestore, "distributors-direct-statuses", distributorId),
          {
            customers: [...dataCollection, uploadData],
          }
        );
      }

      setLoading(false);
      setShowRequestDialog(false);
      success("Wholesale Access Requested!");
    } catch (err) {
      setLoading(false);
      error(err?.response?.data?.message);
    }
  };

  return {
    currentStoreData,
    showRequestDialog,
    handleCloseOrderDirectAccess,
    handleChangePayterm,
    paymentTermsId,
    handleRequestWholesale,
    loading,
  };
};
