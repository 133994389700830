import { Grid, Link } from "@mui/material";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";

export const NotFoundPage = () => {
  return (
    <Grid container height="100vh" justifyContent="center" alignItems="center">
      <Grid item>
        <Box component="p" textAlign="center">
          <Link component={NavLink} to="/" underline="hover">
            Home
          </Link>
        </Box>
        <Box component="h1" textAlign="center" fontSize="150px" my="0">
          404
        </Box>
        <Box component="p" textAlign="center">
          Page not found
        </Box>
      </Grid>
    </Grid>
  );
};
