import { CircularProgress, Stack } from "@mui/material";
import { CartProductItem } from "components";
import { CartContext } from "pages/CartPage/CartPage";
import { useContext } from "react";
import { EmptyProductsList } from "..";

export const ProductsList = () => {
  const {
    getOutOfStock,
    productFields,
    removeProducts,
    setErrorQty,
    products,
    draftsLoading,
    cartData,
  } = useContext(CartContext);

  return (
    <>
      {(draftsLoading || cartData.loadingProducts) && !products.length ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          height="calc(100svh - 157px)"
        >
          <CircularProgress size={32} />
        </Stack>
      ) : (
        <>
          {products.length ? (
            <Stack gap="12px" mt="16px">
              {productFields.map((product, index) => (
                <CartProductItem
                  key={product.id}
                  product={products[index]}
                  index={index}
                  handleDelete={(id) => {
                    removeProducts(index);
                    setErrorQty((prev) => ({
                      ...prev,
                      [id]: null,
                    }));
                  }}
                  outOfStock={getOutOfStock(product, index)}
                />
              ))}
            </Stack>
          ) : (
            <EmptyProductsList />
          )}
        </>
      )}
    </>
  );
};
