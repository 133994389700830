import {} from "react";
import {} from "prop-types";
import { Box, Stack } from "@mui/material";
import { HeaderItem, ReturnOrderItem } from "./components";

export const ProductListBlock = () => {
  return (
    <Box mt={2.5}>
      <HeaderItem />
      <Stack border="0.5px solid #D5D9D9" borderRadius="0 0 4px 4px">
        <ReturnOrderItem
          name="Immunity Booster / small"
          supplier="GNGR Labs"
          itemCount={5}
        />
        <ReturnOrderItem
          name="Immunity Booster / small"
          supplier="GNGR Labs"
          itemCount={5}
        />
      </Stack>
    </Box>
  );
};

ProductListBlock.propTypes = {};
ProductListBlock.defaultProps = {};
