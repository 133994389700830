import { bool, func, object, string } from "prop-types";

import { CloseIcon } from "components/Icons";

import { useStyles } from "../styles";

import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import clsx from "clsx";

export const DrawerHeader = ({
  handleClose,
  formKey = null,
  title = "",
  titleElement = null,
  btnElement = null,
  disabled = false,
  withDivider = true,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.headerWrapper, {
        [classes.headerDividerWrapper]: withDivider,
      })}
    >
      {!!title && <Typography className={classes.title}>{title}</Typography>}
      {!!titleElement && titleElement}

      <Stack direction="row" gap="10px">
        {!!formKey && (
          <Button
            variant="outlined"
            className={classes.actionBtn}
            form={formKey}
            type="submit"
            disabled={disabled}
          >
            Save
          </Button>
        )}
        {!!btnElement && btnElement}

        <IconButton sx={{ p: 0 }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

DrawerHeader.propTypes = {
  handleClose: func,
  title: string,
  formKey: string,
  withDivider: bool,
  disabled: bool,
  titleElement: object,
  btnElement: object,
};
DrawerHeader.defaultProps = {
  title: "",
  formKey: "",
  disabled: false,
  handleClose: () => {},
  titleElement: null,
  btnElement: null,
};
