import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "28px 32px 0px",
    "& .MuiTypography-root": {
      fontSize: "30px",
    },
  },

  dialogWrapper: {
    minHeight: "653px",
  },

  contentWrapper: {
    minWidth: "932px",
    padding: 0,
  },

  tab: {
    textTransform: "none",
    fontSize: "20px",
    color: "#707070",
  },
}));

export default useStyles;
