import { Checkbox, FormControlLabel } from "@mui/material";
import {
  CheckboxIcon,
  CheckboxIconChecked,
  CheckedIcon,
  UncheckedIcon,
} from "../Icons";
import PropTypes, { string, number, object, bool, any } from "prop-types";
import { forwardRef } from "react";

const LargeCheckbox = forwardRef(
  (
    {
      stroke,
      size,
      label,
      formSx,
      fill,
      labelPlacement,
      disabled,
      checkedColor,
      labelSx,
      checkboxSx,
      ...props
    },
    ref
  ) => {
    return (
      <FormControlLabel
        sx={formSx}
        labelPlacement={labelPlacement}
        disabled={disabled}
        control={
          <Checkbox
            ref={ref}
            sx={checkboxSx}
            icon={<CheckboxIcon stroke={stroke} size={size} fill={fill} />}
            checkedIcon={
              <CheckboxIconChecked size={size} color={checkedColor} />
            }
            {...props}
          />
        }
        componentsProps={{
          typography: {
            sx: labelSx,
          },
        }}
        label={label}
      />
    );
  }
);

LargeCheckbox.displayName = "LargeCheckbox";

LargeCheckbox.propTypes = {
  stroke: string,
  size: number,
  formSx: object,
  label: PropTypes.oneOfType([string, object]),
  labelPlacement: string,
  disabled: bool,
  fill: string,
  checkedColor: string,
  labelSx: object,
  checkboxSx: object,
};

LargeCheckbox.defaultProps = {
  stroke: "rgb(112,112,112)",
  label: "",
  labelPlacement: "end",
  disabled: false,
  labelSx: {},
};

const SmallCheckbox = forwardRef(
  ({ formSx, label, width, height, ...props }, ref) => {
    return (
      <FormControlLabel
        sx={formSx}
        label={label}
        control={
          <Checkbox
            ref={ref}
            icon={<UncheckedIcon width={width} height={height} />}
            checkedIcon={<CheckedIcon width={width} height={height} />}
            {...props}
          />
        }
      />
    );
  }
);

SmallCheckbox.propTypes = {
  formSx: object,
  label: any,
  width: string,
  height: string,
};

SmallCheckbox.defaultProps = {
  label: "",
};

SmallCheckbox.displayName = "SmallCheckbox";

export { LargeCheckbox, SmallCheckbox };
