import { string } from "prop-types";

import { FulfillmentStatus } from "components";

import { Stack } from "@mui/material";

export const FulfillmentHeader = ({ deliveryStatus }) => {
  return (
    <Stack width="100%" direction="row" alignItems="center">
      <FulfillmentStatus
        deliveryStatus={deliveryStatus}
        wrapperSx={{
          gap: "8px",
          "& .MuiTypography-root": {
            fontSize: "18px",
            fontWeight: 500,
          },
        }}
      />
    </Stack>
  );
};

FulfillmentHeader.propTypes = {
  deliveryStatus: string,
  termsName: string,
};
