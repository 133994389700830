import * as React from "react";
export const UploadFileIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10.095}
    height={12.258}
    viewBox="0 0 10.095 12.258"
    {...props}
  >
    <path
      data-name="Icon metro-file-upload"
      d="M2.885 9.373h4.326V5.047h2.884L5.048 0 .003 5.047h2.884ZM.003 10.816h10.092v1.442H.003Z"
      fill="#707070"
    />
  </svg>
);
export default UploadFileIcon;
