import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DRAWERS } from "constants/drawer";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import {
  GoogleAutocompleteField,
  StyledSelect,
  StyledTextField,
} from "components";
import { getStates, sliceCountry } from "helpers/helpers";
import { getAddressComponents } from "simply-depo-ui-components";
import { StyledArrowIcon } from "components/Icons";
import { cl } from "pages/ProductsPage/components/MobileProductCard/styles";
import { cl as classes } from "components/ContactDrawer/styles";
import { useEffect } from "react";

export const useUserLocationDrawer = ({
  locationIndex,
  formField,
  setValue,
  fieldName,
  handleDeleteLocation,
  setEditLocationIndex,
  resetFunc,
}) => {
  const dispatch = useDispatch();
  const [defaultLocationValue, setDefaultLocationValue] = useState(false);

  const isDrawerOpened = useSelector(
    ({ uiState }) => uiState.userLocationsDrawer
  );

  useEffect(() => {
    setDefaultLocationValue(formField?.locations?.[locationIndex]?.defaultLoc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpened]);

  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const currentLocation = formField?.locations?.[locationIndex];

  const isNewLocation =
    isDrawerOpened && !formField?.locations?.[locationIndex]?.id;

  const onClose = useCallback(() => {
    setEditLocationIndex();
    if (!currentLocation.id) handleDeleteLocation(locationIndex, true);
    resetFunc();
    dispatch(
      showCurrentDrawerAction({
        type: DRAWERS.USER_LOCATIONS_DRAWER,
        show: false,
      })
    );
  }, [
    setEditLocationIndex,
    currentLocation?.id,
    handleDeleteLocation,
    locationIndex,
    resetFunc,
    dispatch,
  ]);

  const FIELD_DATA = [
    {
      label: "Name",
      Input: StyledTextField,
      name: `${fieldName}.name`,
      placeholder: "Name",
    },
    {
      label: "Street address",
      name: `${fieldName}.formatted_address`,
      Input: GoogleAutocompleteField,
      noErrorMessage: true,
      onPlaceSelected: (place) => {
        const { street, state, city, zip } = getAddressComponents(place);
        setValue(
          `${fieldName}.formatted_address`,
          sliceCountry(place.formatted_address)
        );
        setValue(`${fieldName}.lat`, place.geometry.location.lat());
        setValue(`${fieldName}.lng`, place.geometry.location.lng());
        setValue(`${fieldName}.street`, street);
        setValue(`${fieldName}.state`, state);
        setValue(`${fieldName}.city`, city);
        setValue(`${fieldName}.zip`, zip);
      },
      placeholder: "Street address",
    },
    {
      label: "Unit",
      Input: StyledTextField,
      name: `${fieldName}.appartement`,
      placeholder: "Unit",
    },
    {
      label: "City",
      Input: StyledTextField,
      name: `${fieldName}.city`,
      placeholder: "City",
    },
    {
      label: "State",
      name: `${fieldName}.state`,
      Input: StyledSelect,
      isSelect: true,
      placeholder: "State",
      selectProps: {
        placeholder: "State",
        noErrorMessage: true,
        notched: false,
        fullWidth: true,
        IconComponent: StyledArrowIcon,
        sx: classes.select,
        displayEmpty: true,
        height: "36px",
        MenuProps: {
          PaperProps: {
            sx: { ...cl.paperStyles, width: "100%" },
          },
        },
      },
      menuList: getStates(currentUser.country),
    },
    {
      label: "ZIP code",
      name: `${fieldName}.zip`,
      Input: StyledTextField,
      placeholder: "ZIP code",
    },
  ];

  return {
    onClose,
    FIELD_DATA,
    isNewLocation,
    currentLocation,
    isDrawerOpened,
    defaultLocationValue,
  };
};
