import { Controller } from "react-hook-form";
import { array, func, object, string } from "prop-types";

import { LargeCheckbox, StyledTextField } from "components";
import { FileAttachmentBlock } from "../FileAttachmentBlock";

import { cl as classes } from "../../../ContactDrawer/styles";

import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { normalizeUppercaseString } from "helpers/helpers";
import useStyles from "pages/VendorsPage/components/DesktopVendors/components/ContactDialog/components/ContactTab/styles";

export const ContentBlock = ({
  control,
  inputRef,
  onAttach,
  handleRemoveFile,
  attachments,
  FIELD_DATA,
  setValue,
  setRecipientsSearch,
  CCList,
  processingIntoChip,
  handlePressSpace,
  recipientsSearch,
}) => {
  const styles = useStyles();
  return (
    <Stack width="100%">
      <Stack gap="16px">
        <Controller
          render={({ field, fieldState: { error } }) => (
            <Stack>
              <InputLabel
                shrink
                sx={{
                  ...classes.label,
                  ...(error && classes.errorLabel),
                }}
              >
                To
              </InputLabel>
              <Autocomplete
                multiple
                freeSolo
                disableCloseOnSelect
                PaperComponent={(props) => (
                  <Box {...props} className={styles.recipientsTable} />
                )}
                size="small"
                isOptionEqualToValue={(option, value) =>
                  option.email === value.email
                }
                getOptionLabel={(option) => option.email}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    noErrorMessage
                    inputProps={{
                      ...params.inputProps,
                      value: recipientsSearch,
                    }}
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        ...classes.textInput,
                        ...(error && classes.errorTextInput),
                      },
                      placeholder: "Emails",
                      endAdornment: <></>,
                    }}
                    error={error?.message || ""}
                    placeholder="Emails"
                    value={recipientsSearch}
                    inputSx={{ color: "#707070" }}
                    onChange={(e) => setRecipientsSearch(e.target.value)}
                    onKeyDown={handlePressSpace}
                    onBlur={processingIntoChip}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "fit-content",
                        minHeight: "37px",
                        p: "5px!important",
                        fontSize: "14px",
                      },
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    p: 0,
                    mt: "4px",
                    maxHeight: "178px",
                    overflow: "overlay",
                    borderRadius: "8px",
                    border: "1px solid #e7e7e7",
                  },
                }}
                renderOption={(props, recipient, { selected }) => (
                  <Grid
                    key={recipient.email}
                    container
                    columns={10}
                    sx={{
                      backgroundColor: selected && "rgba(66, 165, 127, 0.1)",
                    }}
                    {...props}
                    className={styles.recipientItem}
                  >
                    <Grid item xs={7}>
                      <Box display="flex" gap="11px" alignItems="center">
                        <Box>
                          <Typography>{recipient.name}</Typography>
                          <Typography fontWeight="400">
                            {recipient.email}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {normalizeUppercaseString(recipient.role)}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                options={CCList || []}
                renderTags={(value, getTagProps) => (
                  <Box className={styles.recipientChipWrapper}>
                    {value.map((cc, index) => (
                      <Chip
                        key={cc.email}
                        sx={{
                          borderRadius: "10px",
                          border: "1px solid #D4D4D4",
                          backgroundColor: "#F8F8FA",
                          height: "19px",
                          maxWidth: "90%",
                        }}
                        size="small"
                        label={
                          <Typography
                            sx={{ fontSize: "12px", lineHeight: 1.2 }}
                            color="groundLighter.main"
                            noWrap
                          >
                            {cc.email}
                          </Typography>
                        }
                        onMouseDown={(e) => e.stopPropagation()}
                        {...getTagProps({ index })}
                      />
                    ))}
                  </Box>
                )}
                {...field}
                onChange={(e, newValue) => {
                  setRecipientsSearch("");
                  setValue(field.name, newValue);
                }}
              />
            </Stack>
          )}
          name="cc"
          control={control}
        />
        {FIELD_DATA.map(
          (
            { name, label, placeholder, simpleStyles, Input, ...props },
            index
          ) => (
            <Controller
              key={index}
              render={({ field, fieldState: { error } }) => {
                return (
                  <Stack>
                    <InputLabel
                      shrink
                      sx={{
                        ...classes.label,
                        ...(error && classes.errorLabel),
                      }}
                    >
                      {label}
                    </InputLabel>
                    <Input
                      fullWidth
                      size="small"
                      error={error ? error.message : ""}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          p: "0!important",
                        },
                      }}
                      InputProps={{
                        ...props,
                        sx: {
                          ...(simpleStyles
                            ? classes.outlinedTextInput
                            : classes.textInput),
                          ...(error && classes.errorTextInput),
                        },
                        placeholder,
                      }}
                      {...field}
                    />
                  </Stack>
                );
              }}
              name={name}
              control={control}
            />
          )
        )}

        <FileAttachmentBlock
          {...{ inputRef, onAttach, handleRemoveFile, attachments }}
        />

        <Controller
          render={({ field }) => (
            <LargeCheckbox
              checked={!!field.value}
              formSx={{
                ml: 0,
                "& .MuiFormControlLabel-root": {
                  ml: 0,
                },
              }}
              checkboxSx={{
                padding: 0,
                margin: "0 8px 0 1px",
              }}
              label={
                <Typography color="black" fontSize="14px" fontWeight="400">
                  Send me a copy of this email
                </Typography>
              }
              size={15}
              {...field}
            />
          )}
          name="sendMe"
          control={control}
        />
      </Stack>
    </Stack>
  );
};

ContentBlock.propTypes = {
  control: object,
  inputRef: object,
  onAttach: func,
  handleRemoveFile: func,
  attachments: array,
  FIELD_DATA: array,
  setValue: func,
  setRecipientsSearch: func,
  CCList: array,
  processingIntoChip: func,
  handlePressSpace: func,
  recipientsSearch: string,
};
