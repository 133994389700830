import { array, func, object } from "prop-types";

import { cl as classes } from "../styles";
import { cl } from "../../../pages/ProductsPage/components/MobileProductCard/styles";

import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { LargeCheckbox, StyledSelect, StyledTextField } from "components";
import { normalizeUppercaseString } from "helpers/helpers";
import { OutlinedPlusIcon, StyledArrowIcon } from "components/Icons";
import { TYPES } from "pages/CartPage/components/ContactsBlock/AddContactPopup/contactTypes";

export const ContentBlock = ({
  FIELD_DATA,
  control,
  formField,
  isCustom,
  contacts,
  onContactDelete,
  deleteContact,
  editContact,
}) => {
  return (
    <Grid
      display="flex"
      sx={{ flexDirection: "column", width: "100%", gap: "12px", mt: "20px" }}
      container
    >
      {FIELD_DATA.map(
        (
          { name, Input, label, mask, customInput, format, placeholder },
          index
        ) => (
          <Controller
            key={index}
            render={({ field, fieldState: { error } }) => (
              <Stack>
                <InputLabel
                  shrink
                  sx={{
                    ...classes.label,
                    ...(error && classes.errorLabel),
                  }}
                >
                  {label}
                </InputLabel>
                <Input
                  {...(name === "phone" && { mask, customInput, format })}
                  fullWidth
                  size="small"
                  error={error ? error.message : ""}
                  InputProps={{
                    sx: {
                      ...classes.textInput,
                      ...(error && classes.errorTextInput),
                    },
                    placeholder,
                  }}
                  {...field}
                />
              </Stack>
            )}
            name={name}
            control={control}
          />
        )
      )}
      <Controller
        render={({ field, fieldState: { error } }) => (
          <Stack>
            <InputLabel
              shrink
              sx={{
                ...classes.label,
                ...(error && classes.errorLabel),
              }}
            >
              Role
            </InputLabel>
            <StyledSelect
              IconComponent={StyledArrowIcon}
              sx={classes.select}
              displayEmpty
              height="36px"
              error={error ? error.message : ""}
              MenuProps={{
                PaperProps: {
                  sx: { ...cl.paperStyles, width: "100%" },
                },
              }}
              {...field}
              renderValue={(s) =>
                s ? normalizeUppercaseString(s) : <span>Sales Manager</span>
              }
            >
              {TYPES.map((type) => (
                <MenuItem key={type} value={type} sx={{ fontSize: "12px" }}>
                  {type}
                </MenuItem>
              ))}
              <MenuItem
                value="custom"
                sx={{
                  color: "#409A65",
                  fontSize: "12px",
                  "& svg": {
                    mr: "5px",
                  },
                }}
              >
                <OutlinedPlusIcon /> Custom
              </MenuItem>
            </StyledSelect>
          </Stack>
        )}
        name="role"
        control={control}
      />

      {isCustom(formField?.role) && (
        <Controller
          render={({ field, fieldState: { error } }) => (
            <Stack>
              <InputLabel
                shrink
                sx={{
                  ...classes.label,
                  ...(error && classes.errorLabel),
                }}
              >
                Custom
              </InputLabel>
              <StyledTextField
                fullWidth
                size="small"
                InputProps={{
                  sx: {
                    ...classes.textInput,
                    ...(error && classes.errorTextInput),
                  },
                  placeholder: "Primary",
                }}
                error={error ? error.message : ""}
                {...field}
              />
            </Stack>
          )}
          name="custom"
          control={control}
        />
      )}

      <Controller
        render={({ field }) => (
          <LargeCheckbox
            disabled={!contacts.length || editContact?.defaultContact}
            checked={!!field.value}
            checkedColor={
              !contacts?.length || editContact?.defaultContact
                ? "#bdbdbd"
                : "#47a06d"
            }
            formSx={{
              ml: 0,
              "& .MuiFormControlLabel-root": {
                ml: 0,
              },
            }}
            checkboxSx={{
              padding: 0,
              margin: "0 8px 0 1px",
            }}
            label={
              <Typography color="black" fontSize="14px" fontWeight="400">
                Primary contact
              </Typography>
            }
            size={15}
            {...field}
          />
        )}
        name="defaultContact"
        control={control}
      />

      {!!deleteContact &&
        !!editContact &&
        !editContact?.defaultContact &&
        !!Object.keys(editContact)?.length && (
          <Button
            disableTouchRipple
            onClick={onContactDelete}
            sx={{
              mt: "20px",
              height: "18px",
              padding: 0,
              whiteSpace: "nowrap",
              width: "fit-content",
              minWidth: 0,
              color: "#E41E3A",
              fontSize: 14,
            }}
          >
            Delete Contact
          </Button>
        )}
    </Grid>
  );
};

ContentBlock.propTypes = {
  FIELD_DATA: array,
  contacts: array,
  control: object,
  formField: object,
  handleSubmit: func,
  onSubmit: func,
  onContactDelete: func,
  deleteContact: func,
  isCustom: func,
  editContact: object,
};
