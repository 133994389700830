import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { StyledCrumb } from "./StyledCrumb";
import { CRUMBS_ITEMS } from "../../RegistrationPage.constants";

export const RegistrationBreadCrumbsNav = () => {
  const { pathname } = useLocation();
  const currentStep = pathname?.split("/").reverse()?.[0];
  const currentStepNumber = currentStep.includes("step")
    ? Number(currentStep[5])
    : 1;

  return (
    <Box
      sx={{
        maxHeight: "60px",
        height: "60px",
        borderTop: { xs: "none", sm: "1px solid #D5D9D9" },
        borderBottom: { xs: "none", sm: "1px solid #D5D9D9" },
        display: "flex",
        justifyContent: "center",
        gap: { xs: "20px", sm: "3px" },
        alignItems: "center",
        mx: "auto",
        px: { xs: 0, sm: "42.5px" },
      }}
    >
      {CRUMBS_ITEMS.map(({ id, name }) => (
        <StyledCrumb
          key={id}
          isActive={id === (currentStepNumber === 1.1 ? 1 : currentStepNumber)}
          id={id}
          name={name}
        />
      ))}
    </Box>
  );
};
