import { useContext } from "react";

import { TabTitle } from "..";
import { ConfirmCardDelete, PaymentCard } from "components";
import { PaymentsContext } from "pages/PaymentsPage/PaymentsPage";

import { isCardExpired } from "helpers/helpers";

import { cl } from "./styles";

import { Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const WalletListBlock = () => {
  const {
    rows,
    onAddNewCard,
    setConfirmState,
    onConfirmDelete,
    handleSetDefault,
    defaultCreditCard,
    handleOpenEditCard,
  } = useContext(PaymentsContext);

  return (
    <Stack gap="8px">
      <TabTitle title="Your credit & debit cards" />
      {!!rows?.length &&
        rows?.map((card) => (
          <PaymentCard
            {...{
              card,
              selectedCard: defaultCreditCard,
              handleSelectCard: () => handleSetDefault(card),
              isCardExpired,
              handleDeleteCard: () =>
                setConfirmState((prev) => ({
                  ...prev,
                  onConfirm: () => onConfirmDelete(card?.id),
                  title: "Delete payment method?",
                  isOpen: true,
                  cardStateItem: card,
                  type: "drawer",
                  titleElement: <ConfirmCardDelete card={card} />,
                })),
              onEditOpen: () => handleOpenEditCard(card),
            }}
            key={card.id}
          />
        ))}
      <Button sx={cl.editBtn} onClick={onAddNewCard}>
        <AddIcon />
        Add credit card
      </Button>
    </Stack>
  );
};
