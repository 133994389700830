import { createContext } from "react";
import { bool, func, object } from "prop-types";
import { useOutlet } from "react-router-dom";
import { useWatch } from "react-hook-form";

import { Box } from "@mui/material";

export const Context = createContext({});

export const RegistrationBody = ({
  control,
  onSubmit,
  setValue,
  errors,
  isLoading,
  errPhoneStatus,
  trigger,
  clearErrors,
}) => {
  const outlet = useOutlet();
  const { step, businessEmail } = useWatch({ control });

  return (
    <Context.Provider
      value={{
        control,
        step,
        businessEmail,
        setValue,
        errors,
        isLoading,
        errPhoneStatus,
        trigger,
        clearErrors,
      }}
    >
      <Box
        px={{ xs: 0, sm: "42.5px" }}
        py={2}
        display="flex"
        flexDirection="column"
        gap={{ xs: "8px", sm: 2 }}
        component="form"
        id="registration-form"
        onSubmit={onSubmit}
      >
        {outlet}
      </Box>
    </Context.Provider>
  );
};

RegistrationBody.propTypes = {
  control: object,
  onSubmit: func,
  setValue: func,
  clearErrors: func,
  errors: object,
  isLoading: bool,
  errPhoneStatus: object,
  trigger: func,
};
