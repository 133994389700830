import * as React from "react";

export const RegBoardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={72}
    height={72}
    viewBox="0 0 72 72"
    {...props}
  >
    <rect
      data-name="Rectangle 9432"
      width={72}
      height={72}
      rx={4}
      fill="rgba(64,154,101,0.1)"
    />
    <g data-name="Group 5571">
      <path
        data-name="Path 2996"
        d="M29.755 39.369h4.685m-4.685-6.246h8Z"
        fill="none"
        stroke="#409a65"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <g data-name="Path 4227" fill="none">
        <path d="M27.793 22.292h16.416a3.571 3.571 0 0 1 3.571 3.571v20.7a3.571 3.571 0 0 1-3.571 3.571H27.793a3.571 3.571 0 0 1-3.571-3.572V25.863a3.571 3.571 0 0 1 3.571-3.571Z" />
        <path
          d="M27.792 23.792c-1.141 0-2.07.929-2.07 2.07v20.7c0 1.142.929 2.071 2.07 2.071h16.417c1.142 0 2.07-.929 2.07-2.07V25.862a2.072 2.072 0 0 0-2.07-2.071H27.793m0-1.5h16.416a3.57 3.57 0 0 1 3.57 3.57v20.7a3.57 3.57 0 0 1-3.57 3.571H27.793a3.57 3.57 0 0 1-3.571-3.569V25.862a3.57 3.57 0 0 1 3.57-3.57Z"
          fill="#409a65"
        />
      </g>
      <path
        data-name="Path 2995"
        d="M37.562 19.631h-3.123a3.123 3.123 0 0 0 0 6.246h3.123a3.123 3.123 0 0 0 0-6.246Z"
        fill="#fef9ee"
        stroke="#409a65"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
);
