import { bool, number, object, string } from "prop-types";
import moment from "moment";
import { cl } from "./styles";

import { Stack, Typography } from "@mui/material";
import { isCardExpired, separateNumWithComma } from "helpers/helpers";
import { PaymentDetailsBlock } from "./PaymentDetailsBlock";
import { PaymentCard } from "components";

export const PaymentItem = ({ payment, index, title, isRefund }) => {
  const { creditCard, amount, date, paymentType, reference, check } = payment;

  return (
    <Stack direction="column" sx={cl.cardWrapper} mx="10px">
      <Stack direction="row" gap="8px" justifyContent="space-between" mb="2px">
        <Typography
          sx={{
            color: "rgba(0, 0, 0, 0.80)",
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "21px",
          }}
        >
          {title}
        </Typography>

        <Stack sx={cl.paymentNumber}>
          <Typography sx={cl.paymentNumberText}>Payment #{index}</Typography>
        </Stack>
      </Stack>
      {creditCard && (
        <PaymentCard
          {...{
            card: creditCard,
            isCardExpired,
            hideAction: true,
          }}
          key={index}
        />
      )}

      {paymentType === "CASH" && (
        <Stack gap="2px">
          <Typography sx={cl.cashTitle}>Reference #</Typography>
          <Typography sx={{ ...cl.cashTitle, fontWeight: 400 }} noWrap>
            {reference || "-"}
          </Typography>
        </Stack>
      )}

      {paymentType === "CHECK" && (
        <Stack gap="2px">
          <Typography sx={cl.cashTitle}>Check #</Typography>
          <Typography sx={{ ...cl.cashTitle, fontWeight: 400 }} noWrap>
            {check || "-"}
          </Typography>
        </Stack>
      )}

      <Stack width="100%" direction="row" mt="2px">
        <PaymentDetailsBlock
          title="Date"
          value={`${moment(date).format("MM/DD/YYYY")}`}
          sx={{ width: "50%" }}
        />
        <PaymentDetailsBlock
          title="Amount"
          value={`${isRefund ? "-" : ""}$${separateNumWithComma(
            amount.toFixed(2)
          )}`}
          sx={{ width: "50%" }}
        />
      </Stack>
    </Stack>
  );
};

PaymentItem.propTypes = {
  payment: object,
  index: number,
  title: string,
  isRefund: bool,
};
