import { useContext } from "react";
import { bool } from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { StyledAvatar, StyledButton, StyledTooltip } from "components";
import { photoUrl } from "helpers/helpers";
import UploadFile from "components/UploadFile";
import { ProfileContext } from "pages/ProfilePage/NewProfile";
import { Pen2Icon } from "components/Icons";

export const LogoBlock = ({ isMobile }) => {
  const {
    formField,
    handleUpdateLogo,
    tooltipRef,
    tooltipData,
    setTooltipData,
    isCroppedDialogOpen,
    setIsCroppedDialogOpen,
    preparingPhoto,
    setPreparingPhoto,
    setProgressPhotoUpload,
    handleDeleteLogo,
  } = useContext(ProfileContext);

  return (
    <Box
      pl={isMobile ? 0 : "27px"}
      pt={isMobile ? 0 : "12px"}
      display="flex"
      alignItems="center"
      sx={
        {
          // border: "1px solid lightblue",
        }
      }
    >
      <Box position="relative">
        <StyledAvatar
          sx={{
            height: isMobile ? "58px" : "61px",
            width: isMobile ? "58px" : "61px",
            border: "1px solid #CBCBCB",
          }}
          variant={isMobile ? "rounded" : "circular"}
          src={photoUrl(formField.profilePhoto?.fileName)}
          stringStyle={{ fontSize: "25px" }}
          name={formField.name}
        />
        {isMobile && (
          <Box
            sx={{
              position: "absolute",
              bottom: "-4px",
              right: "-7px",
              width: "20px",
              height: "20px",
              border: "1px solid #00000010",
              borderRadius: "50%",
              bgcolor: "#FFFFFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
            }}
          >
            <Pen2Icon />
          </Box>
        )}
      </Box>

      <Box>
        <Box
          ml={isMobile ? "6px" : null}
          mt={isMobile ? "-6px" : null}
          display="flex"
          alignItems="center"
        >
          <StyledTooltip
            open={tooltipData.open && !isCroppedDialogOpen}
            title={tooltipData.text}
            placement="top"
            arrow
          >
            <Box
              ref={tooltipRef}
              onMouseEnter={() =>
                setTooltipData((prev) => ({ ...prev, open: true }))
              }
              onMouseLeave={() =>
                setTooltipData((prev) => ({ ...prev, open: false }))
              }
              onMouseDown={() =>
                setTooltipData((prev) => ({ ...prev, open: false }))
              }
            >
              <UploadFile
                label={isMobile ? "Upload logo" : "Upload new logo"}
                accept="image/png, image/gif, image/jpeg"
                wrapperProps={{ sx: { p: "5px" } }}
                onChange={(data) => handleUpdateLogo(data)}
                Wrapper={Button}
                path="users/app-customers/profile-photo"
                withCropper
                dialogSize="small"
                settingsCropper={{ cropShape: "round" }}
                uploadData={{ type: "PROFILE_APP_CUSTOMER" }}
                setIsOpen={setIsCroppedDialogOpen}
                progressUpload={setProgressPhotoUpload}
                {...{ preparingPhoto, setPreparingPhoto }}
              />
            </Box>
          </StyledTooltip>
          {formField.profilePhoto && (
            <>
              <Typography color="#707070">•</Typography>
              <StyledButton
                label="Remove logo"
                fontSize="14px"
                sx={{ p: "5px" }}
                onClick={handleDeleteLogo}
              />
            </>
          )}
        </Box>
        <Box>
          {isMobile ? (
            <Typography
              mt="-4px"
              ml="11px"
              fontSize={11}
              fontWeight={400}
              color="#00000040"
            >
              Logos help recognize your company
            </Typography>
          ) : (
            <Typography ml="5px" mt="-3px" fontSize={12} color="#D4D4D4">
              • Use at least 500 px by 500 px <br /> • Use white or neutral
              background
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

LogoBlock.propTypes = {
  isMobile: bool,
};
LogoBlock.defaultProps = {
  isMobile: false,
};
