import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mobileLink: {
    borderRadius: "8px",
    minHeight: "38px",
    boxSizing: "border-box",
    textDecoration: "none",
    gap: "16px",
    display: "flex",
    alignItems: "center",
    color: "#2F3040",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "24.30px",
  },

  mobileActive: {
    color: "#409A65",
  },

  simpleLink: {
    gap: "8px",
  },

  iconWrapper: {
    background: "#EFEFEF",
    borderRadius: "6px",
    width: "52px",
    height: "52px",
    padding: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& path": {
      stroke: "#0F172A !important",
    },
  },
  activeIconWrapper: {
    background: "#409A65",

    "& path": {
      stroke: "#fff !important",
    },
  },
}));

export default useStyles;
