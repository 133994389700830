export const colors = {
  primary: "#409A65",
  primaryLight: "#26874b",
  errorLight: "#FF6254",
  errorMain: "#FF3737",
  error: "#FF3737",
  grey: "#707070",
  borderOutline: "#D5D9D9",
  groundBlack: "#1C1C19",
  clearBlack: "#000",
  white: "#FFFFFF",
  ground: "#3F3F3F",
  blackBtn: "#363531",
  edit: "#707070",
  greyLight: "#5F6267",
  groundLight: "#8E8E8E",
};
