import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { bool, func, number, object } from "prop-types";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { TrashIcon } from "components/Icons";
import { StyledProductIco, StyledTooltip, TextFieldWithBtns } from "components";
import { photoUrl, separateNumWithComma } from "helpers/helpers";
import { CartContext } from "pages/CartPage/CartPage";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

export const AccDetailItem = ({ product, index, handleDelete, outOfStock }) => {
  const {
    wholesalePrice,
    quantity,
    name,
    manufacturer,
    sku,
    color,
    size,
    photos,
    photo,
    parentProduct,
    itemsPerCase,
    sellingOutOfStock,
  } = useMemo(() => product, [product]);

  const getSellingOutOfStock = parentProduct
    ? parentProduct?.sellingOutOfStock
    : sellingOutOfStock;

  const variation = useMemo(() => color || size, [color, size]);

  const { handleDecreaseQty, handleAddQty, setQuantity, control, setErrorQty } =
    useContext(CartContext);

  const [tooltipState, setTooltipState] = useState({
    open: false,
    title: "",
    type: "",
  });

  const onHand = product?.inventory?.onHand || 0;
  const allocated = product?.inventory?.allocated || 0;
  const getAvailableQTY = onHand - allocated;

  const minOrderQTY =
    product?.minOrderQTY ||
    product?.product?.minOrderQTY ||
    product?.parentProduct?.minOrderQTY ||
    product?.product?.parentProduct?.minOrderQTY;

  const handleTooltipState = useCallback(() => {
    if (minOrderQTY > quantity) {
      setErrorQty((prev) => ({ ...prev, [product?.id]: product }));
      return setTooltipState({
        open: true,
        title: `Minimum Order Quantity: ${minOrderQTY}`,
        type: "minimumOrderQuantity",
      });
    }

    if (
      product?.type === PRODUCT_TYPE_INVENTORY.inventory &&
      !getSellingOutOfStock &&
      quantity > getAvailableQTY
    ) {
      setErrorQty((prev) => ({ ...prev, [product?.id]: product }));
      return setTooltipState({
        open: true,
        title:
          getAvailableQTY <= 0
            ? "Out of stock"
            : `Only ${getAvailableQTY} items in stock`,
        type: "sellingOutOfStock",
      });
    }

    setErrorQty((prev) => ({ ...prev, [product?.id]: null }));
    return setTooltipState((prev) => ({
      ...prev,
      open: false,
    }));
  }, [
    getAvailableQTY,
    minOrderQTY,
    product,
    quantity,
    getSellingOutOfStock,
    setErrorQty,
  ]);

  useEffect(() => {
    handleTooltipState();
  }, [handleTooltipState, minOrderQTY, quantity]);

  return (
    <Grid
      sx={{
        borderWidth: "0 0 0.5px 0",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
        width: "100%",
        height: "74px",
        alignItems: "center",
      }}
      container
    >
      {/* DELETE BTN */}
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={0.5}
        item
      >
        <IconButton
          sx={{ width: "38px", height: "38px" }}
          onClick={() => handleDelete(product?.id)}
        >
          <TrashIcon />
        </IconButton>
      </Grid>

      {/* PRODUCT */}
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
        xs={8}
        item
      >
        <Box
          sx={{
            width: "53px",
            minWidth: "53px",
            height: "53px",
          }}
        >
          <StyledProductIco
            src={
              photos?.length
                ? photoUrl(photos[0].fileName)
                : product?.photo
                ? photoUrl(photo?.fileName)
                : ""
            }
            styles={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
              borderRadius: "4px",
              border: "0.5px solid #D5D9D9",
            }}
            placeholderStyles={{
              width: "53px",
              height: "53px",
              wrapperProps: { height: "53px" },
            }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: "calc(100% - 69px)" }}
        >
          <Typography fontSize={14} color="#1C1C19" noWrap>
            {name || parentProduct?.name} / {sku}{" "}
            {variation && `/ ${variation}`}
            <Box
              fontSize={14}
              fontWeight={300}
              color="#1C1C19"
              component="span"
            >
              {!!itemsPerCase && ` (${itemsPerCase} per case)`}
            </Box>
          </Typography>
          <Typography fontSize={14} fontWeight={300} color="#1C1C19" noWrap>
            by {manufacturer?.name || parentProduct?.manufacturer?.name}
          </Typography>
        </Box>
      </Grid>

      {/* QTY */}
      <Grid xs={1.6} item>
        <StyledTooltip
          disableHoverListener
          open={tooltipState.open}
          placement="top"
          title={tooltipState.title}
          arrow
          sx={{ zIndex: 1200 }}
        >
          <TextFieldWithBtns
            value={quantity}
            setValue={(e) => setQuantity(e, product, outOfStock, index)}
            onClickLeftBtn={() => handleDecreaseQty(index, quantity)}
            onClickRightBtn={() => handleAddQty(index, quantity)}
            index={index}
            control={control}
            disabledIncrementBtn={
              tooltipState.open && tooltipState.type === "sellingOutOfStock"
            }
          />
        </StyledTooltip>
      </Grid>

      {/* TOTAL */}
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          pr: 4,
        }}
        xs={1.9}
        item
      >
        <Typography fontSize={15} color="#000">
          ${separateNumWithComma((wholesalePrice * quantity)?.toFixed(2) || 0)}
        </Typography>
        <Typography fontSize={12} fontWeight={300} color="#000">
          (${separateNumWithComma(wholesalePrice?.toFixed(2) || 0)} per case)
        </Typography>
      </Grid>
    </Grid>
  );
};

AccDetailItem.propTypes = {
  product: object,
  index: number,
  handleDelete: func,
  outOfStock: bool,
};
AccDetailItem.defaultProps = {};
