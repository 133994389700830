/* eslint-disable react/prop-types */
import React from "react";

export const TriangleDownIcon = ({
  width = 7.848,
  height = 3.924,
  color = "#47a06d",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 7.848 3.924"
    >
      <path
        id="Icon_ionic-md-arrow-dropdown"
        data-name="Icon ionic-md-arrow-dropdown"
        d="M9,13.5l3.924,3.924L16.848,13.5Z"
        transform="translate(-9 -13.5)"
        fill={color}
      />
    </svg>
  );
};
