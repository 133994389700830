import { string } from "prop-types";

import { PaymentStatuses } from "components";

import { Stack, Typography } from "@mui/material";

export const ContentHeader = ({ paymentStatus, termsName }) => {
  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap="8px"
      sx={{ overflowX: "hidden" }}
    >
      <Stack direction="row" alignItems="center" gap="8px">
        <Typography
          sx={{
            color: "black",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          Payment
        </Typography>
        <PaymentStatuses str={paymentStatus} />
      </Stack>
      {termsName && (
        <Typography
          noWrap
          sx={{
            color: "black",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "16.2px",
          }}
        >
          Terms: {termsName}
        </Typography>
      )}
    </Stack>
  );
};

ContentHeader.propTypes = {
  paymentStatus: string,
  termsName: string,
};
