import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: "12px",
    height: "34px",
  },

  inputLabel: {
    fontSize: "12px",
    color: "#B5B5AC",
  },
}));

export default useStyles;
