import { useContext } from "react";
import { bool, func } from "prop-types";
import { Box, Dialog, DialogActions } from "@mui/material";
import {
  HeaderBlock,
  HeaderListBlock,
  ItemsListBlock,
  SearchBlock,
} from "./components";
import { StyledButton } from "components";
import { CartContext } from "pages/CartPage/CartPage";
import { useViewAllProducts } from "./ViewAllProductsDialog.hooks";

export const ViewAllProductsDialog = ({ open, onClose }) => {
  const { handleAddProducts, cartData } = useContext(CartContext);

  const {
    productsState,
    params,
    handleFetchProducts,
    handleSearch,
    handleApplyFilter,
    handleDeleteFilter,
    switches,
    filterFields,
    selectMatchData,
    searchDebounced,
  } = useViewAllProducts({ open });

  return (
    <Dialog open={open} PaperProps={{ sx: { maxWidth: "784px" } }}>
      <Box sx={{ width: "784px", px: "32px", py: "20px" }}>
        <HeaderBlock title="All Products" onClose={onClose} />
        <SearchBlock
          handleSearch={handleSearch}
          searchInput={params.search}
          handleApplyFilter={handleApplyFilter}
          handleDeleteFilter={handleDeleteFilter}
          switches={switches}
          filterFields={filterFields}
          selectMatchData={selectMatchData}
          distributorId={cartData?.selectedDistributor?.id}
        />
        <HeaderListBlock />
        <ItemsListBlock
          productsState={productsState}
          handleFetchMore={() =>
            handleFetchProducts({
              page: params.page + 1,
              search: searchDebounced,
            })
          }
        />
      </Box>
      <DialogActions sx={{ p: "10px 32px 20px" }}>
        <StyledButton label="Cancel" onClick={onClose} />
        <StyledButton
          variant="contained"
          label="Add"
          onClick={() => {
            onClose();
            handleAddProducts();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

ViewAllProductsDialog.propTypes = { open: bool, onClose: func };
ViewAllProductsDialog.defaultProps = { open: false, onClose: () => {} };
