import { bool, func, object, string } from "prop-types";
import { Link } from "react-router-dom";
import { Controller } from "react-hook-form";

import {
  Box,
  Button,
  Typography,
  Link as MuiLink,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { StyledTextField } from "../../../components";
import { cl } from "../LoginPage.styles";
import { cl as regCl } from "../../RegistrationPage/RegistrationPage.styles";
import { INVITATION_PATH } from "utils/constants";
import { useMediaDevice } from "hooks/useMediaDevice";
import { ClosePinIcon } from "components/Icons";

export const LoginBody = ({
  control,
  onSubmit,
  setValue,
  isLoading,
  distributorName,
}) => {
  const { isMobile } = useMediaDevice();

  const BODY_FIELDS = [
    {
      sx: { mt: { xs: 0, sm: -2.6 } },
      name: "email",
      label: isMobile ? "" : "Email",
      placeholder: "Email",
      type: "text",
    },
    {
      sx: {},
      name: "password",
      label: isMobile ? "" : "Password",
      placeholder: "Password",
      type: "password",
    },
  ];

  return (
    <Box
      mb={{ xs: "48px", sm: 1 }}
      px={{ xs: 0, sm: "42.5px" }}
      display="flex"
      flexDirection="column"
      gap={{ xs: "16px", sm: "2vh" }}
      component="form"
      id="login-form"
      onSubmit={onSubmit}
    >
      {BODY_FIELDS.map(({ name, placeholder, ...itemProps }, index) => (
        <Controller
          key={index}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              inputSx={regCl.inputSx}
              labelSx={regCl.labelSx}
              size="small"
              error={error?.message || ""}
              {...(isMobile && {
                InputProps: {
                  sx: { ...cl.inputSx, ...(error && cl.errorInputSx) },
                  placeholder,
                  ...(field.value && {
                    endAdornment: (
                      <InputAdornment
                        sx={cl.inputIcon}
                        onClick={() => setValue(name, "")}
                        position="end"
                      >
                        <ClosePinIcon />
                      </InputAdornment>
                    ),
                  }),
                },
              })}
              {...itemProps}
              {...field}
            />
          )}
          name={name}
          control={control}
        />
      ))}

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box my={-2}>
          <Controller
            render={({ field }) => (
              <FormControlLabel
                label={
                  <Typography sx={cl.forgotPassText}>Remember me</Typography>
                }
                control={
                  <Checkbox
                    checked={!!field.value}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  />
                }
                {...field}
              />
            )}
            name="isRememberMe"
            control={control}
          />
        </Box>

        <Box my={-2}>
          <MuiLink
            sx={{ color: "#5F6267" }}
            underline="hover"
            component={Link}
            to={
              distributorName
                ? `/reset-password/${distributorName}`
                : "/reset-password"
            }
          >
            <Typography sx={cl.forgotPassText}>Forgot password?</Typography>
          </MuiLink>
        </Box>
      </Box>
      <Button
        disabled={isLoading}
        sx={{
          ...cl.loginBtn,
          mb: !distributorName && { xs: "8px", sm: "30px" },
        }}
        variant="contained"
        form="login-form"
        type="submit"
      >
        {isLoading ? "Loading" : "Sign In"}
      </Button>
      {distributorName && (
        <Button
          disabled={isLoading}
          sx={cl.createBtn}
          component={Link}
          to={`/${INVITATION_PATH}/${distributorName}`}
        >
          Create Account
        </Button>
      )}
    </Box>
  );
};

LoginBody.propTypes = {
  control: object,
  onSubmit: func,
  setValue: func,
  isLoading: bool,
  distributorName: string,
};
LoginBody.defaultProps = { isLoading: false, distributorName: "" };
