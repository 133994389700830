export const defaultValues = {
  email: "",
  name: "",
  phone: "",
  billingAddress: {
    formatted_address: "",
    lat: 0,
    lng: 0,
    appartement: "",
    street: "",
    city: "",
    state: "",
    zip: "",
  },
  shippingAddress: {
    formatted_address: "",
    lat: 0,
    lng: 0,
    appartement: "",
    street: "",
    city: "",
    state: "",
    zip: "",
  },
  federalTaxId: "",
  website: "",
  accountType: "",
  contacts: [],
  locations: [],
  profilePhoto: null,
};
