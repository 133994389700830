import { string } from "prop-types";
import { Box, Typography } from "@mui/material";
import { InformationIconGreen } from "components/Icons";
import { Link } from "react-router-dom";

export const LastPurchasedBlock = ({ createdAt, orderId }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          mt: "18px",
          width: "100%",
          height: "45px",
          border: "0.5px solid rgba(38, 135, 75, 0.4)",
          borderRadius: "4px",
          backgroundColor: "rgba(38, 135, 75, 0.1)",
        }}
      >
        <Box ml={2} justifyContent="normal" display="flex" alignItems="center">
          <InformationIconGreen />
          <Typography
            sx={{
              ml: 1,
              fontSize: "15px",
              fontWeight: 400,
              color: "#000000",
            }}
          >
            Last purchased {createdAt}
          </Typography>
        </Box>
        <Typography
          fontSize={15}
          sx={{ mr: 2, color: "#26874B", textDecoration: "none" }}
          component={Link}
          to={`/orders/${orderId}`}
          state={{ type: "orders_page" }}
        >
          View order
        </Typography>
      </Box>
    </Box>
  );
};

LastPurchasedBlock.propTypes = {
  createdAt: string,
  orderId: string,
};
LastPurchasedBlock.defaultProps = { createdAt: "", orderId: "" };
