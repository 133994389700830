import { useEffect, useCallback, useMemo } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./RegistrationPage.validations";

import { Box, Button, Paper, Stack } from "@mui/material";

import { LoginFooter, RegistrationBottomBlock } from "../../components";
import {
  RegistrationBody,
  RegistrationBreadCrumbsNav,
  RegistrationHeader,
} from "./components";
import { defaultValues } from "./RegistrationPage.constants";

import { cl } from "./RegistrationPage.styles";
import { cl as loginCl } from "../LoginPage/LoginPage.styles";
import { getDistributorInfoByStoreNameService } from "../../services/account";
import { error } from "helpers/notifications";
import { useSubmit } from "./useSubmit";
import { useCustomerData } from "./useCustomerData";
import { CANADA_PHONE_CODES, COUNTRY_PHONE_CODES } from "utils/constants";
import { useMediaDevice } from "hooks/useMediaDevice";
import { TermsFooter } from "pages/LoginPage/components";
import { WarningBox } from "components/AddCardDialog/components";
import { checkAndPrepareText, createQueryString } from "helpers/helpers";

export const RegistrationPage = () => {
  const { isMobile } = useMediaDevice();
  //const { state } = useLocation();
  const [searchParams] = useSearchParams();

  const { pathname } = useLocation();
  const path = useMemo(() => pathname.split("/")[2], [pathname]);

  const navigate = useNavigate();

  const stateFromParams = useMemo(
    () => Object.fromEntries([...searchParams]),
    [searchParams]
  );

  const stateQuery = useMemo(
    () => createQueryString(stateFromParams),
    [stateFromParams]
  );

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      regParams: stateFromParams,
    },
    resolver: yupResolver(validationSchema(isMobile)),
  });
  const formField = useWatch({ control });
  const { distributorData } = formField;

  const { loadingCustomerData } = useCustomerData({
    tokenParam: stateFromParams?.tokenParam || formField?.regParams?.tokenParam,
    reset,
    formField,
  });

  // eslint-disable-next-line no-console
  if (Object.values(errors).length) console.log(errors); //! temporarily solution SD-7456

  const handleDistributorInfoByStoreName = useCallback(
    async ({ storeName, onlyCountry }) => {
      try {
        const data = await getDistributorInfoByStoreNameService(storeName);
        if (!onlyCountry) setValue("distributorData", data);
        setValue("country", data.country || "US");
        setValue("loadingDistributorData", false);
      } catch (err) {
        error(err.message);
        // eslint-disable-next-line no-console
        console.error(err.message);
      }
    },
    [setValue]
  );

  const sessionStorageData = JSON.parse(sessionStorage.getItem("regData"));

  useEffect(() => {
    if (sessionStorageData) {
      reset({
        ...sessionStorageData,
        regParams: {
          ...sessionStorageData.regParams,
          tokenParam:
            stateFromParams?.tokenParam ||
            sessionStorageData?.regParams?.tokenParam,
        },
        countryPrefix:
          sessionStorageData.country === "CA"
            ? CANADA_PHONE_CODES[0]
            : COUNTRY_PHONE_CODES[0],
      });
    } else {
      reset({ ...defaultValues, regParams: { ...stateFromParams } });
    }

    return () => sessionStorage.removeItem("regData");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sessionStorage.setItem(
      "regData",
      JSON.stringify({ ...formField, password: "", confirmNewPassword: "" })
    );
  }, [formField]);

  useEffect(() => {
    if (stateFromParams?.distributorName) {
      handleDistributorInfoByStoreName({
        storeName: stateFromParams?.distributorName,
      });
    } else {
      setValue("loadingDistributorData", false);
    }
  }, [
    handleDistributorInfoByStoreName,
    setValue,
    stateFromParams?.distributorName,
  ]);

  useEffect(() => {
    if (!stateFromParams?.distributorName && stateFromParams?.storeParam) {
      handleDistributorInfoByStoreName({
        storeName: stateFromParams?.storeParam,
        onlyCountry: true,
      });
    }
  }, [
    handleDistributorInfoByStoreName,
    stateFromParams?.distributorName,
    stateFromParams?.storeParam,
  ]);

  useEffect(() => {
    if (stateFromParams?.stepParam) {
      if (stateFromParams?.stepParam === "registration" && path === "step-1") {
        setValue("step", 1);
      }
      if (stateFromParams?.stepParam === "code") setValue("step", 2.1);
      if (stateFromParams?.stepParam === "complete") setValue("step", 3);
      if (stateFromParams?.stepParam === "login") setValue("step", 3);
    } else if (sessionStorageData) {
      if (sessionStorageData?.step === 2) {
        navigate(`/registration/step-1${stateQuery}`);
        setValue("step", 1);
      }
    }
    if (stateFromParams?.emailParam)
      setValue("businessEmail", stateFromParams?.emailParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setValue,
    stateFromParams?.emailParam,
    stateFromParams?.stepParam,
    navigate,
  ]);

  const { onSubmit, errPhoneStatus, isLoading } = useSubmit({
    setError,
    distributorData,
    setValue,
    stateQuery,
  });

  const formatErrorObject = (errorObject, type) => {
    if (!errorObject || !Object.keys(errorObject).length) return [];
    const filteredErrorObject = { ...errorObject };
    if (filteredErrorObject.formatted_address) {
      delete filteredErrorObject.formatted_address;
      filteredErrorObject[type] = { message: `${type} field is required` };
    }
    return Object.entries(filteredErrorObject).map(([key, value]) => {
      return [key, value];
    });
  };

  const removeDuplicates = (arrays) => {
    const seen = new Map();
    return arrays.filter(([key]) => {
      if (seen.has(key)) return false;
      seen.set(key, true);
      return true;
    });
  };

  const handleErrorList = () => {
    if (!Object.keys(errors).length) return [];

    let errorsList = [
      ...Object.entries(errors),
      ...formatErrorObject(errors.billingAddress, "Billing Address"),
      ...formatErrorObject(errors.shippingAddress, "Shipping Address"),
    ];

    if (errors?.name?.type === "specialCharacter") {
      if (errorsList?.[0]?.[0] === "name") errorsList.shift();

      errorsList.push([
        "name",
        {
          ...errors?.name,
          message: `Invalid character used ${checkAndPrepareText(
            formField?.name
          )
            .map((e) => e)
            .join(", ")}`,
        },
      ]);
    }

    return errorsList;
  };
  const errorsList = handleErrorList();

  const showBackBtn = formField.step === 1 || formField.step === 2;

  const showWarningBox =
    !!errorsList.length && isMobile && formField.step !== 1;

  return (
    <Box sx={cl.wrapper}>
      <Box
        sx={{
          width: { xs: "100%", sm: "fit-content" },
          maxWidth: { xs: "400px", sm: "100%" },
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{ ...loginCl.paperWrapper, mb: 1 }}
          elevation={0}
          variant="outlined"
        >
          <RegistrationHeader control={control} />
          <RegistrationBreadCrumbsNav />
          {showWarningBox && (
            <WarningBox skipAddress errors={removeDuplicates(errorsList)} />
          )}
          <RegistrationBody
            control={control}
            onSubmit={handleSubmit(onSubmit)}
            setValue={setValue}
            errors={errors}
            isLoading={isLoading || loadingCustomerData}
            errPhoneStatus={errPhoneStatus}
            trigger={trigger}
            clearErrors={clearErrors}
          />
          {!isMobile && <RegistrationBottomBlock />}

          {isMobile && (
            <Stack gap="38px" mt="24px">
              {showBackBtn && (
                <Button
                  disabled={isLoading}
                  sx={cl.createBtn}
                  component={Link}
                  to={`/login/${formField.distributorData?.storeName}`}
                >
                  Back to Sign in
                </Button>
              )}

              {formField.step === 1 && <TermsFooter />}
            </Stack>
          )}
        </Paper>
      </Box>
      {!isMobile && (
        <Box
          sx={{
            width: "100%",
            pl: "3.5vw",
            pr: "calc(3.5vw - 10px)",
          }}
        >
          <LoginFooter />
        </Box>
      )}
    </Box>
  );
};
