import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  switcher: {
    width: ({ small }) => (small ? 39 : 70),
    height: ({ small }) => (small ? 19 : 32),
    padding: 0,
    marginLeft: 10,
    pointerEvents: "auto",
    "& .MuiSwitch-switchBase": {
      padding: 1,
      borderRadius: "12px",
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: ({ small }) =>
          small ? "translateX(20px)" : "translateX(38px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {},
      "&.Mui-disabled + .MuiSwitch-track": {},
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      borderRadius: "12px",
      width: ({ small }) => (small ? 17 : 30),
      height: ({ small }) => (small ? 17 : 30),
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#B5B5AC",
      opacity: 1,
    },
  },
}));

export default useStyles;
