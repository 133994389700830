import { Box, Typography } from "@mui/material";
import { MainLogoIcon } from "components/MainLogos";
import { PRIVACY_URL, TERMS_URL } from "pages/LoginPage/constants";

export const LoginFooter = () => {
  return (
    <Box
      component="footer"
      width="100%"
      display="flex"
      justifyContent="space-between"
      borderTop="1px solid #D5D9D9"
      alignItems="center"
      height="8vh"
    >
      <Box display="flex" alignItems="center" gap={1.2}>
        <Typography sx={{ paddingTop: "1px" }}>Powered by</Typography>
        <MainLogoIcon variant="primary" width={176} />
      </Box>
      <Box display="flex" gap="37px">
        <Typography variant="footer" component="a" href={TERMS_URL}>
          Terms of Service
        </Typography>
        <Typography variant="footer" component="a" href={PRIVACY_URL}>
          Privacy Policy
        </Typography>
      </Box>
    </Box>
  );
};
