import { any, bool, func, object, string } from "prop-types";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { cl } from "./styles";

export const MobileContactItem = ({
  title,
  firstLine,
  secondLine,
  onClick,
  showEdit,
  showAdd,
  loading,
  element,
  name,
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={cl.title}>{title}</Typography>
        {(showEdit || showAdd) && (
          <Button
            sx={{
              ...cl.editBtn,
              mb: showEdit ? "4px" : 0,
              justifyContent: "flex-end",
            }}
            onClick={onClick}
          >
            {showAdd && <AddIcon />}
            {!showAdd ? "Edit" : `Add ${name}`}
          </Button>
        )}
        {loading && <CircularProgress size={20} />}
      </Box>

      {firstLine ? <Box sx={cl.addressText}>{firstLine}</Box> : null}
      {secondLine ? <Box sx={cl.addressText}>{secondLine}</Box> : null}

      {!!element && element}
    </Box>
  );
};

MobileContactItem.propTypes = {
  title: string,
  firstLine: any,
  secondLine: any,
  onClick: func,
  name: string,
  loading: bool,
  showEdit: bool,
  showAdd: bool,
  element: object,
};
MobileContactItem.defaultProps = {
  title: "",
  name: "",
  firstLine: "",
  secondLine: "",
  onClick: () => {},
  loading: false,
};
