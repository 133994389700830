import {
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { CardIconComponent, StyledLabel } from "components";
import { DeleteIcon } from "components/Icons";
import { setCardName } from "helpers/helpers";
import { bool, func, object } from "prop-types";
import { cl } from "../SelectCardDrawer/styles";

export const PaymentCard = ({
  card,
  selectedCard,
  handleSelectCard,
  isCardExpired,
  handleDeleteCard,
  onClose = () => {},
  onEditOpen,
  hideAction = false,
  cardSx = {},
}) => {
  const { id, brand, billingAddress, expMonth, expYear, number, last4 } =
    card || {};

  const hasExpiresDateIsDecline = isCardExpired(expMonth, expYear);

  const isSelected = selectedCard?.id === id;

  return (
    <Stack
      sx={{
        ...cl.cardWrapper,
        background: isSelected ? "rgba(64, 154, 101, 0.05)" : "#ffff",
        ...cardSx,
      }}
    >
      {hideAction ? (
        <Stack gap="8px" direction="row" alignItems="center">
          <CardIconComponent
            type={card?.brand === "visa" ? "mobile-visa" : card?.brand}
            size={32}
          />

          <Stack>
            <Stack direction="row" gap="8px">
              <Typography sx={cl.cardNumber}>
                {setCardName(brand)} ending in {number?.slice(-4) || last4}
              </Typography>
            </Stack>
            <Typography sx={cl.cardName}>
              {billingAddress?.name} | Expires {`${expMonth}/${expYear}`}
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <FormControlLabel
          sx={{
            width: "100%",
            mr: 0,
          }}
          checked={selectedCard?.id === card.id}
          value={card?.id}
          control={
            <Radio
              onChange={() => handleSelectCard(card.id)}
              sx={{
                "& svg": {
                  width: "20px",
                  height: "20px",
                },
              }}
            />
          }
          disabled={hasExpiresDateIsDecline}
          label={
            <Stack gap="8px" direction="row" alignItems="center">
              <CardIconComponent
                type={card?.brand === "visa" ? "mobile-visa" : card?.brand}
                size={32}
              />

              <Stack>
                <Stack direction="row" gap="8px">
                  <Typography sx={cl.cardNumber}>
                    {setCardName(brand)} ending in {number?.slice(-4) || last4}
                  </Typography>
                  {hasExpiresDateIsDecline ? (
                    <StyledLabel text="expired" style={{ pb: "2px" }} />
                  ) : (
                    card?.defaultMethod && <StyledLabel style={{ pb: "4px" }} />
                  )}
                </Stack>
                <Typography sx={cl.cardName}>
                  {billingAddress?.name} | Expires {`${expMonth}/${expYear}`}
                </Typography>
              </Stack>
            </Stack>
          }
        />
      )}

      {!hideAction && !hasExpiresDateIsDecline && (
        <Stack sx={cl.cardActions}>
          <Button sx={cl.editBtn} onClick={() => onEditOpen(card)}>
            Edit
          </Button>
          {!card?.defaultMethod && (
            <IconButton
              sx={cl.deleteIcon}
              onClick={() => {
                onClose();
                handleDeleteCard(card);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Stack>
      )}
    </Stack>
  );
};

PaymentCard.propTypes = {
  card: object,
  hideAction: bool,
  selectedCard: object,
  cardSx: object,
  handleSelectCard: func,
  handleDeleteCard: func,
  onEditOpen: func,
  isCardExpired: func,
  onClose: func,
};
