import { makeStyles } from "@mui/styles";

export const cl = {
  cardWrapper: {
    borderRadius: "8px",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    boxShadow: "none",
    height: "100%",
    cursor: "pointer",
    "& .MuiCardMedia-root": {
      borderBottom: "none",
    },
  },

  cardContent: {
    padding: "8px",
    display: "flex",
    width: "100%",
    height: "calc(100% - 167px)",
    gap: "8px",
    flexDirection: "column",
    "&:last-child": { padding: "8px" },
  },

  actionWrapper: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
  },

  priceText: {
    color: "black",
    fontSize: "12px",
    fontWeight: 600,
    wordWrap: "break-word",
  },

  retailPrice: {
    color: "rgba(0, 0, 0, 0.40)",
    fontSize: "8px",
    fontWeight: 400,
    wordWrap: "break-word",
  },

  addToCartBtn: {
    height: "22px",
    padding: "5px 9px",
    borderRadius: "8px",
    minWidth: "72px",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    boxShadow: "none !important",
    gap: "3px",
  },

  productName: {
    color: "black",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: 1.3,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    wordWrap: "break-word",
    width: "100%",
  },

  productNameWrapper: {
    "& .MuiTypography-root": {
      color: "rgba(0, 0, 0, 0.80)",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: 1.3,
    },
  },

  skuSpan: {
    color: "rgba(0, 0, 0, 0.30)",
    fontSize: "10px",
    fontWeight: 400,

    "& span": { mr: "4px", wordBreak: "break-word" },
  },

  productDescription: {
    width: "100%",
    color: "rgba(0, 0, 0, 0.80)",
    fontSize: "8px",
    fontWeight: 400,
    lineHeight: "12px",
    wordWrap: "break-word",

    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
  },

  descriptionWrapper: {
    "& .MuiTypography-root": {
      color: "rgba(0, 0, 0, 0.80)",
      fontSize: "8px",
      fontWeight: 400,
      lineHeight: "12px",
    },
  },

  paperStyles: {
    maxHeight: "260px",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.10)",
    "& li": {
      padding: "5px 11px",
      minHeight: "16px",
      color: "black",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "16.20px",
      wordWrap: "break-word",
    },
    borderRadius: "8px",
    marginTop: "6px",
    "& ul": {
      padding: "5px 0",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: "7px",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "7px",
      backgroundColor: "rgba(0, 0, 0, 0.35)",
    },
  },

  select: {
    "& svg": {
      right: 0,
      fill: "#2F3040",
      width: "27px",
      mr: "3px",
      position: "absolute",
      transform: "scale(1)",
      top: "calc(50% - 11px) !important",
      marginTop: "3px",
    },
    fontWeight: 500,
    color: "#2F3040",
    backgroundColor: "#ffff",
    borderRadius: "8px",
    "& > .MuiSelect-select": {
      backgroundColor: "#ffff",
      padding: "0px 32px 0px 10px !important",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px !important",
    },
  },

  selectInput: {
    "& .MuiOutlinedInput-root": {
      pr: "4px",

      "&.Mui-focused fieldset": {
        borderWidth: "1px",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 10px",
      border: "none",
      height: "22px",
      fontSize: "10px",
      fontWeight: 500,
      color: "#2F3040",
      backgroundColor: "#ffff",
    },

    "& .MuiOutlinedInput-root fieldset": {
      borderRadius: "8px",
    },
  },

  icon: {
    color: "#2F3040",
    position: "absolute",
  },

  addedProductBtn: {
    boxShadow: "none !important",
  },

  outOfStockText: {
    p: "3px 10px",
    height: "22px",
    background: "white",
    borderRadius: "8px",
    border: "1px #FF0000 solid",
    color: "#FF0000",
    fontSize: 8,
    fontFamily: "Inter",
    fontWeight: "500",
    textTransform: "capitalize",
    justifyContent: "center",
    lineHeight: "10.8px",
    wordWrap: "break-word",
    mb: "2px",
  },

  alreadyChangedProductBtn: {
    backgroundColor: "#409A651A !important",
    color: "#409A65 !important",
  },

  disabledActionBtn: {
    backgroundColor: "rgba(0, 0, 0, 0.12) !important",
    color: "rgba(0, 0, 0, 0.26) !important",

    "& path": {
      stroke: "rgba(0, 0, 0, 0.26) !important",
    },
  },
};

export const useStyles = makeStyles(() => ({
  cartBtn: {
    fontSize: "10px",
    backgroundColor: ({ isAdded }) => (isAdded ? "#FAFAFA" : "#409A65"),
    color: ({ isAdded }) => (isAdded ? "#646464" : "white"),
    fontWeight: ({ isAdded }) => (isAdded ? 400 : 500),

    "&:hover": {
      backgroundColor: ({ isAdded }) => (isAdded ? "#FAFAFA" : "#409A65"),
    },
  },
}));
