import { PRIVACY_URL, TERMS_URL } from "../constants";

import { cl } from "../LoginPage.styles";

import { Typography } from "@mui/material";

export const TermsFooter = () => {
  return (
    <Typography sx={cl.termsText}>
      By continuing, you agree to the <a href={TERMS_URL}>Terms of Service</a>{" "}
      and <br />
      <a href={PRIVACY_URL}>Privacy Policy</a>
    </Typography>
  );
};
