export const isLoadingDistributorsSelector = (state) =>
  state.distributors.isLoading;
export const distributorsListSelector = (state) =>
  state.distributors.distributorsList;
export const distributorsCountSelector = (state) =>
  state.distributors.distributorsCount;
export const distributorLogoSelector = (state) =>
  state.distributors.distributorLogo;
export const distributorGetParamsSelector = (state) =>
  state.distributors.distributorGetParams;

export const currentDistributorStoreDataSelector = (state) =>
  state.distributors.currentStoreData;

export const selectedSupplierSelector = (state) =>
  state.distributors.selectedSupplier;
