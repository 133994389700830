import { string } from "prop-types";
import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { StyledTooltip } from "components";

export const SubtitleBlock = ({ manufacturerName, distributorName }) => {
  const brandNameRef = useRef();
  const supplierNameRef = useRef();

  const [isBrandNameCropped, setBrandNameCropped] = useState(null);
  const [isSupplierNameCropped, setSupplierNameCropped] = useState(null);

  const isEllipsisActive = (e) => {
    if (!e) return;
    return e.offsetWidth < e.scrollWidth;
  };

  useEffect(() => {
    if (brandNameRef?.current) {
      setBrandNameCropped(isEllipsisActive(brandNameRef?.current));
    }
    if (supplierNameRef?.current) {
      setSupplierNameCropped(isEllipsisActive(supplierNameRef?.current));
    }
  }, [isBrandNameCropped, isSupplierNameCropped]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mt: "20px",
      }}
    >
      <Box display="flex" alignItems="center" width="45%">
        <Typography fontSize={20} fontWeight={300} color="#989898">
          Brand:
        </Typography>
        <StyledTooltip
          title={manufacturerName}
          placement="top"
          arrow
          disableHoverListener={!isBrandNameCropped}
        >
          <Typography
            ref={brandNameRef}
            fontSize={20}
            fontWeight={400}
            color="#000000"
            ml={1}
            noWrap
          >
            {manufacturerName}
          </Typography>
        </StyledTooltip>
      </Box>

      <Box width="55%" display="flex" justifyContent="flex-end">
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 300,
            color: "#989898",
          }}
        >
          Supplier:
        </Typography>

        <StyledTooltip
          title={distributorName}
          placement="top"
          arrow
          disableHoverListener={!isSupplierNameCropped}
        >
          <Typography
            ref={supplierNameRef}
            sx={{
              fontSize: "20px",
              fontWeight: 400,
              color: "#000000",
              ml: 1,
            }}
            noWrap
          >
            {distributorName}
          </Typography>
        </StyledTooltip>
      </Box>
    </Box>
  );
};

SubtitleBlock.propTypes = {
  manufacturerName: string,
  distributorName: string,
};
SubtitleBlock.defaultProps = {
  manufacturerName: "",
  distributorName: "",
};
