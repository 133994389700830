import { array, func, object } from "prop-types";

import { Button, Stack, Typography } from "@mui/material";
import { AttachmentIcon } from "components/Icons";

export const FileInput = ({ inputRef, onAttach, attachments }) => {
  return (
    <Stack
      direction={attachments.length ? "column" : "row"}
      alignItems="center"
      gap="8px"
    >
      <Button
        variant="outlined"
        startIcon={<AttachmentIcon />}
        color="grey"
        onClick={() => {
          inputRef.current.click();
        }}
        sx={{
          borderColor: "rgba(0, 0, 0, 0.10) !important",
          padding: "8px",
          borderRadius: "8px",
          fontSize: "10px",
          color: "#565656A3",
          height: "28px",
          width: "86px",
          minWidth: "86px",
          span: {
            mr: "4px",
            ml: 0,
          },
        }}
      >
        Attach File
        <input
          ref={inputRef}
          type="file"
          hidden
          onChange={onAttach}
          accept={"*"}
          multiple
        />
      </Button>
      <Typography fontSize="12px" color="#00000066" whiteSpace="nowrap">
        Max. 10 MB
      </Typography>
    </Stack>
  );
};

FileInput.propTypes = {
  inputRef: object,
  onAttach: func,
  attachments: array,
};
