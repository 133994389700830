import {} from "react";
import { string } from "prop-types";
import { Box, Typography } from "@mui/material";

export const Title = ({ name, orderNumber }) => {
  return (
    <Box
      sx={{
        border: "1px solid #D5D9D9",
        borderWidth: "1px 1px 0 1px",
        borderRadius: "4px 4px 0 0 ",
        height: "68px",
        display: "flex",
        alignItems: "center",
        pl: 2,
      }}
    >
      <Typography
        fontSize={24}
        color="clearBlack"
      >{`${name} #${orderNumber}`}</Typography>
    </Box>
  );
};

Title.propTypes = {
  name: string.isRequired,
  orderNumber: string.isRequired,
};
