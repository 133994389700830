import React from "react";
/* eslint-disable react/prop-types */
export const SendEmailIcon = () => {
  return (
    <svg
      width="189"
      height="189"
      viewBox="0 0 189 189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="97.349" cy="100.202" r="58.816" fill="#ECF6EF" />
      <circle cx="42.219" cy="60.489" r="3.686" fill="#ECF6EF" />
      <circle cx="68.862" cy="36.862" r="6.87" fill="#ECF6EF" />
      <circle cx="43.392" cy="138.073" r="6.535" fill="#ECF6EF" />
      <circle cx="160.354" cy="131.705" r="6.535" fill="#ECF6EF" />
      <circle cx="147.619" cy="139.078" r="3.184" fill="#ECF6EF" />
      <path
        d="M96.017 48.604c-.195-.041-.195-.318 0-.36l2.937-.624a.18.18 0 0 0 .142-.141l.632-2.917c.042-.194.318-.194.36 0l.633 2.917c.015.07.07.126.141.14l2.938.625c.194.042.194.319 0 .36l-2.938.625a.18.18 0 0 0-.141.14l-.633 2.918c-.042.193-.318.193-.36 0l-.632-2.917a.18.18 0 0 0-.142-.141zm-67.799 28.85c.075-.354.58-.354.656 0l1.12 5.293c.027.129.128.23.257.258l5.238 1.141c.351.077.351.578 0 .655l-5.238 1.141a.34.34 0 0 0-.257.258l-1.12 5.293c-.075.354-.58.354-.656 0l-1.12-5.293a.34.34 0 0 0-.257-.258l-5.238-1.141c-.351-.077-.352-.578 0-.655l5.238-1.141a.34.34 0 0 0 .257-.258zm121.695 16.757c.075-.354.58-.354.655 0l.654 3.088c.027.129.128.23.257.258l3.064.667c.352.077.352.578 0 .655l-3.064.668a.34.34 0 0 0-.257.258l-.654 3.087c-.075.355-.58.355-.655 0l-.654-3.087a.34.34 0 0 0-.256-.258l-3.065-.668c-.352-.077-.352-.578 0-.655l3.065-.667a.34.34 0 0 0 .256-.258z"
        fill="#8ECEA3"
      />
      <path
        d="m163.423 111.54 1.026 4.849 4.78 1.041-4.78 1.041-1.026 4.849-1.026-4.849-4.779-1.041 4.779-1.041z"
        fill="#fff"
      />
      <path
        d="M141.922 52.446 71.711 126.51l8.043 31.67 5.53-26.643z"
        fill="#BDE4CB"
      />
      <path
        d="m71.376 126.175 8.21 32.676 5.195-27.146m-5.362 26.476 21.113-19.941"
        stroke="#8ECEA3"
        strokeWidth="1.341"
      />
      <path
        d="M141.922 52.446 85.116 131.37l44.573 19.94zm0-.67L36.857 111.429l34.519 14.746z"
        fill="#ECF6EF"
        stroke="#8ECEA3"
        strokeWidth="1.341"
      />
      <path d="m101.538 138.917-16.185-7.044-5.934 27.146z" fill="#8ECEA3" />
    </svg>
  );
};
