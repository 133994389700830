import moment from "moment";
import { useContext } from "react";

import { FulfillmentStatus, PaymentStatuses } from "components";

import { getFormattedDate } from "helpers/helpers";
import { OrderContext } from "pages/ViewOrderPage/ViewOrderPage.hooks";

import { cl } from "../../styles";

import { Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { DRAWERS } from "constants/drawer";

export const HeaderBlock = () => {
  const dispatch = useDispatch();
  const { orderData, currentUser } = useContext(OrderContext);

  const {
    createdAt,
    distributor,
    paymentStatus,
    totalDelivered,
    totalQuantity,
    deliveryStatus,
  } = orderData?.order || {};

  const orderDate = moment(
    getFormattedDate(createdAt, currentUser.timeZone)
  ).format("MMM D, YY [at] h:mm a");

  const onOpenPaymentDrawer = () => {
    if (!["PENDING", "UNPAID"].includes(paymentStatus)) {
      dispatch(
        showCurrentDrawerAction({
          type: DRAWERS.VIEW_PAYMENT_INFO_DRAWER,
          show: true,
        })
      );
    }
  };

  const onOpenFulfillmentDrawer = () => {
    if (deliveryStatus !== "UNFULFILLED") {
      dispatch(
        showCurrentDrawerAction({
          type: DRAWERS.VIEW_FULFILLMENT_DRAWER,
          show: true,
        })
      );
    }
  };

  return (
    <Stack sx={cl.headerBlock}>
      <Stack direction="row" gap="4px">
        <Typography sx={{ ...cl.headerBlockText, whiteSpace: "nowrap" }}>
          {orderDate} •
        </Typography>
        <Typography sx={cl.headerBlockText} noWrap>
          Supplier: {distributor?.name}
        </Typography>
      </Stack>
      <Stack direction="row" gap="8px" alignItems="center">
        <PaymentStatuses str={paymentStatus} onClick={onOpenPaymentDrawer} />
        <FulfillmentStatus
          {...{
            onClick: onOpenFulfillmentDrawer,
            deliveryStatus,
            fulfilled: `${totalDelivered} of ${totalQuantity}`,
          }}
        />
      </Stack>
    </Stack>
  );
};
