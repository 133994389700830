import { useCallback, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { MainLogoIcon } from "components";
import { getDistributorInfoByStoreNameService } from "services/account";
import { setStoreData } from "redux/actions/distributors";
import { photoUrl } from "helpers/helpers";
import { error } from "helpers/notifications";
import { useNavigate } from "react-router-dom";
import { useMediaDevice } from "hooks/useMediaDevice";

export const useLogo = ({ distributor_name }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useMediaDevice();

  const [loadingImg, setLoadingImg] = useState(true);
  const [logo, setLogo] = useState(null);

  const handleSetLogo = useCallback(
    (url) => {
      if (url) {
        const img = new Image();
        img.src = photoUrl(url);
        img.onload = () => setLoadingImg(false);
        return setLogo(
          <Box
            sx={{
              width: "100%",
              height: { xs: "60px", sm: "100px" },
              mb: { xs: 0, sm: 2 },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              component="img"
              src={img.src}
              alt="Logo"
            />
          </Box>
        );
      }

      setLoadingImg(false);
      setLogo(<MainLogoIcon variant="primary" width={isMobile ? 109 : 242} />);
    },
    [isMobile]
  );

  const handleDistributorInfoByStoreName = useCallback(
    async (storeName) => {
      try {
        const data = await getDistributorInfoByStoreNameService(storeName);

        // if storefront logo didn't upload, redirect to the 404 page
        if (!data?.brandLogo) return navigate("*");

        dispatch(setStoreData(data));
        // setDistributor(data);
        handleSetLogo(data?.brandLogo?.fileName);
      } catch (err) {
        error(err.message);
        // eslint-disable-next-line no-console
        console.error(err.message);
      }
    },
    [dispatch, handleSetLogo, navigate]
  );

  useLayoutEffect(() => {
    if (distributor_name) {
      handleDistributorInfoByStoreName(distributor_name);
    } else {
      handleSetLogo();
    }
  }, [distributor_name, handleDistributorInfoByStoreName, handleSetLogo]);

  return { logo, loadingImg };
};
