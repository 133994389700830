import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { getOrdersService } from "services/orders";
import { error } from "helpers/notifications";
import { getRepsLite } from "services/reps";
import { useMediaDevice } from "hooks/useMediaDevice";

export const useOrders = ({
  currentUser,
  currentOrderTab,
  debouncedSearch,
  checkedSuppliers,
  fulfillmentStatus,
  paymentStatus,
}) => {
  const { timeZone, orderDirect } = currentUser || {};
  const { isMobile } = useMediaDevice();
  const [ordersState, setOrdersState] = useState({
    loading: false,
    list: [],
    count: 0,
    timeZone: timeZone,
    page: 1,
  });

  const [switches, setSwitches] = useState([
    {
      value: "date",
      label: "Date",
      type: "switch",
      checked: false,
      values: [
        { value: "last_30_days", name: "Last 30 days" },
        { value: "last_3_months", name: "Last 3 months" },
      ],
    },
    {
      value: "sales_id",
      label: "Created by",
      type: "select",
      checked: false,
      selectLabel: "Select Created by",
    },
  ]);

  const [filterFields, setFilterFields] = useState({
    date: "",
  });

  const handleApplyFilter = (newSwitches, newFields) => {
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  const handleDeleteFilter = (key) => {
    const newState = switches;
    const index = switches.findIndex((s) => s.value === key);
    const insert = {
      ...switches[index],
      checked: false,
    };
    newState.splice(index, 1, insert);
    setSwitches([...newState]);
    setFilterFields((prev) => {
      return { ...prev, [key]: "" };
    });
  };

  const setDate = (date) => {
    if (date === "last_30_days" || date === "last_3_months") {
      return {
        end_date: moment().format("YYYY-MM-DD") + "T00:00:00",
        start_date:
          moment()
            .subtract(date === "last_30_days" ? 30 : 90, "days")
            .format("YYYY-MM-DD") + "T00:00:00",
      };
    }

    return { start_date: null, end_date: null };
  };

  const { start_date, end_date } = setDate(filterFields?.date?.value);

  const params = useMemo(() => {
    const distributorArray = orderDirect.flatMap(
      (item) => item?.distributor?.id
    );

    const matchingDistrib = distributorArray?.includes(
      filterFields?.sales_id?.value
    )
      ? [filterFields?.sales_id?.value]
      : [];

    const preparedData = {
      search: debouncedSearch,
      distributor_ids:
        matchingDistrib?.length > 0
          ? JSON.stringify(matchingDistrib)
          : checkedSuppliers,
      delivery_status: filterFields.fulfillmentStatus
        ? JSON.stringify([filterFields.fulfillmentStatus])
        : fulfillmentStatus,
      payment_status: filterFields.paymentStatus
        ? JSON.stringify([filterFields.paymentStatus])
        : paymentStatus,
      sort_by_order_date: "desc",
      start_date,
      end_date,
    };

    if (filterFields?.sales_id && !matchingDistrib?.length) {
      if (
        ["distributor", "order_direct"].includes(filterFields?.sales_id?.value)
      ) {
        preparedData.created_by = filterFields.sales_id.value;
      } else {
        preparedData.created_by = "sales";
        preparedData.sales_id = filterFields.sales_id.value;
      }
    }

    distributorArray?.some((element) => {
      if (element === filterFields?.sales_id?.value) {
        preparedData.created_by = "distributor";
      }
    });

    return preparedData;
  }, [
    orderDirect,
    filterFields,
    debouncedSearch,
    checkedSuppliers,
    fulfillmentStatus,
    paymentStatus,
    start_date,
    end_date,
  ]);

  const [sales, setSales] = useState({
    loading: true,
    list: [],
    count: 0,
  });

  const distributorList = orderDirect.map((order) => order.distributor);

  const handleFetchReps = useCallback(async () => {
    const fetchQuery = {
      limit: 500,
      role: "sales",
      active: true,
      sort_name: "asc",
    };

    try {
      const res = await getRepsLite(fetchQuery);
      setSales((prev) => ({
        ...prev,
        list: prev?.list?.length ? [...prev.list, ...res.rows] : res?.rows,
        count: res?.countSales,
      }));
    } catch (err) {
      if (err.response?.data?.message) error(err.response.data.message);
      // eslint-disable-next-line no-console
      console.error(err.response?.data?.message || err.message);
    } finally {
      setSales((prev) => ({ ...prev, loading: false }));
    }
  }, []);

  const updatedChildrenList = useMemo(() => {
    const addOptionsToTheFirstItem = (list) => {
      if (list.length > 0) {
        return list.map((item, i) =>
          i === 0 ? { ...item, options: { showBeforeDivider: true } } : item
        );
      }

      return list;
    };

    const sorterDistributorList = distributorList?.sort((a, b) => {
      const aName = a?.name?.trim()?.toLowerCase();
      const bName = b?.name?.trim()?.toLowerCase();
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });

    const sortedList = [
      ...addOptionsToTheFirstItem(sorterDistributorList),
      ...addOptionsToTheFirstItem(sales.list),
    ];

    return [{ name: "You", id: "order_direct" }, ...sortedList];
  }, [distributorList, sales.list]);

  const selectMatchData = useMemo(() => {
    //if (orderDirect.length === 1) {
    //  updatedChildrenList.push({ name: "Supplier", id: "distributor" });
    //}

    return [
      {
        id: "sales_id",
        label: "Select Manufacturer",
        childrenList: updatedChildrenList,
        handleFetch: handleFetchReps,
        dataCount: sales.count,
        dataLength: sales.list.length,
      },
    ];
  }, [updatedChildrenList, handleFetchReps, sales.count, sales.list.length]);

  useEffect(() => {
    handleFetchReps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOrdersState((prev) => ({ ...prev, page: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, debouncedSearch]);

  const handleFetchOrders = useCallback(
    async (page) => {
      setOrdersState((prev) => ({ ...prev, loading: true }));
      try {
        const currentPage = page || ordersState.page;
        const { rows, count } = await getOrdersService({
          ...params,
          type: "DIRECT",
          ...(isMobile && { page: currentPage, limit: 20 }),
        });
        const list = rows ? rows : [];
        setOrdersState((prev) => ({
          ...prev,
          list: currentPage !== 1 ? [...prev.list, ...list] : list,
          count,
        }));
      } catch (err) {
        if (err.response?.data?.message) error(err.response.data.message);
        // eslint-disable-next-line no-console
        console.error(err.response?.data?.message || err.message);
      } finally {
        setOrdersState((prev) => ({ ...prev, loading: false }));
      }
    },
    [isMobile, ordersState.page, params]
  );

  useEffect(() => {
    handleFetchOrders();
  }, [
    currentOrderTab,
    debouncedSearch,
    checkedSuppliers,
    fulfillmentStatus,
    paymentStatus,
    handleFetchOrders,
  ]);

  const handleFetchMore = () => {
    setOrdersState((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  return {
    ordersState,
    switches,
    filterFields,
    handleApplyFilter,
    handleDeleteFilter,
    selectMatchData,
    handleFetchMore,
    updatedChildrenList,
    setFilterFields,
  };
};
