import { func, number, string } from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { separateNumWithComma } from "helpers/helpers";

export const TitleBlock = ({
  productName,
  setContactDialogOpen,
  minimumOrderValueAmount,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography fontSize={30} fontWeight={500} color="#000000" noWrap>
        {productName}
      </Typography>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "normal" }}
      >
        <Box
          sx={{
            border: "1px solid #DBE2ED",
            borderRadius: "8px",
            fontSize: 14,
            fontWeight: 300,
            color: "#989898",
            height: "32px",
            minWidth: "124px",
            px: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          component={Typography}
          noWrap
        >
          {minimumOrderValueAmount
            ? `Minimum $${separateNumWithComma(minimumOrderValueAmount)}`
            : "No minimum"}
        </Box>
        {/* temporarily hidden SD-4451 */}
        {/* <Button
          sx={{
            width: 146,
            height: 32,
            fontSize: 14,
            fontWeight: 400,
            color: "#000000",
            borderColor: "#DBE2ED",
            borderRadius: "8px",
          }}
          variant="outlined"
        >
          Request Samples
        </Button> */}
        <Button
          sx={{
            ml: 2,
            width: 137,
            height: 32,
            fontSize: 14,
            fontWeight: 400,
            borderColor: "#DBE2ED",
            borderRadius: "8px",
            paddingLeft: 0,
            paddingRight: 0,
          }}
          variant="contained"
          onClick={setContactDialogOpen}
        >
          Contact Supplier
        </Button>
      </Box>
    </Box>
  );
};

TitleBlock.propTypes = {
  productName: string,
  setContactDialogOpen: func,
  minimumOrderValueAmount: number,
};
TitleBlock.defaultProps = {
  productName: "",
  setContactDialogOpen: () => {},
  minimumOrderValueAmount: 0,
};
