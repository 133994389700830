import { object, string } from "prop-types";

import { Typography } from "@mui/material";

export const TabTitle = ({ title, titleSx = {} }) => {
  return (
    <Typography
      sx={{
        color: "black",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "18.9px",
        ...titleSx,
      }}
    >
      {title}
    </Typography>
  );
};

TabTitle.propTypes = {
  title: string,
  titleSx: object,
};
