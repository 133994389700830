import { Box } from "@mui/material";
import { CartOrderItem } from "./components";
import { useContext, useEffect } from "react";
import { CartContext } from "pages/CartPage/CartPage";

export const OrdersListBlock = () => {
  const {
    orderDirect,
    handleSelectDistributor,
    cartData,
    products,
    setValue,
    updateCartData,
  } = useContext(CartContext);

  const onlyApproved = ({ status }) => status === "APPROVED";

  useEffect(() => {
    if (!cartData.selectedDistributor && !!orderDirect.length) {
      const filtredDistributor = orderDirect.filter(onlyApproved);
      if (filtredDistributor.length) {
        handleSelectDistributor(filtredDistributor[0].distributor);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData.selectedDistributor]);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {orderDirect.filter(onlyApproved).map(({ distributor }) => (
        <CartOrderItem
          key={distributor.id}
          distributor={distributor}
          onChange={() => {
            if (distributor.id === cartData.selectedDistributor?.id) return;
            setValue("products", []);
            updateCartData({ isAlreadyReseted: false });
            handleSelectDistributor(distributor);
          }}
          expanded={distributor.id === cartData.selectedDistributor?.id}
          products={products}
        />
      ))}
    </Box>
  );
};
