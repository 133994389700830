import { func, string } from "prop-types";

import { useStyles } from "../styles";

import { Box, Button, Stack, Typography } from "@mui/material";

export const NoteHeader = ({
  handleClearAction,
  handleSaveNote,
  noteText,
  fieldValue,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.headerWrapper}>
      <Typography className={classes.title}>Note</Typography>

      <Stack direction="row">
        {!!noteText && (
          <Button
            variant="outlined"
            className={classes.actionBtn}
            sx={{ color: "rgba(0, 0, 0, 0.50)" }}
            onClick={handleClearAction}
          >
            Clear
          </Button>
        )}
        <Button
          variant="outlined"
          disabled={!noteText && !fieldValue}
          className={classes.actionBtn}
          onClick={handleSaveNote}
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
};

NoteHeader.propTypes = {
  noteText: string,
  fieldValue: string,
  handleSaveNote: func,
  handleClearAction: func,
};
NoteHeader.defaultProps = {
  noteText: "",
  fieldValue: "",
  handleSaveNote: () => {},
  handleClearAction: () => {},
};
