import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { string } from "prop-types";

const SectionHeader = ({ title }) => {
  return (
    <Box
      backgroundColor="#EEEEEE"
      borderRadius="4px"
      border="1px solid #D5D9D9"
      height="39px"
      display="flex"
      alignItems="center"
      pl="17px"
    >
      <Typography fontSize="20px" color="#000">
        {title}
      </Typography>
    </Box>
  );
};

SectionHeader.propTypes = {
  title: string,
};

export default SectionHeader;
