import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 10px 12px 31px",
  },
  dialogContent: {
    minWidth: "388px",
    padding: "0px 42px 7px 42px",
    maxHeight: "164px",
    overflow: "auto",
  },

  titleWrapper: {
    display: "inline-flex",
    gap: "11px",
    alignItems: "center",
  },

  dialogActions: {
    display: "flex",
    flexDirection: "column",
    gap: "13px",
    padding: "0 42px 24px",
    "&>:not(:first-of-type)": {
      marginLeft: 0,
    },
  },

  actionButton: {
    width: "100%",
    height: "34px",
  },

  customerItemWrapper: {
    padding: "9px 0",
    "&:not(:last-of-type)": {
      borderBottom: "1px solid #d5d9d9",
    },
  },

  customerName: {
    display: "inline-flex",
    gap: "6px",
    alignItems: "center",
  },

  customerTitle: {
    fontSize: "14px",
    color: "#000000",
  },
}));

export default useStyles;
