/* eslint-disable no-useless-escape */
import {
  LOWERCASE_REG_EXP,
  MIN_PASSWORD_LENGTH,
  NUMBER_REG_EXP,
  SYMBOL_REG_EXP,
  UPPERCASE_REG_EXP,
} from "components/PasswordCriteria/PasswordCriteria.constants";
import * as Yup from "yup";

const codeValidation = (value, ctx) => {
  if (!value) return true;
  const NUMBERS_REGEXP = /^[0-9]*$/;
  if (value?.toString()?.length !== 6)
    return ctx.createError({
      message: "Code is not correct - should be 6 chars.",
    });
  if (!NUMBERS_REGEXP.test(value))
    return ctx.createError({
      message: "Wrong code",
    });
  return true;
};

const businessPhoneValidation = (value, ctx, isMobile = false) => {
  const PHONE_REGEXP = isMobile
    ? /^(?:\+?1[-. ]?)?(?:\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4}))$/
    : /^[\+(|[0-9][0-9 \-\\.)]{9,14}$/im;
  if (!PHONE_REGEXP.test(value))
    return ctx.createError({
      message: "Not valid phone number",
    });
  return true;
};

const businessEmailValidation = (value, ctx) => {
  const EMAIL_REGEXP =
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))\.)+(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))$/i; // eslint-disable-line
  if (!EMAIL_REGEXP.test(value))
    return ctx.createError({
      message: "Not valid email",
    });
  return true;
};

export const validationSchema = (isMobile) =>
  Yup.object().shape({
    // step 1
    // email: Yup.string()
    //   .trim()
    //   .test("emailValidation", "Email not valid!", (value) => {
    //     if (value === "") return true;
    //     const EMAIL_REGEXP =
    //       /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))\.)+(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))$/i; // eslint-disable-line
    //     return EMAIL_REGEXP.test(value);
    //   }),
    businessEmail: Yup.string()
      .trim()
      .when("step", {
        is: 1,
        then: Yup.string()
          .trim()
          .required("The Business email field is required")
          .test({
            name: "emailValidation",
            test: (value, ctx) => businessEmailValidation(value, ctx),
          }),
      }),
    password: Yup.string()
      .trim()
      .when("step", {
        is: 1,
        then: Yup.string()
          .trim()
          .min(
            MIN_PASSWORD_LENGTH,
            `Password is too short - should be ${MIN_PASSWORD_LENGTH} chars minimum.`
          )
          .matches(
            LOWERCASE_REG_EXP,
            "Password must have at least one lowercase char"
          )
          .matches(
            UPPERCASE_REG_EXP,
            "Password must have at least one uppercase char"
          )
          .matches(NUMBER_REG_EXP, "Password must have at least one digit")
          .matches(
            SYMBOL_REG_EXP,
            "Password must have at least one special char"
          )
          .required("This field is required"),
        // .test({
        //   name: "passwordValidation",
        //   test: (value, ctx) => passwordValidation(value, ctx),
        // }),
      }),
    confirmNewPassword: Yup.string()
      .trim()
      .when("step", {
        is: 1,
        then: Yup.string()
          .trim()
          .required("Confirm New Password field is required")
          .test(
            "confirmNewPassword",
            "Incorrect confirm password",
            (value, ctx) => value === ctx?.parent?.password
          ),
      }),
    // the pre-third step
    code: Yup.number()
      .transform((v, o) => (o === "" ? null : v))
      .nullable(true)
      .when("step", {
        is: 2.1,
        then: Yup.number()
          .transform((v, o) => (o === "" ? null : v))
          .nullable(true)
          .typeError("Code field must be numbers")
          .required("Code field is required")
          .test((value, ctx) => codeValidation(value, ctx)),
      }),
    // step 2
    name: Yup.string()
      .trim()
      .when("step", {
        is: 2,
        then: Yup.string()
          .trim()
          .test("specialCharacter", "Invalid character used", (value) => {
            if (!value) return true;
            const trimmedValue = value.trim();
            const SPECIAL_CHARTS_REGEXP = /[:\t\n]/;
            return !SPECIAL_CHARTS_REGEXP.test(trimmedValue);
          })
          .required("Legal business name field is required"),
      }),
    //federalTaxIdEin: Yup.string()
    //  .trim()
    //  .when("step", {
    //    is: 2,
    //    then: Yup.string()
    //      .transform((v, o) => (o === "" ? null : v))
    //      .nullable()
    //      .typeError("Must be a number.")
    //      // .required("Federal Tax ID (EIN) field is required")
    //      .test(
    //        "federalTaxIdEinValidation",
    //        "Please type a valid Tax Identification String!",
    //        (value) => {
    //          if (!value) return true;
    //          const FED_REGEXP = /^[\d-]+[\d]{7}$/;
    //          return FED_REGEXP.test(value);
    //        }
    //      ),
    //  }),
    billingAddress: Yup.object().when("step", {
      is: 2,
      then: Yup.object().shape({
        formatted_address: Yup.string().required("This field is required"),
        street: Yup.string().required("Street field is required"),
        city: Yup.string().required("City field is required"),
        state: Yup.string().required("State field is required").nullable(),
        zip: Yup.string().required("ZIP code field is required"),
        lat: Yup.number().nullable().required("Coordinates are missing"),
        lng: Yup.number().nullable().required("Coordinates are missing"),
      }),
    }),
    shippingAddress: Yup.object().when("step", {
      is: 2,
      then: Yup.object().shape({
        formatted_address: Yup.string()
          .nullable()
          .test(
            "shippingAddress",
            "This field is required",
            (value, context) => {
              return context?.from?.[1]?.value?.sameAsBillingAddress
                ? true
                : !!value;
            }
          ),
        street: Yup.string()
          .nullable()
          .test(
            "shippingAddress",
            "Street field is required",
            (value, context) => {
              return context?.from?.[1]?.value?.sameAsBillingAddress
                ? true
                : !!value;
            }
          ),
        city: Yup.string()
          .nullable()
          .test(
            "shippingAddress",
            "City field is required",
            (value, context) => {
              return context?.from?.[1]?.value?.sameAsBillingAddress
                ? true
                : !!value;
            }
          ),
        state: Yup.string()
          .nullable()
          .test(
            "shippingAddress",
            "State field is required",
            (value, context) => {
              return context?.from?.[1]?.value?.sameAsBillingAddress
                ? true
                : !!value;
            }
          ),
        zip: Yup.string()
          .nullable()
          .test(
            "shippingAddress",
            "ZIP code field is required",
            (value, context) => {
              return context?.from?.[1]?.value?.sameAsBillingAddress
                ? true
                : !!value;
            }
          ),
        lat: Yup.number()
          .nullable()
          .test(
            "shippingAddress",
            "Coordinates are missing",
            (value, context) => {
              return context?.from?.[1]?.value?.sameAsBillingAddress
                ? true
                : !!value;
            }
          ),
        lng: Yup.number()
          .nullable()
          .test(
            "shippingAddress",
            "Coordinates are missing",
            (value, context) => {
              return context?.from?.[1]?.value?.sameAsBillingAddress
                ? true
                : !!value;
            }
          ),
      }),
    }),
    businessPhone: Yup.string()
      .trim()
      .when("step", {
        is: 2,
        then: Yup.string()
          .trim()
          .required("Business phone field is required")
          .test((value, ctx) => businessPhoneValidation(value, ctx, isMobile)),
      }),
    payTermId: Yup.string()
      .trim()
      .when("step", {
        is: 3,
        then: Yup.string().test((value, { parent }) => {
          if (
            parent?.distributorData?.id &&
            !parent?.distributorData?.hideRequestPaymentTerms
          )
            return !!value;
          return true;
        }),
      }),
  });
