export const classes = {
  deliverySelect: {
    "& > .MuiSelect-select": {
      backgroundColor: "#ffff",
      padding: "0px 20px 0px 10px!important",
    },
  },
  deliverySelectList: {
    width: "152px",
    "& ul": { padding: 0 },
  },
  termText: {
    color: "rgba(0, 0, 0, 0.80)",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "15px",
    wordWrap: "break-word",
  },
  selectedCardWrapper: {
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
    padding: "8px 12px",
    background: "rgba(0, 0, 0, 0.03)",
    borderRadius: "8px",
  },
  cardNumber: {
    color: "rgba(0, 0, 0, 0.80)",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    wordWrap: "break-word",
  },
  cardName: {
    color: "rgba(0, 0, 0, 0.40)",
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "15px",
    wordWrap: "break-word",
  },
  addressText: {
    color: "rgba(0, 0, 0, 0.80)",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    wordWrap: "break-word",
  },
};
