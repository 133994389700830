import { useEffect, useMemo, useState } from "react";
import { bool, func, object, string, array } from "prop-types";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import { CrossIcon, CVVIcon } from "components/Icons";

import {
  LargeCheckbox,
  StyledTextField,
  Loader,
  CardIconComponent,
} from "components";

import { WarningBox } from "./components";

import { cardMask, defaultValues } from "./AddCardDialog.constants";
import { validationSchema } from "./AddCardDialog.validations";
import { currentUserSelector } from "redux/selectors/auth";
import { currentDistributorStoreDataSelector } from "redux/selectors/distributors";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { addCustomerCardService } from "services/stripe";
import { success, error } from "helpers/notifications";

const selector = createSelector(
  currentUserSelector,
  currentDistributorStoreDataSelector,
  (currentUser, currentStoreData) => ({
    currentUser,
    currentStoreData,
  })
);
export const AddCardDialog = ({
  isOpen,
  handleClose,
  customerId,
  // distributorId,
  // orderId,
  // control,
  handleSave,
  // replacedCardId,
  // setCardDefault,
  // skipContactValidate,
  customerCreditCards,
  // headers,
  // isRegistration,
}) => {
  const classes = useStyles();
  // const { currentUser, distributorPaymentCards } = useSelector(selector);
  // const dispatch = useDispatch();
  const { currentUser } = useSelector(selector);
  const [state, setState] = useState({
    toggleUnit: false,
    edit: false,
    loading: false,
    cardValue: "",
    cardValueType: "",
  });
  const billingAddress = currentUser.billingAddress;

  const hasCustomerDefaultCard = useMemo(() => {
    if (customerCreditCards) {
      return customerCreditCards.some((card) => card?.defaultMethod);
    }
  }, [customerCreditCards]);
  // const hasDistributorDefaultCard = useMemo(() => {
  //   if (setCardDefault) return false;
  //   if (customerCreditCards === undefined) {
  //     return distributorPaymentCards?.some((card) => card?.defaultMethod);
  //   }
  // }, [customerCreditCards, distributorPaymentCards, setCardDefault]);

  const setDefaultCreditCard = useMemo(() => {
    if (customerCreditCards) {
      return hasCustomerDefaultCard ? defaultValues?.defaultMethod : true;
    }
  }, [customerCreditCards, hasCustomerDefaultCard]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      ...defaultValues,
      fid: Date.now(),
      billingAddress: billingAddress?.formatted_address
        ? billingAddress
        : defaultValues.billingAddress,
      defaultMethod: setDefaultCreditCard,
    },
    resolver: yupResolver(
      validationSchema({
        editingAddress: state.edit,
        isEdit: false,
        cardValueType: state.cardValueType,
      })
    ),
  });

  const currentUserHasBillingAddress = useMemo(() => {
    return !!currentUser?.billingAddress?.formatted_address;
  }, [currentUser?.billingAddress?.formatted_address]);

  useEffect(() => {
    reset({
      ...defaultValues,
      fid: Date.now(),
      billingAddress: billingAddress?.formatted_address
        ? billingAddress
        : defaultValues.billingAddress,
      defaultMethod: setDefaultCreditCard,
    });
  }, [billingAddress, isOpen, reset, setDefaultCreditCard]);

  const getErrorMessage = (err) => {
    if (err === "Your card number is incorrect.") {
      setError("number", { message: err });
      return;
    }
    if (err === "Your card's security code is incorrect.") {
      setError("cvc", { message: err });
      return;
    }
    if (err === "Your card has expired.") {
      setError("exp", { message: err });
      return;
    }
    if (err === "Your card has insufficient funds.") {
      setError("number", { message: err });
      return;
    }

    error(err || "Something went wrong!");
    handleClose();
  };

  const onSubmit = async (data) => {
    // eslint-disable-next-line no-console
    if (!currentUserHasBillingAddress) {
      setError("billingAddress", {
        message: "Please add a default Billing Address before saving.",
      });
      return;
    }

    const { name, exp, fid, defaultMethod, ...saveData } = data;
    const expSplit = data.exp.split(" / ");
    const dataCard = {
      ...saveData,
      expMonth: expSplit[0],
      expYear: "20" + expSplit[1],
      billingAddress: state.edit ? data.billingAddress : billingAddress,
      number: data.number.split(" ").join(""),
      name: name,
      defaultMethod: !!defaultMethod,
    };

    if (customerId) {
      setState((prop) => ({ ...prop, loading: true }));
      addCustomerCardService(customerId, dataCard)
        .then((res) => {
          handleSave(res, !!defaultMethod);
          success("The card was added!");
          handleClose();
        })
        .catch((err) => {
          getErrorMessage(err?.response?.data?.error);
          // eslint-disable-next-line
          console.error(err);
        })
        .finally(() => {
          setState((prop) => ({ ...prop, loading: false }));
        });
    }
  };

  useEffect(() => {
    setState((prop) => ({
      ...prop,
      cardValueType:
        cardMask.find((m) => m.regex.test(+state.cardValue))?.cardtype ||
        "Default",
    }));
  }, [state.cardValue]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setState((prop) => ({ ...prop, edit: false }));
        handleClose();
      }}
      PaperProps={{ sx: { maxHeight: "100vh" } }}
    >
      <Loader isLoading={state.loading} />
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.title}>
          Add a credit or debit card
        </Typography>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 0,
        }}
        className={classes.dialogContent}
      >
        <Box
          component="form"
          id="customer-card-details-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Collapse in={!!Object.keys(errors).length}>
            <WarningBox sx={{ mt: 1 }} errors={Object.entries(errors)} />
          </Collapse>
          <Box mt="14px">
            {/* <Typography className={classes.textFieldTitle}>
              Name on card
            </Typography> */}
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  label="Name on card"
                  error={error?.message || ""}
                  noErrorMessage
                  className={classes.input}
                  // size="normal"
                  {...field}
                />
              )}
              name="name"
              control={control}
            />
          </Box>
          <Box mt="19px">
            {/* <Typography className={classes.textFieldTitle}>
              Card number
            </Typography> */}
            <Controller
              render={({ field, fieldState: { error } }) => (
                <NumberFormat
                  onValueChange={(v) =>
                    setState((prop) => ({ ...prop, cardValue: v?.value }))
                  }
                  customInput={StyledTextField}
                  error={error?.message || ""}
                  label="Card number"
                  placeholder="1234 1234 1234 1234"
                  className={classes.input}
                  format="#### #### #### ####"
                  startAdornment={
                    <InputAdornment position="start">
                      <CardIconComponent size={26} type={state.cardValueType} />
                    </InputAdornment>
                  }
                  noErrorMessage
                  {...field}
                />
              )}
              name="number"
              control={control}
            />
          </Box>
          <Box mb="10px" mt="23px" display="flex" gap="13px">
            <Box>
              {/* <Typography className={classes.textFieldTitle}>
                Expiration Date
              </Typography> */}
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <NumberFormat
                    customInput={StyledTextField}
                    format="## / ##"
                    error={error?.message || ""}
                    placeholder="MM / YY"
                    noErrorMessage
                    className={classes.input}
                    label="Expires on"
                    {...field}
                  />
                )}
                name="exp"
                control={control}
              />
            </Box>
            <Box>
              {/* <Typography className={classes.textFieldTitle}>
                Security code
              </Typography> */}
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    placeholder="CVC"
                    noErrorMessage
                    className={classes.input}
                    label="Security code"
                    sx={{ "& .MuiInputBase-root": { pl: "7px" } }}
                    startAdornment={
                      <InputAdornment position="start">
                        <CVVIcon />
                      </InputAdornment>
                    }
                    error={error?.message || ""}
                    {...field}
                  />
                )}
                name="cvc"
                control={control}
              />
            </Box>
          </Box>
          <Controller
            render={({ field }) => {
              return (
                <LargeCheckbox
                  size={16}
                  formSx={{ pl: "3px" }}
                  label={
                    <Typography className={classes.defaultMethodText}>
                      Set as primary payment method
                    </Typography>
                  }
                  {...field}
                  onChange={() => setValue("defaultMethod", !field.value)}
                  checked={field.value}
                />
              );
            }}
            name="defaultMethod"
            control={control}
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          color="grey"
          sx={{
            height: "27px",
            fontSize: 10,
          }}
          onClick={() => {
            setState((prop) => ({ ...prop, edit: false }));
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          form="customer-card-details-form"
          sx={{ height: "27px", color: "#FFF", fontSize: 10 }}
        >
          Add card
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddCardDialog.propTypes = {
  isOpen: bool,
  handleClose: func,
  customerId: string,
  distributorId: string,
  orderId: string,
  billingAddress: object,
  handleSave: func,
  replacedCardId: string,
  setCardDefault: bool,
  skipContactValidate: bool,
  customerCreditCards: array,
  headers: object,
  isRegistration: bool,
  control: object,
};

export default AddCardDialog;
