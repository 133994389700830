import { func, object, string } from "prop-types";
import { useSelector } from "react-redux";

import { ContentBlock } from "./components/ContentBlock";

import { useAddCardDrawer } from "./useAddCardDrawer";

import { useStyles } from "../ContactDrawer/styles";

import { Drawer, Grid } from "@mui/material";
import { DrawerHeader } from "components/ContactDrawer/components/DrawerHeader";

export const AddCardDrawer = ({ customerId, handleSave }) => {
  const classes = useStyles();
  const isDrawerOpened = useSelector(({ uiState }) => uiState.addCardDrawer);

  const {
    onClose,
    FIELD_DATA,
    control,
    formField,
    handleSubmit,
    onSubmit,
    isCustom,
    errors,
    loading,
  } = useAddCardDrawer({
    isOpen: isDrawerOpened,
    customerId,
    customerCreditCards: [],
    handleSave,
  });
  return (
    <Drawer
      anchor="bottom"
      open={isDrawerOpened}
      onClose={onClose}
      PaperProps={{ className: classes.drawerWrapper }}
    >
      <Grid
        component="form"
        id="customer-mobile-card-details-form"
        onSubmit={handleSubmit(onSubmit)}
        key="customer-mobile-card-details-form"
        sx={{ width: "100%" }}
      >
        <DrawerHeader
          {...{
            disabled: loading,
            formKey: "customer-mobile-card-details-form",
            handleClose: onClose,
            title: "Add a credit or debit card",
          }}
        />
        <ContentBlock
          {...{
            FIELD_DATA,
            control,
            formField,
            handleSubmit,
            onSubmit,
            isCustom,
            errors,
          }}
        />
      </Grid>
    </Drawer>
  );
};

AddCardDrawer.propTypes = {
  handleAddContact: func,
  handleSave: func,
  editContact: object,
  customerId: string,
  card: object,
};
