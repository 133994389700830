import {} from "react";
import {} from "prop-types";
import { Box, Typography } from "@mui/material";
import { HEADER_NAMES_LIST } from "./constants";

export const HeaderItem = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#F7F7F7",
        border: "1px solid #D5D9D9",
        height: 44,
        display: "flex",
      }}
    >
      {HEADER_NAMES_LIST.map(({ name, size, style }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            letterSpacing: -0.6,
            fontSize: 15,
            ...style,
          }}
          width={`${size}%`}
          key={name}
          component={Typography}
        >
          {name}
        </Box>
      ))}
    </Box>
  );
};

HeaderItem.propTypes = {};
HeaderItem.defaultProps = {};
