import { func } from "prop-types";
import { Box, Button } from "@mui/material";
import { UploadFileIcon } from "components/Icons";

export const FooterBlock = ({ onClose }) => {
  return (
    <Box mt={2} display="flex">
      <Box flexGrow={1}>
        <Button
          sx={{
            height: "33px",
            fontSize: 13,
            color: "#6A6A6A",
            borderColor: "#D5D9D9",
            "&:hover": {
              borderColor: "#000",
              bgcolor: "#D5D9D930",
            },
          }}
          variant="outlined"
          startIcon={<UploadFileIcon />}
        >
          Upload Image
        </Button>
      </Box>

      <Box display="flex" gap={1}>
        <Button
          sx={{
            height: "33px",
            fontSize: 13,
            color: "#6A6A6A",
            "&:hover": {
              bgcolor: "#D5D9D930",
            },
          }}
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button sx={{ height: "33px" }} variant="contained">
          Create Return
        </Button>
      </Box>
    </Box>
  );
};

FooterBlock.propTypes = { onClose: func };
FooterBlock.defaultProps = { onClose: () => {} };
