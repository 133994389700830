import { object, array, bool } from "prop-types";
import { Box, Typography } from "@mui/material";
import { AttentionIcon } from "components/Icons";
import { useMediaDevice } from "hooks/useMediaDevice";
import { MobileAttentionIcon } from "components/Icons/MobileAttentionIcon";

export const WarningBox = ({ sx, errors, skipAddress = false }) => {
  const { isMobile } = useMediaDevice();
  const cl = {
    mainBlock: {
      border: {
        xs: "1px rgba(228, 30, 58, 0.20) solid",
        sm: "1px solid #EB4233",
      },
      borderRadius: { xs: "8px", sm: "4px" },
      backgroundColor: {
        xs: "rgba(228, 30, 58, 0.12)",
        sm: "rgba(235, 66, 51, 0.1)",
      },
    },
    textTitle: {
      fontSize: { xs: 12, sm: 14 },
      fontWeight: 600,
    },
    textWarn: {
      fontSize: { xs: 12, sm: 14 },
      fontWeight: 400,
      color: "#000000",
    },
  };

  return (
    <Box sx={{ ...cl.mainBlock, ...sx }}>
      <Box p={{ xs: "10px 8px", sm: "11px" }}>
        <Box mb={1} display="flex" alignItems="center" gap={0.6}>
          {isMobile ? <MobileAttentionIcon /> : <AttentionIcon size="16" />}
          <Typography sx={cl.textTitle}>There was a problem.</Typography>
        </Box>
        {errors.map((err) => (
          <Typography sx={cl.textWarn} key={err[0]} ml={{ xs: "30px", sm: 0 }}>
            {err[0] === "billingAddress" && !skipAddress
              ? "- Address is not correct."
              : err[1]?.message && `${isMobile ? "•" : "-"} ${err[1]?.message}`}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

WarningBox.propTypes = {
  sx: object,
  errors: array,
  skipAddress: bool,
};
WarningBox.defaultProps = {
  errors: [],
};
