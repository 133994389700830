import { forwardRef } from "react";
import {
  LocalizationProvider,
  DatePicker,
  PickersDay,
} from "@mui/x-date-pickers";
import { Box } from "@mui/system";
import PropTypes, { object, func, array, string } from "prop-types";
import { CalendarIcon } from "../Icons";
import { StyledTextField } from "../TextFields/TextFields";
import useStyles from "./styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const StyledDatePicker = forwardRef(
  (
    {
      anchorEl,
      handleDateInput,
      date,
      handleClose,
      textFieldProps,
      error,
      height,
      wrapperClassName,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        dateFormats={{ weekdayShort: "DD" }}
      >
        <Box className={`${classes.menu} ${wrapperClassName}`}>
          <DatePicker
            PaperProps={{
              className: classes.menu,
              sx: { width: "206px", padding: "0px 8px" },
            }}
            dayOfWeekFormatter={(day) => `${day}`}
            renderDay={(dateStr, dateObj, dayProps) => {
              const dayDate = new Date(dateStr);
              const day = dayDate.getDate();
              const today = new Date();
              today.setDate(today.getDate() - 1);
              return (
                <PickersDay
                  sx={{
                    width: "22px",
                    height: "20px",
                    transform: "none",
                    fontWeight: dayProps.today ? "700" : "500",
                    color: "#5F6267",
                    "&:hover": {
                      border: "1px solid #9e9e9e",
                    },
                    "& .div": {
                      border: "none",
                      display: "flex",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#42A57F!important",
                      borderRadius: "3px",
                    },
                  }}
                  {...dayProps}
                >
                  {day}
                </PickersDay>
              );
            }}
            components={{
              OpenPickerIcon: () => CalendarIcon({ fill: "#5F6267" }),
            }}
            InputProps={{
              sx: {
                fontSize: "12px",
                height,
                color: "#5F6267",
                fontWeight: 400,
              },
            }}
            renderInput={(inputProps) => {
              return (
                <StyledTextField
                  size="small"
                  {...inputProps}
                  {...textFieldProps}
                  inputProps={{
                    ...inputProps.inputProps,
                    ...textFieldProps.inputProps,
                  }}
                  placeholder="123"
                  ref={ref}
                  error={error?.message || ""}
                />
              );
            }}
            {...props}
          />
        </Box>
      </LocalizationProvider>
    );
  }
);

StyledDatePicker.displayName = "StyledDatePicker";

StyledDatePicker.propTypes = {
  anchorEl: object,
  handleDateInput: func,
  date: PropTypes.oneOfType([array, string]),
  handleClose: func,
  onChange: func,
  textFieldProps: object,
  error: object,
  height: string,
  wrapperClassName: string,
};

StyledDatePicker.defaultProps = {
  anchorEl: null,
  height: "32px",
  wrapperClassName: "",
};

export default StyledDatePicker;
