import { COUNTRY_PHONE_CODES } from "utils/constants";

export const defaultValues = {
  // the first step
  businessEmail: "",
  password: "",
  confirmNewPassword: "",
  // the pre-second step
  code: "",
  // the second step
  name: "",
  //federalTaxIdEin: "",
  billingAddress: {
    appartement: "",
    city: "",
    formatted_address: "",
    state: "",
    street: "",
    zip: "",
  },
  shippingAddress: {
    appartement: "",
    city: "",
    formatted_address: "",
    state: "",
    street: "",
    zip: "",
  },
  country: "",
  sameAsBillingAddress: true,
  businessPhone: "",
  countryPrefix: COUNTRY_PHONE_CODES[0],
  step: 1,
  loadingDistributorData: true,
  payTermId: "",
};

export const CRUMBS_ITEMS = [
  { id: 1, name: "Account Creation" },
  { id: 2, name: "Business Details" },
  { id: 3, name: "Finish" },
];

export const initialErrPhoneStatus = {
  status: false,
  message: "",
  value: "",
};
