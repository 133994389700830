import { Box, Tab, Tabs, Typography } from "@mui/material";
import { cl } from "../NotificationMenu.styles";

export const Switcher = () => {
  return (
    <Box sx={cl.switcher.tabsWrapper}>
      <Tabs sx={cl.switcher.tabs}>
        <Tab
          sx={cl.switcher.tab}
          label={
            <Box sx={cl.switcher.tabLabel}>
              <Typography
                sx={{
                  color: "#47A06D",
                  fontSize: "12px",
                  py: 0,
                  px: 0,
                  minWidth: 40,
                }}
              >
                all
              </Typography>
            </Box>
          }
        />
        <Tab
          sx={cl.switcher.tab}
          label={<Box sx={cl.switcher.tabLabel}>approved</Box>}
        />
        <Tab
          sx={cl.switcher.tab}
          label={<Box sx={cl.switcher.tabLabel}>pending</Box>}
        />
        <Tab
          sx={cl.switcher.tab}
          label={<Box sx={cl.switcher.tabLabel}>rejected</Box>}
        />
      </Tabs>
    </Box>
  );
};
