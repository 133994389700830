import { createSelector } from "@reduxjs/toolkit";

export const currentUserSelector = (state) => state.auth.currentUser;
export const authLoadingSelector = (state) => state.auth.loading;

export const singleOrderDirectSelector = createSelector(
  currentUserSelector,
  (currentUser) => {
    const { orderDirect } = currentUser || {};
    if (orderDirect?.length === 1) return orderDirect[0];
    return null;
  }
);
