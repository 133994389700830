import React from "react";

export const NoteIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.488 3.013a1.75 1.75 0 0 0-2.475 0L6.75 7.274a2.8 2.8 0 0 0-.596.892l-.848 2.047a.75.75 0 0 0 .98.98l2.047-.848a2.8 2.8 0 0 0 .892-.596l4.262-4.262a1.75 1.75 0 0 0 0-2.474"
        fill="#409A65"
      />
      <path
        d="M4.75 4c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h6.5c.69 0 1.25-.56 1.25-1.25V9.5a.75.75 0 0 1 1.5 0v2.25a2.75 2.75 0 0 1-2.75 2.75h-6.5A2.75 2.75 0 0 1 2 11.75v-6.5A2.75 2.75 0 0 1 4.75 2.5H7A.75.75 0 0 1 7 4z"
        fill="#409A65"
      />
    </svg>
  );
};
