import { makeStyles } from "@mui/styles";

export const cl = {
  dialogContentWrapper: {
    p: 0,
    overflowY: "visible",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
    pl: 2.75,
    pr: 2,
  },
  searchBlockWrapper: {
    borderTop: "1px solid #D5D9D9",
    display: "flex",
    gap: 2,
    px: 2.75,
    py: 3,
  },
  successfullyBlockWrapper: {
    width: "100%",
    height: "380px",
    borderTop: "1px solid #D5D9D9",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
  },
  successfullyText: {
    fontSize: 17,
    fontWeight: 700,
    color: "#000",
  },
  search: {
    "& .MuiAutocomplete-paper": {
      boxShadow: "none",
    },
  },
  searchInput: {
    "& .MuiOutlinedInput-root": {
      height: "34px",
      py: "0px !important",
      fontSize: "15px",
      fontWeight: 300,
      backgroundColor: "#F8F8F8",
      "& > fieldset": {
        borderColor: "rgb(213,217,217)!important",
        borderWidth: "1px!important",
      },
    },
  },
  searchListBoxProps: {
    p: 0,
    maxHeight: "146px",
    boxShadow: "none",
    overflow: "overlay",
    borderRadius: "4px",
  },
  browseAllBtn: {
    minWidth: "94px",
    height: "34px",
    px: 0,
    fontSize: 15,
    fontWeight: 300,
    color: "#727272",
    bgcolor: "#F8F8F8",
    border: "1px solid #D5D9D9",
  },
  supBlockWrapper: {
    display: "flex",
    justifyContent: "space-between",
    px: 2.75,
    py: 1.2,
  },
  supCardWrapper: {
    width: "100%",
    height: "78px",
    borderRadius: "4px",
    backgroundColor: "rgba(64, 154, 102, 0.1)",
    display: "flex",
    alignItems: "center",
  },
  supImgWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    pl: 3.25,
    pr: 1.5,
  },
  supInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "150px",
    borderRight: "1px solid #D5D9D9",
    pr: 0.5,
  },
  supInfoTextName: {
    fontSize: 16,
    color: "#000",
  },
  supInfoTextCompany: {
    fontSize: 16,
    fontWeight: 300,
    color: "#000",
  },
  btnUpdateWrapper: {
    display: "flex",
    alignItems: "center",
  },
  btnUpdate: {
    height: "30px",
    fontSize: 13,
    color: "#6A6A6A",
  },
  customerTitle: {
    fontSize: 20,
    fontWeight: 500,
    color: "#000",
    py: 1.2,
  },
  msgInput: {
    "& .MuiInputBase-input": {
      fontSize: 12,
      fontWeight: 300,
    },
  },
  actionsBlock: { p: "14px 22px 22px 22px" },
  actionCancelBtn: {
    height: "34px",
    fontSize: 15,
    color: "#6A6A6A",
  },
  actionInviteBtn: { height: "34px", fontSize: 15, px: 2.8 },
  divider: {
    mt: "-11.25px",
    mb: "-11.25px",
    "&::before, &::after": {
      borderTop: "0.5px solid #D5D9D9",
    },
  },
  dividerText: {
    fontSize: 15,
    color: "#3F3F3F",
  },
  supplierInfoWrapperText: {
    height: "42px",
    alignItems: "center",
  },
  supplierInfoText: {
    px: 2.75,
    fontSize: 15,
    fontWeight: 500,
    color: "#3F3F3F",
  },
  supplierInfoName: {
    pl: 2.75,
    pr: 0.5,
  },
  supplierInfoEmail: {
    pl: 0.5,
    pr: 2.75,
  },
  supplierInfoTextField: {
    "& .MuiInputBase-input": {
      fontSize: 15,
      fontWeight: 300,
    },
  },
};

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: "12px",
    fontWeight: 300,
    height: "34px",
    backgroundColor: "#F8F8F8",
    paddingLeft: "25px",
  },
  section: {
    border: "0.5px solid #D5D9D9",
    boxShadow: "none",
  },
  recipientsTable: {
    height: "100%",
    zIndex: "10",
    border: "1px solid #D5D9D9",
    width: "100%",
    backgroundColor: "#ffffff",
    overflow: "overlay",
    borderRadius: "4px",
    marginTop: "1px",
  },
  textfield: {
    color: "#ff0000",
    "& input::placeholder, & textarea::placeholder": {
      color: "#BEBEBE",
      opacity: 1,
      fontWeight: 400,
    },
  },
}));

export default useStyles;
