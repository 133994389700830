import { Box } from "@mui/system";
import { any, string, bool } from "prop-types";

export const CustomCurtainComponent = ({
  children,
  toggled,
  curtainHeight,
  ...props
}) => {
  return (
    <Box
      sx={{
        maxHeight: toggled ? curtainHeight : "0px",
        overflow: "hidden",
        transition: "all 0.5s ease",
        opacity: toggled ? 1 : 0,
        pointerEvents: !toggled && "none",
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

CustomCurtainComponent.propTypes = {
  children: any,
  toggled: bool,
  curtainHeight: string,
};
