import { func } from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { CheckmarkIcon, CrossIcon } from "components/Icons";

export const HeaderBlock = ({ onClose }) => {
  return (
    <Box
      width="100%"
      height="71px"
      bgcolor="#F8F8F8"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pl={4.25}
      pr={1.5}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <CheckmarkIcon width="17.71" height="12" stroke="#52B985" />
        <Typography fontSize={18} color="#3F3F3F">
          Added to Cart
        </Typography>
      </Box>

      <IconButton onClick={onClose}>
        <CrossIcon size={30} />
      </IconButton>
    </Box>
  );
};

HeaderBlock.propTypes = { onClose: func };
HeaderBlock.defaultProps = { onClose: () => {} };
