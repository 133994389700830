import React from "react";

export const MobileVisaIcon = () => {
  return (
    <svg
      width="32"
      height="20"
      viewBox="0 0 32 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.325 13.942H8.36L6.887 8.156c-.07-.266-.218-.501-.437-.612a6.3 6.3 0 0 0-1.8-.612v-.223h3.164c.437 0 .765.334.82.723l.764 4.173L11.36 6.71h1.91zm4.039 0h-1.856l1.528-7.233h1.855zm3.928-5.23c.054-.389.382-.612.764-.612a3.37 3.37 0 0 1 1.801.334l.328-1.557a4.6 4.6 0 0 0-1.692-.335c-1.8 0-3.111 1.002-3.111 2.392 0 1.058.928 1.614 1.583 1.948.709.334.982.557.927.89 0 .5-.546.723-1.09.723a4.6 4.6 0 0 1-1.91-.445l-.328 1.558c.655.278 1.364.39 2.019.39 2.02.055 3.274-.946 3.274-2.448 0-1.892-2.565-2.003-2.565-2.837m9.058 5.23L25.877 6.71h-1.582a.82.82 0 0 0-.765.556l-2.728 6.677h1.91l.382-1.057h2.346l.219 1.057zm-2.782-5.285.545 2.726h-1.528z"
        fill="#172B85"
      />
      <rect
        x=".15"
        y=".15"
        width="31.7"
        height="19.7"
        rx="1.85"
        stroke="#000"
        strokeOpacity=".1"
        strokeWidth=".3"
      />
    </svg>
  );
};
