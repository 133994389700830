import React from "react";

export const FilledEmailIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.125 3.75c-1.036 0-1.875.84-1.875 1.875v.991a1 1 0 0 1 .095.04l8.248 3.981a.94.94 0 0 0 .815 0l8.247-3.981a1 1 0 0 1 .095-.04v-.991c0-1.036-.84-1.875-1.875-1.875z"
        fill="#0F172A"
      />
      <path
        d="m18.75 8.692-7.527 3.634a2.81 2.81 0 0 1-2.446 0L1.25 8.692v5.683c0 1.036.84 1.875 1.875 1.875h13.75c1.035 0 1.875-.84 1.875-1.875z"
        fill="#0F172A"
      />
    </svg>
  );
};
