export const cl = {
  orderFulfillmentText: {
    color: "#1C1C19",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "15.6px",
    whiteSpace: "nowrap",
  },

  fulfillmentIcon: {
    width: "8px",
    height: "8px",
    borderRadius: "8px",
  },
};
