export const cl = {
  wrapper: {
    width: "100%",
    minHeight: "100vh",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: { xs: "0 16px", sm: "0 3.5vw" },
    backgroundColor: { xs: "#ffff", sm: "#F9F8F8" },
  },

  paperWrapper: {
    width: { xs: "100%", sm: "470px" },
    pt: { xs: 0, sm: "3vh" },
    borderRadius: "16px",
    border: { xs: "none", sm: "1px solid rgba(0, 0, 0, 0.12)" },
  },

  logo: {
    width: "auto",
    height: "5vh",
    marginBottom: "4vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  inputSx: {
    fontWeight: { xs: 500, sm: 400 },
    height: { xs: "48px", sm: "38px" },
    fontSize: { xs: "14px", sm: "15px" },
    borderRadius: { xs: "8px", sm: "4px" },
    backgroundColor: { xs: "rgba(213.42, 213.42, 213.42, 0.16)", sm: "#ffff" },
    "& input": {
      fontSize: { xs: "14px", sm: "15px" },
      padding: { xs: "13.9px 14px", sm: "7px 14px" },
    },
    "&& fieldset": {
      fontSize: { xs: "14px", sm: "15px" },
      borderColor: "rgba(194, 194, 194, 0.5)",
      borderWidth: { xs: "0px !important", sm: "1px !important" },
      height: { xs: "48px", sm: "41px" },
    },
    "&:hover:not(.Mui-focused)": {
      "&& fieldset": {
        borderColor: "rgba(194, 194, 194, 0.5)",
        borderWidth: { xs: "0px !important", sm: "1px" },
        height: { xs: "48px", sm: "38px" },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      fontSize: { xs: "14px", sm: "17px" },
    },
  },

  termsText: {
    opacity: 0.4,
    textAlign: "center",
    color: "black",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    wordWrap: "break-word",

    "& a": {
      color: "black",
      textDecoration: "underline",
      cursor: "pointer",
    },
  },

  labelSx: {
    fontSize: "17px",
    top: -3,
    "&.Mui-focused": { top: -3 },
    display: { xs: "none", sm: "block" },
  },

  loginBtn: {
    fontSize: { xs: "16px", sm: "clamp(12px, 2vh, 20px)" },
    fontWeight: { xs: 600, sm: 500 },
    mt: { xs: "32px", sm: "2vh" },
    height: { xs: "49px", sm: "40px" },
  },

  forgotPassText: {
    color: {
      xs: "black",
      sm: "#5F6267",
    },
    opacity: { xs: 0.4, sm: 1 },
    fontSize: { xs: "12px", sm: "clamp(0.5rem, 2vh, 19px)" },
  },

  createBtn: {
    height: { xs: "48px", sm: "40px" },
    fontSize: { xs: "14px", sm: "clamp(12px, 2vh, 20px)" },
    fontWeight: { xs: 600, sm: 500 },
    mt: { xs: "34px", sm: 0 },
    color: { xs: "#409A65", sm: "black" },
    boxShadow: {
      xs: "none !important",
    },

    "&:hover": {
      boxShadow: {
        xs: "none !important",
        sm: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
      },
    },

    "& .MuiButtonBase-root-MuiButton-root:active": {},
  },

  errorInputSx: {
    "&& fieldset": {
      borderColor: "rgba(228, 30, 58, 0.60) !important",
      borderWidth: "1px !important",
    },
  },

  inputIcon: {
    p: 0,
    "& svg": {
      width: "18px",
      height: "18px",

      "& path": {
        fill: "rgba(0, 0, 0, 0.2)",
      },
    },
  },
};
