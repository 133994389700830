import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { any, string } from "prop-types";

const SectionItem = ({
  title,
  value,
  secondValue,
  justifyContent,
  titleWidth,
}) => {
  return (
    <Box
      display="flex"
      justifyContent={justifyContent || "space-between"}
      height="52px"
      alignItems="center"
      mx="24px"
      sx={{
        "&:not(:last-of-type)": {
          borderBottom: "1px solid #D5D9D9",
        },
      }}
    >
      <Typography
        color="#979797"
        fontSize="14px"
        fontWeight="600"
        width={titleWidth}
        noWrap
      >
        {title}
      </Typography>
      <Box maxWidth={`calc(100% - ${titleWidth ? titleWidth : "30%"})`}>
        <Typography
          fontSize="14px"
          fontWeight={500}
          textAlign="right"
          color="#000"
          noWrap
        >
          {value}
        </Typography>
        {!!secondValue && (
          <Typography
            fontSize="14px"
            fontWeight={500}
            textAlign="right"
            color="#000"
            noWrap
          >
            {secondValue}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

SectionItem.propTypes = {
  title: string,
  justifyContent: string,
  value: any,
  secondValue: any,
  titleWidth: string,
};

export default SectionItem;
