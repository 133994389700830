import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { Box, Grid } from "@mui/material";

import {
  OrdersTab,
  ReturnsTab,
  ViewSettings,
  OrdersSwitcherTab,
  SearchBlock,
  MobileOrdersTab,
} from "./components";
import { validationSchema } from "./OrdersPage.validations";

import { VIEW_SETTINGS_ITEMS, FILTER_TABS } from "./OrdersPage.constants";
import { currentUserSelector } from "redux/selectors/auth";
import { useOrders } from "./useOrders";
import { useDebounce } from "helpers/hooks";
import { useMediaDevice } from "hooks/useMediaDevice";
import { FilterDrawer, MobileSearchBlock } from "components";
import { useOrdersFilter } from "./useOrdersFilter";

const orderTabs = ["Orders", "Returns"];

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const OrdersPage = () => {
  const { currentUser } = useSelector(selector);
  const { orderDirect, timeZone } = currentUser || {};
  const { isMobile } = useMediaDevice();

  const { control, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      items: VIEW_SETTINGS_ITEMS,
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const [searchVal, setSearchVal] = useState("");
  const debouncedSearch = useDebounce(searchVal, 500);

  const [currentOrderTab, setCurrentOrderTab] = useState(orderTabs[0]);
  const location = useLocation();

  useEffect(() => {
    const suppliers = orderDirect?.map((s) => ({
      ...s?.distributor,
      checked: false,
    }));
    if (suppliers?.length) {
      setValue("items.suppliers.items", suppliers);
    }
  }, [orderDirect, setValue]);

  const checkedSuppliers = useMemo(() => {
    const list = formField.items.suppliers.items;

    return list
      .filter((supplier) => {
        return supplier?.checked;
      })
      .map((el) => el.id);
  }, [formField]);

  const {
    ordersState,
    switches,
    filterFields,
    handleApplyFilter,
    handleDeleteFilter,
    selectMatchData,
    handleFetchMore,
    updatedChildrenList,
    setFilterFields,
  } = useOrders({
    currentUser,
    currentOrderTab,
    debouncedSearch: debouncedSearch ? debouncedSearch : undefined,
    checkedSuppliers: checkedSuppliers?.length
      ? JSON.stringify(checkedSuppliers)
      : undefined,
    fulfillmentStatus: formField.items.fulfillment_status.selected
      ? JSON.stringify([formField.items.fulfillment_status.selected])
      : undefined,
    paymentStatus: formField.items.payment_status.selected
      ? JSON.stringify([formField.items.payment_status.selected])
      : undefined,
  });

  const renderContent = (currentTab, tabs) => {
    switch (currentTab) {
      case tabs[0]:
        return isMobile ? (
          <MobileOrdersTab
            {...{
              handleFetchMore,
              ordersState,
              timeZone,
              hasActiveFilter: !!filterCount,
            }}
          />
        ) : (
          <OrdersTab {...{ ordersState, timeZone }} />
        );
      case tabs[1]:
        return <ReturnsTab />;
    }
  };

  useEffect(() => {
    if (typeof location.state === "string") setCurrentOrderTab(location.state);
  }, [location.state]);

  const {
    onSave,
    chipData,
    filterCount,
    FILTER_DATA,
    onStateClear,
    openFilterDrawer,
    handleDeleteChip,
    disabledActionBtn,
    currentFilterElement,
  } = useOrdersFilter({
    createdByList: updatedChildrenList,
    setFilterForApply: setFilterFields,
  });

  return (
    <Box pt={{ xs: 0, sm: "26px" }} display="flex" gap="30px" maxWidth="100%">
      {!isMobile && <ViewSettings control={control} />}
      <FilterDrawer
        {...{
          FILTER_TABS,
          FILTER_DATA,
          onSave,
          onStateClear,
          disabledActionBtn,
          currentFilterElement,
        }}
      />
      <Box flex={{ xs: "1 1 100%", sm: "1 1 87%" }} maxWidth="100%">
        {isMobile ? (
          <MobileSearchBlock
            handleSearch={(e) => setSearchVal(e.target.value)}
            searchInput={searchVal}
            {...{ chipData, handleDeleteChip, filterCount, openFilterDrawer }}
          />
        ) : (
          <SearchBlock
            handleSearch={(e) => setSearchVal(e.target.value)}
            searchInput={searchVal}
            handleApplyFilter={handleApplyFilter}
            handleDeleteFilter={handleDeleteFilter}
            switches={switches}
            filterFields={filterFields}
            selectMatchData={selectMatchData}
          />
        )}

        <Grid container>
          <>
            {!isMobile && (
              <OrdersSwitcherTab
                tabs={orderTabs}
                currentTab={currentOrderTab}
              />
            )}
            {renderContent(currentOrderTab, orderTabs)}
            <Outlet />
          </>
        </Grid>
      </Box>
    </Box>
  );
};

OrdersPage.propTypes = {};
