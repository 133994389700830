export const cl = {
  btn: {
    position: "fixed",
    bottom: 23,
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#409A65",
    boxShadow: "0px 10px 20px -3px rgba(132.20, 132.20, 132.20, 0.56)",
    borderRadius: 100,
    overflow: "hidden",
    border: "1px #288C51 solid",
    color: "#ffff",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: "600",
    wordWrap: "break-word",
    textTransform: "none",
    gap: "15px",
    height: "50px",
    padding: "0px 17px",
    transition: "all 0.3s",
    zIndex: 101,

    "& button": {
      backgroundColor: "#409A65 !important",
    },
  },
};
