import { Box, Typography } from "@mui/material";
import { ArrowNextIcon, HomeIcon } from "components/Icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { currentUserSelector } from "redux/selectors/auth";
import { createSelector } from "reselect";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const useBreadCrumbs = ({ supplier, category, productName }) => {
  const { currentUser } = useSelector(selector);

  const isSingleOrderDirect = currentUser?.orderDirect?.length === 1;

  const breadCrumbs = (
    <>
      <Box
        sx={{ color: "#000", textDecoration: "none" }}
        component={Link}
        to="/"
        // state={{ filter: { supplier } }}
      >
        <HomeIcon />
      </Box>

      {!isSingleOrderDirect && (
        <>
          <ArrowNextIcon color="#000" width={5.21} height={9.11} />
          <Box
            sx={{
              color: "#000",
              textDecoration: "none",
            }}
            component={Link}
            to="/"
            state={{
              filter: {
                supplier,
              },
            }}
          >
            {supplier?.name}
          </Box>
        </>
      )}

      <Box minWidth="5.21px">
        <ArrowNextIcon color="#000" width={5.21} height={9.11} />
      </Box>

      <Box
        sx={{
          color: "#26874B",
          textDecoration: "none",
        }}
        component={Link}
        to="/"
        state={{
          filter: {
            supplier,
            category,
          },
        }}
      >
        {category?.name}
      </Box>

      <Box minWidth="5.21px">
        <ArrowNextIcon color="#000" width={5.21} height={9.11} />
      </Box>

      <Typography component="span" color="#A7A7A7" noWrap>
        {productName}
      </Typography>
    </>
  );

  return breadCrumbs;
};
