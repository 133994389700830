import {
  END_LOADING_DISTRIBUTORS,
  GET_DISTRIBUTORS_COUNT,
  GET_DISTRIBUTORS_LIST,
  START_LOADING_DISTRIBUTORS,
  GET_DISTRIBUTOR_LOGO,
  GET_DISTRIBUTOR_PARAMS,
  SET_STORE_DATA,
  SET_SELECTED_SUPPLIER,
} from "../actions/distributors";

const initialState = {
  loading: false,
  distributorsList: [],
  selectedSupplier: null,
  distributorsCount: 0,
  distributorLogo: null,
  distributorGetParams: { sort_by_recent: "asc" },
  currentStoreData: null,
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("selectedSupplier");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state.selectedSupplier);
    localStorage.setItem("selectedSupplier", serializedState);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("selected supplier store error", err);
  }
};

const persistedState = loadState();

const distributorsReducer = (
  state = { ...initialState, selectedSupplier: persistedState },
  { type, payload }
) => {
  switch (type) {
    case GET_DISTRIBUTORS_LIST:
      return { ...state, distributorsList: payload };
    case SET_SELECTED_SUPPLIER: {
      const newState = { ...state, selectedSupplier: payload };
      saveState(newState);
      return newState;
    }
    case GET_DISTRIBUTORS_COUNT:
      return { ...state, distributorsCount: payload };
    case GET_DISTRIBUTOR_LOGO:
      return { ...state, distributorLogo: payload };
    case GET_DISTRIBUTOR_PARAMS:
      return { ...state, distributorGetParams: payload };
    case SET_STORE_DATA:
      return { ...state, currentStoreData: payload };

    case START_LOADING_DISTRIBUTORS:
      return { ...state, loading: true };
    case END_LOADING_DISTRIBUTORS:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default distributorsReducer;
