import { makeStyles } from "@mui/styles";

export const cl = {
  actionWrapper: {
    width: "100%",
    padding: "15px 16px",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "9px",
    display: "inline-flex",
    position: "fixed",
    left: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: "#ffff",
  },

  termsText: {
    color: "black",
    fontSize: "14px",
    lineHeight: "18.9px",
  },
};

export const useStyles = makeStyles(() => ({
  invoiceBtn: {
    width: "100%",
    fontWeight: 600,
    height: "49px",
    fontSize: "16px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.10)",
    borderRadius: "12px",
    padding: "10.5px 16px",
  },

  invoiceText: {
    fontWeight: 600,
    fontSize: "16px",
  },
}));
