import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { bool, string, object, func } from "prop-types";
// import MapTooltip from "../MapTooltip/MapTooltip";
import { MapPinRed } from "../Icons";

export const MapPoint = ({
  $hover,
  // address,
  customer,
  onMarkerClick,
  color,
}) => {
  // const [showTooltip, setShowTooltip] = useState(false);
  const [colorItem, setColorItem] = useState(color || "");

  // useEffect(() => {
  //   setShowTooltip($hover);
  // }, [$hover]);

  useEffect(() => {
    if (colorItem === "#3e7c18") {
      setColorItem("#47A06D");
    }
  }, [colorItem, setColorItem]);

  return (
    <Box
      onClick={() => onMarkerClick(customer?.id)}
      position="absolute"
      top="-36px"
      left="-18px"
      margin="0 auto"
      whiteSpace="nowrap"
      sx={{ cursor: "pointer", zIndex: $hover && 100 }}
    >
      {/* {showTooltip && <MapToolti customer={customer} address={address} />} */}
      <MapPinRed fill={!customer?.group ? "clearBlack" : colorItem} />
    </Box>
  );
};

MapPoint.propTypes = {
  $hover: bool,
  address: string,
  customer: object,
  onMarkerClick: func,
  color: string,
};

MapPoint.defaultProps = {
  $hover: false,
  address: "",
  customer: null,
  color: "#000000",
  onMarkerClick: () => {},
};
