export const cl = {
  title: {
    fontSize: "14px",
    color: "black",
    fontWeight: 600,
    lineHeight: "18.90px",
    mb: "4px",
  },
  addressText: {
    color: "rgba(0, 0, 0, 0.80)",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    wordWrap: "break-word",
  },
  editBtn: {
    width: "fit-content",
    minWidth: "0px",
    height: "18px",
    color: "#409A65",
    fontSize: "14px",
    fontWeight: 600,
    padding: 0,
    whiteSpace: "nowrap",

    "& svg": {
      width: "18px",
      height: "18px",
      marginRight: "2px",
    },
  },
};
