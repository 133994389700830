import { yupResolver } from "@hookform/resolvers/yup";
import { error, success } from "helpers/notifications";
import { getFullAddressStripe } from "pages/CartPage/components/EditPaymentPopup/EditPaymentPopup.helpers";
import { ConfirmContext } from "pages/MasterPage/MasterPage";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCustomerCardService,
  getCustomerCardsService,
  getSettingsTransactionsService,
  updateCustomerCardService,
} from "services/stripe";
import { defaultValues, TABS } from "./PaymentsPage.constants";
import { validationSchema } from "./PaymentsPage.validations";
import lodash from "lodash";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { DRAWERS } from "constants/drawer";

export const usePayments = () => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState(TABS[0]);

  const [editCard, setEditCard] = useState(null);
  const [cardOpen, setCardOpen] = useState(false);
  const [transactionList, setTransactionList] = useState([]);

  const {
    control,
    // handleSubmit,
    setValue,
    // reset,
    // setError,
    // formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      fid: Date.now(),
      billingAddress: defaultValues.billingAddress,
      creditCards: [],
      lastCardId: "",
      cardId: "",
      // defaultMethod: setDefaultCreditCard,
      defaultMethod: false,
    },
    resolver: yupResolver(validationSchema()),
  });
  const formField = useWatch({ control });
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const customerId = currentUser?.orderDirect[0]?.distributor?.customers[0]?.id;
  const [isLoading, setIsLoading] = useState(false);
  const [cardState, setCardState] = useState([]);
  const { rows } = cardState ?? {};
  const { setConfirmState } = useContext(ConfirmContext);

  const fetchCustomerCards = useCallback(() => {
    setIsLoading(true);
    getCustomerCardsService(customerId)
      .then((res) => {
        setCardState(res);
        setValue(
          "lastCardId",
          res.hasMore ? res?.rows[res?.rows?.length - 1]?.id : ""
        );
        setValue("creditCards", res.rows);
      })
      .catch((err) => error(err?.message))
      .finally(() => setIsLoading(false));
  }, [customerId, setValue]);

  const handleSaveCard = () => {
    fetchCustomerCards();
  };

  const handleSaveEditCard = () => {
    fetchCustomerCards();
  };

  const onConfirmDelete = useCallback(
    (cardId) => {
      deleteCustomerCardService(customerId, cardId)
        .then(() => {
          fetchCustomerCards();
          success("The card was deleted!");
        })
        .catch((err) => {
          error("Something went wrong!");
          // eslint-disable-next-line
          console.error(err);
        });
      setConfirmState((prev) => ({
        ...prev,
        isOpen: false,
        onConfirm: () => {},
      }));
    },
    [customerId, fetchCustomerCards, setConfirmState]
  );

  useEffect(() => {
    fetchCustomerCards();
  }, [fetchCustomerCards]);

  const handleSetDefault = (card) => {
    const defaultCardIndex = formField.creditCards.findIndex(
      (item) => item.defaultMethod === true
    );
    const cardIndex = formField.creditCards.findIndex((item) =>
      card.id ? item.id === card.id : item.fid === card.fid
    );
    const newCards = formField.creditCards;
    newCards.splice(cardIndex, 1, {
      ...card,
      defaultMethod: card.defaultMethod === true ? false : true,
    });

    if (defaultCardIndex > -1 && card.defaultMethod === false) {
      const changedCard = {
        ...formField.creditCards[defaultCardIndex],
        defaultMethod: false,
      };
      newCards.splice(defaultCardIndex, 1, changedCard);
    }

    const creditCards = formField.creditCards.map(
      ({ fid, address, ...card }) => {
        return lodash.pickBy(
          {
            id: card.id || null,
            name: card.name || card.billingAddress.name,
            defaultMethod: card.defaultMethod === true,
            expMonth: card.expMonth,
            expYear: card.expYear,
            number: card.number || null,
            cvc: card.cvc || null,
            billingAddress: {
              formatted_address:
                card.billingAddress.formatted_address ||
                getFullAddressStripe(card?.billingAddress?.address) ||
                "",
              zip:
                card.billingAddress.zip ||
                card.billingAddress.address.zip ||
                card.billingAddress.address.postal_code,
              city:
                card.billingAddress.city || card.billingAddress.address.city,
              state:
                card.billingAddress.state || card.billingAddress.address.state,
              street:
                card.billingAddress.street || card.billingAddress.address.line1,
              appartement:
                card.billingAddress.appartement ||
                card.billingAddress.address?.line2 ||
                null,
            },
          },
          (value) => value !== null
        );
      }
    );
    const changedCard = creditCards.find((c) => c.id === card.id);
    const { id, ...preparedCard } = changedCard;

    setIsLoading(true);
    updateCustomerCardService(customerId, id, preparedCard)
      .then(() => {
        success("Primary payment method updated");
        setValue("creditCards", newCards, { shouldDirty: true });
        setIsLoading(false);
        fetchCustomerCards();
      })
      .catch((err) => {
        setIsLoading(false);
        error("Something went wrong!");
        // eslint-disable-next-line
        console.error(err);
      });
  };

  const defaultCreditCard = useMemo(
    () => rows?.filter((card) => !!card.defaultMethod)?.[0],
    [rows]
  );

  const handleOpenEditCard = (cart) => {
    setEditCard(cart);
    dispatch(
      showCurrentDrawerAction({
        type: DRAWERS.EDIT_CARD_DRAWER,
        show: true,
      })
    );
  };

  const onAddNewCard = () => {
    dispatch(
      showCurrentDrawerAction({
        type: DRAWERS.ADD_CARD_DRAWER,
        show: true,
      })
    );
  };

  const getTransactionList = useCallback(() => {
    getSettingsTransactionsService()
      .then((res) => {
        setTransactionList(res);
      })
      .catch((err) => {
        error("Something went wrong!");
        // eslint-disable-next-line
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (tab !== TABS[1]) return;
    getTransactionList();
  }, [getTransactionList, tab]);

  return {
    cardOpen,
    setCardOpen,
    rows,
    customerId,
    currentUser,
    handleSaveCard,
    tab,
    setTab,
    setConfirmState,
    onConfirmDelete,
    handleSaveEditCard,
    handleSetDefault,
    isLoading,
    defaultCreditCard,
    handleOpenEditCard,
    editCard,
    onAddNewCard,
    transactionList,
  };
};
