import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { cl } from "../../RegistrationPage.styles";
import { useContext, useEffect, useMemo, useState } from "react";
import { Context } from "../RegistrationBody/RegistrationBody";
import { Controller, useWatch } from "react-hook-form";
import { ExpandMore } from "@mui/icons-material";
import { getPaymentTermsByStoreNameService } from "services/distributors";
import { error } from "helpers/notifications";
import { useMediaDevice } from "hooks/useMediaDevice";
import { StyledArrowIcon } from "components/Icons";

export const StepThree = () => {
  const { isMobile } = useMediaDevice();
  const {
    stepThree: { btn, secondText, thnxText, wrapper },
  } = cl;

  const { control, isLoading, setValue } = useContext(Context);

  const formField = useWatch({ control });

  const { distributorData, token, payTermId, regParams } = formField;

  const [paymentsData, setPaymentsData] = useState({
    loading: false,
    list: [],
  });

  const hasDistributor = useMemo(() => {
    return !!distributorData?.id;
  }, [distributorData?.id]);

  const fetchPaymentTerms = async () => {
    setPaymentsData((prev) => ({ ...prev, loading: true }));
    try {
      const data = await getPaymentTermsByStoreNameService({
        storeName: distributorData?.storeName,
        token: token || regParams?.tokenParam,
        params: { filter_advance_payment: true },
      });
      setPaymentsData((prev) => ({
        ...prev,
        list: data.sort((a, b) => {
          return ("" + a?.name).localeCompare(b?.name);
        }),
      }));
    } catch (err) {
      error(err.message);
      // eslint-disable-next-line no-console
      console.error(err.message);
    } finally {
      setPaymentsData((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (hasDistributor) {
      if (paymentsData?.list?.[0]?.id)
        setValue("payTermId", paymentsData.list[0].id);
    }
  }, [hasDistributor, paymentsData.list, setValue]);

  useEffect(() => {
    if (
      distributorData?.name &&
      !distributorData?.hideRequestPaymentTerms &&
      (token || regParams?.tokenParam)
    ) {
      fetchPaymentTerms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributorData?.name]);

  const disabledBtn = useMemo(() => {
    if (isLoading) return true;
    if (
      hasDistributor &&
      !distributorData?.hideRequestPaymentTerms &&
      !payTermId
    )
      return true;
    return false;
  }, [
    distributorData?.hideRequestPaymentTerms,
    hasDistributor,
    isLoading,
    payTermId,
  ]);

  return (
    <Box sx={wrapper}>
      <Typography sx={thnxText}>Thank you!</Typography>
      <Typography sx={secondText}>
        {hasDistributor
          ? "Your account has been created"
          : "Your account is now active"}
      </Typography>
      {hasDistributor && !distributorData?.hideRequestPaymentTerms && (
        <Box width="100%" px={{ xs: 0, sm: 3.5 }} mb={{ xs: "32px", sm: 2 }}>
          <Controller
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl fullWidth height="48px">
                  <Select
                    displayEmpty
                    sx={
                      isMobile
                        ? {
                            ...cl.select,
                            "& > .MuiSelect-select": {
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "black",
                              padding: "14px 32px 14px 14px !important",
                            },
                          }
                        : cl.paymentTermsSelect
                    }
                    MenuProps={{ sx: { maxHeight: "300px" } }}
                    error={error?.message}
                    fullWidth
                    IconComponent={(props) =>
                      isMobile ? (
                        <StyledArrowIcon {...props} />
                      ) : (
                        <ExpandMore sx={{ fontSize: 20 }} {...props} />
                      )
                    }
                    {...field}
                  >
                    <MenuItem sx={{ display: "none" }} value="">
                      <Typography
                        fontSize={{ xs: 14, sm: 12 }}
                        color={{ xs: "#56565660", sm: "#717171" }}
                      >
                        Apply for Payment Terms
                      </Typography>
                    </MenuItem>
                    {paymentsData.list.map((payTerm) => (
                      <MenuItem key={payTerm?.id} value={payTerm?.id}>
                        <Typography
                          fontSize={{ xs: 14, sm: 12 }}
                          color="#717171"
                        >
                          {payTerm?.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
            name="payTermId"
            control={control}
          />
        </Box>
      )}
      <Button
        disabled={disabledBtn}
        height="4vh"
        sx={btn}
        fullWidth
        variant="contained"
        form="registration-form"
        type="submit"
      >
        {isLoading
          ? "Loading..."
          : hasDistributor && !distributorData?.hideRequestPaymentTerms
          ? "Request Wholesale Access"
          : "Start Shopping"}
      </Button>
    </Box>
  );
};
