import { CANADA_PHONE_CODES, COUNTRY_PHONE_CODES } from "utils/constants";
import { defaultValues } from "./ProfilePage.constants";

export const getDefaultValues = (user, isMobile) => {
  const defaultLocation = {
    ...user.shippingAddress,
    name: user.shippingAddress?.name
      ? user.shippingAddress?.name
      : "Shipping address",
    defaultLoc: true,
  };
  const nonDefaultLocations = (user.locations || []).map((loc) => {
    return { ...loc, defaultLoc: false };
  });

  const handleGetPhone = () => {
    if (user.phone) {
      if (isMobile) return user.phone;

      return user.phone?.replace("+1", "");
    }

    return defaultValues.phone;
  };

  return {
    email: user.baseUser?.email || defaultValues.email,
    name: user.name || defaultValues.name,
    phone: handleGetPhone(),
    billingAddress: user.billingAddress || defaultValues.billingAddress,
    federalTaxId: user.federalTaxId || defaultValues.federalTaxId,
    website: user.website || defaultValues.website,
    contacts: user.contacts
      ? user.contacts.map((c) => ({
          ...c,
          phone: c?.phone?.replace("+1", ""),
          countryPrefix:
            user.country === "CA"
              ? CANADA_PHONE_CODES[0]
              : COUNTRY_PHONE_CODES[0],
        }))
      : defaultValues.contacts,
    locations:
      [defaultLocation, ...nonDefaultLocations] || defaultValues.locations,
    country: user.country || "US",
    profilePhoto: user.profilePhoto || defaultValues.profilePhoto,
    countryPrefix:
      user.country === "CA" ? CANADA_PHONE_CODES[0] : COUNTRY_PHONE_CODES[0],
  };
};
