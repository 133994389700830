import { array } from "prop-types";
import { Grid, Box, IconButton } from "@mui/material";
import { ImagePlaceholder } from "components";
import { ArrowNextIcon, ArrowPrevIcon } from "components/Icons";

import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./styles.css";
import { photoUrl } from "helpers/helpers";
import { useState } from "react";

export const PhotosBlock = ({ productPhotos }) => {
  const [swiper, setSwiper] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [disableNavBtn, setDisableNavBtn] = useState("left");

  const swiperSlideElement = document.querySelector(
    ".swiper-slide-visible"
  )?.offsetWidth;

  return (
    <Grid xs={5.18} item pl="50px">
      {productPhotos?.length ? (
        <Box
          sx={{
            position: "relative",
            "& .mySwiper .swiper-slide": {
              height: swiperSlideElement,
              maxHeight: swiperSlideElement,
            },
          }}
        >
          {productPhotos?.length > 5 && (
            <>
              {" "}
              <IconButton
                disabled={disableNavBtn === "left"}
                className="swiper-button-prev-unique"
                sx={{
                  position: "absolute",
                  width: "40px",
                  height: "40px",
                  left: "-40px",
                  bottom: `calc(${swiperSlideElement / 2}px - 10px)`,
                }}
                onClick={() => {
                  swiper.slidePrev();
                }}
              >
                <ArrowPrevIcon
                  color={disableNavBtn === "left" ? "#d5d9d9" : "#47a06d"}
                />
              </IconButton>
              <IconButton
                disabled={disableNavBtn === "right"}
                className="swiper-button-next-unique"
                sx={{
                  position: "absolute",
                  width: "40px",
                  height: "40px",
                  right: "-40px",
                  bottom: `calc(${swiperSlideElement / 2}px - 10px)`,
                }}
                onClick={() => swiper.slideNext()}
              >
                <ArrowNextIcon
                  color={disableNavBtn === "right" ? "#d5d9d9" : "#47a06d"}
                />
              </IconButton>
            </>
          )}
          <Swiper
            spaceBetween={10}
            thumbs={{
              swiper: thumbsSwiper,
              autoScrollOffset: 0,
              multipleActiveThumbs: false,
            }}
            setWrapperSize={true}
            modules={[Thumbs]}
            className="mySwiper2"
            onSwiper={setSwiper}
            onSlideChange={(e) => {
              if (e.activeIndex === 0) return setDisableNavBtn("left");
              if (e.activeIndex === e.slides?.length - 1) {
                return setDisableNavBtn("right");
              }
              setDisableNavBtn("");
            }}
          >
            {productPhotos.map((photo) => (
              <SwiperSlide key={photo?.id}>
                <Box
                  sx={{ borderRadius: "0 !important" }}
                  component="img"
                  src={photoUrl(photo?.fileName)}
                  alt={photo?.fileName}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {productPhotos?.length > 1 && (
            <Swiper
              spaceBetween={10}
              slidesPerView={5}
              centerInsufficientSlides={true}
              slideToClickedSlide={true}
              modules={[Navigation]}
              navigation={{
                nextEl: ".swiper-button-next-unique",
                prevEl: ".swiper-button-prev-unique",
              }}
              className="mySwiper"
              onSwiper={setThumbsSwiper}
            >
              {productPhotos.map((photo, i) => (
                <SwiperSlide key={photo?.id}>
                  <Box
                    component="img"
                    src={photoUrl(photo?.fileName)}
                    alt={photo?.fileName}
                    onClick={() => {
                      if (
                        i ===
                        thumbsSwiper?.visibleSlidesIndexes[
                          thumbsSwiper?.visibleSlidesIndexes?.length - 1
                        ]
                      ) {
                        thumbsSwiper.slideNext();
                      }
                      if (i === thumbsSwiper?.visibleSlidesIndexes[0]) {
                        thumbsSwiper.slidePrev();
                      }
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Box>
      ) : (
        <ImagePlaceholder
          width="100%"
          height="100%"
          wrapperProps={{ width: "100%" }}
          style={{ objectFit: "cover", borderWidth: 0 }}
          rx={0}
        />
      )}
    </Grid>
  );
};

PhotosBlock.propTypes = { productPhotos: array };
PhotosBlock.defaultProps = { productPhotos: [] };
