import { Box, Typography } from "@mui/material";
import { separateNumWithComma } from "helpers/helpers";
import { ProductContext } from "pages/ProductsPage/components/ProductDetailsPage/ProductDetailsPage";
import { useContext } from "react";

export const TitleBlock = () => {
  const { product } = useContext(ProductContext);

  const distributorName = product?.distributorName || "";
  const productName = product?.name || "";
  const minimumOrderValueAmount = product?.minimumOrderValueAmount || 0;

  return (
    <Box>
      <Box width="100%" mb="4px">
        <Typography fontSize={18} fontWeight={600} color="#2F3040" noWrap>
          {productName}
        </Typography>
      </Box>

      <Box display="flex">
        <Box width="70%">
          <Typography fontSize={12} fontWeight={400} color="#000" noWrap>
            Supplier: {distributorName}
          </Typography>
        </Box>

        <Box width="30%" textAlign="right">
          <Typography fontSize={12} fontWeight={400} color="#000" noWrap>
            {minimumOrderValueAmount
              ? `Minimum $${separateNumWithComma(minimumOrderValueAmount)}`
              : "No minimum"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
