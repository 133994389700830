import { bool, func, object, string } from "prop-types";
import { useSelector } from "react-redux";

import { ContentBlock } from "./components/ContentBlock";

import { useEditCardDrawer } from "./useEditCardDrawer";

import { useStyles } from "../ContactDrawer/styles";

import { Drawer, Grid } from "@mui/material";
import { DrawerHeader } from "components/ContactDrawer/components/DrawerHeader";

export const EditCardDrawer = ({
  customerId,
  handleSave,
  card = null,
  showBillingInfo = false,
  handleDeleteCard,
}) => {
  const classes = useStyles();
  const isDrawerOpened = useSelector(({ uiState }) => uiState.editCardDrawer);

  const {
    onClose,
    FIELD_DATA,
    control,
    formField,
    handleSubmit,
    onSubmit,
    isCustom,
    isLoading,
    billingInfo,
    onDelete,
  } = useEditCardDrawer({
    isOpen: isDrawerOpened,
    customerId,
    card,
    handleSave,
    handleDeleteCard,
  });
  return (
    <Drawer
      anchor="bottom"
      open={isDrawerOpened}
      onClose={onClose}
      PaperProps={{ className: classes.drawerWrapper }}
    >
      <Grid
        component="form"
        id="edit-mobile-card-form"
        onSubmit={handleSubmit(onSubmit)}
        key="edit-mobile-card-form"
        sx={{ width: "100%" }}
      >
        <DrawerHeader
          {...{
            disabled: isLoading,
            formKey: "edit-mobile-card-form",
            handleClose: onClose,
            title: "Edit payment method",
          }}
        />
        <ContentBlock
          {...{
            FIELD_DATA,
            control,
            formField,
            handleSubmit,
            onSubmit,
            isCustom,
            card,
            billingInfo,
            showBillingInfo,
            onDelete,
          }}
        />
      </Grid>
    </Drawer>
  );
};

EditCardDrawer.propTypes = {
  handleAddContact: func,
  handleSave: func,
  handleDeleteCard: func,
  showBillingInfo: bool,
  editContact: object,
  customerId: string,
  card: object,
};
