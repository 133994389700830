import { array, object, string, func } from "prop-types";
import { Box, CardMedia } from "@mui/material";
import { ImagePlaceholder } from "components";
import { photoUrl } from "helpers/helpers";

export const PictureBlock = ({
  photos,
  height = "295px",
  mediaSx,
  product,
  handleOpenProductProfile,
}) => {
  const productPhoto = photos?.length ? photos[0] : {};

  return photos?.length ? (
    <CardMedia
      sx={{
        height,
        borderBottom: "1px solid #d5d9d9",
        backgroundColor: "#F7F7F7",
        ...mediaSx,
      }}
      image={photoUrl(productPhoto?.fileName)}
      onClick={() => handleOpenProductProfile(product)}
    />
  ) : (
    <Box
      backgroundColor="rgb(243, 243, 243)"
      height={height}
      sx={{ "& rect": { rx: 0 } }}
      onClick={() => handleOpenProductProfile(product)}
    >
      <ImagePlaceholder
        width="100%"
        height={height}
        style={{
          objectFit: "cover",
          borderRadius: 0,
        }}
      />
    </Box>
  );
};

PictureBlock.propTypes = {
  photos: array,
  height: string,
  mediaSx: object,
  product: object,
  handleOpenProductProfile: func,
};
PictureBlock.defaultProps = {
  photos: [],
  product: {},
  handleOpenProductProfile: () => {},
};
