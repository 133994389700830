import { useState, useEffect, forwardRef } from "react";
import PropTypes, { string, bool, object } from "prop-types";
import { Switch, Box, Typography, FormControlLabel } from "@mui/material";
import useStyles from "./styles";
import { StyledTooltip } from "components";

export const IOSSwitch = forwardRef(
  ({ label, formSx, small, hasTooltip, tooltipText, ...props }, ref) => {
    const classes = useStyles({ small });
    const [switched, setSwitched] = useState(false);

    useEffect(() => {
      setSwitched(props.checked);
    }, [props.checked]);

    return (
      <FormControlLabel
        label={label}
        sx={{ ...formSx, pointerEvents: "none" }}
        control={
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              color: "white",
              mr: "16px",
            }}
          >
            <StyledTooltip
              title={tooltipText}
              placement="bottom"
              arrow
              disableHoverListener={!hasTooltip}
              modifiers={[{ name: "offset", options: { offset: [0, -8] } }]}
            >
              <Box display="flex" alignItems="center">
                <Switch
                  className={classes.switcher}
                  focusVisibleClassName=".Mui-focusVisible"
                  disableRipple
                  onChange={() => setSwitched(!switched)}
                  ref={ref}
                  {...props}
                  checked={switched}
                />
              </Box>
            </StyledTooltip>
            {switched ? (
              <Typography
                sx={{
                  position: "absolute",
                  right: small ? 21 : 43,
                  zIndex: "1",
                  fontSize: small ? "8px" : "12px",
                  color: "#fff",
                }}
              >
                ON
              </Typography>
            ) : (
              <Typography
                sx={{
                  position: "absolute",
                  right: small ? 5 : 12,
                  zIndex: "1",
                  fontSize: small ? "8px" : "12px",
                  color: "#fff",
                }}
              >
                OFF
              </Typography>
            )}
          </Box>
        }
      />
    );
  }
);

IOSSwitch.displayName = "IOSSwitch";

IOSSwitch.propTypes = {
  label: PropTypes.oneOfType([string, object]),
  checked: bool,
  formSx: object,
  small: bool,
  hasTooltip: bool,
  tooltipText: string,
};

IOSSwitch.defaultProps = {
  label: "",
  checked: false,
  formSx: {},
  small: false,
  hasTooltip: false,
  tooltipText: "",
};

export default IOSSwitch;
