export const MIN_PASSWORD_LENGTH = 8;
export const UPPERCASE_REG_EXP = /[A-Z]/;
export const LOWERCASE_REG_EXP = /[a-z]/;
export const SYMBOL_REG_EXP = /[-!$%^&*()_+|~=`{}[\]:/;<>?,.@#]/;
export const NUMBER_REG_EXP = /\d/;

export const PASSWORD_CRITERIA = [
  {
    title: `At least ${MIN_PASSWORD_LENGTH} characters long`,
    condition: ({ password }) => {
      return password?.length >= MIN_PASSWORD_LENGTH;
    },
  },
  {
    title: "Use upper and lower case letters",
    condition: ({ password }) => {
      return (
        LOWERCASE_REG_EXP.test(password) && UPPERCASE_REG_EXP.test(password)
      );
    },
  },
  {
    title: "Use a number",
    condition: ({ password }) => {
      return NUMBER_REG_EXP.test(password);
    },
  },
  {
    title: "Use a symbol",
    condition: ({ password }) => {
      return SYMBOL_REG_EXP.test(password);
    },
  },
  {
    title: "Password match",
    condition: ({ password, confirmPassword }) => {
      if (confirmPassword === "") return false;
      return password && password === confirmPassword;
    },
  },
];
