import { object } from "prop-types";

import { ProductImage } from "components/CartProductItem/components/ProductImage";

import { cl } from "./styles";

import { Stack, Typography } from "@mui/material";
import { separateNumWithComma } from "helpers/helpers";
import { useRef } from "react";

export const OrderedProductItem = ({ product }) => {
  const {
    quantity,
    name,
    photos,
    photo,
    parentProduct,
    sku,
    color,
    size,
    itemsPerCase,
    price,
    totalAmount,
  } = product;
  const qtyRef = useRef(null);

  const qtyElementWidth = qtyRef?.current && qtyRef?.current?.clientWidth + 8;

  return (
    <Stack
      direction="row"
      gap="12px"
      alignItems="center"
      sx={{
        overflow: "hidden",
        width: "100%",
        py: "4px",
      }}
    >
      <ProductImage {...{ photos, product, photo }} />

      <Stack width="calc(100% - 98px)" gap="2px" height="100%">
        <Typography sx={cl.productName}>
          {name || parentProduct?.name}{" "}
        </Typography>
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{ ...cl.productDescription, "& span": { mr: "4px" } }}
        >
          {!!sku && <span>{sku}</span>}
          {(size || color) && (
            <Typography sx={cl.productDescription}>
              {`/ ${size ? `${size}` : ""} ${
                color ? `${size ? "/" : ""} ${color}` : ""
              } `}{" "}
              {itemsPerCase && `(pack of ${itemsPerCase})`}
            </Typography>
          )}
        </Stack>

        <Stack mt="auto" direction="row" justifyContent="space-between">
          <Stack width={`calc(100% - ${qtyElementWidth || 41}px)`}>
            <Typography sx={cl.price} noWrap>
              ${separateNumWithComma(totalAmount)}
            </Typography>
            <Typography sx={cl.unitPrice} noWrap>
              ${separateNumWithComma(price?.toFixed(2))} / price per unit
            </Typography>
          </Stack>
          <Stack sx={cl.quantityBlock} ref={qtyRef}>
            <Typography sx={cl.quantityNumber}>{quantity}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

OrderedProductItem.propTypes = {
  product: object,
};
