import { error } from "helpers/notifications";
import { createContext, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOrderByIdService, updateOrderService } from "services/orders";

export const OrderContext = createContext();

const defaultOrderData = {
  order: null,
  loading: true,
  editedNote: "",
};

export const useViewOrder = () => {
  const [orderData, setOrderData] = useState(defaultOrderData);
  const { orderId } = useParams();

  const updateOrderData = useCallback((newValues) => {
    setOrderData((prev) => ({ ...prev, ...newValues }));
  }, []);

  const handleGetOrder = useCallback(async () => {
    if (!orderId) return;
    try {
      updateOrderData({ loading: true });
      const order = await getOrderByIdService(orderId);
      updateOrderData({ loading: false, order, editedNote: order.note?.text });
    } catch (err) {
      error(err?.response?.data?.message);
      updateOrderData({ loading: false });
    }
  }, [orderId, updateOrderData]);

  useEffect(() => {
    handleGetOrder();
  }, [handleGetOrder]);

  const handleUpdateNote = async () => {
    try {
      updateOrderData({ loading: true });
      const order = await updateOrderService(orderId, {
        note: { text: orderData.editedNote },
      });

      updateOrderData({ loading: false, order });
    } catch (err) {
      error(err?.response?.data?.message);
      updateOrderData({ loading: false });
    }
  };

  return { orderData, updateOrderData, handleUpdateNote, handleGetOrder };
};
