export const cl = {
  sendBtn: {
    mt: "auto",
    width: "100%",
    height: "49px",
    borderRadius: "12px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.10) ",
  },

  fileItem: {
    position: "relative",
    width: "88px",
    height: "88px",
    borderRadius: "8px",
    overflow: "hidden",

    img: {
      width: "88px",
      height: "88px",
      objectFit: "cover",
    },
  },

  deleteFileItem: {
    position: "absolute",
    top: "4.5px",
    right: "4.5px",
    p: "2px",
    background: "#F2F2F2",
    borderRadius: "3px",
  },
};
