export const cl = {
  TableHeader: {
    wrapper: {
      height: "47px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      pl: "21px",
      pr: "15px",
      backgroundColor: "#EEEEEE",
      borderRadius: "4px 4px 0 0",
      border: "1px solid #d5d9d9",
    },
  },
  CarriersTab: {
    stack: {
      maxHeight: "288px",
      overflowY: "overlay",
      borderWidth: "0px 1px 1px 1px",
      borderStyle: "solid",
      borderColor: "#D5D9D9",
      borderRadius: "0 0 4px 4px",
      // background: !fields.length && "white",
    },
    text: {
      fontWeight: "300",
      fontSize: "16px",
      textAlign: "center",
      width: "100%",
      color: "#6A6A6A",
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },
  CarriersItem: {
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      ml: "20px",
      mr: "10px",
      height: "62px",
      alignItems: "center",
      "&:not(:last-of-type)": {
        borderBottom: "1px solid #d5d9d9",
      },
      "& .MuiTypography-root": {
        fontSize: "15px",
      },
    },
  },
};
