import { array, bool, number, object, string } from "prop-types";
import { Grid, Stack } from "@mui/material";
import { ProductVariationItem, ProductVariationsHeader } from "./components";
import { separateNumWithComma } from "helpers/helpers";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

export const ProductsBlock = ({
  productName,
  productMinOrderQTY,
  productSellingOutOfStock,
  productsList,
  control,
  currentDraftProducts,
  showMSRP,
}) => {
  return (
    <Grid pt={"13px"} xs={12} item>
      <ProductVariationsHeader />
      <Stack>
        {!!productsList?.[0]?.id &&
          productsList.map(
            (
              {
                id,
                retailPrice,
                wholesalePrice,
                size,
                color,
                inventory,
                itemsPerCase,
                sku,
                photos,
                type,
              },
              index
            ) => {
              const isNonInventory =
                type === PRODUCT_TYPE_INVENTORY.non_inventory;

              const alreadyAddedDraft =
                isNonInventory || productSellingOutOfStock
                  ? 0
                  : currentDraftProducts?.find((p) => p?.id === id)?.quantity ||
                    0;

              return (
                <ProductVariationItem
                  key={id}
                  index={index}
                  control={control}
                  productName={productName}
                  variations={`${sku} ${size ? ` / ${size}` : ""} ${
                    color ? ` / ${color}` : ""
                  } `}
                  itemPrice={`$${separateNumWithComma(
                    wholesalePrice / itemsPerCase
                  )}`}
                  retailPrice={retailPrice}
                  productsList={productsList}
                  itemsPerCase={itemsPerCase}
                  wholesalePrice={`$${separateNumWithComma(wholesalePrice)}`}
                  minOrderQTY={productMinOrderQTY}
                  onHand={inventory?.onHand}
                  allocated={inventory?.allocated}
                  sellingOutOfStock={productSellingOutOfStock}
                  photoLink={photos?.[0]?.fileName || ""}
                  alreadyAddedDraft={alreadyAddedDraft}
                  showMSRP={showMSRP}
                  type={type}
                />
              );
            }
          )}
      </Stack>
    </Grid>
  );
};

ProductsBlock.propTypes = {
  productId: string,
  productName: string,
  productMinOrderQTY: number,
  productSellingOutOfStock: bool,
  productsList: array,
  control: object,
  currentDraftProducts: array,
  orderDirect: array,
  showMSRP: bool,
};
ProductsBlock.defaultProps = {
  productId: "",
  productName: "",
  productMinOrderQTY: 0,
  productSellingOutOfStock: false,
  productsList: [],
  currentDraftProducts: [],
  orderDirect: [],
  showMSRP: false,
};
