import { useShowProductsWithoutLogIn } from "helpers/hooks";
import { any } from "prop-types";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const { isShowProductsWithoutLogIn } = useShowProductsWithoutLogIn();

  if (!currentUser && !isShowProductsWithoutLogIn) {
    return <Navigate to="/login" />;
  }

  // if (!currentUser?.legalName) {
  //   return <Navigate to="/profile" />;
  // }

  return children;
};

PrivateRoute.propTypes = {
  children: any,
};
PrivateRoute.defaultTypes = {
  children: null,
};
