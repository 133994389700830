import { PER_PAGE_COUNT_PRODUCTS } from "../../utils/constants";

export const SET_PAGINATION = "SET_PAGINATION";

const setPagination = (payload) => ({
  type: SET_PAGINATION,
  payload,
});

export const setPaginationAction = (data) => {
  return (dispatch) => {
    dispatch(setPagination(data));
  };
};

export const resetPaginationAction = () => {
  return (dispatch) => {
    dispatch(
      setPagination({
        currentPage: 1,
        allPages: null,
        resultsPerPage: PER_PAGE_COUNT_PRODUCTS,
      })
    );
  };
};
