import { ConfirmContext } from "pages/MasterPage/MasterPage";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { singleOrderDirectSelector } from "redux/selectors/auth";

export const useCurrentUser = () => {
  const supplier = useSelector(
    ({ distributors }) => distributors.selectedSupplier
  );
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const singleOrderDirect = useSelector(singleOrderDirectSelector);

  const { distributor } = singleOrderDirect || {};

  const { appWithoutLogInState } = useContext(ConfirmContext);

  const currentDistr =
    distributor || supplier || appWithoutLogInState?.distributor;

  return {
    currentDistr,
    hasMultipleSuppliers: currentUser?.orderDirect?.length > 1,
    selectedSupplier: supplier,
    hasSelectedSupplier: supplier && !!Object.keys(supplier).length,
  };
};
