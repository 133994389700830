export const DRAWERS = {
  FILTER_DRAWER: "filterDrawer",
  HELP_DRAWER: "helpDrawer",
  NOTE_DRAWER: "noteDrawer",
  CONTACT_DRAWER: "contactDrawer",
  ADD_CARD_DRAWER: "addCardDrawer",
  SELECT_CARD_DRAWER: "selectCardDrawer",
  EDIT_CARD_DRAWER: "editCardDrawer",
  VIEW_PAYMENT_INFO_DRAWER: "viewPaymentInfoDrawer",
  VIEW_FULFILLMENT_DRAWER: "viewFulfillmentDrawer",
  USER_LOCATIONS_DRAWER: "userLocationsDrawer",
};
