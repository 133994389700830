/* eslint-disable react/prop-types */
import React from "react";

export const PrimaryIcon = ({
  fill,
  width = "39",
  height = "13",
  fontSize = "11",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 61.004 18.896"
    >
      <path
        id="Path_5228"
        data-name="Path 5228"
        d="M3.135,0H57.869C59.6,0,61,1.7,61,3.806V15.09c0,2.1-1.4,3.806-3.135,3.806H3.135C1.4,18.9,0,17.192,0,15.09V3.806C0,1.7,1.4,0,3.135,0Z"
        fill={fill || "#47a06d"}
        opacity="0.764"
      />
      <text
        id="PRIMARY"
        transform="translate(6.359 13.636)"
        fill="#fff"
        fontSize={fontSize}
        fontFamily="OpenSans, Open Sans"
      >
        <tspan x="0" y="0">
          PRIMARY
        </tspan>
      </text>
    </svg>
  );
};

// eslint-disable-next-line react/prop-types
export const MobilePrimaryIcon = () => {
  return (
    <svg
      width="50"
      height="19"
      viewBox="0 0 50 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="19" rx="4" fill="#409A65" />
      <text
        id="PRIMARY"
        transform="translate(4 13.636)"
        fill="#fff"
        fontSize="10"
        fontFamily="Inter"
      >
        <tspan x="0" y="0">
          PRIMARY
        </tspan>
      </text>
    </svg>
  );
};

export const ExpiredIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="13"
      viewBox="0 0 39 13"
    >
      <g
        id="Group_7719"
        data-name="Group 7719"
        transform="translate(-1133 -310)"
      >
        <rect
          id="Rectangle_11511"
          data-name="Rectangle 11511"
          width="39"
          height="13"
          rx="2"
          transform="translate(1133 310)"
          fill="#ff3737"
        />
        <text
          id="DELETED"
          transform="translate(1138 319)"
          fill="#fff"
          fontSize="7"
          fontFamily="OpenSans-Regular, Open Sans"
        >
          <tspan x="1" y="0">
            EXPIRED
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export const DeletedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="13"
      viewBox="0 0 39 13"
    >
      <g
        id="Group_7719"
        data-name="Group 7719"
        transform="translate(-1133 -310)"
      >
        <rect
          id="Rectangle_11511"
          data-name="Rectangle 11511"
          width="39"
          height="13"
          rx="2"
          transform="translate(1133 310)"
          fill="#ff3737"
        />
        <text
          id="DELETED"
          transform="translate(1138 319)"
          fill="#fff"
          fontSize="7"
          fontFamily="OpenSans-Regular, Open Sans"
        >
          <tspan x="1" y="0">
            DELETED
          </tspan>
        </text>
      </g>
    </svg>
  );
};
