import {
  DELETE_CURRENT_USER,
  SET_AUTH_LOADING,
  SET_CURRENT_USER,
} from "../actions/auth";

const initialState = {
  currentUser: null,
  loading: false,
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...payload,
          country: payload?.orderDirect?.[0]?.distributor?.country,
        },
      };
    case DELETE_CURRENT_USER:
      return { ...state, currentUser: null };

    case SET_AUTH_LOADING:
      return { ...state, loading: payload };

    default:
      return state;
  }
};

export default authReducer;
