import { func, number, object } from "prop-types";

import { ContentBlock } from "./components/ContentBlock";

import { useUserLocationDrawer } from "./useUserLocationDrawer";

import { useStyles } from "../../../../../../components/ContactDrawer/styles";

import { Button, Drawer, Grid } from "@mui/material";
import { DrawerHeader } from "components/ContactDrawer/components/DrawerHeader";

export const UserLocationDrawer = ({
  locationIndex,
  handleSave,
  formField,
  control,
  setValue,
  handleDeleteLocation,
  setEditLocationIndex,
  handleChangeDefault,
  resetFunc,
  handleSubmit,
}) => {
  const classes = useStyles();

  const {
    onClose,
    FIELD_DATA,
    isNewLocation,
    isDrawerOpened,
    defaultLocationValue,
  } = useUserLocationDrawer({
    locationIndex,
    formField,
    setValue,
    handleDeleteLocation,
    fieldName: `locations.${locationIndex}`,
    setEditLocationIndex,
    resetFunc,
  });

  return (
    <Drawer
      anchor="bottom"
      open={isDrawerOpened}
      onClose={onClose}
      PaperProps={{
        className: classes.drawerWrapper,
        sx: { minHeight: "80vh" },
      }}
    >
      <Grid sx={{ width: "100%" }}>
        <DrawerHeader
          {...{
            handleClose: onClose,
            title: "Locations",
            btnElement: (
              <Button
                onClick={handleSubmit(handleSave)}
                variant="outlined"
                className={classes.actionBtn}
              >
                Save
              </Button>
            ),
          }}
        />
        <ContentBlock
          {...{
            FIELD_DATA,
            control,
            isNewLocation,
            defaultLocationValue,
            checkboxValue: !!formField?.locations?.[locationIndex]?.defaultLoc,
            handleChangeDefault: () => {
              handleChangeDefault(locationIndex);
            },
            handleRemoveLocation: () => {
              handleDeleteLocation(locationIndex);
            },
          }}
        />
      </Grid>
    </Drawer>
  );
};

UserLocationDrawer.propTypes = {
  locationIndex: number,
  handleSave: func,
  formField: object,
  control: object,
  resetFunc: func,
  setValue: func,
  handleDeleteLocation: func,
  handleSubmit: func,
  handleChangeDefault: func,
  setEditLocationIndex: func,
};
