import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  inputLabel: {
    // fontSize: "17px",
    fontSize: "15px",
    fontWeight: 300,
    // top: -3,
    color: "#B5B5AC",
    // "&.Mui-focused": { top: -3 },
  },

  input: {
    height: "38px",
    // fontSize: "17px",
    fontSize: "15px",
    "& input": { padding: "7px 14px" },
    "&:hover:not(.Mui-focused)": {
      "&& fieldset": {
        borderColor: "rgba(194, 194, 194, 0.5)",
      },
    },
  },
}));

export default useStyles;
