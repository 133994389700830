import { string, object } from "prop-types";
import { Box } from "@mui/material";
import {
  DeletedIcon,
  ExpiredIcon,
  PrimaryIcon,
  MobilePrimaryIcon,
} from "../Icons";
import { useMediaDevice } from "hooks/useMediaDevice";

const defaultStyle = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
};

export const StyledLabel = ({ text, style, fill, ...props }) => {
  const { isMobile } = useMediaDevice();
  const setSrc = (text) => {
    switch (text) {
      case "expired":
        return <ExpiredIcon />;
      case "deleted":
        return <DeletedIcon />;
      case "primary":
        return isMobile ? <MobilePrimaryIcon /> : <PrimaryIcon fill={fill} />;
      default:
        return <PrimaryIcon />;
    }
  };

  return (
    <Box
      sx={{
        ...defaultStyle,
        ...style,
      }}
      {...props}
    >
      {setSrc(text)}
    </Box>
  );
};

StyledLabel.propTypes = {
  text: string,
  style: object,
  fill: string,
};
StyledLabel.defaultProps = {
  text: "primary",
};

export default StyledLabel;
