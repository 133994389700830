import { string, bool, func } from "prop-types";
import { Box, Button, Stack, Typography } from "@mui/material";
import { PrimaryIcon } from "components/Icons";

export const LocationItem = ({ name, value, isPrimary, onEdit }) => {
  return (
    <Box
      sx={{
        border: "1px solid #00000010",
        borderRadius: "10px",
        p: "12px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        direction="row"
        gap="6px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          fontSize={12}
          fontWeight={600}
          color="#000"
          display="flex"
          alignItems="center"
          gap={1}
          noWrap
          component={Typography}
        >
          {name} {isPrimary && <PrimaryIcon width={52} height={16} />}
        </Box>

        {!!onEdit && (
          <Button
            onClick={onEdit}
            variant="contained"
            sx={{
              backgroundColor: "transparent !important",
              minWidth: "fit-content",
              minHeight: "fit-content",
              fontSize: "14px",
              fontWeight: 600,
              boxShadow: "none !important",
              color: "#409A65",
              p: 0,
            }}
          >
            Edit
          </Button>
        )}
      </Stack>
      <Box
        fontSize={14}
        fontWeight={400}
        color="#00000060"
        noWrap
        component={Typography}
      >
        {value}
      </Box>
    </Box>
  );
};

LocationItem.propTypes = {
  name: string,
  value: string,
  isPrimary: bool,
  onEdit: func,
};
