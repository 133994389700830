import { useContext } from "react";
import {} from "prop-types";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { CartContext } from "pages/CartPage/CartPage";
import { Controller } from "react-hook-form";
import { LargeCheckbox } from "components";
import { isValidFulfillByDate, separateNumWithComma } from "helpers/helpers";
import StyledDatePicker from "components/StyledDatePicker/StyledDatePicker";
import moment from "moment-timezone";

export const AccDetailFooter = () => {
  const {
    setError,
    clearErrors,
    setValue,
    currentUser,
    casesCount,
    control,
    calculatedDelivery,
    calculatedTotal,
    customerDiscount,
    grandTotalWithDiscount,
  } = useContext(CartContext);

  const { timeZone } = currentUser || {};

  return (
    <Box p={3}>
      <Grid container>
        <Grid xs={7} item>
          <Box pb={1}>
            <Typography fontSize={15} color="#6A6A6A">
              ORDER NOTES
            </Typography>
          </Box>
          <Box pr={2}>
            <Controller
              render={({ field }) => (
                <TextField
                  placeholder="Add order note or delivery instructions"
                  multiline
                  rows={5}
                  fullWidth
                  InputProps={{
                    sx: {
                      fontSize: 13,
                      fontWeight: 300,
                      paddingTop: "10px",
                      paddingBottom: "11px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      pb: "6px",
                    },
                    endAdornment: (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Controller
                          render={({ field }) => (
                            <LargeCheckbox
                              size={18}
                              label="Notify your sales rep(s)"
                              labelSx={{ fontSize: "15px" }}
                              checked={!!field.value}
                              {...field}
                            />
                          )}
                          control={control}
                          name="notifyRepresentatives"
                        />
                        <Controller
                          render={({ field, fieldState: { error } }) => (
                            <StyledDatePicker
                              dateLibInstance={moment}
                              label="Requested fulfill date"
                              value={moment.tz(field.value, timeZone)}
                              inputSx={{
                                fontSize: "12px",
                                color: "#5F6267",
                                fontWeight: 400,
                              }}
                              disablePast
                              textFieldProps={{
                                fullWidth: true,
                                noErrorMessage: true,
                                inputProps: {
                                  placeholder: "-",
                                },
                                formSx: {
                                  maxWidth: "167px",
                                },
                              }}
                              error={error}
                              {...field}
                              onChange={(time, keyboardVal) => {
                                let isoTime = "";
                                if (keyboardVal) {
                                  if (
                                    !isValidFulfillByDate(keyboardVal, timeZone)
                                  ) {
                                    return setError("fulfillBy", {
                                      type: "fulfillByValidate",
                                      message: "Incorrect date",
                                    });
                                  } else {
                                    clearErrors("fulfillBy");
                                  }

                                  const formattedDate = moment
                                    .tz(keyboardVal, "MM/DD/YYYY", timeZone)
                                    .hours(0)
                                    .minutes(0)
                                    .seconds(0)
                                    .milliseconds(0)
                                    .utc()
                                    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

                                  const isValidFormattedDate =
                                    moment(formattedDate).isValid();

                                  isoTime =
                                    isValidFormattedDate && formattedDate
                                      ? formattedDate
                                      : "";
                                } else if (time) {
                                  isoTime = moment
                                    .tz(time, "MM/DD/YYYY", timeZone)
                                    .hours(0)
                                    .minutes(0)
                                    .seconds(0)
                                    .milliseconds(0)
                                    .utc()
                                    .toISOString();
                                  clearErrors("fulfillBy");
                                } else {
                                  if (!keyboardVal) {
                                    setValue("fulfillBy", null);
                                    clearErrors("fulfillBy");
                                    return;
                                  }
                                }
                                setValue("fulfillBy", isoTime);
                              }}
                            />
                          )}
                          name="fulfillBy"
                          control={control}
                        />
                      </Box>
                    ),
                  }}
                  // error={!!error}
                  {...field}
                />
              )}
              name="note.text"
              control={control}
            />
          </Box>
        </Grid>
        <Grid pr={1} pl={4} xs={5} item container>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
            }}
            xs={12}
            item
          >
            <Typography fontSize={15} fontWeight={300} color="#000">
              Cases ({casesCount}):
            </Typography>
            <Typography fontSize={15} fontWeight={400} color="#000">
              ${separateNumWithComma(calculatedTotal.toFixed(2) || 0)}
            </Typography>
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
            }}
            xs={12}
            item
          >
            <Typography fontSize={15} fontWeight={300} color="#000">
              Delivery:
            </Typography>
            <Typography fontSize={15} fontWeight={400} color="#000">
              {calculatedDelivery
                ? `$${separateNumWithComma(calculatedDelivery.toFixed(2) || 0)}`
                : "FREE"}
            </Typography>
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
            }}
            xs={12}
            item
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography fontSize={15} fontWeight={300} color="#000">
                Discounts:
              </Typography>
              {/* <Button
                sx={{
                  height: "22px",
                }}
                startIcon={<TagIcon width={13} height={13} />}
              >
                Add
              </Button> */}
            </Box>
            <Typography fontSize={15} fontWeight={400} color="#000">
              -${separateNumWithComma(customerDiscount?.toFixed(2) || 0)}
            </Typography>
          </Grid>

          {/* <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              py: 1,
            }}
            xs={12}
            item
          >
            <Box
              sx={{
                borderRadius: "4px",
                bgcolor: "#409A6520",
                px: 1.5,
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography fontSize={15} fontWeight={400} color="#409A65">
                Buy 5 cases, get 1 free
              </Typography>
              <CrossBigIcon size={9.85} color="#242424" />
            </Box>
          </Grid> */}

          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
            }}
            xs={12}
            item
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                py: 1,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  borderBottom: "0.5px solid #D5D9D9",
                }}
                component="span"
              />
            </Box>
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
            }}
            xs={12}
            item
          >
            <Typography fontSize={15} fontWeight={400} color="#000">
              Grand Total
            </Typography>
            <Typography fontSize={15} fontWeight={400} color="#000">
              ${separateNumWithComma(grandTotalWithDiscount?.toFixed(2) || 0)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

AccDetailFooter.propTypes = {};
AccDetailFooter.defaultProps = {};
