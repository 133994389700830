import { string, func, object } from "prop-types";

import { Button, Grid, Paper } from "@mui/material";
import { StyledTypography, OrderStatuses } from "../../../../../../components";
import { StyledImageBox } from "./StyledImageBox";
import { cl } from "./styles";
import { photoUrl } from "helpers/helpers";

export const VendorsItem = ({
  address,
  terms,
  orderDirect,
  onMessageSeller,
  distributor,
}) => {
  const {
    VendorsItem: { paper },
  } = cl;

  return (
    <Paper sx={paper} elevation={0} square component={Grid} container>
      <Grid xs={0.18} item />
      {/* Vendor */}
      <Grid display="flex" xs={3} item>
        <StyledImageBox src={photoUrl(distributor?.profilePhoto?.fileName)} />
        <Grid alignItems="center" container pl={3}>
          <StyledTypography
            style={{ fontSize: 14 }}
            text={distributor.name}
            size={10}
          />
          <StyledTypography
            text={address}
            size={10}
            style={{ fontSize: 14, fontWeight: 300 }}
          />
        </Grid>
      </Grid>
      {/* Minimum order */}
      <StyledTypography
        text={
          distributor.minimumOrderValueAmount > 0
            ? `Minimum $${distributor.minimumOrderValueAmount}`
            : "No minimum"
        }
        size={2.52}
        style={{ fontSize: 14 }}
      />
      {/* Products */}
      <StyledTypography
        text={`${distributor._count?.products} product${
          distributor._count?.products > 1 ? "s" : ""
        }`}
        size={1.9}
        style={{ fontSize: 14 }}
      />
      {/* Order Direct */}
      <Grid xs={1.72} item>
        <OrderStatuses type={orderDirect} />
      </Grid>
      {/* Terms */}
      <StyledTypography
        text={terms ? terms : "-"}
        size={1.2}
        style={{ fontSize: 14 }}
      />
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
        xs={1.48}
        item
      >
        <Button
          sx={{
            height: 26,
            fontSize: 14,
            fontWeight: 300,
            borderRadius: "10px",
          }}
          variant="outlined"
          color="grey"
          onClick={() => onMessageSeller(distributor)}
        >
          Profile
        </Button>
      </Grid>
    </Paper>
  );
};
VendorsItem.propTypes = {
  address: string,
  terms: string,
  orderDirect: string,
  onMessageSeller: func,
  distributor: object,
};
VendorsItem.defaultProps = {
  address: "",
  terms: "-",
  orderDirect: "",
  onMessageSeller: () => {},
};
