import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DRAWERS } from "constants/drawer";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { StyledTextField } from "components";
import { createSelector } from "@reduxjs/toolkit";
import { currentUserSelector } from "redux/selectors/auth";
import {
  cardMask,
  defaultValues,
} from "components/AddCardDialog/AddCardDialog.constants";
import { addCustomerCardService } from "services/stripe";
import { error, success } from "helpers/notifications";
import { validationSchema } from "components/AddCardDialog/AddCardDialog.validations";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const useAddCardDrawer = ({
  isOpen,
  customerCreditCards,
  customerId,
  handleSave,
}) => {
  const { currentUser } = useSelector(selector);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    toggleUnit: false,
    edit: false,
    loading: false,
    cardValue: "",
    cardValueType: "",
  });
  const billingAddress = currentUser.billingAddress;

  const hasCustomerDefaultCard = useMemo(() => {
    if (customerCreditCards) {
      return customerCreditCards.some((card) => card?.defaultMethod);
    }
  }, [customerCreditCards]);

  const setDefaultCreditCard = useMemo(() => {
    if (customerCreditCards) {
      return hasCustomerDefaultCard ? defaultValues?.defaultMethod : true;
    }
  }, [customerCreditCards, hasCustomerDefaultCard]);

  const onClose = useCallback(
    () =>
      dispatch(
        showCurrentDrawerAction({
          type: DRAWERS.ADD_CARD_DRAWER,
          show: false,
        })
      ),
    [dispatch]
  );

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      ...defaultValues,
      fid: Date.now(),
      billingAddress: billingAddress?.formatted_address
        ? billingAddress
        : defaultValues.billingAddress,
      defaultMethod: setDefaultCreditCard,
    },
    resolver: yupResolver(
      validationSchema({
        editingAddress: state.edit,
        isEdit: false,
        cardValueType: state.cardValueType,
      })
    ),
  });

  const currentUserHasBillingAddress = useMemo(() => {
    return !!currentUser?.billingAddress?.formatted_address;
  }, [currentUser?.billingAddress?.formatted_address]);

  useEffect(() => {
    if (!isOpen) return;
    reset({
      ...defaultValues,
      fid: Date.now(),
      billingAddress: billingAddress?.formatted_address
        ? billingAddress
        : defaultValues.billingAddress,
      defaultMethod: setDefaultCreditCard,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, reset, setDefaultCreditCard]);

  const getErrorMessage = (err) => {
    if (err === "Your card number is incorrect.") {
      setError("number", { message: err });
      return;
    }
    if (err === "Your card's security code is incorrect.") {
      setError("cvc", { message: err });
      return;
    }
    if (err === "Your card has expired.") {
      setError("exp", { message: err });
      return;
    }
    if (err === "Your card has insufficient funds.") {
      setError("number", { message: err });
      return;
    }

    error(err || "Something went wrong!");
    onClose();
  };

  const onSubmit = async (data) => {
    // eslint-disable-next-line no-console
    if (!currentUserHasBillingAddress) {
      setError("billingAddress", {
        message: "Please add a default Billing Address before saving.",
      });
      return;
    }

    const { name, exp, fid, defaultMethod, ...saveData } = data;
    const expSplit = data.exp.split(" / ");
    const dataCard = {
      ...saveData,
      expMonth: expSplit[0],
      expYear: "20" + expSplit[1],
      billingAddress: state.edit ? data.billingAddress : billingAddress,
      number: data.number.split(" ").join(""),
      name: name,
      defaultMethod: !!defaultMethod,
    };

    if (customerId) {
      setState((prop) => ({ ...prop, loading: true }));
      addCustomerCardService(customerId, dataCard)
        .then((res) => {
          handleSave(res, dataCard);
          success("The card was added!");
          onClose();
        })
        .catch((err) => {
          getErrorMessage(err?.response?.data?.error);
          // eslint-disable-next-line
          console.error(err);
        })
        .finally(() => {
          setState((prop) => ({ ...prop, loading: false }));
        });
    }
  };

  useEffect(() => {
    setState((prop) => ({
      ...prop,
      cardValueType:
        cardMask.find((m) => m.regex.test(+state.cardValue))?.cardtype ||
        "Default",
    }));
  }, [state.cardValue]);

  const FIELD_DATA = [
    {
      label: "Name on card",
      name: "name",
      Input: StyledTextField,
      placeholder: "Full Name",
    },
    {
      label: "Card number",
      name: "number",
      Input: NumberFormat,
      customInput: StyledTextField,
      placeholder: "1234 1234 1234 1234",
      format: "#### #### #### ####",
    },
    {
      label: "Expiration Date",
      name: "exp",
      Input: NumberFormat,
      customInput: StyledTextField,
      placeholder: "MM / YY",
      isAllowed: (values) => {
        if (values?.value.length <= 4) return true;
        return false;
      },
      format: (value) => {
        if (value.length >= 3) {
          return `${value.slice(0, 2)} / ${value.slice(2)}`;
        }
        return value;
      },
    },
    {
      label: "CVC",
      name: "cvc",
      Input: StyledTextField,
      placeholder: "123",
    },
  ];

  return {
    onClose,
    FIELD_DATA,
    control,
    handleSubmit,
    onSubmit,
    errors,
    loading: state.loading,
  };
};
