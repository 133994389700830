import axios from "axios";

export const getDraftsListService = async () => {
  return axios.get(`/drafts/list`).then((res) => res.data);
};

export const addDraftService = async (data, params) => {
  return axios.post(`/drafts`, data, { params }).then((res) => res.data);
};

export const updateDraftService = async (data, params, id) => {
  return axios.put(`/drafts/${id}`, data, { params }).then((res) => res.data);
};

export const getCartDraftDataService = async (params) => {
  return await axios
    .get("/drafts/cart-data", { params })
    .then((res) => res.data);
};

export const deleteDraftService = async (id, distributor_id) => {
  return axios
    .delete(`/drafts/${id}`, { params: { distributor_id } })
    .then((res) => res.data);
};
