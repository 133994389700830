import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 19px 0px 26px",
    alignItems: "center",
  },

  title: {
    fontSize: "17px",
    color: "#000000",
  },

  dialogContent: {
    width: "361px",
    padding: "0px 14px 26px !important",
  },

  contentTitle: {
    fontSize: "16px",
    color: "#363531",
    fontWeight: "600",
  },

  textFieldTitle: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#000000",
    marginBottom: "7px",
  },

  defaultMethodText: {
    fontSize: "11px",
    color: "#5F6267",
  },

  contentDivider: {
    // display
    marginLeft: "-36px",
    marginRight: "-36px",
  },

  billingDate: {
    fontSize: "13px",
    fontWeight: 400,
    color: "#363531",
    lineHeight: "1",
  },

  dialogActions: {
    padding: "20px",
  },

  addressTitle: {
    fontSize: "13px",
    color: "#363531",
  },

  toggleButton: {
    color: "#6A6A6A",
    height: "32px",
    width: "20px",
    "&.Mui-selected": {
      backgroundColor: "rgba(71, 160, 109, 0.1)!important",
      borderColor: "#47A06D",
      color: "#6A6A6A",
    },
  },
}));

export default useStyles;
