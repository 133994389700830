export const cl = {
  AddressesBlock: {
    btn: {
      width: "clamp(135px, 10vw, 166px)",
      px: "clamp(0px, 0.1vw, 8px)",
      height: "38px",
    },
    btnText: {
      fontSize: "clamp(13px, 0.8vw, 15px)",
      fontWeight: 500,
      color: "#000",
    },
  },
  AddressItem: {
    wrapper: {
      width: "clamp(228px, 16.5vw, 280px)",
      height: "84px",
      minHeight: "84px",
    },
    divider: {
      position: "absolute",
      left: 0,
      top: "5px",
      bottom: "5px",
      width: "1px",
      border: "1px solid #409A65",
    },
    btn: { p: 0, minWidth: "36px" },
    btnText: { fontSize: 14, color: "#47A06D" },
    text: { fontSize: 18, color: "#363531" },
    textThin: { fontSize: 18, fontWeight: 300, color: "#363531" },
  },
  FooterBlock: {
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      pt: 3,
    },
    titleLeftSide: { fontSize: 15, fontWeight: 400, color: "#6A6A6A" },
    textNote: {
      width: 500,
      fontSize: 13,
      fontWeight: 300,
      color: "#707070",
      "& .MuiOutlinedInput-root": {
        "& .Mui-focused fieldset": {
          borderColor: "#000",
        },
      },
    },
    totalWrapper: {
      width: 444,
      mr: 2.5,
      display: "flex",
      flexDirection: "column",
      gap: 1.8,
    },
    textThin: { fontSize: 15, fontWeight: 300, color: "#000" },
    text: { fontSize: 15, fontWeight: 400, color: "#000" },
    label: {
      display: "flex",
      backgroundColor: "rgba(64, 154, 102, 0.1)",
      borderRadius: "4px",
      width: "fit-content",
      px: 1.2,
      fontSize: 15,
      fontWeight: 400,
      color: "#409A65",
    },
    divider: {
      height: "1px",
      borderTop: "1px solid #D5D9D9",
    },
  },
  OrderItem: {
    paper: {
      display: "flex",
      alignItems: "center",
      height: 74,
      minHeight: 74,
      maxHeight: 74,
      borderRadius: "0 0 4px 4px",
      "&:not(:last-child)": {
        borderBottom: "1px solid #D5D9D9",
        borderRadius: 0,
      },
    },
    img: {
      width: "53px",
      minWidth: "53px",
      height: "53px",
      maxWidth: "53px",
    },
    productWrapper: {
      pl: 2,
      display: "flex",
    },
    productNameWrapper: {
      width: "calc(100% - 53px)",
      pl: 2,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    totalWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      pr: 2.5,
    },
    text: { fontSize: 14, fontWeight: 400, color: "#1C1C19" },
    textThin: { fontSize: 14, fontWeight: 300, color: "#1C1C19" },
    textTotal: { fontSize: 15, fontWeight: 400, color: "#000" },
    textThinTotal: { fontSize: 12, fontWeight: 300, color: "#000" },
    label: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid #DBE2ED",
      borderRadius: "12px",
      backgroundColor: "#F7F7F7",
      minWidth: 93,
      maxWidth: "fit-content",
      height: 42,
      fontSize: 21,
      fontWeight: 300,
      px: 1,
      color: "#363531",
    },
  },
};
