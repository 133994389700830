import { bool, func } from "prop-types";

import { useStyles } from "../styles";

import { Button } from "@mui/material";

export const ActionBlock = ({ handleConfirm, disabledProceed }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.invoiceBtn}
      onClick={handleConfirm}
      disabled={disabledProceed}
    >
      Process Payment
    </Button>
  );
};

ActionBlock.propTypes = {
  handleConfirm: func,
  disabledProceed: bool,
};
