import React from "react";
/* eslint-disable react/prop-types */
export const ArrowDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      id="caret"
    >
      <path d="M0 2l4 4 4-4H0z"></path>
    </svg>
  );
};
