import { Box } from "@mui/material";
import { RegistrationCard } from "./RegistrationCard";
import { REGISTRATION_CARD_ITEMS } from "./RegistrationBottomBlock.constants";

export const RegistrationBottomBlock = () => {
  return (
    <Box
      sx={{
        // height: "280px",
        // maxHeight: "280px",
        py: "2vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
        borderTop: "1px solid rgba(0, 0, 0, 0.2)",
      }}
    >
      {REGISTRATION_CARD_ITEMS.map(({ id, name, text }) => (
        <RegistrationCard key={id} name={name} text={text} />
      ))}
    </Box>
  );
};
