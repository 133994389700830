export const useViewOrderInfoDrawer = ({ orderRefunds }) => {
  const getCardTitle = (paymentType) => {
    switch (paymentType) {
      case "CASH":
        return "Cash (COD)";
      case "CREDIT_CARD":
        return "Credit card";
      case "CHECK":
        return "Check";
      default:
        return "Credit card";
    }
  };

  const handleIsRefund = (id) => {
    if (!orderRefunds.length) return false;
    return !!orderRefunds.find((payment) => payment.id === id);
  };

  return {
    getCardTitle,
    handleIsRefund,
  };
};
