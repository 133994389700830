import { array, bool, func, number, shape, string } from "prop-types";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { Box, CircularProgress, Stack, Typography } from "@mui/material";

import { getFormattedDate, separateNumWithComma } from "helpers/helpers";
import { Loader } from "components";
import { MobileOrdersTabItem } from "./Table/MobileOrdersTabItem";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  createdBy,
  handleViewOrder,
  setTotal,
} from "pages/OrdersPage/Orders.helpers";

export const MobileOrdersTab = ({
  ordersState,
  timeZone,
  handleFetchMore,
  hasActiveFilter,
}) => {
  const { loading, list, count, page } = ordersState || {};

  const navigate = useNavigate();

  const componentHeight = `calc(100svh - ${
    hasActiveFilter ? "160px" : "118px"
  })`;

  return loading && page === 1 ? (
    <Loader isLoading={loading} />
  ) : (
    <Stack
      id="orders-page-wrapper"
      sx={{
        height: componentHeight,
        overflowY: "auto",
        width: "100%",
        "&::-webkit-scrollbar": {
          display: "none",
          width: "0px !important",
        },
      }}
    >
      {!list?.length && !loading ? (
        <Box
          sx={{
            height: componentHeight,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontSize={18}>No orders found</Typography>
        </Box>
      ) : (
        <InfiniteScroll
          dataLength={list.length}
          next={handleFetchMore}
          loader={
            <CircularProgress sx={{ ml: "46%", mt: "15px" }} size="30px" />
          }
          hasMore={list.length < count}
          scrollableTarget="orders-page-wrapper"
        >
          {list.map(
            ({
              id,
              customId,
              createdAt,
              totalQuantity,
              totalRawAmount,
              totalDiscountValue,
              deliveryFee,
              paymentStatus,
              totalDelivered,
              distributor,
              createdType,
              deliveryStatus,
              type,
              salesDuplicate,
              orderStatus,
            }) => (
              <MobileOrdersTabItem
                key={id}
                id={id}
                type={type}
                isCanceledOrder={orderStatus === "CANCELED"}
                salesDuplicate={salesDuplicate}
                customId={customId?.customId}
                supplier={distributor?.name}
                ordered={moment(getFormattedDate(createdAt, timeZone)).format(
                  "MMM D, YY [at] h:mm a"
                )}
                createdBy={createdBy(createdType, distributor?.accountType)}
                total={`$${separateNumWithComma(
                  setTotal(totalRawAmount, totalDiscountValue, deliveryFee)
                )}`}
                deliveryStatus={deliveryStatus}
                payment={paymentStatus}
                fulfilled={`${totalDelivered} of ${totalQuantity}`}
                handleViewOrder={() =>
                  handleViewOrder({
                    orderId: id,
                    navigate,
                    orderNumber: customId?.customId,
                    distributor,
                  })
                }
              />
            )
          )}
        </InfiniteScroll>
      )}
    </Stack>
  );
};

MobileOrdersTab.propTypes = {
  ordersState: shape({
    loading: bool,
    list: array,
    count: number,
    page: number,
  }),
  timeZone: string,
  handleFetchMore: func,
  hasActiveFilter: bool,
};

MobileOrdersTab.defaultProps = {
  ordersState: {
    loading: false,
    list: [],
    count: 0,
    page: 1,
  },
  timeZone: "",
  handleFetchMore: () => {},
  hasActiveFilter: false,
};
