import { array } from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import CarriersItem from "./CarriersItem";
import { cl } from "./styles";
import SectionHeader from "../SectionHeader";

const CarriersTab = ({ shippingCarriers }) => {
  const {
    CarriersTab: { stack, text },
  } = cl;

  return (
    <Box>
      <SectionHeader title="Carrier Name" />
      {shippingCarriers?.length > 0 ? (
        shippingCarriers.map((carrier) => {
          return <CarriersItem key={carrier.name} carrier={carrier} />;
        })
      ) : (
        <Stack sx={stack} id="product-tags-scroll">
          <Typography sx={text}>No carriers created yet</Typography>
        </Stack>
      )}
    </Box>
  );
};

CarriersTab.propTypes = { shippingCarriers: array };

export default CarriersTab;
