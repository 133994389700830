import { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import { separateNumWithComma } from "helpers/helpers";
import { ProductContext } from "pages/ProductsPage/components/ProductDetailsPage/ProductDetailsPage";
import { ProductQtySelect } from "pages/ProductsPage/components/MobileProductCard/components/ProductQtySelect";

const width = {
  small: 75,
  medium: 120,
};

export const PriceBlock = () => {
  const {
    setShowMSRP,
    currentDraft,
    currentProduct,

    isShowProductsWithoutLogIn,
    isLowerThanMOQ,
    productQty,
    setProductQty,
    productSellingOutOfStock,
    isAdded,
    productMinOrderQTY,
    showMOQTooltip,
    showStockTooltip,
  } = useContext(ProductContext);

  const { wholesalePrice, itemsPerCase, retailPrice } = currentProduct || {};

  const [isOutOfStock, setIsOutOfStock] = useState(null);

  return currentProduct ? (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box
        width={`calc(100% - ${isOutOfStock ? width.medium : width.small}px)`}
        display="flex"
        flexDirection="column"
      >
        <Box>
          <Typography fontSize={18} fontWeight={600} color="#000" noWrap>
            {`$${separateNumWithComma(wholesalePrice)}`}
          </Typography>
        </Box>

        <Box>
          <Typography fontSize={12} fontWeight={400} color="#00000040" noWrap>
            Item Price{" "}
            {`$${separateNumWithComma(wholesalePrice / itemsPerCase)}`}{" "}
            {!!setShowMSRP &&
              `(MSRP ${separateNumWithComma(retailPrice / itemsPerCase)})`}
          </Typography>
        </Box>

        <Box>
          <Typography fontSize={12} fontWeight={400} color="#00000040" noWrap>
            Pack of {itemsPerCase}
          </Typography>
        </Box>
      </Box>

      {!isShowProductsWithoutLogIn && (
        <Box width={`${isOutOfStock ? width.medium : width.small}px`}>
          <ProductQtySelect
            {...{
              isLowerThanMOQ,
              product: currentProduct,
              currentDraftProducts: currentDraft?.data?.productsData,
              productQty,
              setProductQty,
              productSellingOutOfStock,
              isAdded,
              minOrderQTY: productMinOrderQTY,
              showMOQTooltip: !!showMOQTooltip,
              showStockTooltip,
              setIsOutOfStock,
            }}
            stylesProps={{
              height: "41px",
              fontSize: "14px",
            }}
          />
        </Box>
      )}
    </Box>
  ) : null;
};
