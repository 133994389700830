import { useShowProductsWithoutLogIn } from "helpers/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getConnectedDistributorsService } from "services/distributors";
import { SCROLL_LIMIT_DISTRIBUTORS } from "utils/constants";

export const useSuppliers = ({ checkedSupplier }) => {
  const { pathname } = useLocation();
  const { isShowProductsWithoutLogIn } = useShowProductsWithoutLogIn();

  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const selectedSupplier = useSelector(
    ({ distributors }) => distributors.selectedSupplier
  );

  const skipDefaultFetch =
    !!currentUser?.orderDirect?.length && selectedSupplier && pathname === "/";

  const [suppliersState, setSuppliersState] = useState({
    loading: false,
    list: [],
    count: 0,
    checkedSupplier,
  });

  const getSortedSuppliersList = (params) => {
    if (isShowProductsWithoutLogIn) return;
    setSuppliersState((prev) => ({ ...prev, loading: true }));
    getConnectedDistributorsService(params)
      .then((res) => {
        setSuppliersState((prev) => ({
          ...prev,
          loading: false,
          list: res?.rows ? res?.rows : [],
          count: res?.count,
        }));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err?.message);
        setSuppliersState((prev) => ({ ...prev, loading: false }));
      });
  };

  useEffect(() => {
    if (skipDefaultFetch) return;
    getSortedSuppliersList({
      params: {
        limit: SCROLL_LIMIT_DISTRIBUTORS,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    suppliersState,
    getSortedSuppliersList,
  };
};
