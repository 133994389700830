import { updateState } from "helpers/helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  deleteCustomerCardService,
  getCustomerCardsService,
  updateCustomerCardService,
} from "services/stripe";
import moment from "moment";
import { pickBy } from "lodash";
import { error, success } from "helpers/notifications";
import { getFullAddressStripe } from "pages/CartPage/components/EditPaymentPopup/EditPaymentPopup.helpers";
import { useMediaDevice } from "hooks/useMediaDevice";

const initState = {
  creditCards: [],
  lastCardId: null,
  errors: null,
  tooltipCard: null,
  selectedCard: null,
  addCardOpen: false,
  loading: false,
  editCardOpen: false,
  editCard: null,
};

export const useCustomerCards = ({
  distributor,
  customerId,
  removeDraftCard,
  draftCardId,
  setCardToCart,
  resetCardCart = () => {},
}) => {
  const { isMobile } = useMediaDevice();
  const [cardsState, setCardsState] = useState(initState);
  const [cardForEdit, setCardForEdit] = useState(null);

  const updateCardsState = (newValues) => {
    updateState(setCardsState, newValues);
  };

  const handleFetchCards = useCallback(
    async (scrolling, setDefaultCard) => {
      if (!distributor) return;

      const params = { cursor: cardsState.lastCardId };
      try {
        updateCardsState({ loading: true });
        const { hasMore, rows } = await getCustomerCardsService(
          distributor.customers[0]?.id,
          params
        );
        updateCardsState({
          lastCardId: hasMore ? rows[rows?.length - 1]?.id : "",
          creditCards: scrolling
            ? [...cardsState.creditCards, ...rows]
            : [...rows],
          loading: false,
        });

        if (cardsState.selectedCard?.id) {
          const selectedCard = rows?.find(
            (c) => c.id === cardsState.selectedCard.id
          );
          setCardToCart(selectedCard, { shouldDirty: isMobile });
        }

        if (setDefaultCard && !draftCardId) {
          const defaultCard = rows?.find((card) => card?.defaultMethod);
          if (!defaultCard) return;
          updateCardsState({
            selectedCard: defaultCard,
          });
          setCardToCart(defaultCard, { shouldDirty: true });
        }
      } catch (err) {
        updateCardsState({ loading: false });
        error(err?.response?.data?.message);
      }
    },
    [
      cardsState.creditCards,
      cardsState.lastCardId,
      cardsState.selectedCard?.id,
      distributor,
      draftCardId,
      setCardToCart,
      isMobile,
    ]
  );

  const isCardExpired = useCallback((month, year) => {
    const cardDate = moment(`${year}.${month}`).format("yyyy.MM");

    return moment().diff(cardDate, "months") > 0;
  }, []);

  const setOpenTooltip = (tooltipCard) => {
    updateCardsState({ tooltipCard });
  };

  const {
    creditCards,
    tooltipCard,
    selectedCard,
    lastCardId,
    addCardOpen,
    loading,
    editCard,
    editCardOpen,
  } = useMemo(() => cardsState, [cardsState]);

  useEffect(() => {
    handleFetchCards(false, "setDefaultCard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor]);

  const selectDraftCard = useCallback(() => {
    if (!cardsState.creditCards.length || cardsState.loading) return;

    if (draftCardId) {
      const selectedCard = cardsState.creditCards?.find(
        (card) => card?.id === draftCardId
      );
      if (!selectedCard && !!resetCardCart) resetCardCart();
      updateCardsState({
        selectedCard,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cardsState.creditCards.length,
    draftCardId,
    resetCardCart,
    cardsState.loading,
  ]);

  useEffect(selectDraftCard, [selectDraftCard]);

  const handleCloseAddCard = () => {
    updateCardsState({ addCardOpen: false });
  };

  const handleOpenAddCard = () => {
    updateCardsState({ addCardOpen: true });
  };

  const handleDeleteCard = useCallback(
    async (id) => {
      try {
        updateCardsState({ loading: true });
        await deleteCustomerCardService(customerId, id);
        if (removeDraftCard) removeDraftCard(id);
        updateCardsState({ loading: false });
        handleFetchCards();
        success("Card deleted successfully.");
      } catch (err) {
        updateCardsState({ loading: false });
        error(err?.response?.data?.message);
      }
    },
    [customerId, handleFetchCards, removeDraftCard]
  );

  const selectCard = (id, onCallback) => {
    const foundCard = creditCards.find((c) => c.id === id);
    updateCardsState({ selectedCard: foundCard });
    onCallback && onCallback(foundCard);
  };

  const handleResetState = () => {
    updateCardsState({ selectedCard: null });
  };

  const handleCloseEditCard = () => {
    updateCardsState({ editCardOpen: false });
  };

  const handleOpenEditCard = (card) => {
    updateCardsState({ editCardOpen: true, editCard: card });
  };

  const handleSaveEditCard = () => {
    handleFetchCards();
  };

  const handleSetDefault = async (card) => {
    const addressFormatted = pickBy(
      {
        name: card?.billingAddress?.name,
        defaultMethod: true,
        expMonth: card?.expMonth,
        expYear: card?.expYear,
        number: card?.number,
        cvc: card?.cvc || null,
      },
      (value) => value !== null
    );
    const billingAddressFormatted = pickBy(
      {
        formatted_address:
          getFullAddressStripe(card?.billingAddress?.address) || null,
        zip:
          card?.billingAddress?.address?.zip ||
          card?.billingAddress?.address?.postal_code ||
          card?.billingAddress?.zip,
        city:
          card?.billingAddress?.address?.city ||
          card?.billingAddress?.city ||
          null,
        state:
          card?.billingAddress?.address?.state ||
          card?.billingAddress?.state ||
          null,
        street:
          card?.billingAddress?.address?.street ||
          card?.billingAddress?.address?.line1 ||
          card?.billingAddress?.street ||
          null,
        appartement:
          card?.billingAddress?.address?.appartement ||
          card?.billingAddress?.address?.line2 ||
          card?.billingAddress?.appartement ||
          null,
        lat: card?.billingAddress?.address?.lat || 0,
        lng: card?.billingAddress?.address?.lng || 0,
      },
      (value) => value !== null
    );

    try {
      updateCardsState({ loading: true });
      handleResetState();
      await updateCustomerCardService(customerId, card?.id, {
        ...addressFormatted,
        billingAddress: billingAddressFormatted,
      });
      await handleFetchCards(false, "setDefaultCard");
      success("Payment method updated successfully!");
    } catch (err) {
      error(err?.response?.data?.message);
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message);
    } finally {
      updateCardsState({ loading: false });
    }
  };

  return {
    handleFetchCards,
    creditCards,
    isCardExpired,
    tooltipCard,
    setOpenTooltip,
    selectedCard,
    lastCardId,
    addCardOpen,
    handleCloseAddCard,
    handleOpenAddCard,
    handleDeleteCard,
    selectCard,
    loading,
    handleResetState,
    editCard,
    editCardOpen,
    handleCloseEditCard,
    handleSaveEditCard,
    handleOpenEditCard,
    handleSetDefault,
    cardForEdit,
    setCardForEdit,
  };
};
