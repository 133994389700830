import { object, func } from "prop-types";
import GoogleMapReact from "google-map-react";
import { getCoordinatesService } from "../../services/googleMaps";
import {
  getAddressComponents,
  sliceCountry,
} from "../AddressField/AddressField.helpers";
import { MapPoint } from "../MapPoint/MapPoint";
import { mapOptions } from "../../utils/mapOptions";

export const GoogleMap = ({ handleSetAddress, address, customer }) => {
  const handleMapClick = ({ lat, lng }) => {
    getCoordinatesService(lat, lng).then((res) => {
      const loc = res.results[0];
      const { street, state, city, zip } = getAddressComponents(loc);
      const newAddress = {
        formatted_address: sliceCountry(loc?.formatted_address),
        lat: loc?.geometry?.location?.lat,
        lng: loc?.geometry?.location?.lng,
        street,
        state,
        city,
        zip,
      };
      handleSetAddress(newAddress);
    });
  };

  return (
    <GoogleMapReact
      defaultCenter={{
        lat: 40.72316765399142,
        lng: -73.99994350677083,
      }}
      center={{
        lat: address?.lat || 40.72316765399142,
        lng: address?.lng || -73.99994350677083,
      }}
      defaultZoom={12}
      // eslint-disable-next-line no-undef
      bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
      onClick={handleMapClick}
      options={{
        styles: mapOptions,
      }}
      yesIWantToUseGoogleMapApiInternals
    >
      {address?.lat && (
        <MapPoint
          customer={customer}
          address={address?.formatted_address}
          color="#a04747"
          lat={address?.lat}
          lng={address?.lng}
        />
      )}
    </GoogleMapReact>
  );
};

GoogleMap.propTypes = {
  handleSetAddress: func,
  address: object,
  customer: object,
};
