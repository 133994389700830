import { array, bool, number, oneOfType } from "prop-types";
import { Typography } from "@mui/material";
import { useRangePrices } from "helpers/hooks";

export const MSRPBlock = ({ isMultiple, prices }) => {
  const calcRangePrices = useRangePrices({ isMultiple, prices });

  return (
    <Typography fontSize={13} fontWeight={300} mr={1.25}>
      MSRP: {calcRangePrices}
    </Typography>
  );
};

MSRPBlock.propTypes = {
  isMultiple: bool,
  prices: oneOfType([array, number]),
};
MSRPBlock.defaultProps = { isMultiple: false, prices: [] };
