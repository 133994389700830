import { object, func, bool, number, array } from "prop-types";

import MobileProductCard from "../MobileProductCard";

import { Box, CircularProgress, Stack } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMobileProduct } from "./useMobileProduct";
import { useShowProductsWithoutLogIn } from "helpers/hooks";

export const MobileProductList = ({
  supplierApproved,
  showRetailPrice,
  productsState,
  handleSearch,
  hasProductsInCart,
  chipData,
  handleOpenProductProfile,
}) => {
  const {
    handleAddToCart,
    handleFetchMore,
    handleGetQTYFromDraft,
    handleIsAlreadyAdded,
    handleGetCurrentDraft,
    getDefaultProductValue,
  } = useMobileProduct({ handleSearch });

  const { isShowProductsWithoutLogIn } = useShowProductsWithoutLogIn();

  const hasChipFilter = !!Object.entries(chipData)?.flatMap(
    ([, value]) => value
  ).length;

  const lastProductsInView =
    hasProductsInCart && productsState.list.length >= productsState.count;

  return (
    <Stack
      id="page-wrapper"
      sx={{
        height: hasChipFilter ? "calc(100svh - 156px)" : "calc(100svh - 112px)",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
          width: "0px !important",
        },
      }}
    >
      <InfiniteScroll
        dataLength={productsState.list.length}
        next={handleFetchMore}
        loader={
          <CircularProgress
            sx={{ ml: "46%", mt: "15px", mb: "18px" }}
            size="30px"
          />
        }
        hasMore={productsState.list.length < productsState.count}
        scrollableTarget="page-wrapper"
        style={{
          paddingBottom: lastProductsInView ? "70px" : 0,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(auto-fill, minmax(167px, 1fr))",
              sm: "repeat(auto-fill, minmax(249px, 1fr))",
            },
            justifyContent: "center",
            gap: { xs: "9px", sm: "15px" },
            mb: "18px",
          }}
        >
          {productsState.list.map((product) => {
            if (product.childProducts.length) {
              return product.childProducts.map((item, index) => {
                const childItem = { ...item, distributor: product.distributor };
                return (
                  <Box key={index}>
                    <MobileProductCard
                      product={{
                        ...childItem,
                        parentProduct: { ...product, index },
                      }}
                      isChild
                      parentProduct={product}
                      supplierApproved={supplierApproved(childItem)}
                      showRetailPrice={showRetailPrice(childItem)}
                      {...{
                        handleAddToCart,
                        handleIsAlreadyAdded,
                        handleGetCurrentDraft,
                        getDefaultProductValue,
                        handleGetQTYFromDraft,
                        isShowProductsWithoutLogIn,
                        handleOpenProductProfile,
                      }}
                    />
                  </Box>
                );
              });
            }

            return (
              <Box key={product?.id}>
                <MobileProductCard
                  key={product?.id}
                  product={product}
                  supplierApproved={supplierApproved(product)}
                  showRetailPrice={showRetailPrice(product)}
                  {...{
                    handleAddToCart,
                    handleIsAlreadyAdded,
                    handleGetCurrentDraft,
                    getDefaultProductValue,
                    handleGetQTYFromDraft,
                    isShowProductsWithoutLogIn,
                    handleOpenProductProfile,
                  }}
                />
              </Box>
            );
          })}
        </Box>
      </InfiniteScroll>
    </Stack>
  );
};

MobileProductList.propTypes = {
  supplierApproved: func,
  showRetailPrice: func,
  handleSearch: func,
  productsState: object,
  productsList: array,
  suppliersCount: number,
  handleOpenProductProfile: func,
  filteredBySupplier: bool,
  hasProductsInCart: bool,
  chipData: object,
};
