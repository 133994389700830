import { RESET_DRAWERS, SHOW_CURRENT_DRAWER } from "redux/actions/uiState";

const initialState = {
  helpDrawer: false,
  filterDrawer: false,
  noteDrawer: false,
  contactDrawer: false,
  addCardDrawer: false,
  selectCardDrawer: false,
  editCardDrawer: false,
  viewPaymentInfoDrawer: false,
  viewFulfillmentDrawer: false,
  userLocationsDrawer: false,
};

const uiStateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_CURRENT_DRAWER:
      return {
        ...state,
        [payload.type]: payload.show,
      };
    case RESET_DRAWERS:
      return { state: initialState };

    default:
      return state;
  }
};

export default uiStateReducer;
