export const SHOW_CURRENT_DRAWER = "SHOW_CURRENT_DRAWER";
export const RESET_DRAWERS = "RESET_DRAWERS";

export const showCurrentDrawer = (payload) => ({
  type: SHOW_CURRENT_DRAWER,
  payload,
});

export const showCurrentDrawerAction = (drawerData) => {
  return (dispatch) => {
    dispatch(showCurrentDrawer(drawerData));
  };
};
