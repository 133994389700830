import { object, number, bool, func } from "prop-types";

import { useRangePrices } from "helpers/hooks";
import { DeleteIcon, MobileLockIcon, ReplaceIcon } from "components/Icons";

import { cl, useStyles } from "../styles";

import { Button, Stack, Typography } from "@mui/material";
import { separateNumWithComma } from "helpers/helpers";

export const ActionsBlock = ({
  product,
  supplierApproved,
  showRetailPrice,
  productQty,
  handleAddToCart,
  isAdded,
  disabledAdding,
  isAlreadyChangedAndAdded,
  isShowProductsWithoutLogIn,
  buttonProps = {},
  hideLeftSide = false,
}) => {
  const styles = useStyles({ isAdded });

  const { isMultiple, retailPrice, wholesalePrice } = product || {};

  const calcRangePrices = useRangePrices({
    isMultiple,
    prices: wholesalePrice,
  });

  const showLeftSide =
    !hideLeftSide && (supplierApproved || (showRetailPrice && retailPrice));

  const isActionBtnDisabled =
    disabledAdding ||
    (isAdded && !productQty) ||
    (!isAdded && (!productQty || disabledAdding));

  return (
    <Stack
      direction="row"
      sx={{ ...cl.actionWrapper, overflow: "hidden" }}
      width="100%"
    >
      {showLeftSide && (
        <Stack direction="column" maxWidth="calc(100% - 80px)">
          {supplierApproved && (
            <Typography noWrap sx={cl.priceText}>
              {calcRangePrices}
            </Typography>
          )}

          {showRetailPrice && retailPrice && (
            <Typography noWrap sx={cl.retailPrice}>
              Retail ${separateNumWithComma(retailPrice.toFixed(2))}
            </Typography>
          )}
        </Stack>
      )}

      {!isShowProductsWithoutLogIn ? (
        <Button
          variant="contained"
          className={styles.cartBtn}
          sx={{
            ...cl.addToCartBtn,
            ...(isAdded && cl.addedProductBtn),
            width: "72px",
            ml: "auto",
            ...buttonProps,
            ...(isAlreadyChangedAndAdded && cl.alreadyChangedProductBtn),
            ...(isActionBtnDisabled && cl.disabledActionBtn),
          }}
          disabled={isActionBtnDisabled}
          onClick={() =>
            handleAddToCart({
              product,
              productQty,
              isAdded,
              isAlreadyChangedAndAdded,
            })
          }
        >
          {isAdded && !isAlreadyChangedAndAdded && <DeleteIcon />}
          {isAdded && isAlreadyChangedAndAdded && <ReplaceIcon />}
          {isAlreadyChangedAndAdded
            ? "Replace"
            : isAdded
            ? "Remove"
            : "Add to cart"}
        </Button>
      ) : (
        <MobileLockIcon style={{ marginLeft: "auto" }} />
      )}
    </Stack>
  );
};

ActionsBlock.propTypes = {
  product: object,
  supplierApproved: bool,
  isShowProductsWithoutLogIn: bool,
  isAlreadyChangedAndAdded: bool,
  showRetailPrice: bool,
  productQty: number,
  handleAddToCart: func,
  isAdded: bool,
  disabledAdding: bool,
  buttonProps: object,
  hideLeftSide: bool,
};
