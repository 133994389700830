import { Box, Typography } from "@mui/material";

export const MobileTitle = () => {
  return (
    <Box>
      <Typography fontSize={18} fontWeight={600} color="#2F3040" noWrap>
        Account Profile
      </Typography>
    </Box>
  );
};
