import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { bool, func, number, object } from "prop-types";

import { CartContext } from "pages/CartPage/CartPage";
import { ActionBlock } from "./components/ActionBlock";
import { ProductImage } from "./components/ProductImage";
import { ProductNameBlock } from "pages/ProductsPage/components/MobileProductCard/components/ProductNameBlock";

import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

import { cl } from "./styles";

import { Stack, Typography } from "@mui/material";

export const CartProductItem = ({
  product,
  index,
  handleDelete,
  outOfStock,
}) => {
  const {
    wholesalePrice,
    quantity,
    name,
    color,
    size,
    photos,
    photo,
    parentProduct,
    sellingOutOfStock,
  } = useMemo(() => product, [product]);

  const getSellingOutOfStock = parentProduct
    ? parentProduct?.sellingOutOfStock
    : sellingOutOfStock;

  const { handleDecreaseQty, handleAddQty, setQuantity, control, setErrorQty } =
    useContext(CartContext);

  const [tooltipState, setTooltipState] = useState({
    open: false,
    title: "",
    type: "",
  });

  const onHand = product?.inventory?.onHand || 0;
  const allocated = product?.inventory?.allocated || 0;
  const getAvailableQTY = onHand - allocated;

  const minOrderQTY =
    product?.minOrderQTY ||
    product?.product?.minOrderQTY ||
    product?.parentProduct?.minOrderQTY ||
    product?.product?.parentProduct?.minOrderQTY;

  const handleTooltipState = useCallback(() => {
    if (minOrderQTY > quantity) {
      setErrorQty((prev) => ({ ...prev, [product?.id]: product }));
      return setTooltipState({
        open: true,
        title: `Minimum Quantity: ${minOrderQTY}`,
        type: "minimumOrderQuantity",
      });
    }

    if (
      product?.type === PRODUCT_TYPE_INVENTORY.inventory &&
      !getSellingOutOfStock &&
      quantity > getAvailableQTY
    ) {
      setErrorQty((prev) => ({ ...prev, [product?.id]: product }));
      return setTooltipState({
        open: true,
        title:
          getAvailableQTY <= 0
            ? "Out of stock"
            : `Only ${getAvailableQTY} items in stock`,
        type: "sellingOutOfStock",
      });
    }

    setErrorQty((prev) => ({ ...prev, [product?.id]: null }));
    return setTooltipState((prev) => ({
      ...prev,
      open: false,
    }));
  }, [
    getAvailableQTY,
    minOrderQTY,
    product,
    quantity,
    getSellingOutOfStock,
    setErrorQty,
  ]);

  useEffect(() => {
    handleTooltipState();
  }, [handleTooltipState, minOrderQTY, quantity]);

  return (
    <Stack
      direction="row"
      gap="12px"
      alignItems="center"
      sx={{ overflow: "hidden", width: "100%", height: "100%", py: "1px" }}
    >
      <ProductImage {...{ photos, product, photo }} />
      <Stack
        width="calc(100% - 98px)"
        sx={{
          "& .MuiTypography-root": {
            fontWeight: 400,
          },
        }}
      >
        <ProductNameBlock
          productName={name || parentProduct?.name}
          sku={null}
        />

        {(size || color) && (
          <Typography sx={cl.productDescription}>{`${size ? `${size}` : ""} ${
            color ? `${size ? "/" : ""} ${color}` : ""
          } `}</Typography>
        )}

        <ActionBlock
          {...{
            tooltipState,
            quantity,
            setQuantity,
            product,
            outOfStock,
            index,
            handleDecreaseQty,
            handleAddQty,
            control,
            wholesalePrice,
            handleDelete,
          }}
        />
      </Stack>
    </Stack>
  );
};

CartProductItem.propTypes = {
  product: object,
  index: number,
  handleDelete: func,
  outOfStock: bool,
};
CartProductItem.defaultProps = {};
