import DateIOAdapter from "@mui/lab/AdapterMoment";

export default function CustomDateAdapter(options) {
  const adapter = new DateIOAdapter(options);
  const constructDayObject = (day) => ({ charAt: () => day });

  return {
    ...adapter,

    getWeekdays() {
      const customWeekdays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
      return customWeekdays.map((day) => constructDayObject(day));
    },
  };
}
