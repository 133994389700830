import clsx from "clsx";
import { Fragment } from "react";
import { bool, func, object } from "prop-types";
import { useNavigate } from "react-router-dom";
import { deleteCurrentUser } from "redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { showCurrentDrawerAction } from "redux/actions/uiState";

import { DRAWERS } from "constants/drawer";
import { removeAllTokens } from "helpers/auth";
import { useShowProductsWithoutLogIn } from "helpers/hooks";
import { getAuthMenuUrls, getMenuUrls, getSecondMenuUrls } from "./constants";

import { NavLink } from "components";
import { MainLogoIcon } from "components/MainLogos";

import useStyles from "./styles";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, List, Stack } from "@mui/material";

export const MenuContent = ({
  open,
  setOpen,
  hasMultipleSuppliers,
  currentDistr,
  hasSelectedSupplier,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const { isShowProductsWithoutLogIn, distributor_name, previewLink } =
    useShowProductsWithoutLogIn();
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const { _count, orderDirect } = currentUser || {};
  const { brandLogo } = currentDistr || {};

  const handleCloseMenu = () => setOpen(false);

  const handleExitUser = () => {
    navigate(
      orderDirect[0]?.distributor?.storeName
        ? `/login/${orderDirect[0]?.distributor?.storeName}`
        : "/login"
    );
    handleCloseMenu();
    removeAllTokens();
    dispatch(deleteCurrentUser());
    window.location.reload();
  };

  const handleClickSignIn = () => {
    navigate(
      isShowProductsWithoutLogIn ? `/login/${distributor_name}` : "/login"
    );
    window.location.reload();
  };

  const handleOpenHelpDrawer = () =>
    dispatch(
      showCurrentDrawerAction({ type: DRAWERS.HELP_DRAWER, show: true })
    );

  const MOBILE_LIST_ITEMS = getMenuUrls({
    _count,
    hasMultipleSuppliers,
    hasSelectedSupplier,
    isShowProductsWithoutLogIn,
    previewLink,
  });

  const SECOND_LIST_ITEMS = getSecondMenuUrls({
    currentUser,
    onClick: handleOpenHelpDrawer,
    hasMultipleSuppliers,
    isShowProductsWithoutLogIn,
  });

  const AUTH_LIST_ITEMS = getAuthMenuUrls({
    handleExitUser,
    isShowProductsWithoutLogIn,
    currentUser,
    handleClickSignIn,
  });

  return (
    <Grid
      className={clsx(classes.menu, {
        [classes.activeMenu]: open,
      })}
    >
      <Stack className={classes.menuContentHeader}>
        {currentDistr && !hasMultipleSuppliers ? (
          <Box
            sx={{
              width: "100px",
              maxWidth: "100px",
              height: "24px",
              maxHeight: "24px",
              objectFit: "contain",
            }}
            src={brandLogo?.fileName}
            component="img"
          />
        ) : (
          <Box className={classes.logoWrapper}>
            <MainLogoIcon variant="primary" width={125} height={68} />
          </Box>
        )}
        <CloseIcon onClick={() => setOpen(false)} />
      </Stack>
      <Box className={classes.menuWrapper}>
        <List className={classes.menuContent}>
          {MOBILE_LIST_ITEMS.map(
            ({ title, path, Icon, disabled, show }, index) => (
              <Fragment key={index}>
                {show && (
                  <NavLink
                    {...{
                      Icon,
                      to: disabled ? "#" : path,
                      onClick: () => {
                        !disabled && handleCloseMenu();
                      },
                      disabled,
                    }}
                  >
                    {title}
                  </NavLink>
                )}
              </Fragment>
            )
          )}
        </List>

        {!!SECOND_LIST_ITEMS.length && (
          <List
            className={classes.menuContent}
            sx={{ gap: "10px !important", paddingBottom: "33px !important" }}
          >
            {SECOND_LIST_ITEMS.map(
              ({ title, path, Icon, disabled, onClick, show }, index) => (
                <Fragment key={index}>
                  {show && (
                    <NavLink
                      {...{
                        Icon,
                        to: path,
                        onClick: () => {
                          onClick && onClick();
                          handleCloseMenu();
                        },
                        disabled,
                        simpleIcon: true,
                      }}
                    >
                      {title}
                    </NavLink>
                  )}
                </Fragment>
              )
            )}
          </List>
        )}

        <List className={classes.menuContent}>
          {AUTH_LIST_ITEMS.map(({ title, to, Icon, show, onClick }, index) => (
            <Fragment key={index}>
              {show && (
                <NavLink
                  key={index}
                  {...{
                    Icon,
                    to,
                    onClick,
                    simpleIcon: true,
                  }}
                >
                  {title}
                </NavLink>
              )}
            </Fragment>
          ))}
        </List>
      </Box>
    </Grid>
  );
};

MenuContent.propTypes = {
  open: bool,
  hasMultipleSuppliers: bool,
  setOpen: func,
  currentDistr: object,
  hasSelectedSupplier: bool,
};

MenuContent.defaultProps = {
  open: false,
  hasMultipleSuppliers: false,
  setOpen: () => {},
  currentDistr: {},
  hasSelectedSupplier: false,
};
