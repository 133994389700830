import React from "react";

// eslint-disable-next-line react/prop-types
export const ReplaceIcon = (props) => {
  return (
    <svg
      width={12}
      height={13}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.011 5.174h2.497v0m-9.016 5.148V7.826m0 0h2.496m-2.496 0 1.59 1.591a4.125 4.125 0 0 0 6.902-1.85M2.015 5.433a4.125 4.125 0 0 1 6.902-1.85l1.59 1.591m0-2.495v2.495"
        stroke="#409A65"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
