import { useMemo } from "react";
import { any, bool, func, string } from "prop-types";
import { Box, CircularProgress, Link, Typography } from "@mui/material";
import { AttentionIcon } from "components/Icons";
import { StyledTooltip } from "components";

export const ContactItem = ({
  title,
  item,
  firstLine,
  secondLine,
  onClick,
  hasEdit,
  error,
  loading,
}) => {
  const hasItem = useMemo(() => !!item, [item]);

  return (
    <Box width="285px" minHeight="84px">
      <Box position="relative" pl={1.5}>
        <Box
          sx={{
            position: "absolute",
            top: "5px",
            bottom: "5px",
            left: 0,
            borderLeft: "1.5px solid #409A65",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: hasItem ? "row" : "column",
            alignItems: hasItem ? "center" : "flex-start",
            gap: hasItem ? 0.5 : 0,
          }}
        >
          <Typography fontSize={18} color="#363531">
            {title}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            gap="6px"
            sx={{ cursor: "pointer" }}
          >
            {hasEdit && (
              <Link
                sx={{
                  cursor: "pointer",
                  fontSize: hasItem ? 14 : 17,
                }}
                underline="hover"
                component="button"
                onClick={onClick}
                type="button"
              >
                {hasItem ? "Edit" : "Add"}
              </Link>
            )}
            {error && !loading && (
              <StyledTooltip placement="top" arrow title={error}>
                <Box display="flex" alignItems="center">
                  <AttentionIcon />
                </Box>
              </StyledTooltip>
            )}
          </Box>
          {loading && <CircularProgress size={20} />}
        </Box>
        {hasItem && firstLine ? (
          <Box>
            <Box fontSize={18} fontWeight={300} color="#363531">
              {firstLine}
            </Box>
          </Box>
        ) : null}
        {hasItem && secondLine ? (
          <Box>
            <Box fontSize={18} fontWeight={300} color="#363531">
              {secondLine}
            </Box>
          </Box>
        ) : null}
        {/* <Typography color="error">{error}</Typography> */}
      </Box>
    </Box>
  );
};

ContactItem.propTypes = {
  title: string,
  item: bool,
  firstLine: any,
  secondLine: any,
  onClick: func,
  hasEdit: bool,
  error: string,
  loading: bool,
};
ContactItem.defaultProps = {
  title: "",
  item: false,
  firstLine: "",
  secondLine: "",
  onClick: () => {},
  hasEdit: false,
  loading: false,
};
