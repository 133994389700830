import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  navToolBar: {
    height: "100%",
    alignItems: "center",
  },
  navAppBar: {
    height: "68px",
  },
  logoWrapper: {
    textDecoration: "none",
  },

  navTab: {
    width: "80px",
    minWidth: "70px",
    maxWidth: "80px",
    "&.Mui-selected": {
      backgroundColor: "#F7F7F7",
      "& .MuiTypography-root": { color: "#3D3D3D" },
    },
    "&.Mui-disabled": {
      opacity: 0.7,
    },
  },

  navTabs: {
    marginLeft: "auto",
    height: "100%",
    flexGrow: 1,
    "& .MuiTabs-flexContainer": {
      height: "100%",
      justifyContent: "space-between",
    },
  },

  headerBtn: {
    borderRadius: "4px 0 0 4px",
    height: "43px",
    borderRight: "none",
    fontSize: "19px",
    color: "#6A6A6A",
    background: "#F7F7F7",
    border: "1px solid #D5D9D9",
  },

  searchFieldWrapper: {
    display: "flex",
    alignItems: "center",
    width: "38%",
    marginLeft: "7%",
  },

  badge: {
    "& .MuiBadge-badge": {
      minWidth: "12px",
      padding: "3px",
      height: "13px",
      color: "#ffffff",
      borderRadius: "6px",
      fontSize: "8px",
    },
  },

  navTypography: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#363531",
  },
}));

export default useStyles;
