import { string, object, func } from "prop-types";
import useStyles from "../styles";

import { Box, Stack, Typography } from "@mui/material";
import { normalizeCamelCaseString } from "helpers/helpers";

export const FilterCategoryItem = ({
  handleCheckItem,
  handleIsActive,
  selectedFilter,
  selectedFilterElement,
}) => {
  const classes = useStyles();
  const { list, getProps, Item } = selectedFilterElement || {};

  return (
    <>
      {!!list.length && (
        <Box className={classes.selectedFilterWrapper}>
          <Typography className={classes.itemText} noWrap>
            {normalizeCamelCaseString(selectedFilter)}
          </Typography>

          <Stack width="100%" direction="row" flexWrap="wrap" gap="8px">
            {list.map((item, index) => {
              const { onClick, ...rest } = getProps(
                item,
                handleCheckItem,
                handleIsActive
              );

              return (
                <Item
                  key={index}
                  {...rest}
                  onClick={() => onClick({ ...item, type: selectedFilter })}
                />
              );
            })}
          </Stack>
        </Box>
      )}
    </>
  );
};

FilterCategoryItem.propTypes = {
  selectedFilter: string,
  selectedFilterElement: object,
  handleCheckItem: func,
  handleIsActive: func,
};
FilterCategoryItem.defaultProps = {
  selectedFilter: "",
  selectedFilterElement: {},
};
