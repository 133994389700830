import { number } from "prop-types";
import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import pluralize from "pluralize";

export const VariationsChip = ({ count }) => {
  const {
    palette: { borderOutline },
  } = useTheme();

  return (
    <Box
      sx={{
        borderRadius: "4px",
        height: "19px",
        padding: "3px 9px",
        display: "flex",
        alignItems: "center",
        "& .MuiTypography-root": {
          fontSize: "12px",
        },
      }}
      border={`1px solid ${borderOutline.main}`}
    >
      <Typography noWrap>{pluralize("variation", count, true)}</Typography>
    </Box>
  );
};

VariationsChip.propTypes = { count: number };
VariationsChip.defaultProps = { count: 0 };
