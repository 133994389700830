export const cl = {
  drawerWrapper: {
    background: "white",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "inline-flex",
    overflowY: "auto",
    width: "100vw",
    padding: "20px 16px",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    gap: "20px",
    height: "90svh",
  },
};
