export const defaultValues = {
  fid: "",
  name: "",
  number: "",
  exp: "",
  cvc: "",
  defaultMethod: false,
  billingAddress: {
    formatted_address: "",
    lat: null,
    lng: null,
    zip: "",
    street: "",
    state: "",
    city: "",
    appartement: "",
  },
};

export const cardMask = [
  {
    regex: /^4/,
    cardtype: "visa",
  },
  {
    regex:
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
    cardtype: "mastercard",
  },
  {
    regex: /^3[47]/,
    cardtype: "amex",
  },
];
