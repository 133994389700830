import { Stack } from "@mui/material";
import { MobileAttentionIcon } from "components/Icons/MobileAttentionIcon";
import { WarningMsgBlock } from "pages/CartPage/components/CartSummaryBlock/components";
import { object } from "prop-types";
import { Fragment } from "react";

export const ErrorsBlock = ({ errors }) => {
  return (
    <>
      {!!Object.keys(errors).length && (
        <Stack gap="12px" width="100%">
          {Object.entries(errors)?.map((err, index) => {
            const isWarning = err[1]?.type === "warning";

            return (
              <Fragment key={index}>
                {err[1]?.message && (
                  <WarningMsgBlock
                    key={index}
                    show
                    text={err[1]?.message}
                    icon={
                      <MobileAttentionIcon
                        fill={
                          isWarning ? "rgba(243, 147, 37)" : "rgba(255, 0, 0)"
                        }
                      />
                    }
                    stylesWrapper={{
                      mt: 0,
                      padding: "10px 8px",
                      background: isWarning
                        ? "rgba(243, 147, 37, 0.12)"
                        : "rgba(228, 30, 58, 0.12)",
                      borderRadius: "8px",
                      border: `1px ${
                        isWarning
                          ? "rgba(243, 147, 37, 0.2)"
                          : "rgba(228, 30, 58, 0.20)"
                      } solid`,
                      gap: "5px",

                      "& .MuiTypography-root": {
                        color: "rgba(0, 0, 0, 0.80)",
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "18px",
                        wordWrap: "break-word",
                      },
                    }}
                  />
                )}
              </Fragment>
            );
          })}
        </Stack>
      )}
    </>
  );
};

ErrorsBlock.propTypes = {
  errors: object,
};
