import React, { useMemo } from "react";
import { bool, func, object } from "prop-types";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { STORE_LABELS } from "utils/constants";
import useStyles from "./styles";
import { StyledButton } from "components";
import { CrossIcon } from "components/Icons";
import { Logo } from "components/HeaderBar/components";
import { useNavigate } from "react-router-dom";

const RegisterFreeDialog = ({ open, onClose, distributor }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { name, billingAddress, storeName } = useMemo(
    () => distributor || {},
    [distributor]
  );
  const handleNavigateToRegistration = () => {
    navigate(`/orderdirect/${storeName}`);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogContent className={classes.dialogWrap}>
        <IconButton onClick={onClose} className={classes.closeIcon}>
          <CrossIcon size="34" />
        </IconButton>
        <Box className={classes.mainContent}>
          <Box className={classes.titleWrap}>
            <Logo className={classes.logo} />
            <Typography fontWeight="500" mt="12px">
              {name}
            </Typography>
            {billingAddress?.city && billingAddress?.state && (
              <Typography fontWeight="300">
                {billingAddress?.city}, {billingAddress?.state}
              </Typography>
            )}
          </Box>
          <Box className={classes.labelsWrap}>
            {STORE_LABELS.map(({ label, icon }) => (
              <Box className={classes.storeLabel} key={label}>
                <Typography noWrap>{label}</Typography>
                {icon}
              </Box>
            ))}
          </Box>
          <StyledButton
            label="Register Free Wholesale Account"
            variant="contained"
            className={classes.actionButton}
            fontSize="18px"
            fontWeight="500"
            onClick={handleNavigateToRegistration}
          />
        </Box>
        <Box className={classes.footer}>
          <Typography>Wholesale Made Simple</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

RegisterFreeDialog.propTypes = {
  open: bool,
  onClose: func,
  distributor: object,
};

export default RegisterFreeDialog;
