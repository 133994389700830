import { useDispatch } from "react-redux";

import { showCurrentDrawerAction } from "redux/actions/uiState";

import { DRAWERS } from "constants/drawer";
import { useCallback, useContext, useEffect } from "react";
import { ConfirmCardDelete } from "components";
import { ConfirmContext } from "pages/MasterPage/MasterPage";

export const useSelectCardDrawer = ({ setCardForEdit, handleDeleteCard }) => {
  const dispatch = useDispatch();
  const { setConfirmState } = useContext(ConfirmContext);

  const onClose = useCallback(
    () =>
      dispatch(
        showCurrentDrawerAction({
          type: DRAWERS.SELECT_CARD_DRAWER,
          show: false,
        })
      ),
    [dispatch]
  );

  const onAddNewCard = () => {
    dispatch(
      showCurrentDrawerAction({
        type: DRAWERS.SELECT_CARD_DRAWER,
        show: false,
      })
    );

    dispatch(
      showCurrentDrawerAction({
        type: DRAWERS.ADD_CARD_DRAWER,
        show: true,
      })
    );
  };

  const onEditOpen = (card) => {
    setCardForEdit(card);
    dispatch(
      showCurrentDrawerAction({
        type: DRAWERS.SELECT_CARD_DRAWER,
        show: false,
      })
    );
    dispatch(
      showCurrentDrawerAction({
        type: DRAWERS.EDIT_CARD_DRAWER,
        show: true,
      })
    );
  };

  const onConfirmDelete = (card) => {
    setConfirmState((prev) => ({
      ...prev,
      onConfirm: () => {
        onClose();
        handleDeleteCard(card?.id);
      },
      title: "Delete payment method?",
      isOpen: true,
      cardStateItem: card,
      type: "drawer",
      titleElement: <ConfirmCardDelete card={card} />,
    }));
  };

  useEffect(() => {
    return () => {
      onClose();
    };
  }, [onClose]);

  return { onClose, onAddNewCard, onEditOpen, onConfirmDelete };
};
