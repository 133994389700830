import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { MainLogoIcon } from "components";
import { confirmUserService } from "../../services/account";
import { error } from "helpers/notifications";

export const CodePage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [isCheckEmail, setIsCheckEmail] = useState(false);

  const [code, setCode] = useState("");

  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) navigate("/");
  }, [currentUser, navigate]);

  const handleRegister = async () => {
    setIsLoading(true);
    try {
      await confirmUserService({
        code: parseInt(code),
        params: currentUser?.distributor?.id,
      });
      navigate("/profile");
      setIsCheckEmail(true);
    } catch (err) {
      if (err.response?.data) return error(err.response.data.message);
      error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <Grid item sx={{ mt: "-120px" }}>
        <Box display="flex" justifyContent="center">
          <MainLogoIcon width={307} height={45} />
        </Box>
        <Paper
          sx={{
            width: "400px",
            paddingY: "35px",
            paddingX: "40px",
            borderRadius: "8px",
            mt: "32px",
          }}
          elevation={3}
        >
          {isCheckEmail ? (
            <>
              <Typography variant="h6" textAlign="center">
                Congratulation!!!
              </Typography>
              <Typography textAlign="center">
                You should to confirm your email
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h6" textAlign="center" mb={2}>
                Registration
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                id="code-input"
                label="Code"
                type="text"
                size="small"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />

              <Button
                disabled={isLoading}
                sx={{
                  marginY: 3,
                  textTransform: "none",
                }}
                variant="contained"
                fullWidth
                onClick={handleRegister}
              >
                {isLoading ? "Loading..." : "Confirm"}
              </Button>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
