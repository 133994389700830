import React from "react";

export const FullPrevIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 10c0 .518-.42.938-.937.938H5.7l4.024 4.024A.938.938 0 0 1 8.4 16.288l-5.625-5.625a.937.937 0 0 1 0-1.326L8.4 3.712a.937.937 0 1 1 1.325 1.326L5.701 9.062h10.862c.517 0 .937.42.937.938"
        fill="#0F172A"
      />
    </svg>
  );
};
