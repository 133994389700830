import { Box, Card, CardContent, Typography } from "@mui/material";
import { object, func, bool, number } from "prop-types";
import { LockIcon } from "components/Icons/LockIcon";
import {
  PictureBlock,
  ManufacturerNameBlock,
  ProductNameBlock,
  VariationsChip,
  MSRPBlock,
  WholeSaleBlock,
} from "./components";
import { useMemo } from "react";
import { StyledTooltip } from "components";

const ProductCard = ({
  product,
  handleOpenProductProfile,
  suppliersCount,
  filteredBySupplier,
  supplierApproved,
  showRetailPrice,
}) => {
  const {
    isMultiple,
    retailPrice,
    wholesalePrice,
    childProducts,
    manufacturer,
    name,
    _count,
  } = product || {};

  const uniqueChildProductsPrices = useMemo(() => {
    return isMultiple
      ? [...new Set(childProducts.map((p) => p?.retailPrice))]
      : [];
  }, [childProducts, isMultiple]);

  const uniqueChildProductsWholeSalePrices = useMemo(() => {
    return isMultiple
      ? [...new Set(childProducts.map((p) => p?.wholesalePrice || 0))]
      : [];
  }, [childProducts, isMultiple]);

  return (
    <Card
      sx={{
        borderRadius: "4px",
        border: "1px solid #D5D9D9",
        boxShadow: "none",
        cursor: "pointer",
      }}
      onClick={() => handleOpenProductProfile(product)}
    >
      <PictureBlock photos={product?.photos} />

      <CardContent sx={{ padding: "9px", "&:last-child": { padding: "9px" } }}>
        <Box
          sx={{
            borderBottom:
              suppliersCount > 1 && !filteredBySupplier
                ? "1px solid #d5d9d9"
                : "",
            pb: suppliersCount > 1 && !filteredBySupplier ? "7px" : "",
          }}
        >
          <ManufacturerNameBlock manufacturerName={manufacturer?.name} />

          <ProductNameBlock productName={name} />

          {/* <Box
            sx={{
              height: "48px",
              border: "1px solid lightblue",
              "& .MuiTypography-root": {
                textOverflow: "ellipsis",
                wordWrap: "break-all",
                overflow: "hidden",
                fontSize: "15px",
                fontWeight: 500,
              },
            }}
          >
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                wordBreak: "break-all",
              }}
            >
              {product?.name}
            </Typography>
          </Box> */}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              width="100%"
              // height={!wholesalePriceCheck ? "18px" : "47px"}
            >
              {/* {(product?._count || product.wholesalePrice !== null) && ( */}
              <>
                {supplierApproved && (
                  <WholeSaleBlock
                    isMultiple={isMultiple}
                    prices={
                      isMultiple
                        ? uniqueChildProductsWholeSalePrices
                        : wholesalePrice || 0
                    }
                  />
                )}
                {/* <Box mb="5px">
                    <StyledButton
                      sx={{
                        width: 164,
                        height: 19,
                        borderColor: "#D5D9D9",
                        backgroundColor: "#F7F7F7",
                        padding: 0,
                      }}
                      // onClick={handleRequest}
                      label={
                        <Typography
                          fontSize="9px"
                          sx={{ color: "#707070" }}
                          fontWeight="normal"
                        >
                          Request access for wholesale price
                        </Typography>
                      }
                      variant="outlined"
                    />
                  </Box> */}
              </>
              {/* )} */}
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center" flexGrow={1}>
                  {showRetailPrice && (
                    <MSRPBlock
                      isMultiple={isMultiple}
                      prices={
                        isMultiple ? uniqueChildProductsPrices : retailPrice
                      }
                    />
                  )}
                  {/* <Typography
                    fontSize="13px"
                    fontWeight="300"
                    marginRight="10px"
                  >
                    MSRP:{" "}
                    {product?.sku && `$${product.retailPrice?.toFixed(2)}`}
                    {!!product?.childProducts?.length &&
                      getRangeRetilPrice(product.childProducts)}
                  </Typography> */}
                  {/* {!product.childProducts.some(
                    (childProduct) => childProduct.wholesalePrice !== null
                  ) &&
                    product.wholesalePrice === null && <LockIcon />} */}
                  {!showRetailPrice && !supplierApproved && (
                    <StyledTooltip
                      placement="top"
                      arrow
                      isDark
                      disableHoverListener={!!showRetailPrice}
                      title={
                        <>
                          <Typography fontSize="12px" sx={{ color: "#FFF" }}>
                            Request Wholesale
                          </Typography>
                          <Typography fontSize="12px" sx={{ color: "#FFF" }}>
                            Access to see pricing
                          </Typography>
                        </>
                      }
                    >
                      <Box>
                        <LockIcon />
                      </Box>
                    </StyledTooltip>
                  )}
                </Box>

                {!!_count?.childProducts && (
                  <VariationsChip count={_count?.childProducts} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        {suppliersCount > 1 && !filteredBySupplier && (
          <Box
            sx={{
              paddingTop: "9px",
              "& .MuiTypography-root": {
                fontSize: "13px",
                fontWeight: 300,
              },
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <Typography>Supplier</Typography>
              {!!product.distributor.minimumOrderValueAmount && (
                <Typography>Minimum</Typography>
              )}
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography noWrap maxWidth="70%" fontWeight="500!important">
                {product.distributor?.name}
              </Typography>{" "}
              <Typography>
                {product.distributor.minimumOrderValueAmount
                  ? `$${product.distributor.minimumOrderValueAmount.toFixed(2)}`
                  : "No minimum"}
              </Typography>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

ProductCard.propTypes = {
  product: object,
  suppliersCount: number,
  handleOpenProductProfile: func,
  filteredBySupplier: bool,
  supplierApproved: bool,
  showRetailPrice: bool,
};

export default ProductCard;
