import {
  OrderIcon,
  PaymentIcon,
  ProfileIcon,
  //ReportIcon,
  SupplierIcon,
  MessageIcon,
  SignOutIcon,
  SignInIcon,
} from "components/Icons";
import { BookIcon } from "simply-depo-ui-components";

export const getMenuUrls = ({
  _count,
  hasMultipleSuppliers,
  hasSelectedSupplier,
  isShowProductsWithoutLogIn,
  previewLink,
}) => {
  const hideProductsItem = hasMultipleSuppliers
    ? hasMultipleSuppliers && hasSelectedSupplier
    : true;

  return [
    {
      title: "Products",
      path: isShowProductsWithoutLogIn ? previewLink : "/",
      Icon: BookIcon,
      disabled: false,
      show: hideProductsItem,
    },
    {
      title: "Suppliers",
      path: "/suppliers",
      Icon: SupplierIcon,
      disabled: false,
      show: hasMultipleSuppliers && !isShowProductsWithoutLogIn,
    },
    {
      title: "Orders",
      path: "/orders",
      Icon: OrderIcon,
      disabled: !_count?.orders,
      show: !isShowProductsWithoutLogIn,
    },
    //{
    //  title: "Reports",
    //  path: "/sign-in",
    //  Icon: ReportIcon,
    //  disabled: false,
    //  show: true,
    //},
  ];
};

export const getSecondMenuUrls = ({
  currentUser,
  onClick,
  hasMultipleSuppliers,
  isShowProductsWithoutLogIn,
}) => {
  return [
    {
      title: "Profile",
      path: "/profile",
      Icon: ProfileIcon,
      show: !!currentUser,
      disabled: false,
    },
    {
      title: "Payments",
      path: "/payments",
      Icon: PaymentIcon,
      disabled: false,
      show: !isShowProductsWithoutLogIn,
    },
    {
      title: "Customer support",
      path: "#",
      onClick,
      show: !hasMultipleSuppliers && !isShowProductsWithoutLogIn,
      Icon: MessageIcon,
    },
  ].filter((item) => item.show);
};

export const getAuthMenuUrls = ({
  handleExitUser,
  isShowProductsWithoutLogIn,
  currentUser,
  handleClickSignIn,
}) => {
  return [
    {
      title: "Sign Out",
      Icon: SignOutIcon,
      to: "#",
      onClick: handleExitUser,
      show: !isShowProductsWithoutLogIn,
    },
    {
      title: "Sign in",
      Icon: SignInIcon,
      to: "#",
      onClick: handleClickSignIn,
      show: !currentUser && !!isShowProductsWithoutLogIn,
    },
  ];
};
