/* eslint-disable react/prop-types */
import React from "react";

export const TrashIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.019"
      height="21.021"
      viewBox="0 0 19.019 21.021"
      {...props}
    >
      <g
        id="Icon_feather-trash-2"
        data-name="Icon feather-trash-2"
        transform="translate(0.5 0.5)"
      >
        <path
          id="Path_2479"
          data-name="Path 2479"
          d="M4.5,9H22.519"
          transform="translate(-4.5 -4.996)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_2480"
          data-name="Path 2480"
          d="M21.515,7V21.019a2,2,0,0,1-2,2H9.5a2,2,0,0,1-2-2V7m3,0V5a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V7"
          transform="translate(-5.498 -3)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_2481"
          data-name="Path 2481"
          d="M15,16.5v6.006"
          transform="translate(-7.992 -7.49)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_2482"
          data-name="Path 2482"
          d="M21,16.5v6.006"
          transform="translate(-9.988 -7.49)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
