import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  BackBtnHeader,
  DesktopHeader,
  MobileHeader,
  OrderHeaderBlock,
  ViewOrderHeaderBlock,
} from "./components";

import { useMediaDevice } from "hooks/useMediaDevice";

export const HeaderBar = () => {
  const location = useLocation();
  const { isMobile } = useMediaDevice();

  const currentLocation = location.pathname.split("/");

  const getCurrentMobileHeader = useCallback(() => {
    if (!isMobile) return null;
    switch (currentLocation[1]) {
      case "cart":
        return <OrderHeaderBlock />;
      case "orders":
        return currentLocation[2] ? <ViewOrderHeaderBlock /> : <MobileHeader />;
      case "products":
        return currentLocation[2] ? (
          <BackBtnHeader title="Product details" showCart={true} />
        ) : (
          <MobileHeader />
        );

      default:
        return <MobileHeader />;
    }
  }, [currentLocation, isMobile]);

  const mobileHeaderElement = useMemo(
    () => getCurrentMobileHeader(),
    [getCurrentMobileHeader]
  );

  return <>{isMobile ? mobileHeaderElement : <DesktopHeader />}</>;
};
