import { array, bool, number, oneOfType } from "prop-types";
import { Typography } from "@mui/material";
import { useRangePrices } from "helpers/hooks";

export const WholeSaleBlock = ({ isMultiple, prices }) => {
  const calcRangePrices = useRangePrices({ isMultiple, prices });

  return (
    <Typography fontSize="18px" fontWeight="600">
      {calcRangePrices}
    </Typography>
  );
};

WholeSaleBlock.propTypes = {
  isMultiple: bool,
  prices: oneOfType([array, number]),
};
WholeSaleBlock.defaultProps = { isMultiple: false, prices: [] };
