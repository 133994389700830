import {} from "react";
import { bool, func } from "prop-types";
import { Box, Dialog } from "@mui/material";
import {
  FooterBlock,
  HeaderBlock,
  InfoBlock,
  NotesBlock,
  ProductListBlock,
  ReasonBlock,
} from "./components";

const temp = {
  id: "326636",
  paidSum: 326.36,
  fulfilledItems: 10,
  fulfilledCount: 10,
};

const { id, paidSum, fulfilledItems, fulfilledCount } = temp;

export const ReturnOrderDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} maxWidth="md">
      <Box pt={2.25} pb={3.25} px={3.25} width="474px">
        <HeaderBlock {...{ onClose, id }} />
        <InfoBlock {...{ paidSum, fulfilledItems, fulfilledCount }} />
        <ReasonBlock />
        <ProductListBlock />
        <NotesBlock />
        <FooterBlock {...{ onClose }} />
      </Box>
    </Dialog>
  );
};

ReturnOrderDialog.propTypes = { open: bool, onClose: func };
ReturnOrderDialog.defaultProps = { open: false };
