import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  input: {
    height: "48px",
    fontSize: "18px",
    borderRadius: "8px",
    "&.Mui-error > fieldset": {
      filter: "drop-shadow(0.1px 0.2px 2px #EB4233)",
    },
  },

  inputLabel: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#B5B5AC",
    top: -4,
    "&.Mui-focused": {
      top: 0,
    },
    "&.MuiFormLabel-filled": {
      top: -1,
    },
  },
}));

export const cl = {
  wrapper: {
    width: "100%",
    minHeight: "100vh",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: { xs: "0 24px", sm: "0 3.5vw" },
    backgroundColor: { xs: "#ffff", sm: "#F9F8F8" },
  },
  inputSx: {
    height: "48px",
    fontSize: "15px",
    "& input": { padding: "7px 14px" },
    "&:hover:not(.Mui-focused)": {
      "&& fieldset": {
        borderColor: "rgba(194, 194, 194, 0.5)",
      },
    },
  },
  labelSx: { fontSize: "17px", top: -3, "&.Mui-focused": { top: -3 } },
};
