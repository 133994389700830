import { func, number, object } from "prop-types";

import { Chip, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Fragment } from "react";

export const MobileChipList = ({ chipData, handleDeleteChip, filterCount }) => {
  const chipList = Object.entries(chipData).flatMap(([, value]) => value);

  return (
    <>
      {!!filterCount && (
        <Stack
          gap="8px"
          mt={chipList.length ? "10px" : 0}
          direction="row"
          sx={{
            overflowX: "scroll",
            scrollSnapType: "x mandatory",
            "&::-webkit-scrollbar": {
              display: "none",
              width: "0px",
            },
          }}
        >
          {chipList.map((item, index) => (
            <Fragment key={index}>
              {item.name && (
                <Chip
                  onClick={() => handleDeleteChip(item.type, item.id)}
                  label={item.name}
                  deleteIcon={<ClearIcon />}
                  style={{
                    width: "fit-content",
                    color: "rgba(0, 0, 0, 0.60)",
                    fontSize: "12px",
                    fontWeight: 500,
                    padding: "5px 8px",
                    background: "rgba(231.72, 231.72, 231.72, 0.10)",
                    borderRadius: "8px",
                    border: "1px #ECECEC solid",
                  }}
                  sx={{
                    "& span": {
                      px: 0,
                    },
                  }}
                />
              )}
            </Fragment>
          ))}
        </Stack>
      )}
    </>
  );
};

MobileChipList.propTypes = {
  chipData: object,
  handleDeleteChip: func,
  filterCount: number,
};
