import React, { useMemo, useState } from "react";
import { any, object, shape, string } from "prop-types";

import { ContactVendorDrawer, StyledButton } from "components";
import { Logo } from "components/HeaderBar/components";
import { MobileAttentionIcon } from "components/Icons/MobileAttentionIcon";

import { cl } from "./styles";

import { Box, Stack, Typography } from "@mui/material";

const MobileRejectScreen = ({ reject }) => {
  const [contactOpen, setContactOpen] = useState(false);

  const { distributor, note } = useMemo(() => reject || {}, [reject]);

  const { name, billingAddress } = useMemo(
    () => distributor || {},
    [distributor]
  );

  return (
    <>
      <ContactVendorDrawer
        open={contactOpen}
        supplier={distributor}
        showContactOnly
        onClose={() => setContactOpen(false)}
      />

      <Stack
        alignItems="center"
        width="100%"
        justifyContent="center"
        mt="60px"
        gap="20px"
      >
        <Stack sx={cl.titleWrap} gap="6px">
          <Logo sx={cl.logo} />
          {name && <Typography sx={cl.titleName}>{name}</Typography>}
          {billingAddress?.city && billingAddress?.state && (
            <Typography fontWeight="400">
              {billingAddress?.city}, {billingAddress?.state}
            </Typography>
          )}
        </Stack>

        <Box sx={cl.dangerIconWrap}>
          <MobileAttentionIcon fill="#FF0000" />
        </Box>

        <Typography sx={cl.mainText}>
          The supplier has rejected your order <br />
          direct access
        </Typography>

        {!!note && (
          <Typography
            sx={cl.mainText}
          >{`Rejection Reason:\n${note}`}</Typography>
        )}

        <StyledButton
          sx={cl.contactButton}
          label="Contact Supplier"
          variant="contained"
          fontSize="16px"
          fontWeight="600"
          onClick={() => setContactOpen(true)}
        />
      </Stack>
    </>
  );
};

MobileRejectScreen.propTypes = {
  reject: shape({
    approvedPaymentTermsDuplicate: any,
    distributor: object,
    note: string,
    status: string,
  }),
};

export default MobileRejectScreen;
