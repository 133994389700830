import { useSelector } from "react-redux";
import { array, bool, func, object } from "prop-types";

import useStyles from "./styles";

import { Drawer, Stack } from "@mui/material";
import { useFilterDrawer } from "./useFilterDrawer";
import { FilterItem } from "./components/FilterItem";
import { FilterHeader } from "./components/FilterHeader";
import { FilterCategoryItem } from "./components/FilterCategoryItem";
import { Fragment } from "react";

export const FilterDrawer = ({
  FILTER_DATA,
  onSave,
  onStateClear,
  currentFilterElement,
  disabledActionBtn,
  FILTER_TABS,
}) => {
  const classes = useStyles();
  const isFilterDrawerOpened = useSelector(
    ({ uiState }) => uiState.filterDrawer
  );

  const {
    onClose,
    handleSelectFilter,
    selectedFilter,
    handleClearAction,
    handleSaveFilter,
    handleCheckItem,
    handleIsActive,
  } = useFilterDrawer({
    onSave,
    onStateClear,
    currentFilterElement,
  });

  return (
    <Drawer
      anchor="right"
      open={isFilterDrawerOpened}
      onClose={onClose}
      PaperProps={{ className: classes.drawerWrapper }}
    >
      <FilterHeader
        {...{
          selectedFilter,
          handleSelectFilter,
          handleClearAction,
          handleSaveFilter,
          disableButtons: disabledActionBtn,
        }}
      />

      {selectedFilter ? (
        <FilterCategoryItem
          {...{
            handleCheckItem,
            handleIsActive,
            selectedFilter,
            selectedFilterElement: currentFilterElement[selectedFilter],
          }}
        />
      ) : (
        <Stack width="100%">
          {FILTER_TABS.map((title, index) => (
            <Fragment key={index}>
              {FILTER_DATA[title].show && (
                <FilterItem {...{ title, handleSelectFilter }} />
              )}
            </Fragment>
          ))}
        </Stack>
      )}
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  FILTER_DATA: object,
  onSave: func,
  disabledActionBtn: bool,
  onStateClear: func,
  currentFilterElement: object,
  FILTER_TABS: array,
};
FilterDrawer.defaultProps = {
  brandsList: [],
  FILTER_TABS: [],
  FILTER_DATA: {},
  disabledActionBtn: false,
  categoriesList: [],
  onStateClear: () => {},
  onSave: () => {},
  currentFilterElement: {},
};
