import { forwardRef } from "react";
import { Box } from "@mui/material";
import { PlaceholderIcon } from "components/Icons";

export const ImagePlaceholder = forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ wrapperProps, rx, ...props }, ref) => {
    return (
      <Box ref={ref} {...wrapperProps}>
        <PlaceholderIcon rx={rx} {...props} />
      </Box>
    );
  }
);

ImagePlaceholder.displayName = "ImagePlaceholder";

export default ImagePlaceholder;
