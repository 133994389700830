import { makeStyles } from "@mui/styles";

export const cl = {};

export const useStyles = makeStyles(() => ({
  noteText: {
    color: "#409A65",
    fontSize: "14px",
    fontWeight: 400,
  },
}));
