import { array, bool, func, object } from "prop-types";
import { Box, Typography } from "@mui/material";
import { CategoriesList } from "./components";
import { cl } from "./CategoriesComponent.styles";

export const CategoriesComponent = ({
  loading,
  childrenlist,
  parentsList,
  category,
  handleFetchCategories,
  isFilter,
}) => {
  const { mainWrapper } = cl;

  return (
    <Box sx={{ ...mainWrapper, p: isFilter ? "6px 5px" : "18px 16px 26px" }}>
      <Box height="100%">
        {!isFilter && (
          <Typography fontSize={19} fontWeight={500} color="#000" mb={2}>
            Category
          </Typography>
        )}
        <CategoriesList
          {...{
            childrenlist,
            parentsList,
            loading,
            category,
            handleFetchCategories,
            isFilter,
          }}
        />
      </Box>
    </Box>
  );
};

CategoriesComponent.propTypes = {
  loading: bool,
  childrenlist: array,
  parentsList: array,
  category: object,
  handleFetchCategories: func,
  isFilter: bool,
};

CategoriesComponent.defaultProps = {
  isFilter: false,
};
