export const cl = {
  cardWrapper: {
    p: "12px",
    borderRadius: "10px",
    border: "1px rgba(0, 0, 0, 0.05) solid",
    gap: "4px",
    minHeight: "173px",
    height: "100%",
  },

  paymentNumber: {
    padding: "3px 8px",
    background: "rgba(149, 149, 149, 0.20)",
    borderRadius: "8px",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "4px",
    width: "fit-content",
  },

  paymentNumberText: {
    color: "#959595",
    fontSize: 10,
    fontWeight: 500,
  },

  cashTitle: {
    color: "rgba(0, 0, 0, 0.80)",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
  },
};
