import React from "react";

export const DeleteIcon = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m7.37 5-.173 4.5m-2.394 0L4.63 5m4.984-1.605q.256.04.511.083m-.511-.083-.534 6.941a1.125 1.125 0 0 1-1.122 1.039H4.042a1.125 1.125 0 0 1-1.122-1.039l-.534-6.94m7.228 0a24 24 0 0 0-1.739-.199m-6 .281q.255-.044.511-.083m0 0a24 24 0 0 1 1.739-.198m3.75 0V2.74a1.09 1.09 0 0 0-1.045-1.1 26 26 0 0 0-1.66 0 1.09 1.09 0 0 0-1.045 1.1v.458m3.75 0a24 24 0 0 0-3.75 0"
        stroke="#000"
        strokeOpacity=".6"
        strokeWidth=".75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
