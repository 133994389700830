import { useContext, useMemo } from "react";
import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import useStyles from "../../newStyles";
import { StyledButton } from "components";
import ValidationPopper from "components/ValidationPopper/ValidationPopper";
import { ProfileContext } from "pages/ProfilePage/NewProfile";
import { LocationsTab, SummaryTab } from "./components";

export const DesktopViewProfile = () => {
  const classes = useStyles();

  const {
    tabs,
    tabValue,
    setTabValue,
    isEdit,
    control,
    handleUpdateLogo,
    handleDeleteLogo,
    setValue,
    errPhoneStatus,
    setIsEdit,
    onSaveHoverRevalidate,
    handleSubmit,
    onSubmit,
    errors,
    isOpen,
    setIsOpen,
    anchorEl,
    checkError,
  } = useContext(ProfileContext);

  const settingsPage = useMemo(() => {
    switch (tabValue) {
      case tabs[0].value:
        return (
          <SummaryTab
            {...{
              isEdit,
              control,
              handleUpdateLogo,
              handleDeleteLogo,
              setValue,
              errPhoneStatus,
            }}
          />
        );

      case tabs[1].value:
        return <LocationsTab {...{ isEdit, control, setValue }} />;

      default:
        <></>;
    }
  }, [
    tabValue,
    tabs,
    isEdit,
    control,
    handleUpdateLogo,
    handleDeleteLogo,
    setValue,
    errPhoneStatus,
  ]);

  return (
    <Box className={classes.pageWrapper}>
      <Box className={classes.profileWrapper}>
        <Box className={classes.profileHeader}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="15px"
          >
            <Typography className={classes.title}>Account Profile</Typography>
            <Box className={classes.buttonsBlock}>
              {isEdit && (
                <StyledButton
                  label="Cancel"
                  variant="outlined"
                  color="grey"
                  onClick={() => setIsEdit(false)}
                  className={classes.editButton}
                  fontSize="14px"
                />
              )}
              <Box onMouseEnter={onSaveHoverRevalidate} component="span">
                <StyledButton
                  label={isEdit ? "Save" : "Edit"}
                  variant={isEdit ? "contained" : "outlined"}
                  color={isEdit ? "primary" : "grey"}
                  onClick={
                    isEdit ? handleSubmit(onSubmit) : () => setIsEdit(true)
                  }
                  disabled={isEdit && !!Object.keys(errors).length}
                  className={classes.editButton}
                  fontSize="14px"
                />
                {isOpen && !!errors.name && (
                  <ValidationPopper
                    isOpen={isOpen}
                    anchorEl={anchorEl}
                    errors={errors}
                    setIsOpen={setIsOpen}
                    tailProps={{
                      top: -6,
                      right: 48,
                      rotate: -180,
                    }}
                    offset={[-170, 21]}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Tabs
            value={tabValue}
            onChange={(e, newTab) => setTabValue(newTab)}
            sx={{
              "& .Mui-selected": {
                backgroundColor: "transparent!important",
              },
            }}
          >
            {tabs
              .filter((t) => t?.desktop?.show)
              .map((tab) => (
                <Tab
                  sx={{
                    py: 0,
                    height: "44px",
                    minHeight: "44px",
                    fontSize: "23px",
                    fontWeight: 400,
                    color: "#707070",
                    textTransform: "none",
                  }}
                  key={tab?.value}
                  label={tab?.name}
                  value={tab?.value}
                  icon={
                    checkError(tab) ? (
                      <ErrorIcon
                        style={{ fill: "#ff6254", fontSize: "20px" }}
                      />
                    ) : (
                      ""
                    )
                  }
                  iconPosition="start"
                />
              ))}
          </Tabs>
        </Box>
        <Divider />
        <Box className={classes.profileInnerPage}>{settingsPage}</Box>
      </Box>
    </Box>
  );
};
