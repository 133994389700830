import axios from "axios";

export const getRepsService = async (params) => {
  return axios
    .get("/users/representatives/territories", {
      params,
    })
    .then((res) => res.data);
};

export const getRepsLite = async (params) => {
  return axios
    .get("/users/representatives/lite", {
      params,
    })
    .then((res) => res.data);
};
