import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  btnsBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "6px",
    marginRight: 1,
  },

  smallBtn: {
    borderColor: "#D4D4D4",
    color: "#000",
    maxWidth: "52px",
    minWidth: "52px",
    height: "16px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },

  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 17,
    fontWeight: 400,
    color: "#000",
  },

  noCardsPlaceholder: {
    display: "flex",
    height: "200px",
    alignItems: "center",
    justifyContent: "center",
  },

  buttonsWrap: {
    padding: "0 24px 20px",
  },
}));

export default useStyles;
