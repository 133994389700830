import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  profileHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "27px 44px",
  },

  blockWrap: {
    display: "flex",
    gap: "124px",
    padding: "0px 182px 36px 253px",
  },

  controlBtns: {
    display: "flex",
    gap: "21px",
  },

  controlBtn: { fontSize: "18px", height: "40px", width: "106px" },

  detailsWrap: { flex: "1 1 50%" },

  contactWrap: { flex: "1 1 50%" },

  fieldsWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    paddingTop: "39px",
  },

  checkboxLabel: {
    fontSize: "17px",
    color: "#B5B5AC",
  },

  titleWrap: { display: "flex", gap: "48px", alignItems: "center" },

  contactsTable: { marginTop: "25px" },

  inputLabel: {
    fontSize: "17px",
    fontWeight: "300",
    color: "#B5B5AC",
  },
}));

export default useStyles;
