import { useContext } from "react";

import {
  CustomerCards,
  EditContactDialog,
  ReturnOrderDialog,
} from "components";
import {
  TitleBlock,
  FooterBlock,
  AddressesBlock,
  ItemsListBlock,
} from "./components";

import { OrderContext } from "pages/ViewOrderPage/ViewOrderPage.hooks";

import { Box } from "@mui/material";

export const DesktopViewOrder = () => {
  const {
    orderData,
    type,
    handleUpdateNote,
    acceptPaymentData,
    returnOrderState,
    currentUser,
    isOpenEditContactDialog,
    editCustomerData,
    editCustomerType,
    setOpenEditContactDialog,
    handleChangeCustomer,
    handleCloseReturnDialogDialog,
    handleClickAddressEdit,
    handleOpenReturnDialogDialog,
    handleClickContinueShopping,
  } = useContext(OrderContext);
  return (
    <>
      {!!orderData.order && (
        <>
          <CustomerCards
            open={acceptPaymentData.open}
            onClose={acceptPaymentData.closeDialog}
            distributor={currentUser?.orderDirect?.[0]?.distributor}
            customerId={
              currentUser?.orderDirect?.[0]?.distributor?.customers?.[0]?.id
            }
            billingAddress={currentUser?.billingAddress}
            amountData={{
              order: orderData?.order,
              balance: orderData?.order?.balance?.toFixed(2),
              amount: acceptPaymentData.amount,
              setAmount: acceptPaymentData.setAmount,
            }}
            handleConfirm={(data) =>
              acceptPaymentData.handleAcceptPayment({
                ...data,
                distributor_id: currentUser?.orderDirect?.[0]?.distributor?.id,
                orderId: orderData?.order?.id,
              })
            }
          />
          <EditContactDialog
            isOpen={isOpenEditContactDialog}
            data={editCustomerData}
            type={editCustomerType}
            handleClose={() => setOpenEditContactDialog(false)}
            handleSave={handleChangeCustomer}
            contacts={orderData?.order?.customer?.contacts}
            customer={orderData?.order?.customer}
          />
          {returnOrderState.open && (
            <ReturnOrderDialog
              open={returnOrderState.open}
              onClose={handleCloseReturnDialogDialog}
            />
          )}
          <Box mb={-2} px={2} pt={2}>
            <AddressesBlock
              onClickEdit={handleClickAddressEdit}
              type={type}
              onClickReturnOrder={handleOpenReturnDialogDialog}
              onClickContinueShopping={handleClickContinueShopping}
              acceptPayment={acceptPaymentData}
              orderDirect={currentUser?.orderDirect}
            />
            <TitleBlock
              text={
                type === "orders_page" ? "View Order" : "Order Confirmation"
              }
            />
            <ItemsListBlock />
            <FooterBlock handleUpdateNote={handleUpdateNote} />
          </Box>
        </>
      )}
    </>
  );
};
