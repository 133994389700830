import { useContext } from "react";
import { object, bool, func } from "prop-types";
import { FormControl, MenuItem, Select, Box } from "@mui/material";
import { Controller, useFieldArray, useWatch } from "react-hook-form";

import useStyles from "../../../../newStyles";
import TableHeader from "../TableHeader";
import TableItem from "../TableItem";

import {
  GoogleAutocompleteField,
  StyledSelect,
  StyledTextField,
  StyledTooltip,
} from "components";

import ContactsTable from "./components/ContactsTable";
import {
  formatAddress,
  onPastePhone,
  setPhoneNumberMask,
  setWebsiteMask,
  sliceCountry,
} from "helpers/helpers";
import NumberFormat from "react-number-format";
import { ExpandMore } from "@mui/icons-material";
import { Loader } from "components";
import { getAddressComponents } from "components/AddressField/AddressField.helpers";
import { ProfileContext } from "pages/ProfilePage/NewProfile";
import { LogoBlock } from "../../../LogoBlock/LogoBlock";

export const SummaryTab = ({ control, isEdit, setValue, errPhoneStatus }) => {
  const formField = useWatch({ control });
  const classes = useStyles();

  const {
    preparingPhoto,
    selectCityState,
    phoneCodeList,
    progressPhotoUpload,
  } = useContext(ProfileContext);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts",
  });

  const handleAddContact = () => {
    append({
      name: "",
      role: "",
      email: "",
      phone: "",
      defaultContact: false,
      countryPrefix: phoneCodeList[0],
    });
  };

  const handleRemoveContact = (pos) => {
    remove(pos);
  };

  const handleChangeDefault = (pos) => {
    if (formField.contacts[pos].defaultContact) return;
    const defIndex = formField.contacts.findIndex((c) => c.defaultContact);
    setValue(`contacts.${defIndex}.defaultContact`, false);
    setValue(`contacts.${pos}.defaultContact`, true);
  };

  return (
    <>
      <Loader isLoading={progressPhotoUpload || preparingPhoto} />
      <Box>
        <Box display="flex" gap="47px">
          <Box flex="1 1 50%">
            <TableHeader title="Business details" />
            <Box>
              <TableItem
                title="Business Name"
                value={formField.name}
                isEdit={isEdit}
                EditField={
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <StyledTooltip
                        open={!!error && error?.type === "specialCharacter"}
                        arrow
                        placement="top"
                        aria-hidden="true"
                        title={error ? error?.message : ""}
                        modifiers={[
                          {
                            name: "offset",
                            options: { offset: [0, -10] },
                          },
                        ]}
                      >
                        <StyledTextField
                          fullWidth
                          size="large"
                          InputProps={{ className: classes.editInput }}
                          error={error?.message || ""}
                          noErrorMessage={
                            !!error && error?.type === "specialCharacter"
                          }
                          {...field}
                        />
                      </StyledTooltip>
                    )}
                    name="name"
                    control={control}
                  />
                }
              />
              <TableItem
                title="FED Tax ID (EIN)"
                value={formField.federalTaxId}
                isEdit={isEdit}
                EditField={
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <NumberFormat
                        customInput={StyledTextField}
                        format="##-#######"
                        size="large"
                        fullWidth
                        InputProps={{ className: classes.editInput }}
                        error={error?.message || ""}
                        {...field}
                      />
                    )}
                    name="federalTaxId"
                    control={control}
                  />
                }
              />
              <TableItem
                title="Billing Address"
                autoHeight={isEdit}
                value={formatAddress(
                  formField.billingAddress?.formatted_address
                )}
                isEdit={isEdit}
                EditField={
                  <Box display="flex" flexDirection="column" gap="20px">
                    <Box display="flex" gap="20px">
                      <Controller
                        render={({ field, fieldState: { error } }) => (
                          <GoogleAutocompleteField
                            fullWidth
                            size="large"
                            placeholder="Address"
                            InputProps={{
                              className: classes.editInput,
                            }}
                            formSx={{
                              flex: "1 1 70%",
                            }}
                            error={error?.message || ""}
                            {...field}
                            onPlaceSelected={(place) => {
                              const { street, state, city, zip } =
                                getAddressComponents(place);
                              setValue("billingAddress", {
                                formatted_address: sliceCountry(
                                  place.formatted_address
                                ),
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng(),
                                street,
                                state,
                                city,
                                zip,
                              });
                            }}
                            noErrorMessage
                          />
                        )}
                        name="billingAddress.formatted_address"
                        control={control}
                      />
                      <Controller
                        render={({ field, fieldState: { error } }) => (
                          <StyledTextField
                            fullWidth
                            size="large"
                            placeholder="Unit"
                            InputProps={{
                              className: classes.editInput,
                            }}
                            formSx={{
                              flex: "1 1 30%",
                            }}
                            error={error?.message || ""}
                            {...field}
                            value={field.value || ""}
                          />
                        )}
                        name="billingAddress.appartement"
                        control={control}
                      />
                    </Box>
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <StyledTextField
                          fullWidth
                          size="large"
                          placeholder="City"
                          InputProps={{
                            className: classes.editInput,
                          }}
                          error={error?.message || ""}
                          {...field}
                        />
                      )}
                      name="billingAddress.city"
                      control={control}
                    />
                    <Box display="flex" gap="22px">
                      <Controller
                        render={({ field, fieldState: { error } }) => (
                          <StyledSelect
                            noErrorMessage
                            notched={false}
                            fullWidth
                            error={error?.message || ""}
                            height="43px"
                            fontSize="15px"
                            {...field}
                            borderRadius="9px"
                            value={field.value || ""}
                          >
                            {Object.keys(selectCityState).map((key) => (
                              <MenuItem key={key} value={key}>
                                {selectCityState[key]}
                              </MenuItem>
                            ))}
                          </StyledSelect>
                        )}
                        name="billingAddress.state"
                        control={control}
                      />
                      <Controller
                        render={({ field, fieldState: { error } }) => (
                          <StyledTextField
                            fullWidth
                            size="large"
                            placeholder="City"
                            InputProps={{
                              className: classes.editInput,
                            }}
                            error={error?.message || ""}
                            {...field}
                          />
                        )}
                        name="billingAddress.zip"
                        control={control}
                      />
                    </Box>
                  </Box>
                }
              />

              <LogoBlock />

              {/* <Box pl="27px" pt="12px" display="flex" alignItems="center">
                <StyledAvatar
                  sx={{
                    height: "61px",
                    width: "61px",
                    border: "1px solid #CBCBCB",
                  }}
                  src={photoUrl(formField.profilePhoto?.fileName)}
                  stringStyle={{ fontSize: "25px" }}
                  name={formField.name}
                />
                <Box>
                  <Box display="flex" alignItems="center">
                    <StyledTooltip
                      open={tooltipData.open && !isCroppedDialogOpen}
                      title={tooltipData.text}
                      placement="top"
                      arrow
                    >
                      <Box
                        ref={tooltipRef}
                        onMouseEnter={() =>
                          setTooltipData((prev) => ({ ...prev, open: true }))
                        }
                        onMouseLeave={() =>
                          setTooltipData((prev) => ({ ...prev, open: false }))
                        }
                        onMouseDown={() =>
                          setTooltipData((prev) => ({ ...prev, open: false }))
                        }
                      >
                        <UploadFile
                          label="Upload new logo"
                          accept="image/png, image/gif, image/jpeg"
                          wrapperProps={{ sx: { p: "5px" } }}
                          onChange={(data) => handleUpdateLogo(data)}
                          Wrapper={Button}
                          path="users/app-customers/profile-photo"
                          withCropper
                          dialogSize="small"
                          settingsCropper={{ cropShape: "round" }}
                          uploadData={{ type: "PROFILE_APP_CUSTOMER" }}
                          setIsOpen={setIsCroppedDialogOpen}
                          progressUpload={setProgressPhotoUpload}
                          {...{ preparingPhoto, setPreparingPhoto }}
                        />
                      </Box>
                    </StyledTooltip>
                    {formField.profilePhoto && (
                      <>
                        <Typography color="#707070">•</Typography>
                        <StyledButton
                          label="Remove logo"
                          fontSize="14px"
                          sx={{ p: "5px" }}
                          onClick={handleDeleteLogo}
                        />
                      </>
                    )}
                  </Box>
                  <Box>
                    <Typography
                      ml="5px"
                      mt="-3px"
                      fontSize={12}
                      color="#D4D4D4"
                    >
                      • Use at least 500 px by 500 px <br /> • Use white or
                      neutral background
                    </Typography>
                  </Box>
                </Box>
              </Box> */}
            </Box>
          </Box>
          <Box flex="1 1 50%">
            <TableHeader title="Business Contact" />
            <Box>
              <TableItem
                title="Business Email"
                value={formField.email}
                isEdit={isEdit}
              />
              <TableItem
                title="Business Phone"
                value={setPhoneNumberMask(
                  `${formField.countryPrefix.phone_code}${formField.phone}`
                )}
                isEdit={isEdit}
                EditField={
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <NumberFormat
                        customInput={StyledTextField}
                        fullWidth
                        format={formField?.countryPrefix?.format}
                        mask="_"
                        size="small"
                        InputProps={{
                          style: {
                            height: "43px",
                            fontSize: "15px",
                            paddingLeft: 0,
                            borderRadius: "8px",
                          },
                          startAdornment: (
                            <FormControl>
                              <Controller
                                render={({ field }) => {
                                  return (
                                    <Select
                                      sx={{
                                        borderRadius: "0",
                                        marginRight: 1,
                                        "& .MuiSelect-select": {
                                          minWidth: "50px",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          borderWidth: "0 1px 0 0 !important",
                                          borderColor: "#D5D9D9 !important",
                                        },
                                      }}
                                      size="small"
                                      renderValue={() =>
                                        field?.value?.phone_code
                                      }
                                      IconComponent={(props) => (
                                        <ExpandMore
                                          sx={{ fontSize: 20 }}
                                          {...props}
                                        />
                                      )}
                                      {...field}
                                      value={JSON.stringify(field?.value)}
                                    >
                                      {phoneCodeList.map((code) => {
                                        return (
                                          <MenuItem
                                            key={code.label}
                                            value={code}
                                          >
                                            {code.phone_code} {code.label}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  );
                                }}
                                name="countryPrefix"
                                control={control}
                              />
                            </FormControl>
                          ),
                        }}
                        error={
                          errPhoneStatus.status
                            ? errPhoneStatus.message
                            : error?.message || ""
                        }
                        {...field}
                        autoComplete="new-password"
                        onPaste={(e) => {
                          const prepareVal = (v) => {
                            if (/^\+[\d]+/.test(v)) {
                              return v.substring(2);
                            }
                            return v;
                          };

                          onPastePhone({
                            e,
                            setValue: (type, val) =>
                              setValue(type, prepareVal(val)),
                            currentValue: formField?.phone,
                          });
                        }}
                      />
                    )}
                    name="phone"
                    control={control}
                  />
                }
              />
              <TableItem
                title="Website"
                // value={formField.website}
                value={setWebsiteMask(formField.website)}
                isEdit={isEdit}
                EditField={
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <StyledTextField
                        fullWidth
                        size="large"
                        InputProps={{ className: classes.editInput }}
                        error={error?.message || ""}
                        {...field}
                      />
                    )}
                    name="website"
                    control={control}
                  />
                }
              />
            </Box>
          </Box>
        </Box>
        <Box mt="26px">
          <ContactsTable
            contacts={fields}
            {...{
              isEdit,
              control,
              handleAddContact,
              handleRemoveContact,
              handleChangeDefault,
              setValue,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

SummaryTab.propTypes = {
  control: object,
  isEdit: bool,
  handleUpdateLogo: func,
  handleDeleteLogo: func,
  setValue: func,
  errPhoneStatus: object,
};

export default SummaryTab;
