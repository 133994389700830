import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  IconButton,
  Divider,
} from "@mui/material";
import { bool, func, array, string } from "prop-types";
import { CrossIcon, CustomerIcon, DangerIcon } from "../Icons";

import { TypographyAddress, StyledButton } from "../index";

import useStyles from "./styles";

export const DuplicatePopup = ({
  isOpen,
  handleClose,
  handleProceed,
  duplicates,
  type,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Box className={classes.titleWrapper}>
          <DangerIcon />
          <Typography fontSize="17px" color="#3F3F3F">
            Duplicates found
          </Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <Divider />

      <DialogContent className={classes.dialogContent}>
        {duplicates.map((duplicate) => {
          return (
            <Box className={classes.customerItemWrapper} key={duplicate.id}>
              <Box className={classes.customerName}>
                <CustomerIcon />
                <Typography className={classes.customerTitle}>
                  {duplicate.name} (ID: {duplicate.customId.customId})
                </Typography>
              </Box>
              <TypographyAddress
                styles={{ fontSize: "12px" }}
                address={duplicate[type]?.formatted_address}
              />
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <StyledButton
          variant="contained"
          label="Proceed With Duplicate"
          onClick={() => handleProceed(type)}
          fontSize="15px"
          className={classes.actionButton}
        />
        <StyledButton
          variant="outlined"
          label="Cancel"
          color="cancel"
          fontSize="15px"
          onClick={handleClose}
          className={classes.actionButton}
        />
      </DialogActions>
    </Dialog>
  );
};

DuplicatePopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  handleProceed: func,
  duplicates: array,
  type: string,
};

DuplicatePopup.defaultProps = {
  isOpen: false,
  duplicates: [],
  type: "",
};
