import { string } from "prop-types";

import { cl } from "../styles";

import { Box, Stack, Typography } from "@mui/material";

export const ProductNameBlock = ({ productName, sku, size, color }) => {
  return (
    <Stack direction="column">
      <Box sx={cl.productNameWrapper}>
        <Typography sx={cl.productName}>{productName}</Typography>
      </Box>

      <Typography sx={cl.skuSpan}>
        {!!sku && <span>#{sku}</span>}
        {(size || color) && (
          <span>
            {`/ ${size ? `${size}` : ""} ${
              color ? `${size ? "/" : ""} ${color}` : ""
            } `}{" "}
          </span>
        )}
      </Typography>
    </Stack>
  );
};

ProductNameBlock.propTypes = {
  productName: string,
  sku: string,
  size: string,
  color: string,
};

ProductNameBlock.defaultProps = { productName: "-", sku: "-" };
