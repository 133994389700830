import { useMemo } from "react";
import { array, func, number, object, shape, string } from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { StyledProductIco } from "components";
import { photoUrl } from "helpers/helpers";

export const ProductItem = ({
  product,
  handleDeleteProductDraft,
  parentProduct,
}) => {
  const {
    wholesalePrice,
    value,
    name,
    manufacturer,
    sku,
    color,
    size,
    photos,
    photo,
  } = useMemo(() => product, [product]);

  const variation = useMemo(() => color || size, [color, size]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "128px",
        borderTop: "0.5px solid #D5D9D9",
        pl: 4.25,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "95px", minWidth: "95px", height: "95px" }}>
        <StyledProductIco
          src={
            photos?.length
              ? photoUrl(photos[0].fileName)
              : photo
              ? photoUrl(photo?.fileName)
              : ""
          }
          styles={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
            borderRadius: "4px",
            border: "0.5px solid #D5D9D9",
          }}
          placeholderStyles={{
            width: "95px",
            height: "95px",
            wrapperProps: { height: "95px" },
          }}
        />
      </Box>

      <Box
        width="80%"
        height="100%"
        py={2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="column"
          pl={2.25}
          pr={4.25}
          width="100%"
        >
          <Typography
            sx={{
              lineHeight: 1.1,
              fontSize: "18px",
              color: "#3F3F3F",
              maxHeight: "40px",
              overflow: "hidden",
            }}
          >
            {name || parentProduct?.name} / {sku}{" "}
            {variation && `/ ${variation}`}
          </Typography>

          <Typography fontSize={14} fontWeight={300} color="#000" noWrap>
            by {manufacturer?.name || parentProduct?.manufacturer?.name}
          </Typography>
        </Box>

        <Box
          width="100%"
          pl={2.25}
          pr={4.25}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center" gap={2.75}>
            <Box
              sx={{
                width: "fit-content",
                minWidth: "62px",
                height: "30px",
                border: "0.5px solid #D5D9D9",
                borderRadius: "4px",
                px: 0.5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 20,
                color: "#3F3F3F",
                cursor: "not-allowed",
              }}
            >
              {value}
            </Box>

            <Typography fontSize={20} fontWeight={600} color="#47A06D">
              ${wholesalePrice?.toFixed(2)}{" "}
              <Box fontWeight={300} color="#3F3F3F" component="span">
                each
              </Box>
            </Typography>
          </Box>
          <Button
            color="groundBlack"
            sx={{ "& .MuiTypography-root": { color: "#000" } }}
            onClick={() => handleDeleteProductDraft(product)}
          >
            <Typography sx={{ fontSize: 16, textDecoration: "underline" }}>
              Remove
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

ProductItem.propTypes = {
  product: shape({
    id: string,
    wholesalePrice: number,
    quantity: number,
    name: string,
    manufacturer: object,
    sku: string,
    color: string,
    size: string,
    photo: object,
    photos: array,
  }),
  handleDeleteProductDraft: func,
  parentProduct: object,
};
ProductItem.defaultProps = {
  product: {
    id: "",
    wholesalePrice: "",
    quantity: "",
    name: "",
    manufacturer: {},
    sku: "",
    color: "",
    size: "",
    photos: "",
    photo: "",
    parentProduct: {},
  },
  handleDeleteProductDraft: () => {},
};
