import { array, func, object } from "prop-types";

import { Stack } from "@mui/material";
import { FileInput } from "../FileInput";
import { FileItem } from "../FileItem/FileItem";

export const FileAttachmentBlock = ({
  inputRef,
  onAttach,
  attachments,
  handleRemoveFile,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="8px"
      sx={{
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        "&::-webkit-scrollbar": {
          display: "none",
          width: "0px",
        },
      }}
    >
      {!!attachments.length && (
        <Stack
          direction="row"
          alignItems="center"
          gap="8px"
          sx={{ mr: "12px" }}
        >
          {attachments?.map((attachment) => (
            <FileItem
              key={attachment.name}
              {...{ attachment, handleRemoveFile }}
            />
          ))}
        </Stack>
      )}
      <FileInput {...{ inputRef, onAttach, attachments }} />
    </Stack>
  );
};

FileAttachmentBlock.propTypes = {
  inputRef: object,
  onAttach: func,
  handleRemoveFile: func,
  attachments: array,
};
