import { useState } from "react";
import { func, number, string } from "prop-types";

import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import { StyledTextField } from "simply-depo-ui-components";
import { ImagePlaceholder, OrderStatuses } from "../../../components";
import { SearchIcon } from "../../../components/Icons";

import { cl } from "./BrandHeader.style";

export const BrandHeader = ({
  logo,
  name,
  minimumOrderValue,
  address,
  productsCount,
  aboutCompany,
  orderStatus,
  terms,
  onSearch,
  onClickViewProfile,
}) => {
  const { leftSide, searchSide, rightSide, collapseSide } = cl;

  const [isOpen, setOpen] = useState(false);

  return (
    <Paper elevation={0} variant="outlined">
      <Grid container>
        {/* left side */}
        <Grid sx={leftSide.mainWrapper} xs="auto" item>
          {/* arrow */}
          <Box sx={leftSide.arrowWrapper}>
            <IconButton p="3px" onClick={() => setOpen((prev) => !prev)}>
              {isOpen ? (
                <KeyboardArrowUp sx={{ fontSize: 34 }} />
              ) : (
                <KeyboardArrowDown sx={{ fontSize: 34 }} />
              )}
            </IconButton>
          </Box>

          {/* logo */}
          <Box sx={leftSide.logoWrapper}>
            {logo ? (
              <Box sx={leftSide.logo} src={logo} component="img" alt="Logo" />
            ) : (
              <Box width="50px" height="50px">
                <ImagePlaceholder width="inherit" height="inherit" />
              </Box>
            )}
          </Box>

          {/* Supplier/vendor name and Info */}
          <Box sx={leftSide.nameWrapper}>
            <Typography
              fontSize={19}
              color="clearBlack.main"
              width="100%"
              noWrap
            >
              {name}
            </Typography>
            <Typography
              fontSize={19}
              fontWeight={300}
              color="clearBlack.main"
              width="100%"
              noWrap
            >
              {minimumOrderValue
                ? `${minimumOrderValue} minimum`
                : "No minimum"}
            </Typography>
          </Box>

          {/* divider */}
          <Box sx={leftSide.dividerWrapper}>
            <Box sx={leftSide.divider} />
          </Box>

          {/* Address / Web */}
          <Box sx={leftSide.addressWrapper}>
            <Typography
              fontSize={19}
              color="clearBlack.main"
              width="100%"
              noWrap
            >
              {address}
            </Typography>
            <Link
              sx={{
                fontSize: 19,
              }}
              underline="hover"
              component="button"
              onClick={onClickViewProfile}
            >
              View Profile
            </Link>
          </Box>

          {/* empty block */}
          <Box width="12px" />
        </Grid>

        {/* search bar */}
        <Grid sx={searchSide.mainWrapper} xs item>
          <StyledTextField
            inputSx={searchSide.searchInput}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            placeholder={"Search by product name or SKU"}
            onChange={(e) => onSearch(e.target.value)}
          />
        </Grid>

        {/* right side */}
        <Grid xs="auto" item container>
          {/* empty block */}
          <Box width="12px" />

          {/* btn */}
          <Box sx={rightSide.btnWrapper}>
            <Button sx={rightSide.btn} variant="contained">
              Contact
            </Button>
          </Box>
          <Box
            sx={rightSide.productsWrapper}
            component={Typography}
            color="clearBlack"
          >
            {!!productsCount &&
              `${productsCount} product${productsCount > 1 ? "s" : ""}`}
          </Box>
        </Grid>
      </Grid>

      <Collapse in={isOpen}>
        <Grid container>
          <Grid pb={3} xs item>
            {/* About Company */}
            <Box sx={collapseSide.descriptionWrapper}>
              <Box
                sx={collapseSide.description}
                color="clearBlack.main"
                component={Typography}
              >
                {aboutCompany}
              </Box>
            </Box>
          </Grid>

          <Grid sx={collapseSide.mainWrapper} xs={5} item>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {/* Terms */}
              <Box sx={collapseSide.termsWrapper}>
                <Typography fontSize={19} color="clearBlack">
                  Terms:{" "}
                  <Box sx={{ color: "#53B986" }} component="span">
                    {terms}
                  </Box>
                </Typography>
              </Box>

              {/* divider */}
              <Box sx={collapseSide.dividerWrapper}>
                <Box sx={collapseSide.divider} />
              </Box>

              {/* Order direct */}
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography fontSize={19} color="clearBlack">
                  Order Direct:
                </Typography>
                <OrderStatuses
                  type={orderStatus}
                  fontStyle={{ fontSize: 19 }}
                />
              </Box>

              {/* divider */}
              <Box sx={collapseSide.dividerWrapper}>
                <Box sx={collapseSide.divider} />
              </Box>

              {/* Shipping & Returns */}
              <Link
                color="inherit"
                sx={collapseSide.link}
                // eslint-disable-next-line no-console
                onClick={() => console.log("click")}
              >
                Shipping & Returns
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </Paper>
  );
};

BrandHeader.propTypes = {
  logo: string,
  name: string,
  minimumOrderValue: number,
  address: string,
  productsCount: number,
  aboutCompany: string,
  orderStatus: string,
  terms: string,
  onSearch: func,
  onClickViewProfile: func,
};
BrandHeader.defaultProps = {
  logo: "",
  name: "",
  minimumOrderValue: 0,
  address: "",
  productsCount: 0,
  aboutCompany: "",
  orderStatus: "",
  terms: "",
  onSearch: () => {},
  onClickViewProfile: () => {},
};
