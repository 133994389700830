import { array, func, number } from "prop-types";
import { Box, CircularProgress, Typography } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { StyledTooltip } from "components";

export const SuppliersFilter = ({
  items,
  handleFetch,
  handleChange,
  itemsCount,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        border: "1px solid #d5d9d9",
        borderRadius: "4px",
        padding: "18px 16px 26px",
        bgcolor: "#26874B20",
      }}
    >
      <Typography
        sx={{
          fontSize: "19px",
          fontWeight: "500",
          marginBottom: "18px",
        }}
      >
        Suppliers
      </Typography>
      <Box
        id="suppliers-settings-scroll"
        sx={{
          width: "100%",
          maxHeight: "218px",
          overflow: "auto",
        }}
      >
        <InfiniteScroll
          dataLength={items?.length}
          next={handleFetch}
          loader={
            <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
          }
          hasMore={items?.length < itemsCount}
          scrollableTarget={"suppliers-settings-scroll"}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            {items.map((item) => (
              <Box
                key={item?.id}
                sx={{ cursor: "pointer" }}
                onClick={() => handleChange(item)}
              >
                <StyledTooltip
                  title={
                    <>
                      <Typography fontSize="12px">
                        {item?._count?.products || "-"} Products
                      </Typography>
                      <Typography fontSize="12px">
                        {item?.shippingAddress?.city || "-"},{" "}
                        {item?.shippingAddress?.state || "-"}
                      </Typography>
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <Typography
                    ml={2}
                    fontSize={18}
                    color="#000"
                    display="inline-block"
                  >
                    {item?.name}
                  </Typography>
                </StyledTooltip>
              </Box>
            ))}
          </Box>
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

SuppliersFilter.propTypes = {
  items: array,
  handleFetch: func,
  handleChange: func,
  itemsCount: number,
};
