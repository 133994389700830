import { shape, string, object, array, number } from "prop-types";
import { Controller } from "react-hook-form";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

export const ViewSettings = ({ index, item, control }) => {
  const { name, type, items } = item;

  return (
    <Grid
      sx={{
        border: "1px solid #D5D9D9", // check theme
        borderRadius: "4px",
        width: "246px",
      }}
      container
      item
    >
      <Grid
        xs={12}
        item
        component={Typography}
        fontSize={19}
        fontWeight={500}
        color="clearBlack"
        pl={2}
        py={2}
      >
        {name}
      </Grid>
      <Grid sx={{ pl: 2, pt: 1, pb: 2 }} xs={12} item container>
        {type === "radio" && (
          <Grid xs={12} item>
            <Controller
              render={({ field }) => (
                <FormControl>
                  <RadioGroup {...field}>
                    {items?.map((item) => {
                      const name = `${item?.name}${
                        item?.count ? ` (${item?.count})` : ""
                      }`;
                      return (
                        <FormControlLabel
                          key={item?.id}
                          label={
                            <Typography
                              sx={{
                                fontSize: 18,
                                color: "#8E8E8E", // theme
                              }}
                            >
                              {name}
                            </Typography>
                          }
                          value={item?.id}
                          control={<Radio />}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              )}
              name={`items[${index}].selected`}
              control={control}
            />
          </Grid>
        )}
        {type === "checkbox" && (
          <Grid xs={12} item>
            <FormControl>
              <FormGroup>
                {items?.map((item, i) => (
                  <Controller
                    key={item?.id}
                    render={({ field }) => (
                      <FormControlLabel
                        label={
                          <Typography
                            sx={{
                              fontSize: 18,
                              color: "#8E8E8E", // theme
                            }}
                          >
                            {item?.name}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                            checked={item?.checked}
                          />
                        }
                        {...field}
                      />
                    )}
                    name={`items[${index}].items[${i}].checked`}
                    control={control}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

ViewSettings.propTypes = {
  index: number,
  item: shape({
    name: string,
    type: string,
    items: array,
  }),
  control: object,
};
ViewSettings.defaultProp = {
  item: {
    name: "",
    type: "",
    items: [],
  },
};
