import { Controller } from "react-hook-form";
import { array, func, object } from "prop-types";

import { LargeCheckbox } from "components";
import { WarningBox } from "components/AddCardDialog/components";

import { cl as classes } from "../../ContactDrawer/styles";

import { Collapse, InputLabel, Stack, Typography } from "@mui/material";

export const ContentBlock = ({ FIELD_DATA, control, errors }) => {
  return (
    <Stack direction="column" gap="20px" mt="20px">
      {!!Object.keys(errors).length && (
        <Collapse in={!!Object.keys(errors).length}>
          <WarningBox errors={Object.entries(errors)} />
        </Collapse>
      )}

      <Stack direction="column" gap="12px">
        {FIELD_DATA.slice(0, 2).map(
          (
            { name, Input, label, mask, customInput, format, placeholder },
            index
          ) => (
            <Controller
              key={index}
              render={({ field, fieldState: { error } }) => (
                <Stack>
                  <InputLabel
                    shrink
                    sx={{
                      ...classes.label,
                      color: error
                        ? "rgba(228, 30, 58, 0.60)"
                        : "rgba(0, 0, 0, 0.60)",
                    }}
                  >
                    {label}
                  </InputLabel>
                  <Input
                    {...(!!customInput && { mask, customInput, format })}
                    fullWidth
                    size="small"
                    error={error ? error.message : ""}
                    InputProps={{
                      sx: {
                        ...classes.textInput,
                        "& > fieldset": {
                          borderColor: error
                            ? "rgba(228, 30, 58, 0.60) !important"
                            : "rgb(213,217,217)!important",
                          borderWidth: error
                            ? "1px !important"
                            : "0px !important",
                        },
                      },
                      placeholder,
                    }}
                    {...field}
                  />
                </Stack>
              )}
              name={name}
              control={control}
            />
          )
        )}
        <Stack direction="row" gap="26px" width="100%">
          {FIELD_DATA.slice(2, 4).map(
            (
              {
                name,
                Input,
                label,
                mask,
                customInput,
                format,
                placeholder,
                isAllowed,
              },
              index
            ) => (
              <Controller
                key={index}
                render={({ field, fieldState: { error } }) => (
                  <Stack>
                    <InputLabel
                      shrink
                      sx={{
                        ...classes.label,
                        color: error
                          ? "rgba(228, 30, 58, 0.60)"
                          : "rgba(0, 0, 0, 0.60)",
                      }}
                    >
                      {label}
                    </InputLabel>
                    <Input
                      {...(!!customInput && {
                        mask,
                        customInput,
                        format,
                        isAllowed,
                      })}
                      fullWidth
                      size="small"
                      error={error ? error.message : ""}
                      InputProps={{
                        sx: {
                          ...classes.textInput,
                          "& > fieldset": {
                            borderColor: error
                              ? "rgba(228, 30, 58, 0.60) !important"
                              : "rgb(213,217,217)!important",
                            borderWidth: error
                              ? "1px !important"
                              : "0px !important",
                          },
                        },
                        placeholder,
                      }}
                      {...field}
                    />
                  </Stack>
                )}
                name={name}
                control={control}
              />
            )
          )}
        </Stack>
        <Controller
          render={({ field }) => (
            <LargeCheckbox
              //disabled={!contacts.length}
              checked={!!field.value}
              formSx={{
                ml: 0,
                "& .MuiFormControlLabel-root": {
                  ml: 0,
                },
              }}
              checkboxSx={{
                padding: 0,
                margin: "0 8px 0 1px",
              }}
              label={
                <Typography color="black" fontSize="14px" fontWeight="400">
                  Set as primary payment
                </Typography>
              }
              size={15}
              {...field}
            />
          )}
          name="defaultMethod"
          control={control}
        />
      </Stack>
    </Stack>
  );
};

ContentBlock.propTypes = {
  FIELD_DATA: array,
  control: object,
  errors: object,
  formField: object,
  handleSubmit: func,
  onSubmit: func,
  isCustom: func,
};
