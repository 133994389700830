import { array } from "prop-types";
import Slider from "react-slick";

import { PaymentItem } from "./PaymentItem";

import { Box, Stack } from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useViewOrderInfoDrawer } from "../useViewOrderInfoDrawer";

export const ContentBlock = ({ payments, orderRefunds }) => {
  const { getCardTitle, handleIsRefund } = useViewOrderInfoDrawer({
    orderRefunds,
  });
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: () => (
      <Box
        style={{
          width: "4px",
          height: "4px",
          opacity: 0.3,
          background: "#409A65",
          borderRadius: 9999,
        }}
      />
    ),
  };

  return (
    <>
      {(payments.length || orderRefunds.length) && (
        <Stack
          height="100%"
          width="100%"
          sx={{
            "& .slick-list": {
              margin: "0 -10px",
            },

            "& .slick-dots": {
              bottom: "-32px",
            },
            "& .slick-dots li": {
              margin: "3px",
              width: "4px",
              height: "4px",
            },
            "& .slick-dots li.slick-active": {
              width: "5px !important",
              height: "5px !important",
              "& .MuiBox-root": {
                width: "6px !important",
                height: "6px !important",
                opacity: "1 !important",
              },
            },
          }}
        >
          <Slider {...settings}>
            {[...payments, ...orderRefunds].map((payment, index) => (
              <PaymentItem
                key={payment.id}
                {...{
                  index: index + 1,
                  payment,
                  isRefund: handleIsRefund(payment.id),
                  title: getCardTitle(payment.paymentType),
                }}
              />
            ))}
          </Slider>
        </Stack>
      )}
    </>
  );
};

ContentBlock.propTypes = {
  payments: array,
  orderRefunds: array,
};
