/* eslint-disable react/prop-types */
import React from "react";

export const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
    >
      <path
        id="icons8-clock_1_"
        data-name="icons8-clock (1)"
        d="M7.5,1A6.5,6.5,0,1,0,14,7.5,6.51,6.51,0,0,0,7.5,1Zm0,1A5.5,5.5,0,1,1,2,7.5,5.493,5.493,0,0,1,7.5,2ZM7,3V8h3V7H8V3Z"
        transform="translate(-1 -1)"
        fill="#b5b5ac"
      />
    </svg>
  );
};
