import { any, object, shape, string } from "prop-types";

import MobileRejectScreen from "./components/MobileRejectScreen/MobileRejectScreen";
import DesktopRejectScreen from "./components/DesktopRejectScreen/DesktopRejectScreen";

import { useMediaDevice } from "hooks/useMediaDevice";

const RejectScreen = ({ reject }) => {
  const { isMobile } = useMediaDevice();

  return (
    <>
      {isMobile ? (
        <MobileRejectScreen reject={reject} />
      ) : (
        <DesktopRejectScreen reject={reject} />
      )}
    </>
  );
};

RejectScreen.propTypes = {
  reject: shape({
    approvedPaymentTermsDuplicate: any,
    distributor: object,
    note: string,
    status: string,
  }),
};

export default RejectScreen;
