import * as React from "react";
export const PhoneIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.713}
    height={15.713}
    viewBox="0 0 15.713 15.713"
    {...props}
  >
    <path
      data-name="Icon awesome-phone"
      d="M15.142.755 11.951.019a.741.741 0 0 0-.844.427L9.634 3.883a.735.735 0 0 0 .212.859l1.855 1.522a11.374 11.374 0 0 1-5.434 5.437L4.745 9.842a.736.736 0 0 0-.859-.212L.449 11.101a.745.745 0 0 0-.43.847l.737 3.192a.736.736 0 0 0 .718.571A14.238 14.238 0 0 0 15.713 1.474a.736.736 0 0 0-.571-.719Z"
      fill="#47a06d"
    />
  </svg>
);
export default PhoneIcon;
