import { array, func, object, string } from "prop-types";

import { Box, Chip } from "@mui/material";

export const MobileTabs = ({
  tabs,
  tabValue,
  setTabValue,
  wrapperProps,
  resetFunc,
}) => {
  return (
    <Box py="8px" {...wrapperProps}>
      <Box display="flex" alignItems="center" gap="8px">
        {tabs
          .filter((t) => t?.mobile?.show)
          .map((tab) => {
            const isActive = tabValue === tab.value;

            return (
              <Chip
                key={tab.value}
                sx={{
                  borderColor: isActive ? "#409A6530" : "#ECECEC",
                  backgroundColor: isActive ? "#409A6510" : "#E8E8E810",
                  height: "26px",
                  borderRadius: "8px",
                  "& span": {
                    color: isActive ? "#000000" : "#00000060",
                    fontSize: 12,
                    fontWeight: 500,
                    padding: "0px 8px",
                  },
                }}
                label={tab.name}
                variant="outlined"
                onClick={() => {
                  setTabValue(tab.value);
                  resetFunc && resetFunc();
                }}
              />
            );
          })}
      </Box>
    </Box>
  );
};

MobileTabs.propTypes = {
  tabs: array,
  tabValue: string,
  setTabValue: func,
  resetFunc: func,
  wrapperProps: object,
};
