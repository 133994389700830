import { forwardRef, useEffect } from "react";
import { Grid, Link, Typography, Box } from "@mui/material";
import { bool, object, func, string, array } from "prop-types";
import { ImagePlaceholder, LargeCheckbox, StyledTooltip } from "components";
import { photoUrl, separateNumWithComma } from "helpers/helpers";
import {
  InformationIcon,
  TriangleDownIcon,
  TriangleRightIcon,
} from "components/Icons";
import useStyles from "./styles";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";
import { AvailableBlock, PriceBlock } from "./components";

const getRangeWholeSalePrice = (childProducts) => {
  if (!childProducts || !childProducts?.length) return "";

  const getChildrenWholesales = childProducts?.map(
    (childProd) => childProd?.wholesalePrice || 0
  );

  const getUniqueWholesales = [...new Set(getChildrenWholesales)];

  const sortedResult = getUniqueWholesales.sort((a, b) => a - b);

  const getRange =
    sortedResult.length > 1
      ? [sortedResult[0], sortedResult[sortedResult.length - 1]]
      : [sortedResult[0]];

  const preparePrices = getRange
    .map((price) => `$${separateNumWithComma(price)}`)
    .join(" - ");

  return preparePrices;
};

export const getRangeRetilPrice = (childProducts) => {
  if (!childProducts || !childProducts?.length) return "";

  const getChildrenRetail = childProducts?.map(
    (childProd) => childProd?.retailPrice
  );
  const getUniqueRetail = [...new Set(getChildrenRetail)];

  const sortedResultRetail = getUniqueRetail.sort((a, b) => a - b);

  const getRangeRetail =
    sortedResultRetail.length > 1
      ? [
          sortedResultRetail[0],
          sortedResultRetail[sortedResultRetail.length - 1],
        ]
      : [sortedResultRetail[0]];

  const preparePricesRetail = getRangeRetail
    .map((price) => `$${separateNumWithComma(price)}`)
    .join(" - ");

  return preparePricesRetail;
};

export const ProductItem = forwardRef(
  (
    {
      product,
      parent,
      isChecked,
      disabled,
      lastChild,
      handleExpandVariations,
      expandedParentId,
      isChild,
      handleCheckProduct,
      outOfStock,
      parentInOrder,
      onMouseEnter,
      onMouseLeave,
      isOrder,
      disableIfChosenProduct,
      indeterminateParent,
      setExpandedParentId,
      isMissingInfo,
      hideAvailable,
      supplierWithOnlyOneProductWithVariations,
    },
    ref
  ) => {
    const classes = useStyles();

    const isNonInventory =
      product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

    const setBackgroundColor = () => {
      if (isChecked) return "#ECF5F0";
      if (isChild) return "#F7F7F7";
      if (expandedParentId === product.id || isChild)
        return "rgba(71, 160, 109, 0.1)";
    };

    const calculatePosition = () => {
      if (expandedParentId === product.id) return "12px";
      if (lastChild) return "-16px";
    };

    useEffect(() => {
      if (supplierWithOnlyOneProductWithVariations) {
        handleExpandVariations(product.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product.id, supplierWithOnlyOneProductWithVariations]);

    return (
      <Grid
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
        container
        key={product.id}
        columns={9}
        height="47px"
        sx={{
          overflow: "hidden",
          backgroundColor: setBackgroundColor,
          opacity: disabled || outOfStock ? 0.5 : 1,
          position: "relative",
          "&:not(:last-of-type)": {
            borderBottom: "0.5px solid #D5D9D9",
          },
          "&:hover": {
            backgroundColor:
              !isChecked &&
              expandedParentId !== product.id &&
              !isChild &&
              "#F7F7F7",
          },
        }}
      >
        {(expandedParentId === product.id || isChild) && (
          <Box
            sx={{
              width: "3px",
              height: "110%",
              backgroundColor: "#42A57F",
              borderRadius: "4px",
              position: "absolute",
              top: calculatePosition,
              left: "0px",
            }}
          />
        )}
        <Grid item xs={0.5} className={classes.grid}>
          <LargeCheckbox
            size={16}
            sx={{
              "&.MuiCheckbox-indeterminate svg": { fontSize: 22 },
            }}
            formSx={{ m: 0 }}
            disabled={disabled || outOfStock}
            checked={!disabled && isChecked}
            indeterminate={indeterminateParent}
            onChange={() => {
              if (!isChild) setExpandedParentId(product.id);
              handleCheckProduct({
                ...product,
                quantity: 1,
                parentProduct: isChild && parent,
                itemDiscountType: "PERCENTAGE",
                itemDiscountAmount: 0,
                isNewAdded: true,
              });
            }}
          />
        </Grid>

        {/* Product */}
        <Grid item xs={0.7} display="flex" alignItems="center">
          {/* Picture */}
          <Box
            className={classes.productPhoto}
            style={{ height: "38px", width: "38px" }}
          >
            {product?.photos[0]?.fileName ? (
              <Box
                component="img"
                src={photoUrl(product.photos[0].fileName)}
                width="36px"
                height="36px"
                borderRadius="4px"
                sx={{ objectFit: "contain" }}
              />
            ) : (
              <ImagePlaceholder
                width="36px"
                height="36px"
                style={{
                  objectFit: "cover",
                  borderRadius: "4px",
                  borderWidth: 0,
                }}
              />
            )}
            {/* <StyledProductIco
              src={
                product?.photos?.length
                  ? photoUrl(product.photos[0].fileName)
                  : ""
              }
              styles={{
                objectFit: "contain",
                width: "100%",
                height: "100%",
                borderRadius: "4px",
              }}
            /> */}
          </Box>
        </Grid>

        {/* Name */}
        <Grid
          item
          // xs={2.8}
          xs={4.6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box display="flex" alignItems="center" gap={0.5}>
            <Typography
              className={classes.text}
              style={{
                fontWeight: 500,
                maxWidth: isMissingInfo?.length ? "80%" : "100%",
                marginRight: isMissingInfo?.length ? 0 : "4px",
              }}
            >
              {product.name || parent.name}
            </Typography>
            {!!isMissingInfo?.length && (
              <StyledTooltip
                arrow
                title={
                  <Box textAlign="left">
                    <Typography fontSize={12}>Missing info:</Typography>
                    {isMissingInfo?.map((info) => (
                      <Typography key={info} fontSize={12} fontWeight={300}>
                        {info}
                      </Typography>
                    ))}
                  </Box>
                }
                placement="top"
                modifiers={[{ name: "offset", options: { offset: [0, -8] } }]}
              >
                <Box display="flex" alignItems="center">
                  <InformationIcon size="15.33" stroke="#FF6969" />
                </Box>
              </StyledTooltip>
            )}
          </Box>
          {/* <Typography
            className={classes.text}
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontWeight: 400,
            }}
          >
            by {product?.manufacturer?.name || parent?.manufacturer?.name}
          </Typography> */}

          {product.childProducts?.length ? (
            <Link
              sx={{
                fontSize: "10px",
                fontWeight: 400,
                color: "#1C1C19",
                underline: "hover",
                width: "fit-content",
              }}
              component="button"
              onClick={() => handleExpandVariations(product.id)}
            >
              {expandedParentId === product.id ? (
                <TriangleDownIcon />
              ) : (
                <TriangleRightIcon />
              )}
              <Box mr="6px" component="span" />
              {product.childProducts.length} Variations
            </Link>
          ) : disableIfChosenProduct || !disabled ? (
            <Typography fontSize="10px" fontWeight="400" color="#1C1C19" noWrap>
              {`${product.sku} ${product.size ? `/ ${product.size}` : ""} ${
                product.color ? `/ ${product.color}` : ""
              }`}
            </Typography>
          ) : (
            <Typography fontSize="10px" fontWeight="400" color="#1C1C19" noWrap>
              {`${product.sku} ${product.size ? `/ ${product.size}` : ""} ${
                product.color ? `/ ${product.color}` : ""
              }`}
            </Typography>
          )}
        </Grid>

        {/* Variations */}
        {/* <Grid
          item
          xs={1.8}
          display="flex"
          alignItems="center"
        >
          {product.childProducts?.length ? (
            <Link
              sx={{
                fontSize: "10px",
                fontWeight: 400,
                color: "#1C1C19",
                underline: "hover",
              }}
              component="button"
              onClick={() => handleExpandVariations(product.id)}
            >
              {expandedParentId === product.id ? (
                <TriangleDownIcon />
              ) : (
                <TriangleRightIcon />
              )}
              <Box mr="6px" component="span" />
              {product.childProducts.length} Variations
            </Link>
          ) : disableIfChosenProduct || !disabled ? (
            <Typography fontSize="10px" fontWeight="400" color="#1C1C19" noWrap>
              {product.sku}
              {(product.size || product.color) &&
                ` / ${product.size || product.color}`}
            </Typography>
          ) : (
            ""
          )}
        </Grid> */}

        {/* Already added */}
        {parentInOrder ||
        (disabled &&
          !outOfStock &&
          !disableIfChosenProduct &&
          !isMissingInfo?.length) ? (
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography
              fontSize="12px"
              fontWeight="400"
              color="#47A06D"
              mr={5.5}
            >
              Already {isOrder ? "in the order" : "added"}
            </Typography>
          </Grid>
        ) : (
          <>
            {/* Available QTY */}
            <Grid item xs={1.6} display="flex" alignItems="center">
              <AvailableBlock
                isNonInventory={isNonInventory}
                productInventory={product?.inventory}
                hideAvailable={hideAvailable}
                outOfStock={outOfStock}
              />
            </Grid>

            {/* Price */}
            <Grid item xs={1.6} display="flex" alignItems="center">
              <PriceBlock
                getRangeWholeSalePrice={getRangeWholeSalePrice}
                productSku={product?.sku}
                productWholesalePrice={product?.wholesalePrice}
                isChild={isChild}
                childProducts={product?.childProducts}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }
);

ProductItem.propTypes = {
  product: object,
  manufacturer: object,
  isChecked: bool,
  disabled: bool,
  handleExpandVariations: func,
  expandedParentId: string,
  parent: object,
  isChild: bool,
  handleCheckProduct: func,
  lastChild: bool,
  outOfStock: bool,
  parentInOrder: bool,
  onMouseEnter: func,
  onMouseLeave: func,
  isOrder: bool,
  hideAvailable: bool,
  disableIfChosenProduct: bool,
  indeterminateParent: bool,
  setExpandedParentId: func,
  isMissingInfo: array,
  supplierWithOnlyOneProductWithVariations: bool,
};

ProductItem.defaultProps = {
  product: null,
  manufacturer: null,
  isChecked: false,
  disabled: false,
  parent: null,
  isChild: false,
  lastChild: false,
  outOfStock: false,
  parentInOrder: false,
  isOrder: false,
  hideAvailable: false,
  disableIfChosenProduct: false,
  indeterminateParent: false,
  setExpandedParentId: () => {},
  isMissingInfo: [],
  supplierWithOnlyOneProductWithVariations: false,
};
ProductItem.displayName = "ProductItem";
