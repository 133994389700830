import { Box, Typography } from "@mui/material";
import { object } from "prop-types";
import StyledLabel from "../../../../../../../../components/StyledLabel/StyledLabel";
import { cl } from "./styles";

const CarriersItem = ({ carrier }) => {
  const {
    CarriersItem: { wrapper },
  } = cl;

  if (!carrier) return <></>;
  return (
    <Box sx={wrapper}>
      <Box display="flex" gap="10px" alignItems="center">
        <Box display="flex" gap="10px" alignItems="center">
          <Typography color="#979797">{carrier.name} </Typography>
          {carrier.default && <StyledLabel style={{ mt: "2px" }} />}
        </Box>
      </Box>
    </Box>
  );
};

CarriersItem.propTypes = {
  carrier: object,
};

export default CarriersItem;
