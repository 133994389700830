import { func, shape, string } from "prop-types";
import { Box, Button, Typography } from "@mui/material";

export const ButtonsBlock = ({ goToCartProps, continueShoppingProps }) => {
  return (
    <Box px={4.25} display="flex" flexDirection="column" gap={2.5}>
      <Button
        sx={{ height: "58px" }}
        variant="contained"
        fullWidth
        onClick={goToCartProps?.onClick}
      >
        <Typography fontSize={24} fontWeight={500}>
          {goToCartProps?.label}
        </Typography>
      </Button>

      <Button
        sx={{ height: "58px" }}
        variant="outlined"
        fullWidth
        onClick={continueShoppingProps?.onClick}
      >
        <Typography fontSize={24} fontWeight={500}>
          {continueShoppingProps?.label}
        </Typography>
      </Button>
    </Box>
  );
};

ButtonsBlock.propTypes = {
  goToCartProps: shape({ label: string, onClick: func }),
  continueShoppingProps: shape({ label: string, onClick: func }),
};
ButtonsBlock.defaultProps = {
  goToCartProps: { label: "Go to Cart", onClick: () => {} },
  continueShoppingProps: { label: "Continue Shopping", onClick: () => {} },
};
