import React from "react";

export const PricingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55.434"
      height="55.436"
      viewBox="0 0 55.434 55.436"
    >
      <path
        id="Path_10228"
        data-name="Path 10228"
        d="M51.668,0H31.041a7.182,7.182,0,0,0-4.4,1.822L1.065,27.4a2.585,2.585,0,0,0,0,3.648L23.2,53.182a2.585,2.585,0,0,0,3.648,0L52.425,27.607a7.171,7.171,0,0,0,1.822-4.4V2.578A2.589,2.589,0,0,0,51.668,0ZM38.776,20.628a5.158,5.158,0,1,1,3.648-1.51,5.158,5.158,0,0,1-3.648,1.51Z"
        transform="translate(0.438 0.75)"
        fill="none"
        stroke="#5f6267"
        strokeWidth="1.5"
      />
    </svg>
  );
};
