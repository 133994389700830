import { toast } from "react-toastify";
// import { ActiveIcon, InformationIcon } from "../components/Icons";
import { getTokenFromLocalStorage } from "../helpers/auth";
import { ActiveIcon, InformationIcon } from "components/Icons";

const success = (msg, props) =>
  toast.success(msg, {
    position: "bottom-center",
    progress: undefined,
    autoClose: 2000,
    hideProgressBar: true,
    theme: "dark",
    ...props,
  });

const successMiddle = (msg, props) =>
  toast.success(msg, {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    className: "middle-toast",
    icon: <ActiveIcon />,
    ...props,
  });

const errorConfig = {
  position: "bottom-center",
  theme: "colored",
  hideProgressBar: true,
  progress: undefined,
  autoClose: 2000,
};

const warningMiddle = (msg) =>
  toast.success(msg, {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    className: "middle-toast",
    icon: <InformationIcon size="19.86" stroke="#fff" />,
  });

const error = (msg) => {
  const token = getTokenFromLocalStorage();
  if (token && window.navigator.onLine)
    return toast.error(msg || "Something went wrong!", errorConfig);
  return;
};

const warning = (msg) =>
  toast.warning(msg, {
    position: "bottom-center",
    progress: undefined,
    autoClose: 2000,
    hideProgressBar: true,
    theme: "dark",
  });

export { success, error, warning, successMiddle, warningMiddle, errorConfig };
export default {
  success,
  error,
  warning,
  successMiddle,
  warningMiddle,
};
