export const setTotal = (
  totalRawAmount = 0,
  totalDiscountValue = 0,
  deliveryFee = 0
) => {
  const res = (totalRawAmount + deliveryFee - totalDiscountValue).toFixed(2);
  if (!res) return "-";
  return +res;
};

export const createdBy = (createdType, accountType) => {
  switch (createdType) {
    case "ORDER_DIRECT":
      return "You";

    case "DISTRIBUTOR":
      return "Supplier";

    case "BRAND":
      return "Supplier";

    case "SALES":
      return "Sales rep";

    default:
      return accountType || "-";
  }
};

export const handleViewOrder = ({
  orderId,
  navigate,
  orderNumber,
  distributor = null,
}) => {
  navigate(`/orders/${orderId}`, {
    state: { type: "orders_page", orderNumber, distributor },
  });
};
