import { Button, Typography } from "@mui/material";
import PropTypes, { string, object, number } from "prop-types";
import { forwardRef } from "react";

export const StyledButton = forwardRef(
  ({ label, fontSize, fontWeight, ...props }, ref) => {
    return (
      <Button
        sx={{ paddingLeft: "12px", paddingRight: "12px", boxShadow: "none" }}
        ref={ref}
        {...props}
      >
        <Typography
          sx={{
            fontSize: fontSize,
          }}
          fontWeight={fontWeight}
          component="span"
          variant="caption"
        >
          {label}
        </Typography>
      </Button>
    );
  }
);

StyledButton.displayName = "StyledButton";

StyledButton.propTypes = {
  label: PropTypes.oneOfType([string, object]),
  fontSize: string,
  fontWeight: PropTypes.oneOfType([string, number]),
};

StyledButton.defaultTypes = {
  label: "",
  fontSize: "12px",
};
