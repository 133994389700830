import { Grid, Paper } from "@mui/material";
import { ProductVariationHeaderItem } from "pages/ProductsPage/components/ProductDetailsPage/ProductVariations/ProductVariationHeaderItem";

import { PRODUCTS_VARIATIONS_HEADER_ITEMS } from "pages/ProductsPage/ProductsPage.constants";

export const ProductVariationsHeader = () => {
  return (
    <Paper
      sx={{
        border: "0.5px solid #D5D9D9",
        borderRadius: "4px",
        backgroundColor: "#F8F8F8",
        height: "44px",
        alignItems: "center",
      }}
      elevation={0}
      square
      component={Grid}
      container
    >
      {PRODUCTS_VARIATIONS_HEADER_ITEMS.map(({ id, name, size, style }) => (
        <ProductVariationHeaderItem
          key={id}
          name={name}
          size={size}
          style={style}
        />
      ))}
    </Paper>
  );
};
