import { CategoryChip } from "components";
import { DRAWERS } from "constants/drawer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import {
  DATE_LIST,
  DEFAULT_FILTER_STATE,
  DEFAULT_FILTER_DATA,
  FULFILLMENT_STATUS_LIST,
  PAYMENT_STATUS_LIST,
} from "./OrdersPage.constants";

export const useOrdersFilter = ({ createdByList, setFilterForApply }) => {
  const dispatch = useDispatch();

  const [chipData, setChipData] = useState(DEFAULT_FILTER_STATE);
  const [filterState, setFilterState] = useState(DEFAULT_FILTER_STATE);

  const isFilterDrawerOpened = useSelector(
    ({ uiState }) => uiState.filterDrawer
  );

  const FILTER_DATA = useMemo(() => {
    return {
      paymentStatus: {
        show: true,
        value: filterState.paymentStatus.value,
      },
      fulfillmentStatus: {
        show: true,
        value: filterState.fulfillmentStatus.value,
      },
      date: {
        show: true,
        value: filterState.date.value,
      },
      createdBy: {
        show: true,
        value: filterState.createdBy.id,
        name: filterState.createdBy.name,
      },
    };
  }, [filterState]);

  const handleAppliedFilters = useCallback(() => {
    let count = 0;
    for (let key in chipData) {
      if (Object.keys(chipData[key]).length) {
        count++;
      }
    }
    return count;
  }, [chipData]);

  const filterCount = useMemo(
    () => handleAppliedFilters(),
    [handleAppliedFilters]
  );

  useEffect(() => {
    if (isFilterDrawerOpened) {
      setFilterState(chipData);
    }
  }, [chipData, isFilterDrawerOpened]);

  const handleApplyFilter = (state, chipState) => {
    const { createdBy, date, fulfillmentStatus, paymentStatus } = state;

    setFilterForApply((prev) => ({
      ...prev,
      date: date.value ? date : null,
      sales_id: createdBy.value ? createdBy : null,
      fulfillmentStatus: fulfillmentStatus.value || null,
      paymentStatus: paymentStatus.value || null,
    }));
    setChipData(chipState);
  };

  const onSave = () => {
    handleApplyFilter(FILTER_DATA, filterState);
  };

  const handleDeleteChip = (category) => {
    setChipData((prev) => ({ ...prev, [category]: {} }));
    setFilterForApply((prev) => ({
      ...prev,
      [category]: null,
      ...(category === "createdBy" && { sales_id: null }),
    }));
  };

  const onStateClear = () => {
    setFilterState(DEFAULT_FILTER_STATE);

    if (filterCount) {
      handleApplyFilter(DEFAULT_FILTER_DATA, DEFAULT_FILTER_STATE);
    }
  };

  const setState = (key, value) =>
    setFilterState((prev) => ({ ...prev, [key]: value }));

  const currentFilterElement = useMemo(() => {
    return {
      paymentStatus: {
        list: PAYMENT_STATUS_LIST,
        Item: CategoryChip,
        setState: (value) => setState("paymentStatus", value),
        defaultValue: {},
        getProps: (item, handleCheckItem, handleIsActive) => ({
          title: item.name,
          isActive: handleIsActive({
            state: filterState.paymentStatus,
            id: item.id,
          }),
          onClick: (item) =>
            handleCheckItem({
              setState: (value) => setState("paymentStatus", value),
              item,
              state: filterState.paymentStatus,
            }),
        }),
      },
      fulfillmentStatus: {
        list: FULFILLMENT_STATUS_LIST,
        Item: CategoryChip,
        setState: (value) => setState("fulfillmentStatus", value),
        defaultValue: {},
        getProps: (item, handleCheckItem, handleIsActive) => ({
          title: item.name,
          isActive: handleIsActive({
            state: filterState.fulfillmentStatus,
            id: item.id,
          }),
          onClick: (item) =>
            handleCheckItem({
              setState: (value) => setState("fulfillmentStatus", value),
              item,
              state: filterState.fulfillmentStatus,
            }),
        }),
      },
      date: {
        list: DATE_LIST,
        Item: CategoryChip,
        setState: (value) => setState("date", value),
        defaultValue: {},
        getProps: (item, handleCheckItem, handleIsActive) => ({
          title: item.name,
          isActive: handleIsActive({
            state: filterState.date,
            id: item.id,
          }),
          onClick: (item) =>
            handleCheckItem({
              setState: (value) => setState("date", value),
              item,
              state: filterState.date,
            }),
        }),
      },
      createdBy: {
        list: createdByList,
        Item: CategoryChip,
        setState: (value) => setState("createdBy", value),
        defaultValue: {},
        getProps: (item, handleCheckItem, handleIsActive) => ({
          title: item.name,
          isActive: handleIsActive({
            state: filterState.createdBy,
            id: item.id,
          }),
          onClick: (item) =>
            handleCheckItem({
              setState: (value) => setState("createdBy", value),
              item,
              state: filterState.createdBy,
            }),
        }),
      },
    };
  }, [
    createdByList,
    filterState.createdBy,
    filterState.date,
    filterState.fulfillmentStatus,
    filterState.paymentStatus,
  ]);

  const openFilterDrawer = () =>
    dispatch(
      showCurrentDrawerAction({ type: DRAWERS.FILTER_DRAWER, show: true })
    );

  const disabledActionBtn = !filterCount;

  return {
    FILTER_DATA,
    onSave,
    onStateClear,
    currentFilterElement,
    disabledActionBtn,
    chipData,
    handleDeleteChip,
    filterCount,
    openFilterDrawer,
  };
};
