import { makeStyles } from "@mui/styles";
import { colors } from "colors";

const useStyles = makeStyles(() => ({
  sortByTitle: {
    fontSize: "18px",
    color: colors.clearBlack,
  },

  sortByItems: {
    "& .MuiTypography-root": {
      fontSize: "17px",
    },
  },

  brandItems: {
    "& .MuiTypography-root": {
      fontSize: "18px",
      color: colors.groundLight,
    },
  },
}));

export default useStyles;
