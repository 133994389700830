import { object, string } from "prop-types";

import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  ApprovedIcon,
  AttentionIcon,
  CheckmarkIcon,
  RefundedIcon,
  RejectIcon,
  SmallHourglassIcon,
} from "../Icons";
import { StyledTypography } from "../StyledTypography/StyledTypography";
import { forwardRef } from "react";
import { useMediaDevice } from "hooks/useMediaDevice";

const styles = {
  block: {
    backgroundColor: "rgba(71, 160, 110, 0.2)",
    width: "max-content",
    minWidth: "62px",
    height: "22px",
    padding: 0.5,
    borderRadius: { xs: "8px", sm: "10px" },
    lineHeight: 1,
    fontSize: 10,
    fontWeight: 500,
    color: "#FFFFFF",
    textTransform: { xs: "capitalize", sm: "uppercase" },
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const StyledBlock = ({ icon, name, fontStyle }) => {
  const matches = useMediaQuery("(min-width:1281px)");
  return (
    <Box display="flex" alignContent="center" gap={1}>
      <Box
        sx={{
          pl: !matches && 2,
          display: "flex",
          alignItems: "center",
        }}
      >
        {icon}
      </Box>
      {matches && (
        <Typography
          sx={{ fontSize: 14, color: "#1C1C19", ...fontStyle }}
          noWrap
        >
          {name}
        </Typography>
      )}
    </Box>
  );
};
StyledBlock.propTypes = {
  icon: object,
  name: string,
  fontStyle: object,
};

export const OrderStatuses = ({ type, fontStyle, boxStyles = {} }) => {
  const { isMobile } = useMediaDevice();
  const statusStr = isMobile ? type.toLowerCase() : type;
  switch (type) {
    case "APPROVED":
      return (
        <Box
          sx={{
            ...styles.block,
            color: "#47A06D",
            display: "inline-flex",
            gap: "7px",
            width: "92px",
            px: "11px",
            ...boxStyles,
          }}
        >
          <ApprovedIcon
            {...(isMobile ? { width: "12px", height: "12px" } : {})}
          />
          {statusStr}
        </Box>
      );

    case "PENDING":
      return (
        <Box
          sx={{
            ...styles.block,
            color: "#959595",
            backgroundColor: "rgba(149, 149, 149, 0.1)",
            display: "inline-flex",
            gap: "7px",
            px: "12px",
            width: "82px",
            ...boxStyles,
          }}
        >
          <SmallHourglassIcon />
          {statusStr}
        </Box>
      );

    case "REJECTED":
      return (
        <StyledBlock
          icon={<RejectIcon />}
          name="Rejected"
          fontStyle={{ color: "#FF877C", ...fontStyle }}
        />
      );

    default:
      return <StyledTypography text={"-"} style={{ fontSize: 14 }} />;
  }
};

OrderStatuses.propTypes = {
  type: string,
  fontStyle: object,
  boxStyles: object,
};
OrderStatuses.defaultProps = {};

export const PaymentStatuses = forwardRef(({ str, ...props }, ref) => {
  const { isMobile } = useMediaDevice();
  const statusStr = isMobile ? str.toLowerCase() : str;

  switch (str) {
    case "PAID":
    case "FULFILLED":
    case "PARTIALLY FULFILLED":
      return (
        <Box sx={{ ...styles.block, color: "#47A06D" }} ref={ref} {...props}>
          <CheckmarkIcon width="11.18" height="8" stroke="#52B985" />
          <CheckmarkIcon
            width="11.18"
            height="8"
            stroke="#52B985"
            style={{ marginLeft: "-7px", marginRight: "3px" }}
          />
          {statusStr}
        </Box>
      );
    case "PARTIALLY_PAID":
      return (
        <Box
          sx={{
            ...styles.block,
            backgroundColor: {
              xs: "rgba(243, 147, 37, 0.10)",
              sm: "rgba(64, 154, 101, 0.10)",
            },
            color: { xs: "#F39325", sm: "#47A06D" },
            display: "inline-flex",
            gap: "4px",
            alignItems: "center",
            px: "10px",
          }}
          ref={ref}
          {...props}
        >
          <CheckmarkIcon
            width="11.18"
            height="8"
            stroke={isMobile ? "#F39325" : "#52B985"}
          />
          Partially Paid
        </Box>
      );

    case "PENDING":
      return (
        <Box
          sx={{
            ...styles.block,
            color: "#959595",
            backgroundColor: "rgba(149, 149, 149, 0.1)",
            display: "inline-flex",
            gap: "7px",
            px: { xs: "6px", sm: "12px" },
            width: { xs: "50px", sm: "82px" },
            minWidth: { xs: "50px", sm: "82px" },
          }}
          ref={ref}
          {...props}
        >
          {!isMobile && <SmallHourglassIcon />}
          Unpaid
        </Box>
      );
    case "UNFULFILLED":
    case "UNPAID":
      return (
        <Box
          sx={{
            ...styles.block,
            color: "#959595",
            backgroundColor: "rgba(149, 149, 149, 0.1)",
            display: "inline-flex",
            gap: "7px",
            px: "12px",
            width: "82px",
          }}
          ref={ref}
          {...props}
        >
          <SmallHourglassIcon />
          {statusStr}
        </Box>
      );
    case "REFUNDED":
      return (
        <Box
          sx={{
            ...styles.block,
            color: { xs: "#E41E3A", sm: "#FF6969" },
            backgroundColor: {
              xs: "rgba(228, 30, 58, 0.10)",
              sm: "rgba(255, 105, 105, 0.15)",
            },
            display: "inline-flex",
            gap: "7px",
            width: "92px",
            px: "11px",
          }}
          ref={ref}
          {...props}
        >
          <RefundedIcon fill={isMobile ? "#E41E3A" : "#FF6969"} />
          {statusStr}
        </Box>
      );
    case "FAILED":
      return (
        <Box
          sx={{
            ...styles.block,
            color: "#FF6969",
            backgroundColor: "rgba(255, 105, 105, 0.15)",
            display: "inline-flex",
            gap: "3px",
            px: "10px",
          }}
          ref={ref}
          {...props}
        >
          <AttentionIcon size="12" />
          {statusStr}
        </Box>
      );
    default:
      return (
        <Box sx={styles.block} ref={ref} {...props}>
          {statusStr}
        </Box>
      );
  }
});
PaymentStatuses.displayName = "PaymentStatuses";
PaymentStatuses.propTypes = { str: string };
