import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";

export default createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    primaryLight: {
      main: colors.primaryLight,
    },
    grey: {
      main: colors.grey,
    },
    borderOutline: {
      main: colors.borderOutline,
    },
    groundBlack: {
      main: colors.groundBlack,
    },
    error: {
      light: colors.errorLight,
      main: colors.errorMain,
    },
    clearBlack: {
      main: colors.clearBlack,
    },
    white: {
      main: colors.white,
    },
    ground: {
      main: colors.ground,
      contrastText: colors.white,
    },
    blackBtn: {
      main: colors.blackBtn,
    },
    edit: {
      main: "#707070",
    },
  },
  shape: {},
  typography: {
    fontFamily: [
      // '"Open Sans"',
      // '"Segoe UI"',
      // "Roboto",
      // '"Helvetica Neue"',
      // "Arial",
      '"Inter"',
      "sans-serif",
    ].join(","),
    h6: {
      fontSize: "18px",
      fontWeight: 600,
      // fontFamily: "Open Sans",
    },
    blockTitle: {
      fontSize: "36px",
      fontWeight: 600,
      // fontFamily: "Open Sans",
      color: colors.grey,
    },
    regular: {
      fontSize: "20px",
      fontWeight: 400,
      // fontFamily: "Open Sans",
      color: "#858585",
    },
    lightText: {
      fontSize: "14px",
      fontWeight: 300,
      // fontFamily: "Open Sans",
      color: "#1C1C19",
    },
    typography: {
      fontSize: "14px",
      fontWeight: 400,
      // fontFamily: "Open Sans",
      color: "#1C1C19",
    },
    typographyMedium: {
      fontSize: "14px",
      fontWeight: 500,
      // fontFamily: "Open Sans",
      color: "#1C1C19",
    },
    footer: {
      fontSize: "16px",
      color: `${colors.grey}!important`,
      textDecoration: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          boxShadow: "none",
          textTransform: "none",
          ...(ownerState.variant === "text" && {
            "& .MuiTypography-root": {
              color: colors[ownerState.color] || colors.clearBlack,
            },
          }),
          ...(ownerState.variant === "outlined" && {
            "& .MuiTypography-root": {
              color: colors[ownerState.color] || colors.clearBlack,
            },
          }),
          ...(ownerState.variant === "contained" && {
            borderRadius: "8px",
            "@media (min-width: 600px)": {
              borderRadius: "4px",
            },
            "& .MuiTypography-root": {
              color: colors.white,
            },
          }),
          ...(ownerState.variant === "outlined" &&
            ownerState.color === colors.clearBlack && {
              color: colors.clearBlack,
              borderColor: "#CCCCCC",
            }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === colors.grey && {
              color: "#6A6A6A",
              border: "1px solid #D5D9D9",
            }),
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "grey" && {
              color: "#6A6A6A",
              border: "1px solid #D5D9D9",
              "& .MuiTypography-root": {
                color: "#707070",
              },
            }),
          ...(ownerState.variant === "text" &&
            ownerState.color === colors.grey && {
              color: "#6A6A6A",
            }),
        }),
      },
    },

    MuiBadge: {
      styleOverrides: {
        root: {
          "& span": {
            minWidth: "17px",
            height: "17px",
            padding: "0 4px",
            fontSize: "10px",
            fontWeight: 400,
            wordWrap: "break-word",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.groundBlack,
          fontFamily:
            // '"Open Sans", "Segoe UI", Roboto, "Helvetica Neue" ,Arial, sans-serif',
            '"Inter", sans-serif',
        },
      },
    },
  },
});
