export const REGISTRATION_CARD_ITEMS = [
  {
    id: 1,
    name: "Business Pricing",
    text: "Save more with exclusive\nbusiness pricing.",
  },
  {
    id: 2,
    name: "Easy Reordering",
    text: "Place orders and modify\norders in minutes.",
  },
];
