/* eslint-disable react/prop-types */
import React from "react";

export const Pen2Icon = ({ ...props }) => {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.26 1.884a1.313 1.313 0 0 1 1.856 1.857L4.67 9.187a2.1 2.1 0 0 1-.67.447l-1.535.636a.563.563 0 0 1-.735-.735L2.366 8c.104-.25.256-.478.447-.67z"
        fill="#000"
        fillOpacity={0.6}
      />
    </svg>
  );
};
