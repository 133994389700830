import { AccordionSummary, Box, accordionSummaryClasses } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SummaryLogo, SummaryProgress, SummaryTitle } from "./components";
import { bool, number, object } from "prop-types";
import { photoUrl } from "helpers/helpers";
import { useContext, useMemo } from "react";
import { CartContext } from "pages/CartPage/CartPage";

export const AccSummaryBlock = ({
  distributor,
  minimumOrderValue,
  hasMinimum,
  expanded,
}) => {
  const { calculatedTotal } = useContext(CartContext);

  const photoSrc = useMemo(
    () => distributor.profilePhoto?.fileName,
    [distributor.profilePhoto?.fileName]
  );
  return (
    <AccordionSummary
      sx={{
        height: "80px",
        [`& .${accordionSummaryClasses.content}`]: {
          flexGrow: 0,
          width: "calc(100% - 32px)",
        },
      }}
      // expandIcon={<ExpandMoreIcon sx={{ fontSize: 30 }} />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Box width="100%" display="flex" justifyContent="space-between">
        <Box
          display="flex"
          alignItems="center"
          gap="5%"
          maxWidth="40%"
          flexGrow="1"
        >
          {photoSrc && <SummaryLogo src={photoUrl(photoSrc)} />}
          <SummaryTitle name={distributor.name} />
        </Box>
        <Box
          pr={2}
          display="flex"
          alignItems="center"
          maxWidth="60%"
          width="60%"
          justifyContent="flex-end"
        >
          {expanded && (
            <SummaryProgress
              hasMinimum={hasMinimum}
              maxValue={minimumOrderValue}
              value={calculatedTotal}
            />
          )}
        </Box>
      </Box>
    </AccordionSummary>
  );
};

AccSummaryBlock.propTypes = {
  distributor: object,
  hasMinimum: bool,
  minimumOrderValue: number,
  expanded: bool,
};
