import { useMemo } from "react";
import { string, object, oneOfType, instanceOf } from "prop-types";
import { Box, Typography } from "@mui/material";
import { CheckmarkIcon, CrossBigIcon, SandClockIcon } from "../Icons";
import { normalizeUppercaseString } from "../../helpers/helpers";

export const Statuses = ({ text, textStyle }) => {
  const preparedText = useMemo(
    () => (typeof text === "string" ? normalizeUppercaseString(text) : ""),
    [text]
  );

  const renderContent = useMemo(() => {
    switch (preparedText) {
      case "Accepted":
      case "Approved":
        return (
          <>
            <CheckmarkIcon
              width="11.18"
              height="8"
              stroke={textStyle?.color ? textStyle?.color : "#53B986"}
            />
            <CheckmarkIcon
              width="11.18"
              height="8"
              stroke={textStyle?.color ? textStyle?.color : "#53B986"}
              style={{ marginLeft: "-7px" }}
            />
            <Typography
              sx={{ ml: "3px", fontSize: 10, color: "#53B986", ...textStyle }}
            >
              {preparedText}
            </Typography>
          </>
        );
      case "Pending":
        return (
          <>
            <SandClockIcon />
            <Typography
              sx={{ ml: "3px", fontSize: 10, color: "#959595", ...textStyle }}
            >
              {preparedText}
            </Typography>
          </>
        );
      case "Rejected":
        return (
          <>
            <CrossBigIcon color="#FF877C" size="8" />
            <Typography
              sx={{ ml: "3px", fontSize: 10, color: "#FF877C", ...textStyle }}
            >
              {preparedText}
            </Typography>
          </>
        );

      default:
        return null;
    }
  }, [preparedText, textStyle]);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>{renderContent}</Box>
  );
};

Statuses.propTypes = {
  text: oneOfType([string, instanceOf(null)]),
  textStyle: object,
};
