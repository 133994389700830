import { cl, useStyles } from "./styles";

import { forwardRef, useContext, useMemo } from "react";
import { OrderContext } from "pages/ViewOrderPage/ViewOrderPage.hooks";

import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { DRAWERS } from "constants/drawer";
import { bool } from "prop-types";
import { useNavigate } from "react-router-dom";

export const ActionBlock = forwardRef(
  ({ hidePayWithCredit, isConfirmationPage }, ref) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { orderData, acceptPaymentData, type } = useContext(OrderContext);

    const { order, loading } = useMemo(() => orderData, [orderData]);

    const onBtnClick = () => {
      dispatch(
        showCurrentDrawerAction({
          type: DRAWERS.SELECT_CARD_DRAWER,
          show: true,
        })
      );
    };

    const disabledPaymentBtn =
      acceptPaymentData?.paymentStatus === "PAID" ||
      order?.orderStatus === "CANCELED" ||
      (!acceptPaymentData?.showBtn && type) ||
      acceptPaymentData.loading;

    return (
      <>
        {(!hidePayWithCredit || isConfirmationPage) && (
          <Stack sx={cl.actionWrapper} ref={ref}>
            {!hidePayWithCredit && !isConfirmationPage && (
              <>
                <Button
                  disabled={disabledPaymentBtn}
                  variant="contained"
                  className={classes.invoiceBtn}
                  onClick={onBtnClick}
                >
                  {loading || acceptPaymentData.loading ? (
                    <CircularProgress
                      size={24}
                      sx={{ color: "#ffff", my: "2px" }}
                    />
                  ) : (
                    <Typography className={classes.invoiceText} noWrap>
                      {acceptPaymentData?.paymentStatus === "PAID"
                        ? "Paid"
                        : "Pay Invoice"}
                    </Typography>
                  )}
                </Button>
                {order?.paymentTermsDuplicate && (
                  <Typography sx={cl.termsText} noWrap>
                    Terms: {order.paymentTermsDuplicate.name}
                  </Typography>
                )}
              </>
            )}

            {isConfirmationPage && (
              <Button
                variant="contained"
                className={classes.invoiceBtn}
                onClick={() => navigate("/")}
              >
                <Typography className={classes.invoiceText} noWrap>
                  Continue Shopping
                </Typography>
              </Button>
            )}
          </Stack>
        )}
      </>
    );
  }
);

ActionBlock.propTypes = {
  hidePayWithCredit: bool,
  isConfirmationPage: bool,
};

ActionBlock.displayName = "ActionBlock";
