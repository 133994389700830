export const cl = {
  mobileOrderItemWrapper: {
    width: "100%",
    gap: "5px",
    overflow: "hidden",

    pb: "16px",
    "&:not(:first-of-type)": {
      py: "16px",
    },

    "&:not(:last-child)": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
      borderRadius: 0,
    },
    textDecorationLine: "none",
  },

  orderItemRow: {
    flexDirection: "row",
    gap: "9px",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "hidden",
  },

  orderNumber: {
    color: "black",
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "17.55px",
    wordWrap: "break-word",
    whiteSpace: "nowrap",
  },

  orderDate: {
    color: "rgba(0, 0, 0, 0.40)",
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "19.5px",
    wordWrap: "break-word",
    whiteSpace: "nowrap",
    mr: "6px",
  },

  orderFulfillmentText: {
    color: "#1C1C19",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "15.6px",
    whiteSpace: "nowrap",
  },

  fulfillmentIcon: {
    width: "8px",
    height: "8px",
    borderRadius: "8px",
  },

  orderTotal: {
    color: "black",
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "17.55px",
    ml: "auto",
  },

  supplierName: {
    color: "rgba(0, 0, 0, 0.80)",
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "19.5px",
  },

  canceledOrder: {
    textDecorationLine: "line-through",
    textDecorationColor: "rgb(28, 28, 25)",
  },
};
