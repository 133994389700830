import { array, func, object } from "prop-types";
import { useSelector } from "react-redux";

import { ContentBlock } from "./components/ContentBlock";
import { DrawerHeader } from "./components/DrawerHeader";

import { useContactDrawer } from "./useContactDrawer";

import { useStyles } from "./styles";

import { Drawer, Grid } from "@mui/material";

export const ContactDrawer = ({
  handleAddContact,
  editContact = {},
  contacts,
  deleteContact,
}) => {
  const classes = useStyles();
  const isDrawerOpened = useSelector(({ uiState }) => uiState.contactDrawer);

  const {
    onClose,
    handleSave,
    FIELD_DATA,
    control,
    formField,
    handleSubmit,
    onSubmit,
    isCustom,
    isDirty,
    errors,
    onContactDelete,
  } = useContactDrawer({
    isOpen: isDrawerOpened,
    handleAddContact,
    editContact,
    contacts,
    deleteContact,
  });

  return (
    <Drawer
      anchor="bottom"
      open={isDrawerOpened}
      onClose={onClose}
      PaperProps={{ className: classes.drawerWrapper }}
    >
      <Grid
        component="form"
        id="add-mobile-contact-form"
        onSubmit={handleSubmit(onSubmit)}
        key="add-mobile-contact-form"
        sx={{ width: "100%" }}
      >
        <DrawerHeader
          {...{
            disabled: !isDirty || !!Object.keys(errors)?.length,
            handleClose: onClose,
            formKey: "add-mobile-contact-form",
            handleSave,
            title: "Delivery contact",
          }}
        />
        <ContentBlock
          {...{
            FIELD_DATA,
            control,
            formField,
            handleSubmit,
            onSubmit,
            isCustom,
            contacts,
            onContactDelete,
            editContact,
            deleteContact,
          }}
        />
      </Grid>
    </Drawer>
  );
};

ContactDrawer.propTypes = {
  handleAddContact: func,
  deleteContact: func,
  editContact: object,
  contacts: array,
};
