import { Grid, IconButton, MenuItem, Radio, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { string, any, bool, object, func, number } from "prop-types";
import { Controller } from "react-hook-form";
import { StyledTextField } from "simply-depo-ui-components";

import { GoogleAutocompleteField, StyledSelect } from "components";
import { TrashIcon } from "../../../../../../../../components/Icons";
import { StyledLabel } from "../../../../../../../../components/StyledLabel";
import {
  CANADAS_STATE_NAMES,
  STATE_NAMES,
} from "../../../../../../../../utils/constants";
import useStyles from "../../../../../../newStyles";
import { getAddressComponents } from "components/AddressField/AddressField.helpers";
import { sliceCountry } from "helpers/helpers";
import { useSelector } from "react-redux";

const LocationsItem = ({
  title,
  address,
  isEdit,
  control,
  fieldName,
  setValue,
  isCompulsory,
  handleDeleteLocation,
  handleChangeDefault,
  index,
}) => {
  const classes = useStyles();
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const selectCityState =
    currentUser.country === "CA" ? CANADAS_STATE_NAMES : STATE_NAMES;

  return (
    <Grid
      sx={{
        ml: !isEdit && "20px",
        "&:not(:last-of-type)": {
          borderBottom: !isEdit && "1px solid #d5d9d9",
        },
        "& .MuiTypography-root": {
          fontSize: "15px",
        },
      }}
      display="flex"
      width="unset"
      justifyContent="space-between"
      mr="10px"
      height="62px"
      alignItems="center"
      container
    >
      <Grid item xs={2.7} display="flex" alignItems="center">
        {isEdit && (
          <Controller
            render={({ field }) => (
              <>
                <Radio
                  sx={{ ml: "-9px" }}
                  {...field}
                  checked={!!field.value}
                  onChange={() => handleChangeDefault(index)}
                />
                <StyledLabel
                  style={{ mr: "12px" }}
                  fill={field.value ? "" : "#d5d9d9"}
                />
              </>
            )}
            name={`${fieldName}.defaultLoc`}
            control={control}
          />
        )}
        {isEdit && !isCompulsory ? (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                fullWidth
                size="small"
                InputProps={{
                  className: classes.editInput,
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                placeholder="Name"
                error={error ? error.message : ""}
                {...field}
              />
            )}
            name={`${fieldName}.name`}
            control={control}
          />
        ) : (
          <Box display="flex" gap="10px">
            <Typography color="#979797" fontWeight={600} whiteSpace="nowrap">
              {title}
            </Typography>
            {address.defaultLoc && <StyledLabel />}
          </Box>
        )}
      </Grid>
      {isEdit ? (
        <Grid
          item
          xs={9}
          container
          gap="21px"
          alignItems="center"
          width="unset"
        >
          <Grid item xs={3.8}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <GoogleAutocompleteField
                  onPlaceSelected={(place) => {
                    const { street, state, city, zip } =
                      getAddressComponents(place);
                    setValue(
                      `${fieldName}.formatted_address`,
                      sliceCountry(place.formatted_address)
                    );
                    setValue(`${fieldName}.lat`, place.geometry.location.lat());
                    setValue(`${fieldName}.lng`, place.geometry.location.lng());
                    setValue(`${fieldName}.street`, street);
                    setValue(`${fieldName}.state`, state);
                    setValue(`${fieldName}.city`, city);
                    setValue(`${fieldName}.zip`, zip);
                  }}
                  noErrorMessage
                  InputProps={{
                    className: classes.editInput,
                  }}
                  size="large"
                  error={error ? error.message : ""}
                  {...field}
                />
              )}
              name={`${fieldName}.formatted_address`}
              control={control}
            />
          </Grid>
          <Grid item xs={1}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  fullWidth
                  size="small"
                  InputProps={{
                    className: classes.editInput,
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  placeholder="Unit"
                  error={error ? error.message : ""}
                  {...field}
                />
              )}
              name={`${fieldName}.appartement`}
              control={control}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  fullWidth
                  size="small"
                  InputProps={{
                    className: classes.editInput,
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  placeholder="City"
                  error={error ? error.message : ""}
                  {...field}
                />
              )}
              name={`${fieldName}.city`}
              control={control}
            />
          </Grid>
          <Grid item xs={1.6}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledSelect
                  noErrorMessage
                  notched={false}
                  fullWidth
                  error={error?.message || ""}
                  height="43px"
                  fontSize="15px"
                  displayEmpty
                  borderRadius="9px"
                  {...field}
                  value={field.value || ""}
                >
                  <MenuItem value="" sx={{ display: "none" }}>
                    <Typography sx={{ opacity: 0.5 }}>State</Typography>
                  </MenuItem>
                  {Object.keys(selectCityState).map((key) => (
                    <MenuItem key={key} value={key}>
                      {selectCityState[key]}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
              name={`${fieldName}.state`}
              control={control}
            />
          </Grid>
          <Grid item xs={1.5}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  fullWidth
                  size="small"
                  InputProps={{
                    className: classes.editInput,
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  placeholder="Zip Code"
                  error={error ? error.message : ""}
                  {...field}
                />
              )}
              name={`${fieldName}.zip`}
              control={control}
            />
          </Grid>
          <Grid item xs={0.3}>
            {!address?.defaultLoc && (
              <IconButton onClick={handleDeleteLocation}>
                <TrashIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ) : (
        <Typography color="#000000">{address.formatted_address}</Typography>
      )}
    </Grid>
  );
};

LocationsItem.propTypes = {
  title: string,
  value: any,
  isDefault: bool,
  isEdit: bool,
  EditField: any,
  address: object,
  control: object,
  fieldName: string,
  setValue: func,
  isCompulsory: bool,
  handleDeleteLocation: func,
  handleChangeDefault: func,
  index: number,
};

LocationsItem.defaultProps = {
  title: "",
  isDefault: false,
  isEdit: false,
  fieldName: "",
  isCompulsory: false,
  handleDeleteLocation: () => {},
  handleChangeDefault: () => {},
};

export default LocationsItem;
