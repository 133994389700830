import { useCallback, useEffect, useMemo, useState } from "react";
import { range } from "lodash";

import { PRODUCT_TYPE_INVENTORY } from "utils/constants";
import { useRef } from "react";

export const useQtySelect = ({
  product,
  currentDraftProducts,
  setProductQty,
  productSellingOutOfStock,
  minOrderQTY,
  isAdded,
  showStockTooltip,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [quantityList, setQuantityList] = useState([]);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [inputAutoFocus, setInputAutoFocus] = useState(false);

  const boxRef = useRef(null);

  const lastQtyItem = quantityList.length
    ? quantityList[quantityList.length - 1] + 1
    : 1;

  const {
    inventory: productInventory,
    type,
    sellingOutOfStock,
    id,
  } = product || {};

  const { onHand, allocated } = productInventory || {};

  const getAvailableQTY = onHand - allocated;

  const tooltipText = showStockTooltip
    ? `Only ${getAvailableQTY} items in stock`
    : `Minimum Quantity ${minOrderQTY ? minOrderQTY : 1}`;

  // const [inventory] = useState(onHand - allocated);
  const inventory = useMemo(() => onHand - allocated, [onHand, allocated]);

  const isNonInventory = type === PRODUCT_TYPE_INVENTORY.non_inventory;

  const alreadyAddedDraft =
    isNonInventory || productSellingOutOfStock
      ? 0
      : currentDraftProducts?.find((p) => p?.id === id)?.quantity || 0;

  const onChange = (e) => {
    const { value } = e.target;
    setProductQty(value);
    setInputAutoFocus(false);

    if (value === lastQtyItem) {
      setShowInput(true);
      setInputAutoFocus(true);
      setProductQty(null);
    }
  };

  const onSelectClick = () => {
    setIsSelectOpen(true);
    if (showInput) setProductQty(minOrderQTY);
    setShowInput(false);
  };

  const handleCloseMenu = useCallback(() => setIsSelectOpen(false), []);

  useEffect(() => {
    if (!isSelectOpen) return setQuantityList([]);
    if (minOrderQTY)
      return setQuantityList(range(minOrderQTY, minOrderQTY + 10));
    return setQuantityList(range(1, 11));
  }, [
    onHand,
    allocated,
    sellingOutOfStock,
    minOrderQTY,
    inventory,
    alreadyAddedDraft,
    isNonInventory,
    isSelectOpen,
  ]);

  useEffect(() => {
    if (isAdded && !isSelectOpen) {
      setShowInput(true);
    }
  }, [isAdded, isSelectOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        handleCloseMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleCloseMenu]);

  return {
    quantityList,
    isNonInventory,
    inventory,
    alreadyAddedDraft,
    setIsSelectOpen,
    onChange,
    onSelectClick,
    showInput,
    isSelectOpen,
    handleCloseMenu,
    boxRef,
    lastQtyItem,
    showStockTooltip,
    tooltipText,
    inputAutoFocus,
  };
};
