import * as React from "react";
export const EmailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23.278}
    height={15.713}
    viewBox="0 0 23.278 15.713"
    {...props}
  >
    <path
      data-name="Icon zocial-email"
      d="M0 14.321V1.392Q0 1.37.067.966l7.61 6.51-7.587 7.3a1.9 1.9 0 0 1-.09-.449ZM1.01.067A.967.967 0 0 1 1.392 0h20.494a1.271 1.271 0 0 1 .4.067l-7.628 6.532-1.01.808-2 1.639-2-1.639-1.01-.808Zm.018 15.579 7.655-7.34 2.963 2.4 2.963-2.4 7.655 7.34a1.078 1.078 0 0 1-.382.067H1.392a1.017 1.017 0 0 1-.359-.067Zm14.6-8.171 7.587-6.51a1.34 1.34 0 0 1 .067.426v12.93a1.72 1.72 0 0 1-.067.449Z"
      fill="#47a06d"
    />
  </svg>
);
export default EmailIcon;
