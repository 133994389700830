import * as React from "react";
export const TrashSolidIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13.758}
    height={14.675}
    viewBox="0 0 13.758 14.675"
    {...props}
  >
    <path
      data-name="Path 4491"
      d="M13.185 2.642h-2.293V1.174A1.162 1.162 0 0 0 9.745 0H4.013a1.162 1.162 0 0 0-1.147 1.174v1.468H.573A.58.58 0 0 0 0 3.229v.587a.145.145 0 0 0 .143.147h1.082l.442 9.594a1.16 1.16 0 0 0 1.145 1.119h8.133a1.157 1.157 0 0 0 1.145-1.119l.443-9.594h1.082a.145.145 0 0 0 .143-.147v-.587a.58.58 0 0 0-.573-.587Zm-3.583 0H4.156V1.321H9.6Z"
      fill="#888"
    />
  </svg>
);
export default TrashSolidIcon;
