import { bool, number, string } from "prop-types";
import { Box, Typography } from "@mui/material";

export const StyledCrumb = ({ isActive, id, name }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.8,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { xs: "20px", sm: "32px" },
          minWidth: { xs: "20px", sm: "32px" },
          height: { xs: "20px", sm: "32px" },
          bgcolor: isActive ? "#409A65" : "#CCC",
          borderRadius: "50%",
        }}
      >
        <Typography fontSize={{ xs: "12px", sm: "20px" }} color="#FFF">
          {id}
        </Typography>
      </Box>
      <Box
        fontSize={{ xs: "12px", sm: "clamp(10px, 1.8vh, 14px)" }}
        fontWeight={{ xs: 500, sm: 400 }}
        color={{ xs: isActive ? "#409A65" : "#000", sm: "#000" }}
        component={Typography}
      >
        {name}
      </Box>
    </Box>
  );
};

StyledCrumb.propTypes = { isActive: bool, id: number, name: string };
StyledCrumb.defaultProps = { isActive: false, id: 0, name: "" };
