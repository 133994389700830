import { string, func } from "prop-types";
import useStyles from "../styles";

import { Box, Typography } from "@mui/material";
import { ArrowNextIcon } from "components/Icons";
import { normalizeCamelCaseString } from "helpers/helpers";

export const FilterItem = ({ title, handleSelectFilter }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.itemWrapper}
      onClick={() => handleSelectFilter(title)}
    >
      <Typography className={classes.itemText} noWrap>
        {normalizeCamelCaseString(title)}
      </Typography>
      <ArrowNextIcon color="#2F3040" width={12} height={12} />
    </Box>
  );
};

FilterItem.propTypes = {
  title: string,
  handleSelectFilter: func,
};
FilterItem.defaultProps = {
  title: "",
  handleSelectFilter: () => {},
};
