import { makeStyles } from "@mui/styles";
import { colors } from "colors";

const useStyles = makeStyles(() => ({
  searchBlockWrap: {
    height: ({ isMobile }) => (isMobile ? "100%" : "80px"),
    display: "flex",
    alignItems: "center",
    border: ({ isMobile }) =>
      isMobile ? "none" : `0.5px solid ${colors.borderOutline}`,
    padding: ({ isMobile }) => (isMobile ? 0 : "0 14px 0 18px"),
    borderRadius: "4px",
    gap: "26px",
  },

  distributorInfo: {
    flex: "0 0 10%",
    maxWidth: "200px",
    position: "relative",
  },

  divider: {
    height: "46px",
  },

  viewProfile: { paddingTop: 0, paddingBottom: 0, marginLeft: "-12px" },

  infoText: {
    fontSize: "19px",
    color: colors.clearBlack,
    "&.main": { fontWeight: 500 },
    "&.nowrap": { whiteSpace: "nowrap" },
  },

  textFieldWrap: {
    flex: ({ isMobile }) => (isMobile ? "1 1 100%" : "1 1 80%"),
    display: "flex",
    alignItems: "center",
    gap: ({ isMobile }) => (isMobile ? "7px" : "17px"),
  },

  filterBtn: {
    height: "100%",
    minWidth: "fit-content",
    padding: "9px",
    borderRadius: "8px",
    border: "1px #EDEDED solid",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    position: "relative",
  },

  loadingIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  filterBadge: {
    position: "absolute",
    right: -6,
    top: -6,
    zIndex: 100,
    background: "#409A65 !important",
    borderRadius: 9999,
    border: "1px rgba(255, 255, 255, 0.30) solid",
    minWidth: "15px",
    minHeight: "15px",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: 8,
    fontWeight: "600",
    letterSpacing: 0.08,
  },

  profileLoader: { position: "absolute", right: 0, bottom: "5px" },
}));

export default useStyles;
