import { string, func, object, oneOfType } from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { cl } from "../../../../ViewOrderPage.styles";

export const AddressItem = ({
  title,
  secondLine,
  bottomLine,
  onClickEdit,
  btnLine,
}) => {
  const {
    AddressItem: { wrapper, divider, btn, btnText, text, textThin },
  } = cl;

  return (
    <Box sx={wrapper}>
      <Box position="relative" pl={1.7}>
        <Box sx={divider} />
        <Box display="flex" gap={1}>
          <Typography sx={text} noWrap>
            {title}
          </Typography>
          {onClickEdit ? (
            <Button
              sx={btn}
              onClick={() =>
                onClickEdit(title?.toLowerCase(), { secondLine, bottomLine })
              }
            >
              <Typography sx={btnText}>Edit</Typography>
            </Button>
          ) : null}
        </Box>
        <Box>
          <Typography sx={textThin} noWrap>
            {secondLine}
          </Typography>
        </Box>
        <Box>
          {typeof bottomLine === "string" ? (
            <Typography sx={textThin} noWrap>
              {bottomLine}
            </Typography>
          ) : (
            bottomLine
          )}
        </Box>
      </Box>
      {btnLine ? <Box mt={1}>{btnLine}</Box> : null}
    </Box>
  );
};

AddressItem.propTypes = {
  title: string,
  secondLine: string,
  bottomLine: oneOfType([string, object]),
  btnLine: object,
  onClickEdit: func,
};
AddressItem.defaultProps = {
  title: "",
  secondLine: "",
  bottomLine: "",
};
