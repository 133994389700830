import { useEffect, useRef, useState } from "react";
import { bool, func, number, object, string } from "prop-types";

import { photoUrl } from "helpers/helpers";

import { OrderStatuses } from "components";
import { StyledImageBox } from "components/InviteVendorDialog/StyledImageBox";

import { cl } from "./styles";

import { Button, Stack, Typography } from "@mui/material";

export const VendorItem = ({
  address,
  terms,
  orderDirect,
  distributor,
  onSelect,
  hideSelectBtn,
  ordersCount,
}) => {
  const ref = useRef(null);
  const [statusBoxWidth, setStatusBoxWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setStatusBoxWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  return (
    <Stack sx={cl.cardWrapper} direction="column">
      <Stack direction="row" gap="12px">
        <StyledImageBox
          src={photoUrl(distributor?.profilePhoto?.fileName)}
          placeholderStyles={{
            style: { border: "none" },
            height: "46px",
            width: "46px",
            wrapperProps: {
              height: "46px",
              width: "46px",
            },
          }}
        />
        <Stack
          width={hideSelectBtn ? "calc(100% - 68px)" : "calc(100% - 162px)"}
          justifyContent="center"
        >
          <Typography
            sx={{ ...cl.cardAddressText, fontWeight: 600, color: "black" }}
            noWrap
          >
            {distributor.name}
          </Typography>
          <Typography sx={cl.cardAddressText} noWrap>
            {address}
          </Typography>
          <Typography sx={cl.cardAddressText} noWrap>
            {distributor.minimumOrderValueAmount > 0
              ? `Minimum $${distributor.minimumOrderValueAmount}`
              : "No minimum"}
          </Typography>
        </Stack>

        {!hideSelectBtn && (
          <Button variant="contained" sx={cl.placeOrderBtn} onClick={onSelect}>
            Place order
          </Button>
        )}
      </Stack>

      <Stack
        direction="row"
        gap="12px"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        sx={{ overflow: "hidden" }}
      >
        <Stack direction="row" width={`calc(100% - ${statusBoxWidth + 12}px)`}>
          <Typography sx={cl.cardMainText} whiteSpace="nowrap">
            Terms: {terms}
          </Typography>
          {!!ordersCount && (
            <Typography sx={cl.cardMainText} noWrap ml="4px">
              • Orders: {ordersCount}
            </Typography>
          )}
        </Stack>
        <Stack ref={ref} width="fit-content">
          <OrderStatuses type={orderDirect} boxStyles={cl.statusStyles} />
        </Stack>
      </Stack>
    </Stack>
  );
};

VendorItem.propTypes = {
  address: string,
  terms: string,
  orderDirect: string,
  distributor: object,
  onSelect: func,
  hideSelectBtn: bool,
  ordersCount: number,
};
VendorItem.defaultProps = {
  address: "",
  terms: "-",
  orderDirect: "",
  onSelect: () => {},
  hideSelectBtn: false,
  ordersCount: 0,
};
