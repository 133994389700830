import { Box, Typography } from "@mui/material";
import { MainLogoIcon } from "components";
import { MobileAppPromptIcon } from "components/Icons";

export const TabletAppPromptPage = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box width="100%" textAlign="center">
        <MainLogoIcon variant="primary" width={130} />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          height: "calc(100vh - 75px)",
        }}
      >
        <MobileAppPromptIcon />
        <Typography
          textAlign="center"
          fontSize={24}
          fontWeight={700}
          color="#000"
        >
          Tablet Optimization in Progress
        </Typography>
        <Typography
          textAlign="center"
          fontSize={16}
          fontWeight={600}
          color="#778479"
          px={3}
        >
          Please use desktop for the optimal viewing experience.
        </Typography>
      </Box>
    </Box>
  );
};
