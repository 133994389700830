import { useTheme } from "@emotion/react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  AddressField,
  ContactsSection,
  Loader,
  // ContactEditPopup,
  MapPopup,
  SmallCheckbox,
  StyledTextField,
} from "../../components";
import useStyles from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./ProfilePage.validations";
import { createSelector } from "reselect";
import { currentUserSelector } from "../../redux/selectors/auth";
import { useDispatch, useSelector } from "react-redux";
import { getDefaultValues } from "./ProfilePage.helpers";
import { useState } from "react";
import {
  completeRegistrationAction,
  updateProfileAction,
} from "../../redux/actions/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import lodash from "lodash";
import NumberFormat from "react-number-format";
import { PlaceAutocompleteField } from "components/TextFields/TextFields";
import { getAddressComponents } from "components/AddressField/AddressField.helpers";
import { sliceCountry } from "helpers/helpers";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const ProfilePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [toggleDetails, setToggleDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [mapOpen, setMapOpen] = useState(null);
  const [newContactOpen, setNewContactOpen] = useState(false);
  const [sameAsBilling, setSameAsBilling] = useState(false);

  const navigate = useNavigate();
  const { currentUser } = useSelector(selector);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: getDefaultValues(currentUser),
    resolver: yupResolver(validationSchema()),
  });

  const theme = useTheme();

  const onSubmit = (data) => {
    const federalTaxId =
      parseInt(data.federalTaxId?.replace("-", ""), 10) || null;

    if (currentUser.legalName) {
      const { email, ...uploadData } = data;
      const contacts = data.contacts.map(
        ({ fid, appCustomerId, ...customer }) => customer
      );

      return dispatch(
        updateProfileAction(
          {
            ...uploadData,
            contacts,
            federalTaxId,
            shippingAddress: sameAsBilling
              ? uploadData.billingAddress
              : uploadData.shippingAddress,
          },
          currentUser.id
        )
      );
    }

    const { email, contacts, ...uploadData } = data;
    dispatch(
      completeRegistrationAction(
        {
          ...uploadData,
          federalTaxId,
          shippingAddress: sameAsBilling
            ? uploadData.billingAddress
            : uploadData.shippingAddress,
        },
        () => {
          navigate("/");
        }
      )
    );
  };

  const formField = useWatch({ control });

  useEffect(() => {
    if (lodash.isEqual(formField.shippingAddress, formField.billingAddress))
      setSameAsBilling(true);
  }, [formField.shippingAddress, formField.billingAddress]);

  useEffect(() => {
    if (sameAsBilling) setValue("shippingAddress", formField.billingAddress);
  }, [sameAsBilling, formField.billingAddress, setValue]);

  useEffect(() => {
    reset(getDefaultValues(currentUser));
  }, [currentUser, reset]);

  const handleSetCustomer = (customerInfo) => {
    const { street, state, city, zip } = getAddressComponents(customerInfo);

    setValue("legalName", customerInfo.name);
    setValue("phone", customerInfo.formatted_phone_number || "");
    setValue("website", customerInfo.website || "");
    setValue("billingAddress", {
      formatted_address: sliceCountry(customerInfo.formatted_address),
      lat: customerInfo.geometry.location.lat(),
      lng: customerInfo.geometry.location.lng(),
      street,
      state,
      city,
      zip,
    });
  };

  return (
    <Box>
      <Loader isLoading={loading} />
      <MapPopup
        handleSetAddress={(address) => setValue(mapOpen, address)}
        address={formField[mapOpen]}
        isOpen={!!mapOpen}
        customer={formField}
        handleClose={() => setMapOpen(null)}
      />
      {/* <ContactEditPopup
        type="customer"
        isOpen={newContactOpen}
        handleClose={() => setNewContactOpen(false)}
        contacts={formField.contacts}
        handleAddContact={handleAddContact}
      /> */}
      <Box className={classes.profileHeader}>
        <Typography variant="regular">
          Account /{" "}
          <span style={{ color: theme.palette.primary.main }}>Profile</span>
        </Typography>
        <Box className={classes.controlBtns}>
          <Button
            variant="outlined"
            color="error"
            className={classes.controlBtn}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.controlBtn}
            type="submit"
            form="profile-form"
          >
            Save
          </Button>
        </Box>
      </Box>
      <form id="profile-form" onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.blockWrap}>
          <Box className={classes.detailsWrap}>
            <Box>
              <Typography variant="blockTitle">Business details</Typography>
            </Box>
            <Box className={classes.fieldsWrap}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <PlaceAutocompleteField
                    handleSetCustomer={handleSetCustomer}
                    error={error ? error.message : ""}
                    label="Business Name"
                    setCustomerLoading={setLoading}
                    inputProps={{ sx: { height: "53px" } }}
                    {...field}
                    value={field.value}
                    optionsTextProps={{
                      fontSize: "16px",
                    }}
                    optionsSecondaryTextProps={{
                      fontSize: "13px",
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "#B5B5AC",
                        fontSize: "17px",
                        fontWeight: "300",
                      },
                    }}
                  />
                )}
                name="legalName"
                control={control}
              />
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <NumberFormat
                    customInput={StyledTextField}
                    noErrorMessage
                    label="FED Tax ID (EIN)"
                    format="##-#######"
                    error={error?.message || ""}
                    {...field}
                  />
                )}
                name="federalTaxId"
                control={control}
              />
              <AddressField
                control={control}
                setValue={setValue}
                wrapperProps={{ mb: "-15px" }}
                fieldName="billingAddress"
                label="Billing address"
                address={formField.shippingAddress}
                addressToggled={toggleDetails === "billingAddress"}
                disabled={!!formField.shippingIsSame}
                autocompleteProps={{
                  InputProps: {
                    sx: {
                      height: "53px",
                      "& fieldset": {
                        borderRadius: "4px 0 0 4px",
                      },
                    },
                  },
                  InputLabelProps: {
                    className: classes.inputLabel,
                  },
                }}
                onMapOpen={() => setMapOpen("billingAddress")}
                onToggle={() => {
                  if (toggleDetails === "billingAddress")
                    return setToggleDetails("");
                  setToggleDetails("billingAddress");
                }}
                error={!!errors.billingAddress}
                addressFieldsWrapperProps={{
                  pb: "16px",
                  borderBottom: "1px solid #D5D9D9",
                  mb: "25px",
                }}
              />
              <Box>
                <AddressField
                  control={control}
                  setValue={setValue}
                  fieldName="shippingAddress"
                  label="Shipping address"
                  address={formField.shippingAddress}
                  addressToggled={toggleDetails === "shippingAddress"}
                  disabled={sameAsBilling}
                  autocompleteProps={{
                    InputProps: {
                      sx: {
                        height: "53px",
                        "& fieldset": {
                          borderRadius: "4px 0 0 4px",
                        },
                      },
                    },
                    InputLabelProps: {
                      className: classes.inputLabel,
                    },
                  }}
                  onMapOpen={() => setMapOpen("shippingAddress")}
                  onToggle={() => {
                    if (toggleDetails === "shippingAddress")
                      return setToggleDetails("");
                    setToggleDetails("shippingAddress");
                  }}
                  error={!!errors.shippingAddress}
                  addressFieldsWrapperProps={{
                    pb: "16px",
                    borderBottom: "1px solid #D5D9D9",
                    mb: "25px",
                  }}
                />
                <SmallCheckbox
                  width="20"
                  height="20"
                  sx={{ mt: "2px" }}
                  formSx={{ ml: "-9px" }}
                  value={sameAsBilling}
                  checked={sameAsBilling}
                  onChange={() => setSameAsBilling((prev) => !prev)}
                  label={
                    <Typography className={classes.checkboxLabel}>
                      Same as billing address
                    </Typography>
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.contactWrap}>
            <Box>
              <Typography variant="blockTitle">Business contact</Typography>
            </Box>
            <Box className={classes.fieldsWrap}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    label="Email"
                    error={error?.message || ""}
                    {...field}
                    disabled
                  />
                )}
                name="email"
                control={control}
              />
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    label="Business phone"
                    error={error?.message || ""}
                    {...field}
                  />
                )}
                name="phone"
                control={control}
              />
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    label="Website"
                    error={error?.message || ""}
                    {...field}
                  />
                )}
                name="website"
                control={control}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className={classes.blockWrap}>
          <Box width="100%" sx={{ pt: "28px" }}>
            <ContactsSection
              contacts={formField?.contacts}
              setValue={setValue}
              newContactOpen={newContactOpen}
              setNewContactOpen={setNewContactOpen}
              disabled={!currentUser.legalName}
              type=""
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};
