import { Grid, Paper } from "@mui/material";

import { VendorsHeaderItem } from "./VendorsHeaderItem";
import { HEADER_ITEMS } from "../../../../VendorsPage.constants";

export const VendorsHeader = () => {
  return (
    <Paper
      sx={{
        border: "1px solid #D5D9D9", // check theme
        borderRadius: "4px 4px 0 0",
        backgroundColor: "#F8F8F8", // check theme
        height: "44px",
        alignItems: "center",
      }}
      elevation={0}
      square
      component={Grid}
      container
    >
      {HEADER_ITEMS.map(({ id, name, size, style }) => (
        <VendorsHeaderItem key={id} name={name} size={size} style={style} />
      ))}
    </Paper>
  );
};
