import { array, bool, func, shape } from "prop-types";
import { Box, Drawer } from "@mui/material";
import { ButtonsBlock, HeaderBlock, ProductsList } from "./components";
import { useNavigate } from "react-router-dom";
import { object } from "prop-types";

export const DrawerComponent = ({
  open,
  onClose,
  state,
  parentProduct,
  ...props
}) => {
  const navigate = useNavigate();

  return (
    <Drawer anchor="right" open={open} onClose={onClose} {...props}>
      <Box width="545px">
        <HeaderBlock {...{ onClose }} />

        <ProductsList
          list={state?.list}
          loading={state?.loading}
          handleDeleteProductDraft={state?.handleDeleteProductDraft}
          parentProduct={parentProduct}
        />

        <ButtonsBlock
          goToCartProps={{
            label: "Checkout",
            onClick: () => navigate("/cart"),
          }}
          continueShoppingProps={{
            label: "Continue Shopping",
            onClick: () => navigate("/"),
          }}
        />
      </Box>
    </Drawer>
  );
};

DrawerComponent.propTypes = {
  open: bool,
  onClose: func,
  parentProduct: object,
  state: shape({
    list: array,
    handleDeleteProductDraft: func,
  }),
};
DrawerComponent.defaultProps = {
  open: false,
  onClose: () => {},
  state: {
    list: [],
    handleDeleteProductDraft: () => {},
  },
};
