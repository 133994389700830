import { useContext, useMemo } from "react";

import { OrderedProductItem } from "..";

import { OrderContext } from "pages/ViewOrderPage/ViewOrderPage.hooks";

import { Stack } from "@mui/material";

export const OrderedProductsList = () => {
  const { orderData } = useContext(OrderContext);

  const { order } = useMemo(() => orderData, [orderData]);

  return (
    <Stack gap="8px">
      {order?.products?.map((product) => (
        <OrderedProductItem key={product?.id} product={product} />
      ))}
    </Stack>
  );
};
