/* eslint-disable react/prop-types */
import React from "react";

export const PlusIcon = ({ size = "11.372", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 11.372 11.372"
      {...props}
    >
      <g
        id="Icon_feather-plus"
        data-name="Icon feather-plus"
        transform="translate(-7 -7)"
      >
        <path
          id="Path_4944"
          data-name="Path 4944"
          d="M18,7.5V17.872"
          transform="translate(-5.314)"
          fill="none"
          stroke="#65675f"
          strokeWidth="1"
        />
        <path
          id="Path_4945"
          data-name="Path 4945"
          d="M7.5,18H17.872"
          transform="translate(0 -5.314)"
          fill="none"
          stroke="#65675f"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
