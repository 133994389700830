import { Controller } from "react-hook-form";

import { Box, Button, InputAdornment, Stack, Typography } from "@mui/material";
import { cl as loginCl } from "../../LoginPage/LoginPage.styles";

import { StyledTextField } from "components";

import { bool, func, object, string } from "prop-types";
import { ClosePinIcon } from "components/Icons";
import { SendEmailIcon } from "components/Icons/SendEmailIcon";

export const MobileResetContent = ({
  logo,
  distributor_name,
  isCheckedAndConfirmedEmail,
  control,
  loading,
  isDisabledContinueBtn,
  handleSubmit,
  onSubmit,
  setValue,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="100%"
      height="92vh"
    >
      {/* Distributor Logo */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "50px",
          mb: "20px",
        }}
      >
        {logo ? (
          logo?.logo
        ) : (
          <Box height={distributor_name ? "116px" : "67px"} />
        )}
      </Box>

      {isCheckedAndConfirmedEmail ? (
        <>
          <SendEmailIcon />
          <Typography
            color="black"
            textAlign="center"
            mt={3}
            fontWeight={500}
            fontSize={18}
            lineHeight={"32.4px"}
          >
            We have sent you an email containing instructions on how to reset
            the password.
          </Typography>
          <Button onClick={handleSubmit(onSubmit)} sx={loginCl.createBtn}>
            Resend code
          </Button>
        </>
      ) : (
        <Stack width="100%" gap="32px" alignItems="center" maxWidth="420px">
          <Typography fontSize={18} fontWeight={500} color="black">
            Forgot your password
          </Typography>

          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                inputSx={loginCl.inputSx}
                labelSx={loginCl.labelSx}
                size="small"
                error={error?.message || ""}
                InputProps={{
                  sx: {
                    ...loginCl.inputSx,
                    ...(error && loginCl.errorInputSx),
                  },
                  placeholder: "Email",
                  ...(field.value && {
                    endAdornment: (
                      <InputAdornment
                        sx={loginCl.inputIcon}
                        onClick={() => setValue("email", "")}
                        position="end"
                      >
                        <ClosePinIcon />
                      </InputAdornment>
                    ),
                  }),
                }}
                {...field}
              />
            )}
            name="email"
            control={control}
          />

          <Button
            disabled={isDisabledContinueBtn}
            fullWidth
            sx={{
              mt: "18px",
              fontSize: "16px",
              fontWeight: 600,
              height: "48px",
            }}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {loading ? "Loading" : "Continue"}
          </Button>
        </Stack>
      )}
    </Box>
  );
};

MobileResetContent.propTypes = {
  logo: object,
  distributor_name: string,
  isCheckedAndConfirmedEmail: bool,
  loading: bool,
  isDisabledContinueBtn: bool,
  handleSubmit: func,
  onSubmit: func,
  setValue: func,
  control: object,
  formField: object,
};
