import { string } from "prop-types";
import { Box, Typography } from "@mui/material";

export const HeadrerBlock = ({ title, ...props }) => {
  return (
    <Box
      sx={{
        height: 42,
        display: "flex",
        alignItems: "center",
        px: 2.25,
        border: "1px solid #D5D9D9",
        borderRadius: "4px",
        backgroundColor: "#EEEEEE",
      }}
      {...props}
    >
      <Typography fontSize={20} color="#000">
        {title}
      </Typography>
    </Box>
  );
};

HeadrerBlock.propTypes = { title: string };
HeadrerBlock.defaultProps = { title: "no title" };
