import { string, func, object, bool } from "prop-types";

import { FulfillmentStatus, PaymentStatuses } from "components";

import { cl } from "./styles";

import { Stack, Typography } from "@mui/material";

export const MobileOrdersTabItem = ({
  customId,
  supplier,
  ordered,
  createdBy,
  total,
  payment,
  fulfilled,
  handleViewOrder,
  deliveryStatus,
  type,
  salesDuplicate,
  isCanceledOrder,
}) => {
  return (
    <Stack
      sx={cl.mobileOrderItemWrapper}
      onClick={(e) => {
        if (e.metaKey || e.ctrlKey) return;
        e.stopPropagation();
        e.preventDefault();
        handleViewOrder();
      }}
    >
      <Stack
        direction="row"
        gap="4px"
        alignItems="center"
        sx={{ ...(isCanceledOrder && cl.canceledOrder) }}
      >
        <Typography sx={cl.orderNumber}>#{customId}</Typography>
        <Typography sx={cl.orderDate}>• {ordered}</Typography>
        <Typography sx={cl.orderTotal} noWrap>
          {total}
        </Typography>
      </Stack>

      <Stack sx={cl.orderItemRow}>
        <Typography
          sx={{ ...cl.supplierName, ...(isCanceledOrder && cl.canceledOrder) }}
          noWrap
        >
          {supplier}
        </Typography>

        <FulfillmentStatus {...{ deliveryStatus, fulfilled }} />
      </Stack>

      <Stack sx={cl.orderItemRow}>
        <Typography
          sx={{ ...cl.supplierName, color: "rgba(0, 0, 0, 0.40)" }}
          noWrap
        >
          By:{" "}
          {type === "DIRECT" && createdBy === "Sales rep"
            ? salesDuplicate?.name
            : createdBy}
        </Typography>
        <PaymentStatuses str={payment} />
      </Stack>
    </Stack>
  );
};

MobileOrdersTabItem.propTypes = {
  customId: string,
  supplier: string,
  ordered: string,
  createdBy: string,
  total: string,
  deliveryStatus: string,
  payment: string,
  fulfilled: string,
  type: string,
  handleViewOrder: func,
  salesDuplicate: object,
  isCanceledOrder: bool,
};

MobileOrdersTabItem.defaultProps = {
  customId: "",
  supplier: "",
  ordered: "",
  createdBy: "",
  total: "",
  deliveryStatus: "",
  payment: "",
  type: "",
  fulfilled: "",
  salesDuplicate: {},
  handleViewOrder: () => {},
  isCanceledOrder: false,
};
