import { createContext } from "react";
import { DesktopViewProfile, MobileViewProfile } from "./components";
import { useNewProfile } from "./NewProfile.hooks";
import { useMediaDevice } from "hooks/useMediaDevice";

export const ProfileContext = createContext();

const NewProfile = () => {
  const profileState = useNewProfile();

  const { isMobile } = useMediaDevice();

  return (
    <ProfileContext.Provider value={{ ...profileState }}>
      {isMobile ? <MobileViewProfile /> : <DesktopViewProfile />}
    </ProfileContext.Provider>
  );
};

export default NewProfile;
