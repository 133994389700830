import { forwardRef, useEffect, useState } from "react";
import {
  ClickAwayListener,
  FormControl,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes, { object, string, bool, func } from "prop-types";
import { CircularProgress } from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDebounce } from "../../helpers/hooks";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

export const PlaceAutocompleteField = forwardRef(
  (
    {
      error,
      label,
      value,
      fullWidth,
      loading,
      formSx,
      handleSetCustomer,
      setCustomerLoading,
      noErrorMessage,
      paperStyles,
      country,
      ...props
    },
    ref
  ) => {
    const currentUser = useSelector(({ auth }) => auth.currentUser);

    const valueDebounced = useDebounce(value, 400);
    const [fetchable, setFetchable] = useState(true);
    const [focused, setFocused] = useState(false);
    const {
      placesService,
      placePredictions,
      getPlacePredictions,
      isPlacePredictionsLoading,
    } = usePlacesService({
      // eslint-disable-next-line no-undef
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      options: {
        types: ["establishment"],
        componentRestrictions: { country: currentUser?.country || country },
      },
      language: "en",
      libraries: ["places", "drawing", "geometry"],
    });

    useEffect(() => {
      if (fetchable && focused) getPlacePredictions({ input: valueDebounced });
      setFetchable(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueDebounced]);

    const handleCloseClickHere = () => {
      setFocused(false);
      getPlacePredictions({ input: "" });
    };

    return (
      <FormControl fullWidth={fullWidth} error={!!error} sx={{ ...formSx }}>
        <TextField
          helperText={noErrorMessage ? "" : error}
          FormHelperTextProps={{
            sx: { position: "absolute", bottom: -16, left: 0, margin: 0 },
          }}
          InputProps={{
            sx: {
              fontSize: "12px",
              height: "32px",
            },
            autoComplete: "new-password",
            form: {
              autocomplete: "new-password",
            },
            endAdornment: isPlacePredictionsLoading && (
              <InputAdornment position="end">
                <CircularProgress size="20px" />
              </InputAdornment>
            ),
          }}
          fullWidth={fullWidth}
          label={label}
          error={!!error}
          ref={ref}
          onFocus={() => {
            setFocused(true);
            getPlacePredictions({ input: value });
          }}
          onBlur={() => setFocused(false)}
          {...props}
          value={value}
        />
        {!!placePredictions.length && (
          <ClickAwayListener
            onClickAway={() => {
              setFocused(false);
              getPlacePredictions({ input: "" });
            }}
          >
            <Paper
              sx={{
                position: "absolute",
                top: 32,
                backgroundColor: "#ffffff",
                zIndex: 100,
                border: "0.5px solid #d5d9d9",
                ...paperStyles,
              }}
              data-testid="bussiness-name-dropdown"
            >
              {placePredictions.map((place) => (
                <Box
                  p="8px"
                  key={place.reference}
                  sx={{
                    cursor: "pointer",
                    borderBottom: "0.5px solid #d5d9d9",
                  }}
                  onClick={() => {
                    setFetchable(false);
                    setCustomerLoading(true);
                    placesService?.getDetails(
                      {
                        placeId: place.place_id,
                      },
                      (placeDetails) => {
                        handleSetCustomer(placeDetails);
                        setCustomerLoading(false);
                      }
                    );
                    getPlacePredictions({ input: "" });
                  }}
                >
                  <Typography color="#1C1C19" fontSize="12px" fontWeight="400">
                    {place.structured_formatting.main_text}
                  </Typography>
                  <Typography color="#1C1C19" fontSize="10px" fontWeight="400">
                    {place.structured_formatting.secondary_text}
                  </Typography>
                </Box>
              ))}
              <Box display="flex" p="15px 8px">
                <Typography fontSize="12px">
                  Can&apos;t find your name?
                </Typography>
                <Typography
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontSize: "12px",
                    ml: "3px",
                  }}
                  onClick={handleCloseClickHere}
                >
                  Click Here
                </Typography>
              </Box>
            </Paper>
          </ClickAwayListener>
        )}
      </FormControl>
    );
  }
);

PlaceAutocompleteField.displayName = "PlaceAutocompleteField";

PlaceAutocompleteField.propTypes = {
  error: string,
  label: PropTypes.oneOfType([string, object]),
  fullWidth: bool,
  formSx: object,
  value: string,
  loading: bool,
  handleSetCustomer: func,
  setCustomerLoading: func,
  noErrorMessage: bool,
  paperStyles: object,
  country: string,
};

PlaceAutocompleteField.defaultProps = {
  error: "",
  label: "",
  fullWidth: true,
  value: "",
  loading: false,
  noErrorMessage: false,
  country: "US",
};
