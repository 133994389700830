import { Grid, IconButton, Paper, Typography } from "@mui/material";
import { object, func, bool } from "prop-types";
import { PenIcon, TrashIcon } from "components/Icons";

export const CustomerContactItem = ({
  contact,
  handleEditContact,
  handleDeleteContact,
  IsLastItem,
}) => {
  const classes = {
    grid: {},
    typography: {
      name: { fontSize: "21px", color: "#3F3F3F" },
      role: { fontSize: "19px", color: "#1C1C19" },
      phone: { fontSize: "18px", color: "#1C1C19" },
      email: { fontSize: "18px" },
    },
  };

  return (
    <Paper
      sx={{
        py: 0.5,
        height: "80px",
        display: "flex",
        alignItems: "center",
        borderWidth: "0px 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
        borderRadius: IsLastItem ? "0 0 4px 4px" : "0 0 0 0",
      }}
      elevation={0}
      square
      component={Grid}
      container
      columns={16}
    >
      <Grid item xs={5.2} sx={{ textAlign: "left", pl: 3 }}>
        <Typography sx={classes.typography.name}>
          {contact.name}
          {contact.defaultContact && (
            <Typography
              variant="caption"
              color="white"
              sx={{
                fontSize: "12px",
                backgroundColor: "#47A06D",
                borderRadius: "3px",
                px: "8px",
                py: "2px",
                fontSpacing: 0,
                ml: "28px",
                opacity: 0.76,
              }}
            >
              DEFAULT
            </Typography>
          )}
        </Typography>
      </Grid>
      <Grid item xs={2.5} sx={{ ...classes.grid, textAlign: "left" }}>
        <Typography
          variant="caption"
          color="ground.main"
          sx={classes.typography.role}
        >
          {contact.role}
        </Typography>
      </Grid>
      <Grid item xs={2.6} sx={{ textAlign: "left" }}>
        <Typography
          sx={classes.typography.phone}
          variant="caption"
          color="ground.main"
        >
          {contact.phone}
        </Typography>
      </Grid>
      <Grid item xs={3.7} sx={classes.grid}>
        <Typography color="primary" sx={classes.typography.email}>
          {contact.email}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          gap: "17px",
        }}
      >
        <IconButton onClick={() => handleEditContact(contact)}>
          <PenIcon />
        </IconButton>
        <IconButton onClick={() => handleDeleteContact(contact)}>
          <TrashIcon />
        </IconButton>
      </Grid>
    </Paper>
  );
};

CustomerContactItem.propTypes = {
  contact: object,
  handleDeleteContact: func,
  handleEditContact: func,
  IsLastItem: bool,
};
