import { bool, object } from "prop-types";
import { Typography } from "@mui/material";
import useStyles from "../../styles";

export const AvailableBlock = ({
  isNonInventory,
  productInventory,
  hideAvailable,
  outOfStock,
}) => {
  const classes = useStyles();

  return (
    !isNonInventory &&
    productInventory &&
    !hideAvailable && (
      <Typography className={classes.text} style={{ fontWeight: 400 }}>
        {outOfStock
          ? "Out of stock"
          : `${
              productInventory?.onHand - productInventory?.allocated
            } available`}
      </Typography>
    )
  );
};

AvailableBlock.propTypes = {
  isNonInventory: bool,
  productInventory: object,
  hideAvailable: bool,
  outOfStock: bool,
};
