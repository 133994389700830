import { useCallback, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import { DRAWERS } from "constants/drawer";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import NumberFormat from "react-number-format";
import { StyledTextField } from "components";
import { TYPES } from "pages/CartPage/components/ContactsBlock/AddContactPopup/contactTypes";
import { validationSchema } from "pages/CartPage/components/ContactsBlock/AddContactPopup/AddContactPopup.validations";
import { Typography } from "@mui/material";
import { ConfirmContext } from "pages/MasterPage/MasterPage";

export const useContactDrawer = ({
  isOpen,
  editContact,
  contacts,
  handleAddContact,
  deleteContact,
}) => {
  const dispatch = useDispatch();
  const { setConfirmState } = useContext(ConfirmContext);

  const {
    control: control,
    handleSubmit: handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({
    control: control,
  });

  const isCustom = (role) => {
    return ![...TYPES, ""].includes(role);
  };

  const FIELD_DATA = [
    {
      label: "Name *",
      name: "name",
      Input: StyledTextField,
      placeholder: "John Malkovich",
    },
    {
      label: "Email *",
      name: "email",
      Input: StyledTextField,
      placeholder: "john@customername.com",
    },
    {
      label: "Phone number *",
      name: "phone",
      format: "+# (###) ### ####",
      mask: "_",
      Input: NumberFormat,
      customInput: StyledTextField,
      placeholder: "347-333-6636",
    },
  ];

  useEffect(() => {
    if (isOpen) {
      reset({
        fid: editContact?.fid || Date.now(),
        id: editContact?.id || "",
        email: editContact?.email || "",
        name: editContact?.name || "",
        phone: editContact?.phone || "",
        role: editContact?.role
          ? TYPES.includes(editContact?.role)
            ? editContact?.role
            : "custom"
          : "Owner",
        defaultContact: contacts.length ? !!editContact?.defaultContact : true,
        custom: editContact?.role
          ? TYPES.includes(editContact?.role)
            ? ""
            : editContact?.role
          : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reset,
    isOpen,
    editContact?.id,
    editContact?.fid,
    editContact?.email,
    editContact?.name,
    editContact?.phone,
    editContact?.role,
    editContact?.defaultContact,
    contacts.length,
  ]);

  const onClose = useCallback(
    () =>
      dispatch(
        showCurrentDrawerAction({ type: DRAWERS.CONTACT_DRAWER, show: false })
      ),
    [dispatch]
  );

  const onSubmit = useCallback(
    (data) => {
      const { fid, custom, ...newData } = {
        ...data,
        id: data.id || undefined,
        defaultContact: data.defaultContact,
      };
      if (custom) newData.role = custom;

      handleAddContact({ ...newData }, () => onClose());
    },
    [handleAddContact, onClose]
  );

  const handleSave = () => {
    handleSubmit(onSubmit);
  };

  const onContactDelete = () => {
    setConfirmState((prev) => ({
      ...prev,
      onConfirm: () => {
        onClose();
        deleteContact && deleteContact(formField?.id);
      },
      title: "Delete contact?",
      titleElement: (
        <Typography fontSize="15px" color="rgba(0, 0, 0, 0.80)">
          Please confirm that you would like to delete delivery contact{" "}
          {formField.name}?
        </Typography>
      ),
      isOpen: true,
      type: "drawer",
    }));
  };

  return {
    onClose,
    handleSave,
    FIELD_DATA,
    control,
    formField,
    handleSubmit,
    onSubmit,
    isCustom,
    isDirty,
    errors,
    onContactDelete,
  };
};
