import { useMemo } from "react";
import moment from "moment";

export const useLastPurchased = ({ product }) => {
  const preparedPurchased = useMemo(() => {
    if (!product?.childProducts?.length) return [];
    return product?.childProducts?.reduce((acc, cur) => {
      return cur?.orderProducts?.length
        ? [...acc, cur?.orderProducts?.[0]?.order]
        : acc;
    }, []);
  }, [product?.childProducts]);

  const getLastPurchased = useMemo(() => {
    if (!preparedPurchased?.length) return false;

    return preparedPurchased.reduce((acc, cur) => {
      const ms = moment(cur?.createdAt).valueOf();

      if (!acc?.createdAt) return { ...cur, createdAt: ms };

      return acc?.createdAt < ms ? { ...cur, createdAt: ms } : acc;
    }, {});
  }, [preparedPurchased]);

  const preparedToDateFormat = useMemo(() => {
    // for product without variations
    if (!product?.childProducts?.length && product?.orderProducts?.[0]?.order) {
      return {
        id: product.orderProducts[0].order?.id || "",
        createdAt: moment(
          product.orderProducts[0].order?.createdAt || ""
        ).format("MMM D, YYYY"),
      };
    }

    // for product with variations
    if (!getLastPurchased?.createdAt) return false;
    return {
      id: getLastPurchased?.id || "",
      createdAt: moment(getLastPurchased).format("MMM D, YYYY"),
    };
  }, [
    getLastPurchased,
    product?.childProducts?.length,
    product?.orderProducts,
  ]);

  return {
    lastPurchasedDate: preparedToDateFormat?.createdAt,
    orderId: preparedToDateFormat?.id,
  };
};
