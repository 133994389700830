import lodash from "lodash";
import { defaultValues } from "components/AddCardDialog/AddCardDialog.constants";
import { validationSchema } from "components/AddCardDialog/AddCardDialog.validations";
import {
  CardIconComponent,
  Loader,
  MapPopup,
  StyledButton,
  StyledSelect,
  StyledTextField,
} from "components";
import { setCardName } from "helpers/helpers";
import { CrossIcon } from "components/Icons";
import { error, success } from "helpers/notifications";
import { updateCustomerCardService } from "services/stripe";
import { getFullAddressStripe } from "./EditPaymentPopup.helpers";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { bool, func, object, string } from "prop-types";

const EditPaymentPopup = ({
  isOpen,
  customerId,
  // distributorId,
  // orderId,
  handleClose,
  card,
  handleSave,
}) => {
  const classes = useStyles();

  // const [toggleDetails, setToggleDetails] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    // formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      id: card?.id || "",
      fid: card?.fid || "",
      name: card?.name || card?.billingAddress?.name || defaultValues.name,
      expYear: card?.expYear || defaultValues.exp,
      expMonth: card?.expMonth || defaultValues.exp,
      billingAddress:
        {
          zip: card?.billingAddress?.zip,
          street: card?.billingAddress?.street,
          state: card?.billingAddress?.state,
          city: card?.billingAddress?.city,
          appartement: card?.billingAddress?.appartement,
        } || defaultValues.billingAddress,
      defaultMethod: card?.defaultMethod || false,
    },
    resolver: yupResolver(
      validationSchema({ isEdit: true, editingAddress: editAddress })
    ),
  });

  const formField = useWatch({ control });

  useEffect(() => {
    reset({
      id: card?.id || "",
      fid: card?.fid || "",
      name: card?.name || card?.billingAddress?.name || defaultValues.name,
      expYear: card?.expYear || defaultValues.exp,
      expMonth: card?.expMonth || defaultValues.exp,
      billingAddress:
        {
          zip: card?.billingAddress?.zip,
          street: card?.billingAddress?.street,
          state: card?.billingAddress?.state,
          city: card?.billingAddress?.city,
          appartement: card?.billingAddress?.appartement,
        } || defaultValues.billingAddress,
      defaultMethod: card?.defaultMethod || false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, reset]);

  const onSubmit = (data) => {
    // const { exp, fid, ...uploadData } = data;

    const addressFormatted = lodash.pickBy(
      {
        // id: card?.id || null,
        name: data?.name || card?.billingAddress?.name,
        defaultMethod: card?.defaultMethod || false,
        expMonth: data?.expMonth || card?.expMonth,
        expYear: data?.expYear || card?.expYear,
        number: card?.number || null,
        cvc: card?.cvc || null,
      },
      (value) => value !== null
    );
    const billingAddressFormatted = lodash.pickBy(
      {
        formatted_address:
          getFullAddressStripe(
            (!!data?.billingAddress?.city && data?.billingAddress) ||
              card?.billingAddress?.address
          ) || null,
        zip:
          (data?.billingAddress?.zip && data?.billingAddress?.zip) ||
          card?.billingAddress?.address?.zip ||
          card?.billingAddress?.address?.postal_code ||
          card?.billingAddress?.zip, // distributor
        city:
          (data?.billingAddress?.city && data?.billingAddress?.city) ||
          card?.billingAddress?.address?.city ||
          card?.billingAddress?.city || // distributor
          null,
        state:
          (data?.billingAddress?.state && data?.billingAddress?.state) ||
          card?.billingAddress?.address?.state ||
          card?.billingAddress?.state || // distributor
          null,
        street:
          (data?.billingAddress?.street && data?.billingAddress?.street) ||
          card?.billingAddress?.address?.street ||
          card?.billingAddress?.address?.line1 ||
          card?.billingAddress?.street || // distributor
          null,
        appartement:
          (data?.billingAddress?.appartement &&
            data?.billingAddress?.appartement) ||
          card?.billingAddress?.address?.appartement ||
          card?.billingAddress?.address?.line2 ||
          card?.billingAddress?.appartement || // distributor
          null,
        lat:
          data?.billingAddress?.lat || card?.billingAddress?.address?.lat || 0,
        lng:
          data?.billingAddress?.lng || card?.billingAddress?.address?.lng || 0,
      },
      (value) => value !== null
    );

    if (customerId) {
      setIsLoading(true);
      updateCustomerCardService(customerId, card?.id, {
        ...addressFormatted,
        billingAddress: billingAddressFormatted,
      })
        .then((res) => {
          handleSave(res?.rows);
          // if (orderId) {
          //   dispatch(getOrderByIdAction(orderId));
          // }
          success("Payment method updated successfully!");
          handleClose();
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err);
          const { error: errorMessage, message } = err?.response?.data || {};
          error(message || errorMessage);
        })
        .finally(() => {
          setIsLoading(false);
          setEditAddress(false);
        });
    }
    // if (distributorId) {
    //   setIsLoading(true);
    //   updateDistributorPaymentCardsService(card?.id, {
    //     ...addressFormatted,
    //     billingAddress: billingAddressFormatted,
    //   })
    //     .then(() => {
    //       // handleSave();
    //       if (orderId) {
    //         dispatch(getOrderByIdAction(orderId));
    //       }
    //       success("The card was added!");
    //       dispatch(getDistributorPaymentCardsAction());
    //     })
    //     .catch((err) => {
    //       error("Something went wrong!");
    //       console.error(err);
    //     })
    //     .finally(() => {
    //       setIsLoading(false);
    //       setEditAddress(false);
    //       handleClose();
    //     });
    // }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Loader isLoading={isLoading} />
      <MapPopup
        isOpen={mapOpen}
        handleClose={() => setMapOpen(false)}
        address={formField.billingAddress}
        handleSetAddress={(billingAddress) =>
          setValue("billingAddress", billingAddress)
        }
      />
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.title}>Edit payment method</Typography>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form id="edit-payment-form" onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box>
              <Box className={classes.methodBlock}>
                <CardIconComponent type={card?.brand} size={32} />
                <Typography className={classes.dialogText}>
                  <span style={{ fontWeight: "400" }}>
                    {setCardName(card?.brand)}
                  </span>{" "}
                  ending in {card?.number?.slice(-4) || card?.last4}
                </Typography>
              </Box>
            </Box>
            <Box mb="19px">
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    disabled={card?.hasExpiresDateIsDecline}
                    error={error?.message || ""}
                    label="Name on card"
                    className={classes.input}
                    {...field}
                  />
                )}
                name="name"
                control={control}
              />
            </Box>
            <Box>
              <Typography className={classes.blockTitle}>
                Expiration date
              </Typography>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledSelect
                    disabled={card?.hasExpiresDateIsDecline}
                    {...field}
                    notched={false}
                    error={error?.message || ""}
                    value={parseInt(field.value)}
                    fontSize="16px"
                    formSx={{
                      width: "62px",
                      "& .MuiInputBase-root": {
                        borderRadius: "6px",
                        fontWeight: "300",
                        "& .MuiSelect-select": {
                          paddingLeft: "11px",
                        },
                      },
                    }}
                    noErrorMessage
                  >
                    {lodash.range(1, 13).map((month) => (
                      <MenuItem
                        disabled={
                          formField.expYear === new Date().getFullYear() &&
                          month < new Date().getUTCMonth() + 1
                        }
                        key={month}
                        value={month}
                      >
                        {month}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                )}
                name="expMonth"
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <StyledSelect
                    disabled={card?.hasExpiresDateIsDecline}
                    {...field}
                    notched={false}
                    fontSize="16px"
                    formSx={{
                      ml: "8px",
                      "& .MuiInputBase-root": {
                        borderRadius: "6px",
                        fontWeight: "300",
                        "& .MuiSelect-select": {
                          paddingLeft: "11px",
                        },
                      },
                    }}
                  >
                    {lodash
                      .range(
                        new Date().getFullYear(),
                        new Date().getFullYear() + 9
                      )
                      .map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                  </StyledSelect>
                )}
                name="expYear"
                control={control}
              />
            </Box>
          </Box>
          {/* <Box mt="20px">
            <Box display="flex" alignItems="center" mt="14px" mb="7px">
              <Typography className={classes.addressTitle}>
                {editAddress ? "Current billing" : "Billing"} address
              </Typography>

              {!card?.hasExpiresDateIsDecline && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: "14px", mt: "3px", mx: "4px" }}
                />
              )}

              {editAddress ? (
                <>
                  {!card?.hasExpiresDateIsDecline && (
                    <StyledButton
                      label="Cancel"
                      sx={{
                        minWidth: "20px",
                        height: "20px",
                        p: "1px",
                        "& .MuiTypography-root": {
                          color: "red",
                        },
                      }}
                      fontWeight="400"
                      onClick={() => setEditAddress(false)}
                      color="error"
                    />
                  )}
                </>
              ) : (
                <>
                  {!card?.hasExpiresDateIsDecline && (
                    <StyledButton
                      label="Edit"
                      sx={{
                        minWidth: "20px",
                        height: "20px",
                        p: "1px",
                        "& .MuiTypography-root": {
                          color: "#409A65",
                        },
                      }}
                      fontWeight="400"
                      onClick={() => setEditAddress(true)}
                    />
                  )}
                </>
              )}
            </Box>
            <TypographyAddress
              address={
                card?.billingAddress?.formatted_address ||
                getFullAddressStripe(card?.billingAddress?.address)
              }
              styles={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#363531",
              }}
            />
            {editAddress && (
              <AddressField
                isEdit={editAddress}
                control={control}
                setValue={setValue}
                fieldName="billingAddress"
                address={formField.billingAddress}
                addressToggled={toggleDetails}
                wrapperProps={{ mt: "16px" }}
                onMapOpen={() => setMapOpen(true)}
                autocompleteProps={{
                  placeholder: "Address",
                  InputProps: {
                    className: classes.textInput,
                    sx: {
                      height: "32px!important",
                      fontSize: "12px",
                      "& fieldset": {
                        borderRadius: "4px 0 0 4px",
                      },
                    },
                  },
                  InputLabelProps: {
                    className: classes.inputLabel,
                  },
                }}
                onToggle={() => {
                  setToggleDetails(!toggleDetails);
                }}
                error={!!errors.billingAddress}
              />
            )} */}
          {/* {editAddress && (
              <Box>
                <Box
                  sx={{
                    mt: "22px",
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <Box display="flex" width="100%">
                    <GoogleAutocompleteField
                      fullWidth
                      noErrorMessage
                      placeholder="Address"
                      size="small"
                      InputProps={{
                        className: classes.textInput,
                        sx: {
                          "& fieldset": {
                            borderRadius: "4px 0 0 4px",
                          },
                        },
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel,
                      }}
                    />
                    <StyledTextField
                      label="Unit"
                      formSx={{
                        "& fieldset": {
                          borderRadius: "0 4px 4px 0",
                          borderLeft: "none",
                        },
                        maxWidth: "85px",
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel,
                      }}
                      InputProps={{
                        sx: {
                          height: "32px",
                        },
                      }}
                    />
                  </Box>
                  <StyledToggleButton
                    value={toggleDetails}
                    selected={toggleDetails}
                    onChange={() => {
                      setToggleDetails(!toggleDetails);
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography
                        fontSize="10px"
                        sx={{ textTransform: "none" }}
                        mr="5px"
                      >
                        Details
                      </Typography>
                      <ShevronIcon width="9" height="18" />
                    </Box>
                  </StyledToggleButton>
                  <StyledButton
                    sx={{
                      color: "#9C9C94",
                      minWidth: "26px",
                      width: "26px",
                      height: "32px",
                      borderColor: "#D5D9D9",
                      pt: "8px",
                    }}
                    label={
                      <LocationIcon
                        fill="#707070"
                        height="13.33"
                        width="8.33"
                      />
                    }
                    variant="outlined"
                    color="greyBtn"
                  />
                </Box>
                <CustomCurtainComponent
                  toggled={toggleDetails}
                  curtainHeight="195px"
                  display="flex"
                  flexDirection="column"
                  gap="17px"
                  pt="20px"
                >
                  <StyledTextField label="Street Address" />
                  <StyledTextField label="Apt, Suite, Unit, Floor etc" />
                  <Box display="flex" gap="9.4px">
                    <StyledTextField label="City" />
                    <StyledTextField label="State" />
                    <StyledTextField label="ZIP Code" />
                  </Box>
                </CustomCurtainComponent>
              </Box>
            )} */}
          {/* </Box> */}
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <StyledButton
          label="Cancel"
          sx={{
            borderColor: "#D4D4D4",
            "& .MuiTypography-root": {
              color: "#6A6A6A",
            },
            "& .MuiTypography-root:hover": {
              borderColor: "#1C1C19",
            },
            fontSize: "13px",
            height: "28px",
          }}
          fontSize="13px"
          onClick={() => {
            handleClose();
            setEditAddress(false);
          }}
        />
        {/* <StyledButton
          label="Cancel"
          type="submit"
          variant="outlined"
          fontSize="10px"
          color="cancel"
        /> */}
        <StyledButton
          label="Save"
          type="submit"
          variant="contained"
          fontSize="13px"
          form="edit-payment-form"
          sx={{ width: "97px" }}
        />
      </DialogActions>
    </Dialog>
  );
};

EditPaymentPopup.propTypes = {
  isOpen: bool,
  customerId: string,
  distributorId: string,
  orderId: string,
  handleClose: func,
  card: object,
  handleSave: func,
  setMapOpen: func,
};

EditPaymentPopup.defaultProps = {
  isOpen: false,
  card: null,
};

export default EditPaymentPopup;
