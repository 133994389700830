import {} from "react";
import { string } from "prop-types";
import { Box, Typography } from "@mui/material";

export const SummaryTitle = ({ name }) => {
  return (
    <Box width="100%">
      <Typography
        noWrap
        fontSize="clamp(18px, 1.2vw, 24px)"
        fontWeight={400}
        color="#000"
        width="calc(95% - 47px)"
      >
        {name}
      </Typography>
    </Box>
  );
};

SummaryTitle.propTypes = { name: string };
SummaryTitle.defaultProps = { name: "" };
