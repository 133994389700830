import { number, object } from "prop-types";
import pluralize from "pluralize";

import { separateNumWithComma } from "helpers/helpers";

import { Stack, Typography } from "@mui/material";

export const TotalCartBlock = ({
  calculatedDelivery,
  calculatedTotal,
  customerDiscount,
  grandTotalWithDiscount,
  casesCount,
  wrapperSx = {},
}) => {
  const TOTAL_DATA = [
    {
      title: `Subtotal: (${casesCount} ${pluralize("item", casesCount)})`,
      value: `$${separateNumWithComma(calculatedTotal.toFixed(2) || 0)}`,
      valueSx: {
        maxWidth: "49%",
      },
      titleSx: {
        maxWidth: "49%",
      },
    },
    {
      title: "Discounts:",
      value: `${customerDiscount ? "-" : ""}$${separateNumWithComma(
        customerDiscount?.toFixed(2)
      )}`,
      valueSx: { color: "#409A65" },
    },
    {
      title: "Shipping:",
      value: `${
        calculatedDelivery
          ? `$${separateNumWithComma(calculatedDelivery.toFixed(2) || 0)}`
          : "FREE"
      }`,
      wrapperSx: { borderBottom: "1px rgba(0, 0, 0, 0.05) solid", pb: "8px" },
    },
    {
      title: "Grand Total:",
      value: `$${separateNumWithComma(
        grandTotalWithDiscount?.toFixed(2) || 0
      )}`,
      valueSx: { fontWeight: 600 },
      titleSx: { fontWeight: 600 },
      wrapperSx: { pt: "8px" },
    },
  ];

  return (
    <Stack
      sx={{
        background: "rgba(64, 154, 101, 0.05)",
        p: "4px 0",
        borderRadius: "8px",
        ...wrapperSx,
      }}
    >
      {TOTAL_DATA.map(
        ({ title, value, titleSx, valueSx, wrapperSx }, index) => (
          <Stack
            direction="row"
            key={index}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              p: "4px 8px",
              ...wrapperSx,
            }}
          >
            <Typography
              fontSize={14}
              fontWeight={500}
              color="#000"
              noWrap
              sx={{ width: "fit-content", maxWidth: "29%", ...titleSx }}
            >
              {title}
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              color="#000"
              noWrap
              sx={{ maxWidth: "70%", ...valueSx }}
            >
              {value}
            </Typography>
          </Stack>
        )
      )}
    </Stack>
  );
};

TotalCartBlock.propTypes = {
  calculatedDelivery: number,
  calculatedTotal: number,
  customerDiscount: number,
  grandTotalWithDiscount: number,
  casesCount: number,
  wrapperSx: object,
};
