import { useCallback, useEffect, useState, useMemo } from "react";
import { bool, func } from "prop-types";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "../../firebase/notifications/config";

import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useDebounce } from "simply-depo-ui-components";
import useStyles, { cl } from "./InviteVendorDialog.styles";
import { StyledTextField } from "../../components";
import { CrossIcon, SearchIcon, SuccessIcon } from "../Icons";
import { validationSchema } from "./InviteVendorDialog.validations";
import { defaultValues } from "./InviteVendorDialog.constants";
import {
  getSuppliersService,
  wholesaleAccessService,
} from "../../services/distributors";
import { SCROLL_LIMIT_DISTRIBUTORS } from "../../utils/constants";
import { SearchItem } from "./SearchItem";
import { currentUserSelector } from "../../redux/selectors/auth";
import { error } from "helpers/notifications";
import OrderDirectConfirm from "components/OrderDirectConfirm/OrderDirectConfirm";
import { currentDistributorStoreDataSelector } from "redux/selectors/distributors";
import { getCurrentUser } from "services/account";
import { setCurrentUser } from "redux/actions/auth";

const selector = createSelector(
  currentUserSelector,
  currentDistributorStoreDataSelector,
  (currentUser, currentStoreData) => ({
    currentUser,
    currentStoreData,
  })
);

export const InviteVendorDialog = ({
  open,
  onClose,
  requestingOrderDirect,
}) => {
  const { currentUser, currentStoreData } = useSelector(selector);
  const dispatch = useDispatch();

  const [customersSearchInput, setCustomersSearchInput] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [suppliersList, setSuppliersList] = useState([]);

  const classes = useStyles({ customersSearchInput });

  const searchInputDebounced = useDebounce(customersSearchInput, 500);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: { ...defaultValues },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });
  const { name, email, isSentSuccessfully } = formField;

  useEffect(
    () =>
      reset({
        ...defaultValues,
        supplier: requestingOrderDirect
          ? currentStoreData
          : defaultValues.supplier,
      }),
    [reset, open, requestingOrderDirect, currentStoreData]
  );

  const getSuppliersList = useCallback(() => {
    setLoading(true);
    getSuppliersService({
      params: {
        limit: SCROLL_LIMIT_DISTRIBUTORS,
        search: searchInputDebounced,
      },
    })
      .then((res) => {
        setSuppliersList(res?.rows);
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err?.message))
      .finally(() => setLoading(false));
  }, [searchInputDebounced]);

  useEffect(() => {
    if (requestingOrderDirect) {
      setValue("supplier", currentStoreData);
    } else getSuppliersList();
  }, [
    currentStoreData,
    getSuppliersList,
    requestingOrderDirect,
    searchInputDebounced,
    setValue,
  ]);
  const hasSupplierChosen = useMemo(
    () => !!formField?.supplier?.id,
    [formField?.supplier?.id]
  );

  useEffect(() => {
    if (hasSupplierChosen) {
      const acliveList = formField?.supplier?.paymentTerms?.filter(
        (term) => term.active
      );

      setValue("paymentTermsId", acliveList?.[0]?.id);
    }
  }, [formField?.supplier?.paymentTerms, hasSupplierChosen, setValue]);

  const handleUpdate = () => {
    reset({ ...defaultValues });
  };

  const handleClose = () => {
    setCustomersSearchInput("");
    setSuppliersList([]);
    onClose();
  };

  // const handleSetNewSupplier = useCallback(
  //   (e) => {
  //     e.preventDefault();
  //     setValue("supplier", {
  //       id: "new",
  //       name,
  //       email,
  //     });
  //   },
  //   [email, name, setValue]
  // );

  const isInviteBtnDisabled = useMemo(() => {
    return !(name && email) || !!errors?.email;
  }, [email, errors?.email, name]);

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      const { name, email, supplier, paymentTermsId } = data;
      let preparedData = {};

      if (data?.supplier?.id && data?.supplier?.id !== "new") {
        preparedData = {
          newDistributor: false,
          distributorId: supplier?.id,
          paymentTermsId,
        };
      }
      if (data?.supplier === null && name && email) {
        preparedData = {
          newDistributor: true,
          name,
          email,
        };
      }

      const uploadData = { customerId: currentUser?.id, read: false };

      try {
        const res = await wholesaleAccessService(preparedData);
        if (res && !preparedData?.newDistributor) {
          const fetchCollection = await getDoc(
            doc(firestore, "distributors-direct-statuses", supplier?.id)
          );
          let dataCollection;
          if (fetchCollection.exists()) {
            dataCollection = fetchCollection.data()?.customers;
          } else {
            dataCollection = [];
          }

          await setDoc(
            doc(firestore, "distributors-direct-statuses", supplier?.id),
            {
              customers: [...dataCollection, uploadData],
            }
          );
        }
        setValue("isSentSuccessfully", true);
        setValue("supplier", null);
        getCurrentUser()
          .then((user) => {
            dispatch(setCurrentUser(user));
            setLoading(false);
          })
          .catch(() => setLoading(false));

        setLoading(false);
      } catch (err) {
        if (err?.response?.data?.message) error(err.response.data.message);
        // eslint-disable-next-line no-console
        console.error(err?.response?.data?.message || err.message);
        setLoading(false);
      }
    },
    [currentUser?.id, dispatch, setValue]
  );

  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: hasSupplierChosen && "16px" },
      }}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
    >
      <Box
        component="form"
        id="invite-vendor-group-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogContent
          sx={{
            ...cl.dialogContentWrapper,
            width: hasSupplierChosen ? "1028px" : "630px",
            maxWidth: hasSupplierChosen ? "1028px" : "630px",
          }}
        >
          {/* title */}
          <Grid container>
            <Grid sx={cl.titleWrapper} xs={12} item>
              <Typography fontSize={22} color="#707070" noWrap>
                {!requestingOrderDirect && "Invite Supplier"}
              </Typography>

              <IconButton onClick={handleClose}>
                <CrossIcon />
              </IconButton>
            </Grid>
          </Grid>

          {isSentSuccessfully ? (
            <Grid xs={12} item container>
              <Box sx={cl.successfullyBlockWrapper}>
                <Typography sx={cl.successfullyText}>
                  Invitation sent successfully!
                </Typography>
                <SuccessIcon />
              </Box>
            </Grid>
          ) : (
            <Grid alignItems="center" xs={12} item container>
              <Box width="100%">
                {!hasSupplierChosen ? (
                  <>
                    <Box sx={cl.searchBlockWrapper}>
                      {/* Search */}
                      <Controller
                        render={({ field, fieldState: { error } }) => (
                          <Autocomplete
                            fullWidth
                            onInputChange={(event, option, reason) => {
                              if (reason === "clear")
                                setCustomersSearchInput("");
                            }}
                            loading={isLoading}
                            PaperComponent={(props) => (
                              <Box
                                {...props}
                                className={classes.recipientsTable}
                              />
                            )}
                            size="small"
                            isOptionEqualToValue={(option, value) => {
                              return option.id === value;
                            }}
                            sx={cl.search}
                            className={classes.textfield}
                            getOptionLabel={(option) => {
                              return option.name || "";
                            }}
                            renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                placeholder="Search by supplier name"
                                className={classes.textfield}
                                value={customersSearchInput}
                                onChange={(e) => {
                                  setCustomersSearchInput(e.target.value);
                                }}
                                sx={cl.searchInput}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                            ListboxProps={{ sx: cl.searchListBoxProps }}
                            noOptionsText={"No results found"}
                            renderOption={(props, recipient) => (
                              <SearchItem item={recipient} {...props} />
                            )}
                            options={suppliersList}
                            error={error?.message || ""}
                            {...field}
                            onChange={(e, newValue) =>
                              setValue(field.name, newValue)
                            }
                          />
                        )}
                        name="supplier"
                        control={control}
                      />
                    </Box>
                    <Divider sx={cl.divider}>
                      <Typography sx={cl.dividerText}>
                        or invite new supplier
                      </Typography>
                    </Divider>

                    <Grid xs={12} item container>
                      <Grid
                        sx={cl.supplierInfoWrapperText}
                        xs={12}
                        item
                        container
                      >
                        <Typography sx={cl.supplierInfoText}>
                          Supplier info
                        </Typography>
                      </Grid>
                      <Grid sx={cl.supplierInfoName} xs={6} item>
                        <Controller
                          render={({ field, fieldState: { error } }) => (
                            <StyledTextField
                              sx={cl.supplierInfoTextField}
                              placeholder="Business name"
                              fullWidth
                              size="small"
                              InputProps={{ sx: { maxHeight: 34 } }}
                              error={!!error?.message}
                              {...field}
                            />
                          )}
                          name="name"
                          control={control}
                        />
                      </Grid>
                      <Grid sx={cl.supplierInfoEmail} xs={6} item>
                        <Controller
                          render={({ field, fieldState: { error } }) => (
                            <StyledTextField
                              sx={cl.supplierInfoTextField}
                              placeholder="Business email"
                              fullWidth
                              size="small"
                              InputProps={{ sx: { maxHeight: 34 } }}
                              error={!!error?.message}
                              {...field}
                            />
                          )}
                          name="email"
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <OrderDirectConfirm
                    handleUpdateSupplier={handleUpdate}
                    supplier={formField.supplier}
                    control={control}
                    requestingOrderDirect={requestingOrderDirect}
                  />
                )}
              </Box>
            </Grid>
          )}
        </DialogContent>
        {!isSentSuccessfully && (
          <DialogActions sx={cl.actionsBlock}>
            <Button sx={cl.actionCancelBtn} color="grey" onClick={handleClose}>
              Cancel
            </Button>
            {!hasSupplierChosen ? (
              <Button
                sx={cl.actionInviteBtn}
                disabled={isInviteBtnDisabled}
                variant="contained"
                // onClick={handleSetNewSupplier}
                type="submit"
                form="invite-vendor-group-form"
              >
                Invite
              </Button>
            ) : (
              <Button
                disabled={isLoading}
                sx={cl.actionInviteBtn}
                variant="contained"
                type="submit"
                form="invite-vendor-group-form"
              >
                {hasSupplierChosen &&
                ["REJECTED", "PENDING"].includes(
                  formField?.supplier?.orderDirect?.[0]?.status
                )
                  ? "Resend Request"
                  : "Request Wholesale Access"}
              </Button>
            )}
          </DialogActions>
        )}
      </Box>
    </Dialog>
  );
};

InviteVendorDialog.propTypes = {
  open: bool,
  onClose: func,
  requestingOrderDirect: bool,
};
InviteVendorDialog.defaultProps = {
  open: false,
  onClose: () => {},
  requestingOrderDirect: false,
};
