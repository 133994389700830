import { object } from "prop-types";

import { PaymentCard } from "components";
import { isCardExpired, setCardName } from "helpers/helpers";

import { Box, Stack, Typography } from "@mui/material";

export const ConfirmCardDelete = ({ card, cardProps = {} }) => {
  return (
    <Stack>
      <PaymentCard
        {...{
          card,
          isCardExpired,
          hideAction: true,
          cardSx: { borderColor: "rgba(255, 0, 0, 0.40)" },
          ...cardProps,
        }}
      />
      <Box
        sx={{
          color: "rgba(0, 0, 0, 0.80)",
          fontWeight: "400",
          fontSize: "15px",
          mt: "10px",
        }}
      >
        Are you sure you want to delete{" "}
        <Typography
          sx={{
            fontWeight: 600,
            color: "rgba(0, 0, 0, 0.80)",
            display: "contents",
          }}
        >
          {setCardName(card?.brand)} ending in{" "}
          {card?.number?.slice(-4) || card?.last4}?
        </Typography>{" "}
        Removing this payment method will not cancel any of your open orders
        that use this method.
      </Box>
    </Stack>
  );
};

ConfirmCardDelete.propTypes = {
  card: object,
  cardProps: object,
};
