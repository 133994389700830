import { useContext, useMemo } from "react";

import { OrderContext } from "pages/ViewOrderPage/ViewOrderPage.hooks";
import { MobileContactItem } from "pages/CartPage/components/ContactsBlock/components";

import { Stack } from "@mui/material";

export const DeliveryInfoBlock = () => {
  const { orderData } = useContext(OrderContext);

  const { order } = useMemo(() => orderData, [orderData]);

  const TOTAL_DATA = useMemo(() => {
    return [
      {
        title: "Ship to",
        firstLine: `${order?.customer?.shippingAddress?.appartement || ""} ${
          order?.customer?.shippingAddress?.street
        }`,
        secondLine: `${order?.customer?.shippingAddress?.city}, ${order?.customer?.shippingAddress?.state} ${order?.customer?.shippingAddress?.zip}`,
      },
      {
        title: "Bill to",
        firstLine: `${order?.customer?.billingAddress?.appartement || ""} ${
          order?.customer?.billingAddress?.street
        }`,
        secondLine: `${order?.customer?.billingAddress?.city}, ${order?.customer?.billingAddress?.state} ${order?.customer?.billingAddress?.zip}`,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.customer?.billingAddress, order?.customer?.shippingAddress]);

  return (
    <Stack direction="column" gap="8px">
      {TOTAL_DATA.map((item, index) => (
        <MobileContactItem item key={index} {...item} />
      ))}
    </Stack>
  );
};
