import { useCallback, useState } from "react";
import { array, bool } from "prop-types";

import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

import { VendorsHeader } from "./VendorsHeader";
import { VendorsItem } from "./VendorsItem";
import ContactDialog from "../ContactDialog";

export const Vendors = ({ vendorsList, vendorsLoading, existData }) => {
  const {
    palette: { borderOutline },
  } = useTheme();

  const [seller, setSeller] = useState(null);
  const [isContactVendorDialog, setContactVendorDialog] = useState(false);

  const handleOpenContactVendorDialog = useCallback((distributor) => {
    setSeller(distributor);
    setContactVendorDialog(true);
  }, []);

  const handleCloseContactVendorDialog = () => setContactVendorDialog(false);

  return (
    <>
      <ContactDialog
        open={isContactVendorDialog}
        supplier={seller}
        onClose={handleCloseContactVendorDialog}
      />
      <Grid xs={12} pt={2} item>
        <VendorsHeader />
        <Stack
          id="distributors-scroll-table"
          sx={{
            border: `1px solid ${borderOutline?.main}`,
            borderWidth: "0 1px 1px 1px",
            borderRadius: "0 0 4px 4px",
            maxHeight: "724px",
            overflow: "overlay",
          }}
        >
          {vendorsLoading ? (
            <Box
              height="calc(100vh - 200px)"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={20} color="inherit" />
            </Box>
          ) : vendorsList?.length ? (
            vendorsList.map((vendor) => {
              const address =
                (vendor?.shippingAddress?.city ?? "") +
                (vendor?.shippingAddress?.city && vendor?.shippingAddress?.state
                  ? ", "
                  : "") +
                (vendor?.shippingAddress?.state ?? "");
              return (
                <VendorsItem
                  key={vendor?.id}
                  distributor={vendor}
                  address={address}
                  terms={
                    vendor?.orderDirect?.[0]?.approvedPaymentTermsDuplicate
                      ?.name ?? "-"
                  }
                  orderDirect={vendor?.orderDirect?.[0]?.status}
                  onMessageSeller={handleOpenContactVendorDialog}
                />
              );
            })
          ) : (
            existData && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "82px",
                }}
              >
                <Typography fontSize={16} fontWeight={700}>
                  No found
                </Typography>
              </Box>
            )
          )}
        </Stack>
      </Grid>
    </>
  );
};

Vendors.propTypes = {
  vendorsList: array,
  vendorsLoading: bool,
  existData: bool,
};
Vendors.defaultProps = {
  vendorsList: [],
  vendorsLoading: false,
  existData: false,
};
