/* eslint-disable react/prop-types */
import React from "react";

export const ActiveIcon = ({
  width = "17.483",
  height = "12.346",
  stroke = "#47a06d",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17.483 12.346"
    >
      <g id="path" transform="translate(1.414 1.414)">
        <path
          id="path-2"
          data-name="path"
          d="M1440.355,4367.771l4.723,4.723,9.932-9.932"
          transform="translate(-1440.355 -4362.562)"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
