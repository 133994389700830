import { Box } from "@mui/material";
import { useVendorsActions } from "pages/VendorsPage/VendorsPage.hooks";
import { Vendors, VendorsTab } from "./components";
import { VendorsFilter } from "./components/VendorsFilter";

export const DesktopVendors = () => {
  const {
    vendorsState,
    vendorsParams,
    search,
    setSearch,
    sortBy,
    setSortBy,
    switchOrderDirectParams,
    switchShowMinimumParams,
    switchIndustryParams,
  } = useVendorsActions();

  const {
    list: vendorsList,
    loading: vendorsLoading,
    existData,
  } = vendorsState || {};

  return (
    <>
      <Box display="flex" sx={{ width: "100%" }}>
        <Box
          sx={{
            pt: "20px",
            pr: 3,
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <VendorsFilter
            {...{
              switchOrderDirectParams,
              switchShowMinimumParams,
              switchIndustryParams,
            }}
            checkedIndustries={vendorsParams.industries}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <VendorsTab
            {...{
              search,
              setSearch,
              sortBy,
              setSortBy,
            }}
          />
          <Vendors {...{ vendorsList, vendorsLoading, existData }} />
        </Box>
      </Box>
    </>
  );
};
