export const defaultValues = { items: [] };

// export const VIEW_SETTINGS_ITEMS = [
// {
//   id: "1",
//   name: "Category",
//   type: "checkbox",
//   items: [
//     { id: "1", name: "Refregirated", checked: false },
//     { id: "2", name: "Water ", checked: false },
//     { id: "3", name: "Milk", checked: false },
//     { id: "4", name: "Soft Drinks", checked: false },
//     { id: "5", name: "Tea", checked: false },
//     { id: "6", name: "Other", checked: false },
//   ],
// },
// {
//   id: "2",
//   name: "Brand",
//   type: "checkbox",
//   items: [
//     { id: "1", name: "GNGR Labs", checked: false },
//     { id: "2", name: "Bang Energy", checked: false },
//     { id: "3", name: "Coca Cola", checked: false },
//     { id: "4", name: "Health Aid", checked: false },
//   ],
// },
// ];

export const TEMP_BRAND_ITEM = {
  name: "GNGR Labs",
  address: "Brooklyn, NY",
  web: "www.gngrlabs.com",
  socials: [
    { name: "facebook", link: "https://www.facebook.com/" },
    { name: "instagram", link: "https://www.instagram.com/" },
    { name: "linkedin", link: "https://www.linkedin.com/" },
    { name: "youtube", link: "https://www.youtube.com/" },
    { name: "other", link: "https://www.varosh.com.ua/" },
  ],
  productsCount: 10,
  aboutCompany:
    "Imagine how much time you’ve lost to low immune health – and that’s not including the hours you’ve spent getting back on your feet after the flu or recovering from the fatigue and burn out of chronic stress. Our ginger shots are designed to fortify your immune defense system so that next cold doesn’t stand a chance.",
  orderStatus: "APPROVED",
  terms: "Net 30",
};
