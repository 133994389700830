import { Box } from "@mui/material";

export const DividerBlock = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "30px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          borderBottom: "0.5px solid #D5D9D9",
        }}
        component="span"
      />
    </Box>
  );
};
