import { useContext } from "react";

import { HeaderItem, TransactionItem } from "./components";
import { PaymentsContext } from "pages/PaymentsPage/PaymentsPage";

import { Box, Stack } from "@mui/material";

export const TransactionsTab = () => {
  const { transactionList } = useContext(PaymentsContext);

  const { rows } = transactionList ?? {};

  return (
    <Box mt={2}>
      <HeaderItem />
      <Stack
        sx={{
          mt: 1.5,
          gap: 2,
        }}
      >
        {rows?.map((item) => (
          <TransactionItem
            key={item.id}
            date={item.date}
            name={item?.cardData?.last4}
            order={item.orderCustomId}
            sum={item.amount / 100}
            type={item.type}
          />
        ))}
      </Stack>
    </Box>
  );
};

TransactionsTab.propTypes = {};
TransactionsTab.defaultProps = {};
