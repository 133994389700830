import {
  // LocalizationProvider,
  StaticDateRangePicker,
  DateRangePickerDay,
} from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Menu } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes, { object, func, array, string } from "prop-types";
import { useEffect, useMemo, useState } from "react";
import CustomDateAdapter from "../../utils/CustomDateAdapter";
import { StyledButton } from "../StyledButton/StyledButton";
import { StyledTextField } from "../TextFields/TextFields";
import useStyles from "./styles";

export const StyledDateRangePicker = ({
  anchorEl,
  handleDateInput,
  date,
  handleClose,
}) => {
  const classes = useStyles();
  const [pickDate, setPickDate] = useState(
    typeof date === "string" ? [null, null] : date
  );

  const dateValue = useMemo(
    () => (typeof date === "string" ? [null, null] : date),
    [date]
  );

  useEffect(() => {
    if (typeof date !== "string") setPickDate(date);
  }, [date]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      PaperProps={{ className: classes.menu }}
    >
      <Box className={classes.tailBackground} />
      <Box className={classes.tail} />
      <LocalizationProvider dateAdapter={CustomDateAdapter}>
        <StaticDateRangePicker
          displayStaticWrapperAs="desktop"
          label="Date"
          renderDay={(dateStr, dayProps) => {
            const dayDate = new Date(dateStr);
            const day = dayDate.getDate();
            const today = new Date();
            today.setDate(today.getDate() - 1);
            return (
              <DateRangePickerDay
                sx={{
                  width: "22px",
                  height: "20px",
                  transform: "none",
                  fontWeight: dayProps.today ? "700" : "500",
                  color: dayDate < today ? "#ABABAB" : "#5F6267",
                  "& .div": {
                    border: "none",
                    display: "flex",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#42A57F!important",
                    borderRadius: pickDate[1]
                      ? dayProps.isEndOfHighlighting
                        ? "0px 3px 3px 0px!important"
                        : "3px 0px 0px 3px"
                      : "3px",
                  },
                }}
                {...dayProps}
              >
                {day}
              </DateRangePickerDay>
            );
          }}
          value={dateValue}
          calendars={2}
          renderInput={(inputProps) => <StyledTextField {...inputProps} />}
          onChange={(newDate) => {
            if (newDate[1] && newDate[1] <= newDate[0])
              return setPickDate([newDate[1], null]);
            setPickDate(newDate);
          }}
        />
      </LocalizationProvider>
      <Box
        display="flex"
        justifyContent="flex-end"
        mt="8.5px"
        height="19px"
        pr="14px"
      >
        <StyledButton
          label="Cancel"
          variant="contained"
          color="borderOutline"
          onClick={() => {
            handleClose();
            setPickDate([null, null]);
          }}
          sx={{
            ml: "6px",
            fontSize: "9px",
            textTransform: "uppercase",
            px: "18px",
            color: "#ffffff",
          }}
        />
        <StyledButton
          label="Apply"
          variant="contained"
          onClick={() => {
            handleDateInput(pickDate);
            handleClose();
            setPickDate([null, null]);
          }}
          sx={{
            ml: "6px",
            fontSize: "9px",
            textTransform: "uppercase",
            px: "18px",
          }}
        />
      </Box>
    </Menu>
  );
};

StyledDateRangePicker.propTypes = {
  anchorEl: object,
  handleDateInput: func,
  date: PropTypes.oneOfType([array, string]),
  handleClose: func,
};

StyledDateRangePicker.defaultProps = {
  anchorEl: null,
};
