export const cl = {
  mainWrapper: {
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px",
    p: "20px 19px 20px 14px",
    width: "100%",
    position: "relative",
  },
  loadingWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};
