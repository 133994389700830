export const cl = {
  editBtn: {
    mt: "8px",
    height: "18px",
    color: "#409A65",
    fontSize: "14px",
    fontWeight: 600,
    padding: 0,
    whiteSpace: "nowrap",
    width: "fit-content",
    minWidth: 0,

    "& svg": {
      width: "18px",
      height: "18px",
      marginRight: "2px",
    },
  },
};
