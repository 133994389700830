import {} from "react";
import {} from "prop-types";
import { Grid } from "@mui/material";

export const HeaderItem = () => {
  return (
    <Grid
      sx={{
        border: "0.5px solid #CCC",
        borderRadius: "4px",
        height: "60px",
        bgcolor: "#EEE",
        alignItems: "center",
        color: "#6A6A6A",
        fontSize: 17,
        // "& * ": { border: "1px solid lightblue" },
      }}
      container
    >
      <Grid pl={3.6} xs={4.6} item>
        YOUR CREDIT AND DEBIT CARDS
      </Grid>
      <Grid xs={3.2} item>
        NAME
      </Grid>
      <Grid xs={4.2} item>
        EXPIRES
      </Grid>
    </Grid>
  );
};

HeaderItem.propTypes = {};
HeaderItem.defaultProps = {};
