import { string } from "prop-types";
import { Box, Typography } from "@mui/material";

export const ProductNameBlock = ({ productName }) => {
  return (
    <Box
      sx={{
        height: "48px",
        "& .MuiTypography-root": {
          textOverflow: "ellipsis",
          wordWrap: "break-all",
          overflow: "hidden",
          fontSize: "15px",
          fontWeight: 500,
        },
      }}
    >
      <Typography
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
          wordBreak: "break-all",
        }}
      >
        {productName}
      </Typography>
    </Box>
  );
};

ProductNameBlock.propTypes = { productName: string };
ProductNameBlock.defaultProps = { productName: "-" };
