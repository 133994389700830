import * as React from "react";

export const ApprovedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20.928}
    height={11.162}
    viewBox="0 0 20.928 11.162"
    {...props}
  >
    <path
      data-name="Union 280"
      d="M9.971 10.957 7.8 8.787l-2.17 2.17a.7.7 0 0 1-.987 0L.2 6.518a.7.7 0 1 1 .986-.987l3.951 3.947L6.814 7.8 5.531 6.518a.7.7 0 1 1 .987-.987L7.8 6.814 14.41.2a.7.7 0 1 1 .987.986L8.787 7.8l1.678 1.678L19.737.2a.7.7 0 0 1 .987.986l-9.767 9.767a.7.7 0 0 1-.986 0Z"
      fill="#52b985"
    />
  </svg>
);
