import { bool, func, object } from "prop-types";

import { Drawer, Stack } from "@mui/material";
import { DrawerHeader } from "components/ContactDrawer/components/DrawerHeader";
import { ContentBlock } from "./components/ContentBlock";
import { useContactSupplier } from "pages/VendorsPage/components/DesktopVendors/components/ContactDialog/components/ContactTab/useContactSupplier";
import { cl } from "./styles";
import { ActionBlock } from "./components/ActionBlock";

export const ContactVendorDrawer = ({ open, onClose, supplier }) => {
  const {
    onSubmit,
    control,
    setValue,
    disabledSubmitBtn,
    handleSubmit,
    attachments,
    onAttach,
    handleRemoveFile,
    setRecipientsSearch,
    recipientsSearch,
    handlePressSpace,
    inputRef,
    CCList,
    attachmentsSizeExceeded,
    processingIntoChip,
    FIELD_DATA,
  } = useContactSupplier(supplier, onClose, open);

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: cl.drawerWrapper }}
    >
      <DrawerHeader
        {...{
          disabled: false,
          handleClose: onClose,
          title: "Contact Supplier",
        }}
      />

      <Stack
        width="100%"
        height="100%"
        component="form"
        id="contact-vendor-group-form"
        onSubmit={handleSubmit(onSubmit)}
        gap="10px"
        sx={{
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
            width: "0px",
          },
        }}
      >
        <ContentBlock
          {...{
            FIELD_DATA,
            control,
            inputRef,
            onAttach,
            handleRemoveFile,
            attachments,
            setValue,
            setRecipientsSearch,
            CCList,
            processingIntoChip,
            handlePressSpace,
            recipientsSearch,
          }}
        />

        <ActionBlock
          disabledBtn={attachmentsSizeExceeded || disabledSubmitBtn}
        />
      </Stack>
    </Drawer>
  );
};

ContactVendorDrawer.propTypes = {
  open: bool,
  onClose: func,
  supplier: object,
};
