import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import useStyles from "./styles";

import { MainLogoIcon, Pagination } from "components";
import generatedGitInfo from "gitInfo/generatedGitInfo.json";

import { paginationSelector } from "../../redux/selectors/settings";
import { getEnvironment } from "helpers/helpers";

const selector = createSelector(paginationSelector, (pagination) => ({
  pagination,
}));

export const Footer = ({ ...props }) => {
  const classes = useStyles();
  const { pagination } = useSelector(selector);

  const isDevEnvironment = getEnvironment("development");

  return (
    <Box className={classes.footerWrapper} {...props}>
      <Box display="flex" gap="81px">
        <Box position="relative">
          <Box
            sx={{
              width: "230px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1.2,
            }}
          >
            <Typography>Powered by</Typography>
            <MainLogoIcon variant="primary" width={130} />
          </Box>
          {generatedGitInfo?.gitCommitHash && isDevEnvironment && (
            <Typography
              mt="-5px"
              position="absolute"
              bottom="-5px"
              fontSize="10px"
              sx={{ opacity: 0.5 }}
            >
              Build hash: {generatedGitInfo.gitCommitHash}
            </Typography>
          )}
        </Box>
        {pagination.allPages > 0 && <Pagination />}
      </Box>
    </Box>
  );
};
