import * as React from "react";
export const HomeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.168}
    height={15.847}
    viewBox="0 0 17.168 15.847"
    {...props}
  >
    <path
      data-name="Icon ionic-md-home"
      d="M6.603 15.847v-5.282h3.962v5.282h4.028V7.924h2.575L8.584 0 0 7.924h2.575v7.924Z"
      fill="#47a06d"
    />
  </svg>
);
