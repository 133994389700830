import { func, object, string } from "prop-types";

import { normalizeSnakeCaseString } from "helpers/helpers";
import { STATUS_COLORS } from "pages/OrdersPage/OrdersPage.constants";

import { cl } from "./styles";

import { Box, Stack, Typography } from "@mui/material";

export const FulfillmentStatus = ({
  deliveryStatus,
  fulfilled,
  onClick = () => {},
  wrapperSx = {},
}) => {
  return (
    <Stack
      direction="row"
      gap="4px"
      alignItems="center"
      onClick={onClick}
      sx={wrapperSx}
    >
      <Box
        sx={{
          ...cl.fulfillmentIcon,
          backgroundColor: STATUS_COLORS[deliveryStatus],
        }}
      />
      <Typography sx={cl.orderFulfillmentText}>
        {normalizeSnakeCaseString(deliveryStatus)} {fulfilled}
      </Typography>
    </Stack>
  );
};

FulfillmentStatus.propTypes = {
  deliveryStatus: string,
  fulfilled: string,
  onClick: func,
  wrapperSx: object,
};
