import { useContext } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ProfileContext } from "pages/ProfilePage/NewProfile";
import { LocationItem } from "./components";
import AddIcon from "@mui/icons-material/Add";
import { cl } from "components/SelectCardDrawer/styles";

export const LocationsTab = () => {
  const { formField, onAddLocation, onLocationEdit, fields } =
    useContext(ProfileContext);

  const { locations = [] } = formField || {};

  return (
    <>
      <Box mt={1}>
        <Typography fontSize={14} fontWeight={600} color="#000" noWrap>
          Locations
        </Typography>
      </Box>

      <Stack mt={1} gap="8px">
        {fields.map((l, i) => (
          <LocationItem
            key={l?.name}
            name={locations?.[i]?.name}
            isPrimary={locations?.[i]?.defaultLoc}
            value={locations?.[i]?.formatted_address || "-"}
            onEdit={() => onLocationEdit(i)}
          />
        ))}
      </Stack>

      <Button sx={{ ...cl.editBtn, mt: "16px" }} onClick={onAddLocation}>
        <AddIcon />
        Add new
      </Button>
    </>
  );
};
