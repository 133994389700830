import { number, string } from "prop-types";
import { Box, Typography } from "@mui/material";
import pluralize from "pluralize";

export const TotalBlock = ({ itemsCount, sum }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        my: 1,
        flexWrap: "wrap",
      }}
    >
      <Box>
        <Typography
          noWrap
          fontSize="clamp(18px, 1.7vw, 24px)"
          fontWeight={600}
          color="#000"
        >
          Total{" "}
          <Box fontWeight={300} component="span">
            ({pluralize("case", itemsCount, true)})
          </Box>
        </Typography>
      </Box>

      <Box>
        <Typography
          noWrap
          fontSize="clamp(18px, 1.7vw, 31px)"
          fontWeight={600}
          color="#000"
        >
          $ {sum}
        </Typography>
      </Box>
    </Box>
  );
};

TotalBlock.propTypes = { itemsCount: number, sum: string };
TotalBlock.defaultProps = { itemsCount: 0, sum: 0 };
