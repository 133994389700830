import { MobileVisaIcon } from "components/Icons";
import {
  AmExIcon,
  DefaultIcon,
  MastercardIcon,
  VisaIcon,
} from "../Icons/CreditCards";

export const CardIconComponent = ({ type, size }) => {
  const setCardIcon = () => {
    switch (type) {
      case "visa":
        return <VisaIcon size={size ? size : 40} />;
      case "mobile-visa":
        return <MobileVisaIcon size={size ? size : 40} />;
      case "mastercard":
        return <MastercardIcon size={size ? size : 40} />;
      case "amex":
        return <AmExIcon size={size ? size - 5 : 35} />;

      default:
        return <DefaultIcon />;
    }
  };

  return setCardIcon();
};
