import {} from "react";
import {} from "prop-types";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

export const ReasonBlock = () => {
  return (
    <Box mt={3}>
      <Box>
        <Typography fontSize={12} fontWeight={400} color="#000">
          Why are you returning this?
        </Typography>
        <Box mt={1}>
          <FormControl fullWidth>
            <Select
              value={"Product and shipping box damaged"}
              sx={{
                height: 32,
                // borderRadius: "8px",
                "& .MuiInputBase-input": {
                  marginLeft: "10px",
                  fontSize: 12,
                  fontWeight: 300,
                },
                "& fieldset": {
                  border: "0.5px solid #D5D9D9",
                },
                // color: field.value === "" ? "#717171" : "",
                color: "#717171",
                "& .MuiSvgIcon-root": {
                  right: "unset",
                  left: "7px",
                },
              }}
              fullWidth
              IconComponent={(props) => (
                <ExpandMore sx={{ fontSize: 14 }} {...props} />
              )}
            >
              <MenuItem value={"Product and shipping box damaged"}>
                <Typography fontSize={12} fontWeight={400} color="#000">
                  Product and shipping box damaged
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box display="flex" gap={2.5}>
        <Box mt={2} width="50%">
          <Typography fontSize={12} fontWeight={400} color="#000" mb={1}>
            RMA # (Optional)
          </Typography>
          <TextField size="small" InputProps={{ sx: { maxHeight: 32 } }} />
        </Box>

        <Box mt={2} width="50%">
          <Typography fontSize={12} fontWeight={400} color="#000" mb={1}>
            Tracking ID (Optional)
          </Typography>
          <TextField size="small" InputProps={{ sx: { maxHeight: 32 } }} />
        </Box>
      </Box>
    </Box>
  );
};

ReasonBlock.propTypes = {};
ReasonBlock.defaultProps = {};
