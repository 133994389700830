import { useContext } from "react";

import { PaymentsContext } from "pages/PaymentsPage/PaymentsPage";
import { AddCardDrawer, EditCardDrawer, MobileTabs } from "components";
import { TabTitle, TransactionListBlock, WalletListBlock } from "./components";

import { MOBILE_TABS } from "pages/PaymentsPage/PaymentsPage.constants";

import { Stack } from "@mui/material";

export const MobilePayments = () => {
  const {
    tab,
    setTab,
    editCard,
    customerId,
    handleSaveCard,
    onConfirmDelete,
    handleSaveEditCard,
  } = useContext(PaymentsContext);

  const currentTabView = {
    [MOBILE_TABS[0].value]: <WalletListBlock />,
    [MOBILE_TABS[1].value]: <TransactionListBlock />,
  };

  return (
    <Stack
      sx={{
        height: "calc(100svh - 64px)",
        overflowY: "auto",
        pb: "16px",
        "&::-webkit-scrollbar": {
          display: "none",
          width: "0px !important",
        },
        gap: "12px",
      }}
    >
      <AddCardDrawer
        {...{
          customerId,
          handleSave: handleSaveCard,
        }}
      />

      <EditCardDrawer
        showBillingInfo
        {...{
          customerId,
          card: editCard,
          handleSave: handleSaveEditCard,
          handleDeleteCard: onConfirmDelete,
        }}
      />

      <TabTitle
        title="Your credit & debit cards"
        titleSx={{ fontSize: "18px", lineHeight: "24.30px" }}
      />

      <MobileTabs
        {...{
          tabValue: tab,
          setTabValue: setTab,
          tabs: MOBILE_TABS,
          wrapperProps: { mb: "4px", p: 0 },
        }}
      />

      {currentTabView[tab]}
    </Stack>
  );
};
