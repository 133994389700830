import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box } from "@mui/material";
import { cl } from "./styles";
import {
  LOWERCASE_REG_EXP,
  MIN_PASSWORD_LENGTH,
  NUMBER_REG_EXP,
  SYMBOL_REG_EXP,
  UPPERCASE_REG_EXP,
} from "components/PasswordCriteria/PasswordCriteria.constants";
import { restorePasswordService } from "services/account";
import { error } from "helpers/notifications";
import { useLogo } from "pages/LoginPage/useLogo";
import { MobileRestorePassword } from "./components/MobileRestorePassword";
import { DesktopRestorePassword } from "./components/DesktopRestorePassword";
import { useMediaDevice } from "hooks/useMediaDevice";

export const validationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .lowercase()
      .trim()
      .required("Enter an email to proceed.")
      .test(
        "emailValidation",
        "Please enter a valid email address.",
        (value) => {
          const EMAIL_REGEXP =
            /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))\.)+(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))$/i; // eslint-disable-line
          return EMAIL_REGEXP.test(value);
        }
      ),
    password: Yup.string()
      .min(
        MIN_PASSWORD_LENGTH,
        `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
      )
      .matches(
        LOWERCASE_REG_EXP,
        "Password must have at least one lowercase char"
      )
      .matches(
        UPPERCASE_REG_EXP,
        "Password must have at least one uppercase char"
      )
      .matches(NUMBER_REG_EXP, "Password must have at least one digit")
      .matches(SYMBOL_REG_EXP, "Password must have at least one special char")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords don't match!")
      .required("Required")
      .test("confirmPassword", "Incorrect confirm password", (value, ctx) => {
        return value === ctx?.parent?.password;
      }),
  });

export const RestorePasswordPage = () => {
  const { isMobile } = useMediaDevice();
  const [params] = useSearchParams();

  const token = params.get("restore_password_token");
  const email = params.get("email");
  const role = params.get("role");
  const store = params.get("store");

  const logo = useLogo({ distributor_name: store });

  const { control, handleSubmit, reset, trigger, setValue } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  useEffect(() => {
    if (email && token && role === "app_customer") {
      reset({
        email,
      });
    }
  }, [email, reset, role, token]);

  const [loading, setLoading] = useState(false);
  const [hasPasswordUpdated, setPasswordUpdated] = useState(false);

  const onSubmit = async (data) => {
    const { password } = data;
    try {
      setLoading(true);
      await restorePasswordService({
        token,
        password,
      });
      setPasswordUpdated(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={cl.wrapper}>
      {isMobile ? (
        <MobileRestorePassword
          {...{
            logo,
            store,
            hasPasswordUpdated,
            formField,
            trigger,
            control,
            loading,
            setValue,
            handleSubmit,
            onSubmit,
          }}
        />
      ) : (
        <DesktopRestorePassword
          {...{
            logo,
            store,
            hasPasswordUpdated,
            formField,
            trigger,
            control,
            loading,
            handleSubmit,
            onSubmit,
          }}
        />
      )}
    </Box>
  );
};
