import { useMemo } from "react";
import { string, any, func, bool } from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import useStyles from "./styles";

import clsx from "clsx";
import { Box, MenuItem } from "@mui/material";

export const NavLink = ({
  to,
  Icon,
  children,
  onClick = () => {},
  disabled,
  simpleIcon = false,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = useMemo(
    () => location.pathname === to,
    [location.pathname, to]
  );

  return (
    <MenuItem
      disabled={disabled}
      className={clsx(classes.mobileLink, {
        [classes.mobileActive]: isActive && !simpleIcon,
        [classes.simpleLink]: simpleIcon,
      })}
      onClick={() => {
        onClick();
        navigate(to);
      }}
    >
      {!!Icon && (
        <>
          {simpleIcon ? (
            <Icon />
          ) : (
            <Box
              className={clsx(classes.iconWrapper, {
                [classes.activeIconWrapper]: isActive,
              })}
            >
              <Icon />
            </Box>
          )}
        </>
      )}
      {children}
    </MenuItem>
  );
};

NavLink.propTypes = {
  to: string,
  children: any,
  Icon: any,
  onClick: func,
  disabled: bool,
  simpleIcon: bool,
};

NavLink.defaultProps = {
  to: "/",
  onClick: () => {},
};
