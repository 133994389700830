import { ProductContext } from "pages/ProductsPage/components/ProductDetailsPage/ProductDetailsPage";
import { useContext } from "react";
import { ActionsBlock as ActionsComponent } from "pages/ProductsPage/components/MobileProductCard/components/ActionsBlock";

export const ActionsBlock = () => {
  const {
    handleAddToCart,

    currentProduct,
    productQty,
    isAdded,
    isShowProductsWithoutLogIn,
    showMOQTooltip,
    showStockTooltip,
    isAlreadyChangedAndAdded,
    showRetailPrice,
    supplierApproved,
  } = useContext(ProductContext);

  return (
    <ActionsComponent
      {...{
        product: currentProduct,
        isAlreadyChangedAndAdded,
        showRetailPrice,
        supplierApproved,
        productQty,
        handleAddToCart,
        isAdded,
        isShowProductsWithoutLogIn,
        disabledAdding: !!showMOQTooltip || !!showStockTooltip,
      }}
      buttonProps={{
        width: "100%",
        height: "49px",
        fontSize: "16px !important",
        fontWeight: 600,
        borderRadius: "12px",
      }}
      hideLeftSide
    />
  );
};
