import { useEffect, useState } from "react";
import { bool, string, any, func, array, object } from "prop-types";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, useWatch } from "react-hook-form";
// import pluralize from "pluralize";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";

import { CrossIcon, OutlinedPlusIcon } from "../Icons";

import useStyles from "./styles";

// import { checkCustomerAddressService } from "../../../../services/customers";

import { useDebounce } from "../../helpers/hooks";

import {
  AddressField,
  DuplicatePopup,
  TypographyAddress,
  StyledButton,
  GoogleMap,
  StyledSelect,
  CustomCurtainComponent,
} from "../index";

export const EditContactDialog = ({
  isOpen,
  type,
  data,
  handleClose,
  handleSave,
  contacts,
  handleAddNewContact,
  customer,
}) => {
  const classes = useStyles();

  const [addressDuplicates, setAddressDuplicates] = useState([]);
  const [duplicateOpen, setDuplicateOpen] = useState(false);

  const validationSchema = () =>
    Yup.object().shape({
      data:
        type === "customer contact"
          ? Yup.number()
              .transform((v, o) => (o === "" ? null : v))
              .nullable()
              .required("This field is required")
          : Yup.object().shape({
              formatted_address: Yup.string().required(
                "This field is required"
              ),
              street: Yup.string().required("This field is required"),
              city: Yup.string().required("This field is required"),
              state: Yup.string().required("This field is required"),
              zip: Yup.string().required("This field is required"),
            }),
    });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      data: {
        formatted_address: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        appartement: "",
      },
    },
    resolver: yupResolver(validationSchema()),
  });

  useEffect(() => {
    if (type === "customer contact")
      return reset({
        data: "",
      });
    reset({
      data: {
        ...data,
        formatted_address: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        appartement: "",
      },
    });
    setToggleField("");
    setAddressDuplicates([]);
  }, [reset, type, isOpen, data]);

  const formField = useWatch({ control });

  const [toggleField, setToggleField] = useState("");

  const addressDebounced = useDebounce(formField.data?.formatted_address);

  useEffect(() => {
    // if (addressDebounced && type !== "customer contact")
    // checkCustomerAddressService({
    //   formatted_address: addressDebounced,
    //   type: type.split(" ")[0],
    // }).then((res) => {
    //   const customers = res.customers.filter(
    //     (resCustomer) => resCustomer.id !== customer.id
    //   );
    //   setAddressDuplicates(customers);
    //   if (!customers.length) return clearErrors("data.formatted_address");
    //   setError("data.formatted_address", {
    //     type: "duplicate",
    //     message: `${pluralize("duplicate", customers.length, true)} found`,
    //   });
    // });
  }, [addressDebounced, setError, customer?.id, type, clearErrors]);

  const handleOpenDuplicates = () => {
    setDuplicateOpen(true);
  };

  const handleProceedWithDuplicate = () => {
    clearErrors("data.formatted_address");
    setDuplicateOpen(false);
    setAddressDuplicates([]);
  };

  const onError = () => {
    if (errors.data?.formatted_address?.type === "duplicate")
      setError("data.formatted_address", {
        ...errors.data?.formatted_address,
      });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="631px"
      PaperProps={{
        sx: { maxHeight: "calc(100vh - 11px)" },
      }}
    >
      {type !== "customer contact" && (
        <DuplicatePopup
          isOpen={duplicateOpen}
          handleClose={() => setDuplicateOpen(false)}
          handleProceed={handleProceedWithDuplicate}
          duplicates={addressDuplicates}
        />
      )}
      <form
        id="edit-customer-order"
        onSubmit={handleSubmit(
          (data) => handleSave(data, type, errors?.data, setError),
          onError
        )}
      >
        <DialogTitle className={classes.dialogTitleBlock}>
          <Typography className={classes.dialogTitle}>Edit {type}</Typography>
          <IconButton onClick={handleClose}>
            <CrossIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.currentText}>
            Current {type}
          </Typography>
          {data?.formatted_address ? (
            <TypographyAddress
              address={data.formatted_address}
              className={classes.data}
            />
          ) : (
            <Box className={classes.contactItem}>
              <Box className={classes.contactTitle}>
                <Typography>
                  {data?.name} | {data?.role}
                </Typography>
                {data?.defaultContact && (
                  <Typography className="default">primary</Typography>
                )}
              </Box>
              <Typography>{data?.phone}</Typography>
              <Typography>{data?.email}</Typography>
            </Box>
          )}
          <Typography className={classes.currentText} mt="32px">
            New {type}
          </Typography>
          {data?.formatted_address ? (
            <>
              <AddressField
                duplicates={addressDuplicates}
                handleOpenDuplicates={handleOpenDuplicates}
                control={control}
                setValue={setValue}
                fieldName="data"
                address={formField.data}
                addressToggled={toggleField === "details"}
                wrapperProps={{ mt: "16px" }}
                onMapOpen={() => {
                  if (toggleField === "map") setToggleField("");
                  else setToggleField("map");
                }}
                mapToggled={toggleField === "map"}
                autocompleteProps={{
                  placeholder: "Address",
                  InputProps: {
                    className: classes.textInput,
                    sx: {
                      height: "53px",
                      "& fieldset": {
                        borderRadius: "4px 0 0 4px",
                      },
                    },
                  },
                  InputLabelProps: {
                    className: classes.inputLabel,
                  },
                  noErrorMessage: false,
                }}
                onToggle={() => {
                  if (toggleField === "details") setToggleField("");
                  else setToggleField("details");
                }}
                error={!!errors.data}
              />
              <CustomCurtainComponent
                toggled={toggleField === "map"}
                curtainHeight="478px"
                height="478px"
              >
                <GoogleMap
                  address={formField.data}
                  handleSetAddress={(billingAddress) =>
                    setValue("data", billingAddress)
                  }
                  customer={customer}
                />
              </CustomCurtainComponent>
            </>
          ) : (
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledSelect
                  fullWidth
                  noErrorMessage
                  notched={false}
                  className={classes.contactsSelect}
                  error={error?.message || ""}
                  {...field}
                >
                  {contacts.map((contact) => (
                    <MenuItem key={contact.id} value={contact.id}>
                      {contact.name} | {contact.role}
                    </MenuItem>
                  ))}
                  <MenuItem
                    onClick={handleAddNewContact}
                    value=""
                    sx={{
                      color: "#409A65",
                      fontSize: "9px",
                      "& svg": {
                        mr: "8.5px",
                      },
                    }}
                  >
                    <OutlinedPlusIcon /> Add New
                  </MenuItem>
                </StyledSelect>
              )}
              name="data"
              control={control}
            />
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <StyledButton
            label="Cancel"
            color="grey"
            variant="outlined"
            fontSize="10px"
            onClick={handleClose}
            className={classes.actionButton}
          />
          <StyledButton
            label="Save"
            variant="contained"
            fontSize="10px"
            color="primary"
            type="submit"
            form="edit-customer-order"
            className={classes.actionButtonSave}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

EditContactDialog.propTypes = {
  isOpen: bool,
  type: string,
  data: any,
  handleClose: func,
  handleSave: func,
  contacts: array,
  handleAddNewContact: func,
  customer: object,
};

EditContactDialog.defaultProps = {
  isOpen: false,
  type: "billing address",
  data: "",
  contacts: [],
};
