import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  blockTitle: {
    fontSize: "25px",
    fontWeight: 500,
    color: "#707070",
  },

  smallerButtonReport: {
    marginLeft: "16px",
    height: "40px",
  },
}));

export default useStyles;
