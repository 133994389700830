import { Box, Typography } from "@mui/material";

import ErrorIcon from "@mui/icons-material/Error";
import { StyledButton } from "simply-depo-ui-components";
const ErrorPage = () => {
  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="15px"
    >
      <ErrorIcon style={{ fontSize: "80px", fill: "#FF6254" }} />
      <Typography color="error" fontSize="32px">
        Something went wrong!
      </Typography>
      <StyledButton
        label="Reload page"
        variant="contained"
        fontSize="24px"
        onClick={() => {
          window.location.reload();
        }}
      />
    </Box>
  );
};

export default ErrorPage;
