import { any, string } from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const FooterBlock = ({ title, children, ...props }) => {
  return (
    <Accordion
      sx={{
        borderRadius: 0,
        "&:before": { display: "none" },
        "&:first-of-type": {
          borderRadius: 0,
        },
        "&:last-of-type": {
          borderRadius: 0,
        },
      }}
      elevation={0}
      disableGutters
      {...props}
    >
      <AccordionSummary
        sx={{
          height: "38px",
          minHeight: "38px",
          px: 0,
          [`& .${accordionSummaryClasses.content}`]: {
            flexGrow: 0,
            width: "100%",
          },
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-product-content"
        id="panel-product-header"
      >
        <Typography fontSize={14} fontWeight={600} color="#000">
          {title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        <Typography fontSize={12} fontWeight={400} color="#00000080">
          {children}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

FooterBlock.propTypes = { title: string, children: any };
FooterBlock.defaultProps = { title: "" };
