import { useCallback, useEffect, useRef, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getDefaultValues } from "./ProfilePage.helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  businessContactsValidationSchema,
  businessDetailsValidationSchema,
  locationsValidationSchema,
  validationSchema,
} from "./ProfilePage.validations";
import { useDebounce } from "helpers/hooks";
import {
  checkAndPrepareWebsiteForCustomer,
  containsSpecialChars,
  getPhoneCodes,
  getStates,
  setPhoneNumberMask,
} from "helpers/helpers";
import { error } from "helpers/notifications";
import { setCurrentUser, updateProfileAction } from "redux/actions/auth";
import { pickBy } from "lodash";
import { getTimeZoneService } from "services/googleMaps";
import { getCurrentUser } from "services/account";
import { Typography } from "@mui/material";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { DRAWERS } from "constants/drawer";
import { useMediaDevice } from "hooks/useMediaDevice";

export const defaultLocation = {
  formatted_address: "",
  name: "",
  defaultLoc: false,
  lat: 0,
  lng: 0,
  appartement: "",
  street: "",
  city: "",
  state: "",
  zip: "",
};

export const useNewProfile = () => {
  const tabs = [
    {
      name: "Summary",
      value: "summary",
      desktop: {
        show: true,
        disabled: false,
      },
      mobile: {
        show: true,
        disabled: false,
      },
    },
    {
      name: "Locations",
      value: "locations",
      desktop: {
        show: true,
        disabled: false,
      },
      mobile: {
        show: true,
        disabled: false,
      },
    },
  ];

  const { isMobile } = useMediaDevice();
  const [tabValue, setTabValue] = useState(tabs[0].value);

  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const [isEdit, setIsEdit] = useState(false);
  const [editLocationIndex, setEditLocationIndex] = useState(null);
  const [editContact, setEditContact] = useState(null);
  const [editType, setEditType] = useState("");
  const dispatch = useDispatch();

  const selectCityState = getStates(currentUser.country);

  const phoneCodeList = getPhoneCodes(currentUser.country);

  const [expandedDetailsAccordion, setExpandedDetailsAccordion] =
    useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleGetCurrentValidation = () => {
    switch (editType) {
      case "Business details":
        return businessDetailsValidationSchema();
      case "Business contact":
        return businessContactsValidationSchema();
      case "locations":
        return locationsValidationSchema();
      default:
        return validationSchema();
    }
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors, isDirty },
    setError,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: getDefaultValues(currentUser, isMobile),
    resolver: yupResolver(handleGetCurrentValidation()),
  });

  const validationNames = Object.keys(validationSchema(isMobile)?.fields);

  const formField = useWatch({ control });

  const nameDebounced = useDebounce(formField.name, 500);

  useEffect(() => {
    if (!nameDebounced) return;
    const containsSpecial = containsSpecialChars(nameDebounced);

    if (containsSpecial)
      return setError("name", {
        type: "specialCharacter",
        message: "Invalid character used",
        valueRef: nameDebounced,
      });
    return clearErrors("name");
  }, [clearErrors, nameDebounced, setError]);

  useEffect(() => {
    if (!isEdit) reset(getDefaultValues(currentUser, isMobile));
  }, [isEdit, currentUser, reset, isMobile]);

  const initialErrPhoneStatus = {
    status: false,
    message: "",
    value: "",
  };

  const [errPhoneStatus, setErrPhoneStatus] = useState(initialErrPhoneStatus);

  const handleSubmitError = useCallback(
    (err) => {
      const errMsg = "Website must contain a valid domain name";
      const serverErrMsg = err?.response?.data?.message;
      const isWebsiteError = serverErrMsg.includes("body.website");

      if (/phone/.test(serverErrMsg)) {
        setErrPhoneStatus((prev) => ({
          ...prev,
          status: true,
          message: "Not valid phone number!",
          value: formField?.phone,
        }));
      }

      error(isWebsiteError ? errMsg : serverErrMsg || "Something went wrong.");
      if (isWebsiteError) setError("website", { message: errMsg });
      // eslint-disable-next-line no-console
      console.error(err?.response?.data);
    },
    [formField?.phone, setError]
  );

  const onLocationEditClose = useCallback(() => {
    setEditLocationIndex(null);
    setEditType("");
    dispatch(
      showCurrentDrawerAction({
        type: DRAWERS.USER_LOCATIONS_DRAWER,
        show: false,
      })
    );
  }, [dispatch]);

  const handleUpdateProfile = useCallback(
    (data) => {
      // setLoading(true);
      setEditType("");
      return dispatch(
        updateProfileAction({
          data,
          onSuccess: () => {
            setIsEdit(false);
            onLocationEditClose();
          },
          onError: (err) => handleSubmitError(err),
        })
      );
    },
    [dispatch, handleSubmitError, onLocationEditClose]
  );

  const onSubmit = useCallback(
    async (data) => {
      const containsSpecial = containsSpecialChars(formField.name);

      if (isMobile && containsSpecial)
        return setError("name", {
          type: "specialCharacter",
          message: "Invalid character used",
          valueRef: formField.name,
        });

      clearErrors("name");

      const profilePhotoId =
        data.profilePhoto?.id || data.profilePhotoId || null;

      const contacts = data.contacts.map(
        ({ appCustomerId, countryPrefix, ...c }) => ({
          ...c,
          phone: `${countryPrefix?.phone_code}${c?.phone}`.replace(
            /\s|\(|\)/g,
            ""
          ),
        })
      );

      const shippingAddressIndex = data.locations.findIndex(
        (loc) => loc.defaultLoc
      );

      const { defaultLoc, id, ...shippingAddress } =
        data.locations[shippingAddressIndex];
      const locations = data.locations.map((l) => {
        const { defaultLoc, ...res } = l;
        return res;
      });

      locations.splice(shippingAddressIndex, 1);

      const {
        shippingIsSame,
        profilePhoto,
        carriers,
        countryPrefix,
        ...uploadData
      } = {
        ...data,
        contacts,
        locations,
        shippingAddress,
        phone: isMobile
          ? `${data?.phone}`.replace(/\s|\(|\)/g, "")
          : `${data?.countryPrefix?.phone_code}${data?.phone}`.replace(
              /\s|\(|\)/g,
              ""
            ),
      };
      const cleanData = pickBy(uploadData, (value) => value !== "");

      const { lat, lng } = cleanData?.shippingAddress || {};

      const { timeZoneId } = await getTimeZoneService(
        lat,
        lng,
        Math.round(new Date().getTime() / 1000)
      );

      if (timeZoneId) cleanData.timeZone = timeZoneId;

      const preparedWebsite = checkAndPrepareWebsiteForCustomer(data?.website);

      const { country, ...rest } = cleanData;

      handleUpdateProfile({
        ...rest,
        profilePhotoId,
        federalTaxId:
          typeof data.federalTaxId === "number"
            ? data.federalTaxId || null
            : parseInt(data.federalTaxId?.replace("-", "")) || null,
        // federalTaxId: parseInt(data.federalTaxId?.replace("-", "")),
        website: preparedWebsite,
      });
    },
    [clearErrors, handleUpdateProfile, isMobile, formField?.name, setError]
  );

  const handleUpdateLogo = useCallback(async () => {
    try {
      const user = await getCurrentUser();
      setValue("profilePhoto", user?.profilePhoto);
      dispatch(setCurrentUser(user));
    } catch (err) {
      error(err?.response?.data?.message);
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message);
    }
  }, [dispatch, setValue]);

  const handleDeleteLogo = useCallback(async () => {
    await handleUpdateProfile({ profilePhotoId: null });
    setValue("profilePhoto", null);
  }, [handleUpdateProfile, setValue]);

  const onSaveHoverRevalidate = useCallback(
    (e) => {
      if (!isEdit) return;
      if (!isOpen) {
        setIsOpen(true);
        setAnchorEl(e.target);
      }

      if (trigger) {
        const invalidCharacter = errors.name?.type === "specialCharacter";

        if (invalidCharacter) {
          trigger(validationNames.filter((name) => name !== "name"));
          return;
        }
        trigger();
      }
    },
    [isEdit, isOpen, trigger, errors.name?.type, validationNames]
  );

  const checkError = (tab) => {
    switch (tab) {
      case "Summary": {
        const { name, federalTaxId, email, phone, billingAddress, contacts } =
          errors;
        return (
          !!name ||
          !!federalTaxId ||
          !!email ||
          !!phone ||
          !!billingAddress ||
          !!contacts
        );
      }
      case "Locations":
        return !!errors.locations;
      default:
        return false;
    }
  };

  const tooltipRef = useRef(null);

  const [isCroppedDialogOpen, setIsCroppedDialogOpen] = useState(false);
  const [progressPhotoUpload, setProgressPhotoUpload] = useState(false);
  const [preparingPhoto, setPreparingPhoto] = useState(false);

  const [tooltipData, setTooltipData] = useState({
    open: false,
    text: (
      <>
        <Typography component="span" fontSize={12}>
          • Use at least 500 px by 500 px <br /> • Use white or neutral
          background
        </Typography>
        <br />
      </>
    ),
    modifiers: [{ name: "offset", options: { offset: [10, 0] } }],
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "locations",
  });

  const handleSaveLocation = () => {
    const currentLocations = [...formField.locations];

    const shippingAddressIndex = currentLocations.findIndex(
      (loc) => loc.defaultLoc
    );

    const { defaultLoc, id, ...shippingAddress } =
      currentLocations[shippingAddressIndex];

    const locations = currentLocations.map((l) => {
      const { defaultLoc, ...res } = l;
      return res;
    });

    locations.splice(shippingAddressIndex, 1);
    handleUpdateProfile({ locations, shippingAddress });
    dispatch(
      showCurrentDrawerAction({
        type: DRAWERS.USER_LOCATIONS_DRAWER,
        show: false,
      })
    );
  };

  const handleDeleteLocation = useCallback(
    (index, skipSubmit = false) => {
      remove(index);

      if (isMobile && !skipSubmit) {
        let frontPart = formField.locations.slice(0, index);
        let lastPart = formField.locations.slice(index + 1);
        const filteredLocations = [...frontPart, ...lastPart];

        const shippingAddressIndex = filteredLocations.findIndex(
          (loc) => loc.defaultLoc
        );

        const { defaultLoc, id, ...shippingAddress } =
          filteredLocations[shippingAddressIndex];

        const locations = filteredLocations.map((l) => {
          const { defaultLoc, ...res } = l;
          return res;
        });

        locations.splice(shippingAddressIndex, 1);
        handleUpdateProfile({ locations, shippingAddress });
      }

      dispatch(
        showCurrentDrawerAction({
          type: DRAWERS.USER_LOCATIONS_DRAWER,
          show: false,
        })
      );
    },
    [dispatch, formField.locations, handleUpdateProfile, isMobile, remove]
  );

  const handleAddLocations = () => {
    append(defaultLocation);
  };

  const handleChangeDefault = (pos) => {
    const defIndex = formField.locations.findIndex((c) => c.defaultLoc);

    setValue(`locations.${defIndex}.defaultLoc`, false);
    setValue(`locations.${pos}.defaultLoc`, true);
  };

  const handleChangeDefaultLocation = (pos) => {
    const currentLocValue = formField.locations?.[pos]?.defaultLoc;

    formField.locations.forEach((_, index) => {
      if (index !== pos) {
        setValue(`locations.${index}.defaultLoc`, false);
      }
    });

    setValue(`locations.${pos}.defaultLoc`, !currentLocValue);
  };

  const onLocationEdit = (index) => {
    setEditType("locations");
    setEditLocationIndex(index);
    dispatch(
      showCurrentDrawerAction({
        type: DRAWERS.USER_LOCATIONS_DRAWER,
        show: true,
      })
    );
  };

  const onAddLocation = () => {
    handleAddLocations();
    setEditType("locations");
    setEditLocationIndex(fields.length);
    dispatch(
      showCurrentDrawerAction({
        type: DRAWERS.USER_LOCATIONS_DRAWER,
        show: true,
      })
    );
  };

  const onOpenContactDrawer = () => {
    dispatch(
      showCurrentDrawerAction({
        type: DRAWERS.CONTACT_DRAWER,
        show: true,
      })
    );
  };

  const onOpenCreateContact = () => {
    setEditContact(null);
    onOpenContactDrawer();
  };

  const onEditContact = (contact) => {
    const preparedContact = {
      ...contact,
      phone: setPhoneNumberMask(
        `${contact.countryPrefix?.phone_code || "+1"}${contact.phone}`
      ),
    };

    onOpenContactDrawer();
    setEditContact(preparedContact);
  };

  const onSaveContact = (contact, callback) => {
    const contacts = [...formField.contacts];
    const index = contacts.findIndex((c) => c.id === contact.id);

    if (index === -1) {
      const { id, ...contactRest } = contact;

      let preparedContacts = [...contacts];

      if (contactRest.defaultContact) {
        preparedContacts = contacts.map(
          ({ appCustomerId, countryPrefix, ...c }) => {
            return {
              ...c,
              phone: c.phone ? `${phoneCodeList[0].phone_code}${c.phone}` : "",
              defaultContact: false,
            };
          }
        );
      }

      preparedContacts = preparedContacts.map(
        ({ appCustomerId, countryPrefix, ...c }) => {
          return {
            ...c,
            phone: c.phone
              ? `${c.phone.includes("+") ? "" : phoneCodeList[0].phone_code}${
                  c.phone
                }`
              : "",
          };
        }
      );

      const newContact = {
        ...contactRest,
        phone: contactRest.phone
          ? `${phoneCodeList[0].phone_code}${contactRest.phone
              .replace(/\D/g, "")
              .slice(1)}`
          : "",
      };

      if (callback) callback();
      handleUpdateProfile({ contacts: [...preparedContacts, newContact] });
      return;
    }

    if (
      !editContact?.defaultContact &&
      contact.defaultContact &&
      contacts.length > 1
    ) {
      const defIndex = contacts.findIndex((c) => c.defaultContact);
      contacts[defIndex].defaultContact = false;
    }

    contacts[index] = {
      ...contact,
      phone: contact.phone
        ? `${phoneCodeList[0].phone_code}${contact.phone
            .replace(/\D/g, "")
            .slice(1)}`
        : "",
    };

    const preparedContacts = contacts.map(
      ({ appCustomerId, countryPrefix, ...c }) => {
        return {
          ...c,
          phone: c.phone
            ? `${c.phone.includes("+") ? "" : phoneCodeList[0].phone_code}${
                c.phone
              }`
            : "",
        };
      }
    );

    if (callback) callback();
    handleUpdateProfile({ contacts: preparedContacts });
  };

  const onDeleteContact = (id) => {
    if (!id) return;
    const contacts = [...formField.contacts];
    const index = contacts.findIndex((c) => c.id === id);

    contacts.splice(index, 1);

    const preparedContacts = contacts.map(
      ({ appCustomerId, countryPrefix, ...c }) => {
        return {
          ...c,
          phone: c.phone ? `${phoneCodeList[0].phone_code}${c.phone}` : "",
        };
      }
    );

    setEditContact(null);
    handleUpdateProfile({ contacts: preparedContacts });
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpandedDetailsAccordion(isExpanded ? panel : false);
    reset(getDefaultValues(currentUser, isMobile));

    if (!isExpanded) {
      setEditType("");
    }
  };

  const resetFunc = () => {
    reset(getDefaultValues(currentUser, isMobile));
    setEditType("");
  };

  return {
    tabs,
    tabValue,
    isEdit,
    control,
    handleUpdateLogo,
    handleDeleteLogo,
    setValue,
    errPhoneStatus,
    setIsEdit,
    onSaveHoverRevalidate,
    handleSubmit,
    onSubmit,
    errors,
    isOpen,
    setIsOpen,
    anchorEl,
    setTabValue,
    checkError,
    formField,
    tooltipRef,
    isCroppedDialogOpen,
    setIsCroppedDialogOpen,
    progressPhotoUpload,
    setProgressPhotoUpload,
    preparingPhoto,
    setPreparingPhoto,
    tooltipData,
    setTooltipData,
    selectCityState,
    phoneCodeList,
    editLocation: editLocationIndex,
    onLocationEdit,
    onLocationEditClose,
    onAddLocation,
    handleAddLocations,
    handleDeleteLocation,
    fields,
    handleChangeDefault,
    setEditLocationIndex,
    resetFunc,
    editContact,
    setEditContact,
    onEditContact,
    onSaveContact,
    onDeleteContact,
    handleSaveLocation,
    editType,
    setEditType,
    isDirty,
    handleChangeAccordion,
    expandedDetailsAccordion,
    setExpandedDetailsAccordion,
    handleChangeDefaultLocation,
    onOpenCreateContact,
  };
};
