/* eslint-disable react/prop-types */
import React from "react";

export const ShevronIcon = ({
  className,
  color = "#B5B5AC",
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20.557 11.779"
      color={color}
    >
      <g id="icon-chevron" transform="translate(2.121 2.121)">
        <path
          id="path"
          d="M0,16.314,8.157,8.157,0,0"
          transform="translate(16.314) rotate(90)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};
