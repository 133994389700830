export const cl = {
  productDescription: {
    color: "rgba(0, 0, 0, 0.40)",
    fontSize: "10px",
    fontWeight: 400,
    wordWrap: "break-word",
  },
  priceText: {
    color: "black",
    fontSize: "18px",
    fontWeight: "600 !important",
    wordWrap: "break-word",
  },
};
