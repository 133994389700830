import * as React from "react";

export const SandClockIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.818}
    height={11.818}
    viewBox="0 0 11.818 11.818"
    {...props}
  >
    <g data-name="Group 7043">
      <g data-name="Group 1861">
        <g data-name="Group 1858">
          <path
            data-name="Path 1213"
            d="M2.592 2.078h6.682a.418.418 0 0 0 .418-.418v-.627H2.174v.627a.418.418 0 0 0 .418.418Zm7.1 8.567H2.174v-.627a.418.418 0 0 1 .418-.418h6.682a.418.418 0 0 1 .418.418Zm-1.04-8.567v1.613a1.672 1.672 0 0 1-.744 1.391l-1.134.757 1.136.757a1.672 1.672 0 0 1 .744 1.391V9.6M3.219 2.078v1.613a1.672 1.672 0 0 0 .744 1.391l1.136.757-1.136.757a1.672 1.672 0 0 0-.744 1.391V9.6"
            fill="none"
            stroke="#959595"
            strokeMiterlimit={10}
            strokeWidth={0.5}
          />
          <path
            data-name="Path 1214"
            d="M7.816 9.6c0-1.672-1.881-2.3-1.881-2.3s-1.88.663-1.88 2.3ZM4.264 3.749c0 1.064 1.672 1.463 1.672 1.463s1.672-.421 1.672-1.463Z"
            fill="#959595"
          />
        </g>
        <path
          data-name="Rectangle 2262"
          fill="none"
          d="M0 0h11.818v11.818H0z"
        />
      </g>
    </g>
  </svg>
);
