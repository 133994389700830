import { useCallback, useContext } from "react";
import PropTypes, { func, string, object } from "prop-types";
import { DialogActions, Drawer } from "@mui/material";
import { StyledButton } from "components";
import { useStyles } from "../../../ContactDrawer/styles";
import { cl } from "./styles";

import { ConfirmContext } from "pages/MasterPage/MasterPage";
import { DrawerHeader } from "components/ContactDrawer/components/DrawerHeader";

export const UniversalConfirmDrawer = () => {
  const classes = useStyles();
  const { confirmState, setConfirmState } = useContext(ConfirmContext);

  const handleConfirmClose = useCallback(() => {
    setConfirmState((prev) => ({
      ...prev,
      isOpen: false,
    }));
  }, [setConfirmState]);

  const { titleElement, loading, title, isOpen, onConfirm, confirmDrawerSx } =
    confirmState;

  return (
    <Drawer
      open={!!isOpen}
      anchor="bottom"
      onClose={handleConfirmClose}
      PaperProps={{
        className: classes.drawerWrapper,
        //sx: { minHeight: "57svh" },
        sx: confirmDrawerSx,
      }}
    >
      <DrawerHeader
        {...{
          disabled: loading,
          handleClose: handleConfirmClose,
          title,
          withDivider: false,
        }}
      />

      {!!titleElement && titleElement}

      <DialogActions sx={{ p: 0, width: "100%" }}>
        <StyledButton
          sx={cl.actionBtn}
          onClick={handleConfirmClose}
          label="Cancel"
          variant="contained"
        />
        <StyledButton
          sx={{ ...cl.actionBtn, background: "#E41E3A" }}
          onClick={() => {
            onConfirm();
            handleConfirmClose();
          }}
          variant="contained"
          label="Confirm"
        />
      </DialogActions>
    </Drawer>
  );
};

UniversalConfirmDrawer.propTypes = {
  text: PropTypes.oneOfType([string, object]),
  onCancel: func,
  onAccept: func,
};

UniversalConfirmDrawer.defaultProps = {
  // isOpen: false,
  text: "Are you sure?",
};
