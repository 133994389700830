import { any, string } from "prop-types";
import { Grid, Typography } from "@mui/material";
import { getFormattedDate, separateNumWithComma } from "helpers/helpers";
import moment from "moment";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { currentUserSelector } from "redux/selectors/auth";
import { useMediaDevice } from "hooks/useMediaDevice";
import { useEffect, useRef, useState } from "react";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const TransactionItem = ({ date, name, order, sum, type }) => {
  const { currentUser } = useSelector(selector);
  const { isMobile } = useMediaDevice();
  const priceRef = useRef(null);

  const [priceBoxWidth, setPriceBoxWidth] = useState(0);

  useEffect(() => {
    if (priceRef.current) {
      setPriceBoxWidth(priceRef.current.offsetWidth);
    }
  }, [priceRef]);

  const { timeZone } = currentUser || {};

  const formattedSum = `${type === "REFUND" ? "+" : "-"}$${separateNumWithComma(
    Math.abs(sum)
  )}`;

  const formattedDate = moment(getFormattedDate(date, timeZone)).format(
    "MMMM D, YYYY"
  );
  return (
    <Grid
      sx={{
        border: { xs: "1px rgba(0, 0, 0, 0.05) solid", sm: "0.5px solid #CCC" },
        borderRadius: { xs: "8px", sm: "4px" },
        height: { xs: "100%", sm: "112px" },
        p: { xs: "8px 10px", sm: 0 },
      }}
      container
      flexWrap="nowrap"
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          pl: { xs: 0, sm: 3.6 },
        }}
        {...(!isMobile
          ? { xs: 10 }
          : { sx: { maxWidth: `calc(100% - ${priceBoxWidth + 8}px)` } })}
        item
      >
        <Typography
          fontSize={{ xs: "10px", sm: 18 }}
          fontWeight={300}
          lineHeight={{ xs: "15px", sm: 1.5 }}
          color={{ xs: "rgba(0, 0, 0, 0.40)", sm: "#B5B5B5" }}
        >
          {formattedDate}
        </Typography>
        <Typography
          lineHeight={{ xs: "21px", sm: 1.5 }}
          fontSize={{ xs: "14px", sm: 22 }}
          fontWeight={{ xs: 400, sm: 500 }}
          color="#000"
          noWrap
        >
          Visa ending in {name}
        </Typography>
        <Typography
          fontSize={{ xs: "10px", sm: 18 }}
          fontWeight={400}
          lineHeight={{ xs: "15px", sm: 1.5 }}
          color={{ xs: "rgba(0, 0, 0, 0.40)", sm: "#B5B5B5" }}
          noWrap
        >
          Order# {order}
        </Typography>
      </Grid>

      <Grid
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        ref={priceRef}
        ml="auto"
        pr={{ xs: 0, sm: 3.6 }}
        {...(!isMobile ? { xs: 2 } : { sx: { maxWidth: "50%" } })}
        item
      >
        <Typography
          fontSize={{ xs: "14px", sm: 22 }}
          fontWeight={{ xs: 600, sm: 500 }}
          color="#000"
          noWrap
        >
          {formattedSum}
        </Typography>
      </Grid>
    </Grid>
  );
};

TransactionItem.propTypes = {
  date: string,
  name: string,
  order: string,
  sum: any,
  type: string,
};
TransactionItem.defaultProps = { date: "", name: "", order: "" };
