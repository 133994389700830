import { Stack, Typography } from "@mui/material";
import { CardIconComponent } from "components";
import { setCardName } from "helpers/helpers";
import { DRAWERS } from "constants/drawer";
import { object } from "prop-types";
import { classes } from "../DeliveryCartBlock/styles";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { useDispatch } from "react-redux";

export const SelectedCard = ({ card }) => {
  const dispatch = useDispatch();
  const handleOpenDrawer = () =>
    dispatch(
      showCurrentDrawerAction({ type: DRAWERS.SELECT_CARD_DRAWER, show: true })
    );
  return (
    <Stack sx={classes.selectedCardWrapper} onClick={handleOpenDrawer}>
      <CardIconComponent
        type={card?.brand === "visa" ? "mobile-visa" : card?.brand}
        size={32}
      />
      <Stack>
        <Typography sx={classes.cardNumber}>
          {setCardName(card?.brand)} ending in{" "}
          {card?.number?.slice(-4) || card?.last4}
        </Typography>
        <Typography sx={classes.cardName}>
          {card?.billingAddress?.name} | Expires{" "}
          {`${card?.expMonth}/${String(card?.expYear).slice(-2)}`}
        </Typography>
      </Stack>
    </Stack>
  );
};

SelectedCard.propTypes = {
  card: object,
};
