import { CategoryChip } from "components";
import { DRAWERS } from "constants/drawer";
import {
  DEFAULT_VENDOR_FILTER_DATA,
  DEFAULT_VENDOR_FILTER_STATE,
  FILTER_FIELD_NAMES,
  INDUSTRIES_WITH_IDS,
  MOBILE_MINIMUM_ORDER_FILTERS,
  ORDER_DIRECT_FILTERS,
} from "pages/VendorsPage/VendorsPage.constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showCurrentDrawerAction } from "redux/actions/uiState";

export const useVendorFilters = ({ setVendorsParams }) => {
  const dispatch = useDispatch();

  const [chipData, setChipData] = useState(DEFAULT_VENDOR_FILTER_STATE);
  const [filterState, setFilterState] = useState(DEFAULT_VENDOR_FILTER_STATE);

  const isFilterDrawerOpened = useSelector(
    ({ uiState }) => uiState.filterDrawer
  );

  const handleIndustries = (industryList) => {
    if (!industryList?.length) return [];
    return industryList.map((item) => item.value);
  };

  const FILTER_DATA = useMemo(() => {
    return {
      orderDirect: {
        show: true,
        value: filterState.orderDirect.value,
      },
      minimumOrder: {
        show: true,
        value: filterState.minimumOrder.value,
      },
      industry: {
        show: true,
        value: handleIndustries(filterState.industry),
      },
    };
  }, [filterState]);

  const handleAppliedFilters = useCallback(() => {
    let count = 0;
    for (let key in chipData) {
      if (Object.keys(chipData[key]).length) {
        count++;
      }
    }
    return count;
  }, [chipData]);

  const filterCount = useMemo(
    () => handleAppliedFilters(),
    [handleAppliedFilters]
  );

  useEffect(() => {
    if (isFilterDrawerOpened) {
      setFilterState(chipData);
    }
  }, [chipData, isFilterDrawerOpened]);

  const handleApplyFilter = (state, chipState) => {
    const { orderDirect, minimumOrder, industry } = state;

    setVendorsParams((prev) => ({
      ...prev,
      order_direct: orderDirect.value ?? "",
      min_order: minimumOrder.value ?? "",
      industries: industry?.value?.length ? industry?.value : [],
    }));
    setChipData(chipState);
  };

  const onSave = () => {
    handleApplyFilter(FILTER_DATA, filterState);
  };

  const handleDeleteChip = (category, chipId) => {
    if (category === "industry") {
      const filteredState = chipData[category].filter(
        ({ id }) => id !== chipId
      );

      setChipData((prev) => ({ ...prev, industry: filteredState }));
      setVendorsParams((prev) => ({ ...prev, industries: filteredState }));
      return;
    }

    setChipData((prev) => ({ ...prev, [category]: {} }));
    setVendorsParams((prev) => ({
      ...prev,
      [FILTER_FIELD_NAMES[category]]: "",
    }));
    return;
  };

  const onStateClear = () => {
    setFilterState(DEFAULT_VENDOR_FILTER_STATE);

    if (filterCount) {
      handleApplyFilter(
        DEFAULT_VENDOR_FILTER_DATA,
        DEFAULT_VENDOR_FILTER_STATE
      );
    }
  };

  const setState = (key, value) =>
    setFilterState((prev) => ({ ...prev, [key]: value }));

  const currentFilterElement = useMemo(() => {
    return {
      orderDirect: {
        list: ORDER_DIRECT_FILTERS,
        Item: CategoryChip,
        setState: (value) => setState("orderDirect", value),
        defaultValue: {},
        getProps: (item, handleCheckItem, handleIsActive) => ({
          title: item.name,
          isActive: handleIsActive({
            state: filterState.orderDirect,
            id: item.id,
          }),
          onClick: (item) =>
            handleCheckItem({
              setState: (value) => setState("orderDirect", value),
              item,
              state: filterState.orderDirect,
            }),
        }),
      },
      minimumOrder: {
        list: MOBILE_MINIMUM_ORDER_FILTERS,
        Item: CategoryChip,
        setState: (value) => setState("minimumOrder", value),
        defaultValue: {},
        getProps: (item, handleCheckItem, handleIsActive) => ({
          title: item.name,
          isActive: handleIsActive({
            state: filterState.minimumOrder,
            id: item.id,
          }),
          onClick: (item) =>
            handleCheckItem({
              setState: (value) => setState("minimumOrder", value),
              item,
              state: filterState.minimumOrder,
            }),
        }),
      },
      industry: {
        list: INDUSTRIES_WITH_IDS,
        Item: CategoryChip,
        setState: (value) => setState("industry", value),
        defaultValue: [],
        getProps: (item, handleCheckItem, handleIsActive) => ({
          title: item.name,
          isActive: handleIsActive({
            state: filterState.industry,
            id: item.id,
            isMultiple: true,
          }),
          onClick: (item) =>
            handleCheckItem({
              setState: (value) => setState("industry", value),
              item,
              state: filterState.industry,
              isMultiple: true,
            }),
        }),
      },
    };
  }, [filterState.industry, filterState.minimumOrder, filterState.orderDirect]);

  const openFilterDrawer = () =>
    dispatch(
      showCurrentDrawerAction({ type: DRAWERS.FILTER_DRAWER, show: true })
    );

  const disabledActionBtn = !filterCount;

  return {
    onSave,
    chipData,
    filterCount,
    FILTER_DATA,
    onStateClear,
    openFilterDrawer,
    handleDeleteChip,
    disabledActionBtn,
    currentFilterElement,
  };
};
