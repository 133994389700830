import React from "react";
import { string } from "prop-types";

import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { OrderStatuses } from "../../../../../components";

export const ReturnsTabItem = ({
  customId,
  rma,
  supplier,
  reason,
  entered,
  status,
}) => {
  return (
    <Paper
      sx={{
        height: "48px",
        alignItems: "center",
        borderRadius: "0 0 4px 4px",
        "&:not(:last-child)": {
          borderBottom: "1px solid #D5D9D9", // check theme
          borderRadius: 0,
        },
      }}
      elevation={0}
      square
      component={Grid}
      container
    >
      <Grid xs={0.15} item />
      {/* Custom ID */}
      <Grid sx={{ display: "flex" }} xs={1.2} item>
        <Box display="flex" gap="5px">
          <Typography
            sx={{
              color: "#26874B",
              cursor: "pointer",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            variant="typographyMedium"
          >
            {customId}
          </Typography>
        </Box>
      </Grid>
      {/* RMA # */}
      <Grid item xs={1.4}>
        {rma ? (
          <Typography variant="typography" color="groundBlack">
            {rma}
          </Typography>
        ) : (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 300,
              fontStyle: "italic",
              color: "#C8C8C8",
            }}
          >
            None provided
          </Typography>
        )}
      </Grid>
      {/* Supplier */}
      <Grid item xs={1.6}>
        <Typography variant="typography" color="groundBlack">
          {supplier}
        </Typography>
      </Grid>
      {/* Reason */}
      <Grid
        item
        xs={3.4}
        sx={{
          flexDirection: "column",
          alignItems: "flex-start",
        }}
        variant="typography"
      >
        <Typography variant="lightText">{reason}</Typography>
      </Grid>
      {/* Entered */}
      <Grid item xs={1.6}>
        <Typography variant="lightText">{entered}</Typography>
      </Grid>
      {/* Status */}
      <Grid
        item
        xs={1.2}
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          justifyContent: "flex-start",
        }}
      >
        <OrderStatuses type={status} />
      </Grid>
      {/* Get Label button */}
      <Grid
        sx={{
          display: "flex",
          justifyContent: "right",
        }}
        xs={1.3}
        item
      >
        <Button
          sx={{
            width: 105,
            height: 26,
            fontSize: 14,
            fontWeight: 300,
            color: "#1C1C19",
            borderColor: "#DBE2ED",
            borderRadius: "8px",
          }}
          variant="outlined"
          // onClick={handleRegister}
        >
          Get Label
        </Button>
      </Grid>
    </Paper>
  );
};
ReturnsTabItem.propTypes = {
  customId: string,
  rma: string,
  supplier: string,
  reason: string,
  entered: string,
  status: string,
};
ReturnsTabItem.defaultProps = {
  customId: "",
  rma: "",
  supplier: "",
  reason: "",
  entered: "",
  status: "",
};
