export const VIEW_SETTINGS_ITEMS = {
  suppliers: {
    id: "suppliers",
    name: "Suppliers",
    type: "checkbox",
    items: [],
  },
  payment_status: {
    id: "payment_status",
    name: "Payment Status",
    type: "radio",
    items: [
      { id: "", name: "All" },
      { id: "paid", name: "Paid" },
      { id: "partially_paid", name: "Partially Paid" },
      { id: "pending", name: "Unpaid" },
      { id: "refunded", name: "Refunded" },
    ],
    selected: "",
  },
  fulfillment_status: {
    id: "fulfillment_status",
    name: "Fulfillment Status",
    type: "radio",
    items: [
      { id: "", name: "All" },
      { id: "fulfilled", name: "Fulfilled" },
      { id: "partially_fulfilled", name: "Partially Fulfilled" },
      { id: "unfulfilled", name: "Unfulfilled" },
    ],
    selected: "",
  },
};

export const ORDERS_HEADER_ITEMS = [
  {
    id: "0",
    name: "",
    size: 0.15,
  },
  {
    id: "1",
    name: "ORDER ID",
    size: 1,
  },
  {
    id: "2",
    name: "SUPPLIER",
    size: 1.6,
  },
  {
    id: "3",
    name: "ORDERED",
    size: 1.6,
  },
  { id: "4", name: "CREATED BY", size: 1.2 },
  { id: "5", name: "TOTAL ITEMS", size: 1.2 },
  { id: "6", name: "TOTAL", size: 1.2 },
  { id: "7", name: "PAYMENT", size: 1.4 },
  { id: "8", name: "FULFILLED", size: 1.6 },
  { id: "9", name: "", size: 1 },
];

export const RETURNS_HEADER_ITEMS = [
  {
    id: "0",
    name: "",
    size: 0.15,
  },
  {
    id: "1",
    name: "ID",
    size: 1.2,
  },
  {
    id: "2",
    name: "RMA #",
    size: 1.4,
  },
  {
    id: "3",
    name: "SUPPLIER",
    size: 1.6,
  },
  { id: "4", name: "REASON", size: 3.4 },
  { id: "5", name: "ENTERED", size: 1.6 },
  { id: "6", name: "STATUS", size: 1.2 },
  { id: "7", name: "", size: 1 },
];

export const STATUS_COLORS = {
  FULFILLED: "#409A65",
  UNFULFILLED: "#CE8C0C",
  PARTIALLY_FULFILLED: "#CE8C0C",
};

export const DEFAULT_FILTER_DATA = {
  paymentStatus: {
    show: false,
    values: [],
  },
  fulfillmentStatus: {
    show: false,
    values: [],
  },
  date: {
    show: false,
    values: [],
  },
  createdBy: {
    show: false,
    values: [],
  },
};

export const PAYMENT_STATUS_LIST = [
  { name: "All", id: 1, value: null },
  { name: "Paid", id: 2, value: "PAID" },
  { name: "Partially Paid", id: 3, value: "PARTIALLY_PAID" },
  { name: "Unpaid", id: 4, value: "PENDING" },
  { name: "Refunded", id: 5, value: "REFUNDED" },
];

export const FULFILLMENT_STATUS_LIST = [
  { name: "Fulfilled", id: 1, value: "FULFILLED" },
  { name: "Partially Fulfilled", id: 2, value: "PARTIALLY_FULFILLED" },
  { name: "Unfulfilled", id: 3, value: "UNFULFILLED" },
];

export const DATE_LIST = [
  { name: "Last 30 days", value: "last_30_days", id: 1 },
  { name: "Last 3 months", value: "last_3_months", id: 2 },
];

export const FILTER_TABS = [
  "paymentStatus",
  "fulfillmentStatus",
  "date",
  "createdBy",
];

export const DEFAULT_FILTER_STATE = {
  date: {},
  createdBy: {},
  paymentStatus: {},
  fulfillmentStatus: {},
};
