import { useNavigate } from "react-router-dom";

import { cl, useStyles } from "./styles";

import { StyledArrowIcon } from "components/Icons";

import AddIcon from "@mui/icons-material/Add";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { userCartStepAction } from "redux/actions/drafts";

export const OrderHeaderBlock = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userCartStep = useSelector(({ drafts }) => drafts.userCartStep);

  const handleClickBack = () => {
    if (userCartStep === 1) return navigate(-1);
    return dispatch(userCartStepAction(userCartStep - 1));
  };

  return (
    <Stack sx={cl.wrapper} direction="row">
      <Stack sx={cl.backArrowBlock}>
        <IconButton sx={{ p: 0, width: "24px" }} onClick={handleClickBack}>
          <StyledArrowIcon />
        </IconButton>
      </Stack>
      <Typography sx={cl.titleText}>Your Order</Typography>
      <Stack gap={2} justifyContent="flex-end" alignItems="center" width="87px">
        {userCartStep === 1 && (
          <Button className={styles.addItemsBtn} onClick={() => navigate("/")}>
            <AddIcon />
            Add items
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
