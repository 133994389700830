import { bool, object, oneOfType, string } from "prop-types";
import { Box, Typography } from "@mui/material";

export const ItemBlock = ({
  title,
  mainText,
  subText,
  borderBottom,
  select,
}) => {
  return (
    <Box
      sx={{
        height: "54px",
        minHeight: "54px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 2.25,
        position: "relative",
      }}
    >
      <Typography
        width="40%"
        fontSize={13}
        fontWeight={600}
        color="#979797"
        noWrap
      >
        {title}
      </Typography>
      {select ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {select}
        </Box>
      ) : (
        <Box
          sx={{
            maxWidth: "60%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Typography fontSize={13} fontWeight={400} color="#000" noWrap>
            {mainText || "-"}
          </Typography>
          <Typography fontSize={13} fontWeight={400} color="#000" noWrap>
            {subText}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          width: "95%",
          height: "1px",
          borderBottom: borderBottom && "1px solid #D5D9D9",
          position: "absolute",
          bottom: 0,
          right: 10,
        }}
      />
    </Box>
  );
};

ItemBlock.propTypes = {
  title: string,
  mainText: oneOfType([string, object]),
  subText: string,
  borderBottom: bool,
  select: object,
};
ItemBlock.defaultProps = {
  title: "no title",
  mainText: "",
  subText: "",
  borderBottom: false,
};
