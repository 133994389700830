import * as React from "react";
export const SuccessIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.91}
    height={22.91}
    viewBox="0 0 22.91 22.91"
    role="img"
    {...props}
  >
    <g data-name="Ellipse 1054" fill="none" stroke="#47a06d">
      <circle cx={11.455} cy={11.455} r={11.455} stroke="none" />
      <circle cx={11.455} cy={11.455} r={10.955} />
    </g>
    <path
      data-name="Path 5217"
      d="m6.754 12.698 2.576 2.578 7.641-7.64"
      fill="none"
      stroke="#47a06d"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
