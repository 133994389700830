import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import {
  LargeCheckbox,
  StyledButton,
  StyledSelect,
  StyledTextField,
} from "components";
import { CrossIcon, OutlinedPlusIcon } from "components/Icons";
import { normalizeUppercaseString, onPastePhone } from "helpers/helpers";
import { bool, func, object, array, string } from "prop-types";
import { useCallback, useEffect, useRef } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import NumberFormat from "react-number-format";
import { TYPES } from "./contactTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./AddContactPopup.validations";

export const AddContactPopup = ({
  type,
  isOpen,
  editContact,
  handleClose,
  contacts,
  handleAddContact,
}) => {
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    reset,
    // formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      // fid: editContact?.fid || Date.now(),
      // id: editContact?.id || "",
      // email: editContact?.email || "",
      // name: editContact?.name || "",
      // phone: editContact?.phone || "",
      // role: editContact?.role
      //   ? TYPES.includes(editContact?.role)
      //     ? editContact?.role
      //     : "custom"
      //   : "Primary",
      // defaultContact: !!editContact?.defaultContact,
      // custom: editContact?.role
      //   ? TYPES.includes(editContact?.role)
      //     ? ""
      //     : editContact?.role
      //   : "",
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({
    control: control2,
  });

  const isCustom = (role) => {
    return ![...TYPES, ""].includes(role);
  };

  const onSubmit = useCallback(
    (data) => {
      const { fid, custom, ...newData } = { ...data, id: data.id || undefined };
      if (custom) newData.role = custom;
      handleAddContact(newData);

      // handleAddContact(
      //   {
      //     name,
      //     phone: phone.replace(/\s|\(|\)/g, ""),
      //     email,
      //     defaultContact,
      //     role: data.role === "custom" ? custom : role,
      //     fid,
      //     id: id || undefined,
      //   },
      //   !!editContact
      // );
      // handleClose();
    },
    [handleAddContact]
  );

  useEffect(() => {
    if (isOpen) {
      reset({
        fid: editContact?.fid || Date.now(),
        id: editContact?.id || "",
        email: editContact?.email || "",
        name: editContact?.name || "",
        phone: editContact?.phone || "",
        role: editContact?.role
          ? TYPES.includes(editContact?.role)
            ? editContact?.role
            : "custom"
          : "Owner",
        defaultContact: contacts.length ? !!editContact?.defaultContact : true,
        custom: editContact?.role
          ? TYPES.includes(editContact?.role)
            ? ""
            : editContact?.role
          : "",
      });
    }

    //   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reset,
    isOpen,
    editContact?.id,
    editContact?.fid,
    editContact?.email,
    editContact?.name,
    editContact?.phone,
    editContact?.role,
    editContact?.defaultContact,
    contacts.length,
  ]);

  // const isDisabled = useMemo(() => {
  //   return (
  //     !formField?.name ||
  //     !formField?.role ||
  //     (formField?.role === "custom" && !formField?.custom) ||
  //     errors?.name ||
  //     errors?.phone ||
  //     errors?.email ||
  //     errors?.role ||
  //     errors?.custom
  //   );
  // }, [
  //   errors?.custom,
  //   errors?.email,
  //   errors?.name,
  //   errors?.phone,
  //   errors?.role,
  //   formField?.custom,
  //   formField?.name,
  //   formField?.role,
  // ]);

  const saveBtnNameRef = useRef(null);
  const titleRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      saveBtnNameRef.current = editContact ? "Save contact" : "Add Contact";
      titleRef.current = editContact
        ? `Edit ${type} contact`
        : `New ${type} contact`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog open={isOpen}>
      <Box sx={{ borderBottom: "0.5px solid #D5D9D9" }}>
        <IconButton
          sx={{ position: "absolute", top: 14, right: 14, p: 0 }}
          onClick={handleClose}
        >
          <CrossIcon />
        </IconButton>
        <Typography
          p="14px 0 12px 26px"
          fontWeight="400"
          fontSize="17px"
          color="#3F3F3F"
        >
          {/* {titleRef.current} */}
          New delivery contact
        </Typography>
      </Box>
      <DialogContent sx={{ p: "10px 42px 24px 47px", minWidth: "413px" }}>
        <Grid
          display="flex"
          sx={{ flexDirection: "column" }}
          container
          component="form"
          id="add-contact-form"
          onSubmit={handleSubmit2(onSubmit)}
          key="add-contact-form"
        >
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                formSx={{ mt: "15px" }}
                fullWidth
                size="small"
                // InputProps={{
                //   className: cl.textInput,
                // }}
                // InputLabelProps={{
                //   className: cl.inputLabel,
                // }}
                label="Full name *"
                error={error ? error.message : ""}
                {...field}
                autoComplete="new-password"
                onPaste={(e) => {
                  onPastePhone({ e, setValue, currentValue: formField.phone });
                }}
              />
            )}
            name="name"
            control={control2}
          />

          <Controller
            render={({ field, fieldState: { error } }) => (
              <NumberFormat
                customInput={StyledTextField}
                fullWidth
                formSx={{ mt: "26px" }}
                size="small"
                error={error ? error.message : ""}
                label="Phone number *"
                format="+# (###) ### ####"
                mask="_"
                onClick={() => {
                  if (["", "+"].includes(field?.value)) {
                    setValue("phone", "+1");
                  }
                }}
                {...field}
                autoComplete="new-password"
                onPaste={(e) => {
                  onPastePhone({ e, setValue, currentValue: formField.phone });
                }}
              />
            )}
            name="phone"
            control={control2}
          />

          {/* <Controller
            render={({ field, fieldState: { error } }) => (
              <NumberFormat
                customInput={StyledTextField}
                fullWidth
                formSx={{ mt: "26px" }}
                size="small"
                // InputProps={{
                //   className: cl.textInput,
                // }}
                // InputLabelProps={{
                //   className: cl.inputLabel,
                // }}
                error={error ? error.message : ""}
                label="Phone number *"
                format="+# (###) ### ####"
                mask="_"
                {...field}
              />
            )}
            name="phone"
            control={control2}
          /> */}
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                formSx={{ mt: "26px" }}
                fullWidth
                size="small"
                // InputProps={{
                //   className: cl.textInput,
                // }}
                // InputLabelProps={{
                //   className: cl.inputLabel,
                // }}
                label="Email address *"
                error={error ? error.message : ""}
                {...field}
              />
            )}
            name="email"
            control={control2}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledSelect
                formSx={{
                  mt: "26px",
                  mb: "9px",
                  "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
                    paddingLeft: 0,
                  },
                }}
                label="Contact type"
                leftSidedIcon
                error={error ? error.message : ""}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiList-root": {
                        pb: 0,
                      },
                    },
                  },
                }}
                {...field}
                renderValue={(s) => normalizeUppercaseString(s)}
              >
                {TYPES.map((type) => (
                  <MenuItem key={type} value={type} sx={{ fontSize: "12px" }}>
                    {type}
                  </MenuItem>
                ))}
                <Divider sx={{ m: "0!important" }} />
                <MenuItem
                  value="custom"
                  sx={{
                    color: "#409A65",
                    fontSize: "12px",
                    "& svg": {
                      mr: "5px",
                    },
                  }}
                >
                  <OutlinedPlusIcon /> Custom
                </MenuItem>
              </StyledSelect>
            )}
            name="role"
            control={control2}
          />

          {isCustom(formField?.role) && (
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  formSx={{ mt: "15px" }}
                  fullWidth
                  size="small"
                  // InputProps={{
                  //   className: cl.textInput,
                  // }}
                  // InputLabelProps={{
                  //   className: cl.inputLabel,
                  // }}
                  label="Custom"
                  error={error ? error.message : ""}
                  {...field}
                />
              )}
              name="custom"
              control={control2}
            />
          )}

          <Controller
            render={({ field }) => (
              <LargeCheckbox
                disabled={!contacts.length}
                checked={!!field.value}
                formSx={{ ml: "auto", mr: 0, mb: "13px" }}
                label={
                  <Typography color="#9C9C94" fontSize="11px" fontWeight="400">
                    primary contact
                  </Typography>
                }
                size={16}
                {...field}
              />
            )}
            name="defaultContact"
            control={control2}
          />
          {/* 
          <StyledButton
            disabled={isDisabled}
            sx={{ height: 34, mb: "13px", boxShadow: "none" }}
            onClick={handleSubmit(onSubmit)}
            label={
              <Typography fontSize="15px" fontWeight="normal">
                {saveBtnNameRef.current}
                Add Contact
              </Typography>
            }
            variant="contained"
          />
          <StyledButton
            sx={{ height: 34, borderColor: "#D5D9D9" }}
            onClick={handleClose}
            label={
              <Typography
                fontSize="15px"
                sx={{
                  color: "#5f6267",
                }}
                fontWeight="normal"
              >
                Cancel
              </Typography>
            }
            variant="outlined"
          /> */}
          <Box display="flex" flexDirection="column">
            <StyledButton
              // disabled={isDisabled}
              sx={{ height: 34, mb: "13px", boxShadow: "none" }}
              // onClick={handleSubmit(onSubmit)}
              form="add-contact-form"
              type="submit"
              label={
                <Typography fontSize="15px" fontWeight="normal" color="#fff">
                  Add Contact
                </Typography>
              }
              variant="contained"
            />
            <StyledButton
              sx={{ height: 34, borderColor: "#D5D9D9" }}
              onClick={handleClose}
              label={
                <Typography
                  fontSize="15px"
                  sx={{
                    color: "#5f6267",
                  }}
                  fontWeight="normal"
                >
                  Cancel
                </Typography>
              }
              variant="outlined"
            />
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

AddContactPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  handleAddContact: func,
  editContact: object,
  contacts: array,
  type: string,
};

AddContactPopup.defaultProps = {
  isOpen: false,
  contacts: [],
  type: "customer",
};
