import * as React from "react";

export const RegLabelIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={72}
    height={72}
    viewBox="0 0 72 72"
    {...props}
  >
    <rect
      data-name="Rectangle 9431"
      width={72}
      height={72}
      rx={4}
      fill="rgba(64,154,101,0.1)"
    />
    <path
      data-name="Path 4225"
      d="M49.694 20.857H38.112a4.033 4.033 0 0 0-2.471 1.023L21.282 36.24a1.452 1.452 0 0 0 0 2.048l12.429 12.429a1.452 1.452 0 0 0 2.048 0l14.36-14.36a4.026 4.026 0 0 0 1.023-2.471V22.305a1.453 1.453 0 0 0-1.448-1.448Zm-7.239 11.582a2.9 2.9 0 1 1 2.048-.848 2.9 2.9 0 0 1-2.048.848Z"
      fill="none"
      stroke="#409a65"
      strokeWidth={1.5}
    />
  </svg>
);
