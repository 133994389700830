import { useContext } from "react";

import {
  CartSummaryBlock,
  ContactsBlock,
  OrdersListBlock,
  TitleBlock,
} from "..";
import { CustomerCards, Loader, ViewAllProductsDialog } from "components";
import { AddContactPopup } from "../ContactsBlock/AddContactPopup/AddContactPopup";

import { CartContext } from "pages/CartPage/CartPage";
import { CART_FORM } from "pages/CartPage/CartPage.constants";

import { Box, Grid } from "@mui/material";

export const DesktopCartPage = () => {
  const {
    cartData,
    contacts,
    draftsInitialLoaded,
    formField,
    viewAllProductsDialogState,
    handleCloseContact,
    handleAddNewContact,
    handleCloseCards,
    customerId,
    billingAddress,
    handleConfirmCard,
    removeDraftCard,
    handleSetCardToCart,
    handleSubmitCart,
    handleCloseViewAllProductsDialog,
    casesCount,
    resetCardCart,
  } = useContext(CartContext);

  return (
    <>
      <Loader isLoading={cartData.loading || !draftsInitialLoaded} />
      <AddContactPopup
        isOpen={cartData.isContactOpen}
        handleClose={handleCloseContact}
        handleAddContact={(data) => {
          handleAddNewContact(data);
          handleCloseContact();
        }}
        contacts={contacts}
      />
      <CustomerCards
        open={cartData.isCardOpen}
        onClose={handleCloseCards}
        distributor={cartData.selectedDistributor}
        customerId={customerId}
        billingAddress={billingAddress}
        handleConfirm={handleConfirmCard}
        removeDraftCard={removeDraftCard}
        draftCardId={formField.cardId}
        setCardToCart={handleSetCardToCart}
        resetCardCart={resetCardCart}
      />

      <Box
        id={CART_FORM}
        key={CART_FORM}
        component="form"
        onSubmit={handleSubmitCart}
      >
        <ViewAllProductsDialog
          open={viewAllProductsDialogState.open}
          onClose={handleCloseViewAllProductsDialog}
        />
        <Box p={3.25}>
          <ContactsBlock />
          <TitleBlock itemsCount={casesCount} />

          <Grid container columnSpacing={4}>
            <Grid xs={8.3} item>
              <OrdersListBlock />
            </Grid>

            {cartData.selectedDistributor && (
              <Grid xs={3.7} item>
                <CartSummaryBlock />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};
