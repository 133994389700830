import { bool } from "prop-types";
import { Backdrop, CircularProgress } from "@mui/material";

export const Loader = ({ isLoading }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.tooltip + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

Loader.propTypes = {
  isLoading: bool,
};

Loader.defaultTypes = {
  isLoading: false,
};
