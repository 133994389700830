import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Box } from "@mui/material";
import { MainLogoIcon } from "components/MainLogos";
import { currentUserSelector } from "redux/selectors/auth";
import { photoUrl } from "helpers/helpers";
import { ConfirmContext } from "pages/MasterPage/MasterPage";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const Logo = ({ ...props }) => {
  const { currentUser } = useSelector(selector);
  const { orderDirect } = currentUser || {};

  const { appWithoutLogInState } = useContext(ConfirmContext);

  const showDistributorLogo = useMemo(() => {
    const distributorCount = orderDirect?.length;
    const brandLogo =
      appWithoutLogInState?.distributor?.brandLogo?.fileName ||
      orderDirect?.[0]?.distributor?.brandLogo?.fileName;

    if (
      (appWithoutLogInState?.distributor?.allowStorePreview ||
        distributorCount === 1) &&
      brandLogo
    )
      return photoUrl(brandLogo);
    return false;
  }, [
    appWithoutLogInState?.distributor?.allowStorePreview,
    appWithoutLogInState?.distributor?.brandLogo?.fileName,
    orderDirect,
  ]);

  return (
    <Box
      sx={{
        width: "172px",
        maxWidth: "172px",
        height: "40px",
        maxHeight: "40px",
        display: "flex",
        alignItems: "center",
      }}
      {...props}
    >
      {showDistributorLogo ? (
        <Box
          sx={{
            width: "172px",
            maxWidth: "172px",
            height: "40px",
            maxHeight: "40px",
            objectFit: "contain",
          }}
          src={showDistributorLogo}
          component="img"
          {...props}
        />
      ) : (
        <MainLogoIcon variant="primary" width={125} height={68} />
      )}
    </Box>
  );
};
