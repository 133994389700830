import { array, bool, func, object } from "prop-types";
import { Box, CircularProgress } from "@mui/material";
import { ProductItem } from "../ProductItem/ProductItem";

export const ProductsList = ({
  loading,
  list,
  handleDeleteProductDraft,
  parentProduct,
}) => {
  return (
    <Box
      sx={{
        opacity: loading ? 0.5 : 1,
        pointerEvents: loading && "none",
        maxHeight: "calc(100vh - 230px)",
        overflow: "scroll",
        position: "relative",
      }}
    >
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {list?.length ? (
        list?.map((product) => (
          <ProductItem
            key={product?.id}
            {...{ product, handleDeleteProductDraft }}
            parentProduct={parentProduct}
          />
        ))
      ) : (
        <Box width="100%" height="128px" />
      )}
    </Box>
  );
};

ProductsList.propTypes = {
  loading: bool,
  list: array,
  handleDeleteProductDraft: func,
  parentProduct: object,
};
ProductsList.defaultProps = { loading: false, list: [] };
