import { func, string } from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { CrossBigIcon } from "components/Icons";

export const HeaderBlock = ({ onClose, id }) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography fontSize={30} color="#707070">
          Return
        </Typography>
        <IconButton onClick={onClose}>
          <CrossBigIcon />
        </IconButton>
      </Box>
      <Box>
        <Typography fontSize={20} fontWeight={300} color="#707070">
          Order ID: {id}
        </Typography>
      </Box>
    </>
  );
};

HeaderBlock.propTypes = { onClose: func, id: string };
HeaderBlock.defaultProps = { onClose: () => {}, id: "" };
