import { bool, func, object } from "prop-types";

import { Box, Menu } from "@mui/material";

import { Header, ItemsList, Switcher } from "./components";
import { cl } from "./NotificationMenu.styles";

export const NotificationsMenu = ({ anchorEl, open, handleClose }) => {
  return (
    <Menu
      sx={cl.menu}
      id="notifications-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Box sx={cl.wrapper}>
        <Header />
        <Switcher />
        <ItemsList />
      </Box>
    </Menu>
  );
};

NotificationsMenu.propTypes = {
  anchorEl: object,
  open: bool,
  handleClose: func,
};
NotificationsMenu.defaultProps = {
  open: false,
  handleClose: () => {},
};
