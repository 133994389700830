import { useCallback, useState } from "react";
import { getCountriesService } from "services/countryOfOrigin";
import { error } from "helpers/notifications";
// import { useDebounce } from "helpers/hooks";

const init = {
  loading: false,
  list: [],
};

export const useCountryOfOriginActions = ({
  params = {},
  // open = true,
} = {}) => {
  const [state, setState] = useState(init);
  const [search, setSearch] = useState("");
  // const searchInputDebounced = useDebounce(search, 500);

  const handleFetch = useCallback(
    async (distributor_id) => {
      setState((prev) => ({ ...prev, loading: true }));
      try {
        const res = await getCountriesService({
          ...params,
          distributor_id,
          search,
        });

        setState((prev) => ({
          ...prev,
          list: Array.isArray(res) ? res : [],
        }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setState((prev) => ({ ...prev, loading: false }));
      }
    },
    [params, search]
  );

  // useEffect(() => {
  //   if (open) {
  //     console.log("first fetch");
  //     handleFetch();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [open, searchInputDebounced]);

  return { ...state, search, setSearch, handleFetch };
};
