import * as React from "react";
export const TagIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13.029}
    height={13.032}
    viewBox="0 0 13.029 13.032"
    {...props}
  >
    <path
      data-name="Icon ionic-ios-pricetag"
      d="M12.131 0h-3.85a.456.456 0 0 0-.32.13l-7.7 7.7a.9.9 0 0 0 0 1.275l3.664 3.664a.9.9 0 0 0 1.275 0l7.698-7.694a.456.456 0 0 0 .13-.32V.902a.9.9 0 0 0-.9-.9Zm-1.5 3.5a1 1 0 1 1 .887-.887 1 1 0 0 1-.887.887Z"
      fill="#26874b"
    />
  </svg>
);
export default TagIcon;
