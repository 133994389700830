import React from "react";

export const ReorderingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43.548"
      height="57.134"
      viewBox="0 0 43.548 57.134"
    >
      <path
        id="Path_2996"
        data-name="Path 2996"
        d="M9,22.546h8.66M9,11H21Z"
        transform="translate(1.228 14.691)"
        fill="none"
        stroke="#5f6267"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <g
        id="Path_4227"
        data-name="Path 4227"
        transform="translate(0 5.668)"
        fill="none"
      >
        <path
          d="M6.6,0H36.947a6.6,6.6,0,0,1,6.6,6.6V44.865a6.6,6.6,0,0,1-6.6,6.6H6.6a6.6,6.6,0,0,1-6.6-6.6V6.6A6.6,6.6,0,0,1,6.6,0Z"
          stroke="none"
        />
        <path
          d="M 6.600502014160156 1.499996185302734 C 3.788074493408203 1.499996185302734 1.499992370605469 3.788066864013672 1.499992370605469 6.600498199462891 L 1.499992370605469 44.86512756347656 C 1.499992370605469 47.67755508422852 3.788074493408203 49.96563720703125 6.600502014160156 49.96563720703125 L 36.94734191894531 49.96563720703125 C 39.75977325439453 49.96563720703125 42.04784393310547 47.67755508422852 42.04784393310547 44.86512756347656 L 42.04784393310547 6.600498199462891 C 42.04784393310547 3.788066864013672 39.75977325439453 1.499996185302734 36.94734191894531 1.499996185302734 L 6.600502014160156 1.499996185302734 M 6.600502014160156 -3.814697265625e-06 L 36.94734191894531 -3.814697265625e-06 C 40.59270477294922 -3.814697265625e-06 43.54784393310547 2.955146789550781 43.54784393310547 6.600498199462891 L 43.54784393310547 44.86512756347656 C 43.54784393310547 48.5104866027832 40.59270477294922 51.46563720703125 36.94734191894531 51.46563720703125 L 6.600502014160156 51.46563720703125 C 2.955142974853516 51.46563720703125 -7.62939453125e-06 48.5104866027832 -7.62939453125e-06 44.86512756347656 L -7.62939453125e-06 6.600498199462891 C -7.62939453125e-06 2.955146789550781 2.955142974853516 -3.814697265625e-06 6.600502014160156 -3.814697265625e-06 Z"
          stroke="none"
          fill="#5f6267"
        />
      </g>
      <path
        id="Path_2995"
        data-name="Path 2995"
        d="M20.546,3H14.773a5.773,5.773,0,0,0,0,11.546h5.773A5.773,5.773,0,0,0,20.546,3Z"
        transform="translate(4.114 -2.25)"
        fill="#fff"
        stroke="#5f6267"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
