import { Box, Tab, Tabs, Typography } from "@mui/material";
import { cl } from "../NotificationMenu.styles";

export const Header = () => {
  return (
    <Box sx={cl.header}>
      <Box sx={cl.header.textWrapper}>
        <Typography sx={cl.header.text}>Order Direct</Typography>
      </Box>
      <Box sx={cl.header.tabsWrapper}>
        <Tabs sx={cl.header.tabs}>
          <Tab
            sx={{
              ...cl.header.tab,
              color: " rgb(71, 160, 109)",
              borderBottom: "2px solid rgb(71, 160, 109);",
            }}
            label={<Box sx={cl.header.tabLabel}>Invited</Box>}
          />
          <Tab
            sx={cl.header.tab}
            label={<Box sx={cl.header.tabLabel}>Received</Box>}
          />
        </Tabs>
      </Box>
    </Box>
  );
};
