import React, { useContext, useMemo } from "react";
import { Box, Divider, Typography } from "@mui/material";
import useStyles from "./styles";
import { EmailIcon, PhoneIcon } from "components/Icons";
import { useSelector } from "react-redux";
import { singleOrderDirectSelector } from "redux/selectors/auth";
import { setPhoneNumberMask } from "helpers/helpers";
import { ConfirmContext } from "pages/MasterPage/MasterPage";
import { object } from "prop-types";

const ContactUsBar = ({ supplierState }) => {
  const classes = useStyles();
  const singleOrderDirect = useSelector(singleOrderDirectSelector);
  const { distributor } = useMemo(
    () => singleOrderDirect || {},
    [singleOrderDirect]
  );

  const { appWithoutLogInState } = useContext(ConfirmContext);
  const distr = appWithoutLogInState?.distributor;

  const { phone, baseUser } = useMemo(
    () => distr || distributor || {},
    [distr, distributor]
  );

  return (
    <Box className={classes.contactWrap}>
      <Typography>Need Help?</Typography>
      <Divider orientation="vertical" className={classes.divider} />
      <Box className={classes.section}>
        <EmailIcon width={15.83} height={10.69} />
        <Typography>
          {supplierState?.baseUser?.email || baseUser?.email}
        </Typography>
      </Box>
      {supplierState?.phone || phone ? (
        <Box className={classes.section}>
          <PhoneIcon
            className={classes.phoneIcon}
            width={10.69}
            height={10.69}
          />
          <Typography>
            {setPhoneNumberMask(supplierState?.phone || phone)}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

ContactUsBar.propTypes = {
  supplierState: object,
};

export default ContactUsBar;
