export const cl = {
  contactButton: {
    width: "100%",
    height: "49px",
    mt: "6px",
    borderRadius: "12px",
  },

  mainText: {
    color: "rgba(0, 0, 0, 0.80)",
    fontSize: "18px",
    fontFamily: "Inter",
    fontWeight: "400",
    lineHeight: "27px",
    wordWrap: "break-word",
    textAlign: "center",
  },

  logo: {
    maxWidth: "214px",
    maxHeight: "56px",
    width: "214px",
    height: "56px",
    borderRadius: "4px",
    objectFit: "cover",
  },

  titleName: {
    color: "black",
    fontSize: "24px",
    fontWeight: "500",
    mt: "6px",
  },

  dangerIconWrap: {
    svg: {
      width: "84px",
      height: "84px",
    },
  },

  titleWrap: {
    textAlign: "center",
    alignItems: "center",
  },
};
