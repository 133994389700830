import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  drawerWrapper: {
    background: "white",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "inline-flex",
    overflow: "hidden",
    width: "83vw",
  },
  headerWrapper: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    display: "inline-flex",
    gap: "20px",
    padding: "20px 16px 15px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
  },
  itemWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    alignItems: "center",
    padding: "15px 16px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
  },
  selectedFilterWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "15px 16px",
    gap: "12px",
  },
  itemText: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#2F3040",
    textTransform: "capitalize",
    lineHeight: "21.60px",
    wordWrap: "break-word",
  },
  title: {
    color: "black",
    fontSize: 18,
    fontWeight: 500,
  },
  actionBtn: {
    fontSize: "14px",
    fontWeight: 400,
    border: "none !important",
    padding: 0,
    minWidth: "50px",

    "&:hover": {
      border: "none",
    },
  },
}));

export default useStyles;
