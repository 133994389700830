import { bool, number, string } from "prop-types";

import { Box, Grid, Paper, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { DefaultEmptyLogo } from "../Icons";

import { separateNumWithComma } from "../../helpers/helpers";
import { PaymentStatuses } from "../OrderStatuses/OrderStatuses";

export const ProductCard = ({
  bestSeller,
  name,
  manufacturer,
  wholesalePrice,
  retailPrice,
  img,
  variations,
}) => {
  const {
    palette: { borderOutline, grey, clearBlack },
  } = useTheme();

  return (
    <Paper
      sx={{
        border: `1px solid ${borderOutline?.main}`,
        pb: 1,
      }}
      elevation={0}
      component={Grid}
      container
    >
      {/* Picture */}
      <Grid justifyContent="center" my={1} xs={12} item container>
        <Paper
          sx={{
            position: "relative",
            width: "220px",
            height: "220px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F7F7F7",
          }}
          elevation={0}
        >
          {img ? (
            <Box
              sx={{ objectFit: "cover", width: "220px", height: "220px" }}
              src={img}
              component="img"
            />
          ) : (
            <DefaultEmptyLogo />
          )}
          <Typography
            sx={{
              position: "absolute",
              bottom: 8,
              right: 12,
              fontSize: 12,
              fontWeight: 300,
              color: grey.main,
            }}
          >
            {variations}
          </Typography>
          {bestSeller && (
            <Box
              sx={{
                position: "absolute",
                top: 16,
                right: 12,
                fontSize: 12,
                fontWeight: 300,
                color: grey.main,
              }}
            >
              <PaymentStatuses str="Best Seller" />
            </Box>
          )}
        </Paper>
      </Grid>
      {/* Name */}
      <Grid xs={12} item>
        <Typography
          px={1}
          sx={{ fontSize: 18, fontWeight: 500, color: clearBlack.main }}
          noWrap
        >
          {name}
        </Typography>
      </Grid>
      {/* Manufacturer Name */}
      <Grid xs={12} item>
        <Typography
          px={1}
          sx={{ fontSize: 14, fontWeight: 300, color: clearBlack.main }}
          noWrap
        >
          by {manufacturer}
        </Typography>
      </Grid>
      {/* Wholesale Price */}
      <Grid sx={{ justifyContent: "space-between" }} xs={12} item container>
        <Typography
          px={1}
          sx={{ fontSize: 14, fontWeight: 400, color: clearBlack.main }}
          noWrap
        >
          Wholesale
        </Typography>
        <Typography
          px={1}
          sx={{ fontSize: 14, fontWeight: 400, color: clearBlack.main }}
          noWrap
        >
          ${separateNumWithComma(wholesalePrice)}
        </Typography>
      </Grid>
      {/* retailPrice Price */}
      <Grid sx={{ justifyContent: "space-between" }} xs={12} item container>
        <Typography
          px={1}
          sx={{ fontSize: 14, fontWeight: 300, color: clearBlack.main }}
          noWrap
        >
          Retail
        </Typography>
        <Typography
          px={1}
          sx={{ fontSize: 14, fontWeight: 300, color: clearBlack.main }}
          noWrap
        >
          ${separateNumWithComma(retailPrice)}
        </Typography>
      </Grid>
      {/* divider */}
      {/* <Grid
        sx={{ justifyContent: "center", mt: 0.8, px: 1 }}
        xs={12}
        item
        container
      >
        <Divider sx={{ width: "100%" }} />
      </Grid> */}
      {/* Minimum */}
      {/* <Grid
        sx={{ justifyContent: "space-between", my: 0.8 }}
        xs={12}
        item
        container
      >
        <Typography
          px={1}
          sx={{ fontSize: 14, fontWeight: 400, color: clearBlack.main }}
          noWrap
        >
          Minimum
        </Typography>
        <Typography
          px={1}
          sx={{ fontSize: 14, fontWeight: 300, color: clearBlack.main }}
          noWrap
        >
          ${separateNumWithComma(minimumPrice)}
        </Typography>
      </Grid> */}
    </Paper>
  );
};

ProductCard.propTypes = {
  bestSeller: bool,
  name: string,
  manufacturer: string,
  wholesalePrice: number,
  retailPrice: number,
  img: string,
  variations: string,
};
ProductCard.defaultProps = {
  bestSeller: false,
  name: "",
  manufacturer: "",
  wholesalePrice: 0,
  retailPrice: 0,
  img: "",
  variations: "",
};
