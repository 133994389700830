import { Stack } from "@mui/material";

import { Title } from "./Title";
import { HeaderItem } from "./HeaderItem";
import { OrderItem } from "./OrderItem";
import { useContext, useMemo } from "react";
import { OrderContext } from "pages/ViewOrderPage/ViewOrderPage.hooks";

export const ItemsListBlock = () => {
  const { orderData } = useContext(OrderContext);

  const { order } = useMemo(() => orderData, [orderData]);
  return (
    <>
      <Title
        name={order?.distributor?.name}
        orderNumber={order?.customId?.customId}
      />
      <HeaderItem />
      <Stack
        sx={{
          border: "1px solid #D5D9D9",
          borderWidth: "0 1px 1px 1px",
          borderRadius: "0 0 4px 4px",
          maxHeight: "298px",
          overflow: "overlay",
        }}
      >
        {order?.products?.map((product) => (
          <OrderItem key={product?.id} item={product} />
        ))}
      </Stack>
    </>
  );
};
