import { error } from "helpers/notifications";
import { CartContext } from "pages/CartPage/CartPage";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getManufacturersService } from "services/manufacturers";
import { getProductsService } from "services/products";
import { useDebounce } from "simply-depo-ui-components";

const initProductsState = {
  list: [],
  loading: false,
  count: 0,
};

export const useViewAllProducts = ({ open = true }) => {
  const { cartData } = useContext(CartContext);
  const [params, setParams] = useState({ limit: 15, page: 1, search: "" });

  const [productsState, setProductsState] = useState(initProductsState);

  const [manufacturersState, setManufacturersState] = useState({
    list: [],
    count: 0,
    page: 1,
  });

  const [switches, setSwitches] = useState([
    {
      value: "manufacturer_id",
      label: "Manufacturer",
      type: "select",
      checked: false,
      selectLabel: "Select Manufacturer",
    },
    {
      value: "category_id",
      label: "Category",
      type: "categories",
      checked: false,
      selectLabel: "Search product categories",
    },
    // {
    //   value: "tag_ids",
    //   label: "Tags",
    //   type: "select",
    //   checked: false,
    //   selectLabel: "Select Tag",
    // },
  ]);

  const [filterFields, setFilterFields] = useState({
    manufacturer_id: "",
    category_id: "",
    tag_ids: "",
  });

  const searchDebounced = useDebounce(params.search, 500);

  const handleFetchProducts = useCallback(
    async (query) => {
      const {
        // tag_id,
        manufacturer_id,
        category_id,
        search,
        didCancel,
        page,
      } = query;

      try {
        setParams((prev) => ({ ...prev, ...query }));
        setProductsState((prev) => ({ ...prev, loading: true }));
        const { rows, count } = await getProductsService({
          // tag_id,
          manufacturer_ids: manufacturer_id?.value
            ? JSON.stringify([manufacturer_id?.value])
            : undefined,
          distributor_ids: cartData?.selectedDistributor?.id
            ? JSON.stringify([cartData?.selectedDistributor?.id])
            : undefined,
          category_id: category_id?.value,
          search,
          page,
          limit: 15,
        });

        if (didCancel) return;

        setProductsState((prev) => ({
          ...prev,
          loading: false,
          list: page === 1 ? [...rows] : [...prev.list, ...rows],
          count,
        }));

        setParams((prev) => ({ ...prev }));
      } catch (err) {
        setProductsState((prev) => ({ ...prev, loading: false }));

        error(err?.response?.data?.message);
      }
    },
    [cartData?.selectedDistributor?.id]
  );

  useEffect(() => {
    let didCancel = false;

    if (open)
      handleFetchProducts({
        ...filterFields,
        search: searchDebounced,
        didCancel,
        page: 1,
        limit: 15,
      });

    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, filterFields, searchDebounced]);

  const handleFetchManufacturers = useCallback(async () => {
    try {
      const distributor_id = cartData?.selectedDistributor?.id;
      if (!distributor_id) return;
      const { rows, count } = await getManufacturersService({
        page: manufacturersState.page,
        limit: 15,
        distributor_id,
        status: '["ACTIVE"]',
      });
      setManufacturersState((prev) => ({
        ...prev,
        list:
          manufacturersState.page === 1 ? [...rows] : [...prev.list, ...rows],
        count,
      }));
    } catch (err) {
      error(err?.response?.data?.message);
    }
  }, [cartData?.selectedDistributor?.id, manufacturersState.page]);

  useEffect(() => {
    handleFetchManufacturers();
  }, [handleFetchManufacturers]);

  useEffect(() => {
    if (!open) {
      setProductsState(initProductsState);
    }
  }, [open]);

  const handleSearch = useCallback((e) => {
    const search = e.target.value;
    setProductsState((prev) => ({ ...prev, loading: true }));
    setParams((prev) => ({ ...prev, search }));
  }, []);

  const handleApplyFilter = (newSwitches, newFields) => {
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  const handleDeleteFilter = (key) => {
    const newState = switches;
    const index = switches.findIndex((s) => s.value === key);
    const insert = {
      ...switches[index],
      checked: false,
    };
    newState.splice(index, 1, insert);
    setSwitches([...newState]);
    setFilterFields((prev) => {
      return { ...prev, [key]: "" };
    });
  };

  const selectMatchData = useMemo(
    () => [
      {
        id: "manufacturer_id",
        childrenList: manufacturersState.list,
        handleFetch: () =>
          setManufacturersState((prev) => ({ ...prev, page: prev.page + 1 })),
        dataCount: manufacturersState.count,
        dataLength: manufacturersState.list.length,
        label: "Select Manufacturer",
      },
    ],
    [manufacturersState.list, manufacturersState.count]
  );

  return {
    productsState,
    handleFetchProducts,
    params,
    handleSearch,
    handleApplyFilter,
    switches,
    handleDeleteFilter,
    filterFields,
    selectMatchData,
    searchDebounced,
  };
};
