import { useContext } from "react";
import PropTypes, { func, string, object } from "prop-types";
import { ConfirmContext } from "pages/MasterPage/MasterPage";
import { UniversalConfirmDialog, UniversalConfirmDrawer } from "./components";

const UniversalConfirm = () => {
  const { confirmState } = useContext(ConfirmContext);

  return (
    <>
      {confirmState.type === "drawer" ? (
        <UniversalConfirmDrawer />
      ) : (
        <UniversalConfirmDialog />
      )}
    </>
  );
};

UniversalConfirm.propTypes = {
  text: PropTypes.oneOfType([string, object]),
  onCancel: func,
  onAccept: func,
};

UniversalConfirm.defaultProps = {
  // isOpen: false,
  text: "Are you sure?",
};

export default UniversalConfirm;
