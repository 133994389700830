import { error } from "helpers/notifications";
import { useCallback, useEffect, useState } from "react";
import { getProductByIdService } from "services/products";

const initProduct = {
  loading: true,
  product: null,
};

export const useProduct = ({ productId, distributorInfo }) => {
  const [productState, setProductState] = useState(initProduct);

  const handleGetProduct = useCallback(
    async (id) => {
      try {
        const product = await getProductByIdService(id);
        setProductState((prev) => ({
          ...prev,
          product: { ...product, ...distributorInfo },
        }));
      } catch (err) {
        if (err.response?.data?.message) error(err.response.data.message);
        // eslint-disable-next-line no-console
        console.error(err.response?.data?.message || err.message);
      } finally {
        setProductState((prev) => ({ ...prev, loading: false }));
      }
    },
    [distributorInfo]
  );

  useEffect(() => {
    if (productId) handleGetProduct(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return {
    ...productState,
  };
};
