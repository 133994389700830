import { useDispatch, useSelector } from "react-redux";
import { showCurrentDrawerAction } from "redux/actions/uiState";

import { CloseIcon, FilledEmailIcon, PhoneIcon } from "components/Icons";

import useStyles from "./styles";

import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { Fragment } from "react";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useLocation } from "react-router-dom";

export const HelpDrawer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const isHelpDrawerOpened = useSelector(({ uiState }) => uiState.helpDrawer);

  const { currentDistr } = useCurrentUser();

  const { baseUser, phone } = state?.distributor || currentDistr || {};

  const onClose = () =>
    dispatch(showCurrentDrawerAction({ type: "helpDrawer", show: false }));

  const HELP_DATA = [
    {
      title: baseUser?.email,
      href: `mailto:${baseUser?.email}`,
      icon: <FilledEmailIcon />,
      show: !!baseUser?.email,
    },
    {
      title: phone,
      href: `tel:${phone}`,
      sx: { ml: "3px" },
      icon: <PhoneIcon />,
      show: !!phone,
    },
  ];

  return (
    <Drawer
      anchor="bottom"
      open={isHelpDrawerOpened}
      onClose={onClose}
      PaperProps={{ className: classes.drawerWrapper }}
    >
      <Box className={classes.headerWrapper}>
        <Typography className={classes.title}>Need help?</Typography>
        <IconButton sx={{ p: 0 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={classes.fakeLine} />
      {HELP_DATA.map(({ title, href, icon, sx, show }, index) => (
        <Fragment key={index}>
          {show && (
            <Box className={classes.itemWrapper}>
              {icon}
              <Typography
                className={classes.itemText}
                noWrap
                component="a"
                href={href}
                sx={sx}
              >
                {title}
              </Typography>
            </Box>
          )}
        </Fragment>
      ))}
    </Drawer>
  );
};
