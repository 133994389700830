import React from "react";

import { Grid, Stack, Typography } from "@mui/material";

import { ReturnsTabHeader } from "./Table/ReturnsTabHeader";
import { ReturnsTabItem } from "./Table/ReturnsTabItem";

const itemsList = [
  {
    id: "1",
    customId: "10001",
    rma: null,
    supplier: "GNGR Labs",
    reason: "Arrived too late custome",
    entered: "Aug 13, 22",
    status: "APPROVED",
  },
  {
    id: "2",
    customId: "10001",
    rma: "132666629",
    supplier: "Rainforest Distribution",
    reason: "Arrived too late",
    entered: "Aug 13, 22",
    status: "PENDING",
  },
  {
    id: "3",
    customId: "10001",
    rma: "226662992",
    supplier: "Doras Naturals",
    reason: "Order Direct",
    entered: "Aug 13, 22",
    status: "APPROVED",
  },
  {
    id: "4",
    customId: "10001",
    rma: "222669999",
    supplier: "Test Vendor",
    reason: "Distributor",
    entered: "Aug 13, 22",
    status: "APPROVED",
  }
];

export const ReturnsTab = () => {
  return (
    <Grid pt={1} xs={12} item>
      <ReturnsTabHeader />
      <Stack
        sx={{
          border: "1px solid #D5D9D9", // check theme
          borderWidth: "0 1px 1px 1px",
          borderRadius: "0 0 4px 4px",
        }}
      >
        {itemsList.map(
          ({
             id,
             customId,
             rma,
             supplier,
             reason,
             entered,
             status,
           }) => (
            <ReturnsTabItem
              key={id}
              id={id}
              customId={customId}
              rma={rma}
              supplier={supplier}
              reason={reason}
              entered={entered}
              status={status}
            />
          )
        )}
      </Stack>
      <Typography
        p="12px 0 0 20px"
        fontSize="17px"
        fontWeight="300"
        color="#6E6E6E"
      >
        Showing {itemsList.length} returns
      </Typography>
    </Grid>
  );
};
