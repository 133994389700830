import { Box, Typography } from "@mui/material";
import { cl } from "../NotificationMenu.styles";
import { Link } from "react-router-dom";
import { CheckmarkIcon, CrossBigIcon, SandClockIcon } from "components/Icons";
import { PlaceholderIcon } from "components/Icons/PlaceholderIcon";

export const ItemsList = () => {
  return (
    <Box id="order-direct-scroll-popup" sx={cl.list.wrapper}>
      <Box
        display="flex"
        borderBottom="1px solid rgb(213, 217, 217)"
        height="48px"
      >
        <Box
          display="flex"
          alignItems="center"
          width="56px"
          justifyContent="center"
        >
          <PlaceholderIcon height="29px" width="29px" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="188px"
        >
          <Typography fontWeight={300} fontSize="12px" display="flex">
            Invited{" "}
            <Box fontWeight={400} fontSize="12px" ml="3px">
              Test order direct custom...
            </Box>
          </Typography>
          <Box display="flex">
            <Typography
              fontSize="10px"
              margin="0px"
              overflow="hidden"
              text-overflow="ellipsis"
              white-space=" nowrap"
              width="50%"
              color="rgb(106, 106, 106)"
              fontWeight="300"
              paddingEight="8px"
            >
              a day ago
            </Typography>{" "}
            <Box components="span" sx={cl.order.middleSide.date}>
              |
            </Box>
            <Box display="flex" alignItems="center">
              <SandClockIcon />
              <Typography sx={{ ml: "3px", fontSize: 10, color: "#959595" }}>
                Pending
              </Typography>
            </Box>
          </Box>
        </Box>
        <Link
          style={{
            fontSize: 10,
            fontWeight: 300,
            display: "flex",
            alignItems: "center",
            color: "#000",
            textDecorationColor: "#000",
            p: 0,
          }}
        >
          Resend
        </Link>
      </Box>
      <Box
        display="flex"
        height="48px"
        borderBottom="1px solid rgb(213, 217, 217)"
      >
        <Box
          display="flex"
          alignItems="center"
          width="56px"
          justifyContent="center"
        >
          <PlaceholderIcon height="29px" width="29px" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="188px"
        >
          <Typography fontWeight={300} fontSize="12px" display="flex">
            Invited{" "}
            <Box fontWeight={400} fontSize="12px" ml="3px">
              Gus World Famous Frie...
            </Box>
          </Typography>
          <Box display="flex">
            <Typography
              fontSize="10px"
              margin="0px"
              overflow="hidden"
              text-overflow="ellipsis"
              white-space=" nowrap"
              width="50%"
              color="rgb(106, 106, 106)"
              fontWeight="300"
              paddingEight="8px"
            >
              a day ago
            </Typography>{" "}
            <Box components="span" sx={cl.order.middleSide.date}>
              |
            </Box>
            <Box display="flex" alignItems="center">
              <SandClockIcon />
              <Typography sx={{ ml: "3px", fontSize: 10, color: "#959595" }}>
                Pending
              </Typography>
            </Box>
          </Box>
        </Box>
        <Link
          style={{
            fontSize: 10,
            fontWeight: 300,
            display: "flex",
            alignItems: "center",
            color: "#000",
            textDecorationColor: "#000",
            p: 0,
          }}
        >
          Resend
        </Link>
      </Box>
      <Box
        display="flex"
        height="48px"
        borderBottom="1px solid rgb(213, 217, 217)"
      >
        <Box
          display="flex"
          alignItems="center"
          width="56px"
          justifyContent="center"
        >
          <PlaceholderIcon height="29px" width="29px" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="188px"
        >
          <Typography fontWeight={300} fontSize="12px" display="flex">
            Invited{" "}
            <Box fontWeight={400} fontSize="12px" ml="3px">
              Shop Delight
            </Box>
          </Typography>
          <Box display="flex">
            <Typography
              fontSize="10px"
              margin="0px"
              overflow="hidden"
              text-overflow="ellipsis"
              white-space=" nowrap"
              width="50%"
              color="rgb(106, 106, 106)"
              fontWeight="300"
              paddingEight="8px"
            >
              a day ago
            </Typography>{" "}
            <Box components="span" sx={cl.order.middleSide.date}>
              |
            </Box>
            <Box display="flex" alignItems="center">
              <SandClockIcon />
              <Typography sx={{ ml: "3px", fontSize: 10, color: "#959595" }}>
                Pending
              </Typography>
            </Box>
          </Box>
        </Box>
        <Link
          style={{
            fontSize: 10,
            fontWeight: 300,
            display: "flex",
            alignItems: "center",
            color: "#000",
            textDecorationColor: "#000",
            p: 0,
          }}
        >
          Resend
        </Link>
      </Box>
      <Box
        display="flex"
        height="48px"
        borderBottom="1px solid rgb(213, 217, 217)"
      >
        <Box
          display="flex"
          alignItems="center"
          width="56px"
          justifyContent="center"
        >
          <PlaceholderIcon height="29px" width="29px" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="188px"
        >
          <Typography fontWeight={300} fontSize="12px" display="flex">
            Invited{" "}
            <Box fontWeight={400} fontSize="12px" ml="3px">
              Baker test
            </Box>
          </Typography>
          <Box display="flex">
            <Typography
              fontSize="10px"
              margin="0px"
              overflow="hidden"
              text-overflow="ellipsis"
              white-space=" nowrap"
              width="50%"
              color="rgb(106, 106, 106)"
              fontWeight="300"
              paddingEight="8px"
            >
              8 days ago
            </Typography>{" "}
            <Box components="span" sx={cl.order.middleSide.date}>
              |
            </Box>
            <Box display="flex" alignItems="center">
              <CheckmarkIcon width="11.18" height="8" stroke={"#53B986"} />
              <CheckmarkIcon
                width="11.18"
                height="8"
                stroke={"#53B986"}
                style={{ marginLeft: "-7px" }}
              />
              <Typography
                sx={{
                  ml: "3px",
                  fontSize: 10,
                  color: "#53B986",
                }}
              >
                Approved
              </Typography>
            </Box>
          </Box>
        </Box>
        <Link
          style={{
            fontSize: 10,
            fontWeight: 300,
            display: "flex",
            alignItems: "center",
            color: "#000",
            textDecorationColor: "#000",
            p: 0,
          }}
        >
          Resend
        </Link>
      </Box>
      <Box
        display="flex"
        height="48px"
        borderBottom="1px solid rgb(213, 217, 217)"
      >
        <Box
          display="flex"
          alignItems="center"
          width="56px"
          justifyContent="center"
        >
          <PlaceholderIcon height="29px" width="29px" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="188px"
        >
          <Typography fontWeight={300} fontSize="12px" display="flex">
            Invited{" "}
            <Box fontWeight={400} fontSize="12px" ml="3px">
              Wow Cafe Theater corp.
            </Box>
          </Typography>
          <Box display="flex">
            <Typography
              fontSize="10px"
              margin="0px"
              overflow="hidden"
              text-overflow="ellipsis"
              white-space=" nowrap"
              width="50%"
              color="rgb(106, 106, 106)"
              fontWeight="300"
              paddingEight="8px"
            >
              6 months ago
            </Typography>{" "}
            <Box components="span" sx={cl.order.middleSide.date}>
              |
            </Box>
            <Box display="flex" alignItems="center">
              <CheckmarkIcon width="11.18" height="8" stroke={"#53B986"} />
              <CheckmarkIcon
                width="11.18"
                height="8"
                stroke={"#53B986"}
                style={{ marginLeft: "-7px" }}
              />
              <Typography
                sx={{
                  ml: "3px",
                  fontSize: 10,
                  color: "#53B986",
                }}
              >
                Approved
              </Typography>
            </Box>
          </Box>
        </Box>
        <Link
          style={{
            fontSize: 10,
            fontWeight: 300,
            display: "flex",
            alignItems: "center",
            color: "#000",
            textDecorationColor: "#000",
            p: 0,
          }}
        >
          Resend
        </Link>
      </Box>
      <Box
        display="flex"
        height="48px"
        borderBottom="1px solid rgb(213, 217, 217)"
      >
        <Box
          display="flex"
          alignItems="center"
          width="56px"
          justifyContent="center"
        >
          <PlaceholderIcon height="29px" width="29px" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="188px"
        >
          <Typography fontWeight={300} fontSize="12px" display="flex">
            Invited{" "}
            <Box fontWeight={400} fontSize="12px" ml="3px">
              Another Broken Egg Cafe
            </Box>
          </Typography>
          <Box display="flex">
            <Typography
              fontSize="10px"
              margin="0px"
              overflow="hidden"
              text-overflow="ellipsis"
              white-space=" nowrap"
              width="50%"
              color="rgb(106, 106, 106)"
              fontWeight="300"
              paddingEight="8px"
            >
              7 months ago
            </Typography>{" "}
            <Box components="span" sx={cl.order.middleSide.date}>
              |
            </Box>
            <Box display="flex" alignItems="center">
              <SandClockIcon />
              <Typography sx={{ ml: "3px", fontSize: 10, color: "#959595" }}>
                Pending
              </Typography>
            </Box>
          </Box>
        </Box>
        <Link
          style={{
            fontSize: 10,
            fontWeight: 300,
            display: "flex",
            alignItems: "center",
            color: "#000",
            textDecorationColor: "#000",
            p: 0,
          }}
        >
          Resend
        </Link>
      </Box>
      <Box
        display="flex"
        height="48px"
        borderBottom="1px solid rgb(213, 217, 217)"
      >
        <Box
          display="flex"
          alignItems="center"
          width="56px"
          justifyContent="center"
        >
          <PlaceholderIcon height="29px" width="29px" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="188px"
        >
          <Typography fontWeight={300} fontSize="12px" display="flex">
            Invited{" "}
            <Box fontWeight={400} fontSize="12px" ml="3px">
              Petterson Apps
            </Box>
          </Typography>
          <Box display="flex">
            <Typography
              fontSize="10px"
              margin="0px"
              overflow="hidden"
              text-overflow="ellipsis"
              white-space=" nowrap"
              width="50%"
              color="rgb(106, 106, 106)"
              fontWeight="300"
              paddingEight="8px"
            >
              7 months ago
            </Typography>{" "}
            <Box components="span" sx={cl.order.middleSide.date}>
              |
            </Box>
            <Box display="flex" alignItems="center">
              <CrossBigIcon color="#FF877C" size="8" />
              <Typography sx={{ ml: "3px", fontSize: 10, color: "#FF877C" }}>
                Rejected
              </Typography>
            </Box>
          </Box>
        </Box>
        <Link
          style={{
            fontSize: 10,
            fontWeight: 300,
            display: "flex",
            alignItems: "center",
            color: "#000",
            textDecorationColor: "#000",
            p: 0,
          }}
        >
          Resend
        </Link>
      </Box>
    </Box>
  );
};
