// eslint-disable-next-line react/prop-types
export const CVVIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width || "28.5"}
      height={height || "19.257"}
      viewBox="0 0 28.5 19.257"
    >
      <image
        id="Image_71"
        data-name="Image 71"
        width="28.5"
        height="19.257"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAZCAYAAAC2JufVAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAIQSURBVEhL7ZZLSxtRFMf/xpjMzeThJJmp3XQV2hoasduKfgL9BuoHEEHwUWgpuk13rrIVXLtUUFwJwQftStSFComPidiQaRcqTtoknZkevLWT2voYO0J/cJl7/ncWf845d8401AzgMjz0dBWuNFW3fPlcDuWvZVQqFVLuHp/Ph1AoBEV5RArHZkrTStjfP0C1WiXFORgT0NqapIhjM7W5uYHDQ5UiZwkEGDo7uyji2Eytra3i7Zt3FDnLs+dPkclkKOL8v31/S93yrSyvUnQ3sJMTpFaWwU7PUGsAvsgy1l91IB6Pobevj97i2Ezl8znjBn6m6Pb49/Ygz8ygMDCAKmOWxra3EZubw/HrMaRSbZb2M781pSgKIpEIqTejWCxCnJhAYXgYiUSC1B8cLCxA+vgBj9PvSeFcaUoUg8hms3RyPXp6upHb2QFLp3E0OAhVLdAJEA6HEQyKeDI5ifj0NKmcPza6pmk3WhbGRKg1NlpbrVS6WLp+bmm4nI8LrsyUbDTkbVBVFcHxcagjI0gm+ZfbnBa7S0uQ5+fRYmTrVxxv9MDWFqTFRahDQ6QAXiOTLVNT+DQ2ihftL0nlOG7KRDAGfHR2Fp5y2SrZt+ZmHPf3wysGrnf77oOmJm9dUw9jzAiCAI/nfrz6/X7aXcZWPhOzhLquO/pPZf7kSZKEaDRGCqeuqX/Nw+gpN+BCU8B3RN4ZdaWPS1wAAAAASUVORK5CYII="
      />
    </svg>
  );
};
