import { useWatch } from "react-hook-form";
import { object } from "prop-types";

import { Box } from "@mui/material";
import { ViewSettingsBlock } from "./ViewSettingsBlock";
import { useMemo } from "react";

export const ViewSettings = ({ control }) => {
  const formField = useWatch({ control });

  const settingsList = useMemo(() => {
    const list = Object.values(formField?.items);

    return list?.filter(
      (el) => !(el.id === "suppliers" && el?.items?.length < 2)
    );
  }, [formField?.items]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="22px"
      width="292px"
      minWidth="292px"
    >
      {settingsList?.map((item) => (
        <ViewSettingsBlock key={item?.name} item={item} control={control} />
      ))}
    </Box>
  );
};

ViewSettings.propTypes = {
  control: object,
};
