import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: "12px",
    color: "#1C1C19",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  productPhoto: {
    width: "38px",
    height: "38px",
    backgroundColor: "white",
    border: "1px solid #D5D9D9",
    borderRadius: "4px",
  },
}));

export default useStyles;
