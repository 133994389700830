import { func, oneOf } from "prop-types";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { TABS } from "../../../../PaymentsPage.constants";
import { PlusIcon } from "components/Icons";

export const TabsBlock = ({ currentTab, setCurrentTab, onClickAddCard }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        my: 1.5,
      }}
    >
      <Tabs
        value={currentTab}
        onChange={(e, newTab) => setCurrentTab(newTab)}
        sx={{
          "& .Mui-selected": {
            backgroundColor: "transparent!important",
          },
        }}
      >
        {TABS.map((tab) => (
          <Tab
            sx={{
              py: 0,
              height: "44px",
              minHeight: "44px",
              fontSize: 21,
              fontWeight: 400,
              color: "#9C9C94",
              textTransform: "none",
            }}
            key={tab}
            label={tab}
            iconPosition="start"
            value={tab}
          />
        ))}
      </Tabs>

      {currentTab === TABS[0] && (
        <Button
          sx={{
            fontSize: 17,
            color: "#363531",
            height: "28px",
            "& .MuiButton-startIcon": { m: "0 8px 0 -4px" },
            "&:hover": {
              borderColor: "#000",
              bgcolor: "#D5D9D930",
            },
          }}
          startIcon={<PlusIcon />}
          onClick={onClickAddCard}
        >
          Add Card
        </Button>
      )}
    </Box>
  );
};

TabsBlock.propTypes = {
  currentTab: oneOf(TABS),
  setCurrentTab: func,
  onClickAddCard: func,
};
TabsBlock.defaultProps = {
  currentTab: "",
  setCurrentTab: () => {},
  onClickAddCard: () => {},
};
