import { object, bool, array, string, oneOfType } from "prop-types";
import { Box, Fade, Popper, Typography } from "@mui/material";

export const StyledPopper = ({
  anchorEl,
  open,
  text,
  modifiers,
  contentSx = {},
  arrowSx = {},
  ...props
}) => {
  return (
    <Popper
      anchorEl={anchorEl}
      open={open}
      placement="top"
      id={open ? "transition-popper" : undefined}
      transition
      modifiers={modifiers}
      sx={{
        pointerEvents: "none",
      }}
      {...props}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              textAlign: "center",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "17px",
              color: "#1C1C19",
              border: "0.4px solid #d5d9d9",
              borderRadius: "8px",
              boxShadow: "0px 3px 6px rgba(0, 3, 6, 0.16)",
              padding: "12.5px 13.5px",
              ...contentSx,
            }}
          >
            <Typography fontSize="10px" color="#363531">
              {text}
            </Typography>
            <Box
              sx={{
                width: "16.5px",
                height: "12.5px",
                bottom: "-12px",
                backgroundColor: "#D5D9D9",
                clipPath: "polygon(0 0, 50% 55%, 100% 0)",
                position: "absolute",
                ...arrowSx,
              }}
            />
            <Box
              sx={{
                width: "16px",
                height: "12px",
                bottom: "-11px",
                zIndex: 1,
                backgroundColor: "#ffffff",
                clipPath: "polygon(0 0, 50% 55%, 100% 0)",
                position: "absolute",
                ...arrowSx,
              }}
            />
          </Box>
        </Fade>
      )}
    </Popper>
  );
};

StyledPopper.propTypes = {
  anchorEl: object,
  text: oneOfType([string, object]),
  open: bool,
  modifiers: array,
  contentSx: object,
  arrowSx: object,
};

StyledPopper.defaultProps = {
  anchorEl: {},
  text: "",
  open: false,
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, 11],
      },
    },
  ],
};

export default StyledPopper;
