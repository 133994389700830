import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "helpers/hooks";
import { error } from "helpers/notifications";
import { getDistributorsService } from "services/distributors";
import { DISTRIBUTOR_SORT_PARAMS } from "./VendorsPage.constants";

const vendorsStateInitial = {
  loading: true,
  list: [],
  count: null,
  existData: false,
};

const vendorsParamsInitial = {
  order_direct: "",
  order_directs: ["pending", "approved"],
  min_order: "",
  industries: [],
  sort_by_name: "",
  sort_by_recent: "desc",
};

export const useVendorsActions = () => {
  const [vendorsState, setVendorsState] = useState(vendorsStateInitial);
  const [vendorsParams, setVendorsParams] = useState(vendorsParamsInitial);

  const [search, setSearch] = useState("");
  const searchDebounced = useDebounce(search, 500);

  const [sortBy, setSortBy] = useState(DISTRIBUTOR_SORT_PARAMS[0]);

  const switchOrderDirectParams = (param) => {
    setVendorsParams((prev) => ({ ...prev, order_direct: param?.value }));
  };

  const switchShowMinimumParams = (param) => {
    setVendorsParams((prev) => ({ ...prev, min_order: param?.value }));
  };

  const switchIndustryParams = (param) => {
    const { value } = param || {};
    const currentIndustriesParam = [...vendorsParams.industries];

    const findIndex = currentIndustriesParam.findIndex((el) => el === value);

    if (findIndex > -1) {
      const duplicate = [...vendorsParams.industries];
      duplicate.splice(findIndex, 1);
      return setVendorsParams((prev) => ({
        ...prev,
        industries: [...duplicate],
      }));
    }

    setVendorsParams((prev) => ({
      ...prev,
      industries: [...prev.industries, value],
    }));
  };

  const handleFetchVendors = useCallback(async () => {
    try {
      setVendorsState((prev) => ({ ...prev, loading: true }));

      const { order_directs, industries, ...params } = vendorsParams;

      const preparedParams = {
        ...params,
        search: searchDebounced,
        order_directs: order_directs?.length
          ? JSON.stringify(order_directs)
          : "",
        industries: industries?.length ? JSON.stringify(industries) : "",
      };

      if (sortBy === DISTRIBUTOR_SORT_PARAMS[0]) {
        (preparedParams.sort_by_recent = "desc"),
          (preparedParams.sort_by_name = "");
      }

      if (
        [DISTRIBUTOR_SORT_PARAMS[1], DISTRIBUTOR_SORT_PARAMS[2]].includes(
          sortBy
        )
      ) {
        (preparedParams.sort_by_recent = ""),
          (preparedParams.sort_by_name =
            sortBy === DISTRIBUTOR_SORT_PARAMS[1] ? "asc" : "desc");
      }

      const data = await getDistributorsService({
        params: preparedParams,
      });
      const { rows, count, existData } = data || {};

      setVendorsState((prev) => ({ ...prev, list: rows, count, existData }));
    } catch (err) {
      if (err?.response?.data?.message) error(err.response.data.message);
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message || err.message);
    } finally {
      setVendorsState((prev) => ({ ...prev, loading: false }));
    }
  }, [searchDebounced, sortBy, vendorsParams]);

  useEffect(() => {
    handleFetchVendors();
  }, [handleFetchVendors, searchDebounced, sortBy]);

  return {
    vendorsState,
    vendorsParams,
    search,
    setSearch,
    sortBy,
    setSortBy,
    switchOrderDirectParams,
    switchShowMinimumParams,
    switchIndustryParams,
    setVendorsParams,
  };
};
