export const cl = {
  menu: { "&.MuiModal-root ul": { p: 0 } },
  wrapper: {
    width: "300px",
    height: "410px",
    maxHeight: "410px",
    display: "flex",
    flexDirection: "column",
  },
  btnResend: {
    fontSize: 10,
    fontWeight: 300,
    color: "#000",
    textDecorationColor: "#000",
    p: 0,
  },
  header: {
    width: "100%",
    height: "42px",
    minHeight: "42px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #D5D9D9",

    textWrapper: { display: "flex", alignItems: "center", pl: 1.8 },
    text: { fontSize: 16, fontWeight: 500, color: "#1C1C19" },
    tabsWrapper: {
      display: "flex",
      alignItems: "center",
    },
    tabs: {
      mr: 0.6,
      minHeight: "24px",
      height: "24px",
      "& .MuiButtonBase-root.MuiTab-root": {
        minHeight: "24px",
      },
      "& .MuiTabs-indicator": {
        borderRadius: "6px 6px 0 0",
      },
      "& .Mui-selected": {
        backgroundColor: "transparent!important",
      },
    },
    tab: {
      fontSize: 12,
      color: "#5F6267",
      mx: 1.2,
      py: 0,
      px: 0,
      minWidth: 40,
    },
    tabLabel: { textTransform: "none", pointerEvents: "auto" },
  },
  switcher: {
    tabsWrapper: {
      height: "32px",
      minHeight: "32px",
      display: "flex",
      alignItems: "center",
      pl: 1,
    },
    tabs: {
      mr: 0.6,
      minHeight: "24px",
      height: "24px",
      "& .MuiButtonBase-root.MuiTab-root": {
        minHeight: "24px",
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "transparent!important",
      },
      "& .Mui-selected": {
        backgroundColor: "transparent!important",
      },
      "& .MuiTabs-flexContainer > button:not(:nth-last-of-type(-n+1)) div": {
        borderRight: "1px solid #D5D9D9",
      },
    },
    tab: {
      fontSize: 12,
      color: "#CCC",
      py: 0,
      px: 0,
      minWidth: 10,
    },
    tabLabel: {
      textTransform: "none",
      px: 1,
    },
  },
  list: {
    wrapper: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      overflow: "overlay",
      position: "relative",
    },
    emptyWrapper: { textAlign: "center" },
    empty: {
      fontSize: 12,
      fontWeight: 300,
      color: "#D5D9D9",
    },
  },
  order: {
    wrapper: {
      display: "flex",
      height: "48px",
      minHeight: "48px",
      borderBottom: "1px solid #D5D9D9",
    },
    leftSide: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "56px",
      height: "100%",
      pl: "4px",
    },
    middleSide: {
      flexDirection: "column",
      justifyContent: "center",
      flexGrow: 1,
      display: "flex",
      width: "188px",
      invitedText: {
        fontSize: 12,
        fontWeight: 300,
        color: "#000",
      },
      date: {
        fontSize: 10,
        fontWeight: 300,
        color: "#6A6A6A",
        pr: 1,
      },
      btn: {
        fontSize: 10,
        fontWeight: 300,
        color: "#FF8000",
        textDecorationColor: "#FF8000",
        p: 0,
      },
    },
    rightSide: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "56px",
      height: "100%",
      pr: "16px",

      btn: {
        fontSize: 10,
        fontWeight: 300,
        color: "#FF8000",
        textDecorationColor: "#FF8000",
        p: 0,
      },
      btnResend: {
        fontSize: 10,
        fontWeight: 300,
        color: "#000",
        textDecorationColor: "#000",
        p: 0,
      },
    },
    imgWrapper: {
      maxWidth: "30px",
      minWidth: "30px",
      height: "30px",
      backgroundColor: "white",
      border: "1px solid #D5D9D9",
      borderRadius: "4px",
    },
    img: {
      objectFit: "cover",
      width: "100%",
      height: "100%",
      borderRadius: "4px",
    },
    svg: {
      height: "30px",
      width: "30px",
    },
  },
  footer: {
    wrapper: { borderTop: "1px solid #D5D9D9" },
    btn: {
      height: "36px",
      color: "#000",
    },
  },
};
