import { useMemo, useState } from "react";
import { MenuContent } from "./MenuContent";

import useStyles from "./styles";

import { BurgerMenuIcon } from "components/Icons/BurgerMenuIcon";
import { Box, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { separateNumWithComma } from "helpers/helpers";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useLocation, useNavigate } from "react-router-dom";
import { MainLogoIcon } from "components/MainLogos";
import { useCartCounter } from "pages/ProductsPage/useCartCounter";

export const MobileHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isOpen, setOpen] = useState(false);

  const showHelpDrawer = () =>
    dispatch(showCurrentDrawerAction({ type: "helpDrawer", show: true }));

  const handleIsOpen = () => setOpen(!isOpen);

  const {
    currentDistr,
    selectedSupplier,
    hasSelectedSupplier,
    hasMultipleSuppliers,
  } = useCurrentUser();

  const { minimumOrderValueAmount, brandLogo } = currentDistr || {};

  const { showRejectScreen } = useCartCounter();

  const shouldHideRightInfo = useMemo(() => {
    return !currentDistr || pathname !== "/" || showRejectScreen;
  }, [currentDistr, pathname, showRejectScreen]);

  const showMainLogo = hasMultipleSuppliers && pathname !== "/";

  return (
    <Stack className={classes.wrapper}>
      <nav className={classes.nav}>
        <Stack className={classes.iconContainer}>
          <Box className={classes.navIcon} onClick={handleIsOpen}>
            <BurgerMenuIcon />
          </Box>

          {!showRejectScreen && (
            <>
              {currentDistr && !showMainLogo ? (
                <Box
                  onClick={() => navigate("/")}
                  sx={{
                    width: "100px",
                    maxWidth: "100px",
                    height: "24px",
                    maxHeight: "24px",
                    objectFit: "contain",
                  }}
                  src={brandLogo?.fileName}
                  component="img"
                />
              ) : (
                <Box className={classes.logoWrapper}>
                  <MainLogoIcon variant="primary" width={133} height={72} />
                </Box>
              )}
            </>
          )}
        </Stack>

        {shouldHideRightInfo ? null : (
          <Stack className={classes.supText}>
            <Typography className={classes.minOrderText}>
              {!!minimumOrderValueAmount &&
                `Minimum $${separateNumWithComma(minimumOrderValueAmount)}`}
            </Typography>

            <Typography className={classes.helpText} onClick={showHelpDrawer}>
              Need help?
            </Typography>
          </Stack>
        )}
      </nav>
      <MenuContent
        {...{
          selectedSupplier,
          currentDistr,
          setOpen,
          open: isOpen,
          hasSelectedSupplier,
          hasMultipleSuppliers,
        }}
      />
    </Stack>
  );
};
