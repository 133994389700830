import { Stack, Typography } from "@mui/material";
import { object } from "prop-types";

export const BillingAddressBlock = ({ address }) => {
  return (
    <Stack
      direction="column"
      gap="4px"
      sx={{
        "& .MuiTypography-root": {
          color: "black",
          fontSize: "14px",
          lineHeight: "18.9px",
        },
      }}
    >
      <Typography fontWeight={600}>Billing address</Typography>
      <Typography>
        {address.topLine}
        {address.topLine.length > 1 && ", "}
        {address.bottomLine}
      </Typography>
    </Stack>
  );
};

BillingAddressBlock.propTypes = {
  address: object,
};
