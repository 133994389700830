import * as React from "react";

export const DefaultEmptyLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={236}
    height={236}
    viewBox="0 0 236 236"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <circle
          data-name="Ellipse 860"
          cx={57.403}
          cy={57.403}
          r={57.403}
          transform="translate(0 .001)"
          fill="#409a65"
        />
      </clipPath>
    </defs>
    <g data-name="Group 7264" transform="translate(-1659 -1406)">
      <rect
        data-name="Rectangle 11508"
        width={236}
        height={236}
        rx={6}
        transform="translate(1659 1406)"
        fill="#f3f3f3"
      />
      <g
        data-name="Mask Group 29"
        transform="translate(1719.597 1466.596)"
        clipPath="url(#a)"
      >
        <g data-name="Group 5007" transform="translate(-6.401 .001)">
          <circle
            data-name="Ellipse 859"
            cx={57.403}
            cy={57.403}
            r={57.403}
            transform="translate(6.401)"
            fill="#dbdbdb"
          />
          <path
            data-name="Path 3163"
            d="M.001 89.664a4.888 4.888 0 0 0 4.887 4.891h66.731a20.57 20.57 0 0 0 0-41.14H55.972a11.69 11.69 0 1 1 0-23.381h64.452a4.888 4.888 0 0 0 0-9.776H55.972a21.466 21.466 0 1 0 0 42.932h15.647a10.794 10.794 0 0 1 0 21.589H4.888a4.888 4.888 0 0 0-4.887 4.885Z"
            fill="#fff"
            stroke="#fff"
            fillRule="evenodd"
          />
        </g>
      </g>
    </g>
  </svg>
);
