import { Controller } from "react-hook-form";
import { array, bool, func, object } from "prop-types";
import { cl as classes } from "../../../../../../../components/ContactDrawer/styles";

import { Button, InputLabel, MenuItem, Stack, Typography } from "@mui/material";
import { LargeCheckbox } from "components";

export const ContentBlock = ({
  FIELD_DATA,
  control,
  checkboxValue,
  isNewLocation,
  handleChangeDefault,
  handleRemoveLocation,
  defaultLocationValue,
}) => {
  return (
    <Stack direction="column" gap="20px" mt="20px">
      <Stack direction="column" gap="12px">
        {FIELD_DATA.slice(0, 3).map(
          (
            { name, Input, label, placeholder, onPlaceSelected, ...props },
            index
          ) => (
            <Controller
              key={index}
              render={({ field, fieldState: { error } }) => (
                <Stack>
                  <InputLabel
                    shrink
                    sx={{
                      ...classes.label,
                      color: error
                        ? "rgba(228, 30, 58, 0.60)"
                        : "rgba(0, 0, 0, 0.60)",
                    }}
                  >
                    {label}
                  </InputLabel>
                  <Input
                    fullWidth
                    size="small"
                    {...(!!onPlaceSelected && {
                      onPlaceSelected,
                    })}
                    error={error ? error.message : ""}
                    InputProps={{
                      sx: {
                        ...classes.textInput,
                        "& > fieldset": {
                          borderColor: error
                            ? "rgba(228, 30, 58, 0.60) !important"
                            : "rgb(213,217,217)!important",
                          borderWidth: error
                            ? "1px !important"
                            : "0px !important",
                        },
                      },
                      placeholder,
                    }}
                    {...field}
                    {...props}
                  />
                </Stack>
              )}
              name={name}
              control={control}
            />
          )
        )}
        <Stack direction="row" gap="26px" width="100%">
          {FIELD_DATA.slice(3, 6).map(
            (
              {
                name,
                Input,
                label,
                placeholder,
                isSelect,
                selectProps,
                menuList,
              },
              index
            ) => (
              <Controller
                key={index}
                render={({ field, fieldState: { error } }) => (
                  <Stack>
                    <InputLabel
                      shrink
                      sx={{
                        ...classes.label,
                        color: error
                          ? "rgba(228, 30, 58, 0.60)"
                          : "rgba(0, 0, 0, 0.60)",
                      }}
                    >
                      {label}
                    </InputLabel>
                    {isSelect ? (
                      <Input
                        {...field}
                        error={error?.message || ""}
                        {...selectProps}
                        renderValue={(s) => (s ? s : <span>State</span>)}
                        sx={{
                          ...selectProps?.sx,
                          ...(error && classes.errorSelect),
                        }}
                        {...field}
                        value={field.value || ""}
                      >
                        {Object.keys(menuList).map((key) => (
                          <MenuItem key={key} value={key}>
                            {menuList[key]}
                          </MenuItem>
                        ))}
                      </Input>
                    ) : (
                      <Input
                        fullWidth
                        size="small"
                        error={error ? error.message : ""}
                        InputProps={{
                          sx: {
                            ...classes.textInput,
                            "& > fieldset": {
                              borderColor: error
                                ? "rgba(228, 30, 58, 0.60) !important"
                                : "rgb(213,217,217)!important",
                              borderWidth: error
                                ? "1px !important"
                                : "0px !important",
                            },
                          },
                          placeholder,
                        }}
                        {...field}
                      />
                    )}
                  </Stack>
                )}
                name={name}
                control={control}
              />
            )
          )}
        </Stack>
      </Stack>
      <LargeCheckbox
        onChange={handleChangeDefault}
        disabled={defaultLocationValue}
        checked={checkboxValue}
        checkedColor={defaultLocationValue ? "#bdbdbd" : "#47a06d"}
        formSx={{
          ml: 0,
          "& .MuiFormControlLabel-root": {
            ml: 0,
          },
        }}
        checkboxSx={{
          padding: 0,
          margin: "0 8px 0 1px",
        }}
        label={
          <Typography color="black" fontSize="14px" fontWeight="400">
            Set as primary location
          </Typography>
        }
        size={15}
      />

      {!isNewLocation && !checkboxValue && (
        <Button
          disabled={checkboxValue}
          onClick={handleRemoveLocation}
          disableTouchRipple
          sx={{
            height: "18px",
            padding: 0,
            whiteSpace: "nowrap",
            width: "fit-content",
            minWidth: 0,
            color: "#E41E3A",
            fontSize: 14,
          }}
        >
          Delete Address
        </Button>
      )}
    </Stack>
  );
};

ContentBlock.propTypes = {
  FIELD_DATA: array,
  isNewLocation: bool,
  defaultLocationValue: bool,
  control: object,
  checkboxValue: bool,
  handleChangeDefault: func,
  handleRemoveLocation: func,
};
