export const getCoordinatesService = async (lat, lng) => {
  return fetch(
    // eslint-disable-next-line no-undef
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
  ).then((res) => res.json());
};

export const getTimeZoneService = async (lat, lng, timestamp) => {
  return fetch(
    // eslint-disable-next-line no-undef
    `https://maps.googleapis.com/maps/api/timezone/json?location=${lat}%2C${lng}&timestamp=${timestamp}&key=${process.env.REACT_APP_TIMEZONE_KEY}`
  ).then((res) => res.json());
};
