import { string } from "prop-types";
import { Box, Typography } from "@mui/material";

export const ManufacturerNameBlock = ({ manufacturerName }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={13} fontWeight={300} noWrap>
        {manufacturerName}
      </Typography>
    </Box>
  );
};

ManufacturerNameBlock.propTypes = {
  manufacturerName: string,
};
ManufacturerNameBlock.defaultProps = {
  manufacturerName: "-",
};
