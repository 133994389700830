export const cl = {
  contactWrapper: {
    width: "100%",
    p: "8px 10px",
    borderRadius: "8px",
    border: "1px rgba(0, 0, 0, 0.05) solid",
    alignItems: "center",
    gap: "8px",

    "& svg": {
      width: "20px",
      height: "20px",
      color: "rgba(15, 23, 42, 0.7)",
    },
  },

  contactName: {
    color: "rgba(0, 0, 0, 0.80)",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "15px",
  },
};
