import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  closeButton: { position: "absolute", right: "20px", top: "20px" },

  storeInfoWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "11px",
  },

  logoWrap: {
    width: "250px",
    height: "70px",
    objectFit: "contain",
    borderRadius: "8px",
  },

  storeName: {
    fontSize: "25px",
    fontWeight: "500",
  },

  storeAddress: {
    fontSize: "25px",
    fontWeight: "300",
  },

  labelIcon: {
    "& path": {
      strokeWidth: 1,
    },
  },

  labelsWrap: {
    display: "flex",
    // marginTop: "81px",
    marginTop: "clamp(20px, 3vh, 63px)",
    justifyContent: "center",
  },

  storeLabel: {
    textAlign: "center",
    padding: "0 45px",
    "&:not(:last-of-type)": {
      borderRight: "1px solid #B9B9B9",
    },
    "& .MuiTypography-root": {
      fontSize: "25px",
      marginBottom: "19px",
      color: "#5F6267",
    },
  },

  dialogWrap: { padding: "0" },

  contentWrap: {
    // padding: "63px 131px 59px 145px",
    paddingTop: "clamp(20px, 3vh, 63px)",
    paddingBottom: "clamp(20px, 3vh, 63px)",
    paddingLeft: "145px",
    paddingRight: "131px",
  },

  selectWrap: {
    display: "flex",
    justifyContent: "center",
    // marginTop: "75px",
    // marginTop: "16%",
    marginTop: "clamp(20px, 3vh, 63px)",
    flexDirection: "column",
    gap: "26px",
  },

  requestButton: {
    height: ({ isMobile }) => (isMobile ? "50px" : "45px"),
    borderRadius: ({ isMobile }) => (isMobile ? "12px" : null),

    "& .MuiTypography-root": {
      color: "#fff",
      fontSize: ({ isMobile }) => (isMobile ? "16px" : "18px"),
      fontWeight: ({ isMobile }) => (isMobile ? "600" : "500"),
    },
  },

  footer: {
    padding: "16px 0",
    textAlign: "center",
    background: "rgba(64, 154, 101, 0.15)",
    borderTop: "0.5px solid #d5d9d9",

    "& .MuiTypography-root": {
      fontSize: "20px",
      fontWeight: "500",
      color: "#47A06D",
    },
  },
}));

export default useStyles;
