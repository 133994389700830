import { Stack, Typography } from "@mui/material";
import { OrderContext } from "pages/ViewOrderPage/ViewOrderPage.hooks";
import { useContext } from "react";

export const NoteBlock = () => {
  const { orderData } = useContext(OrderContext);

  const { note } = orderData?.order || {};

  return (
    <>
      {note?.text && (
        <Stack
          sx={{
            p: "10px 13px",
            mt: "20px",
            width: "100%",
            height: "fit-content",
            borderRadius: "8px",
            border: "1px rgba(0, 0, 0, 0.05) solid",
          }}
        >
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.80)",
              fontSize: "14px",
              lineHeight: "21px",
              wordWrap: "break-word",
            }}
          >
            {note.text}
          </Typography>
        </Stack>
      )}
    </>
  );
};
