import { string } from "prop-types";
import { Box, Grid, Typography } from "@mui/material";

import { RegLabelIcon, RegBoardIcon } from "../Icons";
// import businessPricingImg from "./assets/business_pricing.png";
// import easyReorderingImg from "./assets/easy_reordering.png";

export const RegistrationCard = ({ name, text }) => {
  // const setTextImg = useMemo(() => {
  //   if (name === "Business Pricing") {
  //     return businessPricingImg;
  //   }
  //   if (name === "Easy Reordering") {
  //     return easyReorderingImg;
  //   }
  //   return "";
  // }, [name]);

  return (
    <Box sx={{ width: "210px", maxWidth: "210px" }}>
      <Grid gap="2vh" container>
        <Grid sx={{ display: "flex", justifyContent: "center" }} xs={12} item>
          <Box alt="Create Wholesale Account">
            <Typography
              fontSize="clamp(10px, 1.8vh, 18px)"
              color="#5F6267"
              fontWeight="500"
            >
              {name}
            </Typography>
          </Box>
        </Grid>
        <Grid sx={{ display: "flex", justifyContent: "center" }} xs={12} item>
          {name === "Business Pricing" && <RegLabelIcon />}
          {name === "Easy Reordering" && <RegBoardIcon />}
        </Grid>
        <Grid
          sx={{
            textAlign: "center",
            fontSize: "clamp(10px, 1.8vh, 14px)",
            color: "#7E7E7E",
            whiteSpace: "pre-wrap",
          }}
          xs={12}
          item
          component={Typography}
        >
          {text}
        </Grid>
      </Grid>
    </Box>
  );
};

RegistrationCard.propTypes = { name: string, text: string };
RegistrationCard.defaultProps = { name: "", text: "" };
