import { array, func, object } from "prop-types";

import { PaymentCard } from "components";

import { cl } from "../styles";

import AddIcon from "@mui/icons-material/Add";
import { Button, Stack } from "@mui/material";

export const ContentBlock = ({
  cardList,
  selectedCard,
  handleSelectCard,
  isCardExpired,
  onAddNewCard,
  handleDeleteCard,
  onEditOpen,
  handleConfirm,
}) => {
  return (
    <Stack
      direction="column"
      gap="20px"
      width="100%"
      mb={handleConfirm ? "20px" : 0}
    >
      {cardList.map((card, index) => (
        <PaymentCard
          {...{
            card,
            selectedCard,
            handleSelectCard,
            isCardExpired,
            handleDeleteCard,
            onEditOpen,
          }}
          key={index}
        />
      ))}
      <Button sx={cl.editBtn} onClick={onAddNewCard}>
        <AddIcon />
        Add credit card
      </Button>
    </Stack>
  );
};

ContentBlock.propTypes = {
  selectedCard: object,
  handleSelectCard: func,
  onAddNewCard: func,
  isCardExpired: func,
  handleDeleteCard: func,
  onEditOpen: func,
  handleConfirm: func,
  cardList: array,
};
