import { useNavigate } from "react-router-dom";
import { string } from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";

export const SimpleTab = ({ startText, endText, navigatePath }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "58px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ fontSize: 19 }} component={Typography}>
        <Box color="blackClear" component="span">
          {startText}
        </Box>
        <Box color="#A7A7A7" component="span">
          {endText ? ` / ${endText}` : ""}
        </Box>
      </Box>
      <Box>
        <Button onClick={() => navigate(navigatePath || -1)}>
          <ArrowBackIos sx={{ fontSize: 18, color: "#6A6A6A" }} />
          <Typography sx={{ fontSize: 20, ml: -0.5, color: "#6A6A6A" }}>
            Back
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

SimpleTab.propTypes = {
  startText: string,
  endText: string,
  navigatePath: string,
};
SimpleTab.defaultProps = { startText: "", endText: "", navigatePath: "/" };
