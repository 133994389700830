import { useContext } from "react";
import { array } from "prop-types";
import { AccordionDetails, Box, Button, Typography } from "@mui/material";
import { AccDetailFooter, AccDetailItem } from "./components";
import { CartContext } from "pages/CartPage/CartPage";
import { PlusIcon } from "components/Icons";

// eslint-disable-next-line react/prop-types
const DashedBtn = ({ onClick }) => {
  return (
    <Button
      sx={{
        backgroundImage:
          "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D5D9D9' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")",
        height: "56px",
        backgroundColor: "#FFF",
        "& .MuiTypography-root": { color: "#707070" },
        "&:hover": {
          borderColor: "#000",
          backgroundColor: "#F7F7F7",
        },
      }}
      fullWidth
      startIcon={<PlusIcon size={17.52} />}
      onClick={onClick}
    >
      <Typography fontSize={26} fontWeight={300}>
        Add items
      </Typography>
    </Button>
  );
};

export const AccDetailsBlock = ({ products }) => {
  const {
    getOutOfStock,
    productFields,
    removeProducts,
    setErrorQty,
    handleOpenViewAllProductsDialog,
  } = useContext(CartContext);

  return (
    <AccordionDetails sx={{ p: 0 }}>
      {products.length ? (
        <Box>
          <Box p={2.75}>
            <DashedBtn onClick={handleOpenViewAllProductsDialog} />
          </Box>
          <Box>
            {productFields.map((product, index) => (
              <AccDetailItem
                key={product.id}
                product={products[index]}
                index={index}
                handleDelete={(id) => {
                  removeProducts(index);
                  setErrorQty((prev) => ({
                    ...prev,
                    [id]: null,
                  }));
                }}
                outOfStock={getOutOfStock(product, index)}
              />
            ))}
            {/* <AccDetailItem />
          <AccDetailItem /> */}
          </Box>
          <AccDetailFooter />
        </Box>
      ) : (
        <Box p={2.75}>
          <DashedBtn onClick={handleOpenViewAllProductsDialog} />
        </Box>
      )}
    </AccordionDetails>
  );
};

AccDetailsBlock.propTypes = { products: array };
AccDetailsBlock.defaultProps = { products: [] };
