import React, { useContext, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { StyledButton, StyledSelect, StyledTextField } from "components";
import { SearchIcon, SettingsIcon } from "components/Icons";
import { ProductsContext } from "pages/ProductsPage/useProducts";
import { useDispatch, useSelector } from "react-redux";
import { singleOrderDirectSelector } from "redux/selectors/auth";
import useStyles from "./styles";
import ContactDialog from "pages/VendorsPage/components/DesktopVendors/components/ContactDialog/ContactDialog";
import { getDistributorByIdService } from "services/distributors";
import { error } from "helpers/notifications";
import { any, bool, number, object } from "prop-types";
import { useShowProductsWithoutLogIn } from "helpers/hooks";
import { SORT_BY } from "pages/ProductsPage/ProductsPage.constants";
import { useMediaDevice } from "hooks/useMediaDevice";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { DRAWERS } from "constants/drawer";

const SearchBlock = ({ distr, setSortingVal, filterCount }) => {
  const { isMobile } = useMediaDevice();
  const dispatch = useDispatch();

  const classes = useStyles({ isMobile });

  const { isShowProductsWithoutLogIn } = useShowProductsWithoutLogIn();

  const [profileState, setProfileState] = useState({
    open: false,
    distributor: null,
    loading: false,
  });
  const { searchLoading, searchVal, setSearchVal, productsCount } =
    useContext(ProductsContext);
  const singleOrderDirect = useSelector(singleOrderDirectSelector);

  const { distributor } = useMemo(
    () => singleOrderDirect || {},
    [singleOrderDirect]
  );

  const { name, minimumOrderValue, minimumOrderValueAmount, billingAddress } =
    useMemo(() => distributor || distr || {}, [distributor, distr]);

  const handleViewProfile = async () => {
    if (profileState.loading) return;
    try {
      setProfileState((prev) => ({ ...prev, loading: true }));
      const res = await getDistributorByIdService(distributor?.id || distr?.id);
      setProfileState((prev) => ({
        ...prev,
        loading: false,
        distributor: res,
        open: true,
      }));
    } catch (err) {
      setProfileState((prev) => ({ ...prev, loading: false }));

      error(err?.response?.data?.message);
    }
  };

  const openFilterDrawer = () =>
    dispatch(
      showCurrentDrawerAction({ type: DRAWERS.FILTER_DRAWER, show: true })
    );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        mt: { xs: "6px", sm: 0 },
      }}
    >
      <Box
        sx={{
          width:
            isMobile || !!distributor || !!distr
              ? "100%"
              : "calc(100% - 322px)",
        }}
        className={classes.searchBlockWrap}
      >
        <ContactDialog
          open={profileState.open}
          supplier={profileState.distributor}
          onClose={() => setProfileState((prev) => ({ ...prev, open: false }))}
          defaultTab={0}
        />

        {!isMobile && (!!distributor || !!distr) && (
          <>
            <Box className={classes.distributorInfo}>
              <Typography className={`${classes.infoText} main`} noWrap>
                {name}
              </Typography>
              <Typography
                className={classes.infoText}
                sx={{ fontWeight: 300 }}
                noWrap
              >
                {!minimumOrderValue || minimumOrderValueAmount === 0
                  ? "No minimum"
                  : `Minimum: $${minimumOrderValueAmount.toFixed(2)}`}
              </Typography>
            </Box>
            <Divider orientation="vertical" className={classes.divider} />
            <Box className={classes.distributorInfo}>
              {billingAddress && (
                <Typography className={`${classes.infoText} main`} noWrap>
                  {billingAddress?.city}, {billingAddress?.state}
                </Typography>
              )}
              {isShowProductsWithoutLogIn ? (
                <Typography className={`${classes.infoText} nowrap`}>
                  {productsCount} products
                </Typography>
              ) : (
                <StyledButton
                  label="Profile"
                  fontSize="19px"
                  className={classes.viewProfile}
                  onClick={handleViewProfile}
                  color="primaryLight"
                />
              )}
              {profileState.loading && (
                <CircularProgress size={20} className={classes.profileLoader} />
              )}
            </Box>
          </>
        )}

        <Box className={classes.textFieldWrap}>
          <StyledTextField
            inputSx={{
              height: isMobile ? "36px" : "40px",
              fontSize: "14px",
              fontWeight: 300,
              borderRadius: isMobile ? "8px" : "4px",
              backgroundColor: isMobile
                ? "rgba(213.42, 213.42, 213.42, 0.16)"
                : "#FFFFFF",
              "& > fieldset": {
                borderColor: "rgb(213,217,217)!important",
                borderWidth: isMobile ? "0px !important" : "1px!important",
              },
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon strokeWidth={1.5} />
              </InputAdornment>
            }
            placeholder="Search products"
            endIcon={
              searchLoading && !isMobile ? (
                <Box className={classes.loadingIcon}>
                  <CircularProgress size="20px" />
                </Box>
              ) : (
                <></>
              )
            }
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
          />

          {isMobile ? (
            <Box position="relative" width="36px" height="36px">
              <Button className={classes.filterBtn} onClick={openFilterDrawer}>
                <SettingsIcon />
              </Button>
              {!!filterCount && (
                <Stack className={classes.filterBadge}>{filterCount}</Stack>
              )}
            </Box>
          ) : (
            <StyledSelect
              label=""
              height="40px"
              color="#707070"
              formSx={{
                "& svg": { fill: "#000000" },
                backgroundColor: "#FFFFFF",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#D5D9D9!important",
                  borderWidth: "1px !important",
                },
              }}
              fontSize="14px"
              displayEmpty
              defaultValue={SORT_BY[0].value}
              onChange={(event) => {
                const selectedSort = event.target.value;
                const selectedItem = SORT_BY.find(
                  (item) => item.value === selectedSort
                );

                setSortingVal({
                  [selectedItem.sort]: selectedItem.sortValue,
                });
              }}
            >
              {SORT_BY.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <Box sx={{ display: "flex", mr: "41px" }}>
                    <Typography
                      sx={{
                        color: "#707070",
                        fontSize: "15px",
                      }}
                    >
                      Sort By:
                    </Typography>
                    <Typography
                      sx={{ fontSize: "15px", width: "100%", ml: "4px", mr: "8px" }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </StyledSelect>
          )}
        </Box>
      </Box>
    </Box>
  );
};

SearchBlock.propTypes = {
  isShowProductsWithoutLogIn: bool,
  distr: object,
  filterCount: number,
  setSortingVal: any,
};
SearchBlock.defaultProps = {
  isShowProductsWithoutLogIn: false,
};

export default SearchBlock;
