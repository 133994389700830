import React from "react";

export const CustomerIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.625"
      height="10.495"
      viewBox="0 0 12.625 10.495"
      {...props}
    >
      <path
        id="store-solid"
        d="M12.4,2.431,11.065.307A.656.656,0,0,0,10.509,0H2.228a.656.656,0,0,0-.555.307L.342,2.431a1.829,1.829,0,0,0,1.205,2.8,2.13,2.13,0,0,0,.281.018,2.024,2.024,0,0,0,1.513-.678,2.026,2.026,0,0,0,3.026,0,2.026,2.026,0,0,0,3.026,0,2.031,2.031,0,0,0,1.513.678,2.107,2.107,0,0,0,.281-.018A1.827,1.827,0,0,0,12.4,2.431ZM10.909,5.9a2.6,2.6,0,0,1-.6-.078V7.871H2.433V5.826a2.715,2.715,0,0,1-.6.078,2.766,2.766,0,0,1-.369-.025,2.578,2.578,0,0,1-.336-.074V9.839a.655.655,0,0,0,.656.656h9.183a.655.655,0,0,0,.656-.656V5.805a2.093,2.093,0,0,1-.336.074,2.851,2.851,0,0,1-.373.025Z"
        transform="translate(-0.056)"
        fill="#5f6267"
      />
    </svg>
  );
};
