import React from "react";

// eslint-disable-next-line react/prop-types
export const AmExIcon = ({ size = 96 }) => {
  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width={size}
      height={size}
    >
      <path d="M 5 7 C 2.242188 7 0 9.242188 0 12 L 0 38 C 0 40.757813 2.242188 43 5 43 L 45 43 C 47.757813 43 50 40.757813 50 38 L 50 12 C 50 9.242188 47.757813 7 45 7 Z M 8.5625 19.90625 L 11.34375 19.90625 L 15 28.09375 L 15 19.90625 L 18.28125 19.90625 L 20.84375 25.625 L 23.40625 19.90625 L 26.75 19.90625 L 26.75 28.40625 L 24.65625 28.40625 L 24.65625 22.1875 L 21.8125 28.40625 L 19.90625 28.40625 L 17.09375 22.09375 L 17.09375 28.40625 L 12.875 28.40625 L 12.0625 26.53125 L 7.875 26.53125 L 7.0625 28.40625 L 4.84375 28.40625 Z M 29.5 19.90625 L 37.75 19.90625 L 40.0625 22.5625 L 42.46875 19.90625 L 45.125 19.90625 L 41.375 24.0625 L 45.125 28.4375 L 42.46875 28.4375 L 40 25.59375 L 37.46875 28.40625 L 29.5 28.40625 Z M 9.90625 21.6875 L 8.65625 24.6875 L 11.21875 24.6875 Z M 31.5625 21.71875 L 31.5625 23.3125 L 36.1875 23.3125 L 36.1875 24.9375 L 31.5625 24.9375 L 31.5625 26.625 L 36.40625 26.625 L 38.6875 24.0625 L 36.65625 21.75 Z" />
    </svg>
  );
};
