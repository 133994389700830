import { bool, object, string } from "prop-types";

import { cl } from "./styles";

import { Fab } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const FloatingButton = ({ title, icon, url, disabled }) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (!url) return;
    return navigate(url);
  };

  return (
    <Fab
      variant="extended"
      sx={cl.btn}
      onClick={handleOnClick}
      disabled={disabled}
      disableRipple
      disableTouchRipple
    >
      {icon}
      {title}
    </Fab>
  );
};

FloatingButton.propTypes = {
  title: string,
  url: string,
  icon: object,
  disabled: bool,
};
