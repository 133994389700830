import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { object, bool, func, string } from "prop-types";
import useStyles from "./styles";

import { forwardRef } from "react";
import { LargeCheckbox, StyledProductIco, StyledTooltip } from "components";
import { photoUrl } from "helpers/helpers";
import { InformationIcon } from "components/Icons";

const ProductsSearchItem = forwardRef(
  (
    {
      product,
      isChecked,
      handleCheckProduct,
      name,
      manufacturer,
      disabled,
      outOfStock,
      onMouseEnter,
      onMouseLeave,
      hideAvailable,
    },
    ref
  ) => {
    const classes = useStyles();
    const isMissingInfo = product?.missingFields;
    return (
      <Grid
        container
        key={product.id}
        columns={12}
        height="55px"
        borderBottom="0.5px solid #D5D9D9"
        sx={{ opacity: disabled || outOfStock ? 0.5 : 1 }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
      >
        <Grid item xs={1} className={classes.grid}>
          <LargeCheckbox
            size={20}
            formSx={{ m: 0 }}
            disabled={disabled || outOfStock || !!isMissingInfo?.length}
            checked={isChecked}
            onChange={() =>
              handleCheckProduct({
                ...product,
                name,
                manufacturer,
                quantity: 1,
                itemDiscountAmount: 0,
                itemDiscountType: "PERCENTAGE",
                isNewAdded: true,
              })
            }
          />
        </Grid>
        <Grid item xs={1} className={classes.grid}>
          <Box
            sx={{
              width: "40px",
              height: "40px",
              backgroundColor: "white",
            }}
          >
            <StyledProductIco
              src={
                product?.photos?.length
                  ? photoUrl(product.photos[0].fileName)
                  : ""
              }
              styles={{
                objectFit: "contain",
                width: "100%",
                height: "100%",
                borderRadius: "4px",
                border: "1px solid #D5D9D9",
              }}
              placeholderStyles={{
                width: "40px",
                height: "40px",
                wrapperProps: { height: "40px" },
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={2.5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography className={classes.text} fontWeight="500">
            {name}
          </Typography>
          <Typography
            className={classes.text}
            fontWeight="400"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            by {manufacturer.name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3.5}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            className={classes.text}
            fontWeight="500"
            noWrap
            width="100%"
            textAlign="center"
          >
            {product.sku}
          </Typography>
          <Typography className={classes.text} fontWeight="400">
            {product.flavor || product.color || product.size} (Pack of{" "}
            {product.itemsPerCase})
          </Typography>
        </Grid>
        <Grid item xs={2.5} className={classes.grid}>
          {!!isMissingInfo?.length && (
            <StyledTooltip
              arrow
              title={
                <Box textAlign="left">
                  <Typography fontSize={12}>Missing info:</Typography>
                  {isMissingInfo?.map((info) => (
                    <Typography key={info} fontSize={12} fontWeight={300}>
                      {info}
                    </Typography>
                  ))}
                </Box>
              }
              placement="top"
            >
              <Box display="flex" alignItems="center">
                <InformationIcon size="15.33" stroke="#FF6969" />
              </Box>
            </StyledTooltip>
          )}

          {!isMissingInfo?.length && !hideAvailable && (
            <Typography className={classes.text} fontWeight="400">
              {outOfStock
                ? "Out of stock"
                : `${
                    product.inventory?.onHand - product.inventory?.allocated
                  } available`}
            </Typography>
          )}
        </Grid>
        <Grid item xs={1.5} className={classes.grid}>
          <Typography className={classes.text} fontWeight="400">
            ${product.wholesalePrice?.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    );
  }
);

ProductsSearchItem.propTypes = {
  product: object,
  handleCheckProduct: func,
  isChecked: bool,
  name: string,
  manufacturer: object,
  disabled: bool,
  outOfStock: bool,
  onMouseEnter: func,
  onMouseLeave: func,
  hideAvailable: bool,
};

ProductsSearchItem.defaultProps = {
  product: null,
  isChecked: false,
  name: "",
  manufacturer: null,
  disabled: false,
  hideAvailable: false,
};
ProductsSearchItem.displayName = "ProductsSearchItem";

export default ProductsSearchItem;
