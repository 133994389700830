import { useMediaDevice } from "hooks/useMediaDevice";
import { useState } from "react";
import { getManufacturersService } from "services/manufacturers";

const init = {
  loading: false,
  list: [],
  count: 0,
  page: 1,
};

export const useManufacturers = () => {
  const { isMobile } = useMediaDevice();
  const [manufacturersState, setManufacturersState] = useState(init);

  const getManufacturersList = (params) => {
    if (!params.distributor_id) return;
    setManufacturersState((prev) => ({ ...prev, loading: true }));
    getManufacturersService({
      ...params,
      page: params.page ?? 1,
      ...(!isMobile && { limit: 10 }),
      status: '["ACTIVE"]',
    })
      .then((res) => {
        setManufacturersState((prev) => ({
          ...prev,
          loading: false,
          list: params?.page === 1 ? res.rows : [...prev.list, ...res.rows],
          count: res?.count,
        }));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err?.message);
        setManufacturersState((prev) => ({ ...prev, loading: false }));
      });
  };

  const resetManufacturersState = () => {
    setManufacturersState(init);
  };

  return {
    manufacturersState,
    getManufacturersList,
    resetManufacturersState,
  };
};
