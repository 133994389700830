import { useEffect, useMemo, useState } from "react";
import { bool, number, object, oneOf, string } from "prop-types";
import { range } from "lodash";

import { Box, Grid, MenuItem, Paper, Typography } from "@mui/material";
import { ImagePlaceholder, StyledSelect, StyledTooltip } from "components";
import { Controller } from "react-hook-form";
import { photoUrl, separateNumWithComma } from "helpers/helpers";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

const styles = {
  normalText: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#000",
  },
};

export const ProductVariationItem = ({
  variations,
  itemPrice,
  retailPrice,
  itemsPerCase,
  wholesalePrice,
  minOrderQTY,
  onHand,
  allocated,
  sellingOutOfStock,
  control,
  index,
  productName,
  photoLink,
  alreadyAddedDraft,
  showMSRP,
  type,
}) => {
  const [quantityList, setQuantityList] = useState([]);

  const inventory = useMemo(() => onHand - allocated, [onHand, allocated]);

  const isNonInventory = type === PRODUCT_TYPE_INVENTORY.non_inventory;

  const list = range(
    minOrderQTY,
    inventory + 1 - alreadyAddedDraft > minOrderQTY
      ? inventory + 1 - alreadyAddedDraft
      : 1000
  );

  useEffect(() => {
    const from =
      typeof minOrderQTY === "number" && minOrderQTY > 0 ? minOrderQTY : 0;
    const to = from + 100;

    const newList =
      isNonInventory || sellingOutOfStock ? range(from, to) : list;

    setQuantityList(newList.length > 100 ? newList.slice(0, 100) : newList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onHand,
    allocated,
    sellingOutOfStock,
    minOrderQTY,
    inventory,
    alreadyAddedDraft,
    isNonInventory,
  ]);

  const dataLength = useMemo(() => {
    if (sellingOutOfStock || isNonInventory) {
      return quantityList?.length + minOrderQTY;
    }

    if (quantityList?.length < list?.length) {
      return quantityList?.length;
    }

    return null;
  }, [
    isNonInventory,
    list?.length,
    minOrderQTY,
    quantityList?.length,
    sellingOutOfStock,
  ]);

  const dataCount = useMemo(() => {
    if (sellingOutOfStock || isNonInventory) {
      return 1000;
    }

    if (quantityList?.length < list?.length) {
      return list?.length + minOrderQTY - 1;
    }

    return null;
  }, [
    isNonInventory,
    list?.length,
    minOrderQTY,
    quantityList?.length,
    sellingOutOfStock,
  ]);

  const handleFetch = () => {
    setQuantityList((prev) => {
      if (quantityList?.length < list?.length) {
        const from = prev[prev.length - 1] + 1;
        const to = prev[prev.length - 1] + 101;

        return [
          ...prev,
          ...range(from, to >= list?.length ? dataCount + 1 : to),
        ];
      }

      return [
        ...prev,
        ...range(prev[prev.length - 1] + 1, prev[prev.length - 1] + 101),
      ];
    });
  };

  return (
    <Paper
      sx={{
        height: "62px",
        alignItems: "center",
        borderRadius: "0 0 4px 4px",
        position: "relative",
      }}
      elevation={0}
      square
      component={Grid}
      container
    >
      {/* Variations */}
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
        xs={6}
        item
      >
        {photoLink ? (
          <Box
            sx={{
              border: "0.5px solid #D5D9D9",
              width: "47px",
              minWidth: "47px",
              height: "47px",
              minHeight: "47px",
              borderRadius: "4px",
            }}
          >
            <Box
              sx={{ objectFit: "contain" }}
              component="img"
              src={photoUrl(photoLink)}
              width="100%"
              height="100%"
              borderRadius="4px"
            />
          </Box>
        ) : (
          <ImagePlaceholder
            width="47px"
            height="47px"
            style={{
              objectFit: "cover",
              borderRadius: "4px",
              border: "0.5px solid #D5D9D9",
            }}
            wrapperProps={{ display: "flex" }}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "calc(100% - 46px)",
          }}
        >
          <Typography
            sx={{
              ...styles.normalText,
              fontWeight: 500,
            }}
            noWrap
          >
            {productName}
          </Typography>
          <Typography fontSize={16} fontWeight={300} color="#000" noWrap>
            {variations}
          </Typography>
        </Box>
      </Grid>

      {/* Items / case */}
      <Grid
        item
        xs={1.8}
        display="flex"
        justifyContent="center"
        flexDirection={{
          xs: "column",
        }}
        alignItems={{
          xs: "flex-start",
        }}
      >
        <Typography sx={{ ...styles.normalText, pl: "35px" }}>
          {itemsPerCase}
        </Typography>
      </Grid>

      {/* Case Qty */}
      <Grid item xs={1.5}>
        <StyledTooltip
          title="Out of Stock"
          placement="top"
          arrow
          disableHoverListener={
            isNonInventory
              ? true
              : !(sellingOutOfStock ? false : inventory - alreadyAddedDraft < 1)
          }
          disableFocusListener={
            isNonInventory
              ? true
              : !(sellingOutOfStock ? false : inventory - alreadyAddedDraft < 1)
          }
        >
          <Box
            sx={{
              maxWidth: {
                xl: "80px",
                xs: "62px",
              },
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "4px",
            }}
          >
            <Controller
              render={({ field }) => (
                <StyledSelect
                  labelProps={{ shrink: false }}
                  disabled={
                    isNonInventory
                      ? false
                      : sellingOutOfStock
                      ? false
                      : inventory - alreadyAddedDraft < 1
                  }
                  notched={false}
                  fontSize="16px"
                  color="#1C1C19"
                  value={inventory < 1 ? 0 : field.value}
                  height="36px"
                  formSx={{ ml: "4px", minWidth: "100%" }}
                  sx={{
                    fontWeight: 400,
                    "& .MuiSelect-select": {
                      pl: 1,
                      paddingTop: "3px !important",
                      paddingBottom: "3px !important",
                    },
                    "& svg": {
                      left: { xl: 45, xs: 35 },
                      fill: "#363531",
                    },
                    "& > fieldset": {
                      border: "none",
                    },
                  }}
                  dataLength={dataLength}
                  dataCount={dataCount}
                  handleFetch={() => {
                    if (
                      sellingOutOfStock ||
                      isNonInventory ||
                      quantityList?.length < list?.length
                    ) {
                      handleFetch();
                    }
                  }}
                  {...field}
                >
                  {minOrderQTY && (
                    <MenuItem disabled value={minOrderQTY}>
                      Minimum Quantity {minOrderQTY}
                    </MenuItem>
                  )}
                  <MenuItem value={0}>0</MenuItem>
                  {quantityList.map((num) => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
              name={`products.${index}.value`}
              control={control}
            />
          </Box>
        </StyledTooltip>
      </Grid>

      {/* Price */}
      <Grid
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={0}
        xs={1.4}
        item
      >
        <Typography sx={styles.normalText}>{itemPrice}</Typography>
        {!!showMSRP && (
          <Typography fontSize={14} fontWeight={300}>
            (MSRP {separateNumWithComma(retailPrice / itemsPerCase)})
          </Typography>
        )}
      </Grid>

      {/* Case Price */}
      <Grid
        item
        xs={1.3}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        pr="2%"
      >
        <Typography
          sx={{
            ...styles.normalText,
            fontSize: 16,
            fontWeight: 700,
            mr: "4px",
            color: "1C1C19",
          }}
        >
          {wholesalePrice}
        </Typography>
      </Grid>
    </Paper>
  );
};

ProductVariationItem.propTypes = {
  variations: string,
  itemPrice: string,
  retailPrice: number,
  itemsPerCase: number,
  wholesalePrice: string,
  minOrderQTY: number,
  onHand: number,
  allocated: number,
  sellingOutOfStock: bool,
  control: object,
  index: number,
  productName: string,
  photoLink: string,
  alreadyAddedDraft: number,
  showMSRP: bool,
  type: oneOf([
    PRODUCT_TYPE_INVENTORY.inventory,
    PRODUCT_TYPE_INVENTORY.non_inventory,
  ]),
};
ProductVariationItem.defaultProps = {
  variations: "",
  itemPrice: "",
  retailPrice: 0,
  itemsPerCase: 0,
  wholesalePrice: 0,
  minOrderQTY: 0,
  onHand: 0,
  allocated: 0,
  sellingOutOfStock: false,
  index: 0,
  productName: "",
  photoLink: "",
  alreadyAddedDraft: 0,
  showMSRP: false,
};
