import React from "react";

import { Grid, Paper } from "@mui/material";

import { ReturnsTabHeaderItem } from "./ReturnsTabHeaderItem";
import { RETURNS_HEADER_ITEMS } from "../../../OrdersPage.constants";

export const ReturnsTabHeader = () => {
  return (
    <Paper
      sx={{
        border: "1px solid #D5D9D9",
        borderRadius: "4px 4px 0 0",
        backgroundColor: "#F8F8F8",
        height: "44px",
        alignItems: "center",
      }}
      elevation={0}
      square
      component={Grid}
      container
    >
      {RETURNS_HEADER_ITEMS.map(({ id, name, size, style }) => (
        <ReturnsTabHeaderItem key={id} name={name} size={size} style={style} />
      ))}
    </Paper>
  );
};
