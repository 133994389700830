import { Accordion } from "@mui/material";
import { AccDetailsBlock, AccSummaryBlock } from "./components";
import { array, bool, func, object } from "prop-types";

export const CartOrderItem = ({
  distributor,
  products,
  expanded,
  onChange,
}) => {
  return (
    <Accordion
      sx={{
        border: "0.5px solid #D5D9D9",
        borderRadius: "4px",
        "&:before": {
          display: "none",
        },
      }}
      elevation={0}
      disableGutters
      expanded={expanded}
      onChange={onChange}
    >
      <AccSummaryBlock
        distributor={distributor}
        hasMinimum={
          distributor.minimumOrderValue &&
          distributor.minimumOrderValueAmount > 0
        }
        minimumOrderValue={distributor.minimumOrderValueAmount}
        expanded={expanded}
      />
      <AccDetailsBlock products={products} />
    </Accordion>
  );
};

CartOrderItem.propTypes = {
  distributor: object,
  products: array,
  expanded: bool,
  onChange: func,
};

CartOrderItem.defaultProps = {
  distributor: {},
  products: [],
  expanded: false,
  onChange: () => {},
};
