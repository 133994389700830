import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { Box, Pagination as MuiPagination } from "@mui/material";

import { paginationSelector } from "../../redux/selectors/settings";
import { setPaginationAction } from "../../redux/actions/settings";

import { cl } from "./Pagination.styles";

const selector = createSelector(paginationSelector, (pagination) => ({
  pagination,
}));

export const Pagination = () => {
  const { pagination } = useSelector(selector);
  const dispatch = useDispatch();

  const handleSetCurrentPage = (e, value) => {
    dispatch(
      setPaginationAction({
        currentPage: value,
      })
    );
  };
  // const handleSetResultPerPage = (e) => {
  //   const value = e.target.value;
  //   dispatch(
  //     setPaginationAction({
  //       resultsPerPage: value,
  //       currentPage: 1,
  //     })
  //   );
  // };

  return (
    <Box
      display="flex"
      alignItems="center"
      height="100%"
      sx={{ transform: "translateY(-40px)" }}
    >
      {/* <Typography mr={3} fontSize={20} fontWeight={300}>
        results per page
      </Typography>
      <Select
        sx={cl.select}
        value={pagination.resultsPerPage}
        fullWidth
        size="small"
        IconComponent={(props) => (
          <ExpandMore sx={{ fontSize: 20 }} {...props} />
        )}
        onChange={handleSetResultPerPage}
      >
        {PER_PAGE_COUNT_LIST.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select> */}

      {![null, 1].includes(pagination.allPages) && (
        <Box
          position="relative"
          border="1px solid #D4D4D4"
          borderRadius="4px"
          height={42}
        >
          <Box sx={cl.paginationWrapper} />
          <MuiPagination
            sx={cl.pagination}
            shape="rounded"
            page={pagination.currentPage}
            count={pagination.allPages}
            onChange={handleSetCurrentPage}
          />
        </Box>
      )}
    </Box>
  );
};
