import { useState } from "react";

const initState = {
  open: false,
};

export const useOrderReturn = () => {
  const [state, setState] = useState(initState);

  const handleOpenDialog = () => {
    setState((prev) => ({ ...prev, open: true }));
  };
  const handleCloseDialog = () => {
    setState((prev) => ({ ...prev, open: false }));
  };

  return { state, handleOpenDialog, handleCloseDialog };
};
