import { number, object, string } from "prop-types";

import { Stack, Typography } from "@mui/material";
import { func } from "prop-types";

export const PaymentDetailsBlock = ({ title, value, sx, valueSx, onClick }) => {
  return (
    <Stack gap="4px" sx={sx}>
      <Typography
        noWrap
        sx={{
          color: "black",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "18.9px",
        }}
      >
        {title}
      </Typography>
      <Typography
        noWrap
        onClick={onClick}
        sx={{
          color: "rgba(0, 0, 0, 0.80)",
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "21px",
          ...valueSx,
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

PaymentDetailsBlock.propTypes = {
  title: string,
  value: string || number,
  sx: object,
  valueSx: object,
  onClick: func,
};
