import { Controller } from "react-hook-form";

import { array, bool, func, object } from "prop-types";

import {
  CardIconComponent,
  LargeCheckbox,
  StyledSelect,
  StyledTextField,
} from "components";

import { cl } from "../../SelectCardDrawer/styles";
import { cl as classes } from "../../ContactDrawer/styles";
import { cl as productCardClasses } from "../../../pages/ProductsPage/components/MobileProductCard/styles";

import { setCardName } from "helpers/helpers";

import { Button, InputLabel, MenuItem, Stack, Typography } from "@mui/material";
import { StyledArrowIcon } from "components/Icons";
import { BillingAddressBlock } from "./BillingAddressBlock";

export const ContentBlock = ({
  FIELD_DATA,
  control,
  card,
  formField,
  showBillingInfo = false,
  billingInfo = {},
  onDelete,
}) => {
  return (
    <Stack direction="column" gap="20px" mt="20px">
      <Stack direction="row" alignItems="center" gap="8px">
        <CardIconComponent
          type={card?.brand === "visa" ? "mobile-visa" : card?.brand}
          size={32}
        />
        <Stack>
          <Typography sx={cl.cardNumber}>
            {setCardName(card?.brand)} ending in{" "}
            {card?.number?.slice(-4) || card?.last4}
          </Typography>
          <Typography sx={cl.cardName}>
            {card?.billingAddress?.name} | Expires{" "}
            {`${card?.expMonth}/${card?.expYear}`}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="column" gap="12px">
        {FIELD_DATA.slice(0, 1).map(({ name, label, placeholder }, index) => (
          <Controller
            key={index}
            render={({ field, fieldState: { error } }) => (
              <Stack>
                <InputLabel
                  shrink
                  sx={{
                    ...classes.label,
                    ...(error && classes.errorLabel),
                  }}
                >
                  {label}
                </InputLabel>
                <StyledTextField
                  fullWidth
                  size="small"
                  error={error ? error.message : ""}
                  InputProps={{
                    sx: {
                      ...classes.textInput,
                      ...(error && classes.errorTextInput),
                    },
                    placeholder,
                  }}
                  {...field}
                />
              </Stack>
            )}
            name={name}
            control={control}
          />
        ))}
        <Stack gap="20px" width="100%">
          <Stack direction="row" gap="20px" width="100%">
            {FIELD_DATA.slice(1, 3).map(
              ({ name, label, renderValue, items, disabled }, index) => (
                <Controller
                  key={index}
                  render={({ field, fieldState: { error } }) => (
                    <Stack width="fit-content">
                      <InputLabel
                        shrink
                        sx={{
                          ...classes.label,
                          ...(error && classes.errorLabel),
                        }}
                      >
                        {label}
                      </InputLabel>
                      <StyledSelect
                        labelProps={{ shrink: false }}
                        notched={false}
                        fullWidth
                        displayEmpty
                        fontSize="10px"
                        IconComponent={StyledArrowIcon}
                        color="#1C1C19"
                        MenuProps={{
                          PaperProps: {
                            sx: productCardClasses.paperStyles,
                          },
                        }}
                        formSx={{ width: "80px" }}
                        height="36px"
                        renderValue={renderValue}
                        paperSx={classes.paperStyles}
                        sx={{
                          ...classes.select,
                          width: "80px",
                          "& > .MuiSelect-select": {
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "black",
                            padding: "0px 0px 0px 10px !important",
                          },
                          ...(error && classes.errorSelect),
                        }}
                        {...field}
                      >
                        {items.map((item) => (
                          <MenuItem
                            key={item}
                            value={item}
                            disabled={
                              name === "expYear"
                                ? disabled
                                : formField.expYear ===
                                    new Date().getFullYear() &&
                                  item < new Date().getUTCMonth() + 1
                            }
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </Stack>
                  )}
                  name={name}
                  control={control}
                />
              )
            )}
          </Stack>

          {showBillingInfo && billingInfo && (
            <BillingAddressBlock address={billingInfo} />
          )}

          <Controller
            render={({ field }) => (
              <LargeCheckbox
                checked={!!field.value}
                disabled={card?.defaultMethod}
                checkedColor={card?.defaultMethod ? "#bdbdbd" : "#47a06d"}
                formSx={{
                  ml: 0,
                  "& .MuiFormControlLabel-root": {
                    ml: 0,
                  },
                }}
                checkboxSx={{
                  padding: 0,
                  margin: "0 8px 0 1px",
                }}
                label={
                  <Typography color="black" fontSize="14px" fontWeight="400">
                    Set as primary payment
                  </Typography>
                }
                size={15}
                {...field}
              />
            )}
            name="defaultMethod"
            control={control}
          />

          {!formField.defaultMethod && (
            <Button
              disableTouchRipple
              onClick={onDelete}
              sx={{
                mt: "20px",
                height: "18px",
                padding: 0,
                whiteSpace: "nowrap",
                width: "fit-content",
                minWidth: 0,
                color: "#E41E3A",
                fontSize: 14,
              }}
            >
              Delete Payment Method
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

ContentBlock.propTypes = {
  FIELD_DATA: array,
  control: object,
  card: object,
  billingInfo: object,
  formField: object,
  handleSubmit: func,
  onSubmit: func,
  isCustom: func,
  onDelete: func,
  showBillingInfo: bool,
};
