import { useMemo } from "react";
import { number, string } from "prop-types";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

export const ReturnOrderItem = ({ name, supplier, itemCount }) => {
  const itemCountList = useMemo(() => {
    const arr = [];
    for (let i = 0; i < itemCount; i++) {
      arr.push(i);
    }
    arr.push(arr.length);
    return arr;
  }, [itemCount]);

  return (
    <Grid
      sx={{
        height: "50px",
        "&:not(:last-of-type)": {
          borderBottom: "0.5px solid #D5D9D9",
        },
      }}
      container
    >
      <Grid
        display="flex"
        justifyContent="center"
        alignItems="center"
        xs={1.5}
        item
      >
        {/* Logo */}
        <Box
          border="1px solid #D5D9D9"
          width="33px"
          height="33px"
          borderRadius="4px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              width: "25px",
              height: "25px",
              bgcolor: "#F7F7F7",
              borderRadius: "50%",
            }}
          />
        </Box>
      </Grid>

      {/* Name */}
      <Grid xs={7.5} item>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography fontSize={12} fontWeight={500} color="#1C1C19">
            {name}
          </Typography>
          <Typography fontSize={12} fontWeight={300} color="#1C1C19">
            {supplier}
          </Typography>
        </Box>
      </Grid>

      {/* Quantity */}
      <Grid display="flex" alignItems="center" xs={3} item>
        <Box width="39px">
          <FormControl fullWidth>
            <Select
              defaultValue={0}
              sx={{
                height: 26,
                borderRadius: "4px 0px 0px 4px",
                "& .MuiInputBase-input": {
                  paddingLeft: "8px",
                  fontSize: 12,
                  fontWeight: 300,
                },
                "& fieldset": {
                  border: "0.5px solid #D5D9D9",
                },
                // color: field.value === "" ? "#717171" : "",
                color: "#717171",
              }}
              fullWidth
              IconComponent={(props) => (
                <ExpandMore sx={{ fontSize: 14 }} {...props} />
              )}
            >
              {itemCountList.map((i) => (
                <MenuItem key={i} value={i}>
                  <Typography fontSize={12} fontWeight={300} color="#717171">
                    {i}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: "39px",
            height: "26px",
            borderWidth: "0.5px 0.5px 0.5px 0px",
            borderStyle: "solid",
            borderColor: "#D5D9D9",
            borderRadius: "0px 4px 4px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontSize={12} fontWeight={300} color="#717171">
            of {itemCount}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

ReturnOrderItem.propTypes = {
  name: string,
  supplier: string,
  itemCount: number,
};
ReturnOrderItem.defaultProps = { name: "", supplier: "", itemCount: 0 };
