/* eslint-disable react/prop-types */
import React from "react";

export const CheckedIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "12"}
      height={height || "12"}
      viewBox="0 0 12 12"
    >
      <rect
        id="Rectangle_4665"
        data-name="Rectangle 4665"
        width="12"
        height="12"
        rx="4"
        fill="#42a57f"
      />
      <g id="path" transform="translate(3.063 2.97)">
        <path
          id="path-2"
          data-name="path"
          d="M1440.355,4365.74l2.216,2.881,4.661-6.06"
          transform="translate(-1440.355 -4362.562)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
