import axios from "axios";
import accountService from "../services/account";
import { redirect } from "react-router-dom";

const getTokenFromLocalStorage = () => localStorage.getItem("token");

const setTokenToLocalStorage = (token) => {
  if (token) {
    localStorage.setItem("token", token);
  }
};

const removeTokenFromLocalStorage = () => localStorage.removeItem("token");

const setTokenToAxios = (token) =>
  (axios.defaults.headers.Authorization = `Bearer ${token}`);

const removeTokenFromAxios = () => (axios.defaults.headers.Authorization = "");

const getUserRoleFromLocalStorage = () => localStorage.getItem("userRole");

const setUserRoleToLocalStorage = (userRole) =>
  localStorage.setItem("userRole", userRole);

const removeUserRoleFromLocalStorage = () =>
  localStorage.removeItem("userRole");

const removeSupplierFromLocalStorage = () =>
  localStorage.removeItem("selectedSupplier");

const removeAllTokens = () => {
  removeTokenFromAxios();
  removeTokenFromLocalStorage();
  removeUserRoleFromLocalStorage();
  removeSupplierFromLocalStorage();
};

const createAxiosResponseInterceptor = () => {
  axios.interceptors.response.use(
    (response) => response, // on success
    (error) => {
      const logInLink = "/login";

      if (
        error.response.status === 401 &&
        error?.response?.config?.url !== "/payment-terms" &&
        error?.response?.config?.url !== "/auth/app-customer"
      ) {
        removeAllTokens();
        redirect(logInLink);
        window.location.reload();
      }

      // axios.interceptors.response.eject(interceptor);
      return Promise.reject(error);
    }
  );
};

const getCurrentUser = async ({
  navigate,
  setCurrentUser,
  role,
  onSuccess,
  onFail,
}) => {
  const logInLink = `/login`;
  const userRole = getUserRoleFromLocalStorage();

  if (role && userRole !== role) {
    onFail();
    return navigate(logInLink);
  }

  try {
    const user = await accountService.getCurrentUser();
    setCurrentUser({ ...user });
    onSuccess();
  } catch (err) {
    removeAllTokens();
    onFail();
    navigate(logInLink);
  }
};

export {
  getTokenFromLocalStorage,
  setTokenToLocalStorage,
  removeTokenFromLocalStorage,
  removeTokenFromAxios,
  getUserRoleFromLocalStorage,
  setUserRoleToLocalStorage,
  removeUserRoleFromLocalStorage,
  setTokenToAxios,
  removeAllTokens,
  getCurrentUser,
  createAxiosResponseInterceptor,
};

export default {
  getTokenFromLocalStorage,
  removeTokenFromLocalStorage,
  removeTokenFromAxios,
  getUserRoleFromLocalStorage,
  setUserRoleToLocalStorage,
  removeUserRoleFromLocalStorage,
  setTokenToAxios,
  removeAllTokens,
  getCurrentUser,
  createAxiosResponseInterceptor,
};
