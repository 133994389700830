import { Box, Stack, Typography } from "@mui/material";
import { StyledTooltip, TextFieldWithBtns } from "components";
import { separateNumWithComma } from "helpers/helpers";
import { useIsVisible } from "hooks/useInVisible";
import { bool, func, number, object } from "prop-types";
import { useRef } from "react";
import { cl } from "../styles";

export const ActionBlock = ({
  tooltipState,
  quantity,
  setQuantity,
  product,
  outOfStock,
  index,
  handleDecreaseQty,
  handleAddQty,
  control,
  wholesalePrice,
  handleDelete,
}) => {
  const ref = useRef(null);
  const isVisible = useIsVisible(ref);
  const onLeftBtnClick = () => {
    if (quantity === 1) return handleDelete(product?.id);

    return handleDecreaseQty(index, quantity);
  };

  return (
    <Stack
      mt="8px"
      direction="row"
      gap="10px"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Box width="calc(100% - 102px)" sx={{ overflow: "hidden" }}>
        <Typography sx={cl.priceText} noWrap>
          ${separateNumWithComma(wholesalePrice?.toFixed(2))}
        </Typography>
      </Box>
      <StyledTooltip
        disableHoverListener
        open={tooltipState.open}
        placement="top"
        title={tooltipState.title}
        arrow
        sx={{
          zIndex: 10,
          opacity: isVisible ? 1 : 0,
          "& .MuiTooltip-tooltip": {
            mb: "14px !important",
          },
        }}
      >
        <Stack direction="column">
          <Box ref={ref} />
          <TextFieldWithBtns
            value={quantity}
            setValue={(e) => setQuantity(e, product, outOfStock, index)}
            onClickLeftBtn={onLeftBtnClick}
            onClickRightBtn={() => handleAddQty(index, quantity)}
            index={index}
            control={control}
            disabledIncrementBtn={
              tooltipState.open && tooltipState.type === "sellingOutOfStock"
            }
          />
        </Stack>
      </StyledTooltip>
    </Stack>
  );
};

ActionBlock.propTypes = {
  product: object,
  outOfStock: bool,
  tooltipState: object,
  control: object,
  quantity: number,
  wholesalePrice: number,
  index: number,
  setQuantity: func,
  handleDecreaseQty: func,
  handleAddQty: func,
  handleDelete: func,
};
