import { error } from "helpers/notifications";
import { useCallback, useEffect, useState } from "react";
import { isEqual } from "lodash";
import * as Yup from "yup";
import { getCustomerInfoByOrderDirectInviteToken } from "services/account";
import { defaultValues } from "./RegistrationPage.constants";

const schemaAddress = Yup.object().shape({
  formatted_address: Yup.string().required(),
  street: Yup.string().required(),
  city: Yup.string().required(),
  state: Yup.string().required().nullable(),
  zip: Yup.string().required(),
  lat: Yup.number().required(),
  lng: Yup.number().required(),
});

export const useCustomerData = ({ tokenParam, reset, formField }) => {
  const [customerData, setCustomerData] = useState({
    loadingCustomerData: false,
  });

  const handleFetchCustomerData = useCallback(
    async (token) => {
      try {
        setCustomerData((prev) => ({ ...prev, loadingCustomerData: true }));
        const {
          billingAddress,
          shippingAddress,
          //federalTaxId,
          name,
          phone,
          country,
          // hideRequestPaymentTerms,
        } = await getCustomerInfoByOrderDirectInviteToken(token);

        const isValidBillingAddress = await schemaAddress.isValid(
          billingAddress
        );
        const isValidShippingAddress = await schemaAddress.isValid(
          shippingAddress
        );

        //const isValidFederalTaxId = await Yup.number()
        //  .required()
        //  .isValid(federalTaxId);

        reset({
          ...formField,
          billingAddress: isValidBillingAddress
            ? billingAddress
            : defaultValues?.billingAddress,
          shippingAddress: isValidShippingAddress
            ? shippingAddress
            : defaultValues?.shippingAddress,
          sameAsBillingAddress: isEqual(billingAddress, shippingAddress),
          //federalTaxIdEin: isValidFederalTaxId ? federalTaxId : "",
          name: name || "",
          businessPhone: phone?.replace("+1", "") || "",
          country: country || "US",
          // hideRequestPaymentTerms: !!hideRequestPaymentTerms,
        });
      } catch (err) {
        if (err?.response?.data?.message) error(err.response.data.message);
        // eslint-disable-next-line no-console
        console.error(err?.response?.data?.message || err?.message);
      } finally {
        setCustomerData((prev) => ({ ...prev, loadingCustomerData: false }));
      }
    },
    [formField, reset]
  );

  useEffect(() => {
    if (tokenParam && formField?.businessEmail && formField?.step === 2) {
      handleFetchCustomerData(tokenParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenParam, formField?.businessEmail, formField?.step]);

  return {
    ...customerData,
  };
};
