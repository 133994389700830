import { func, string } from "prop-types";

import { TextBlock } from "./components/TextBlock";
import { NoteHeader } from "./components/NoteHeader";

import { useNoteDrawer } from "./useNoteDrawer";

import { useStyles } from "./styles";

import { SwipeableDrawer } from "@mui/material";

export const NoteDrawer = ({ setValue, fieldValue }) => {
  const classes = useStyles();

  const {
    onClose,
    onOpen,
    handleClearAction,
    handleSaveNote,
    noteText,
    setNoteText,
    isDrawerOpened,
  } = useNoteDrawer({ setValue, fieldValue });
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isDrawerOpened}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{ className: classes.drawerWrapper }}
    >
      <NoteHeader
        {...{ handleClearAction, handleSaveNote, noteText, fieldValue }}
      />
      <TextBlock {...{ noteText, setNoteText }} />
    </SwipeableDrawer>
  );
};

NoteDrawer.propTypes = {
  setValue: func,
  fieldValue: string,
};
