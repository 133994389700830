import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box } from "@mui/material";
import { cl } from "./styles";
import { validationSchema } from "./validations";

import { resetPasswordService } from "services/account";
import { error } from "helpers/notifications";
import { useLogo } from "pages/LoginPage/useLogo";
import { useMediaDevice } from "hooks/useMediaDevice";
import { MobileResetContent } from "./components/MobileResetContent";
import { DesktopResetContent } from "./components/DesktopResetContent";

export const ResetPasswordPage = () => {
  const { distributor_name } = useParams();
  const { isMobile } = useMediaDevice();

  const logo = useLogo({ distributor_name });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: { email: "" },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const isEmailError = useMemo(() => {
    return !!errors?.email;
  }, [errors?.email]);

  const [isCheckedAndConfirmedEmail, setCheckedAndConfirmedEmail] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const isDisabledContinueBtn = useMemo(() => {
    return loading || isEmailError || !formField?.email;
  }, [formField?.email, isEmailError, loading]);

  const onSubmit = async (data) => {
    const { email } = data;
    try {
      setLoading(true);
      const preparedData = { email };
      if (distributor_name) preparedData.storeName = distributor_name;

      await resetPasswordService(preparedData);
      setCheckedAndConfirmedEmail(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={cl.wrapper}>
      {isMobile ? (
        <MobileResetContent
          {...{
            logo,
            distributor_name,
            isCheckedAndConfirmedEmail,
            control,
            loading,
            isDisabledContinueBtn,
            handleSubmit,
            onSubmit,
            formField,
            setValue,
          }}
        />
      ) : (
        <DesktopResetContent
          {...{
            logo,
            distributor_name,
            isCheckedAndConfirmedEmail,
            control,
            loading,
            isDisabledContinueBtn,
            handleSubmit,
            onSubmit,
            formField,
            setValue,
          }}
        />
      )}
    </Box>
  );
};
