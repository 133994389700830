import { Link, NavLink } from "react-router-dom";
import { Controller } from "react-hook-form";

import { Box, Button, Paper, Typography } from "@mui/material";
import { useStyles } from "../styles";
import { bool, func, object, string } from "prop-types";

import { LoginFooter, StyledTextField } from "components";
import { PadlockIco } from "components/Icons";

export const DesktopResetContent = ({
  logo,
  distributor_name,
  isCheckedAndConfirmedEmail,
  control,
  loading,
  isDisabledContinueBtn,
  handleSubmit,
  onSubmit,
  formField,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="92vh"
      >
        <Paper
          sx={{
            width: "636px",
            px: "84px",
            borderRadius: "16px",
          }}
          elevation={0}
          variant="outlined"
        >
          {/* Distributor Logo */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "76px",
              mb: "58px",
            }}
          >
            {logo ? (
              logo?.logo
            ) : (
              <Box height={distributor_name ? "116px" : "67px"} />
            )}
          </Box>

          {/* Padlock ico */}
          <Box display="flex" justifyContent="center">
            <PadlockIco />
          </Box>

          {isCheckedAndConfirmedEmail ? (
            <>
              <Typography
                variant="h6"
                textAlign="center"
                my={1.5}
                fontSize="25px"
                color="#5F6267"
              >
                Check your email
              </Typography>

              <Typography
                color="#1C1C19"
                textAlign="center"
                mt={3}
                fontSize={15}
                fontWeight={300}
              >
                We sent a reset password email to:
              </Typography>
              <Typography
                textAlign="center"
                mb={3}
                fontWeight="400"
                color="#409A65"
                fontSize="15px"
              >
                {formField.email}
              </Typography>

              <Typography
                color="#1C1C19"
                textAlign="center"
                fontSize={15}
                fontWeight={300}
                sx={{ my: 2, whiteSpace: "pre-wrap" }}
              >
                {`Please click the reset password link to set your\nnew password.`}
              </Typography>
              <Button
                sx={{ mt: 3, height: "48px", fontSize: 20 }}
                variant="contained"
                fullWidth
                component={NavLink}
                to={distributor_name ? `/login/${distributor_name}` : "/login"}
              >
                Log In
              </Button>
              <Typography
                color="#7E7E7E"
                textAlign="center"
                fontSize={15}
                fontWeight={300}
                sx={{ mt: "48.5px", mb: 10, whiteSpace: "pre-wrap" }}
              >
                {`If you don't see the email in your inbox,\ncheck your spam folder.`}
              </Typography>
            </>
          ) : (
            <>
              {/* Title */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: "18px",
                }}
              >
                <Typography fontSize={25} fontWeight={500} color="#5F6267">
                  Forgot your password?
                </Typography>
              </Box>

              {/* Subtitle */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: "12px",
                }}
              >
                <Typography fontSize={15} fontWeight={300} color="#5F6267">
                  Enter your email below to reset your password
                </Typography>
              </Box>

              {/* Email */}
              <Box mt="40px">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledTextField
                      fullWidth
                      margin="normal"
                      label="Email"
                      size="medium"
                      InputProps={{
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel,
                      }}
                      error={error ? error.message : ""}
                      {...field}
                    />
                  )}
                  name="email"
                  control={control}
                />
              </Box>

              {/* Continue Button */}
              <Box mt={2}>
                <Button
                  disabled={isDisabledContinueBtn}
                  fullWidth
                  sx={{
                    fontSize: 20,
                    fontWeight: 500,
                    height: "48px",
                  }}
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                >
                  {loading ? "Loading" : "Continue"}
                </Button>
              </Box>

              {/* Divider */}
              <Box
                sx={{
                  // border: "1px solid lightblue",
                  width: "100%",
                  height: "64px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    borderBottom: "0.5px solid #D5D9D9",
                    width: "40%",
                    height: "1px",
                  }}
                  component="span"
                />
                <Box
                  sx={{
                    width: "20%",
                    textAlign: "center",
                    color: "#000",
                    fontSize: 13,
                    fontWeight: 300,
                  }}
                  component={Typography}
                >
                  Or
                </Box>
                <Box
                  sx={{
                    borderBottom: "0.5px solid #D5D9D9",
                    width: "40%",
                    height: "1px",
                  }}
                  component="span"
                />
              </Box>

              {/* Log in Button */}
              <Box mb={10}>
                <Button
                  fullWidth
                  sx={{
                    fontSize: 20,
                    fontWeight: 500,
                    height: "48px",
                    color: "#5F6267",
                    borderColor: "#D5D9D9",
                    "&:hover": {
                      borderColor: "#000",
                    },
                  }}
                  variant="outlined"
                  component={Link}
                  to={
                    distributor_name ? `/login/${distributor_name}` : "/login"
                  }
                >
                  Log in
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </Box>
      <LoginFooter />
    </>
  );
};

DesktopResetContent.propTypes = {
  logo: object,
  distributor_name: string,
  isCheckedAndConfirmedEmail: bool,
  loading: bool,
  isDisabledContinueBtn: bool,
  handleSubmit: func,
  onSubmit: func,
  setValue: func,
  control: object,
  formField: object,
};
