import {
  Autocomplete,
  Grid,
  Typography,
  Chip,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { object } from "prop-types";
import { Controller } from "react-hook-form";
import { StyledTextField } from "simply-depo-ui-components";
import useStyles, { cl } from "./styles";
import {
  bytesToSize,
  normalizeUppercaseString,
} from "../../../../../../../../helpers/helpers";
import SectionHeader from "../SectionHeader";
import FileUploadIcon from "@mui/icons-material/FileUpload";
// import { StyledProductIco } from "../../../../../../components/StyledProductIco/StyledProductIco";
import {
  ActiveIcon,
  CrossIcon,
} from "../../../../../../../../components/Icons";
import { Logo } from "components/HeaderBar/components";
import { StyledTooltip } from "components";
import { useContactSupplier } from "./useContactSupplier";

const ContactTab = ({ supplier }) => {
  const classes = useStyles();

  const {
    onSubmit,
    formField,
    control,
    setValue,
    disabledSubmitBtn,
    handleSubmit,
    attachments,
    onAttach,
    handleRemoveFile,
    setRecipientsSearch,
    recipientsSearch,
    handlePressSpace,
    inputRef,
    CCList,
    sent,
    attachmentsSizeExceeded,
    processingIntoChip,
    currentUser,
    baseUser,
    distribIsAlreadyAdded,
  } = useContactSupplier(supplier);

  return (
    <Box
      component="form"
      id="contact-vendor-group-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <SectionHeader title="Contact Supplier" />
      {/* Info Text */}
      {sent ? (
        <Box className={classes.successWrapper}>
          <Typography fontSize="17px" fontWeight="700">
            Sent successfully!
          </Typography>
          <Box
            borderRadius="50%"
            border="1px solid #47A06D"
            width="26px"
            height="26px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p="4px"
            my="25px"
            className={classes.activeIcon}
          >
            <ActiveIcon />
          </Box>
          <Typography fontSize="12px" fontWeight="600">
            Your message has been sent to the supplier!
          </Typography>
          <Box className={classes.logoWrap}>
            <Logo className={classes.logo} />
          </Box>
        </Box>
      ) : (
        <Box>
          {/* CC */}
          <Grid sx={{ mt: "34px" }} xs={12} item container>
            <Grid
              sx={{
                pt: 1,
                pl: 4.5,
              }}
              xs={2}
              item
              container
            >
              <Typography fontSize={14} color="#000000" fontWeight={600} noWrap>
                To:
              </Typography>
            </Grid>
            <Grid xs={9.2} item>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    multiple
                    freeSolo
                    disableCloseOnSelect
                    // loading={recipientsLoading}
                    PaperComponent={(props) => (
                      <Box {...props} className={classes.recipientsTable} />
                    )}
                    size="small"
                    isOptionEqualToValue={(option, value) =>
                      option.email === value.email
                    }
                    sx={{
                      "& .MuiAutocomplete-paper": {
                        boxShadow: "none",
                      },
                    }}
                    className={classes.textfield}
                    getOptionLabel={(option) => option.email}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        noErrorMessage
                        inputProps={{
                          ...params.inputProps,
                          value: recipientsSearch,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <></>,
                        }}
                        error={error?.message || ""}
                        placeholder="Emails"
                        className={classes.textfield}
                        value={recipientsSearch}
                        inputSx={{ color: "#707070" }}
                        onChange={(e) => setRecipientsSearch(e.target.value)}
                        onKeyDown={handlePressSpace}
                        onBlur={processingIntoChip}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            height: "fit-content",
                            minHeight: "37px",
                            p: "5px!important",
                            fontSize: "12px",
                          },
                        }}
                      />
                    )}
                    ListboxProps={{
                      sx: {
                        p: 0,
                        maxHeight: "178px",
                        boxShadow: "none",
                        overflow: "overlay",
                        borderRadius: "0 0 4px 4px",
                        border: {
                          xs: "none",
                          sm: "1px solid #D5D9D9",
                        },
                      },
                    }}
                    renderOption={(props, recipient, { selected }) => (
                      <Grid
                        key={recipient.email}
                        container
                        columns={10}
                        sx={{
                          backgroundColor:
                            selected && "rgba(66, 165, 127, 0.1)",
                        }}
                        {...props}
                        className={classes.recipientItem}
                      >
                        <Grid item xs={7}>
                          <Box display="flex" gap="11px" alignItems="center">
                            <Box>
                              <Typography>{recipient.name}</Typography>
                              <Typography fontWeight="400">
                                {recipient.email}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Typography>
                            {normalizeUppercaseString(recipient.role)}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    options={CCList || []}
                    renderTags={(value, getTagProps) => (
                      <Box className={classes.recipientChipWrapper}>
                        {value.map((cc, index) => (
                          <Chip
                            key={cc.email}
                            sx={{
                              borderRadius: "10px",
                              border: "1px solid #D4D4D4",
                              backgroundColor: "#F8F8FA",
                              height: "19px",
                              maxWidth: "90%",
                            }}
                            size="small"
                            label={
                              <Typography
                                sx={{ fontSize: "12px", lineHeight: 1.2 }}
                                color="groundLighter.main"
                                noWrap
                              >
                                {cc.email}
                              </Typography>
                            }
                            onMouseDown={(e) => e.stopPropagation()}
                            {...getTagProps({ index })}
                            {...(baseUser?.email === cc.email && {
                              disabled: true,
                            })}
                          />
                        ))}
                      </Box>
                    )}
                    {...field}
                    onChange={(e, newValue) => {
                      if (
                        newValue.length &&
                        typeof newValue[newValue.length - 1] === "string"
                      )
                        return;

                      setRecipientsSearch("");
                      setValue(field.name, newValue);
                    }}
                  />
                )}
                name="cc"
                control={control}
              />
            </Grid>
          </Grid>
          {/* Subject */}
          <Grid sx={{ mt: 3 }} xs={12} item container>
            <Grid
              sx={{
                pt: 1,
                pl: 4.5,
              }}
              xs={2}
              item
              container
            >
              <Typography fontSize={14} fontWeight={600} color="#000000" noWrap>
                Subject:
              </Typography>
            </Grid>
            <Grid xs={9.2} item>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: 12,
                        fontWeight: 300,
                      },
                    }}
                    placeholder="Subject"
                    fullWidth
                    size="small"
                    InputProps={{ sx: { maxHeight: 37 } }}
                    error={!!error?.message}
                    {...field}
                  />
                )}
                name="subject"
                control={control}
              />
            </Grid>
          </Grid>
          {/* Message */}
          <Grid sx={{ mt: 3 }} xs={12} item container>
            <Grid
              sx={{
                alignItems: "flex-start",
                pt: 1,
                pl: 4.5,
              }}
              xs={2}
              item
              container
            >
              <Typography fontSize={14} fontWeight={600} color="#000000" noWrap>
                Message:
              </Typography>
            </Grid>
            <Grid xs={9.2} item>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: 12,
                        fontWeight: 300,
                      },
                    }}
                    placeholder="Example of the message"
                    fullWidth
                    size="small"
                    InputProps={{
                      sx: cl.messageField,
                      endAdornment: !attachments.length ? null : (
                        <Box className={classes.filesWrapper}>
                          {attachments.map((attachment) => (
                            <Box
                              key={attachment.name}
                              className={classes.fileRow}
                            >
                              <Box className={classes.fileTitle}>
                                <Typography
                                  fontSize="14px"
                                  noWrap
                                  color="primary"
                                  fontWeight={600}
                                  maxWidth="65%"
                                >
                                  {attachment.name}
                                </Typography>
                                <Typography
                                  fontSize="14px"
                                  fontWeight={600}
                                  whiteSpace="nowrap"
                                  color="#707070"
                                >
                                  ({bytesToSize(attachment.size)})
                                </Typography>
                              </Box>
                              <IconButton
                                sx={{ p: "2px" }}
                                onClick={() =>
                                  handleRemoveFile(attachment.name)
                                }
                              >
                                <CrossIcon size="18" />
                              </IconButton>
                            </Box>
                          ))}
                        </Box>
                      ),
                    }}
                    multiline
                    rows={4.5}
                    error={!!error?.message}
                    {...field}
                  />
                )}
                name="message"
                control={control}
              />
            </Grid>
          </Grid>
          {/* Send Me Copy */}
          <Grid sx={{ mt: 1 }} xs={12} item container>
            <Box
              ml="144px"
              display="flex"
              justifyContent="space-between"
              width="100%"
              pr="58px"
              position="relative"
              alignItems="flex-start"
            >
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{
                          fontSize: 14,
                          color: "#000000", // theme
                        }}
                      >
                        Send me a copy of this email
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={!!field.value || distribIsAlreadyAdded}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                      />
                    }
                    {...field}
                    onChange={() => {
                      setValue(field.name, !field.value);
                      if (!field.value && !distribIsAlreadyAdded)
                        return setValue("cc", [
                          ...formField.cc,
                          {
                            id: currentUser.id,
                            email: currentUser.baseUser.email,
                          },
                        ]);
                      setValue(
                        "cc",
                        formField.cc.filter(
                          (c) => c?.email !== currentUser?.baseUser?.email
                        )
                      );
                    }}
                  />
                )}
                name="sendMe"
                control={control}
              />
              <Box>
                <Button
                  variant="outlined"
                  startIcon={<FileUploadIcon style={{ fontSize: "14px" }} />}
                  color="grey"
                  onClick={() => {
                    inputRef.current.click();
                  }}
                  sx={{ borderColor: "#d5d9d9" }}
                >
                  Attach File
                  <input
                    ref={inputRef}
                    type="file"
                    hidden
                    onChange={onAttach}
                    accept={"*"}
                    multiple
                  />
                </Button>
                <Typography fontSize="12px" color="#A8A8A8">
                  Max. 10 MB
                </Typography>
              </Box>
            </Box>
          </Grid>
          <DialogActions sx={{ mt: "47px", pr: "58px" }}>
            <StyledTooltip
              placement="top"
              title={
                attachmentsSizeExceeded ? "10 MB max. can be attached" : ""
              }
            >
              <Box>
                <Button
                  variant="contained"
                  type="submit"
                  form="contact-vendor-group-form"
                  sx={{ fontSize: "17px" }}
                  disabled={attachmentsSizeExceeded || disabledSubmitBtn}
                >
                  Send Email
                </Button>
              </Box>
            </StyledTooltip>
          </DialogActions>
        </Box>
      )}
    </Box>
  );
};

ContactTab.propTypes = {
  supplier: object,
};

export default ContactTab;
