import { array, bool, func, number, string } from "prop-types";
import { Typography } from "@mui/material";
import useStyles from "../../styles";

export const PriceBlock = ({
  getRangeWholeSalePrice,
  productSku,
  productWholesalePrice,
  isChild,
  childProducts,
}) => {
  const classes = useStyles();

  return (
    <>
      {productSku && (
        <Typography className={classes.text} style={{ fontWeight: 400 }}>
          ${(productWholesalePrice || 0).toFixed(2)}
        </Typography>
      )}
      {!isChild && !!childProducts?.length && (
        <Typography className={classes.text} style={{ fontWeight: 400 }}>
          {getRangeWholeSalePrice(childProducts)}
        </Typography>
      )}
    </>
  );
};

PriceBlock.propTypes = {
  getRangeWholeSalePrice: func,
  productSku: string,
  productWholesalePrice: number,
  isChild: bool,
  childProducts: array,
};
