import { Box } from "@mui/material";
import { StyledButton } from "components";
import { bool } from "prop-types";

import { cl } from "../ContentBlock/styles";

export const ActionBlock = ({ disabledBtn }) => {
  return (
    <Box width="100%" mt="auto">
      <StyledButton
        sx={cl.sendBtn}
        disabled={disabledBtn}
        label="Contact Supplier"
        variant="contained"
        fontSize="16px"
        fontWeight="600"
        type="submit"
        form="contact-vendor-group-form"
      />
    </Box>
  );
};

ActionBlock.propTypes = {
  disabledBtn: bool,
};
