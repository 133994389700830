import { func, object } from "prop-types";

import { CrossIcon } from "components/Icons";

import { Box, IconButton } from "@mui/material";

import { cl } from "../ContentBlock/styles";

export const FileItem = ({ attachment, handleRemoveFile }) => {
  return (
    <Box sx={cl.fileItem}>
      <Box component="img" src={attachment.url} />

      <IconButton
        sx={cl.deleteFileItem}
        onClick={() => handleRemoveFile(attachment.name)}
      >
        <CrossIcon size="14" />
      </IconButton>
    </Box>
  );
};

FileItem.propTypes = {
  attachment: object,
  handleRemoveFile: func,
};
