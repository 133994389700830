import axios from "axios";

const getCurrentUser = async () => {
  const { data } = await axios.get("/users/app-customers/me");

  return data;
};

const loginUser = async (userData) => {
  const { data } = await axios.post("/auth/app-customer", userData);

  return data;
};

const registerUserService = async (userData, params) => {
  const { data } = await axios({
    method: "post",
    url: "/users/app-customers",
    data: userData,
    params,
  });

  return data;
};

const confirmUserService = async (userData, token, params) => {
  const { data } = await axios({
    method: "post",
    url: "/users/app-customers/code",
    data: userData,
    headers: { Authorization: `Bearer ${token}` },
    params,
  });

  return data;
};

const resetPasswordService = async (resetData) => {
  const { data } = await axios({
    method: "post",
    url: "/users/app-customers/reset-password",
    data: resetData,
  });
  return data;
};

const restorePasswordService = async (restoreData) => {
  const { data } = await axios({
    method: "post",
    url: "/users/app-customers/restore-password",
    data: restoreData,
  });
  return data;
};

const updateProfileService = async (body) => {
  const { data } = await axios.put("/users/app-customers/", body);
  return data;
};

const completeRegistrationService = async (userData, token) => {
  const { data } = await axios({
    method: "post",
    url: "/users/app-customers/complete-registration",
    data: userData,
    headers: { Authorization: `Bearer ${token}` },
  });
  return data;
};

const resendRegistrationCodeService = async ({ token, params }) => {
  return await axios({
    method: "PUT",
    url: "/users/app-customers/code",
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
};

const getDistributorInfoByStoreNameService = async (storeName) => {
  const { data } = await axios({
    method: "GET",
    url: `/users/distributors/info/${storeName}`,
  });
  return data;
};

const getCustomerInfoByOrderDirectInviteToken = async (inviteToken) => {
  const { data } = await axios({
    method: "GET",
    url: `/customers/info/${inviteToken}`,
  });
  return data;
};

export {
  getCurrentUser,
  loginUser,
  confirmUserService,
  registerUserService,
  updateProfileService,
  completeRegistrationService,
  resendRegistrationCodeService,
  resetPasswordService,
  restorePasswordService,
  getDistributorInfoByStoreNameService,
  getCustomerInfoByOrderDirectInviteToken,
};

export default {
  getCurrentUser,
  loginUser,
  registerUserService,
};
