/* eslint-disable react/prop-types */
import React from "react";

export const DangerIcon = ({ fill = "#ff1919", size = "26.851" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 26.851 26.851"
    >
      <path
        id="Icon_metro-warning"
        data-name="Icon metro-warning"
        d="M16,4.361,27.248,26.787H4.744L16,4.361Zm0-2.433A1.941,1.941,0,0,0,14.4,3.1L2.939,25.941c-.878,1.561-.131,2.838,1.66,2.838h22.8c1.791,0,2.538-1.277,1.66-2.838h0L17.593,3.1A1.941,1.941,0,0,0,16,1.928Zm1.678,21.817A1.678,1.678,0,1,1,16,22.067,1.678,1.678,0,0,1,17.675,23.745ZM16,20.388a1.678,1.678,0,0,1-1.678-1.678V13.676a1.678,1.678,0,0,1,3.356,0V18.71A1.678,1.678,0,0,1,16,20.388Z"
        transform="translate(-2.571 -1.928)"
        fill={fill}
      />
    </svg>
  );
};
