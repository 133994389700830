export const cl = {
  cardWrapper: {
    width: "100%",
    boxSizing: "border-box",
    gap: "8px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",

    p: "0 0 11px",

    "&:not(:first-of-type)": {
      p: "12px 0 11px",
    },
  },

  cardMainText: {
    color: "#1C1C19",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15.6px",
    wordWrap: "break-word",
  },

  cardAddressText: {
    color: "rgba(0, 0, 0, 0.40)",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "17.5px",
    wordWrap: "break-word",
  },

  placeOrderBtn: {
    height: "fit-content",
    p: "2.5px 12px",
    background: "#409A65",
    borderRadius: "8px",
    color: "white",
    fontSize: "10px",
    fontWeight: 500,
    minWidth: "fit-content",
  },

  statusStyles: {
    fontSize: "12px",
    fontWeight: 400,
    width: "fit-content",
    gap: "4px",
    textTransform: "capitalize",

    //"& svg": {
    //  width: "12px",
    //  height: "12px",
    //},
  },
};
