import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  footerWrapper: {
    height: "54px",
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 35px",
    marginTop: "4vh",
    width: "100%",
  },

  footerText: {
    fontSize: "14px",
    fontWeight: 300,
    color: "#9C9C94",
  },
}));

export default useStyles;
