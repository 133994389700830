export const cl = {
  VendorsItem: {
    paper: {
      height: "82px",
      minHeight: "82px",
      maxHeight: "82px",
      alignItems: "center",
      borderRadius: "0 0 4px 4px",
      "&:not(:last-child)": {
        borderBottom: "1px solid #D5D9D9", // check theme
        borderRadius: 0,
      },
    },
  },
};
