export const defaultValues = {};

export const VIEW_SETTINGS_ITEMS = [
  {
    id: "1",
    name: "Order Direct",
    type: "radio",
    selected: "1",
    items: [
      { id: "1", name: "Approved", count: 5 },
      { id: "2", name: "Pending", count: 2 },
      { id: "3", name: "Rejected", count: 3 },
    ],
  },
  {
    id: "2",
    name: "Minimum Order",
    type: "radio",
    selected: "1",
    items: [
      { id: "1", name: "Show all" },
      { id: "2", name: "No minimum " },
      { id: "3", name: "$100 or less" },
      { id: "4", name: "$200 or less" },
      { id: "5", name: "$300 or less" },
    ],
  },
  {
    id: "3",
    name: "Industry",
    type: "checkbox",
    items: [
      { id: "1", name: "Food & Beverage", checked: false },
      { id: "2", name: "Health & Beauty ", checked: false },
      { id: "3", name: "Beer, Wine & Spirits", checked: true },
      { id: "4", name: "Cannabis", checked: false },
    ],
  },
];

export const HEADER_ITEMS = [
  {
    id: "0",
    name: "",
    size: 0.18,
  },
  {
    id: "1",
    name: "VENDOR",
    size: 3,
  },
  {
    id: "2",
    name: "MINIMUM ORDER",
    size: 2.52,
  },
  {
    id: "3",
    name: "PRODUCTS",
    size: 1.9,
  },
  { id: "4", name: "ORDER DIRECT", size: 1.72 },
  { id: "5", name: "TERMS", size: 1.2 },
  { id: "6", name: "", size: 0.74 },
  { id: "7", name: "", size: 0.74 },
];

export const INDUSTRIES = [
  { name: "Food & Beverage", value: "Food & Beverage" },
  { name: "Health & Beauty", value: "Health & Beauty" },
  { name: "General Consumer Goods", value: "General Consumer Goods" },
  { name: "Retail Services", value: "Retail Services" },
  { name: "Beer, Wine, & Spirits", value: "Beer, Wine, & Spirits" },
  { name: "Cannabis", value: "Cannabis" },
  { name: "Broker Or Distributor", value: "Broker Or Distributor" },
  { name: "Other", value: "Other" },
];

export const ORDER_DIRECT_FILTERS = [
  { name: "Show all", value: "", id: 1 },
  { name: "Approved", value: "APPROVED", id: 2 },
  { name: "Pending", value: "PENDING", id: 3 },
];

export const MINIMUM_ORDER_FILTERS = [
  { name: "Show all", value: "" },
  { name: "No minimum", value: "No minimum" },
  { name: "$100 or less", value: "$100 or less" },
  { name: "$200 or less", value: "$200 or less" },
  { name: "$300 or less", value: "$300 or less" },
];

export const INDUSTRIES_WITH_IDS = [
  { name: "Food & Beverage", value: "Food & Beverage", id: 1 },
  { name: "Health & Beauty", value: "Health & Beauty", id: 2 },
  { name: "General Consumer Goods", value: "General Consumer Goods", id: 3 },
  { name: "Retail Services", value: "Retail Services", id: 4 },
  { name: "Beer, Wine, & Spirits", value: "Beer, Wine, & Spirits", id: 5 },
  { name: "Cannabis", value: "Cannabis", id: 6 },
  { name: "Broker Or Distributor", value: "Broker Or Distributor", id: 7 },
  { name: "Other", value: "Other", id: 8 },
];

export const MOBILE_MINIMUM_ORDER_FILTERS = [
  { name: "Show all", value: "", id: 1 },
  { name: "No minimum", value: "no", id: 2 },
  { name: "$100 or less", value: 100, id: 3 },
  { name: "$200 or less", value: 200, id: 4 },
  { name: "$300 or less", value: 300, id: 5 },
];

export const DISTRIBUTOR_SORT_PARAMS = [
  "Most Recent",
  "Supplier (A-Z)",
  "Supplier (Z-A)",
];

export const VENDOR_FILTER_TABS = ["orderDirect", "minimumOrder", "industry"];

export const DEFAULT_VENDOR_FILTER_STATE = {
  minimumOrder: {},
  orderDirect: {},
  industry: [],
};

export const DEFAULT_VENDOR_FILTER_DATA = {
  orderDirect: {
    show: false,
    values: [],
  },
  minimumOrder: {
    show: false,
    values: [],
    name: "",
  },
  industry: [],
};

export const FILTER_FIELD_NAMES = {
  orderDirect: "order_direct",
  minimumOrder: "min_order",
  industry: "industries",
};
