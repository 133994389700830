import { string } from "prop-types";
import { Box, Typography } from "@mui/material";
import { ShevronIcon } from "components/Icons";

export const SummaryItem = ({ name }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          ml: "-16px",
          fontSize: "24px",
          fontWeight: 500,
          color: "#000000",
        }}
      >
        {name}
      </Typography>
      <ShevronIcon className="my-ico" />
    </Box>
  );
};

SummaryItem.propTypes = {
  name: string,
};
SummaryItem.defaultProps = {
  name: "",
};

export default SummaryItem;
