import { Stack } from "@mui/material";
import { FilterDrawer, MobileSearchBlock } from "components";
import { VENDOR_FILTER_TABS } from "pages/VendorsPage/VendorsPage.constants";
import { useVendorsActions } from "pages/VendorsPage/VendorsPage.hooks";
import { VendorListBlock } from "./components";
import { useVendorFilters } from "./useVendorFilters";

export const MobileVendors = () => {
  const { vendorsState, search, setSearch, setVendorsParams } =
    useVendorsActions();

  const {
    onSave,
    chipData,
    filterCount,
    FILTER_DATA,
    onStateClear,
    openFilterDrawer,
    handleDeleteChip,
    disabledActionBtn,
    currentFilterElement,
  } = useVendorFilters({ setVendorsParams });

  return (
    <Stack>
      <FilterDrawer
        {...{
          FILTER_TABS: VENDOR_FILTER_TABS,
          FILTER_DATA,
          onSave,
          onStateClear,
          disabledActionBtn,
          currentFilterElement,
        }}
      />
      <MobileSearchBlock
        handleSearch={(e) => setSearch(e.target.value)}
        searchInput={search}
        {...{
          chipData,
          handleDeleteChip,
          filterCount,
          openFilterDrawer,
          placeholder: "Search suppliers",
        }}
      />
      <VendorListBlock {...{ vendorsState, filterCount }} />
    </Stack>
  );
};
