import { createSelector } from "reselect";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addDraftAction, updateDraftsAction } from "redux/actions/drafts";
import { draftsListSelector } from "redux/selectors/drafts";

const selector = createSelector(draftsListSelector, (draftsList) => ({
  draftsList,
}));

export const useMobileProduct = ({ handleSearch }) => {
  const dispatch = useDispatch();
  const { currentPage } = useSelector(({ settings }) => settings.pagination);
  const { draftsList } = useSelector(selector);
  const [page, setPage] = useState(currentPage);

  const handleFetchMore = () => {
    handleSearch(page + 1);
    setPage((prev) => prev + 1);
  };

  const handleGetCurrentDraft = useCallback(
    (id) => {
      return draftsList?.length
        ? draftsList?.find(({ distributor }) => distributor.id === id)
        : null;
    },
    [draftsList]
  );

  const getDefaultProductValue = useCallback(
    (product) => {
      const { id, distributor } = product || {};
      const currentDraft = handleGetCurrentDraft(distributor?.id);

      const filteredProductsData = currentDraft?.data?.productsData.filter(
        (item) => item.id === id
      );

      if (filteredProductsData && filteredProductsData.length)
        return filteredProductsData?.[0]?.quantity;
      return 0;
    },
    [handleGetCurrentDraft]
  );

  const handleGetQTYFromDraft = (draft, productId) => {
    if (!!draft && !!draft?.data?.productsData?.length) {
      const productFromDraft = draft?.data?.productsData.filter(
        (product) => product.id === productId
      );

      if (productFromDraft.length) return productFromDraft?.[0]?.quantity;
      return null;
    }
    return null;
  };

  const handleAddToCart = useCallback(
    ({ product, productQty, isAdded, isAlreadyChangedAndAdded }) => {
      const {
        id,
        wholesalePrice,
        manufacturerDiscountData,
        itemDiscountAmount,
        minOrderQTY,
        distributor,
        itemDiscountType,
      } = product || {};

      const { id: distributorId } = distributor || {};

      const currentDraft = handleGetCurrentDraft(distributorId);

      const newProductsData = [...(currentDraft?.data?.productsData || [])];

      if (isAdded && !isAlreadyChangedAndAdded) {
        const filteredProductsData = newProductsData.filter(
          (item) => item.id !== id
        );

        const uploadDraft = {
          ...currentDraft?.data,
          productsData: filteredProductsData,
        };

        return dispatch(
          updateDraftsAction(uploadDraft, distributorId, currentDraft.id)
        );
      }

      if (isAlreadyChangedAndAdded) {
        const productForReplace = newProductsData.filter(
          (item) => item.id === id
        )[0];
        const filteredProductsData = newProductsData.filter(
          (item) => item.id !== id
        );

        const uploadDraft = {
          ...currentDraft?.data,
          productsData: [
            ...filteredProductsData,
            { ...productForReplace, quantity: productQty },
          ],
        };

        return dispatch(
          updateDraftsAction(uploadDraft, distributorId, currentDraft.id)
        );
      }

      const newProduct = {
        id,
        quantity: productQty,
        manufacturerDiscountData: manufacturerDiscountData || [],
        itemDiscountAmount: itemDiscountAmount || 0,
        itemDiscountType: itemDiscountType || "PERCENTAGE",
        minOrderQTY: minOrderQTY,
        price: wholesalePrice,
      };

      newProductsData.push(newProduct);

      const uploadDraft = {
        ...currentDraft?.data,
        productsData: newProductsData,
      };
      if (currentDraft)
        return dispatch(
          updateDraftsAction(uploadDraft, distributorId, currentDraft.id)
        );
      return dispatch(addDraftAction(uploadDraft, distributorId));
    },
    [dispatch, handleGetCurrentDraft]
  );

  const handleIsAlreadyAdded = useCallback(
    (product) => {
      const currentDraft = handleGetCurrentDraft(product?.distributor?.id);

      return !!currentDraft?.data?.productsData?.find(
        (item) => item.id === product.id
      );
    },
    [handleGetCurrentDraft]
  );

  return {
    handleAddToCart,
    handleFetchMore,
    handleIsAlreadyAdded,
    handleGetCurrentDraft,
    getDefaultProductValue,
    handleGetQTYFromDraft,
  };
};
