import { Box, Typography } from "@mui/material";

export const TitleBlock = () => {
  return (
    <Box>
      <Typography fontSize={30} fontWeight={600} color="#000">
        Cart summary
      </Typography>
    </Box>
  );
};
