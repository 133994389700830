import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  categoryChip: {
    borderColor: ({ isActive }) =>
      isActive ? "#409A65" : "rgba(0, 0, 0, 0.05)",
    backgroundColor: ({ isActive }) =>
      isActive ? "rgba(64, 154, 101, 0.05)" : "inherit",
    minWidth: "72px",
    borderRadius: "8px",

    "& span": {
      color: "#2F3040",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "18.90px",
      wordWrap: "break-word",
      padding: "8px",
    },
  },
}));

export default useStyles;
