import * as React from "react";
export const MobileAppPromptIcon = (props) => (
  <svg
    width={245}
    height={102}
    viewBox="0 0 245 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M206.5 29a4 4 0 0 0-4 4v65a4 4 0 0 0 4 4h34a4 4 0 0 0 4-4V33a4 4 0 0 0-4-4h-34Zm2 4a2 2 0 0 0-2 2v61a2 2 0 0 0 2 2h30a2 2 0 0 0 2-2V35a2 2 0 0 0-2-2h-30Z"
      fill="#F0C401"
    />
    <path
      d="M215.5 95a1 1 0 0 1 1-1h14a1 1 0 0 1 0 2h-14a1 1 0 0 1-1-1Z"
      fill="#F0C401"
    />
    <path
      d="M223.5 60a1.004 1.004 0 0 0-.924.617 1.01 1.01 0 0 0-.076.383v3.268l-1.098-.634a.997.997 0 0 0-1.365.366.996.996 0 0 0 .365 1.366l2.598 1.5a.998.998 0 0 0 1.5-.866v-5a1.005 1.005 0 0 0-.617-.924A1.004 1.004 0 0 0 223.5 60Zm0-4a10.002 10.002 0 0 0-9.808 11.95 9.998 9.998 0 0 0 13.635 7.289A10.003 10.003 0 0 0 233.5 66a10.014 10.014 0 0 0-10-10Zm0 18a8.003 8.003 0 0 1-7.391-4.939 7.993 7.993 0 0 1-.455-4.622 7.993 7.993 0 0 1 6.285-6.285 7.998 7.998 0 0 1 7.215 13.5A8.01 8.01 0 0 1 223.5 74Z"
      fill="#FBBC04"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.5 0a4 4 0 0 0-4 4v92a4 4 0 0 0 4 4h139a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4h-139Zm2 4a2 2 0 0 0-2 2v88a2 2 0 0 0 2 2h135a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-135Z"
      fill="#409A65"
    />
    <path
      d="M96.5 4h16v2a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V4Zm-78 91h172v5a2 2 0 0 1-2 2h-168a2 2 0 0 1-2-2v-5Z"
      fill="#409A65"
    />
    <g clipPath="url(#a)">
      <path
        d="m102.5 55.172 9.192-9.193 1.415 1.414L102.5 58l-6.364-6.364 1.414-1.414 4.95 4.95Z"
        fill="#409A65"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M92.5 40h24v24h-24z" />
      </clipPath>
    </defs>
  </svg>
);
