import React from "react";
import { string, number, object, oneOfType } from "prop-types";

import { Grid, Typography } from "@mui/material";

export const StyledTypography = ({ text, size, style }, props) => {
  const defaultStyles = {
    fontSize: 19,
    color: "clearBlack",
  };

  return (
    <Grid
      sx={{ ...defaultStyles, ...style }}
      xs={size}
      item
      component={Typography}
      noWrap
      {...props}
    >
      {text}
    </Grid>
  );
};

StyledTypography.propTypes = {
  text: oneOfType([string, number]),
  size: number,
  style: object,
};
StyledTypography.defaultProps = {};
