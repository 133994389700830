import { string, func, bool } from "prop-types";

import { FullPrevIcon } from "components/Icons/FullPrevIcon";

import useStyles from "../styles";

import { Box, Button, IconButton, Stack, Typography } from "@mui/material";

export const FilterHeader = ({
  selectedFilter,
  handleSelectFilter,
  handleClearAction,
  handleSaveFilter,
  disableButtons,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.headerWrapper}>
      {!selectedFilter ? (
        <Typography className={classes.title}>Filter by</Typography>
      ) : (
        <IconButton sx={{ p: 0 }} onClick={() => handleSelectFilter("")}>
          <FullPrevIcon />
        </IconButton>
      )}

      <Stack direction="row">
        <Button
          disabled={disableButtons && !selectedFilter}
          variant="outlined"
          className={classes.actionBtn}
          sx={{ color: "rgba(0, 0, 0, 0.50)" }}
          onClick={handleClearAction}
        >
          Clear
        </Button>
        <Button
          variant="outlined"
          className={classes.actionBtn}
          onClick={handleSaveFilter}
        >
          Done
        </Button>
      </Stack>
    </Box>
  );
};

FilterHeader.propTypes = {
  selectedFilter: string,
  handleSelectFilter: func,
  handleSaveFilter: func,
  handleClearAction: func,
  disableButtons: bool,
};
FilterHeader.defaultProps = {
  selectedFilter: "",
  handleSelectFilter: () => {},
  handleSaveFilter: () => {},
  handleClearAction: () => {},
};
