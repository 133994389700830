import React from "react";

export const CloseIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F6F6F6" />
      <path
        d="m12.788 12 3.543-3.544a.544.544 0 0 0 0-.787.544.544 0 0 0-.787 0L12 11.213 8.456 7.669a.544.544 0 0 0-.787 0 .544.544 0 0 0 0 .787L11.213 12l-3.544 3.544a.51.51 0 0 0-.169.394c0 .337.225.562.563.562a.51.51 0 0 0 .393-.169L12 12.788l3.544 3.543a.51.51 0 0 0 .394.17.51.51 0 0 0 .393-.17.544.544 0 0 0 0-.787z"
        fill="#8A8A8A"
        fillOpacity=".8"
      />
    </svg>
  );
};
