import { array, object } from "prop-types";

import { StyledProductIco } from "components";

import { photoUrl } from "helpers/helpers";

import { Box } from "@mui/material";

export const ProductImage = ({ photos, product, photo }) => {
  return (
    <Box
      sx={{
        minWidth: "86px",
        width: "86px",
        height: "86px",
      }}
    >
      <StyledProductIco
        src={
          photos?.length
            ? photoUrl(photos[0].fileName)
            : product?.photo
            ? photoUrl(photo?.fileName)
            : ""
        }
        styles={{
          objectFit: "contain",
          width: "100%",
          height: "100%",
          borderRadius: "8px",
        }}
        placeholderStyles={{
          width: "86px",
          height: "86px",
          wrapperProps: {
            height: "86px",
            sx: {
              borderRadius: "8px",
              svg: { borderRadius: "8px !important" },
            },
          },
        }}
      />
    </Box>
  );
};

ProductImage.propTypes = {
  photos: array,
  product: object,
  photo: object,
};
