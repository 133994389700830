import React, { useMemo, useState } from "react";
import { any, object, shape, string } from "prop-types";
import { Box, Typography } from "@mui/material";
import useStyles from "./styles";
import { Logo } from "components/HeaderBar/components";
import { DangerIcon } from "components/Icons";
import { StyledButton } from "components";
import ContactDialog from "pages/VendorsPage/components/DesktopVendors/components/ContactDialog/ContactDialog";

const DesktopRejectScreen = ({ reject }) => {
  const classes = useStyles();
  const [contactOpen, setContactOpen] = useState(false);

  const { distributor, note } = useMemo(() => reject || {}, [reject]);

  const { name, billingAddress } = useMemo(
    () => distributor || {},
    [distributor]
  );

  return (
    <Box className={classes.rejectWrap}>
      <ContactDialog
        open={contactOpen}
        supplier={distributor}
        showContactOnly
        onClose={() => setContactOpen(false)}
      />
      <Box className={classes.rejectScreen}>
        <Box className={classes.titleWrap}>
          <Logo className={classes.logo} />
          <Typography fontWeight="500" mt="12px">
            {name}
          </Typography>
          {billingAddress?.city && billingAddress?.state && (
            <Typography fontWeight="300">
              {billingAddress?.city}, {billingAddress?.state}
            </Typography>
          )}
        </Box>
        <Box className={classes.dangerIconWrap}>
          <DangerIcon size="71.74" />
        </Box>
        <Typography className={classes.mainText}>
          {`The supplier has rejected your order direct access`}
        </Typography>
        {!!note && (
          <Typography
            className={classes.mainText}
          >{`Rejection Reason:\n${note}`}</Typography>
        )}
        <StyledButton
          className={classes.contactButton}
          label="Contact Supplier"
          variant="contained"
          fontSize="18px"
          fontWeight="500"
          onClick={() => setContactOpen(true)}
        />
      </Box>
    </Box>
  );
};

DesktopRejectScreen.propTypes = {
  reject: shape({
    approvedPaymentTermsDuplicate: any,
    distributor: object,
    note: string,
    status: string,
  }),
};

export default DesktopRejectScreen;
