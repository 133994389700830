import { useShowProductsWithoutLogIn } from "helpers/hooks";
import { error } from "helpers/notifications";
import { useCallback, useEffect, useState } from "react";
import { getDistributorInfoByStoreNameService } from "services/account";

export const useAppWithoutLogIn = () => {
  const { isShowProductsWithoutLogIn, distributor_name } =
    useShowProductsWithoutLogIn();

  const [state, setState] = useState({
    loading: false,
    distributor: null,
  });

  const handleDistributorInfoByStoreName = useCallback(async (storeName) => {
    try {
      const data = await getDistributorInfoByStoreNameService(storeName);
      setState((prev) => ({ ...prev, distributor: data }));
    } catch (err) {
      error(err.message);
      // eslint-disable-next-line no-console
      console.error(err.message);
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  }, []);

  useEffect(() => {
    if (isShowProductsWithoutLogIn && distributor_name)
      handleDistributorInfoByStoreName(distributor_name);
  }, [
    handleDistributorInfoByStoreName,
    isShowProductsWithoutLogIn,
    distributor_name,
  ]);

  return state;
};
