import { Stack, Typography } from "@mui/material";
import { LargeCheckbox } from "components";
import { NoteIcon } from "components/Icons";
import { DRAWERS } from "constants/drawer";
import { CartContext } from "pages/CartPage/CartPage";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { useStyles } from "./styles";

export const OptionsBlock = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { control, formField } = useContext(CartContext);

  const {
    note: { text },
  } = formField || { note: { text: "" } };

  const handleOpenNoteDrawer = () => {
    dispatch(
      showCurrentDrawerAction({ type: DRAWERS.NOTE_DRAWER, show: true })
    );
  };

  return (
    <Stack>
      <Stack gap="20px">
        <Stack
          direction="row"
          gap="8px"
          alignItems="center"
          onClick={handleOpenNoteDrawer}
        >
          <NoteIcon />
          <Typography className={classes.noteText}>
            {text ? "Edit order note" : "Add order note"}
          </Typography>
        </Stack>

        <Controller
          render={({ field }) => (
            <LargeCheckbox
              size={14}
              label="Notify your sales rep(s)"
              labelSx={{ fontSize: "14px" }}
              checked={!!field.value}
              formSx={{
                ml: 0,
                "& .MuiFormControlLabel-root": {
                  ml: 0,
                },
              }}
              checkboxSx={{
                padding: 0,
                margin: "0 8px 0 1px",
              }}
              {...field}
            />
          )}
          control={control}
          name="notifyRepresentatives"
        />
      </Stack>
    </Stack>
  );
};
