import { bool, number, object, string } from "prop-types";
import { Box, Typography } from "@mui/material";
import { DangerIcon } from "components/Icons";

export const WarningMsgBlock = ({
  sum,
  show,
  stylesWrapper,
  colorIcon,
  text,
  icon,
}) => {
  return (
    <Box
      sx={{
        bgcolor: "#26874B20",
        border: "0.5px solid #26874B75",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        pl: 2,
        gap: 1.25,
        mt: 1,
        py: 1,
        opacity: show ? 1 : 0,
        transition: "all 0.3s ease",
        ...stylesWrapper,
      }}
    >
      {icon ? icon : <DangerIcon fill={colorIcon || "#FF8419"} size={17.74} />}
      <Typography fontSize={15} fontWeight={300} color="#707070">
        {text ? text : `To place order, you must meet $${sum} minimum spend.`}
      </Typography>
    </Box>
  );
};

WarningMsgBlock.propTypes = {
  sum: number,
  show: bool,
  stylesWrapper: object,
  colorIcon: string,
  text: string,
  icon: object,
};
WarningMsgBlock.defaultProps = {
  sum: 0,
  stylesWrapper: {},
  colorIcon: "",
  text: "",
};
