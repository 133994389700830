import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  supBlockWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: "9.6px 22px",
  },

  supCardWrapper: {
    width: "100%",
    height: "78px",
    borderRadius: "4px",
    backgroundColor: "rgba(64, 154, 102, 0.1)",
    display: "flex",
    alignItems: "center",
  },
  supImgWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    paddingLeft: "26px",
    paddingRight: "12px",
  },
  supInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "150px",
    borderRight: "1px solid #D5D9D9",
    paddingRight: "4px",
  },
  supInfoTextName: {
    fontSize: 16,
    color: "#000",
  },
  supInfoTextCompany: {
    fontSize: 16,
    fontWeight: 300,
    color: "#000",
  },
  btnUpdate: {
    height: "30px",
    fontSize: 13,
    color: "#6A6A6A",
  },

  customerTitle: {
    fontSize: 20,
    fontWeight: 500,
    color: "#000",
    padding: "9.6px 0px",
  },
}));

export default useStyles;
