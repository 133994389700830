import { object, string } from "prop-types";

import { Box } from "@mui/material";
import { StyledProductIco } from "../../../../../../components";

export const StyledImageBox = ({ src, wrapper }) => {
  return (
    <Box
      sx={{
        width: "60px",
        minWidth: "60px",
        minHeight: "60px",
        maxWidth: "60px",
        maxHeight: "60px",
        ...wrapper,
      }}
    >
      <StyledProductIco
        src={src}
        styles={{
          objectFit: "contain",
          width: "100%",
          height: "100%",
          borderRadius: "4px",
          border: "1px solid #D5D9D9",
        }}
        placeholderStyles={{
          width: "60px",
          height: "60px",
          wrapperProps: {
            height: "60px",
          },
        }}
      />
    </Box>
  );
};

StyledImageBox.propTypes = { src: string, wrapper: object };
StyledImageBox.defaultProps = { src: "", wrapper: {} };
