export const FilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.473"
      height="10.325"
      viewBox="0 0 11.473 10.325"
    >
      <path
        id="Icon_feather-filter"
        data-name="Icon feather-filter"
        d="M14.473,4.5H3L7.589,9.927v3.752l2.295,1.147v-4.9Z"
        transform="translate(-3 -4.5)"
        fill="#5f6267"
      />
    </svg>
  );
};
