export const cl = {
  wrapper: {
    width: "100%",
    height: "100vh",
    overflowY: "auto",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    // Remove Scrollbar "Jumping"
    padding: { xs: "16px", sm: "8px calc(10px - (100vw - 100%)) 0 0" },
    backgroundColor: { xs: "#ffff", sm: "#F9F8F8" },
  },
  // inputSx: { height: "48px", fontSize: "18px" },
  // labelSx: { fontSize: "18px", top: -5 },
  inputSx: {
    height: "38px",
    fontSize: "15px",
    "& input": { padding: "7px 14px" },
    "&:hover:not(.Mui-focused)": {
      "&& fieldset": {
        borderColor: "rgba(194, 194, 194, 0.5)",
      },
    },
  },
  labelSx: {
    fontSize: 15,
    fontWeight: 300,
  },
  addressFieldsWrapperProps: {
    pb: "16px",
    borderBottom: "1px solid #D5D9D9",
    mb: "25px",
  },
  toggleButtonProps: {
    height: "38px",
    "& .MuiTypography-root": { fontSize: "15px" },
  },
  selectProps: {
    // height: "48px",
    height: "38px",
    // formProps: { size: "large" },
    formProps: { size: "small" },
    // fontSize: "18px",
    fontSize: "17px",
    labelSx: {
      // fontSize: "18px",
      fontSize: "15px",
      fontWeight: 300,
      top: 0,
      color: "#B5B5AC",
    },
  },

  select: {
    "& span": {
      color: "rgba(86, 86, 86, 0.50)",
      fontSize: "14px",
      lineHeight: "20px",
    },

    "& svg": {
      right: 0,
      fill: "#2F3040",
      width: "27px",
      mr: "3px",
      position: "absolute",
      transform: "scale(1)",
      top: "calc(50% - 11px)",
      marginTop: "3px",
    },
    fontWeight: 500,
    color: "black",
    backgroundColor: "rgba(213.42, 213.42, 213.42, 0.16)",
    borderRadius: "8px",
    "& > .MuiSelect-select": {
      fontSize: "14px",
      lineHeight: "20px",
      color: "black",
      padding: "0px 32px 0px 10px !important",
    },

    "& .Mui-error": {
      border: "1px solid red !important",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      height: "48px",
      top: "0px",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  mobileLabelSx: {
    transform: "translate(14px, -17px)",
    color: "rgba(0, 0, 0, 0.6) !important",
    lineHeight: "16px",
    fontWeight: 500,
    fontSize: 12,
  },

  desktopPhoneSelect: {
    borderRadius: "0",
    marginRight: 1,
    "& .MuiSelect-select": {
      minWidth: "50px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: {
        xs: "0 !important",
        sm: "0 1px 0 0 !important",
      },
      borderColor: "#D5D9D9 !important",
    },
  },

  paymentTermsSelect: {
    height: 38,
    "& .MuiInputBase-input": {
      fontSize: 12,
    },
    "& fieldset": {
      border: "0.5px solid #D5D9D9",
    },
    color: "#717171",
  },

  stepTwo: {
    btn: { fontSize: "clamp(12px, 2vh, 20px)", fontWeight: 500 },
  },

  stepThree: {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    thnxText: {
      my: { xs: 0, sm: 2 },
      fontSize: { xs: "18px", sm: "clamp(29px, 4vh, 38px)" },
      fontWeight: { xs: 500, sm: 300 },
      color: { xs: "black", sm: "#545454" },
    },
    secondText: {
      fontSize: { xs: "14px", sm: "clamp(16px, 2.5vh, 20px)" },
      fontWeight: { xs: 500, sm: 600 },
      color: { xs: "#00000060", sm: "#5F6267" },
      mt: { xs: "4px", sm: 1 },
      mb: { xs: 4.5, sm: 3 },
    },
    btn: {
      width: { xs: "100%", sm: "326px" },
      mb: "80px",
      height: { xs: "48px", sm: "40px" },
      fontSize: { xs: "16px", sm: "clamp(12px, 2vh, 20px)" },
      fontWeight: { xs: 600, sm: 500 },
    },
  },
  toggleBtnSx: {
    height: { xs: "48px", sm: "38px" },
    width: { xs: "44px", sm: "33px" },
    borderRadius: { xs: "8px", sm: "4px" },
    borderWidth: { xs: "0px", sm: "1px" },
    backgroundColor: "#F8F8F8",

    "&:hover": {
      backgroundColor: "#F8F8F8 !important",
    },
  },
};
