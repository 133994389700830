import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  LinearProgress,
  Typography,
  linearProgressClasses,
  Stack,
} from "@mui/material";
import { separateNumWithComma } from "helpers/helpers";
import { useMediaDevice } from "hooks/useMediaDevice";
import { bool, number } from "prop-types";
import { useMemo } from "react";

export const SummaryProgress = ({ maxValue, value, hasMinimum }) => {
  const { isMobile } = useMediaDevice();

  const hasMinimumMet = useMemo(() => value >= maxValue, [maxValue, value]);

  return (
    <Box display="flex" alignItems="center" gap={{ xs: "10px", sm: 2 }}>
      <Typography
        noWrap
        fontSize={{ xs: "12px", sm: "clamp(18px, 1.2vw, 24px)" }}
        fontWeight={{ xs: 400, sm: 300 }}
        color={{ xs: "#000000", sm: "#989898" }}
      >
        {hasMinimum ? "Minimum Order" : "No Minimum"}
      </Typography>
      {hasMinimum && (
        <>
          <Box width={{ xs: "50px", sm: "13vw" }}>
            <LinearProgress
              sx={{
                height: { xs: "5px", sm: "10px" },
                maxWidth: "276px",
                borderRadius: "16px",
                border: "0.5px solid #70707050",
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: "#EFEFEF",
                },
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 5,
                  backgroundColor: "#47A06D",
                },
              }}
              value={((value > maxValue ? maxValue : value) / maxValue) * 100}
              variant="determinate"
            />
          </Box>
          {!isMobile && (
            <Box display="flex" alignItems="center" width="24px">
              <CheckCircle
                style={{
                  opacity: hasMinimumMet ? 1 : 0,
                  transition: "all 0.3s ease",
                }}
                color="primary"
              />
            </Box>
          )}
          <Stack
            direction="row"
            flexWrap="nowrap"
            width={{ xs: "calc(100% - 160px)", sm: "fit-content" }}
            gap="3px"
          >
            <Typography
              fontSize={{ xs: 10, sm: 15 }}
              fontWeight={hasMinimumMet ? 600 : 400}
              color={isMobile && !hasMinimumMet ? "#00000040" : "#000"}
              width={{ xs: "fit-content", sm: "104px" }}
              noWrap
              textAlign="end"
              sx={{
                opacity: !isMobile && hasMinimumMet ? 0 : 1,
                transition: "all 0.3s ease",
              }}
            >
              $ {separateNumWithComma(value.toFixed(2) || 0)} /
            </Typography>
            <Typography
              fontSize={{ xs: 10, sm: 15 }}
              fontWeight={{ xs: 600, sm: 400 }}
              color="#000"
              width={{ xs: "fit-content", sm: "54px" }}
              textAlign="end"
              sx={{
                transition: "all 0.3s ease",
              }}
              whiteSpace="nowrap"
            >
              ${separateNumWithComma(maxValue.toFixed(2) || 0)}
            </Typography>
          </Stack>
        </>
      )}
    </Box>
  );
};

SummaryProgress.propTypes = {
  maxValue: number,
  value: number,
  hasMinimum: bool,
};
