import axios from "axios";

export const getCategoriesWithParentsAndChildrenService = async ({
  id,
  distributor_ids,
  manufacturer_ids,
}) => {
  const { data } = await axios.get(`/categories/${id}`, {
    params: { distributor_ids, manufacturer_ids },
  });
  return data;
};

export const getByDistributorAndIdWithParentsAndChildrenService = async ({
  distributorId,
  id,
}) => {
  const { data } = await axios.get(`/categories/${distributorId}/${id}`);
  return data;
};

export const getProductsService = async (params) => {
  const { data } = await axios.get("/users/app-customers/products", { params });
  return data;
};

export const getGroupNameVariantsService = async ({ params }) => {
  const { data } = await axios({
    method: "GET",
    url: "/product-categories/name-variants",
    params,
  });
  return data;
};

export const getProductByIdService = async (id) => {
  const { data } = await axios({
    method: "GET",
    url: `/products/${id}`,
  });
  return data;
};

export const getManufacturerSpecificDiscountsByProductService = async (id) => {
  const { data } = await axios({
    method: "GET",
    url: `/manufacturer-discounts/by-product/${id}`,
  });
  return data;
};

export const getProductsByDistributorService = async ({
  params,
  distributorId,
}) => {
  const { data } = await axios({
    method: "GET",
    url: `/users/app-customers/products-by-distributor/${distributorId}`,
    params,
  });
  return data;
};
