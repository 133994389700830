import { useEffect, useMemo, useState } from "react";
import { func, object, bool, oneOf, number } from "prop-types";
import Cropper from "react-easy-crop";
import { Box, Button, Dialog, Slider } from "@mui/material";

export const CropperComponent = ({
  open,
  onClose,
  file,
  handleSaveImg,
  settings,
  size,
  defaultAspect,
}) => {
  const initialCropState = useMemo(
    () => ({
      imageSrc: "",
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: defaultAspect || 1,
      croppedAreaPixels: {},
    }),
    [defaultAspect]
  );

  const [cropState, setCropState] = useState(initialCropState);

  const { imageSrc, crop, zoom, aspect } = cropState;

  const getSize = useMemo(() => {
    const s = {
      maxWidth: "",
      height: "",
    };
    switch (size) {
      case "small":
        s.maxWidth = "xs";
        s.height = "444px";
        return s;

      default:
        s.maxWidth = "md";
        s.height = "100vh";
        return s;
    }
  }, [size]);

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setCropState((prev) => ({ ...prev, imageSrc: objectUrl }));
    }
    if (!open) {
      setCropState(initialCropState);
      URL.revokeObjectURL(file);
    }
    return () => URL.revokeObjectURL(file);
  }, [file, open, initialCropState]);

  const onCropChange = (crop) => {
    setCropState((prev) => ({ ...prev, crop }));
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCropState((prev) => ({
      ...prev,
      croppedAreaPixels,
    }));
  };

  const onZoomChange = (zoom) => {
    setCropState((prev) => ({ ...prev, zoom }));
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.src = url;
    });

  const getRadianAngle = (degreeValue) => {
    return (degreeValue * Math.PI) / 180;
  };

  const rotateSize = (width, height, rotation) => {
    const rotRad = getRadianAngle(rotation);

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    };
  };

  const getCroppedImg = async (
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    );

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    const res = new Promise((resolve) => {
      canvas.toBlob((blob) => {
        const newFile = new File([blob], file.name, {
          type: blob.type,
        });
        resolve(newFile);
      }, file?.type);
    });

    handleSaveImg(res);
    onClose();
  };

  return (
    <Dialog open={open} fullWidth maxWidth={getSize.maxWidth}>
      <Box
        width="100%"
        height={getSize.height}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Box>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            showGrid={false}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
            {...settings}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 3,
            py: 3,
            px: 3,
          }}
        >
          <Button
            sx={{
              "&.MuiButton-root": {
                backgroundColor: "#FFF !important",
              },
              height: 28,
            }}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => onZoomChange(zoom)}
            classes={{ container: "slider" }}
            sx={{ "& span": { boxShadow: "0 0 5px 5px #FFF" } }}
          />
          <Button
            variant="contained"
            sx={{ height: 28 }}
            onClick={() =>
              getCroppedImg(cropState.imageSrc, cropState.croppedAreaPixels)
            }
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

CropperComponent.propTypes = {
  open: bool,
  onClose: func,
  file: object,
  handleSaveImg: func,
  settings: object,
  size: oneOf(["small", ""]),
  defaultAspect: number,
};
CropperComponent.defaultProps = {
  open: false,
  onClose: () => {},
  file: {},
  handleSaveImg: () => {},
  settings: {},
  size: "regular",
  defaultAspect: 1,
};
