import { Dialog, DialogContent, IconButton } from "@mui/material";
import { bool, func, object } from "prop-types";
import { GoogleMap } from "../GoogleMap/GoogleMap";
import { CrossIcon } from "../Icons";

export const MapPopup = ({
  isOpen,
  handleClose,
  address,
  handleSetAddress,
  customer,
}) => {
  return (
    <Dialog
      open={isOpen}
      sx={{
        "& > .MuiDialog-container > .MuiPaper-root": {
          maxWidth: "900px",
        },
      }}
    >
      <IconButton
        sx={{ position: "absolute", top: 0, right: 0 }}
        onClick={handleClose}
      >
        <CrossIcon />
      </IconButton>
      <DialogContent sx={{ width: "900px", height: "800px", pt: "30px" }}>
        <GoogleMap
          handleSetAddress={handleSetAddress}
          customer={customer}
          address={address}
        />
      </DialogContent>
    </Dialog>
  );
};

MapPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  handleSetAddress: func,
  address: object,
  customer: object,
};

MapPopup.defaultProps = {
  isOpen: false,
  address: null,
};
