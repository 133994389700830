import React from "react";
import { shape, string, object, array, number } from "prop-types";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

export const ViewSettingsBlock = ({ item, control }) => {
  const { id, name, type, items } = item;

  return (
    <Grid
      sx={{
        border: "1px solid #D5D9D9",
        borderRadius: "4px",
      }}
      container
      item
    >
      <Grid
        xs={12}
        item
        component={Typography}
        fontSize={19}
        fontWeight={500}
        color="clearBlack"
        pl={2}
        py={1}
      >
        {name}
      </Grid>
      <Grid sx={{ pl: 2, pt: 1, pb: 1.8 }} xs={12} item container>
        {type === "radio" && (
          <Grid xs={12} item>
            <Controller
              render={({ field }) => (
                <FormControl>
                  <RadioGroup {...field}>
                    {items?.map((item) => {
                      return (
                        <FormControlLabel
                          key={item?.id}
                          label={
                            <Typography
                              sx={{
                                fontSize: 18,
                                color: "#8E8E8E",
                              }}
                            >
                              {item?.name}
                            </Typography>
                          }
                          value={item?.id}
                          control={<Radio />}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              )}
              name={`items[${id}].selected`}
              control={control}
            />
          </Grid>
        )}
        {type === "checkbox" && (
          <Grid xs={12} item>
            <FormControl>
              <FormGroup>
                {items?.map((item, i) => (
                  <Controller
                    key={item?.id}
                    render={({ field }) => (
                      <FormControlLabel
                        label={
                          <Typography
                            sx={{
                              fontSize: 18,
                              color: "#8E8E8E",
                            }}
                          >
                            {item?.name}
                          </Typography>
                        }
                        control={<Checkbox checked={item?.checked} />}
                        {...field}
                      />
                    )}
                    name={`items[${id}].items[${i}].checked`}
                    control={control}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

ViewSettingsBlock.propTypes = {
  index: number,
  item: shape({
    name: string,
    items: array,
  }),
  control: object,
};
ViewSettingsBlock.defaultProp = {
  item: {
    name: "",
    items: [],
  },
};
