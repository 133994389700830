import { func, string } from "prop-types";
import { Grid, IconButton, Typography } from "@mui/material";
import { CrossBigIcon } from "components/Icons";

export const HeaderBlock = ({ title, onClose }) => {
  return (
    <Grid mb="20px" container>
      <Grid xs={6} item>
        <Typography fontSize={30} color="#707070">
          {title}
        </Typography>
      </Grid>
      <Grid textAlign="end" xs={6} item>
        <IconButton onClick={onClose}>
          <CrossBigIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

HeaderBlock.propTypes = { title: string, onClose: func };
HeaderBlock.defaultProps = { title: "", onClose: () => {} };
