import React from "react";

export const SignInIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 20.25a1.5 1.5 0 0 0 1.5-1.5V5.25a1.5 1.5 0 0 0-1.5-1.5h-6A1.5 1.5 0 0 0 9 5.25V9a.75.75 0 1 1-1.5 0V5.25a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v13.5a3 3 0 0 1-3 3h-6a3 3 0 0 1-3-3V15A.75.75 0 0 1 9 15v3.75a1.5 1.5 0 0 0 1.5 1.5zm-5.03-4.72a.75.75 0 0 1 0-1.06l1.72-1.72H2.25a.75.75 0 0 1 0-1.5h10.94l-1.72-1.72a.75.75 0 0 1 1.06-1.06l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 0 1-1.06 0"
        fill="#0F172A"
      />
    </svg>
  );
};
