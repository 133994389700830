import { array, bool, number, shape, string } from "prop-types";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { Box, Grid, Stack, Typography } from "@mui/material";

import { OrdersTabHeader } from "./Table/OrdersTabHeader";
import { OrdersTabItem } from "./Table/OrdersTabItem";
import { getFormattedDate, separateNumWithComma } from "helpers/helpers";
import { Loader } from "components";
import {
  createdBy,
  handleViewOrder,
  setTotal,
} from "pages/OrdersPage/Orders.helpers";

export const OrdersTab = ({ ordersState, timeZone }) => {
  const { loading, list, count } = ordersState || {};

  const navigate = useNavigate();

  return loading ? (
    <Loader isLoading={loading} />
  ) : (
    <Grid pt={1} xs={12} item>
      <OrdersTabHeader />
      <Stack
        sx={{
          border: "1px solid #D5D9D9", // check theme
          borderWidth: "0 1px 1px 1px",
          borderRadius: "0 0 4px 4px",
        }}
      >
        {!list?.length && !loading ? (
          <Box
            sx={{
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontSize={18}>No orders found</Typography>
          </Box>
        ) : (
          list.map(
            ({
              id,
              customId,
              createdAt,
              totalQuantity,
              totalRawAmount,
              totalDiscountValue,
              deliveryFee,
              paymentStatus,
              totalDelivered,
              distributor,
              createdType,
              orderStatus,
            }) => (
              <OrdersTabItem
                key={id}
                id={id}
                customId={customId?.customId}
                supplier={distributor?.name}
                // ordered={moment(getFormattedDate(createdAt, timeZone)).format(
                ordered={moment(getFormattedDate(createdAt, timeZone)).format(
                  "MMM D, YY [at] h:mm a"
                )}
                isCanceledOrder={orderStatus === "CANCELED"}
                createdBy={createdBy(createdType, distributor?.accountType)}
                totalItems={totalQuantity}
                total={`$${separateNumWithComma(
                  setTotal(totalRawAmount, totalDiscountValue, deliveryFee)
                )}`}
                payment={paymentStatus}
                fulfilled={`${totalDelivered} of ${totalQuantity} items`}
                isFulfilled={totalDelivered === totalQuantity}
                handleViewOrder={() =>
                  handleViewOrder({ orderId: id, navigate })
                }
              />
            )
          )
        )}
      </Stack>
      <Typography
        p="12px 0 0 20px"
        fontSize="17px"
        fontWeight="300"
        color="#6E6E6E"
      >
        Showing {count} orders
      </Typography>
    </Grid>
  );
};
OrdersTab.propTypes = {
  ordersState: shape({
    loading: bool,
    list: array,
    count: number,
  }),
  timeZone: string,
};
OrdersTab.defaultProps = {
  ordersState: {
    loading: false,
    list: [],
    count: 0,
  },
  timeZone: "",
};
