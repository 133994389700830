/* eslint-disable react/prop-types */
import React from "react";

export const CheckboxIconChecked = ({ size = 24, color = "#47a06d" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <rect id="Rectangle_2581" width="24" height="24" rx="4" fill={color} />
      <path
        id="path"
        d="M1440.355,4366.756l3.8,3.8,8-8"
        transform="translate(-1434.355 -4353.669)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
