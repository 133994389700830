import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "sticky",
    top: 0,
    backgroundColor: "#ffff",
    minHeight: "64px",
    zIndex: 1001,
    width: "100%",
  },

  nav: {
    padding: "14px 16px",
    display: "flex",
    minHeight: "64px",
    alignItems: "center",
    justifyContent: "space-between",
  },

  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },

  logoWrapper: {
    width: "172px",
    maxWidth: "172px",
    height: "25px",
    maxHeight: "25px",
    display: "flex",
    alignItems: "center",
  },

  navIcon: {
    height: "25px",
    position: "relative",
    transition: "0.5s ease-in-out",
    cursor: "pointer",
  },

  helpText: {
    fontSize: "12px",
    color: "#409A65",
    textDecoration: "underline",
  },

  minOrderText: {
    fontSize: "12px",
    opacity: 0.4,
    color: "black",
    fontWeight: 400,
  },

  supText: {
    display: "flex",
    flexDirection: "column",
    textAlign: "end",
  },

  burgerMenu: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },

  menuWrapper: {
    padding: "16px",
    width: "100%",
    zIndex: 100,
    gap: "20px",
    display: "flex",
    flexDirection: "column",
    transition: "all 0.5s",
    overflowY: "auto",
    boxSizing: "border-box",

    "& .MuiList-root": {
      padding: "0px",
    },
  },

  menuContent: {
    gap: "16px",
    marginTop: "8px",
    display: "flex",
    flexDirection: "column",
    "& li": {
      padding: 0,
      width: "100%",
    },

    "&:not(:last-child)": {
      paddingBottom: "36px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
    },
  },

  menu: {
    position: "fixed",
    top: 0,
    width: "100vw",
    height: "100vh",
    transform: "translateX(-300%)",
    transition: "all 0.5s",
    left: 0,
    backgroundColor: "#ffff",
    overflowY: "auto",
    boxSizing: "border-box",
  },

  activeMenu: {
    transition: "all 0.5s",
    transform: "translateX(0)",
  },

  menuContentHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #0000000D",
    padding: "16px",
  },
}));

export default useStyles;
