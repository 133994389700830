import { makeStyles } from "@mui/styles";

export const classes = {
  badgeIcon: {
    "& span": {
      color: "#409A65",
      backgroundColor: "#ffff",
    },
  },
};

const useStyles = makeStyles(() => ({
  requestButton: {
    height: "50px",
    borderRadius: "12px",

    "& .MuiTypography-root": {
      color: "#fff",
      fontSize: "16px",
      fontWeight: "600",
    },
  },
}));

export default useStyles;
