import { bool, string } from "prop-types";
import { Badge, Box, IconButton, Stack, Typography } from "@mui/material";
import { CartBtnIcon, StyledArrowIcon } from "components/Icons";
import { useNavigate } from "react-router-dom";
import { useCartCounter } from "pages/ProductsPage/useCartCounter";

export const cl = {
  wrapper: {
    position: "fixed",
    top: 0,
    width: "100%",
    height: "44px",
    backgroundColor: "#ffff",
    zIndex: 100,
    display: "flex",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.05)",
    justifyContent: "space-between",
    alignItems: "center",
  },

  backArrowBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "56px",

    "& svg": {
      width: "24px",
      height: "24px",
      transform: "rotate(90deg)",
    },
  },
};

export const BackBtnHeader = ({ title, showCart }) => {
  const navigate = useNavigate();

  const { cartCounter, disabledCartBtn } = useCartCounter();

  return (
    <Stack sx={cl.wrapper} direction="row">
      <Box display="flex" alignItems="center">
        <Box sx={cl.backArrowBlock}>
          <IconButton sx={{ p: 0, width: "24px" }} onClick={() => navigate(-1)}>
            <StyledArrowIcon fill="#0F172A" />
          </IconButton>
        </Box>

        <Typography fontSize={18} fontWeight={600} color="#2F3040" noWrap>
          {title}
        </Typography>
      </Box>

      {showCart && (
        <Box mr={3}>
          <IconButton
            disabled={disabledCartBtn}
            sx={{ p: 0, width: "24px", height: "24px" }}
            onClick={() => navigate("/cart")}
          >
            <Badge
              badgeContent={cartCounter}
              sx={{
                "& span": {
                  color: "#FFF",
                  backgroundColor: "#409A65",
                },
              }}
            >
              <CartBtnIcon fill="#409A65" />
            </Badge>
          </IconButton>
        </Box>
      )}
    </Stack>
  );
};

BackBtnHeader.propTypes = {
  title: string,
  showCart: bool,
};
BackBtnHeader.defaultProps = {
  title: "",
  showCart: false,
};
