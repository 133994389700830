import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { showCurrentDrawerAction } from "redux/actions/uiState";

import { DRAWERS } from "constants/drawer";

export const useFilterDrawer = ({
  onSave,
  onStateClear,
  currentFilterElement,
}) => {
  const dispatch = useDispatch();

  const [selectedFilter, setSelectedFilter] = useState("");

  const handleSelectFilter = (filter) => setSelectedFilter(filter);

  const onClose = useCallback(
    () =>
      dispatch(
        showCurrentDrawerAction({ type: DRAWERS.FILTER_DRAWER, show: false })
      ),
    [dispatch]
  );

  const handleSaveFilter = () => {
    onClose();
    setSelectedFilter("");
    onSave();
  };

  const handleIsActive = ({ state, id, isMultiple = false }) => {
    if (!isMultiple) return state?.id === id;

    const stateIds = state.map((item) => item.id || item._id);
    return !!stateIds.includes(id);
  };

  const handleCheckItem = ({ setState, state, item, isMultiple = false }) => {
    if (isMultiple) {
      const itemId = item.id || item._id;
      const stateIds = state.map((item) => item.id || item._id);

      if (stateIds.includes(itemId)) {
        const filteredState = state.filter(({ id }) => id !== itemId);
        return setState(filteredState);
      }
      return setState([...state, item]);
    }
    return setState(item);
  };

  const handleClearAction = useCallback(() => {
    if (selectedFilter) {
      const { setState, defaultValue } = currentFilterElement[selectedFilter];
      return setState(defaultValue);
    }

    onClose();
    setSelectedFilter("");

    onStateClear();
    return;
  }, [currentFilterElement, onClose, onStateClear, selectedFilter]);

  return {
    onClose,
    handleSelectFilter,
    selectedFilter,
    handleClearAction,
    handleSaveFilter,
    handleIsActive,
    handleCheckItem,
  };
};
