export const defaultValues = { items: [] };

export const MINIMUM_FILTERS = [
  { name: "Show all", value: "" },
  { name: "No minimum", value: "no" },
  { name: "$100 or less", value: "100" },
  { name: "$200 or less", value: "200" },
  { name: "$300 or less", value: "300" },
];

export const SORT_BY = [
  {
    name: "A-Z ",
    sort: "sort_by_name",
    sortValue: "asc",
    value: "sort_by_a_z",
  },
  {
    name: "Z-A",
    sort: "sort_by_name",
    sortValue: "desc",
    value: "sort_by_z_a",
  },
  {
    name: "High To Low",
    sort: "sort_by_price",
    sortValue: "desc",
    value: "price_high_to_low",
  },
  {
    name: "Low To High",
    sort: "sort_by_price",
    sortValue: "asc",
    value: "price_low_to_high",
  },
  {
    name: "Newest Items",
    sort: "sort_by_date",
    sortValue: "desc",
    value: "newest_items",
  },
  {
    name: "Best Sellers",
    sort: "sort_by_top_sales",
    sortValue: "desc",
    value: "best_sellers",
  },
];

export const PRODUCTS_VARIATIONS_HEADER_ITEMS = [
  {
    id: "0",
    name: "",
    size: 0.3,
  },
  {
    id: "1",
    name: "PRODUCTS",
    size: 5.7,
  },
  {
    id: "2",
    name: "ITEMS / CASE",
    size: 1.8,
  },
  {
    id: "3",
    name: "CASE QTY",
    size: 1.5,
  },
  {
    id: "4",
    name: "PRICE",
    size: 1.4,
  },
  {
    id: "5",
    name: "CASE PRICE",
    size: 1.3,
    style: {
      textAlign: "right",
      pr: "2%",
    },
  },
];
