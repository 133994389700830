import { Box } from "@mui/system";
import { array, bool, object, func } from "prop-types";
import { useWatch } from "react-hook-form";

import { PlusIcon } from "components/Icons";
import TableHeader from "../../../TableHeader";

import { ContactsItem } from "./components";
import { StyledButton } from "components";

const ContactsTable = ({
  contacts,
  isEdit,
  control,
  handleAddContact,
  handleRemoveContact,
  handleChangeDefault,
  setValue,
}) => {
  const formField = useWatch({ control });

  return (
    <Box>
      <TableHeader
        title="Contacts"
        isEdit={isEdit}
        addElement={
          <StyledButton
            label="Add contact"
            color="grey"
            sx={{
              color: "#444444",
              "& .MuiButton-startIcon": { m: "0 6px 0 0" },
            }}
            startIcon={<PlusIcon stroke="#eeeeee" width="10" height="10" />}
            onClick={handleAddContact}
          />
        }
      />
      <Box>
        {contacts.map((contact, index) => (
          <ContactsItem
            key={contact.id}
            contact={formField.contacts[index]}
            {...{
              // contact
              index,
              isEdit,
              control,
              handleRemoveContact,
              handleChangeDefault,
              formField,
              setValue,
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

ContactsTable.propTypes = {
  contacts: array,
  isEdit: bool,
  control: object,
  handleAddContact: func,
  handleRemoveContact: func,
  handleChangeDefault: func,
  setValue: func,
};

export default ContactsTable;
