import React from "react";
import { number, object, string } from "prop-types";

import { Grid, Typography } from "@mui/material";

export const ReturnsTabHeaderItem = ({ name, size, style }) => {
  return (
    <Grid
      sx={{
        ...style,
        fontSize: "12px",
        color: "#000",
      }}
      xs={size}
      item
      component={Typography}
    >
      {name}
    </Grid>
  );
};
ReturnsTabHeaderItem.propTypes = {
  name: string,
  size: number,
  style: object,
};
ReturnsTabHeaderItem.defaultProps = {
  name: "",
  size: 2,
  style: {},
};
