import { object } from "prop-types";
import { useWatch } from "react-hook-form";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { MainLogoIcon } from "components";
import { photoUrl } from "helpers/helpers";
import { useCallback, useEffect, useState } from "react";
import { useMediaDevice } from "hooks/useMediaDevice";

export const RegistrationHeader = ({ control }) => {
  const { isMobile } = useMediaDevice();
  const formField = useWatch({ control });

  const {
    regParams: { photoParam },
    distributorData,
    loadingDistributorData,
  } = formField;

  const [loadingImg, setLoadingImg] = useState(true);
  const [imgBlock, setImgBlock] = useState(null);

  const setLogo = useCallback(
    (url) => {
      if (url && url !== "null") {
        const img = new Image();
        img.src = url;
        img.onload = () => setLoadingImg(false);
        return (
          <Box
            sx={{
              width: "100%",
              height: { xs: "60px", sm: "100px" },
            }}
          >
            <Box
              width="100%"
              height="100%"
              sx={{ objectFit: "contain" }}
              component="img"
              src={img.src}
              alt="Logo"
            />
          </Box>
        );
      }

      if (!loadingDistributorData) {
        setLoadingImg(false);
        return <MainLogoIcon variant="primary" width={isMobile ? 109 : 242} />;
      }
    },
    [loadingDistributorData, isMobile]
  );

  useEffect(() => {
    setImgBlock(
      setLogo(photoParam || photoUrl(distributorData?.brandLogo?.fileName))
    );
  }, [distributorData?.brandLogo?.fileName, photoParam, setLogo]);

  return (
    <Grid
      sx={{ width: { xs: "100%", sm: "470px" }, justifyContent: "center" }}
      container
    >
      <Box minHeight={{ xs: "100%", sm: "240px" }}>
        <Box
          mt={{ xs: 0, sm: 3 }}
          mb={3}
          height="100px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {loadingImg ? <CircularProgress /> : imgBlock}
        </Box>
        <Typography
          sx={{
            pb: { xs: 1, sm: 2 },
            justifyContent: "center",
            fontSize: { xs: "18px", sm: "clamp(14px, 2.5vh, 23px)" },
            fontWeight: 500,
            color: { xs: "black", sm: "#5F6267" },
          }}
          color="clearBlack"
          component={Grid}
          xs={12}
          item
          container
        >
          Create Wholesale Account
        </Typography>
      </Box>
    </Grid>
  );
};

RegistrationHeader.propTypes = {
  control: object,
};
