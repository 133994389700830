import {} from "react";
import { any, func, string } from "prop-types";
import { Box, Typography } from "@mui/material";
import { HeaderItem, WalletItem } from "./components";

export const WalletTab = ({
  cardState,
  handleConfirmDeleteCard,
  customerId,
  handleSave,
  handleSetDefault,
}) => {
  return (
    <Box mt={2}>
      <HeaderItem />
      {cardState?.length > 0 ? (
        <Box mt={1.5} gap={1.5}>
          {cardState?.map((item, key) => (
            <WalletItem
              cardState={item}
              key={key}
              handleConfirmDeleteCard={() => handleConfirmDeleteCard(item)}
              customerId={customerId}
              handleSave={handleSave}
              handleSetDefault={handleSetDefault}
            />
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            marginTop: "30px",
          }}
        >
          <Typography sx={{ color: "#707070" }} fontSize={20}>
            No credit cards added
          </Typography>
        </Box>
      )}
    </Box>
  );
};

WalletTab.propTypes = {
  cardState: any,
  handleConfirmDeleteCard: func,
  handleSetDefault: func,

  customerId: string,
  handleSave: func,
};
WalletTab.defaultProps = {};
