export const cl = {
  leftSide: {
    mainWrapper: {
      display: "flex",
      height: "80px",
    },
    arrowWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "60px",
      minWidth: "60px",
    },
    logoWrapper: {
      display: "flex",
      alignItems: "center",
      width: "100px",
      minWidth: "100px",
    },
    logo: { objectFit: "contain", width: "80px", height: "60px" },
    nameWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "160px",
      minWidth: "100px",
    },
    dividerWrapper: {
      display: "flex",
      width: "34px",
      minWidth: "34px",
      alignItems: "center",
    },
    divider: { borderLeft: `1px solid #D5D9D9`, height: "60%" },
    addressWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "200px",
      minWidth: "200px",
    },
  },
  searchSide: {
    mainWrapper: { display: "flex", alignItems: "center" },
    searchInput: {
      height: "40px",
      fontSize: "15px",
      fontWeight: 300,
      backgroundColor: "#FBFBFB",
      "& > fieldset": {
        borderColor: "rgb(213,217,217)!important",
        borderWidth: "1px!important",
      },
    },
  },
  rightSide: {
    btnWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    btn: {
      height: "40px",
      px: 1.8,
      fontSize: 15,
    },
    productsWrapper: {
      pr: 3,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "144px",
      fontSize: 19,
    },
  },
  collapseSide: {
    descriptionWrapper: {
      px: 4,
      pt: 1,
      maxHeight: "146px",
      overflow: "overlay",
    },
    description: {
      fontSize: 20,
      fontWeight: 300,
    },
    mainWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      pb: 3,
    },
    termsWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dividerWrapper: {
      width: "42px",
      height: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    divider: {
      borderLeft: `1px solid #D5D9D9`,
      width: "1px",
      height: "18px",
    },
    link: { cursor: "pointer", fontSize: 19, pr: "22px" },
  },
};
