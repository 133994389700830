import { number, object } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { ContentBlock } from "./components/ContentBlock";

import { useStyles } from "../ContactDrawer/styles";

import { Drawer } from "@mui/material";
import { DrawerHeader } from "components/ContactDrawer/components/DrawerHeader";
import { ContentHeader } from "./components/ContentHeader";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { DRAWERS } from "constants/drawer";
import { FulfillmentHeader } from "./components/FulfillmentHeader";
import { FulfillmentContentBlock } from "./components/FulfillmentContentBlock";
import { useCallback, useEffect } from "react";

export const ViewOrderInfoDrawer = ({ order, totalQuantity }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isDrawerOpened = useSelector(
    ({ uiState }) => uiState.viewPaymentInfoDrawer
  );

  const isFulfillmentDrawerOpened = useSelector(
    ({ uiState }) => uiState.viewFulfillmentDrawer
  );

  const isOpen = isDrawerOpened || isFulfillmentDrawerOpened;

  const isPaymentDrawer = isDrawerOpened;

  const onClose = useCallback(() => {
    if (isPaymentDrawer)
      return dispatch(
        showCurrentDrawerAction({
          type: DRAWERS.VIEW_PAYMENT_INFO_DRAWER,
          show: false,
        })
      );
    return dispatch(
      showCurrentDrawerAction({
        type: DRAWERS.VIEW_FULFILLMENT_DRAWER,
        show: false,
      })
    );
  }, [dispatch, isPaymentDrawer]);

  useEffect(() => {
    return () => {
      onClose();
    };
  }, [onClose]);

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        className: classes.drawerWrapper,
        sx: { minHeight: "57svh" },
      }}
    >
      <DrawerHeader
        {...{
          handleClose: onClose,
          titleElement: (
            <>
              {isFulfillmentDrawerOpened ? (
                <FulfillmentHeader deliveryStatus={order.deliveryStatus} />
              ) : (
                <ContentHeader
                  paymentStatus={order?.paymentStatus}
                  termsName={order?.paymentTermsDuplicate?.name}
                />
              )}
            </>
          ),
        }}
      />
      {isFulfillmentDrawerOpened ? (
        <FulfillmentContentBlock
          orderDeliveries={order.orderDeliveries}
          totalQuantity={totalQuantity}
        />
      ) : (
        <ContentBlock
          payments={order.orderPayments}
          orderRefunds={order.orderRefunds}
        />
      )}
    </Drawer>
  );
};

ViewOrderInfoDrawer.propTypes = {
  order: object,
  totalQuantity: number,
};
