export const cl = {
  productName: {
    color: "rgba(0, 0, 0, 0.80)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
  },

  productDescription: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "rgba(0, 0, 0, 0.80)",
  },

  price: {
    color: "black",
    fontSize: "14px",
    fontWeight: 600,
    wordWrap: "break-word",
  },

  unitPrice: {
    color: "rgba(0, 0, 0, 0.40)",
    fontSize: "8px",
    fontFamily: "Inter",
    fontWeight: "400",
    wordWrap: "break-word",
  },

  quantityBlock: {
    width: "fit-content",
    height: "100%",
    minWidth: "35px",
    p: "8px",
    borderRadius: "8px",
    border: "1px rgba(0, 0, 0, 0.05) solid",
    justifyContent: "center",
    alignItems: "center",
  },

  quantityNumber: {
    color: "#2F3040",
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "capitalize",
    lineHeight: "18.9px",
  },
};
