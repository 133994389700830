import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useOrderDirectConfirm = () => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const normalizePhoneNumber = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        // return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`; // (123) 56-77
        return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
      // return `(${currentValue.slice(0, 3)}) ${currentValue.slice(  // (123) 456-7789
      return `${currentValue.slice(0, 3)}-${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };

  const {
    businessName,
    fedTax,
    billingAddress,
    shippingAddress,
    baseUser,
    phone,
    website,
    contacts: userContacts,
  } = useMemo(() => currentUser, [currentUser]);

  const billingAddressMain = useMemo(
    () =>
      `${
        billingAddress?.appartement ? billingAddress?.appartement + ", " : ""
      } ${billingAddress?.street ?? ""}`,
    [billingAddress?.appartement, billingAddress?.street]
  );

  const billingAddressSub = useMemo(
    () =>
      `${billingAddress?.city}, ${billingAddress?.state} ${billingAddress?.zip}`,
    [billingAddress?.city, billingAddress?.state, billingAddress?.zip]
  );

  const shippingAddressMain = useMemo(
    () =>
      `${
        shippingAddress?.appartement ? shippingAddress?.appartement + ", " : ""
      } ${shippingAddress?.street ?? ""}`,
    [shippingAddress?.appartement, shippingAddress?.street]
  );

  const shippingAddressSub = useMemo(
    () =>
      `${shippingAddress?.city}, ${shippingAddress?.state} ${shippingAddress?.zip}`,
    [shippingAddress?.city, shippingAddress?.state, shippingAddress?.zip]
  );

  const businessEmail = useMemo(() => baseUser?.email ?? "", [baseUser?.email]);

  const businessPhone = useMemo(
    () => (phone ? normalizePhoneNumber(phone) : ""),
    [phone]
  );

  const contacts = useMemo(
    () =>
      [...(userContacts || [])].sort((x, y) =>
        x.defaultContact === y.defaultContact ? 0 : x ? -1 : 1
      ),
    [userContacts]
  );

  const preparedContacts = useMemo(() => {
    const res = [];

    for (let i = 0; i < (contacts.length > 3 ? 3 : contacts.length); i++) {
      res.push({
        name: i === 0 ? "Owner" : contacts[i].name,
        main: contacts[i].phone,
        sub: contacts[i].email,
      });
    }

    return res;
  }, [contacts]);

  return {
    preparedContacts,
    businessPhone,
    businessEmail,
    shippingAddressSub,
    shippingAddressMain,
    billingAddressSub,
    billingAddressMain,
    businessName,
    fedTax,
    website,
    contacts,
  };
};
