import { useContext, useEffect, useMemo, useState } from "react";
import { func, object } from "prop-types";
import { Controller, useWatch } from "react-hook-form";
import { PasswordCriteria, StyledTextField, StyledTooltip } from "components";

import { Context } from "../RegistrationBody/RegistrationBody";
import {
  Box,
  Button,
  Typography,
  Link as MuiLink,
  InputAdornment,
} from "@mui/material";

import { cl } from "../../../LoginPage/LoginPage.styles";
import { cl as regCl } from "../../RegistrationPage.styles";
import { PASSWORD_CRITERIA } from "components/PasswordCriteria/PasswordCriteria.constants";
import { useDebounce } from "helpers/hooks";
import { checkRegistrationEmailService } from "services/distributors";
import { Link } from "react-router-dom";
import { error } from "helpers/notifications";
import { useMediaDevice } from "hooks/useMediaDevice";
import { ClosePinIcon } from "components/Icons";

export const StepOne = () => {
  const { isMobile } = useMediaDevice();
  const { control, isLoading, errors, trigger, setValue } = useContext(Context);
  const [userAlreadyRegistered, setUserAlreadyRegistered] = useState(false);

  const formField = useWatch({ control });
  const { businessEmail, password, confirmNewPassword, distributorData } =
    formField;

  const hasDistributor = useMemo(() => {
    return !!distributorData?.id;
  }, [distributorData?.id]);

  const isDisabled = useMemo(() => {
    if (!businessEmail || !!errors?.businessEmail) return true;
    if (hasDistributor && userAlreadyRegistered) {
      return true;
    }

    return (
      isLoading ||
      PASSWORD_CRITERIA.some((criteria) => {
        const completed = criteria.condition({
          password,
          confirmPassword: confirmNewPassword,
        });
        return !completed;
      })
    );
  }, [
    businessEmail,
    confirmNewPassword,
    errors?.businessEmail,
    hasDistributor,
    isLoading,
    password,
    userAlreadyRegistered,
  ]);

  const debouncedEmail = useDebounce(businessEmail, 500);

  const hasEmailAlreadyRegistered = async (email) => {
    try {
      const { exists } = await checkRegistrationEmailService(email);
      if (exists) {
        setUserAlreadyRegistered(true);
      } else {
        setUserAlreadyRegistered(false);
      }
    } catch (err) {
      if (err.response?.data?.message) error(err.response.data.message);
      // eslint-disable-next-line no-console
      console.error(
        err.response?.data?.message || err.message || "Something went wrong!"
      );
    }
  };

  useEffect(() => {
    if (debouncedEmail && !errors?.businessEmail) {
      hasEmailAlreadyRegistered(debouncedEmail);
    }
    if (!debouncedEmail) setUserAlreadyRegistered(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEmail]);

  const BODY_FIELDS = [
    {
      name: "businessEmail",
      label: isMobile ? "" : "Business email",
      placeholder: "Business email",
    },
    {
      name: "password",
      type: "password",
      label: isMobile ? "" : "Password",
      placeholder: "Password",
      triggerName: "confirmNewPassword",
    },
    {
      name: "confirmNewPassword",
      type: "password",
      label: isMobile ? "" : "Confirm new password",
      placeholder: "Confirm new password",
      triggerName: "password",
    },
  ];

  return (
    <>
      {BODY_FIELDS.map(
        ({ name, placeholder, triggerName, ...itemProps }, index) => (
          <Controller
            key={index}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                inputSx={regCl.inputSx}
                labelSx={regCl.labelSx}
                size="small"
                error={error?.message || ""}
                {...(isMobile && {
                  InputProps: {
                    sx: { ...cl.inputSx, ...(error && cl.errorInputSx) },
                    placeholder,
                    ...(isMobile &&
                      field.value && {
                        endAdornment: (
                          <InputAdornment
                            sx={cl.inputIcon}
                            onClick={() => setValue(name, "")}
                            position="end"
                          >
                            <ClosePinIcon />
                          </InputAdornment>
                        ),
                      }),
                  },
                })}
                {...itemProps}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  if (triggerName) trigger([triggerName]);
                }}
              />
            )}
            name={name}
            control={control}
          />
        )
      )}

      {/* Password criteria */}
      <Box display="flex" alignItems="center" gap="8px">
        <PasswordCriteria
          password={password}
          confirmPassword={confirmNewPassword}
        />
      </Box>

      <StyledTooltip
        title={
          <Box>
            <Typography fontSize={12}>
              This email is already registered.
            </Typography>
            <Typography fontSize={12}>
              Click{" "}
              <MuiLink
                component={Link}
                color="primary"
                to={`/login/${formField.distributorData?.storeName}`}
              >
                here
              </MuiLink>{" "}
              to login.
            </Typography>
          </Box>
        }
        placement="top"
        arrow
        open={userAlreadyRegistered || false}
      >
        <Box component="span" sx={{ mt: { xs: "32px", sm: "2vh" } }}>
          <Button
            fullWidth
            disabled={isDisabled}
            sx={{ ...cl.loginBtn, mt: 0 }}
            variant="contained"
            form="registration-form"
            type="submit"
          >
            {isLoading ? "Loading..." : "Next Step"}
          </Button>
        </Box>
      </StyledTooltip>
    </>
  );
};

StepOne.propTypes = { control: object, onSubmit: func };
