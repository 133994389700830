import { string } from "prop-types";

import { Box, Typography } from "@mui/material";

export const SupplierNameBlock = ({ name }) => {
  return (
    <Box>
      <Typography fontSize={24} fontWeight={400} color="#000">
        {name}
      </Typography>
    </Box>
  );
};

SupplierNameBlock.propTypes = { name: string };
SupplierNameBlock.defaultProps = { name: "" };
