import * as React from "react";

export const RejectIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12.073}
    height={12.073}
    viewBox="0 0 12.073 12.073"
    {...props}
  >
    <path
      data-name="Union 283"
      d="M.219 11.853a.748.748 0 0 1 0-1.058l4.762-4.761L.223 1.276A.747.747 0 0 1 1.28.219l4.757 4.759L10.8.22a.748.748 0 0 1 1.057 1.058L7.095 6.035l4.76 4.761a.748.748 0 0 1-1.055 1.056l-4.762-4.76-4.762 4.761a.747.747 0 0 1-1.057 0Z"
      fill="#ff877c"
    />
  </svg>
);
