import { Grid, Typography } from "@mui/material";

export const HeaderItem = () => {
  return (
    <Grid
      sx={{
        height: "39px",
        borderWidth: "0.5px 0.5px 0 0.5px",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
        borderRadius: "4px 4px 0 0",
        bgcolor: "#F7F7F7",
        alignItems: "center",
      }}
      container
    >
      <Grid pl={2.5} xs={9} item>
        <Typography fontSize={12} color="#6A6A6A">
          ITEMS
        </Typography>
      </Grid>
      <Grid xs={3} item>
        <Typography fontSize={12} color="#6A6A6A">
          QUANTITY
        </Typography>
      </Grid>
    </Grid>
  );
};
