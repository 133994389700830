import { useContext } from "react";
import { Box } from "@mui/material";
import {
  LocationsTab,
  MobileTitle,
  SummaryTab,
  UserLocationDrawer,
} from "./components";
import { ProfileContext } from "pages/ProfilePage/NewProfile";
import { ContactDrawer, MobileTabs } from "components";

export const MobileViewProfile = () => {
  const {
    tabValue,
    tabs,
    setTabValue,
    editLocation,
    formField,
    control,
    setValue,
    handleDeleteLocation,
    setEditLocationIndex,
    editContact,
    onDeleteContact,
    onSaveContact,
    handleSaveLocation,
    resetFunc,
    handleSubmit,
    setExpandedDetailsAccordion,
    handleChangeDefaultLocation,
  } = useContext(ProfileContext);

  const currentTabView = {
    [tabs[0].value]: <SummaryTab />,
    [tabs[1].value]: <LocationsTab />,
  };

  return (
    <Box
      sx={{
        height: "calc(100svh - 64px)",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
          width: "0px !important",
        },
      }}
    >
      <ContactDrawer
        {...{
          handleAddContact: onSaveContact,
          editContact,
          contacts: formField.contacts,
          deleteContact: onDeleteContact,
        }}
      />

      <UserLocationDrawer
        locationIndex={editLocation}
        handleSave={handleSaveLocation}
        {...{
          formField,
          control,
          setValue,
          handleDeleteLocation,
          handleChangeDefault: handleChangeDefaultLocation,
          setEditLocationIndex,
          resetFunc,
          handleSubmit,
        }}
      />

      <MobileTitle />

      <MobileTabs
        {...{
          tabs,
          tabValue,
          setTabValue,
          resetFunc: () => {
            resetFunc();
            setExpandedDetailsAccordion(false);
          },
        }}
      />

      {currentTabView[tabValue]}
    </Box>
  );
};
