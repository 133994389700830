import { GoogleAutocompleteField, StyledTextField } from "components";
import { Box, MenuItem, Typography, Link } from "@mui/material";
import { bool, func, string, object, array } from "prop-types";
import { Controller } from "react-hook-form";
import { CustomCurtainComponent } from "components";
import { LocationIcon, ShevronIcon } from "components/Icons";
import { StyledSelect } from "components";
import { StyledButton } from "components";
import { StyledToggleButton } from "components";
import { sliceCountry } from "helpers/helpers";
import { getAddressComponents } from "./AddressField.helpers";
import { CANADAS_STATE_NAMES, STATE_NAMES } from "utils/constants";
import { useMediaDevice } from "hooks/useMediaDevice";
import { LabeledTextField } from "components/TextFields/TextFields";

export const AddressField = ({
  isEdit,
  setValue,
  address,
  googleAddress,
  setGoogleAddress,
  fieldName,
  control,
  addressToggled,
  onToggle,
  onMapOpen,
  autocompleteProps,
  label,
  wrapperProps,
  disabled,
  error,
  errorCoordinates,
  noToggleLabel,
  toggleButtonProps,
  addressFieldsWrapperProps,
  mapToggled,
  handleOpenDuplicates,
  duplicates,
  curtainProps,
  textFieldProps,
  selectProps,
  hideUnit,
  formSx,
  unitLabel,
  country,
  typingTrigger,
  clearErrors,
}) => {
  const { isMobile } = useMediaDevice();

  const handleDisableField = (field) => {
    if (googleAddress && !googleAddress?.[fieldName]?.[field]) return false;
    return disabled || isEdit || !!googleAddress?.[fieldName]?.[field];
  };

  const AddressInput = isMobile ? LabeledTextField : StyledTextField;

  const countryList = country === "CA" ? CANADAS_STATE_NAMES : STATE_NAMES;

  return (
    <Box {...wrapperProps}>
      <Box
        sx={{
          display: "flex",
          gap: { xs: "2px", sm: "8px" },
        }}
      >
        <Box display="flex" width="100%" position="relative">
          <Controller
            render={({ field, fieldState: { error } }) => {
              return (
                <GoogleAutocompleteField
                  country={country}
                  disabled={disabled}
                  autoComplete="new-password"
                  onPlaceSelected={(place) => {
                    const { street, state, city, zip } =
                      getAddressComponents(place);
                    const address = {
                      formatted_address: sliceCountry(place.formatted_address),
                      lat: place.geometry.location.lat(),
                      lng: place.geometry.location.lng(),
                      street,
                      state,
                      city,
                      zip,
                    };
                    if (setGoogleAddress)
                      setGoogleAddress((prev) => ({
                        ...prev,
                        [fieldName]: { ...address },
                      }));

                    if (clearErrors) clearErrors(fieldName);

                    setValue(fieldName, address, { shouldDirty: true });
                  }}
                  noErrorMessage
                  formSx={formSx}
                  size="small"
                  error={
                    error || errorCoordinates
                      ? errorCoordinates
                        ? "Validate address by selecting from the drop down"
                        : error.message
                      : ""
                  }
                  label={label}
                  {...field}
                  onChange={(e) => {
                    setValue(
                      fieldName,
                      {
                        ...address,
                        formatted_address: e.target.value,
                      },
                      { shouldDirty: true }
                    );
                    if (typingTrigger) typingTrigger();
                  }}
                  {...textFieldProps}
                  {...autocompleteProps}
                />
              );
            }}
            name={`${fieldName}.formatted_address`}
            control={control}
          />
          {!errorCoordinates && !!duplicates?.length && (
            <Typography
              component={Link}
              fontSize="11px"
              onClick={() => handleOpenDuplicates(duplicates, fieldName)}
              sx={{
                position: "absolute",
                bottom: -19,
                left: 110,
                cursor: "pointer",
              }}
            >
              View here
            </Typography>
          )}
          {!hideUnit && (
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  label="Unit"
                  disabled={disabled}
                  formSx={{
                    maxWidth: "15%",
                    ...formSx,
                  }}
                  labelSx={{ left: -3, top: -1 }}
                  autoComplete="new-password"
                  {...textFieldProps}
                  InputProps={{
                    ...textFieldProps?.InputProps,
                    sx: {
                      height: "32px",
                      fontSize: "12px",
                      ...textFieldProps?.InputProps?.sx,
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#47A06D!important",
                        },
                      },
                      "& fieldset": {
                        borderRadius: "0 4px 4px 0",
                        borderLeft: "none",
                      },
                    },
                  }}
                  error={error?.message || !!duplicates?.length || ""}
                  {...field}
                  value={field.value || ""}
                />
              )}
              name={`${fieldName}.appartement`}
              control={control}
            />
          )}
        </Box>
        {onToggle && (
          <StyledToggleButton
            value={fieldName}
            selected={addressToggled}
            onChange={onToggle}
            error={error}
            {...toggleButtonProps}
            sx={{
              ...toggleButtonProps.sx,
              "& svg": {
                transition: "all 0.3s",
                transform: addressToggled && "rotate(180deg)",
              },
            }}
          >
            <Box display="flex" alignItems="center">
              {!noToggleLabel && (
                <Typography
                  fontSize="10px"
                  sx={{ textTransform: "none" }}
                  mr="5px"
                >
                  Details
                </Typography>
              )}
              <ShevronIcon
                width="9"
                height="18"
                color={isMobile ? "rgba(0, 0, 0, 0.60)" : "#B5B5AC"}
              />
            </Box>
          </StyledToggleButton>
        )}
        {onMapOpen && (
          <StyledButton
            sx={{
              color: "#9C9C94",
              minWidth: "26px",
              width: "26px",
              height: "32px",
              borderColor: errorCoordinates
                ? "#F2545B !important"
                : mapToggled
                ? "#409A65"
                : "#D5D9D9",
              pt: "8px",
              backgroundColor: errorCoordinates
                ? "rgba(236,66,51,0.15)"
                : mapToggled && "rgba(64, 154, 101, 0.1)",
            }}
            disabled={disabled}
            label={
              <LocationIcon
                fill={mapToggled ? "#409A65" : "#707070"}
                height="13.33"
                width="8.33"
              />
            }
            variant="outlined"
            onClick={onMapOpen}
          />
        )}
      </Box>
      <CustomCurtainComponent
        toggled={addressToggled}
        curtainHeight="178px"
        display="flex"
        flexDirection="column"
        gap={{ xs: "27px", sm: "17px" }}
        pt={addressToggled ? { xs: "22px", sm: "20px" } : 0}
        {...curtainProps}
      >
        <Controller
          render={({ field, fieldState: { error } }) => (
            <AddressInput
              label="Street Address *"
              error={error?.message || ""}
              disabled={handleDisableField("street")}
              {...field}
              value={field.value || ""}
              autoComplete="new-password"
              {...textFieldProps}
              inputProps={{ placeholder: "Street Address" }}
              formSx={formSx}
              onChange={(e) => {
                field?.onChange(e);
                if (typingTrigger) typingTrigger();
              }}
            />
          )}
          name={`${fieldName}.street`}
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <AddressInput
              label={unitLabel}
              error={error?.message || ""}
              disabled={disabled}
              autoComplete="new-password"
              {...field}
              value={field.value || ""}
              {...textFieldProps}
              inputProps={{ placeholder: unitLabel }}
              formSx={formSx}
              onChange={(e) => {
                field?.onChange(e);
                if (typingTrigger) typingTrigger();
              }}
            />
          )}
          name={`${fieldName}.appartement`}
          control={control}
        />
        <Box display="flex" gap="9.4px" {...addressFieldsWrapperProps}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <AddressInput
                label="City *"
                error={error?.message || ""}
                disabled={handleDisableField("city")}
                autoComplete="new-password"
                {...field}
                value={field.value || ""}
                {...textFieldProps}
                inputProps={{ placeholder: "City" }}
                formSx={formSx}
                onChange={(e) => {
                  field?.onChange(e);
                  if (typingTrigger) typingTrigger();
                }}
              />
            )}
            name={`${fieldName}.city`}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledSelect
                noErrorMessage
                label="State *"
                fullWidth
                disabled={handleDisableField("state")}
                error={error?.message || ""}
                {...field}
                value={field.value || ""}
                {...selectProps}
                sx={{
                  ...selectProps.sx,
                  ...(!!error && {
                    border: {
                      xs: "1px solid rgba(228, 30, 58, 0.60) !important",
                      sm: "#FF3737 !important",
                    },
                  }),
                }}
                formSx={formSx}
                onChange={(e) => {
                  field?.onChange(e);
                  if (typingTrigger) typingTrigger();
                }}
              >
                {Object.keys(countryList).map((key) => (
                  <MenuItem key={key} value={key}>
                    {countryList[key]}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
            name={`${fieldName}.state`}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <AddressInput
                label="ZIP Code *"
                disabled={handleDisableField("zip")}
                error={error?.message || ""}
                autoComplete="new-password"
                {...field}
                value={field.value || ""}
                {...textFieldProps}
                inputProps={{ placeholder: "ZIP Code" }}
                formSx={formSx}
                onChange={(e) => {
                  field?.onChange(e);
                  if (typingTrigger) typingTrigger();
                }}
              />
            )}
            name={`${fieldName}.zip`}
            control={control}
          />
        </Box>
      </CustomCurtainComponent>
    </Box>
  );
};

AddressField.propTypes = {
  isEdit: bool,
  setValue: func,
  address: object,
  googleAddress: object,
  setGoogleAddress: func,
  autocompleteProps: object,
  fieldName: string,
  control: object,
  onToggle: func,
  addressToggled: bool,
  onMapOpen: func,
  label: string,
  wrapperProps: object,
  disabled: bool,
  error: bool,
  errorCoordinates: bool,
  noToggleLabel: bool,
  toggleButtonProps: object,
  addressFieldsWrapperProps: object,
  mapToggled: bool,
  handleOpenDuplicates: func,
  duplicates: array,
  curtainProps: object,
  textFieldProps: object,
  selectProps: object,
  hideUnit: bool,
  formSx: object,
  unitLabel: string,
  country: string,
  typingTrigger: func,
  clearErrors: func,
};

AddressField.defaultProps = {
  country: "US",
  duplicates: [],
  textFieldProps: {},
  selectProps: {},
  hideUnit: false,
  unitLabel: "Apt, Suite, Unit, Floor etc",
};

export default AddressField;
