import React from "react";

export const ConversationsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.87"
      height="25.716"
      viewBox="0 0 26.87 25.716"
    >
      <g id="Group_6908" data-name="Group 6908" transform="translate(2 -3.8)">
        <path
          id="Union_145"
          data-name="Union 145"
          d="M4.2,25.715a.742.742,0,0,1-.6-1.17,9.29,9.29,0,0,0,1.4-2.9,3.7,3.7,0,0,0,.147-.77,12.45,12.45,0,0,1-3.707-3.928A10.278,10.278,0,0,1,0,11.7,10.921,10.921,0,0,1,3.963,3.4,14.43,14.43,0,0,1,13.434,0a14.431,14.431,0,0,1,9.472,3.4,10.922,10.922,0,0,1,3.963,8.3A10.922,10.922,0,0,1,22.906,20a14.431,14.431,0,0,1-9.472,3.4,15.054,15.054,0,0,1-3.21-.37,8.094,8.094,0,0,1-5.988,2.678ZM1.486,11.7A9.742,9.742,0,0,0,6.33,19.918a.74.74,0,0,1,.345.628,6.33,6.33,0,0,1-.241,1.485,10.107,10.107,0,0,1-.808,1.936A8,8,0,0,0,9.4,21.784a.746.746,0,0,1,.75-.252,13.89,13.89,0,0,0,3.28.391c6.59,0,11.95-4.584,11.95-10.219S20.024,1.486,13.434,1.486,1.486,6.069,1.486,11.7Zm5.6,4.244a.783.783,0,1,1,0-1.566H19.781a.783.783,0,0,1,0,1.566Zm10.962-3.461a.783.783,0,0,1,0-1.566h4.039a.783.783,0,0,1,0,1.566Zm-13.269,0a.783.783,0,0,1,0-1.566h9.807a.783.783,0,1,1,0,1.566ZM7.089,9.026a.783.783,0,1,1,0-1.566H19.781a.783.783,0,0,1,0,1.566Z"
          transform="translate(-2 3.8)"
          fill="#a5a5a5"
        />
      </g>
    </svg>
  );
};
