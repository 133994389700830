import { func, string } from "prop-types";
import { Box, InputAdornment, MenuItem } from "@mui/material";
import { SearchIcon } from "../../../../../components/Icons";
import { DISTRIBUTOR_SORT_PARAMS } from "../../../VendorsPage.constants";
import { StyledSelect, StyledTextField } from "components";

export const VendorsTab = ({ search, setSearch, sortBy, setSortBy }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "64px",
        pt: "18px",
        gap: 1,
      }}
    >
      <Box width="90%">
        <StyledTextField
          inputSx={{
            pr: "20px",
            height: "40px",
            fontSize: "14px",
            fontWeight: 300,
            backgroundColor: "#FFFFFF",
            "& > fieldset": {
              borderColor: "rgb(213,217,217)!important",
              borderWidth: "1px!important",
            },
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search by supplier's name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <StyledSelect
        label=""
        height="40px"
        color="#707070"
        formSx={{
          width: "220px",
          "& svg": { fill: "#000000" },
          backgroundColor: "#FFFFFF",
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#D5D9D9!important",
            borderWidth: "1px !important",
          },
        }}
        startAdornment={
          <InputAdornment position="start">Sort By: </InputAdornment>
        }
        fontSize="14px"
        displayEmpty
        value={sortBy}
        onChange={(v) => {
          const value = v.target.value;
          setSortBy(value);
        }}
      >
        {DISTRIBUTOR_SORT_PARAMS.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </StyledSelect>
    </Box>
  );
};
VendorsTab.propTypes = {
  search: string,
  setSearch: func,
  sortBy: string,
  setSortBy: func,
};

VendorsTab.defaultProps = {
  search: "",
  setSearch: () => {},
  sortBy: "",
  setSortBy: () => {},
};
