import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  drawerWrapper: {
    padding: "20px 16px 105px",
    background: "white",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "20px",
    display: "inline-flex",
    overflow: "hidden",
    width: "100%",
  },
  headerWrapper: {
    alignSelf: "stretch",
    justifyContent: "space-between",
    alignItems: "center",
    display: "inline-flex",
  },
  itemWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 10,

    "& path": {
      fill: "#0F172A",
    },
  },
  itemText: {
    opacity: 0.4,
    color: "black",
    fontSize: "16px",
    fontWeight: 400,
    width: "calc(100% - 30px)",
    textDecoration: "none",
  },
  fakeLine: {
    alignSelf: "stretch",
    height: 0,
    border: "1px rgba(0, 0, 0, 0.05) solid",
  },
  title: {
    color: "black",
    fontSize: 18,
    fontWeight: 500,
  },
}));

export default useStyles;
