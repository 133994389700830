import { Grid, Typography } from "@mui/material";

export const HeaderListBlock = () => {
  return (
    <Grid
      sx={{
        backgroundColor: "#EEEEEE",
        border: "1px solid #D5D9D9",
        borderBottom: "none",
        height: "34px",
        alignItems: "center",
        pr: "10px",
      }}
      container
    >
      <Grid pl="5.5%" xs={9.86} item>
        <Typography fontSize={12} color="#6A6A6A">
          PRODUCT
        </Typography>
      </Grid>

      <Grid xs={2.14} item>
        <Typography fontSize={12} color="#6A6A6A">
          PRICE
        </Typography>
      </Grid>
    </Grid>
  );
};
