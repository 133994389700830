import { Chip } from "@mui/material";
import { string, bool, func } from "prop-types";
import useStyles from "./styles";

export const CategoryChip = ({ title, isActive, onClick }) => {
  const classes = useStyles({ isActive });
  return (
    <Chip
      label={title}
      className={classes.categoryChip}
      variant="outlined"
      onClick={onClick}
    />
  );
};

CategoryChip.propTypes = {
  title: string,
  isActive: bool,
  onClick: func,
};
CategoryChip.defaultProps = {
  title: "",
  isActive: false,
  onClick: () => {},
};
