import axios from "axios";

const uploadFile = async (file, path, config = {}) => {
  if (!file) return new Promise(null);
  const formData = new FormData();
  formData.append(path === "product-photos" ? "file[]" : "file", file);
  return axios
    .post(path || "representatives/profile-photo", formData, config)
    .then((res) => res.data);
};

const uploadGenerateService = (data) => {
  return axios({
    method: "POST",
    // eslint-disable-next-line no-undef
    baseURL: process.env.REACT_APP_UPLOAD,
    url: "/generateUploadPayload",
    data,
  }).then((res) => res.data);
};

const uploadPhotoService = ({ file, url, headers, config = {} }) => {
  if (!file) return new Promise(null);

  return axios({ method: "PUT", headers, url, data: file, ...config }).then(
    (res) => res.data
  );
};

const checkUploadPhotoStatusService = (data) => {
  return axios({
    method: "POST",
    url: "/photos/check-upload-status",
    data,
  }).then((res) => res.data);
};

const removePhotoService = ({ data, isGroupContentId }) => {
  return axios({
    method: "DELETE",
    url: isGroupContentId ? "/photos/remove/gallery" : "/photos/remove/images",
    data,
  }).then((res) => res.data);
};

export {
  uploadFile,
  uploadGenerateService,
  uploadPhotoService,
  checkUploadPhotoStatusService,
  removePhotoService,
};

export default {
  uploadFile,
  uploadGenerateService,
  uploadPhotoService,
  checkUploadPhotoStatusService,
  removePhotoService,
};
