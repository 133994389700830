import { useState } from "react";
import { object, func, any, string } from "prop-types";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import { PenIcon, TrashSolidIcon } from "components/Icons";
import EditPaymentPopup from "pages/CartPage/components/EditPaymentPopup/EditPaymentPopup";

export const WalletDetailsBlock = ({
  billingAddress,
  handleConfirmDeleteCard,
  cardState,
  customerId,
  handleSave,
  handleSetDefault,
}) => {
  const [editPaymentOpen, setEditPaymentOpen] = useState(false);
  const [editCard, setEditCard] = useState(null);
  return (
    <>
      <EditPaymentPopup
        isOpen={editPaymentOpen}
        customerId={customerId}
        handleClose={() => setEditPaymentOpen(false)}
        card={editCard}
        handleSave={handleSave}
      />
      <Grid
        sx={{
          backgroundColor: "#FBFBFB",
          borderTop: "0.5px solid #D5D9D9",
          borderRadius: "0 0 4px 4px",
          pt: 1,
          pb: 2,
        }}
        container
      >
        <Grid xs={9} item>
          <Box ml={3.6}>
            <Typography fontSize={19} fontWeight={400} color="#363531">
              Billing address
            </Typography>
            <Typography fontSize={19} fontWeight={300} color="#363531">
              {billingAddress?.address?.city}
            </Typography>
            <Typography fontSize={19} fontWeight={300} color="#363531">
              {billingAddress?.address?.line1}
            </Typography>
          </Box>

          <Box
            sx={{
              ml: 3.6,
            }}
          >
            <FormControlLabel
              control={<Radio />}
              checked={cardState?.defaultMethod}
              label={
                <Typography fontSize={18} fontWeight={300} color="#000000">
                  Set as primary payment method
                </Typography>
              }
              onChange={() => handleSetDefault(cardState)}
            />
          </Box>
        </Grid>

        <Grid pr={2.6} xs={3} item>
          <Box
            sx={{
              height: "36px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Button
              sx={{
                fontSize: 17,
                color: "#888888",
                height: "32px",
                "& .MuiButton-startIcon": { m: "0 8px 0 -4px" },

                "&:hover": {
                  borderColor: "#000",
                  bgcolor: "#D5D9D930",
                },
              }}
              startIcon={<PenIcon width="14.72" height="14.72" />}
              onClick={() => {
                setEditCard(cardState);
                setEditPaymentOpen(true);
              }}
            >
              Edit
            </Button>
            <Divider sx={{ height: "20px" }} orientation="vertical" />
            <Button
              sx={{
                fontSize: 17,
                color: "#888888",
                height: "32px",
                "& .MuiButton-startIcon": { m: "0 8px 0 -4px" },
                "&:hover": {
                  borderColor: "#000",
                  bgcolor: "#D5D9D930",
                },
              }}
              startIcon={<TrashSolidIcon />}
              onClick={handleConfirmDeleteCard}
            >
              Delete
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

WalletDetailsBlock.propTypes = {
  billingAddress: object,
  handleConfirmDeleteCard: func,
  cardState: any,
  customerId: string,
  handleSetDefault: func,
  handleSave: func,
};
WalletDetailsBlock.defaultProps = {};
