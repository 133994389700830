import { useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";
import { bool, func, object, string } from "prop-types";
import { Box, Button, InputAdornment, Stack, Typography } from "@mui/material";
import { PasswordCriteria, StyledTextField } from "components";
import { ClosePinIcon } from "components/Icons";
import { PASSWORD_CRITERIA } from "components/PasswordCriteria/PasswordCriteria.constants";

import { cl as loginCl } from "../../LoginPage/LoginPage.styles";

export const MobileRestorePassword = ({
  logo,
  store,
  hasPasswordUpdated,
  formField,
  trigger,
  control,
  loading,
  setValue,
  handleSubmit,
  onSubmit,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="100%"
      height="92vh"
    >
      {/* Distributor Logo */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "50px",
          mb: "20px",
        }}
      >
        {logo ? logo?.logo : <Box height={store ? "116px" : "67px"} />}
      </Box>

      {hasPasswordUpdated ? (
        <>
          {/* Title */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "18px",
            }}
          >
            <Typography fontSize={18} fontWeight={500} color="black">
              Thank you!
            </Typography>
          </Box>

          {/* Subtitle */}

          <Typography
            mt="10px"
            fontSize={16}
            color="#5F6267"
            lineHeight="28px"
            textAlign="center"
          >
            Your password has been <br />
            successfully updated.
          </Typography>
          <Button
            fullWidth
            sx={{
              mt: "50px",
              fontSize: "16px",
              fontWeight: 600,
              height: "48px",
            }}
            variant="contained"
            onClick={() => navigate(store ? `/login/${store}` : "/login")}
          >
            {loading ? "Loading" : "Sign in"}
          </Button>
        </>
      ) : (
        <Stack width="100%" gap="12px" alignItems="center" maxWidth="420px">
          <Typography fontSize={18} fontWeight={500} color="black">
            Forgot password
          </Typography>

          <Stack gap="8px" width="100%">
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  fullWidth
                  type="password"
                  size="medium"
                  InputProps={{
                    sx: {
                      ...loginCl.inputSx,
                      ...(error && loginCl.errorInputSx),
                    },
                    autoComplete: "off",
                    placeholder: "New password",
                    ...(field.value && {
                      endAdornment: (
                        <InputAdornment
                          sx={loginCl.inputIcon}
                          onClick={() => setValue("password", "")}
                          position="end"
                        >
                          <ClosePinIcon />
                        </InputAdornment>
                      ),
                    }),
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  error={error ? error.message : ""}
                  autoComplete="off"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    if (formField?.confirmPassword)
                      trigger(["confirmPassword"]);
                  }}
                />
              )}
              name="password"
              control={control}
            />

            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  fullWidth
                  type="password"
                  size="medium"
                  InputProps={{
                    sx: {
                      ...loginCl.inputSx,
                      ...(error && loginCl.errorInputSx),
                    },
                    placeholder: "Confirm new password",
                    ...(field.value && {
                      endAdornment: (
                        <InputAdornment
                          sx={loginCl.inputIcon}
                          onClick={() => setValue("confirmPassword", "")}
                          position="end"
                        >
                          <ClosePinIcon />
                        </InputAdornment>
                      ),
                    }),
                    autoComplete: "off",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  error={error ? error.message : ""}
                  autoComplete="off"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    if (formField?.password) trigger(["password"]);
                  }}
                />
              )}
              name="confirmPassword"
              control={control}
            />
          </Stack>

          <Stack width="100%" mb="20px">
            <PasswordCriteria
              password={formField.password}
              confirmPassword={formField.confirmPassword}
            />
          </Stack>

          <Button
            disabled={
              loading ||
              PASSWORD_CRITERIA.some((criteria) => {
                const { password, confirmPassword } = formField;
                const completed = criteria.condition({
                  password,
                  confirmPassword,
                });
                return !completed;
              })
            }
            fullWidth
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              height: "48px",
              mt: "18px",
            }}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {loading ? "Loading" : "Confirm"}
          </Button>
        </Stack>
      )}
    </Box>
  );
};

MobileRestorePassword.propTypes = {
  logo: object,
  store: string,
  loading: bool,
  hasPasswordUpdated: bool,
  formField: object,
  trigger: func,
  control: object,
  setValue: func,
  handleSubmit: func,
  onSubmit: func,
};
