import { element, string } from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContext } from "react";
import { ProfileContext } from "pages/ProfilePage/NewProfile";

export const ProfileAccordion = ({ title, children }) => {
  const { handleChangeAccordion, expandedDetailsAccordion } =
    useContext(ProfileContext);

  return (
    <Accordion
      expanded={expandedDetailsAccordion === title}
      onChange={handleChangeAccordion(title)}
      sx={{
        borderTop: "1px solid #00000010",
        borderRadius: 0,
        "&:before": { display: "none" },
        "&:first-of-type": {
          borderRadius: 0,
        },
        "&:last-of-type": {
          borderRadius: 0,
        },
      }}
      elevation={0}
      disableGutters
    >
      <AccordionSummary
        sx={{
          height: "52px",
          px: 0,
          [`& .${accordionSummaryClasses.content}`]: {
            flexGrow: 0,
            width: "100%",
          },
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-profile-content"
        id="panel-profile-header"
      >
        <Typography fontSize={14} fontWeight={600} color="#000">
          {title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
};

ProfileAccordion.propTypes = {
  title: string,
  children: element,
};
ProfileAccordion.defaultProps = {
  title: "",
};
