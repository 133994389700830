import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  contactButton: {
    padding: "4px",
    "&.Mui-disabled path": {
      fill: "rgba(0, 0, 0, 0.26)",
    },
  },

  contactButtonWrap: {
    cursor: "pointer",
  },
}));

export default useStyles;
