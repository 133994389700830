import React from "react";

// eslint-disable-next-line react/prop-types
export const LockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.601"
      height="12.778"
      viewBox="0 0 11.601 12.778"
    >
      <g
        id="Group_8818"
        data-name="Group 8818"
        transform="translate(-560.729 -489.913)"
      >
        <g
          id="Icon_feather-lock"
          data-name="Icon feather-lock"
          transform="translate(561.229 490.413)"
        >
          <path
            id="Path_10236"
            data-name="Path 10236"
            d="M5.678,16.5h8.245A1.178,1.178,0,0,1,15.1,17.678V21.8a1.178,1.178,0,0,1-1.178,1.178H5.678A1.178,1.178,0,0,1,4.5,21.8V17.678A1.178,1.178,0,0,1,5.678,16.5Z"
            transform="translate(-4.5 -11.2)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_10237"
            data-name="Path 10237"
            d="M10.5,8.3V5.945a2.945,2.945,0,1,1,5.889,0V8.3"
            transform="translate(-8.144 -3)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <circle
          id="Ellipse_1256"
          data-name="Ellipse 1256"
          cx="0.944"
          cy="0.944"
          r="0.944"
          transform="translate(565.586 497.701)"
        />
      </g>
    </svg>
  );
};
