import { Box, Divider, Typography } from "@mui/material";
import { BuyMoreIcon } from "components/Icons";

export const DiscountHeader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 115px 0 80px",
      }}
    >
      <Box mt={4} display="flex" alignItems="center" justifyContent="normal">
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 400,
            color: "#000000",
          }}
        >
          Discount
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            height: "22px",
            m: "8px 0 2px 12px",
          }}
        />

        <Box ml={2} justifyContent="normal" display="flex" alignItems="center">
          <BuyMoreIcon />
          <Typography
            sx={{
              ml: 1,
              mb: "2px",
              fontSize: "22px",
              fontWeight: 400,
              color: "#000000",
            }}
          >
            Buy more, earn more
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
