/* eslint-disable react/prop-types */
export const CartBtnIcon = ({ fill = "#fff", ...props }) => {
  return (
    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.397 15.317q-1.018 0-1.558-.56-.53-.571-.675-1.57L4.43 1.338H.7a.57.57 0 0 1-.415-.177A.6.6 0 0 1 .12.734.586.586 0 0 1 .701.142h3.79q.624 0 .842.239.23.228.291.685l1.787 11.935q.072.54.374.83.3.292.779.292h12.121q.24 0 .406.176.176.167.176.426a.56.56 0 0 1-.177.426.55.55 0 0 1-.405.166zM6.56 11.651l-.042-1.195H20.52q.5 0 .769-.301.27-.3.343-.831l.82-5.412h-16.9l-.03-1.205h17.45q.384 0 .602.208a.68.68 0 0 1 .218.53 2 2 0 0 1-.041.363l-.863 5.651q-.155 1.029-.695 1.61-.53.582-1.58.582zm2.919 8.673a1.58 1.58 0 0 1-1.413-.81 1.55 1.55 0 0 1-.218-.81 1.65 1.65 0 0 1 .8-1.413q.364-.208.83-.208.666 0 1.143.468.478.477.478 1.152t-.478 1.143a1.56 1.56 0 0 1-1.142.478m9.525 0q-.666 0-1.153-.478a1.54 1.54 0 0 1-.478-1.143 1.6 1.6 0 0 1 .218-.82q.229-.375.592-.592.375-.208.82-.208.686 0 1.154.468.477.477.477 1.152t-.477 1.143a1.55 1.55 0 0 1-1.153.478"
        fill={fill}
      />
    </svg>
  );
};
