import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  drawerWrapper: {
    background: "white",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "inline-flex",
    overflow: "hidden",
    width: "100vw",
    padding: "20px 16px 61px",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  headerWrapper: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    display: "inline-flex",
    gap: "20px",
    marginBottom: "20px",
  },
  actionBtn: {
    fontSize: "14px",
    fontWeight: 400,
    border: "none !important",
    padding: 0,
    minWidth: "50px",

    "&:hover": {
      border: "none",
    },
  },
}));

export const cl = {
  textInput: {
    fontSize: 13,
    fontWeight: 300,
    padding: "11px 9px",
    borderRadius: "8px",

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.05)",
    },
  },
};
