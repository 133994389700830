export const cl = {
  actionBtn: {
    width: "50%",
    height: "49px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.10)",
    borderRadius: "12px",
    color: "white",

    "span.MuiTypography-root": {
      fontSize: "16px",
      fontWeight: 600,
    },
  },
};
