import { SET_PAGINATION } from "../actions/settings";

const initialState = {
  pagination: {
    currentPage: 1,
    allPages: null,
    resultsPerPage: 50,
  },
};

const settingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...payload } };

    default:
      return state;
  }
};

export default settingsReducer;
