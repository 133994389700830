import { useMemo, useRef, useState } from "react";
import { array, func, object, string } from "prop-types";
import { Box, Chip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { FilterMenu, FilterSearchTextField, StyledButton } from "components";
import { normalizeSnakeCaseString } from "helpers/helpers";
import { FilterIcon } from "components/Icons";

const useStyles = makeStyles(() => ({
  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    height: "28px",
  },
}));

export const SearchBlock = ({
  handleSearch,
  searchInput,
  handleApplyFilter,
  switches,
  handleDeleteFilter,
  filterFields,
  selectMatchData,
}) => {
  const classes = useStyles();

  const filterAnchor = useRef();

  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const filterChipKeys = useMemo(
    () => Object.keys(filterFields).filter((key) => filterFields[key]?.value),
    [filterFields]
  );

  const getChipLabel = (key) => {
    const icon = filterFields[key]?.icon;
    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          // color="groundLighter.main"
        >
          {normalizeSnakeCaseString(filterFields[key]?.label || key)}:{" "}
          {!icon && (
            <span style={{ color: "#5F6368", marginTop: !!icon && "5px" }}>
              {normalizeSnakeCaseString(
                filterFields[key]?.name || filterFields[key]
              )}
            </span>
          )}
        </Typography>
        {icon}
      </Box>
    );
  };

  return (
    <Box mt={2.5}>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          display: "flex",
          borderWidth: "0.5px",
          borderStyle: "solid",
          borderColor: "#d5d9d9",
          borderRadius: "4px",
          alignItems: "center",
          padding: "0px 14px 0px 14px",
          height: "67px",
          gap: "11px",
        }}
      >
        <FilterSearchTextField
          formSx={{ minWidth: "380px" }}
          placeholder="Search by ID, customer name or address"
          value={searchInput}
          onChange={handleSearch}
          fullWidth
          customAdornment={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                width: "100%",
                justifyContent: "flex-end",
                gap: 0.5,
              }}
            >
              {filterChipKeys.map((key) => (
                <Chip
                  sx={{ display: "inline-flex" }}
                  key={key}
                  className={classes.filterChip}
                  size="small"
                  label={getChipLabel(key)}
                  deleteIcon={
                    <CloseIcon size="15" style={{ fill: "#707070" }} />
                  }
                  onMouseDown={(e) => e.stopPropagation()}
                  onDelete={() => handleDeleteFilter(key)}
                />
              ))}
            </Box>
          }
        />

        <StyledButton
          sx={{
            height: "39px",
            width: "100%",
            maxWidth: "74px",
            border: "0.5px solid #D5D9D9",
            "& .MuiButton-startIcon": {
              ml: 0,
            },
          }}
          fontSize="15px"
          label="Filter"
          startIcon={
            filterChipKeys.length ? (
              <Typography
                sx={{
                  fontSize: "9px!important",
                  color: "#ffffff !important",
                  backgroundColor: "#47A06D",
                  borderRadius: "50%",
                  width: "15px",
                  height: "15px",
                }}
              >
                {filterChipKeys.length}
              </Typography>
            ) : (
              <FilterIcon />
            )
          }
          variant="outlined"
          ref={filterAnchor}
          color="edit"
          onClick={() => setFilterMenuOpen(true)}
        />
        <FilterMenu
          anchorEl={filterAnchor.current}
          open={filterMenuOpen}
          onClose={() => setFilterMenuOpen(false)}
          filterFields={filterFields}
          switches={switches}
          handleApplyFilter={(switchesState, temporaryFields) => {
            handleApplyFilter(switchesState, temporaryFields);
            setFilterMenuOpen(false);
          }}
          selectMatchData={selectMatchData}
        />
      </Box>
    </Box>
  );
};

SearchBlock.propTypes = {
  handleSearch: func,
  searchInput: string,
  handleApplyFilter: func,
  switches: array,
  handleDeleteFilter: func,
  filterFields: object,
  selectMatchData: array,
};
