import { string } from "prop-types";
import { Box, Typography } from "@mui/material";
import {
  AccordionComponent,
  DetailsComponent,
  SummaryComponent,
} from "components";
import { SummaryItem } from "./components";

export const DescriptionBlock = ({ productDescription }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          mt: 3,
        }}
        id="discounts-scroll-table"
      >
        <AccordionComponent
          sx={{
            "&.MuiPaper-root.MuiAccordion-root.MuiPaper-root.MuiAccordion-root":
              { "&:last-of-type": { mb: 0 } },

            "&.MuiAccordion-root svg": {
              display: "none",
            },
          }}
          item={{ id: 1 }}
          expandedDflt={true}
        >
          <SummaryComponent>
            <SummaryItem name="Product Description" />
          </SummaryComponent>
          <DetailsComponent>
            <Typography width="100%" fontSize={18} color="#7E7E7E">
              {productDescription}
            </Typography>
          </DetailsComponent>
        </AccordionComponent>
      </Box>
    </Box>
  );
};

DescriptionBlock.propTypes = { productDescription: string };
DescriptionBlock.defaultProps = { productDescription: "" };
