import { string } from "prop-types";
import { Box, Typography } from "@mui/material";

export const InfoItem = ({ title, value }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      bgcolor="#F7F7F790"
      border="0.5px solid #DBE2ED90"
      borderRadius="4px"
      width="100%"
      height="36px"
      px={2.5}
    >
      <Box flexGrow={1} display="flex" alignItems="center" gap={2}>
        <Box
          sx={{
            width: "9.56px",
            height: "9.56px",
            borderRadius: "50%",
            bgcolor: "#47A06D",
          }}
        />
        <Typography fontSize={16} fontWeight={500} color="#000">
          {title}
        </Typography>
      </Box>
      <Typography fontSize={16} fontWeight={300} color="#1C1C19">
        {value}
      </Typography>
    </Box>
  );
};

InfoItem.propTypes = { title: string, value: string };
InfoItem.defaultProps = { title: "", value: "" };
