import { TabTitle } from "..";

import { Stack } from "@mui/material";
import { useContext } from "react";
import { PaymentsContext } from "pages/PaymentsPage/PaymentsPage";
import { TransactionItem } from "pages/PaymentsPage/components/DesktopPayments/components/TransactionsTab/components";

export const TransactionListBlock = () => {
  const { transactionList } = useContext(PaymentsContext);

  const { rows } = transactionList ?? {};

  return (
    <Stack gap="8px">
      <TabTitle title="Completed" />
      {rows?.map((item) => (
        <TransactionItem
          key={item.id}
          date={item.date}
          name={item?.cardData?.last4}
          order={item.orderCustomId}
          sum={item.amount / 100}
          type={item.type}
        />
      ))}
    </Stack>
  );
};
