import { useCallback } from "react";
import { number, object } from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSupplierAction } from "redux/actions/distributors";

import { VendorItem } from "..";

import { Box, CircularProgress, Stack, Typography } from "@mui/material";

export const VendorListBlock = ({ vendorsState, filterCount }) => {
  const {
    list: vendorsList,
    loading: vendorsLoading,
    existData,
  } = vendorsState || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const currentSupplier = useSelector(
    ({ distributors }) => distributors.selectedSupplier
  );

  const { orderDirect } = currentUser || {};

  const handleFilterDistributors = useCallback(
    (item) => {
      const distr = orderDirect?.find((d) => d?.distributor?.id === item);
      if (distr) {
        dispatch(setSelectedSupplierAction(distr?.distributor));
        navigate("/");
      }
    },
    [dispatch, navigate, orderDirect]
  );

  return (
    <Stack
      sx={{
        height: filterCount ? "calc(100svh - 162px)" : "calc(100svh - 118px)",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
          width: "0px !important",
        },
      }}
    >
      {vendorsLoading ? (
        <Box
          height="calc(100vh - 200px)"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={20} color="inherit" />
        </Box>
      ) : vendorsList?.length ? (
        vendorsList.map((vendor) => {
          const address =
            (vendor?.shippingAddress?.city ?? "") +
            (vendor?.shippingAddress?.city && vendor?.shippingAddress?.state
              ? ", "
              : "") +
            (vendor?.shippingAddress?.state ?? "");
          return (
            <VendorItem
              key={vendor?.id}
              onSelect={() => handleFilterDistributors(vendor?.id)}
              distributor={vendor}
              address={address}
              ordersCount={vendor?._count?.orders}
              terms={
                vendor?.orderDirect?.[0]?.approvedPaymentTermsDuplicate?.name ??
                "-"
              }
              hideSelectBtn={currentSupplier?.id === vendor?.id}
              orderDirect={vendor?.orderDirect?.[0]?.status}
            />
          );
        })
      ) : (
        existData && (
          <Box
            height="calc(100vh - 200px)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography fontSize={16} fontWeight={400}>
              No Supplier found
            </Typography>
          </Box>
        )
      )}
    </Stack>
  );
};

VendorListBlock.propTypes = {
  vendorsState: object,
  filterCount: number,
};
