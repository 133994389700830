import React from "react";

// eslint-disable-next-line react/prop-types
export const CartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36.64"
      height="33.51"
      viewBox="0 0 32.075 28.335"
    >
      <g id="noun_cart_2998671" transform="translate(0.1 2.983)">
        <g id="Group_3500" data-name="Group 3500" transform="translate(0 0)">
          <g id="Group_3495" data-name="Group 3495" transform="translate(0 0)">
            <g id="Group_3492" data-name="Group 3492">
              <g id="Group_3491" data-name="Group 3491">
                <path
                  id="Path_2125"
                  data-name="Path 2125"
                  d="M9.588,48.18h6.638c-.212-.166-.423-.34-.635-.506q.664,2.58,1.336,5.166,1.071,4.14,2.136,8.273c.165.631.324,1.262.489,1.893a.678.678,0,0,0,.635.506H36.609A.672.672,0,0,0,37.244,63q.6-1.893,1.19-3.793c.641-2.025,1.276-4.057,1.917-6.081l.436-1.394a.664.664,0,1,0-1.276-.367q-.6,1.893-1.19,3.793c-.641,2.025-1.276,4.057-1.917,6.081l-.436,1.394c.212-.166.423-.34.635-.506H20.18c.212.166.423.34.635.506q-.664-2.58-1.336-5.166-1.071-4.14-2.136-8.273c-.165-.631-.324-1.262-.489-1.893a.678.678,0,0,0-.635-.506H9.581a.692.692,0,0,0,.007,1.38Z"
                  transform="translate(-8.95 -46.8)"
                  fill="#a5a5a5"
                  stroke="#b2b2b2"
                  strokeWidth="0.2"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_3497"
            data-name="Group 3497"
            transform="translate(10.249 19.545)"
          >
            <g id="Group_3496" data-name="Group 3496">
              <path
                id="Path_2127"
                data-name="Path 2127"
                d="M141.82,360.347a1.657,1.657,0,1,1-1.367-1.636,1.671,1.671,0,0,1,1.367,1.636.6.6,0,0,0,1.194,0,2.9,2.9,0,0,0-1.9-2.687,2.851,2.851,0,0,0-3.134.872,2.847,2.847,0,1,0,5.039,1.815A.6.6,0,0,0,141.82,360.347Z"
                transform="translate(-137.326 -357.499)"
                fill="#a5a5a5"
                stroke="#b2b2b2"
                strokeWidth="0.2"
              />
            </g>
          </g>
          <g
            id="Group_3499"
            data-name="Group 3499"
            transform="translate(22.118 19.545)"
          >
            <g id="Group_3498" data-name="Group 3498" transform="translate(0)">
              <path
                id="Path_2128"
                data-name="Path 2128"
                d="M350.52,360.347a1.657,1.657,0,1,1-1.367-1.636,1.671,1.671,0,0,1,1.367,1.636.6.6,0,0,0,1.194,0,2.9,2.9,0,0,0-1.9-2.687,2.851,2.851,0,0,0-3.134.872,2.847,2.847,0,1,0,5.039,1.815A.6.6,0,0,0,350.52,360.347Z"
                transform="translate(-346.026 -357.499)"
                fill="#a5a5a5"
                stroke="#b2b2b2"
                strokeWidth="0.2"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
