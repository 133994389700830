import { useContext } from "react";
import { any } from "prop-types";
import { Box, Button, Stack, Typography } from "@mui/material";
import { DetailsItem, ProfileAccordion } from "./components";
import { ProfileContext } from "pages/ProfilePage/NewProfile";
import {
  formatAddress,
  setPhoneNumberMask,
  setWebsiteMask,
} from "helpers/helpers";
import {
  GoogleAutocompleteField,
  StyledSelect,
  StyledTextField,
} from "components";
import NumberFormat from "react-number-format";
import { getAddressComponents } from "components/AddressField/AddressField.helpers";
import { sliceCountry } from "simply-depo-ui-components";
import { StyledArrowIcon } from "components/Icons";
import { cl } from "pages/ProductsPage/components/MobileProductCard/styles";
import { cl as classes } from "components/ContactDrawer/styles";
import AddIcon from "@mui/icons-material/Add";
import { cl as summaryCl } from "../../styles";
const DetailsWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        border: "1px solid #00000010",
        borderRadius: "10px",
        mb: "16px",
        p: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {children}
    </Box>
  );
};
DetailsWrapper.propTypes = {
  children: any,
};

export const MobileAccordion = () => {
  const {
    formField,
    control,
    setValue,
    selectCityState,
    handleSubmit,
    onSubmit,
    onEditContact,
    editType,
    setEditType,
    isDirty,
    onOpenCreateContact,
  } = useContext(ProfileContext);

  const {
    name,
    email,
    federalTaxId,
    billingAddress,
    phone,
    website,
    contacts = [],
  } = formField || {};

  const DETAILS_FIELDS = [
    {
      name: "Business name",
      value: name || "-",
      showEditBtn: true,
      fieldName: "name",
      Input: StyledTextField,
      inputProps: { placeholder: "Business name" },
    },
    {
      name: "FED Tax ID",
      value: federalTaxId || "-",
      showEditBtn: false,
      fieldName: "federalTaxId",
      Input: NumberFormat,
      inputProps: {
        placeholder: "FED Tax ID",
        customInput: StyledTextField,
      },
    },
    {
      name: "Billing address",
      value:
        editType === "Business details"
          ? formatAddress(formField.billingAddress?.formatted_address)
          : billingAddress?.formatted_address || "-",
      showEditBtn: false,
      fieldName: "billingAddress.formatted_address",
      Input: GoogleAutocompleteField,
      inputProps: {
        placeholder: "Billing address",
        onPlaceSelected: (place) => {
          const { street, state, city, zip } = getAddressComponents(place);
          setValue("billingAddress", {
            formatted_address: sliceCountry(place.formatted_address),
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            street,
            state,
            city,
            zip,
          });
        },
        noErrorMessage: true,
      },
      subFields: [
        {
          subName: "Unit",
          subFieldName: "billingAddress.appartement",
          SubInput: StyledTextField,
          isSelect: false,
          subInputProps: {
            placeholder: "Unit",
          },
          wrapperProps: { mt: "10px" },
        },
        {
          subName: "City",
          subFieldName: "billingAddress.city",
          SubInput: StyledTextField,
          isSelect: false,
          subInputProps: {
            placeholder: "City",
          },
        },
      ],
    },
  ];

  const HORIZONTAL_DETAILS_FIELDS = [
    {
      name: "State",
      fieldName: "billingAddress.state",
      Input: StyledSelect,
      isSelect: true,
      wrapperProps: {
        width: "50%",
      },
      selectProps: {
        placeholder: "State",
        noErrorMessage: true,
        notched: false,
        fullWidth: true,
        IconComponent: StyledArrowIcon,
        sx: { ...classes.select, fontWeight: 400 },
        displayEmpty: true,
        height: "36px",
        MenuProps: {
          PaperProps: {
            sx: { ...cl.paperStyles, width: "100%" },
          },
        },
      },
      menuList: selectCityState,
    },
    {
      name: "Zip",

      fieldName: "billingAddress.zip",
      isSelect: false,
      Input: StyledTextField,
      wrapperProps: {
        width: "50%",
      },
      InputProps: {
        placeholder: "Zip",
      },
    },
  ];

  const BUSINESS_CONTACT_FIELDS = [
    {
      name: "Business email",
      value: email || "-",
      showEditBtn: true,
      fieldName: "email",
      Input: StyledTextField,
      inputProps: { placeholder: "Business email" },
    },
    {
      name: "Business phone",
      value: setPhoneNumberMask(`${phone}`) || "-",
      fieldName: "phone",
      Input: NumberFormat,
      inputProps: {
        customInput: StyledTextField,
        placeholder: "Business phone",
        mask: "_",
        format: "+# (###) ### ####",
      },
    },
    {
      name: "Website",
      value: setWebsiteMask(website) || "-",
      fieldName: "website",
      Input: StyledTextField,
      inputProps: { placeholder: "Website" },
    },
  ];

  return (
    <Box mt={2}>
      <ProfileAccordion title="Business details">
        <DetailsWrapper>
          {DETAILS_FIELDS.map((field, index) => {
            const isEdit = editType === "Business details";
            return (
              <DetailsItem
                key={index}
                {...field}
                {...{
                  isEdit,
                  control,
                  onEditOpen: isEdit
                    ? handleSubmit(onSubmit)
                    : () => setEditType("Business details"),
                  disabled: isEdit && !isDirty,
                }}
              />
            );
          })}

          {editType === "Business details" && (
            <Stack direction="row" gap="10px">
              {HORIZONTAL_DETAILS_FIELDS.map((field, index) => {
                const isEdit = editType === "Business details";
                return (
                  <DetailsItem
                    key={index}
                    {...field}
                    {...{
                      isEdit,
                      control,
                      onEditOpen: isEdit
                        ? handleSubmit(onSubmit)
                        : () => setEditType("Business details"),
                      disabled: isEdit && !isDirty,
                    }}
                  />
                );
              })}
            </Stack>
          )}
        </DetailsWrapper>
      </ProfileAccordion>

      <ProfileAccordion title="Business contact">
        <DetailsWrapper>
          {BUSINESS_CONTACT_FIELDS.map((field, index) => {
            const isEdit = editType === "Business contact";
            return (
              <DetailsItem
                key={index}
                {...field}
                {...{
                  control,
                  isEdit,
                  onEditOpen: isEdit
                    ? handleSubmit(onSubmit)
                    : () => setEditType("Business contact"),
                  disabled: isEdit && !isDirty,
                }}
              />
            );
          })}
        </DetailsWrapper>
      </ProfileAccordion>

      {!!contacts?.length && (
        <ProfileAccordion title="Contacts">
          <DetailsWrapper>
            {contacts.map((c) => (
              <DetailsItem
                key={c?.id}
                name={c?.role}
                showEditBtn
                onEditOpen={() => onEditContact(c)}
                isPrimary={c?.defaultContact}
                value={
                  <Box display="block" component="span">
                    {!!c?.name && (
                      <Typography color="#00000060" fontSize={14} noWrap>
                        {c.name}
                      </Typography>
                    )}

                    {!!c?.phone && !!c?.countryPrefix && (
                      <Typography color="#00000060" fontSize={14} noWrap>
                        {setPhoneNumberMask(
                          `${c.countryPrefix?.phone_code}${c.phone}`
                        )}
                      </Typography>
                    )}

                    {!!c?.email && (
                      <Typography color="#00000060" fontSize={14} noWrap>
                        {c.email}
                      </Typography>
                    )}
                  </Box>
                }
              />
            ))}
          </DetailsWrapper>

          <Button
            sx={{ ...summaryCl.editBtn, mb: "16px" }}
            onClick={onOpenCreateContact}
          >
            <AddIcon />
            Add new
          </Button>
        </ProfileAccordion>
      )}
    </Box>
  );
};
