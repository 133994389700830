import { instanceOf, number, oneOfType, shape, string } from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import {
  photoUrl,
  separateNumWithComma,
} from "../../../../../../helpers/helpers";
import { cl } from "../../../../ViewOrderPage.styles";
import { ImagePlaceholder } from "components";

export const OrderItem = ({ item }) => {
  const {
    OrderItem: {
      paper,
      // img,
      productWrapper,
      productNameWrapper,
      text,
      textThin,
      textTotal,
      textThinTotal,
      label,
      totalWrapper,
    },
  } = cl;

  return (
    <Paper sx={paper} elevation={0} square>
      {/* Product */}
      <Box width="70%" sx={productWrapper}>
        {/* <StyledImageBox src={item?.photo?.fileName} wrapper={img} /> */}
        {item?.photo?.fileName ? (
          <Box
            component="img"
            src={photoUrl(item?.photo?.fileName)}
            width="60px"
            height="60px"
            borderRadius="4px"
            sx={{ objectFit: "contain" }}
          />
        ) : (
          <ImagePlaceholder
            width="60px"
            height="60px"
            style={{
              objectFit: "cover",
              border: "1px solid #D5D9D9",
              borderRadius: "4px",
            }}
          />
        )}
        <Box sx={productNameWrapper}>
          <Typography sx={text} noWrap>
            {item?.name}{" "}
            {!!(item?.color || item?.size) && ` / ${item?.color || item?.size}`}
          </Typography>
          <Typography sx={textThin} noWrap>
            by {item?.manufacturerName}
          </Typography>
        </Box>
      </Box>

      {/* Variations */}
      {/* <Box width="54.8%">
        <Typography sx={text} noWrap>
          {item?.color || item?.size}
        </Typography>
      </Box> */}

      {/* QTY */}
      <Box width="15.6%" justifyContent="center" display="flex">
        <Box sx={label} textAlign="center" component={Typography} noWrap>
          {item?.quantity}
        </Box>
      </Box>

      {/* Total */}
      <Box width="14.4%" sx={totalWrapper}>
        <Typography sx={textTotal} noWrap>
          ${separateNumWithComma(item?.totalAmount)}
        </Typography>
        <Typography sx={textThinTotal} noWrap>
          (${separateNumWithComma(item?.price)} per item)
        </Typography>
      </Box>
    </Paper>
  );
};

OrderItem.propTypes = {
  item: shape({
    photo: shape({
      fileName: string,
    }),
    name: string,
    manufacturerName: string,
  }),
  color: oneOfType([string, instanceOf(null)]),
  size: oneOfType([string, instanceOf(null)]),
  quantity: number,
  totalAmount: number,
  price: number,
};
