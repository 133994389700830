import { number } from "prop-types";
import { Box, Typography } from "@mui/material";

// eslint-disable-next-line no-unused-vars
export const TitleBlock = ({ itemsCount }) => {
  return (
    <Box py={3}>
      <Typography fontSize={30} color="#000">
        Shopping Cart{" "}
        <Box fontWeight={300} component="span">
          {/* ({pluralize("case", itemsCount, true)}) */}
        </Box>
      </Typography>
    </Box>
  );
};

TitleBlock.propTypes = { itemsCount: number };
TitleBlock.defaultProps = { itemsCount: 0 };
