import { useEffect, useState } from "react";
import { array, object, func, bool } from "prop-types";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  InputAdornment,
} from "@mui/material";
import moment from "moment";
// import { CategoriesComponent } from "./components";
import useStyles from "./styles";
// import ArrowBtn from "components/Buttons/ArrowBtn/ArrowBtn";
import {
  ArrowBtn,
  IOSSwitch,
  LargeCheckbox,
  SelectDatePicker,
  StyledButton,
  StyledSelect,
  StyledTextField,
} from "components";
import { normalizeSnakeCaseString } from "helpers/helpers";
import { CategoriesComponent } from "pages/ProductsPage/components";
import { useCategories } from "pages/ProductsPage/useCategories";

export const FilterMenu = ({
  switches,
  filterFields,
  handleApplyFilter,
  selectMatchData,
  iconDict,
  price,
  categoriesOptions = {},
  ...props
}) => {
  const classes = useStyles();
  const [switchesState, setSwitchesState] = useState([...switches]);
  const [temporaryFields, setTemporaryFields] = useState({ ...filterFields });

  useEffect(() => {
    if (props.open) {
      setSwitchesState([...switches]);
      setTemporaryFields({ ...filterFields });
      return;
    }
  }, [props.open, switches, filterFields]);

  const handleChangeTextField = ({ item, option, value }) => {
    setTemporaryFields((prev) => {
      return {
        ...prev,
        [item.value]: {
          ...prev[item.value],
          [option.id]: {
            value: value,
            prefix: option.prefix,
          },
        },
      };
    });
  };

  const {
    firstLoadState: firstLoadCategoriesState,
    state: categoriesState,
    handleFetchCategories,
    setState: setCategoriesState,
  } = useCategories(categoriesOptions);

  const onSwitchChange = (filter, index) => {
    if (filter.checked) {
      if (filter.value === "category_id")
        setCategoriesState(firstLoadCategoriesState);

      setTemporaryFields((prev) => {
        return { ...prev, [filter.value]: "" };
      });
    }

    const newState = switchesState;

    const insert = {
      ...filter,
      checked: !filter.checked,
    };

    newState.splice(index, 1, insert);
    setSwitchesState([...newState]);
  };

  const rowItem = (item) => {
    switch (item.type) {
      case "switch": {
        const { checkboxes, valuesForCheckboxes } =
          selectMatchData.find((data) => data.id === item.value) || {};

        const currentValue = temporaryFields[item.value];
        const showCheckboxes =
          !!currentValue?.length &&
          valuesForCheckboxes?.some((checkboxValue) =>
            currentValue.some(
              (selectedValue) => selectedValue === checkboxValue
            )
          );
        return (
          <Box>
            <ToggleButtonGroup
              exclusive={!item.multiple}
              value={temporaryFields[item.value]}
              onChange={(e, val) => {
                let newVal = "";
                if (item.multiple) newVal = val?.length ? val : "";
                else newVal = val;

                setTemporaryFields((prev) => {
                  return { ...prev, [item.value]: newVal };
                });
              }}
            >
              {item.values.map((value) => (
                <ToggleButton
                  value={value}
                  key={value?.value || value}
                  className={classes.toggleButton}
                >
                  {value?.name || normalizeSnakeCaseString(value)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            {showCheckboxes && (
              <Box display="flex" flexDirection="column" mt="3px">
                {checkboxes?.map((item) => (
                  <LargeCheckbox
                    key={item.value}
                    checked={item.value === temporaryFields[item.field]}
                    onChange={() =>
                      setTemporaryFields((prev) => ({
                        ...prev,
                        [item.field]:
                          prev[item.field] === item.value ? "" : item.value,
                      }))
                    }
                    formSx={{ mr: 0, ml: "-9px" }}
                    sx={{ py: "7px" }}
                    label={item.label}
                    size={13}
                    labelSx={{ fontSize: "10px", color: "#B1ACAC" }}
                  />
                ))}
              </Box>
            )}
          </Box>
        );
      }
      case "select": {
        const { childrenList, textFieldProps, ...selectProps } =
          selectMatchData?.find((data) => data?.id === item?.value) ?? {};

        return (
          <StyledSelect
            notched={false}
            labelProps={{ shrink: false }}
            {...selectProps}
            label={temporaryFields[item.value]?.value ? "" : item.selectLabel}
            value={temporaryFields[item.value]?.value || ""}
            formSx={{ height: "32px" }}
            height="32px"
            onChange={(e, el) => {
              setTemporaryFields((prev) => {
                return {
                  ...prev,
                  [item.value]: {
                    label: item.label,
                    name: el.props.name || el.props.tag,
                    value: e.target.value,
                    iconId: el.props.iconid,
                  },
                };
              });
            }}
            sx={{
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            <MenuItem sx={{ display: "none" }} value="">
              {item?.selectLabel}
            </MenuItem>
            {childrenList
              ?.filter((el) => el !== false)
              .map((child) => {
                if (child?.type === "group")
                  return (
                    <ListSubheader
                      key={child?.name}
                      className={classes.selectGroupTitle}
                    >
                      {child?.name}
                    </ListSubheader>
                  );

                return (
                  <MenuItem
                    key={`${child?.id}-${child?.name}`}
                    value={child?.id}
                    name={child?.name || child?.tag}
                    iconid={child?.iconId}
                    sx={{
                      maxWidth: "292px",
                      borderTop: child?.options?.showBeforeDivider
                        ? "0.5px solid #d5d9d9"
                        : null,
                    }}
                    {...child?.props}
                  >
                    {child?.iconId && (
                      <ListItemIcon> {iconDict[child?.iconId]} </ListItemIcon>
                    )}
                    <ListItemText
                      primaryTypographyProps={{
                        noWrap: true,
                        sx: { fontSize: 12 },
                      }}
                    >
                      {child?.name || child?.tag}
                    </ListItemText>
                  </MenuItem>
                );
              })}
          </StyledSelect>
        );
      }
      case "date": {
        const {
          isVisit,
          textFieldProps,
          valuesForTextfield,
          isOrder,
          ...selectProps
        } = selectMatchData.find((data) => data.id === item.value);
        const { value: currentValue } = temporaryFields[item.value] || {};

        const showTextField = valuesForTextfield?.includes(currentValue);
        return (
          <Box display="flex" gap="14px">
            <SelectDatePicker
              {...selectProps}
              value={
                currentValue?.start_date
                  ? [
                      moment(currentValue?.start_date),
                      currentValue?.end_date
                        ? moment(currentValue?.end_date)
                        : null,
                    ]
                  : currentValue ?? ""
              }
              isVisit={isVisit}
              isOrder={isOrder}
              noSquare
              height="32px"
              fontSizeLabel="12px"
              handleDateInput={(newDates) => {
                const start_date = newDates?.[0]?.format()?.split("+")?.[0];
                const end_date = newDates?.[1]?.format()?.split("+")?.[0];
                setTemporaryFields((prev) => {
                  return {
                    ...prev,
                    [item.value]: {
                      label: item.label,
                      name: newDates[1]
                        ? ` ${newDates[0]?.format(
                            "MM/DD/YYYY"
                          )} - ${newDates[1]?.format("MM/DD/YYYY")}`
                        : newDates[0]?.format("MM/DD/YYYY"),
                      value: {
                        date: null,
                        start_date,
                        end_date,
                      },
                    },
                  };
                });
              }}
              fullWidth
              label={temporaryFields[item.value]?.value ? "" : item.selectLabel}
              sx={{
                "& > fieldset": {
                  borderRadius: "4px",
                },
              }}
              handleClearValue={() => {
                setTemporaryFields((prev) => {
                  return {
                    ...prev,
                    [item.value]: "",
                  };
                });
              }}
              onChange={(e, el) => {
                setTemporaryFields((prev) => {
                  let preparedData = {
                    ...prev,
                    [item.value]: {
                      label: item.label,
                      name: el.props.value,
                      value: e.target.value,
                    },
                  };
                  if (["more_than", "less_than"].includes(e.target.value))
                    preparedData[item.value].days = 1;
                  return preparedData;
                });
              }}
              textfieldInputSx={{
                borderRadius: "4px",
              }}
            />
            {showTextField && (
              <StyledTextField
                value={temporaryFields[item.value]?.days || "1"}
                onChange={(e) => {
                  setTemporaryFields((prev) => {
                    return {
                      ...prev,
                      [item.value]: {
                        ...prev[item.value],
                        days: e.target.value,
                      },
                    };
                  });
                }}
                InputProps={{
                  sx: { padding: "0", height: "32px", fontSize: "12px" },
                  endAdornment: (
                    <ArrowBtn
                      checkDate={true}
                      stylesBox={{
                        display: "flex",
                        flexDirection: "column",
                        height: "32px",
                      }}
                      stylesUpBtn={{
                        height: "16px",
                        borderRadius: "0",
                        background: "#EEEEEE",
                        "&:hover": {
                          backgroundColor: "lightgray",
                          cursor: "pointer",
                        },
                      }}
                      stylesDownBtn={{
                        height: "16px",
                        borderRadius: "0",
                        background: "#EEEEEE",
                        "&:hover": {
                          backgroundColor: "lightgray",
                          cursor: "pointer",
                        },
                      }}
                      onClickUp={() =>
                        setTemporaryFields((prev) => ({
                          ...prev,
                          [item.value]: {
                            ...prev[item.value],
                            days: prev[item.value]?.days
                              ? +prev[item.value].days + 1
                              : 1,
                          },
                        }))
                      }
                      onClickDown={() =>
                        setTemporaryFields((prev) => ({
                          ...prev,
                          [item.value]: {
                            ...prev[item.value],
                            days:
                              prev[item.value]?.days &&
                              prev[item.value].days > 1
                                ? +prev[item.value].days - 1
                                : 1,
                          },
                        }))
                      }
                    />
                  ),
                }}
                {...textFieldProps}
              />
            )}
          </Box>
        );
      }

      case "textfields": {
        return (
          <Box className={classes.textFieldsWrapper}>
            {item?.options?.map((option) => {
              const { prefix, ...textfieldProps } = option;

              return (
                <StyledTextField
                  key={option.id}
                  {...textfieldProps}
                  inputSx={{ fontSize: "12px", height: "35px" }}
                  formSx={{ "& legend > span": { px: "2px" } }}
                  value={temporaryFields[item.value]?.[option.id]?.value || ""}
                  type="text"
                  InputProps={{
                    className: classes.textInputInventory,

                    startAdornment: price && (
                      <InputAdornment position="start">
                        <div>$</div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <ArrowBtn
                          stylesBox={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                          stylesUpBtn={{
                            height: "16px",
                            borderRadius: "0 5px 0 0",
                            background: "#EEEEEE",
                            "&:hover": {
                              backgroundColor: "lightgray",
                              cursor: "pointer",
                            },
                          }}
                          stylesDownBtn={{
                            height: "16px",
                            borderRadius: "0 0 5px 0",
                            background: "#EEEEEE",
                            "&:hover": {
                              backgroundColor: "lightgray",
                              cursor: "pointer",
                            },
                          }}
                          onClickUp={() => {
                            setTemporaryFields((prev) => {
                              const prevValue =
                                temporaryFields[item.value]?.[option.id]
                                  ?.value || 0;
                              return {
                                ...prev,
                                [item.value]: {
                                  ...prev[item.value],
                                  [option.id]: {
                                    value: +prevValue + 1,
                                    prefix: option.prefix,
                                  },
                                },
                              };
                            });
                          }}
                          onClickDown={() => {
                            setTemporaryFields((prev) => {
                              const prevValue =
                                temporaryFields[item.value]?.[option.id]
                                  ?.value || 0;
                              return {
                                ...prev,
                                [item.value]: {
                                  ...prev[item.value],
                                  [option.id]: {
                                    value: +prevValue - 1,
                                    prefix: option.prefix,
                                  },
                                },
                              };
                            });
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  labelSx={{ left: -3 }}
                  onChange={(e) =>
                    handleChangeTextField({
                      item,
                      option,
                      value: e.target.value,
                    })
                  }
                />
              );
            })}
          </Box>
        );
      }

      case "categories": {
        return (
          <CategoriesComponent
            loading={categoriesState.loading}
            childrenlist={categoriesState.childrenlist}
            parentsList={categoriesState.parentsList}
            category={categoriesState.currentCategory}
            handleFetchCategories={handleFetchCategories}
            isFilter
          />
        );
      }
    }
  };

  return (
    <Menu
      id="basic-menu"
      MenuListProps={{
        "aria-labelledby": "basic-button",
        sx: {
          width: "340px",
          pt: 0,
        },
      }}
      {...props}
    >
      <MenuList>
        {switchesState.map((filter, i) => (
          <Box key={filter.value} className={classes.menuRow}>
            <IOSSwitch
              small
              label={filter.label}
              checked={!!filter.checked}
              onChange={() => onSwitchChange(filter, i)}
              formSx={{
                "& .MuiFormControlLabel-label": { fontSize: "12px" },
              }}
            />
            {filter.checked && rowItem(filter)}
          </Box>
        ))}
        <Divider />
        <Box className={classes.buttonsBlock}>
          <StyledButton
            label="Cancel"
            onClick={props.onClose}
            variant="outlined"
            // color="cancel"
            sx={{ height: "28px" }}
          />
          <StyledButton
            label="Apply filter"
            variant="contained"
            onClick={() =>
              handleApplyFilter(switchesState, {
                ...temporaryFields,
                category_id: {
                  ...categoriesState?.currentCategory,
                  value: categoriesState?.chosenCategoryId,
                  label: "Category",
                },
              })
            }
            sx={{ height: "28px" }}
          />
        </Box>
      </MenuList>
    </Menu>
  );
};

FilterMenu.propTypes = {
  switches: array,
  filterFields: object,
  iconDict: object,
  onClose: func,
  handleApplyFilter: func,
  open: bool,
  price: bool,
  selectMatchData: array,
  categoriesOptions: object,
};

FilterMenu.defaultProps = {
  switches: [],
  filterFields: {},
  iconDict: {},
  handleApplyFilter: () => {},
  selectMatchData: [],
  categoriesOptions: {},
};

export default FilterMenu;
