import { useContext, useMemo, useRef } from "react";

import {
  ActionBlock,
  ContactBlockItem,
  DeliveryInfoBlock,
  HeaderBlock,
  NoteBlock,
  OrderedProductsList,
} from "./components";

import { OrderContext } from "pages/ViewOrderPage/ViewOrderPage.hooks";
import { TotalCartBlock } from "pages/CartPage/components/MobileCartPage/components";

import { Stack } from "@mui/material";
import { ViewOrderInfoDrawer } from "components/ViewOrderInfoDrawer/ViewOrderInfoDrawer";
import { useCustomerCards } from "components/CustomerCards/CustomerCards.hooks";
import { SelectCardDrawer } from "components/SelectCardDrawer/SelectCardDrawer";
import { AddCardDrawer, EditCardDrawer } from "components";
import { useLocation } from "react-router-dom";

export const MobileViewOrder = () => {
  const actionBlockRef = useRef(null);
  const { orderData, acceptPaymentData, currentUser } =
    useContext(OrderContext);

  const {
    totalAmount,
    deliveryFee,
    totalQuantity,
    totalRawAmount,
    totalDiscountValue,
  } = useMemo(() => orderData.order || {}, [orderData]);

  const customerId =
    currentUser?.orderDirect?.[0]?.distributor?.customers?.[0]?.id;

  const {
    cardForEdit,
    handleFetchCards,
    creditCards,
    isCardExpired,
    selectedCard,
    handleDeleteCard,
    selectCard,
    setCardForEdit,
  } = useCustomerCards({
    distributor: currentUser?.orderDirect?.[0]?.distributor,
    customerId,
    setCardToCart: () => {},
  });

  const { state } = useLocation();

  const matchOrderDirect = useMemo(
    () =>
      currentUser?.orderDirect.find(
        ({ distributor }) =>
          distributor?.id === orderData.order?.distributor?.id
      ),
    [currentUser?.orderDirect, orderData.order?.distributor?.id]
  );

  const hidePayWithCredit = useMemo(() => {
    return !(
      !!matchOrderDirect &&
      !!matchOrderDirect?.distributor?.allowStoreCreditCardPayments
    );
  }, [matchOrderDirect]);

  const isConfirmationPage = useMemo(
    () => state?.isConfirmationPage,
    [state?.isConfirmationPage]
  );

  const actionBlockHeight =
    actionBlockRef?.current && actionBlockRef?.current?.clientHeight;

  const contentHeight = useMemo(() => {
    return (
      (isConfirmationPage
        ? actionBlockHeight
        : hidePayWithCredit
        ? 0
        : actionBlockHeight) + 30
    );
  }, [actionBlockHeight, hidePayWithCredit, isConfirmationPage]);

  return (
    <Stack
      mt="44px"
      gap="8px"
      height={`calc(100svh - ${contentHeight}px)`}
      sx={{
        overflowY: "auto",
        position: "relative",
        "&::-webkit-scrollbar": {
          display: "none",
          width: "0px",
        },
      }}
    >
      <AddCardDrawer
        {...{
          customerId,
          handleSave: (_, dataCard) => {
            handleFetchCards(false, dataCard.defaultMethod ?? false);
          },
        }}
      />

      <EditCardDrawer
        {...{
          customerId,
          card: cardForEdit,
          handleSave: (_, dataCard) =>
            handleFetchCards(false, dataCard.defaultMethod ?? false),
          handleDeleteCard,
        }}
      />

      <SelectCardDrawer
        {...{
          cardList: creditCards,
          selectedCard,
          handleSelectCard: selectCard,
          isCardExpired,
          handleDeleteCard,
          setCardForEdit,
          handleConfirm: () =>
            acceptPaymentData.handleAcceptPayment({
              ...selectedCard,
              distributor_id: currentUser?.orderDirect?.[0]?.distributor?.id,
              orderId: orderData?.order?.id,
            }),
          drawerSx: { height: "92svh", padding: "20px 16px 48px !important" },
        }}
      />

      <ViewOrderInfoDrawer
        order={orderData.order}
        totalQuantity={totalQuantity}
      />
      <HeaderBlock />
      <DeliveryInfoBlock />
      <ContactBlockItem />
      <OrderedProductsList />
      <NoteBlock />
      <TotalCartBlock
        {...{
          calculatedDelivery: deliveryFee,
          calculatedTotal: totalRawAmount,
          customerDiscount: totalDiscountValue,
          grandTotalWithDiscount: totalAmount,
          casesCount: totalQuantity,
          wrapperSx: { mb: "20px" },
        }}
      />
      <ActionBlock
        ref={actionBlockRef}
        {...{ isConfirmationPage, hidePayWithCredit }}
      />
    </Stack>
  );
};
