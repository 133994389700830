import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import distributorsReducer from "./reducers/distributors";
import productsReducer from "./reducers/products";
import settingsReducer from "./reducers/settings";
import draftsReducer from "./reducers/drafts";
import confirmDialogs from "./reducers/confirmDialogs";
import uiStateReducer from "./reducers/uiState";

const store = configureStore({
  reducer: {
    settings: settingsReducer,
    auth: authReducer,
    distributors: distributorsReducer,
    products: productsReducer,
    drafts: draftsReducer,
    confirmDialogs: confirmDialogs,
    uiState: uiStateReducer,
  },
});

export default store;
