import * as React from "react";
export const PadlockIco = (props) => (
  <svg
    data-name="PadlockIco"
    xmlns="http://www.w3.org/2000/svg"
    width={49}
    height={49}
    viewBox="0 0 49 49"
    {...props}
  >
    <g
      data-name="Icon feather-lock"
      fill="none"
      stroke="#5f6267"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        data-name="Path 2114"
        d="M15.817 22.336h17.366a2.481 2.481 0 0 1 2.481 2.481V33.5a2.481 2.481 0 0 1-2.481 2.481H15.817a2.481 2.481 0 0 1-2.481-2.481v-8.683a2.481 2.481 0 0 1 2.481-2.481Z"
      />
      <path
        data-name="Path 2115"
        d="M18.298 22.336v-4.964a6.2 6.2 0 0 1 12.4 0v4.962"
      />
    </g>
    <g data-name="Path 2130" fill="none">
      <path d="M24.5 0a24.606 24.606 0 0 1 20.806 11.557A24.138 24.138 0 0 1 49 24.5 24.5 24.5 0 1 1 24.5 0Z" />
      <path
        d="M24.5 1A23.423 23.423 0 0 0 7.883 7.883 23.423 23.423 0 0 0 1 24.5a23.423 23.423 0 0 0 6.883 16.617A23.423 23.423 0 0 0 24.5 48a23.423 23.423 0 0 0 16.617-6.883A23.423 23.423 0 0 0 48 24.5c0-4.44-1.256-8.846-3.536-12.404-2.16-3.373-5.096-6.171-8.492-8.09A23.357 23.357 0 0 0 24.5 1m0-1c8.673 0 16.294 4.507 20.806 11.557C47.586 15.115 49 19.642 49 24.5 49 38.031 38.031 49 24.5 49S0 38.031 0 24.5 10.969 0 24.5 0Z"
        fill="#5f6267"
      />
    </g>
  </svg>
);
export default PadlockIco;
