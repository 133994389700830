import { error } from "helpers/notifications";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDraftsAction } from "redux/actions/drafts";
import { draftsListSelector } from "redux/selectors/drafts";
import { createSelector } from "reselect";
import { getCartDraftDataService } from "services/drafts";

const selector = createSelector(draftsListSelector, (draftsList) => ({
  draftsList,
}));

export const useDrawer = ({ distributorId, showSelectProducts }) => {
  const { draftsList } = useSelector(selector);
  const dispatch = useDispatch();

  const productsList = draftsList[0]?.data?.productsData;

  const [state, setState] = useState({
    open: false,
    loading: true,
    list: [],
    draftData: {},
  });

  useEffect(() => {
    if (showSelectProducts)
      setState((prev) => ({ ...prev, list: showSelectProducts }));
  }, [showSelectProducts]);

  const currentDraft = useMemo(
    () =>
      draftsList.find(({ distributor }) => distributor.id === distributorId),
    [distributorId, draftsList]
  );

  const fetchCartDraft = async () => {
    if (!distributorId) return;
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const res = await getCartDraftDataService({
        productsData: JSON.stringify(
          productsList?.map(
            ({ id, quantity, itemDiscountAmount, itemDiscountType }) => ({
              id,
              quantity,
              itemDiscountAmount,
              itemDiscountType,
            })
          )
        ),
        distributor_id: distributorId,
      });

      setState((prev) => ({
        ...prev,
        draftData: res,
        loading: false,
      }));
    } catch (err) {
      if (err?.response?.data?.message) error(err.response.data.message);
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message || err.message);
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchCartDraft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsList]);

  const handleOpen = () => {
    setState((prev) => ({ ...prev, open: true }));
  };
  const handleClose = () => {
    setState({
      open: false,
      loading: true,
      list: [],
      draftData: {},
    });
  };

  useEffect(() => {
    if (!state?.list?.length) handleClose();
  }, [state?.list?.length]);

  const handleDeleteProductDraft = async (product) => {
    if (!product) return error("Something went wrong.");

    const { id, value } = product || {};

    const {
      draftData: { products },
    } = state;

    const productsDraftList = [...products];

    const indexProductDraft = productsDraftList.findIndex((p) => p?.id === id);
    const productDraft = productsDraftList[indexProductDraft];

    let productsData;

    if (productDraft?.quantity === value) {
      const listWithoutCurrentProduct = productsDraftList?.filter(
        (p) => p?.id !== id
      );
      productsData = listWithoutCurrentProduct;
    } else {
      const listWithChangedQuantityCurrentProduct = productsDraftList.map(
        (p) => {
          return p?.id === id ? { ...p, quantity: p?.quantity - value } : p;
        }
      );
      productsData = listWithChangedQuantityCurrentProduct;
    }

    dispatch(
      updateDraftsAction(
        { ...state?.draftData, productsData },
        distributorId,
        currentDraft?.id
      )
    );
    setState((prev) => ({
      ...prev,
      list: prev.list.filter((p) => p?.id !== id),
    }));
  };

  return { ...state, handleOpen, handleClose, handleDeleteProductDraft };
};
