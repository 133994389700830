import { error } from "helpers/notifications";
import { getProductsService } from "../../services/products";

export const GET_PRODUCTS_LIST = "GET_PRODUCTS_LIST";
export const SET_PRODUCTS_LOADING = "SET_PRODUCTS_LOADING";

const getProductsList = (payload) => ({
  type: GET_PRODUCTS_LIST,
  payload,
});

const setProductsLoading = (payload) => ({
  type: SET_PRODUCTS_LOADING,
  payload,
});

export const getProductsAction = (params) => {
  return (dispatch) => {
    dispatch(setProductsLoading(true));
    getProductsService(params)
      .then((res) => {
        dispatch(setProductsLoading(false));
        dispatch(getProductsList(res?.rows));
      })
      .catch((err) => {
        dispatch(setProductsLoading(false));
        error(err?.response?.data?.message);
      });
  };
};
