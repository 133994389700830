import axios from "axios";

export const getOrdersService = async (params) => {
  const { data } = await axios({
    method: "GET",
    url: "/orders",
    params,
  });
  return data;
};

export const getOrderByIdService = async (id) => {
  return axios.get(`/orders/${id}`).then((res) => res.data);
};

export const updateOrderService = async (id, data) => {
  return axios.put(`/orders/${id}`, data).then((res) => res.data);
};

export const createOrderService = async ({ body, params }) => {
  return axios.post("/orders", body, { params }).then((res) => res.data);
};

export const acceptPaymentService = async (distributor_id, dataPayment) => {
  const { data } = await axios({
    method: "POST",
    url: "/orders/payments",
    params: { distributor_id },
    data: dataPayment,
  });
  return data;
};
