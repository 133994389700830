import { number, string, func, bool } from "prop-types";

import { Box, Button, Grid, Typography } from "@mui/material";
import { PaymentStatuses } from "components";
import { Link } from "react-router-dom";

export const OrdersTabItem = ({
  customId,
  supplier,
  ordered,
  createdBy,
  totalItems,
  total,
  payment,
  fulfilled,
  isFulfilled,
  handleViewOrder,
  id,
  isCanceledOrder,
}) => {
  return (
    <Grid
      sx={{
        height: "48px",
        alignItems: "center",
        borderRadius: "0 0 4px 4px",
        "&:not(:last-child)": {
          borderBottom: "1px solid #D5D9D9",
          borderRadius: 0,
        },
        "&:hover": { backgroundColor: "#F7F7F7" },
        textDecorationLine: "none",
      }}
      component={Link}
      to={`/orders/${id}`}
      state={{ type: "orders_page" }}
      onClick={(e) => {
        if (e.metaKey || e.ctrlKey) return;
        e.stopPropagation();
        e.preventDefault();
        handleViewOrder();
      }}
      container
    >
      <Grid xs={0.15} item />
      {/* Custom ID */}
      <Grid sx={{ display: "flex" }} xs={1} item>
        <Box display="flex" gap="5px">
          <Typography
            sx={{
              color: "#26874B",
              cursor: "pointer",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "clamp(12px, 0.8vw, 14px)",
              whiteSpace: "nowrap",
              "&:hover": {
                textDecoration: "underline",
              },
              ...(isCanceledOrder && {
                textDecorationLine: "line-through",
                textDecorationColor: "rgb(28, 28, 25)",
              }),
            }}
            variant="typographyMedium"
          >
            {customId}
          </Typography>
        </Box>
      </Grid>

      {/* Supplier */}
      <Grid item xs={1.6}>
        <Typography
          fontSize="clamp(12px, 0.8vw, 14px)"
          fontWeight={400}
          color="groundBlack"
          noWrap
          sx={{
            ...(isCanceledOrder && {
              textDecorationLine: "line-through",
              textDecorationColor: "rgb(28, 28, 25)",
            }),
          }}
        >
          {supplier}
        </Typography>
      </Grid>

      {/* Ordered */}
      <Grid xs={1.6} item>
        <Typography
          fontSize="clamp(12px, 0.8vw, 14px)"
          fontWeight={300}
          color="#1C1C19"
          noWrap
          sx={{
            ...(isCanceledOrder && {
              textDecorationLine: "line-through",
              textDecorationColor: "rgb(28, 28, 25)",
            }),
          }}
        >
          {ordered}
        </Typography>
      </Grid>

      {/* Created By */}
      <Grid xs={1.2} item>
        <Typography
          fontSize="clamp(12px, 0.8vw, 14px)"
          fontWeight={300}
          color="#1C1C19"
          noWrap
        >
          {createdBy}
        </Typography>
      </Grid>

      {/* Total Items */}
      <Grid xs={1.2} item>
        <Typography fontSize="clamp(12px, 0.8vw, 14px)" variant="lightText">
          {totalItems}
        </Typography>
      </Grid>

      {/* Total */}
      <Grid xs={1.2} item>
        <Typography
          fontSize="clamp(12px, 0.8vw, 14px)"
          variant="typography"
          sx={{
            ...(isCanceledOrder && {
              textDecorationLine: "line-through",
              textDecorationColor: "rgb(28, 28, 25)",
            }),
          }}
        >
          {total}
        </Typography>
      </Grid>

      {/* Payment */}
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          justifyContent: "flex-start",
        }}
        xs={1.4}
        item
      >
        <PaymentStatuses str={payment} />
      </Grid>

      {/* Fulfilled */}
      <Grid xs={1.2} item>
        <Typography
          sx={{
            color: isFulfilled && "#26874B",
            fontSize: "clamp(12px, 0.8vw, 14px)",
          }}
          variant="typographyMedium"
        >
          {fulfilled}
        </Typography>
      </Grid>

      {/* View Order button */}
      <Grid
        sx={{
          display: "flex",
          justifyContent: "right",
        }}
        xs={1.3}
        item
      >
        <Button
          sx={{
            minWidth: 105,
            maxWidth: "fit-content",
            height: 26,
            fontSize: "clamp(12px, 0.8vw, 14px)",
            fontWeight: 300,
            lineHeight: "normal",
            color: "#1C1C19",
            borderColor: "#DBE2ED",
            borderRadius: "8px",
          }}
          variant="outlined"
          onClick={handleViewOrder}
        >
          View Order
        </Button>
      </Grid>
    </Grid>
  );
};
OrdersTabItem.propTypes = {
  customId: string,
  supplier: string,
  ordered: string,
  id: string,
  createdBy: string,
  totalItems: number,
  total: string,
  payment: string,
  fulfilled: string,
  isFulfilled: bool,
  handleViewOrder: func,
  isCanceledOrder: bool,
};
OrdersTabItem.defaultProps = {
  customId: "",
  supplier: "",
  ordered: "",
  id: "",
  createdBy: "",
  totalItems: 0,
  total: "",
  payment: "",
  fulfilled: "",
  isFulfilled: false,
  handleViewOrder: () => {},
  isCanceledOrder: false,
};
