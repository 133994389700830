import { func, number, object, string } from "prop-types";

import { StyledTextField } from "components";
import { SearchIcon, SettingsIcon } from "components/Icons";
import { MobileChipList } from "pages/ProductsPage/components";

import useStyles from "../../pages/ProductsPage/components/SearchBlock/styles";

import { Box, Button, InputAdornment, Stack } from "@mui/material";

export const MobileSearchBlock = ({
  chipData = [],
  filterCount,
  searchInput,
  handleSearch,
  handleDeleteChip,
  openFilterDrawer,
  placeholder = "Search orders",
}) => {
  const classes = useStyles();

  return (
    <Stack width="100%" gap="2px" mt="6px" mb="12px">
      <Box
        sx={{
          backgroundColor: "#ffffff",
          display: "flex",
          borderWidth: { xs: 0, sm: "0.5px" },
          borderStyle: "solid",
          borderColor: "#d5d9d9",
          borderRadius: "4px",
          alignItems: "center",
          padding: { xs: 0, sm: "0px 14px 0px 14px" },
          gap: "11px",
        }}
      >
        <StyledTextField
          inputSx={{
            height: "36px",
            fontSize: "14px",
            fontWeight: 300,
            borderRadius: "8px",
            backgroundColor: "rgba(213.42, 213.42, 213.42, 0.16)",
            "& > fieldset": {
              borderColor: "rgb(213,217,217)!important",
              borderWidth: "0px !important",
            },
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon strokeWidth={1.5} />
            </InputAdornment>
          }
          placeholder={placeholder}
          value={searchInput}
          onChange={handleSearch}
        />
        <Box position="relative" width="36px" height="36px">
          <Button className={classes.filterBtn} onClick={openFilterDrawer}>
            <SettingsIcon />
          </Button>
          {!!filterCount && (
            <Stack className={classes.filterBadge}>{filterCount}</Stack>
          )}
        </Box>
      </Box>
      <MobileChipList {...{ chipData, handleDeleteChip, filterCount }} />
    </Stack>
  );
};

MobileSearchBlock.propTypes = {
  chipData: object,
  handleSearch: func,
  filterCount: number,
  searchInput: string,
  placeholder: string,
  handleDeleteChip: func,
  openFilterDrawer: func,
};
