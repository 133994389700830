import { Box } from "@mui/system";
import { object } from "prop-types";
import SectionHeader from "../SectionHeader";
import SectionItem from "../SectionItem";

const LocationsTab = ({ supplier }) => {
  return (
    <Box>
      <SectionHeader title="Locations" />
      <SectionItem
        title="Fulfillment"
        value={supplier.shippingAddress?.formatted_address ?? "-"}
        justifyContent={location?.formatted_address ? "flex-start" : ""}
        titleWidth="15%"
      />
      <SectionItem
        title="Office"
        value={supplier.billingAddress?.formatted_address ?? "-"}
        justifyContent={location?.formatted_address ? "flex-start" : ""}
        titleWidth="15%"
      />
      {supplier?.locations?.length
        ? supplier.locations.map((location) => (
            <SectionItem
              key={location?.id}
              title={location?.name}
              value={location?.formatted_address}
              justifyContent={
                location?.formatted_address ? "space-between" : ""
              }
              titleWidth="15%"
            />
          ))
        : null}
    </Box>
  );
};

LocationsTab.propTypes = {
  supplier: object,
};

export default LocationsTab;
