// eslint-disable-next-line react/prop-types
export const RefundedIcon = ({ fill = "#ff6969" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.823"
      height="11.106"
      viewBox="0 0 9.823 11.106"
    >
      <path
        id="Union_273"
        data-name="Union 273"
        d="M-10453.574,7478.6H-10460v-1.116h6.427v-2.441l3.4,3.026-3.4,3.027Z"
        transform="translate(10460.001 -7469.988)"
        fill={fill}
      />
      <path
        id="Union_274"
        data-name="Union 274"
        d="M-10453.574,7478.6H-10460v-1.116h6.427v-2.441l3.4,3.026-3.4,3.027Z"
        transform="translate(-10450.178 7481.095) rotate(180)"
        fill={fill}
      />
    </svg>
  );
};
