import { makeStyles } from "@mui/styles";
import { colors } from "colors";

const useStyles = makeStyles(() => ({
  contactWrap: {
    position: "absolute",
    top: 0,
    left: "50vw",
    transform: "translateX(-50%)",
    backgroundColor: "#F8F8F8",
    borderRadius: "0 0 23px 23px",
    padding: "0 25px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    zIndex: "10000",
    "& .MuiTypography-root": {
      fontSize: "14px",
    },
    gap: "7px",
  },

  divider: {
    maxHeight: "15px",
  },

  section: {
    display: "flex",
    gap: "7px",
    alignItems: "center",
    "& .MuiTypography-root": {
      color: colors.grey,
    },
  },

  phoneIcon: {
    transform: "rotate(90deg)",
  },
}));

export default useStyles;
