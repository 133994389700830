import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  blockWrapper: {
    width: "100%",
    border: "1px solid #d5d9d9",
    borderRadius: "4px",
    padding: "18px 16px 26px 0",
  },

  blockTitle: {
    fontSize: "19px",
    fontWeight: "500",
    marginBottom: "18px",
  },

  optionsWrapper: {
    width: "100%",
    maxHeight: "218px",
    overflow: "auto",
    paddingLeft: "16px",
  },
}));

export default useStyles;
