import { bool } from "prop-types";
import { useContext } from "react";

import { CartContext } from "pages/CartPage/CartPage";

import { cl, useStyles } from "./styles";

import { Button, CircularProgress, Stack, Typography } from "@mui/material";

export const CartActionBlock = ({ isFirstStep }) => {
  const classes = useStyles();
  const {
    handleIncreaseUserStep,
    draftsLoading,
    errors,
    disabledCreateOrderBtn,
    cartData,
  } = useContext(CartContext);

  const disabledFirstStep = isFirstStep && !!errors?.moqField;

  const { moqField, ...restErrors } = errors || {};

  return (
    <Stack sx={cl.actionWrapper}>
      <Button
        disabled={
          disabledFirstStep ||
          (!isFirstStep && !!Object.keys(restErrors).length) ||
          draftsLoading ||
          cartData.loading ||
          disabledCreateOrderBtn
        }
        variant="contained"
        className={classes.proccedBtn}
        onClick={handleIncreaseUserStep}
      >
        {draftsLoading ? (
          <CircularProgress size={24} sx={{ color: "#ffff", my: "2px" }} />
        ) : (
          <Typography className={classes.proccedText} noWrap>
            {isFirstStep ? "Proceed to checkout" : "Place your order"}
          </Typography>
        )}
      </Button>
    </Stack>
  );
};

CartActionBlock.propTypes = {
  isFirstStep: bool,
};
