import { useDispatch } from "react-redux";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { useLocation, useNavigate } from "react-router-dom";

import { cl } from "../OrderHeaderBlock/styles";
import useStyles from "../MobileHeader/styles";

import { StyledArrowIcon } from "components/Icons";

import { IconButton, Stack, Typography } from "@mui/material";

export const ViewOrderHeaderBlock = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const handleClickBack = () => navigate(-1);

  const showHelpDrawer = () =>
    dispatch(showCurrentDrawerAction({ type: "helpDrawer", show: true }));

  return (
    <Stack sx={cl.wrapper} direction="row">
      <Stack
        sx={{
          ...cl.backArrowBlock,
          minWidth: "30px",
          width: "fit-content",
        }}
      >
        <IconButton sx={{ p: 0, width: "24px" }} onClick={handleClickBack}>
          <StyledArrowIcon />
        </IconButton>
      </Stack>
      <Typography sx={cl.titleText} noWrap>
        Order #{state?.orderNumber}
      </Typography>
      <Stack gap={2} justifyContent="flex-end" alignItems="center" width="65px">
        {!!state?.distributor && (
          <Typography className={styles.helpText} onClick={showHelpDrawer}>
            Questions?
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
