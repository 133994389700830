import { Box, Typography } from "@mui/material";
import { string } from "prop-types";
import { ActiveIcon } from "../Icons";
import { PASSWORD_CRITERIA } from "./PasswordCriteria.constants";

export const PasswordCriteria = ({ password, confirmPassword }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="5px"
      pt={{ xs: "6px", sm: "15px" }}
    >
      {PASSWORD_CRITERIA.map((criteria) => {
        const completed = criteria.condition({
          password,
          confirmPassword,
        });
        return (
          <Box
            key={criteria.title}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              "& > *": { transition: "all 0.4s ease" },
            }}
          >
            <Box
              sx={{
                width: "16px",
                height: "16px",
                border: `1px solid ${completed ? "#47A06D" : "#DEDEDE"}`,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActiveIcon
                width="8.83"
                height="6.36"
                stroke={completed ? "#47A06D" : "#9C9C94"}
              />
            </Box>
            <Typography fontSize={11} color={completed ? "primary" : "#9C9C94"}>
              {criteria.title}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

PasswordCriteria.propTypes = {
  password: string,
  confirmPassword: string,
};

export default PasswordCriteria;
