import { Box, Typography } from "@mui/material";
import { string } from "prop-types";

export const TitleBlock = ({ text }) => {
  return (
    <Box
      sx={{
        fontSize: 30,
        color: "#000",
        mt: 5,
        mb: 2.5,
      }}
      component={Typography}
    >
      {text}
    </Box>
  );
};

TitleBlock.propTypes = { text: string };
TitleBlock.defaultProps = { text: "" };
