import { array, func, object } from "prop-types";
import { useSelector } from "react-redux";

import { ContentBlock } from "./components/ContentBlock";

import { useSelectCardDrawer } from "./useSelectCardDrawer";

import { useStyles } from "../ContactDrawer/styles";

import { Drawer } from "@mui/material";
import { DrawerHeader } from "components/ContactDrawer/components/DrawerHeader";
import { ActionBlock } from "./components/ActionBlock";

export const SelectCardDrawer = ({
  selectedCard,
  handleSelectCard,
  cardList,
  isCardExpired,
  handleDeleteCard,
  setCardForEdit,
  drawerSx = {},
  handleConfirm,
}) => {
  const classes = useStyles();
  const isDrawerOpened = useSelector(({ uiState }) => uiState.selectCardDrawer);

  const { onClose, onAddNewCard, onEditOpen, onConfirmDelete } =
    useSelectCardDrawer({
      setCardForEdit,
      handleDeleteCard,
    });

  return (
    <Drawer
      anchor="bottom"
      open={isDrawerOpened}
      onClose={onClose}
      PaperProps={{ className: classes.drawerWrapper, sx: drawerSx }}
    >
      <DrawerHeader
        {...{
          withDivider: false,
          handleClose: onClose,
          title: "Select payment method",
        }}
      />
      <ContentBlock
        {...{
          cardList,
          selectedCard,
          handleSelectCard,
          isCardExpired,
          onAddNewCard,
          handleDeleteCard: onConfirmDelete,
          handleConfirm,
          onEditOpen,
        }}
      />
      {!!handleConfirm && (
        <ActionBlock
          disabledProceed={!cardList.length}
          handleConfirm={() => {
            onClose();
            handleConfirm();
          }}
        />
      )}
    </Drawer>
  );
};

SelectCardDrawer.propTypes = {
  selectedCard: object,
  drawerSx: object,
  handleSelectCard: func,
  isCardExpired: func,
  handleConfirm: func,
  handleDeleteCard: func,
  setCardForEdit: func,
  cardList: array,
};
