import { AddCardDialog } from "components";
import { TabsBlock, TransactionsTab, WalletTab } from "./components";

import { TABS } from "../../PaymentsPage.constants";

import { Box } from "@mui/material";
import { useContext } from "react";
import { PaymentsContext } from "pages/PaymentsPage/PaymentsPage";

export const DesktopPayments = () => {
  const {
    cardOpen,
    setCardOpen,
    rows,
    customerId,
    currentUser,
    handleSaveCard,
    tab,
    setTab,
    setConfirmState,
    onConfirmDelete,
    handleSaveEditCard,
    handleSetDefault,
  } = useContext(PaymentsContext);

  return (
    <>
      <AddCardDialog
        isOpen={cardOpen}
        handleClose={() => setCardOpen(false)}
        customerCreditCards={rows}
        customerId={customerId}
        billingAddress={currentUser?.billingAddress}
        handleSave={handleSaveCard}
      />
      <Box mx={1}>
        <TabsBlock
          currentTab={tab}
          setCurrentTab={setTab}
          onClickAddCard={() => setCardOpen(true)}
        />

        {tab === TABS[0] && (
          <WalletTab
            cardState={rows}
            customerId={customerId}
            handleConfirmDeleteCard={(card) => {
              setConfirmState((prev) => ({
                ...prev,
                onConfirm: onConfirmDelete,
                title: "Delete payment method?",
                isOpen: true,
                cardStateItem: card,
              }));
            }}
            handleSave={handleSaveEditCard}
            handleSetDefault={handleSetDefault}
          />
        )}
        {tab === TABS[1] && <TransactionsTab />}
      </Box>
    </>
  );
};
