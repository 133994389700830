import {
  string,
  object,
  oneOfType,
  bool,
  func,
  array,
  arrayOf,
  shape,
} from "prop-types";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { PrimaryIcon } from "components/Icons";
import { cl } from "components/ContactDrawer/styles";
import { Controller } from "react-hook-form";

export const DetailsItem = ({
  name,
  value = "",
  isPrimary,
  showEditBtn,
  isEdit,
  fieldName,
  onEditOpen,
  control,
  Input,
  subFields,
  inputProps,
  isSelect,
  menuList = [],
  selectProps,
  wrapperProps,
  disabled,
}) => {
  return (
    <Box {...wrapperProps}>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          sx={{
            width: `calc(100% - ${showEditBtn ? "36px" : "0px"})`,
          }}
        >
          <Typography fontSize={12} fontWeight={600} color="#000" noWrap>
            {name}
          </Typography>
          {isPrimary && (
            <IconButton sx={{ p: 0, height: "16px" }} disableTouchRipple>
              <PrimaryIcon width={52} height={16} />{" "}
            </IconButton>
          )}
        </Stack>

        {showEditBtn && (
          <Button
            onClick={onEditOpen}
            disabled={disabled}
            variant="contained"
            sx={{
              backgroundColor: "transparent !important",
              minWidth: "fit-content",
              minHeight: "fit-content",
              fontSize: "14px",
              fontWeight: 600,
              color: "#409A65",
              boxShadow: "none !important",
              p: 0,
            }}
          >
            {isEdit ? "Save" : "Edit"}
          </Button>
        )}
      </Stack>
      {isEdit ? (
        <>
          {isSelect ? (
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Input error={error?.message || ""} {...field} {...selectProps}>
                  {Object.keys(menuList).map((key) => (
                    <MenuItem key={key} value={key}>
                      {menuList[key]}
                    </MenuItem>
                  ))}
                </Input>
              )}
              name={fieldName}
              control={control}
            />
          ) : (
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Input
                  fullWidth
                  size="large"
                  error={error?.message || ""}
                  noErrorMessage
                  {...inputProps}
                  {...field}
                  value={field.value ?? ""}
                  InputProps={{
                    sx: {
                      ...cl.textInput,
                      "& > fieldset": {
                        borderColor: error
                          ? "rgba(228, 30, 58, 0.60) !important"
                          : "rgb(213,217,217)!important",
                        borderWidth: error
                          ? "1px !important"
                          : "0px !important",
                      },
                    },
                  }}
                />
              )}
              name={fieldName}
              control={control}
            />
          )}
        </>
      ) : (
        <Box
          fontSize={14}
          fontWeight={400}
          color="#00000060"
          noWrap
          component={Typography}
        >
          {value}
        </Box>
      )}
      {isEdit && !!subFields?.length && (
        <Stack width="100%" gap="10px">
          {subFields.map(
            (
              {
                subFieldName,
                subName,
                SubInput,
                subInputProps,
                wrapperProps,
                isSelect,
                subSelectProps,
                menuList,
              },
              index
            ) => (
              <Stack key={index} {...wrapperProps}>
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  color="#000"
                  gap={1}
                  noWrap
                >
                  {subName}
                </Typography>{" "}
                {isSelect ? (
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <SubInput
                        error={error?.message || ""}
                        {...field}
                        {...subSelectProps}
                      >
                        {Object.keys(menuList).map((key) => (
                          <MenuItem key={key} value={key}>
                            {menuList[key]}
                          </MenuItem>
                        ))}
                      </SubInput>
                    )}
                    name={subFieldName}
                    control={control}
                  />
                ) : (
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <SubInput
                        fullWidth
                        size="large"
                        error={error?.message || ""}
                        noErrorMessage
                        {...subInputProps}
                        {...field}
                        value={field.value ?? ""}
                        InputProps={{
                          sx: {
                            ...cl.textInput,
                            "& > fieldset": {
                              borderColor: error
                                ? "rgba(228, 30, 58, 0.60) !important"
                                : "rgb(213,217,217)!important",
                              borderWidth: error
                                ? "1px !important"
                                : "0px !important",
                            },
                          },
                        }}
                      />
                    )}
                    name={subFieldName}
                    control={control}
                  />
                )}
              </Stack>
            )
          )}
        </Stack>
      )}
    </Box>
  );
};

DetailsItem.propTypes = {
  name: string,
  value: oneOfType([string, object]),
  isPrimary: bool,
  isEdit: bool,
  showEditBtn: bool,
  fieldName: string,
  onEditOpen: func,
  control: object,
  customInput: object,
  Input: oneOfType([func, object]),
  inputProps: object,
  selectProps: object,
  isSelect: bool,
  menuList: oneOfType([string, object]),
  wrapperProps: object,
  disabled: bool,
  subFields: arrayOf(
    shape({
      subFieldName: string,
      subName: string,
      SubInput: oneOfType([func, object]),
      subInputProps: object,
      wrapperProps: object,
      isSelect: bool,
      subSelectProps: object,
      menuList: array,
    })
  ),
};
