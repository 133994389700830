import { PricingIcon, ReorderingIcon } from "components/Icons";

export const INVITATION_PATH = "orderdirect";

export const FILTER_DATES = [
  { name: "All time" },
  { name: "Today" },
  { name: "Yesterday" },
  { name: "This week" },
  { name: "Last week" },
  { name: "This month" },
  { name: "Last month" },
  { name: "This year" },
  { name: "Last year" },
];
export const PATH_NAMES = [
  "/",
  "/orders",
  "/suppliers",
  // "/reports",
  "/cart",
  "/notifications",
  "/conversations",
];

export const CANADAS_STATE_NAMES = {
  AB: "Alberta",
  BC: "British Columbia ",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NS: "Nova Scotia",
  ON: "Ontario",
  PEI: "Prince Edward Island",
  QC: "Quebec",
  SK: "Saskatchewan",
};

export const STATE_NAMES = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const PER_PAGE_COUNT_LIST = [10, 20, 50, 100, 150];
export const SCROLL_LIMIT_DISTRIBUTORS = 20;
export const SCROLL_LIMIT_SALES = 20;
export const PER_PAGE_COUNT_PRODUCTS = PER_PAGE_COUNT_LIST[2];

export const COUNTRY_PHONE_CODES = [
  {
    code: "US",
    phone_code: "+1",
    label: "United States",
    format: "(###) ### ####",
    digits: 11,
  },
];

export const CANADA_PHONE_CODES = [
  {
    code: "CA",
    phone_code: "+1",
    label: "Canada",
    format: "(###) ### ####",
    digits: 11,
  },
];

export const ORDER_DIRECT_STATUSES = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
};

export const STORE_LABELS = [
  { label: "Business Pricing", icon: <PricingIcon /> },
  {
    label: "Easy Reordering",
    icon: <ReorderingIcon />,
  },
];

export const PRODUCT_TYPE_INVENTORY = {
  inventory: "INVENTORY",
  non_inventory: "NON_INVENTORY",
};

export const shippingLinks = {
  FedEx: "https://www.fedex.com/fedextrack/?tracknumbers=",
  UPS: "https://www.ups.com/track?track=yes&trackNums=",
  USPS: "https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=",
};

export const EMAIL_REGEXP =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))\.)+(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))$/i; // eslint-disable-line
