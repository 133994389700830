export const AttachmentIcon = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.215 9.536a2.65 2.65 0 0 1-.813-1.945q0-1.131.813-1.944l3.713-3.713q.583-.583 1.414-.583.83 0 1.414.583.583.584.583 1.414 0 .831-.583 1.415L5.397 8.12a1.2 1.2 0 0 1-.884.372q-.512 0-.884-.372a1.2 1.2 0 0 1-.37-.883q0-.513.37-.884l3.36-3.36.53.531-3.36 3.359q-.15.15-.15.354t.15.353q.152.15.354.15.204 0 .354-.15l3.359-3.359q.37-.37.37-.884 0-.512-.37-.883a1.2 1.2 0 0 0-.884-.372q-.513 0-.884.372L2.746 6.177q-.585.583-.584 1.414 0 .83.584 1.414.583.585 1.414.584.83 0 1.414-.584l3.712-3.712.53.53-3.712 3.713a2.65 2.65 0 0 1-1.944.813 2.65 2.65 0 0 1-1.945-.813"
      fill="#939393"
    />
  </svg>
);
