import { makeStyles } from "@mui/styles";

export const cl = {
  cardWrapper: {
    flexDirection: "row",
    gap: "15px",
    alignItems: "center",
    padding: "8px 10px",
    borderRadius: "8px",
    overflow: "hidden",
    height: "70px",
    border: "1px rgba(0, 0, 0, 0.05) solid",
  },
  cardNumber: {
    color: "rgba(0, 0, 0, 0.80)",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    wordWrap: "break-word",
  },
  cardName: {
    color: "rgba(0, 0, 0, 0.40)",
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "15px",
    wordWrap: "break-word",
  },
  cardActions: {
    ml: "auto",
    justifyContent: "space-between",
    gap: "4px",
    flexDirection: "column",
    height: "100%",
  },
  editBtn: {
    height: "18px",
    color: "#409A65",
    fontSize: "14px",
    fontWeight: 600,
    padding: 0,
    whiteSpace: "nowrap",
    width: "fit-content",
    minWidth: 0,
    mb: "auto",

    "& svg": {
      width: "18px",
      height: "18px",
      marginRight: "2px",
    },
  },
  deleteIcon: {
    width: "28px",
    background: "rgba(0, 0, 0, 0.02)",
    borderRadius: "8px",
    height: "28px",
    padding: 0,

    svg: {
      width: "16px",
      height: "16px",
    },
  },
};

export const useStyles = makeStyles(() => ({
  invoiceBtn: {
    width: "100%",
    fontWeight: 600,
    height: "49px",
    fontSize: "16px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.10)",
    borderRadius: "12px",
    padding: "10.5px 16px",
    marginTop: "auto",
  },
}));
