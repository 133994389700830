import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { Box, Stack } from "@mui/material";

import { Footer, HeaderBar, HelpDrawer, Loader } from "components";

import { authLoadingSelector, currentUserSelector } from "redux/selectors/auth";
import { getDraftsAction } from "redux/actions/drafts";
import UniversalConfirm from "components/UniversalConfirm/UniversalConfirm";
import { useShowProductsWithoutLogIn } from "helpers/hooks";
import { useAppWithoutLogIn } from "pages/ProductsPage/useAppWithoutLogIn";
import { useMediaDevice } from "hooks/useMediaDevice";
import { useSuppliers } from "pages/ProductsPage/useSuppliers";
import RequestWholesale from "components/RequestWholesaleDialog/RequestWholesale";
import { deleteDoc, doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebase/notifications/config";
import { error } from "helpers/notifications";
import { setCurrentUser } from "redux/actions/auth";
import { getCurrentUser } from "services/account";
import { draftsListSelector } from "redux/selectors/drafts";

const selector = createSelector(
  currentUserSelector,
  draftsListSelector,
  authLoadingSelector,
  (currentUser, draftsList, authLoading) => ({
    currentUser,
    draftsList,
    authLoading,
  })
);
export const ConfirmContext = createContext({});
export const SuppliersContext = createContext({});

let unsubscribe = {};

export const MasterPage = () => {
  const { currentUser, authLoading } = useSelector(selector);

  const dispatch = useDispatch();

  const { state } = useLocation();
  const { isMobile } = useMediaDevice();
  const { isShowProductsWithoutLogIn } = useShowProductsWithoutLogIn();

  const isLoading = useMemo(() => authLoading, [authLoading]);

  const [confirmState, setConfirmState] = useState({
    onConfirm: () => {},
    title: "",
    isOpen: false,
    cardStateItem: {},
    type: "dialog",
    text: "",
    subText: "",
    titleElement: null,
  });

  useEffect(() => {
    if (isShowProductsWithoutLogIn) return;
    dispatch(getDraftsAction());
  }, [dispatch, isShowProductsWithoutLogIn]);

  const appWithoutLogInState = useAppWithoutLogIn();

  const { suppliersState, getSortedSuppliersList } = useSuppliers({
    checkedSupplier: state?.filter?.supplier,
  });

  const getUser = useCallback(async () => {
    const user = await getCurrentUser();
    dispatch(setCurrentUser(user));
  }, [dispatch]);

  const fetchDeliveriesCollection = useCallback(
    async ({ id }) => {
      const distributor = currentUser?.orderDirect?.find(
        (item) => item.distributor.id === id
      );
      // eslint-disable-next-line no-console
      if (!(id || distributor)) return console.error("no id or distributor");

      unsubscribe[id] = onSnapshot(
        doc(firestore, `distributor-settings/${id}`),
        { includeMetadataChanges: true },
        (doc) => {
          const { allowStoreCreditCardPayments = null } = doc.data() || {};

          if (
            allowStoreCreditCardPayments !== null &&
            allowStoreCreditCardPayments !==
              distributor?.allowStoreCreditCardPayments
          ) {
            getUser();
          }
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          error(`Firebase: ${err?.message || "Something went wrong."}`);
        }
      );
    },
    [currentUser?.orderDirect, getUser]
  );

  useEffect(() => {
    if (!currentUser?.orderDirect?.length) return;
    currentUser?.orderDirect.forEach((direct) => {
      const id = direct?.distributor?.id;
      fetchDeliveriesCollection({ id });
    });

    return () =>
      currentUser?.orderDirect.forEach((direct) => {
        const id = direct?.distributor?.id;
        !!unsubscribe[id] && unsubscribe[id]();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id]);

  const setDraftCollection = async (customerId) => {
    await deleteDoc(doc(firestore, "app-customers", customerId));
  };

  const fetchDraftsCollection = useCallback(
    async ({ customerId }) => {
      // eslint-disable-next-line no-console
      if (!customerId) return console.error("no customerId");

      unsubscribe[customerId] = onSnapshot(
        doc(firestore, `app-customers/${customerId}`),
        { includeMetadataChanges: true },
        (doc) => {
          const { paymentMethodsUpdateRequired } = doc?.data() || {};

          if (paymentMethodsUpdateRequired) {
            getUser();
            dispatch(getDraftsAction());

            setDraftCollection(customerId);
          }
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          error(`Firebase: ${err?.message || "Something went wrong."}`);
        }
      );
    },
    [dispatch, getUser]
  );

  // SD-8026 Create order | Payment term issue
  useEffect(() => {
    if (isShowProductsWithoutLogIn) return;
    if (!currentUser?.id) return;

    fetchDraftsCollection({ customerId: currentUser?.id });

    return () =>
      !!unsubscribe[currentUser?.id] && unsubscribe[currentUser?.id]();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id]);

  return (
    <ConfirmContext.Provider
      value={{ confirmState, setConfirmState, appWithoutLogInState }}
    >
      <SuppliersContext.Provider
        value={{ suppliersState, getSortedSuppliersList }}
      >
        <Stack sx={{ height: { xs: "100%", sm: "100vh" } }} direction="column">
          <HelpDrawer />
          <UniversalConfirm />
          <RequestWholesale />
          <Loader isLoading={isLoading} />
          <HeaderBar />

          <Stack
            sx={{
              flexGrow: 1,
              paddingLeft: {
                xs: !state?.product && "16px",
                sm: !state?.product && "24px",
              },
              paddingRight: {
                xs: !state?.product && "16px",
                sm: !state?.product && "24px",
              },
              position: "relative",
            }}
          >
            <Outlet />
          </Stack>
          {!isMobile && (
            <Box component="footer">
              <Footer />
            </Box>
          )}
        </Stack>
      </SuppliersContext.Provider>
    </ConfirmContext.Provider>
  );
};
