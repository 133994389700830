import {} from "react";
import { string } from "prop-types";
import { Box } from "@mui/material";

export const SummaryLogo = ({ src }) => {
  return (
    <Box
      sx={{
        width: "47px",
        height: "47px",
        minWidth: "47px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "50%",
        }}
        component="img"
        src={src}
        alt="Logo supplier"
      />
    </Box>
  );
};

SummaryLogo.propTypes = { src: string };
SummaryLogo.defaultProps = {};
