import { makeStyles } from "@mui/styles";
import { colors } from "colors";

const useStyles = makeStyles(() => ({
  rejectWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100% - 40px)",
  },

  rejectScreen: {
    border: `0.5px solid ${colors.borderOutline}`,
    borderRadius: "18px",
    padding: "5.8vh 4.2vw 3.7vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "748px",
  },

  logo: {
    maxWidth: "214px",
    maxHeight: "56px",
    width: "214px",
    height: "56px",
    borderRadius: "4px",
    objectFit: "cover",
  },

  titleWrap: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: "25px",
    },
  },

  dangerIconWrap: {
    padding: "3.8vh 0 3.6vh",
  },

  mainText: {
    whiteSpace: "pre-wrap",
    textAlign: "center",
    fontSize: "clamp(16px, 1.3vw, 25px)",
    color: colors.greyLight,
    marginBottom: "4.5vh",
  },

  contactButton: {
    width: "466px",
    height: "45px",
  },
}));

export default useStyles;
