import { CircularProgress, Stack, Typography } from "@mui/material";
import { EmptyCartIcon } from "components/Icons";
import { CartContext } from "pages/CartPage/CartPage";
import React, { useContext } from "react";

export const EmptyProductsList = () => {
  const { draftsLoading } = useContext(CartContext);

  return (
    <Stack
      sx={{
        height: "calc(100svh - 157px)",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {draftsLoading ? (
        <CircularProgress size={26} />
      ) : (
        <Stack direction="row" alignItems="center" mr="16px">
          <EmptyCartIcon />{" "}
          <Typography
            sx={{
              color: "#2F3040",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "18.90px",
            }}
          >
            Your cart is empty...
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
