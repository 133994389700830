import { array, func, string } from "prop-types";

import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { StyledTooltip } from "components";

const useStyles = makeStyles(() => ({
  label: {
    "& .MuiFormLabel-root": {
      color: "#9C9C94",
      fontSize: "12px",
      top: 0,
    },
    "& .Mui-focused": {
      color: "#47A06D",
      fontSize: "1rem",
      top: 0,
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
  },
  tabs: {
    minHeight: "32px",
    maxHeight: "32px",
    "& .Mui-selected": {
      backgroundColor: "transparent!important",
    },
    "& .MuiBox-root": {
      position: "relative",
      bottom: "8px",
    },
    "& .MuiButtonBase-root": {
      minWidth: "55px",
      maxWidth: "55px",
    },
    "& .MuiTab-root": {
      marginRight: "15px",
    },
  },
  smallerButton: {
    height: "22px",
    "& > span": {
      fontSize: "11px",
    },
  },

  biggerButton: {
    "& > span": {
      fontSize: "14px",
    },
  },
}));

export const OrdersSwitcherTab = ({
  tabs,
  currentTab,
  // handleSetCurrentTab,
}) => {
  const classes = useStyles();

  // const handleChangeCurrentTab = (e, newValue) => {
  //   handleSetCurrentTab(newValue);
  // };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mt: "10px",
      }}
    >
      {tabs && (
        <Tabs
          className={classes.tabs}
          value={currentTab}
          // onChange={handleChangeCurrentTab}
        >
          {/* {tabs.map((tab) => ( */}
          <Tab
            sx={{
              py: 0,
              height: "44px",
              fontSize: "12px",
              fontWeight: 400,
              color: "#9C9C94",
            }}
            key={tabs[0]}
            label={<Box sx={{ textTransform: "none" }}>{tabs[0]}</Box>}
            value={tabs[0]}
          />
          <StyledTooltip
            title="Coming soon"
            placement="bottom"
            arrow
            modifiers={[{ name: "offset", options: { offset: [0, -18] } }]}
          >
            <Tab
              sx={{
                py: 0,
                height: "44px",
                fontSize: "12px",
                fontWeight: 400,
                color: "#9C9C94",
              }}
              // key={tab}
              label={<Box sx={{ textTransform: "none" }}>{tabs[1]}</Box>}
              // value={tab}
            />
          </StyledTooltip>

          {/* ))} */}
        </Tabs>
      )}
    </Box>
  );
};

OrdersSwitcherTab.propTypes = {
  tabs: array,
  currentTab: string,
  handleSetCurrentTab: func,
};
OrdersSwitcherTab.defaultProps = {
  tabs: [],
};
