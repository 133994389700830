import { useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { array, bool, func, number, object, string } from "prop-types";
import useStyles from "./styles";
import { StyledTooltip } from "components";

const SettingsBlock = ({
  title,
  multiselect,
  items,
  handleChange,
  handleFetch,
  itemsCount,
  hideCounter,
  checkedItemsIds,
  classNames,
  ...props
}) => {
  const classes = useStyles();

  const getItemCount = useCallback((count) => `(${count ?? 0})`, []);

  return (
    <Box className={classes.blockWrapper} {...props}>
      <Typography
        marginLeft="16px"
        className={`${classes.blockTitle} ${classNames.title}`}
        noWrap
      >
        {title}
      </Typography>
      <Box id={`${title}-settings-scroll`} className={classes.optionsWrapper}>
        <InfiniteScroll
          dataLength={items?.length}
          next={handleFetch}
          loader={
            <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
          }
          hasMore={items?.length < itemsCount}
          scrollableTarget={`${title}-settings-scroll`}
        >
          {multiselect ? (
            <FormGroup sx={{ width: "100%" }}>
              {items.map((item) => {
                return (
                  <FormControlLabel
                    className={classNames.items}
                    sx={{ width: "100%" }}
                    key={item?.id || `${item?.name}-${item?.productCount}`}
                    label={
                      <Typography
                        sx={{
                          width: "87%",
                          display: "inline-flex",
                          gap: 1,
                        }}
                        component="div"
                      >
                        <StyledTooltip placement="top" title={item.name}>
                          <Typography noWrap>{item.name}</Typography>
                        </StyledTooltip>
                        {!hideCounter && (
                          <Typography>
                            {getItemCount(
                              item?.productCount || item?._count?.products
                            )}
                          </Typography>
                        )}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        onChange={() => handleChange(item)}
                        checked={checkedItemsIds.some((s) => {
                          return s === (item?.id || item?.name);
                        })}
                      />
                    }
                  />
                );
              })}
            </FormGroup>
          ) : (
            <RadioGroup sx={{ marginLeft: "16px" }} defaultValue={""}>
              {items.map((item) => {
                return (
                  <FormControlLabel
                    onChange={() => handleChange(item)}
                    key={item?.id || item.value}
                    className={classNames.items}
                    label={
                      <Typography
                        sx={{
                          fontSize: 16,
                          color: "#8E8E8E", // theme
                        }}
                      >
                        {item.name}
                      </Typography>
                    }
                    value={item.id || item.value}
                    control={<Radio />}
                  />
                );
              })}
            </RadioGroup>
          )}
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

SettingsBlock.propTypes = {
  title: string,
  multiselect: bool,
  items: array,
  handleChange: func,
  handleFetch: func,
  itemsCount: number,
  hideCounter: bool,
  checkedItemsIds: array,
  classNames: object,
};

SettingsBlock.defaultProps = {
  title: "Filters",
  multiselect: false,
  items: [],
  handleChange: () => {},
  handleFetch: () => {},
  hideCounter: false,
  checkedItemsIds: [],
  classNames: {},
};

export default SettingsBlock;
