import React from "react";
import { func, object, bool } from "prop-types";
import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import { StyledImageBox } from "components/InviteVendorDialog/StyledImageBox";
import { HeadrerBlock } from "components/InviteVendorDialog/components/HeadrerBlock";
import { ItemBlock } from "components/InviteVendorDialog/components/ItemBlock";
import useStyles from "./styles";
import { separateNumWithComma } from "helpers/helpers";
import { StyledSelect } from "simply-depo-ui-components";
import { Controller } from "react-hook-form";
import { useOrderDirectConfirm } from "./OrderDirectConfirm.hooks";

const OrderDirectConfirm = ({
  handleUpdateSupplier,
  supplier,
  control,
  requestingOrderDirect,
}) => {
  const classes = useStyles();

  const {
    preparedContacts,
    businessPhone,
    businessEmail,
    shippingAddressSub,
    shippingAddressMain,
    billingAddressSub,
    billingAddressMain,
    businessName,
    fedTax,
    website,
    contacts,
  } = useOrderDirectConfirm();

  return (
    <Box>
      <Box className={classes.supBlockWrapper}>
        <Box className={classes.supCardWrapper}>
          <Box className={classes.supImgWrapper}>
            <StyledImageBox src={supplier?.profilePhoto?.fileName} />
          </Box>
          <Box className={classes.supInfoWrapper}>
            <Typography className={classes.supInfoTextName} noWrap>
              {supplier?.name ?? ""}
            </Typography>
            <Box>
              <Typography className={classes.supInfoTextCompany} noWrap>
                {supplier?.companyIndustry ?? ""}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              pl: 2.75,
            }}
          >
            {supplier?.shippingAddress?.city ||
              (supplier?.shippingAddress?.state && (
                <Typography className={classes.supInfoTextName} noWrap>
                  {`${supplier?.shippingAddress?.city || ""}, ${
                    supplier?.shippingAddress?.state || ""
                  }`}
                </Typography>
              ))}
            <Typography className={classes.supInfoTextCompany} noWrap>
              {supplier?.minimumOrderValueAmount === 0
                ? "No Minimum"
                : `Minimum $${separateNumWithComma(
                    supplier?.minimumOrderValueAmount || 0
                  )}`}
            </Typography>
          </Box>
          <Box mr={1.75} sx={{ backgroundColor: "#fff" }}>
            {!requestingOrderDirect && (
              <Button
                className={classes.btnUpdate}
                variant="outlined"
                color="grey"
                onClick={handleUpdateSupplier}
              >
                Update
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Box mt={2} px={2.75}>
        <Typography className={classes.customerTitle}>
          Order Direct (Confirm Your information)
        </Typography>
        <Grid container>
          <Grid pr={2.65} xs={6} item>
            <HeadrerBlock title="Business details" />
            <ItemBlock
              title="Business Name"
              mainText={businessName}
              borderBottom
            />
            <ItemBlock
              title="FED Tax ID (EIN)"
              mainText={fedTax}
              borderBottom
            />
            <ItemBlock
              title="Billing Address"
              mainText={billingAddressMain}
              subText={billingAddressSub}
              borderBottom
            />
            <ItemBlock
              title="Shipping Address"
              mainText={shippingAddressMain}
              subText={shippingAddressSub}
            />

            <HeadrerBlock mt={1} title="Settings" />
            <ItemBlock
              title="Payment Terms"
              select={
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledSelect
                      formSx={{
                        width: "150px",
                        mr: -2.25,
                        "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
                          paddingLeft: 0,
                        },
                      }}
                      label=""
                      leftSidedIcon
                      displayEmpty
                      MenuProps={{
                        MenuListProps: {
                          sx: {
                            width: "150px",
                          },
                        },
                      }}
                      error={error ? error.message : ""}
                      {...field}
                    >
                      {supplier?.paymentTerms?.map((term) => {
                        return (
                          <MenuItem key={term?.id} value={term?.id}>
                            <Typography fontSize={12} noWrap>
                              {term?.name}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                  )}
                  name="paymentTermsId"
                  control={control}
                />
              }
            />
          </Grid>
          <Grid xs={6} item pl={2.65}>
            <HeadrerBlock title="Business Contact" />
            <ItemBlock
              title="Business Email"
              mainText={businessEmail}
              borderBottom
            />
            <ItemBlock
              title="Business Phone"
              mainText={businessPhone}
              borderBottom
            />
            <ItemBlock title="Website" mainText={website} />

            {!!contacts?.length && <HeadrerBlock mt={1} title="Contacts" />}
            {preparedContacts.map(({ name, main, sub }) => (
              <ItemBlock
                key={name}
                title={name}
                mainText={
                  <Box fontWeight={500} component="span">
                    {main}
                  </Box>
                }
                subText={sub}
                borderBottom
              />
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

OrderDirectConfirm.propTypes = {
  handleUpdateSupplier: func,
  supplier: object,
  control: object,
  requestingOrderDirect: bool,
};

OrderDirectConfirm.defaultProps = {
  handleUpdateSupplier: () => {},
  supplier: null,
  control: {},
  requestingOrderDirect: false,
};

export default OrderDirectConfirm;
