import { object, string } from "prop-types";

import { Box } from "@mui/material";
import { StyledProductIco } from "../../components";

export const StyledImageBox = ({ src, placeholderStyles = {} }) => {
  return (
    <Box
      sx={{
        border: "1px solid #D5D9D9",
        borderRadius: { xs: "6.5px", sm: "4px" },
        backgroundColor: "#F3F3F3",
        width: { xs: "58px", sm: "46px" },
        minWidth: { xs: "58px", sm: "46px" },
        maxWidth: { xs: "58px", sm: "46px" },
        height: { xs: "58px", sm: "46px" },
      }}
    >
      <StyledProductIco
        src={src}
        styles={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
          borderRadius: { xs: "6.5px", sm: "4px" },
        }}
        placeholderStyles={placeholderStyles}
      />
    </Box>
  );
};

StyledImageBox.propTypes = { src: string, placeholderStyles: object };
StyledImageBox.defaultProps = { src: "", placeholderStyles: {} };
