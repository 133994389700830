import { usePlacesWidget } from "react-google-autocomplete";
import { forwardRef, useEffect, useMemo, useState } from "react";
import {
  Button,
  ClickAwayListener,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes, {
  object,
  string,
  bool,
  func,
  any,
  number,
  oneOfType,
} from "prop-types";
import {
  CalendarIcon,
  ClockIcon,
  CrossIcon,
  DeleteIcon,
  SearchIcon,
} from "../Icons";
import { CircularProgress } from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDebounce } from "../../helpers/hooks";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import NumberFormat from "react-number-format";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useMediaDevice } from "hooks/useMediaDevice";

const styles = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgb(213,217,217)",
    },
  },
};

const StyledTextField = forwardRef(
  (
    {
      error,
      label,
      fullWidth,
      formSx,
      noErrorMessage,
      endIcon,
      labelSx,
      startAdornment,
      inputSx,
      errorMsgSx,
      LabelProps,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl
        fullWidth={fullWidth}
        error={!!error}
        sx={{ ...styles, ...formSx }}
      >
        <TextField
          helperText={noErrorMessage ? "" : error}
          size="small"
          FormHelperTextProps={{
            sx: {
              position: "absolute",
              bottom: -20,
              left: 8,
              margin: 0,
              whiteSpace: "nowrap",
              ...errorMsgSx,
            },
          }}
          InputProps={{
            sx: {
              height: "32px",
              fontSize: "12px",
              ...inputSx,
            },
            endAdornment: endIcon,
            startAdornment,
          }}
          InputLabelProps={{
            sx: {
              fontSize: "12px",
              fontWeight: 400,
              color: "#B5B5AC",
              ...labelSx,
            },
            ...LabelProps,
          }}
          fullWidth={fullWidth}
          label={label}
          error={!!error}
          inputRef={ref}
          {...props}
        />
      </FormControl>
    );
  }
);

StyledTextField.propTypes = {
  error: PropTypes.oneOfType([string, bool]),
  label: PropTypes.oneOfType([string, object]),
  fullWidth: bool,
  formSx: object,
  noErrorMessage: bool,
  endIcon: any,
  labelSx: object,
  startAdornment: any,
  inputSx: object,
  errorMsgSx: object,
  LabelProps: object,
};

StyledTextField.defaultProps = {
  error: "",
  label: "",
  fullWidth: true,
  noErrorMessage: true,
};

StyledTextField.displayName = "StyledTextField";

const LabeledTextField = forwardRef(
  (
    {
      error,
      label,
      fullWidth,
      formSx,
      noErrorMessage,
      endIcon,
      labelSx,
      startAdornment,
      inputSx,
      errorMsgSx,
      inputProps,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl
        fullWidth={fullWidth}
        error={!!error}
        sx={{
          "& .MuiInputLabel-root.Mui-error": {
            color: "rgba(228, 30, 58, 0.60)",
          },
        }}
      >
        <InputLabel
          shrink
          htmlFor={label}
          sx={{
            fontSize: 12,
            fontWeight: "500",
            lineHeight: "16px",
            transform: "translate(14px, -17px)",
          }}
        >
          {label}
        </InputLabel>
        <TextField
          id={label}
          size="small"
          fullWidth={fullWidth}
          error={!!error}
          inputRef={ref}
          {...props}
          InputProps={{
            ...inputProps,
            sx: {
              fontWeight: { xs: 500, sm: 400 },
              height: { xs: "48px", sm: "38px" },
              fontSize: { xs: "14px", sm: "15px" },
              borderRadius: { xs: "8px", sm: "4px" },
              backgroundColor: {
                xs: "rgba(213.42, 213.42, 213.42, 0.16)",
                sm: "#ffff",
              },
              "& input": {
                fontSize: { xs: "14px", sm: "15px" },
                padding: { xs: "13.9px 14px", sm: "7px 14px" },
              },
              "&& fieldset": {
                fontSize: { xs: "14px", sm: "15px" },
                borderColor: error
                  ? "rgba(228, 30, 58, 0.60) !important"
                  : "rgba(194, 194, 194, 0.5)",
                borderWidth: {
                  xs: `${error ? "1px" : "0px"} !important`,
                  sm: "1px !important",
                },
                height: { xs: "53px", sm: "41px" },
              },
              "&:hover:not(.Mui-focused)": {
                "&& fieldset": {
                  borderColor: "rgba(194, 194, 194, 0.5)",
                  borderWidth: { xs: "0px !important", sm: "1px" },
                  height: { xs: "53px", sm: "38px" },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                fontSize: { xs: "14px", sm: "17px" },
              },
            },
            endAdornment: endIcon,
            startAdornment,
          }}
        />
      </FormControl>
    );
  }
);

LabeledTextField.propTypes = {
  error: PropTypes.oneOfType([string, bool]),
  label: PropTypes.oneOfType([string, object]),
  fullWidth: bool,
  formSx: object,
  noErrorMessage: bool,
  endIcon: any,
  labelSx: object,
  startAdornment: any,
  inputSx: object,
  errorMsgSx: object,
  inputProps: object,
};

LabeledTextField.defaultProps = {
  error: "",
  label: "",
  fullWidth: true,
  noErrorMessage: true,
};

LabeledTextField.displayName = "LabeledTextField";

const PriceFormat = forwardRef(({ type, ...props }, ref) => {
  const [focused, setFocused] = useState(false);
  return (
    <NumberFormat
      customInput={StyledTextField}
      fullWidth
      size="small"
      placeholder={type === "ABSOLUTE" ? "0.00" : "0"}
      thousandSeparator={type === "ABSOLUTE"}
      decimalScale={type === "ABSOLUTE" ? 2 : 0}
      fixedDecimalScale={type === "ABSOLUTE" && !focused}
      {...props}
      ref={ref}
      onBlur={() => setFocused(false)}
      onFocus={() => setFocused(true)}
    />
  );
});

PriceFormat.displayName = "PriceFormat";

PriceFormat.propTypes = { type: string };
PriceFormat.defaultProps = { type: "ABSOLUTE" };

const PlaceAutocompleteField = forwardRef(
  (
    {
      error,
      label,
      value,
      fullWidth,
      loading,
      formSx,
      handleSetCustomer,
      setCustomerLoading,
      noErrorMessage,
      paperStyles,
      ...props
    },
    ref
  ) => {
    const currentUser = useSelector(({ auth }) => auth.currentUser);

    const valueDebounced = useDebounce(value, 400);
    const [fetchable, setFetchable] = useState(true);
    const [focused, setFocused] = useState(false);
    const {
      placesService,
      placePredictions,
      getPlacePredictions,
      isPlacePredictionsLoading,
    } = usePlacesService({
      // eslint-disable-next-line no-undef
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      options: {
        types: ["establishment"],
        componentRestrictions: { country: currentUser?.country || "US" },
      },
      language: "en",
      libraries: ["places", "drawing", "geometry"],
    });

    useEffect(() => {
      if (fetchable && focused) getPlacePredictions({ input: valueDebounced });
      setFetchable(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueDebounced]);

    const handleCloseClickHere = () => {
      setFocused(false);
      getPlacePredictions({ input: "" });
    };

    return (
      <FormControl
        fullWidth={fullWidth}
        error={!!error}
        sx={{ ...formSx, ...styles }}
      >
        <TextField
          helperText={noErrorMessage ? "" : error}
          FormHelperTextProps={{
            sx: { position: "absolute", bottom: -16, left: 0, margin: 0 },
          }}
          InputProps={{
            sx: {
              fontSize: "12px",
              height: "32px",
            },
            autoComplete: "new-password",
            form: {
              autocomplete: "new-password",
            },
            endAdornment: isPlacePredictionsLoading && (
              <InputAdornment position="end">
                <CircularProgress size="20px" />
              </InputAdornment>
            ),
          }}
          fullWidth={fullWidth}
          label={label}
          error={!!error}
          ref={ref}
          onFocus={() => {
            setFocused(true);
            getPlacePredictions({ input: value });
          }}
          onBlur={() => setFocused(false)}
          {...props}
          value={value}
        />
        {!!placePredictions.length && (
          <ClickAwayListener
            onClickAway={() => {
              setFocused(false);
              getPlacePredictions({ input: "" });
            }}
          >
            <Paper
              sx={{
                position: "absolute",
                top: 32,
                backgroundColor: "#ffffff",
                zIndex: 100,
                border: "0.5px solid #d5d9d9",
                ...paperStyles,
              }}
            >
              {placePredictions.map((place) => (
                <Box
                  p="8px"
                  key={place.reference}
                  sx={{
                    cursor: "pointer",
                    borderBottom: "0.5px solid #d5d9d9",
                  }}
                  onClick={() => {
                    setFetchable(false);
                    setCustomerLoading(true);
                    placesService?.getDetails(
                      {
                        placeId: place.place_id,
                      },
                      (placeDetails) => {
                        handleSetCustomer(placeDetails);
                        setCustomerLoading(false);
                      }
                    );
                    getPlacePredictions({ input: "" });
                  }}
                >
                  <Typography color="#1C1C19" fontSize="12px" fontWeight="400">
                    {place.structured_formatting.main_text}
                  </Typography>
                  <Typography color="#1C1C19" fontSize="10px" fontWeight="400">
                    {place.structured_formatting.secondary_text}
                  </Typography>
                </Box>
              ))}
              <Box display="flex" p="15px 8px">
                <Typography fontSize="12px" ml="3px">
                  Can&apos;t find your name?
                </Typography>
                <Typography
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontSize: "12px",
                  }}
                  onClick={handleCloseClickHere}
                >
                  Click Here
                </Typography>
              </Box>
            </Paper>
          </ClickAwayListener>
        )}
      </FormControl>
    );
  }
);

PlaceAutocompleteField.displayName = "PlaceAutocompleteField";

PlaceAutocompleteField.propTypes = {
  error: string,
  label: PropTypes.oneOfType([string, object]),
  fullWidth: bool,
  formSx: object,
  value: string,
  loading: bool,
  handleSetCustomer: func,
  setCustomerLoading: func,
  noErrorMessage: bool,
  paperStyles: object,
};

PlaceAutocompleteField.defaultProps = {
  error: "",
  label: "",
  fullWidth: true,
  value: "",
  loading: false,
  noErrorMessage: false,
};

const GoogleAutocompleteField = forwardRef(
  (
    {
      error,
      label,
      fullWidth,
      formSx,
      noErrorMessage,
      onPlaceSelected,
      inputSx,
      endAdornment,
      country,
      ...props
    },
    ref
  ) => {
    const currentUser = useSelector(({ auth }) => auth.currentUser);

    const { ref: materialRef } = usePlacesWidget({
      // eslint-disable-next-line no-undef
      apiKey: process.env.REACT_APP_MAP_KEY,
      onPlaceSelected,
      inputAutocompleteValue: "country",
      options: {
        types: ["geocode", "establishment"],
        componentRestrictions: {
          country: currentUser?.country || country,
        },
      },
    });

    const styles = {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "rgb(213,217,217)",
        },
      },
      "& .MuiOutlinedInput-root:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#47A06D",
        },
      },
    };

    return (
      <FormControl
        fullWidth={fullWidth}
        error={!!error}
        sx={{ ...styles, ...formSx }}
      >
        <TextField
          size="small"
          helperText={noErrorMessage ? "" : error}
          FormHelperTextProps={{
            sx: { position: "absolute", bottom: -20, left: 0, margin: 0 },
          }}
          InputProps={{
            sx: {
              height: "32px",
              fontSize: "12px",
              ...inputSx,
            },
            endAdornment,
          }}
          InputLabelProps={{
            sx: {
              fontSize: "12px",
              fontWeight: 400,
              color: "#B5B5AC",
            },
          }}
          fullWidth={fullWidth}
          label={label}
          error={!!error}
          ref={ref}
          inputRef={materialRef}
          {...props}
          inputProps={{
            autocomplete: "new-password",
            form: {
              autocomplete: "new-password",
            },
          }}
        />
      </FormControl>
    );
  }
);

GoogleAutocompleteField.propTypes = {
  error: string,
  label: PropTypes.oneOfType([string, object]),
  fullWidth: bool,
  formSx: object,
  noErrorMessage: bool,
  onPlaceSelected: func,
  inputSx: object,
  endAdornment: any,
  country: string,
};

GoogleAutocompleteField.defaultProps = {
  error: "",
  country: "US",
  label: "",
  fullWidth: true,
  noErrorMessage: false,
};

GoogleAutocompleteField.displayName = "GoogleAutocompleteField";

const FilterSearchTextField = forwardRef(
  (
    {
      label,
      fullWidth,
      formSx,
      loading,
      handleClearValue,
      bgColor,
      customAdornment,
      adornmentProps,
      placeholderWidth,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl fullWidth={fullWidth} sx={formSx}>
        <TextField
          label={label}
          size="small"
          InputLabelProps={{
            sx: {
              pl: "25px",
              pr: loading && "25px",
              fontSize: "15px",
              fontWeight: 400,
              transitionProperty: "all",
              "&.Mui-focused": {
                pl: 0,
                // opacity: 0,
              },
              "&.MuiFormLabel-filled": {
                pl: 0,
                // opacity: 0,
              },
            },
          }}
          InputProps={{
            sx: {
              height: "39px",
              pl: "25px",
              pr: "5px",
              fontSize: "15px",
              fontWeight: 400,
              backgroundColor: bgColor || "#ffffff",
              "& input": {
                width: placeholderWidth || "100%",
              },
              "&:hover": {
                "&& fieldset": {
                  backgroundColor: "rgba(213, 217, 217, 0.15)",
                  border: "0.5px solid #D5D9D9",
                },
              },
              "&.Mui-focused": {
                filter: "drop-shadow(0.1px 0.2px 2px #47A06D)",
                "& > fieldset.MuiOutlinedInput-notchedOutline": {
                  borderWidth: "0.3px",
                },
              },
              "& > fieldset": {
                border: "0.5px solid #D5D9D9",
              },
            },
            endAdornment: (
              <>
                <InputAdornment
                  sx={{ position: "absolute", left: 9 }}
                  position="start"
                >
                  <SearchIcon />
                </InputAdornment>
                {loading && (
                  <InputAdornment position="end">
                    <CircularProgress size="20px" />
                  </InputAdornment>
                )}
                {props?.value && handleClearValue && (
                  <InputAdornment
                    position="end"
                    sx={{ transition: "all 0.3s ease" }}
                  >
                    <IconButton
                      id="search-clear-adornment"
                      sx={{ p: "1px" }}
                      onClick={handleClearValue}
                    >
                      <CrossIcon size="20" />
                    </IconButton>
                  </InputAdornment>
                )}
                {customAdornment && (
                  <InputAdornment
                    position="end"
                    {...adornmentProps}
                    sx={{
                      ...adornmentProps?.sx,
                      width: placeholderWidth
                        ? `calc(100% - ${placeholderWidth})`
                        : "100%",
                    }}
                  >
                    {customAdornment}
                  </InputAdornment>
                )}
              </>
            ),
          }}
          ref={ref}
          {...props}
        />
      </FormControl>
    );
  }
);

FilterSearchTextField.displayName = "FilterSearchTextField";

FilterSearchTextField.propTypes = {
  label: PropTypes.oneOfType([string, object]),
  fullWidth: bool,
  formSx: object,
  loading: bool,
  value: any,
  handleClearValue: func,
  bgColor: string,
  customAdornment: object,
  adornmentProps: object,
  placeholderWidth: string,
};

FilterSearchTextField.defaultProps = {
  label: "",
  value: "",
  fullWidth: false,
  loading: false,
  bgColor: "#ffffff",
  adornmentProps: { sx: {} },
};

const SearchTextField = forwardRef(
  ({ label, fullWidth, formSx, ...props }, ref) => {
    return (
      <FormControl sx={formSx}>
        <TextField
          sx={{ backgroundColor: "#F4F6F9" }}
          label={label}
          size="small"
          InputLabelProps={{
            sx: {
              top: -2,
              color: "#B5B5AC",
              fontSize: "12px",
              "&.Mui-focused": {
                color: "#42A57F",
                top: 0,
              },
              "&.MuiFormLabel-filled": {
                top: 0,
              },
            },
          }}
          InputProps={{
            sx: { height: "28px", fontSize: "12px" },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          ref={ref}
          {...props}
        />
      </FormControl>
    );
  }
);

SearchTextField.displayName = "SearchTextField";

SearchTextField.propTypes = {
  label: PropTypes.oneOfType([string, object]),
  fullWidth: bool,
  formSx: object,
};

SearchTextField.defaultProps = {
  label: "",
  fullWidth: false,
};

const StyledTimePicker = forwardRef(
  ({ textFieldProps, error, ...props }, ref) => {
    const [open, setOpen] = useState(false);
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderInput={(params) => (
            <StyledTextField
              size="small"
              {...params}
              error={error}
              InputProps={{
                ...params.InputProps,
                sx: {
                  height: "32px",
                  "& > input": {
                    textTransform: "uppercase",
                    color: "#1C1C19",
                  },
                },
              }}
              ref={ref}
              {...textFieldProps}
              onMouseDown={(e) => {
                e.preventDefault();
                if (!props.disabled) setOpen((prev) => !prev);
              }}
              onKeyDown={(e) => e.preventDefault()}
              disabled={props.disabled}
            />
          )}
          components={{
            OpenPickerIcon: ClockIcon,
          }}
          {...props}
        />
      </LocalizationProvider>
    );
  }
);

StyledTimePicker.displayName = "StyledTimePicker";

StyledTimePicker.propTypes = {
  textFieldProps: object,
  error: string,
  disabled: bool,
};

StyledTimePicker.defaultProps = {
  textFieldProps: {},
  error: "",
  disabled: false,
};

const StyledDatePicker = forwardRef(
  ({ textFieldProps, inputSx, error, ...props }, ref) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          renderInput={(params) => (
            <StyledTextField
              size="small"
              {...params}
              error={error}
              InputProps={{
                ...params.InputProps,
                sx: {
                  ...inputSx,
                  height: "32px",
                },
              }}
              ref={ref}
              {...textFieldProps}
            />
          )}
          components={{
            OpenPickerIcon: CalendarIcon,
          }}
          {...props}
        />
      </LocalizationProvider>
    );
  }
);

StyledDatePicker.displayName = "StyledDatePicker";

StyledDatePicker.propTypes = {
  textFieldProps: object,
  error: string,
  inputSx: object,
};

StyledDatePicker.defaultProps = {
  textFieldProps: {},
  inputSx: {},
  error: "",
};

const MinusIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.225}
    height={1}
    viewBox="0 0 9.225 1"
    {...props}
  >
    <path data-name="Line 1042" fill="none" stroke="#707070" d="M0 .5h9.225" />
  </svg>
);

const MobileMinusIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    fillOpacity=".6"
  >
    <path
      d="M4.688 9.063a.937.937 0 1 0 0 1.874h10.625a.937.937 0 1 0 0-1.874z"
      fill="#0F172A"
    />
  </svg>
);

const PlusIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.225}
    height={8.072}
    viewBox="0 0 9.225 8.072"
    {...props}
  >
    <path
      data-name="Line 1041"
      fill="none"
      stroke="#707070"
      d="M4.57 0v8.072"
    />
    <path
      data-name="Line 1043"
      fill="none"
      stroke="#707070"
      d="M0 4.036h9.225"
    />
  </svg>
);

const MobilePlusIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.938 4.688a.937.937 0 1 0-1.876 0v4.375H4.689a.937.937 0 1 0 0 1.874h4.375v4.376a.937.937 0 1 0 1.874 0v-4.376h4.376a.937.937 0 1 0 0-1.874h-4.376z"
      fill="#000"
      fillOpacity=".6"
    />
  </svg>
);

const TextFieldWithBtns = forwardRef(
  (
    {
      value,
      setValue,
      onClickLeftBtn,
      onClickRightBtn,
      control,
      index,
      outOfStock,
      minOrderQTY,
      disabledIncrementBtn,
      ...props
    },
    ref
  ) => {
    const { isMobile } = useMediaDevice();

    const setPaddings = useMemo(() => {
      const valueLength = value?.toString()?.length;
      if (valueLength === 1 || valueLength === 0) return "8.5px 38%";
      if (valueLength === 2) return "8.5px 28%";
      if (valueLength === 3) return "8.5px 18%";

      return "8.5px 8%";
    }, [value]);

    const MobileIcon = value === 1 ? DeleteIcon : MobileMinusIcon;

    return (
      <Box
        width={{ xs: "108px", sm: "105px" }}
        display="flex"
        ref={ref}
        {...props}
      >
        <Button
          sx={{
            mr: { xs: "0px", sm: "-1px" },
            width: { xs: "33px", sm: "31px" },
            minWidth: { xs: "33px", sm: "31px" },
            height: "34px",
            minHeight: "34px",
            borderRadius: { xs: "8px 0 0 8px", sm: "4px 0 0 4px" },
            borderWidth: `1px ${isMobile ? "0px" : "0.5px"} 1px 1px`,
            borderColor: { xs: "rgba(0, 0, 0, 0.05)", sm: "#D5D9D9" },
            backgroundColor: { xs: "white", sm: "#F7F7F7" },
            color: "#707070",
            svg: {
              width: { xs: "20px", sm: "9.22px" },
              height: { xs: "20px", sm: "9.22px" },
            },
            span: {
              mx: 0,
            },
            "&:hover": {
              borderWidth: {
                xs: "1px 0px 1px 1px",
                sm: "1px 0.5px 1px 1px}",
              },
              borderColor: { xs: "rgba(0, 0, 0, 0.05)", sm: "#000" },
              backgroundColor: { xs: "white !important", sm: "#F7F7F7" },
              zIndex: 11,
            },
          }}
          variant="outlined"
          startIcon={isMobile ? <MobileIcon /> : <MinusIcon />}
          onClick={onClickLeftBtn}
        />

        <Controller
          name={`products.${index}.quantity`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              placeholder="0"
              value={value}
              fullWidth
              size="small"
              error={!!error?.message}
              sx={{ "&:hover": { zIndex: 1 } }}
              InputProps={{
                sx: {
                  fontSize: { xs: 14, sm: 16 },
                  fontWeight: { xs: 500, sm: 400 },
                  color: "#5F6267",
                  height: "34px",
                  minHeight: "34px",
                  "&.Mui-focused fieldset": {
                    borderColor: { xs: "rgba(0, 0, 0, 0.05)", sm: "#000" },
                    zIndex: 1,
                    borderWidth: {
                      xs: "1px 0px 1px 0px !important",
                      sm: "1px !important",
                    },
                  },
                  borderRadius: 0,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: {
                      xs: "rgba(0, 0, 0, 0.05) !important",
                      sm: "#D5D9D9",
                    },
                    borderWidth: { xs: "1px 0px 1px 0px", sm: "1px" },
                  },
                  "& input": { p: setPaddings },
                  zIndex: "10",
                },
              }}
              type="tel"
              {...field}
              onChange={setValue}
            />
          )}
        />

        <Button
          sx={{
            ml: "-1px",
            width: { xs: "33px", sm: "31px" },
            minWidth: { xs: "33px", sm: "31px" },
            height: "34px",
            minHeight: "34px",
            borderRadius: { xs: "0 8px 8px 0", sm: "0 4px 4px 0" },
            borderWidth: `1px 1px 1px ${isMobile ? "0px" : "0.5px"}`,
            borderColor: { xs: "rgba(0, 0, 0, 0.05)", sm: "#D5D9D9" },
            backgroundColor: { xs: "white", sm: "#F7F7F7" },
            color: "#707070",
            "&:hover": {
              borderWidth: { xs: "1px 1px 1px 0px", sm: "1px 1px 1px 0.5px" },
              zIndex: 11,
              borderColor: { xs: "rgba(0, 0, 0, 0.05)", sm: "#000" },
              backgroundColor: { xs: "white !important", sm: "#F7F7F7" },
            },
            "& .MuiButton-startIcon": { mx: 0 },
          }}
          disabled={disabledIncrementBtn}
          variant="outlined"
          startIcon={isMobile ? <MobilePlusIcon /> : <PlusIcon />}
          onClick={onClickRightBtn}
        />
      </Box>
    );
  }
);

TextFieldWithBtns.displayName = "TextFieldWithBtns";

TextFieldWithBtns.propTypes = {
  value: oneOfType([string, number]),
  setValue: func,
  onClickLeftBtn: func,
  onClickRightBtn: func,
  index: number,
  control: object,
  minOrderQTY: number,
  outOfStock: bool,
  disabledIncrementBtn: bool,
};
TextFieldWithBtns.defaultProps = {
  value: 0,
  setValue: () => {},
  onClickLeftBtn: () => {},
  onClickRightBtn: () => {},
  index: 0,
  control: {},
  minOrderQTY: 0,
  outOfStock: false,
  disabledIncrementBtn: false,
};

export {
  StyledTextField,
  LabeledTextField,
  FilterSearchTextField,
  SearchTextField,
  GoogleAutocompleteField,
  PlaceAutocompleteField,
  StyledTimePicker,
  StyledDatePicker,
  PriceFormat,
  TextFieldWithBtns,
};
